import { Color } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { useRequisitionSchedulesQuery } from 'graphql/types.generated'
import { logger } from 'logger'
import React, { useEffect } from 'react'

import { RequisitionScheduleContainer } from './RequisitionScheduleContainer'

export const RequisitionSchedulesContainer = () => {
  const { data, loading, error } = useRequisitionSchedulesQuery({ fetchPolicy: 'cache-first' })

  useEffect(() => {
    if (!!error) {
      logger.error('RequisitionSchedulesContainer', `${error.message}`)
    }
  }, [error])

  return (
    <div>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )}
      <div>
        {data?.schedules.map((s, index) => (
          <div
            key={`${s.id}.${index}`}
            style={{
              border: `1px solid ${Color.RDY_FOREST}`,
              paddingTop: 12,
              paddingLeft: 12,
              paddingRight: 12,
              paddingBottom: 24,
              marginBottom: 30,
              backgroundColor: Color.WHITE
            }}
          >
            <RequisitionScheduleContainer requisitionSchedule={s} />
          </div>
        ))}
      </div>
    </div>
  )
}
