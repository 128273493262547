import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Input, Paper, TextField, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Alert } from '@material-ui/lab'
import { useUploadImageByUrlMutation, useUploadImageMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { ProductImageFormModel } from 'views/products/CreateProduct/productValidator'

interface ImageUploadProps {
  productImages: ProductImageFormModel[]
  updateProductImages: (images: ProductImageFormModel[]) => void
}

export const ImageUpload = (props: ImageUploadProps) => {
  const { updateProductImages, productImages } = props
  const [fileUrl, onChangeFileUrl] = useState<string>()

  const toast = useToast()
  const [execute, { loading: loadingFileUpload }] = useUploadImageMutation()
  const [uploadImageByUrl, { loading: loadingUrlImageUpload }] = useUploadImageByUrlMutation()

  async function onChangeHandler(event: any) {
    if (event.target.validity.valid) {
      uploadFile(event.target.files[0])
    }
  }

  const hiddenFileInput = React.useRef<HTMLInputElement>(null)
  const handleChooseFileClick = (event: any) => {
    if (document != null) {
      document.getElementById('fileInput')?.click()
    }
  }
  async function handleUploadViaUrlClick() {
    if (!fileUrl || !fileUrl.length) {
      toast.error('Please specify a url')
      return
    }
    try {
      let filename = (
        await uploadImageByUrl({
          variables: {
            url: fileUrl
          }
        })
      ).data?.uploadImageByUrl!
      let newProductImages = [...productImages, { imageUri: filename, libraryImageId: null }]
      updateProductImages(newProductImages)
    } catch (e: any) {
      toast.error(e.toString())
    }
  }
  async function uploadFile(currentFile: File) {
    if (!currentFile) {
      return
    }
    try {
      let filename = (
        await execute({
          variables: {
            file: currentFile
          }
        })
      ).data?.uploadImage!

      let newProductImages = [...productImages, { imageUri: filename, libraryImageId: null }]
      updateProductImages(newProductImages)
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  return (
    <div style={{ margin: 10 }}>
      <Alert severity='info' style={{ marginBottom: 10 }}>
        Choose square image with the product centered for the best result
      </Alert>
      <div>
        <div>
          <Button label='Upload an image from your computer' onClick={handleChooseFileClick} endIcon={<AddIcon />} />
        </div>
        <div>
          <Typography variant='subtitle2'>or</Typography>
          <div>
            <TextField
              fullWidth
              name='fileUrl'
              variant='outlined'
              value={fileUrl}
              label='Image URL'
              onChange={(e) => {
                onChangeFileUrl(e.target.value)
              }}
            />
            <Button label='Upload an image from a URL' onClick={handleUploadViaUrlClick} endIcon={<AddIcon />} />
          </div>
        </div>
        <Input
          id='fileInput'
          type='file'
          placeholder='Choose an image'
          ref={hiddenFileInput}
          onChange={onChangeHandler}
          style={{ display: 'none' }}
        />
      </div>
      {(loadingFileUpload || loadingUrlImageUpload) && (
        <Paper variant={'outlined'} elevation={0}>
          <CircularProgress />{' '}
        </Paper>
      )}
    </div>
  )
}
