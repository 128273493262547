import { Section } from '@bloom-coffee/steamed-milk'
import { MenuItem } from '@material-ui/core'
import { NumericField } from 'components/form/NumericField'
import { SelectField } from 'components/form/SelectField'
import { SwitchField } from 'components/form/SwitchField'
import { TextField } from 'components/form/TextField'
import { InfoButton } from 'components/InfoButton'
import { CategoryType, RoastType, useCategorySelectQuery } from 'graphql/types.generated'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultValues, useFormContext } from 'react-hook-form'
import { ProductFormModel } from 'views/products/CreateProduct/productValidator'

import { CategorySelect } from '../CategorySelect/CategorySelect'
const categoriesWithoutDescriptions = [CategoryType.RetailBeans]
const categoriesWithSize = [CategoryType.RetailBeans]
const categoriesWithRoasterDetails = [CategoryType.RetailBeans]

interface ProductDetailsProps {
  initialValues?: DefaultValues<ProductFormModel> | undefined
  updateCategoryType: (categoryType: CategoryType | null) => void
  isSynced?: boolean
  merchantId: string
}

export function ProductDetails(props: ProductDetailsProps) {
  const { initialValues, updateCategoryType, isSynced, merchantId } = props
  const [categoryType, setCategoryType] = useState<CategoryType | null>()
  const [currentInventory, setCurrentInventory] = useState<number | null>()
  const { register, setValue, getValues, watch } = useFormContext()
  const { data: categoryData, loading: loadingCategory } = useCategorySelectQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })
  const categories = useMemo(() => categoryData?.categories ?? [], [categoryData?.categories])

  const getCategoryType = useCallback(() => {
    const categoryId = getValues('categoryId')
    let matchedCategory = categories.filter((c) => c.id === categoryId)
    return matchedCategory.length ? matchedCategory[0].type ?? null : null
  }, [categories, getValues])

  const categoryChanged = useCallback(() => {
    const newCategoryType = getCategoryType()
    if (newCategoryType === categoryType) {
      return
    }
    setCategoryType(newCategoryType)
    updateCategoryType(newCategoryType)
  }, [categoryType, getCategoryType, updateCategoryType])

  useEffect(() => {
    categoryChanged()
  }, [categoryChanged])

  const active = watch('active')
  const productName = watch('name')
  const printableName = watch('printableName')

  function updateNames() {
    if (productName && productName.length) {
      if (!printableName?.length && !printableName?.length) {
        setValue('printableName', productName)
      }
    }
  }

  function descriptionIsAllowed() {
    return !categoryType || categoriesWithoutDescriptions.indexOf(categoryType) === -1
  }
  function sizeIsAllowed() {
    return categoryType && categoriesWithSize.indexOf(categoryType) >= 0
  }
  function areRoasterDetailsAllowed() {
    return categoryType && categoriesWithRoasterDetails.indexOf(categoryType) >= 0
  }

  useEffect(() => {
    if (!categoryType) {
      return
    }
    if (!descriptionIsAllowed()) {
      setValue('description', null)
    }
    if (!sizeIsAllowed()) {
      setValue('sizeInGrams', null)
    }

    if (!areRoasterDetailsAllowed()) {
      setValue('roaster', null)
      setValue('roastType', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryType, setValue])

  useEffect(() => {
    if (!initialValues) {
      setCurrentInventory(null)
      return
    }

    if (initialValues.currentQuantity != null && initialValues.currentQuantity !== undefined) {
      setCurrentInventory(initialValues.currentQuantity)
      return
    }

    let topModsWithOptionsWithInventory = initialValues.modifiers?.filter(
      (t) => t.options?.filter((o) => o.currentQuantity != null && o.currentQuantity !== undefined)?.length
    )

    if (topModsWithOptionsWithInventory?.length) {
      let currentInvCount = 0
      for (let topMod of topModsWithOptionsWithInventory) {
        for (let option of topMod.options!!) {
          if (option.currentQuantity) {
            currentInvCount += option.currentQuantity
          }
        }
      }

      setCurrentInventory(currentInvCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return (
    <Section
      title='Product Details'
      variant='subsection'
      addon={<SwitchField name='active' label={active ? 'Active' : 'Inactive'} />}
      subTitle={
        <div>
          <div>{!active && 'This product is inactive and will not appear on your menu'}</div>
          <div>{currentInventory != null && `(${currentInventory} Available)`}</div>
        </div>
      }
    >
      <div style={detailsContainerStyle}>
        <TextField
          style={fieldStyle}
          name='name'
          label={categoryType === CategoryType.RetailBeans ? 'Roast Name' : 'Name'}
          inputRef={register()}
          onBlur={updateNames}
        />
        <div style={fieldIconContainerStyle}>
          <TextField
            style={fieldWithIconStyle}
            name='printableName'
            label={'Printer Name (Optional)'}
            inputRef={register()}
          />
          <InfoButton style={infoButtonStyle} title='Printer Name'>
            Printed names are sometimes abbreviated. This will be how it's printed on the ticket
          </InfoButton>
        </div>
        <div style={fieldIconContainerStyle}>
          <CategorySelect
            name='categoryId'
            label='Category'
            style={fieldWithIconStyle}
            onChange={(e, c) => {
              categoryChanged()
            }}
            categories={categories}
            loading={loadingCategory}
          />
          <InfoButton style={infoButtonStyle} title='Categories'>
            RDY organizes Products into different categories like ‘Coffee’ and ‘Merch’
          </InfoButton>
        </div>
        {descriptionIsAllowed() && (
          <TextField style={fieldStyle} name='description' label='Description' inputRef={register()} multiline={true} />
        )}
        {areRoasterDetailsAllowed() && (
          <TextField style={fieldStyle} name='roaster' label='Roaster' inputRef={register()} />
        )}
        {sizeIsAllowed() && (
          <NumericField
            style={fieldStyle}
            name='sizeInGrams'
            label='Size (g)'
            defaultValue={initialValues?.sizeInGrams}
          />
        )}
        {categoryType === CategoryType.RetailBeans && (
          <SelectField
            style={fieldStyle}
            name='roastType'
            label='Roast Type'
            defaultValue={initialValues?.roastType || ''}
          >
            <MenuItem value=''>
              <em>Select a roast type</em>
            </MenuItem>
            <MenuItem value={RoastType.Light}>Light</MenuItem>
            <MenuItem value={RoastType.Medium}>Medium</MenuItem>
            <MenuItem value={RoastType.Dark}>Dark</MenuItem>
          </SelectField>
        )}
        {!!initialValues?.taxRate && (
          <TextField disabled={isSynced} style={fieldStyle} name='taxRate' label='Tax Rate (%)' inputRef={register()} />
        )}
      </div>
    </Section>
  )
}

const infoButtonStyle: React.CSSProperties = {
  marginTop: 10
}

const fieldStyle: React.CSSProperties = {
  minWidth: 200,
  margin: 10
}

const fieldWithIconStyle: React.CSSProperties = {
  ...fieldStyle,
  marginLeft: 10,
  marginRight: 1,
  marginBottom: 10,
  marginTop: 10
}

const fieldIconContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const detailsContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  maxWidth: 700
}
