import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, DialogContentText, Grid, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { usePromotionQuery } from 'graphql/types.generated'
import { useArchiveMerchantPromotion } from 'hooks/useArchiveMerchantPromotion/useArchiveMerchantPromotion'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { PromoCodesList } from 'views/admin/promotions/Promotion/components/PromoCodesList'

export const ViewMerchantPromotion = () => {
  const navigate = useNavigate()
  const { promotionId } = useParams()

  const [showDeletePrompt, toggleDeletePrompt] = useState(false)

  const { data, loading } = usePromotionQuery({
    variables: { id: promotionId! },
    fetchPolicy: 'cache-first'
  })

  const { archive, loading: archiving } = useArchiveMerchantPromotion()

  function goBack() {
    navigate(-1)
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Section
          title={`Promotion ${data?.promotion.customerFacingName}`}
          addon={
            <>
              <ConfirmationDialog
                title='Are you sure?'
                open={showDeletePrompt}
                onClose={() => toggleDeletePrompt(false)}
                onConfirm={() => archive(promotionId!, goBack)}
              >
                <DialogContentText>Are you sure you want to delete this promotion?</DialogContentText>
              </ConfirmationDialog>
              <Button
                disabled={archiving}
                theme='warning'
                endIcon={<DeleteIcon />}
                onClick={() => toggleDeletePrompt(true)}
              />
            </>
          }
        >
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <Typography variant='caption'>Status</Typography>
              <Typography variant='subtitle2' color={data?.promotion.status === 'REJECTED' ? 'error' : 'textPrimary'}>
                {data?.promotion.status}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant='caption'>Created On</Typography>
              <Typography variant='subtitle2'>
                {data?.promotion.createdOn ? new Date(data?.promotion.createdOn).toDateString() : undefined}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant='caption'>Start</Typography>
              <Typography variant='subtitle2'>
                {data?.promotion.start
                  ? `${new Date(data?.promotion.start).toDateString()} ${new Date(
                      data?.promotion.start
                    ).toTimeString()}`
                  : undefined}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant='caption'>End</Typography>
              <Typography variant='subtitle2'>
                {data?.promotion.end
                  ? `${new Date(data?.promotion.end).toDateString()} ${new Date(data?.promotion.end).toTimeString()}`
                  : undefined}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant='caption'>Flat Discount</Typography>
              <Typography variant='subtitle2'>
                {formatUsCentsAsUsDollar(data?.promotion.flatDiscountUsCents || 0)}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant='caption'>Percentage Discount</Typography>
              <Typography variant='subtitle2'>{`${data?.promotion.percentageDiscount}%`}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant='caption'>Max Discount</Typography>
              <Typography variant='subtitle2'>
                {data?.promotion.maxDiscountUsCents
                  ? formatUsCentsAsUsDollar(data.promotion.maxDiscountUsCents)
                  : 'None'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant='caption'>New Users Only</Typography>
              <Typography variant='subtitle2'>{data?.promotion.newUserOnly ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='caption'>Limit Per User</Typography>
              <Typography variant='subtitle2'>{data?.promotion.limitPerUser}</Typography>
            </Grid>
          </Grid>

          <PromoCodesList codes={data?.promotion.codes || []} promotionId={data?.promotion.id!!} />
        </Section>
      )}
    </>
  )
}
