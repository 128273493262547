import { Grid } from '@material-ui/core'
import { DefaultFormFooter, DefaultFormFooterProps } from 'components/form/DefaultFormFooter'
import { TextField } from 'components/form/TextField'
import { FormProvider, useForm } from 'react-hook-form'

interface FreshToGoLoginFormProps {
  onCancel?(): void
  onSubmit(values: FreshToGoLoginFormModel): void
  FooterComponent?(props: DefaultFormFooterProps): JSX.Element
  disabled: boolean
}

export function FreshToGoLoginForm(props: FreshToGoLoginFormProps) {
  const { onCancel, onSubmit, FooterComponent = DefaultFormFooter, disabled } = props
  const formProps = useForm<FreshToGoLoginFormModel>()

  const { register, handleSubmit } = formProps

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formProps}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={disabled}
              name='username'
              label='Username/Email'
              inputRef={register({ required: 'Username is required' })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={disabled}
              type='password'
              name='password'
              label='Password'
              inputRef={register({ required: 'Password is required' })}
            />
          </Grid>
          <Grid item>
            <FooterComponent onCancel={onCancel} onSubmit={handleSubmit(onSubmit)} saveLabel='Log In' />
          </Grid>
        </Grid>
      </FormProvider>
    </form>
  )
}

export interface FreshToGoLoginFormModel {
  username: string
  password: string
}
