import { Button, Section } from '@bloom-coffee/steamed-milk'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

import {
  ModifierInput,
  ModifierOptionFragment,
  ModifiersWithoutChildrenByMerchantDocument,
  useCreateModifierOptionMutation
} from '../../../graphql/types.generated'
import { useToast } from '../../../hooks/useToast'
import { ModifierOptionForm } from './ModifierOptionForm'
import { ModifierOptionFormModel } from './modifierOptionValidator'

interface CreateModifierOptionContainerProps {
  inlineCreate: boolean
  merchantId: string
  onFinish: (modifierOption: ModifierOptionFragment) => void
  onCancel: () => void
}

export const CreateModifierOptionContainer = (props: CreateModifierOptionContainerProps) => {
  const { merchantId, onFinish, onCancel, inlineCreate } = props
  const toast = useToast()
  const [execute] = useCreateModifierOptionMutation()

  async function handleSubmit(formInput: ModifierOptionFormModel) {
    const { currentQuantity, currentlyAvailable, ...rest } = formInput
    const input: ModifierInput = {
      id: undefined,
      description: undefined,
      childSelectionType: undefined,
      defaultSelectionIds: [],
      options: [],
      merchantId: merchantId,
      ...rest
    }

    const res = await execute({
      variables: {
        input
      },
      refetchQueries: [
        {
          query: ModifiersWithoutChildrenByMerchantDocument,
          variables: { merchantId: props.merchantId }
        }
      ]
    })

    if (res.data?.createModifier) {
      toast.success('Modifier Option created')
      onFinish(res.data?.createModifier)
    }
  }

  return (
    <Section
      title={'Create a New Modifier Option'}
      addon={<Button endIcon={<CloseIcon />} onClick={onCancel} label='Close' theme='cancel' />}
      variant={inlineCreate ? 'subsection' : undefined}
    >
      <ModifierOptionForm onSubmit={handleSubmit} initialValues={{}} onCancel={onCancel} />
    </Section>
  )
}
