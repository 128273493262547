import { CircularProgress, Switch } from '@material-ui/core'
import { ExternalDataSource, useUpdateMenuSyncConfigActiveMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React from 'react'
import { Maybe } from 'yup/lib/types'

interface MenuSyncContainerProps {
  merchantId: ID
  canSync: boolean
  isSyncActive: Maybe<boolean>
  sourceSystem: Maybe<ExternalDataSource>
}

export function MenuSyncContainer(props: MenuSyncContainerProps) {
  const { merchantId, isSyncActive, canSync, sourceSystem } = props
  const toast = useToast()

  const [execute, { loading: loadingUpdate }] = useUpdateMenuSyncConfigActiveMutation()

  async function updateMenuSyncConfigActive(active: boolean) {
    try {
      execute({
        variables: {
          merchantId,
          active
        }
      })
      toast.success('Sync Updated')
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  return (
    <div style={mainStyle}>
      {loadingUpdate && <CircularProgress />}
      {canSync && (
        <div style={mainStyle}>
          <span style={syncingStyle}>
            {isSyncActive ? `Syncing ${sourceSystem} Menu` : `Syncing ${sourceSystem} Menu Paused`}
          </span>
          <Switch checked={isSyncActive === true} onChange={(e, c) => updateMenuSyncConfigActive(c)} />
        </div>
      )}
    </div>
  )
}

const syncingStyle: React.CSSProperties = {
  fontSize: 16,
  margin: 'auto'
}
const mainStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}
