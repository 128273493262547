import { InMemoryCache } from '@apollo/client'

import { ModifierOptionFragment } from './types.generated'

export const graphQLCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        modifiersWithoutChildrenByMerchant: {
          merge(existing: ModifierOptionFragment[], incoming: ModifierOptionFragment[]) {
            return incoming
          }
        }
      }
    }
  }
})
