import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { DecimalField } from 'components/form/DecimalField'
import { PriceField } from 'components/form/PriceField'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../../components/form/DefaultFormFooter'
import { MerchantFeeConfigFormModel, merchantFeeConfigSchema } from './merchantFeeConfigValidator'

interface MerchantFeeConfigFormProps {
  onCancel: () => void
  onSubmit: (values: MerchantFeeConfigFormModel) => void
  disableSubmit?: boolean
  initialValues?: DefaultValues<MerchantFeeConfigFormModel> | undefined
}

export const MerchantFeeConfigForm = (props: MerchantFeeConfigFormProps) => {
  const { onCancel, onSubmit, disableSubmit, initialValues } = props

  const formProps = useForm<MerchantFeeConfigFormModel>({
    resolver: yupResolver(merchantFeeConfigSchema),
    shouldUnregister: false
  })

  const { handleSubmit, formState } = formProps
  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={4}>
            <DecimalField
              fullWidth
              name='percentageFee'
              label='Percentage Fee'
              defaultValue={initialValues?.percentageFee}
            />
          </Grid>

          <Grid item xs={4}>
            <PriceField
              fullWidth
              name='staticFeeInUsCents'
              label='Static Fee'
              defaultValue={initialValues?.staticFeeInUsCents}
            />
          </Grid>
          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
