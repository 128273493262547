import { Section } from '@bloom-coffee/steamed-milk'
import { FeatureFlagName, useFeatureFlagQuery } from 'graphql/types.generated'
import React from 'react'

import { MerchantDeepLinkGrid } from './MerchantDeepLinkGrid'
import { WebOrderingUrl } from './WebOrderingUrl'

interface MerchantLinkContainerProps {
  merchantId: string
}

export const MerchantLinkContainer = (props: MerchantLinkContainerProps) => {
  const { merchantId } = props

  const { data } = useFeatureFlagQuery({
    fetchPolicy: 'cache-first',
    variables: { merchantId, featureFlagName: FeatureFlagName.WebOrdering }
  })

  return (
    <Section title='Links'>
      {data?.featureFlag?.enabled && (
        <Section title='Web Ordering Url' variant='subsection'>
          <WebOrderingUrl merchantId={merchantId} />
        </Section>
      )}

      <Section title='Other' variant='subsection'>
        <MerchantDeepLinkGrid merchantId={merchantId} />
      </Section>
    </Section>
  )
}
