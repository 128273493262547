import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useCatalogListQuery } from 'graphql/types.generated'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Catalog } from './Catalog'

interface CatalogListProps {
  merchantId: ID
}

export function CatalogList(props: CatalogListProps) {
  const { merchantId } = props
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data, loading } = useCatalogListQuery({
    variables: {
      merchantId
    }
  })

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Section
      title='Menus'
      addon={<Button endIcon={<AddIcon />} onClick={() => navigate(`${pathname}/new`)} label='Create menu' />}
    >
      {data?.merchant?.catalogs?.map((catalog) => {
        return <Catalog merchantId={merchantId} catalog={catalog} />
      })}
      {!data?.merchant?.catalogs?.length && (
        <Typography>
          <em>No Menus found</em>
        </Typography>
      )}
    </Section>
  )
}
