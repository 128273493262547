export type FontWeight = 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'

export enum ColorHex {
  RDY_FOREST = '#09292D',
  RDY_GOLD = '#F7C02D',
  RDY_BEIGE = '#F2EFE4',
  RDY_MALIBU = '#A6E8FF',
  RDY_PINK = '#FFB7BB',
  RDY_RED = '#EF4726',
  RDY_LIGHT_FOREST = '#3A5457',
  RDY_LIGHT_GREY = '#9B9B9B',
  RDY_BLACK = '#000000',
  BLUE_GREY_100 = '#CFD8DC',
  BLUE_GREY_200 = '#B0BEC5',
  BLUE_GREY_500 = '#607D8B',
  BLUE_GREY_700 = '#455A64',
  BLUE_GREY_900 = '#263238',
  FACEBOOK_BLUE = '#1877F2',
  GOOGLE_BLUE = '#4285F4',
  GOOGLE_WHITE = '#FFFFFF ',
  GREY_200 = '#EEEEEE',
  GREY_300 = '#E0E0E0',
  GREY_600 = '#757575',
  GREY_700 = '#616161',
  LIGHT_LIGHT_BLUE_GREY = '#B0BEC5',
  PINK_A500 = '#F50057',
  RED = '#ee020a',
  WHITE = '#FFFFFF'
}
