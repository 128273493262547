import { DataGrid, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { readableDateTimeFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'

import { MerchantReferralDetails } from './transactionTransforms'

interface MerchantReferralsDataGridProps {
  referrals: MerchantReferralDetails[]
  loading: boolean
}

export function MerchantReferralsDataGrid(props: MerchantReferralsDataGridProps) {
  const { referrals, loading } = props

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'referralDate',
      sort: 'desc'
    }
  ])

  return (
    <div style={{ height: 300 }}>
      <DataGrid
        loading={loading}
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={(model) => {
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model)
          }
        }}
        rows={referrals || []}
        columns={[
          {
            field: 'referralDate',
            headerName: 'Date',
            sortable: true,
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => readableDateTimeFromInstant(params.value as number)
          },
          {
            field: 'payout',
            headerName: 'Payout',
            sortable: false,
            width: 110,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          }
        ]}
      />
    </div>
  )
}
