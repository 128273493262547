import { DropdownMenu } from '@bloom-coffee/steamed-milk'
import { ReactComponent as MenuIcon } from 'assets/svg/glyphs/menu.svg'
import { ReactComponent as RdyLogo } from 'assets/svg/glyphs/RDY_logo_primary.svg'
import { config } from 'config'
import { useOrganizationListQuery } from 'graphql/types.generated'
import { useLogout } from 'hooks/useLogout'
import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import { useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { ColorHex } from 'theme/ThemeConstants'
import { MyDevices } from 'views/devices/MyDevices'
import { OrganizationSwitcher } from 'views/organizations/OrganizationSwitcher'
import { UpdateUserProfile } from 'views/users/components/UserProfile/UpdateUserProfile'

import { Modal } from '../Modal/Modal'

export function MainMenu() {
  const { hasAnyRole } = usePermissions()
  const navigate = useNavigate()
  const logout = useLogout()
  const match = useMatch(`/organizations/:organizationId/*`)

  const [userProfileModalOpen, setUserProfileModalOpen] = useState<boolean>(false)
  const [devicesModalOpen, setDevicesModalOpen] = useState<boolean>(false)
  const [switchOrgModalOpen, setSwitchOrgModalOpen] = useState<boolean>(false)

  const { data: organizationData } = useOrganizationListQuery()

  function getMenuItems() {
    let menuItems = []
    menuItems.push({
      buttonStyle: buttonHeaderTitle,
      onClick: () => {},
      title: (
        <div style={buttonHeaderTitle}>
          <RdyLogo style={rdyActiveStyle} />
        </div>
      )
    })
    if (hasAnyRole(roles.admin)) {
      menuItems.push({
        buttonStyle: menuItemStyle,
        onClick: () => navigate('/admin'),
        title: 'Admin'
      })
    }
    if ((organizationData?.organizations?.length ?? 0) > 1 && !hasAnyRole(roles.admin)) {
      menuItems.push({
        buttonStyle: menuItemStyle,
        onClick: () => setSwitchOrgModalOpen(true),
        title: 'Switch Organization'
      })
    }

    if (match && match.params.organizationId) {
      menuItems.push({
        buttonStyle: menuItemStyle,
        onClick: () => navigate(`/organizations/${match.params.organizationId}`),
        title: 'All Cafes'
      })
    }

    if (hasAnyRole(roles.anyOrganizationMember)) {
      menuItems.push({
        buttonStyle: menuItemStyle,
        onClick: () => setUserProfileModalOpen(true),
        title: 'My Profile'
      })
      menuItems.push({
        buttonStyle: menuItemStyle,
        onClick: () => setDevicesModalOpen(true),
        title: 'My Devices'
      })
    }

    menuItems.push({
      buttonStyle: menuItemStyle,
      onClick: () => (window.location.href = 'mailto:support@rdy.xyz?subject=RDY Feedback'),
      title: 'Contact Us'
    })

    menuItems.push({
      buttonStyle: menuItemStyle,
      onClick: () => logout(),
      title: 'Logout'
    })
    menuItems.push({
      buttonStyle: versionMenuItemStyle,
      onClick: () => {},
      title: `RDY v${config.version}`
    })
    return menuItems
  }
  return (
    <div style={menuContainerStyle}>
      <DropdownMenu
        dropdownMenuItemDetails={getMenuItems()}
        triggerButtonStyle={triggerButtonStyle}
        menuStyle={dropdownMenuStyle}
      >
        <MenuIcon style={menuStyle} />
      </DropdownMenu>
      <Modal open={userProfileModalOpen} onClose={() => setUserProfileModalOpen(false)} title='Update User Profile'>
        <UpdateUserProfile onClose={() => setUserProfileModalOpen(false)} />
      </Modal>
      <Modal open={devicesModalOpen} onClose={() => setDevicesModalOpen(false)} title='My Tablets'>
        <MyDevices />
      </Modal>
      <Modal
        open={switchOrgModalOpen}
        onClose={() => setSwitchOrgModalOpen(false)}
        title='Choose Your Organization'
        style={{ width: 400 }}
        disableClickOutClose={true}
      >
        <OrganizationSwitcher
          organizations={organizationData?.organizations ?? []}
          onSwitch={() => setSwitchOrgModalOpen(false)}
        />
      </Modal>
    </div>
  )
}
const menuContainerStyle: React.CSSProperties = {
  padding: 10,
  paddingRight: 20
}

const triggerButtonStyle: React.CSSProperties = {
  backgroundColor: ColorHex.RDY_FOREST,
  paddingTop: 10,
  paddingRight: 10,
  paddingLeft: 10,
  paddingBottom: 0,
  margin: 0,
  border: 'none',
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer'
}
const buttonHeaderTitle: React.CSSProperties = {
  ...triggerButtonStyle,
  backgroundColor: ColorHex.RDY_MALIBU,
  padding: 0,
  margin: 0,
  border: 'none',
  display: 'flex',
  flexDirection: 'row',
  cursor: 'default'
}

const dropdownMenuStyle: React.CSSProperties = {
  backgroundColor: ColorHex.RDY_MALIBU,
  borderRadius: 30,
  border: 'solid 1px',
  borderColor: ColorHex.RDY_FOREST
}

const menuStyle: React.CSSProperties = {
  color: ColorHex.RDY_MALIBU,
  fill: ColorHex.RDY_MALIBU,
  width: 30,
  height: 30,
  padding: 0,
  margin: 'auto'
}

const rdyActiveStyle: React.CSSProperties = {
  color: ColorHex.RDY_FOREST,
  fill: ColorHex.RDY_FOREST,
  width: 60,
  height: 60,
  padding: 0,
  paddingLeft: 10,
  margin: 0
}
const menuItemStyle: React.CSSProperties = {
  width: 150,
  padding: 5,
  border: 'none',
  backgroundColor: ColorHex.RDY_MALIBU,
  color: ColorHex.RDY_FOREST,
  fontFamily: 'Raw-Bold',
  fontSize: 16,
  cursor: 'pointer',
  justifyContent: 'center'
}

const versionMenuItemStyle: React.CSSProperties = {
  width: 100,
  fontFamily: 'Matter-Regular',
  padding: 5,
  fontSize: 12,
  borderRadius: 5,
  border: 'none',
  backgroundColor: ColorHex.RDY_MALIBU,
  color: ColorHex.RDY_FOREST,
  justifyContent: 'center'
}
