import {
  TopModifiersByMerchantDocument,
  UpdateModifierInput,
  useUpdateModifierOptionMutation
} from '../graphql/types.generated'
import { useToast } from './useToast'

export function useUpdateModifier(merchantId: string) {
  const toast = useToast()
  const [execute, { loading }] = useUpdateModifierOptionMutation()

  async function updateModifier(input: UpdateModifierInput, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { input },
        refetchQueries: [
          {
            query: TopModifiersByMerchantDocument,
            variables: { merchantId }
          }
        ]
      })

      if (res.data?.updateModifier) {
        toast.success('Modifier updated')
        onSuccess()
      } else {
        throw new Error(`Could not update modifier: ${res.errors}`)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  return { updateModifier, loading }
}
