import { useWindowSize } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useViewMerchantQuery } from 'graphql/types.generated'
import React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { OrderRerouter } from '../components/OrderDetails/OrderRerouter'
import { BillingContainer } from './components/BillingContainer'
import { CafeHomeContainer } from './components/CafeHomeContainer'
import { GoLiveTracker } from './components/GoLiveTracker/GoLiveTracker'
import { MenuContainer } from './components/MenuContainer'
import { ReportContainer } from './components/ReportContainer'

export function ViewMerchant() {
  const { merchantId, organizationId } = useParams()
  const { data, loading } = useViewMerchantQuery({
    variables: {
      id: merchantId!
    },
    fetchPolicy: 'cache-first'
  })

  const [windowSize] = useWindowSize()

  if (!merchantId || !organizationId) {
    return <></>
  }

  if (loading) {
    return <CircularProgress />
  }

  if (!data?.merchant) {
    return <Alert color='error'>Cafe not found.</Alert>
  }

  return (
    <>
      {!data.merchant?.active && windowSize === 'large' && (
        <GoLiveTracker organizationId={organizationId} merchantId={merchantId} />
      )}

      <Routes>
        <Route
          path={'reports/*'}
          element={<ReportContainer merchantId={merchantId} organizationId={organizationId} />}
        />
        <Route path={'order/:orderId'} element={<OrderRerouter />} />
        <Route
          path={'home/*'}
          element={<CafeHomeContainer merchantId={merchantId} organizationId={organizationId} />}
        />
        <Route
          path={'billing/*'}
          element={<BillingContainer merchantId={merchantId} organizationId={organizationId} />}
        />
        <Route path={'menu/*'} element={<MenuContainer merchantId={merchantId} organizationId={organizationId} />} />
        <Route
          path={'*'}
          element={<Navigate to={`/organizations/${organizationId}/merchants/${merchantId}/home`} replace={true} />}
        />
      </Routes>
    </>
  )
}
