import { Maybe, RecurringInterval, SubscriptionStatus } from 'graphql/types.generated'
import { PlatformPricingInfo, SubscriptionProductInfo } from 'models/Subscriptions'

import { formatUsCentsAsUsDollar } from './formatting'

export function getSubscriptionStatusFriendlyName(subscriptionStatus: Maybe<SubscriptionStatus>): String {
  switch (subscriptionStatus) {
    case SubscriptionStatus.Current:
      return 'Current'
    case SubscriptionStatus.Complete:
      return 'Complete'
    case SubscriptionStatus.Cancelled:
      return 'Cancelled'
    case SubscriptionStatus.PastDue:
      return 'Past Due'
    case SubscriptionStatus.PendingPayment:
      return 'Pending Payment'
    default:
      return 'No Plan Selected'
  }
}

export function formatRecurringInterval(recurringInterval?: RecurringInterval) {
  if (recurringInterval === RecurringInterval.Year) {
    return 'Yearly'
  }
  if (recurringInterval === RecurringInterval.Month) {
    return 'Monthly'
  }
  return ''
}

export function formatRecurringIntervalUnits(recurringInterval?: RecurringInterval) {
  if (recurringInterval === RecurringInterval.Year) {
    return 'Year'
  }
  if (recurringInterval === RecurringInterval.Month) {
    return 'Month'
  }
  return ''
}

export function formatSubscriptionPlanCost(subscriptionPlan: SubscriptionProductInfo) {
  return `${formatUsCentsAsUsDollar(subscriptionPlan.costUsCents!!)} / ${formatRecurringIntervalUnits(
    subscriptionPlan.recurringInterval!!
  )}`
}

export function formatPlatformPricing(termPricing: boolean, platformPricing: PlatformPricingInfo) {
  return termPricing
    ? `${formatUsCentsAsUsDollar(platformPricing.subscriptionProduct?.costUsCents!!)} / Month for ${
        platformPricing.subscriptionTerm
      } Months`
    : formatUsCentsAsUsDollar(platformPricing.instantPriceUsCents!!)
}
