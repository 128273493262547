import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Typography } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { TextDisplay } from 'components/layout/TextDisplay'
import { PayoutType, useMerchantPayoutsQuery } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { MerchantPayoutInfo } from 'models/Payouts'
import React from 'react'
import { useParams } from 'react-router-dom'
import { readableDateFromInstant, readableDateTimeFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { requestAmericanoHttpDownload } from 'util/httpClient'
import { MerchantReferralsDataGrid } from 'views/merchants/components/Transactions/MerchantReferralsDataGrid'
import { OrdersDataGrid } from 'views/merchants/components/Transactions/OrdersDataGrid'
import { OrderSummaryDataGrid } from 'views/merchants/components/Transactions/OrderSummaryDataGrid'
import {
  generateSummaryOfOrders,
  merchantReferralToRow,
  orderReportingDetailsToOrderRow
} from 'views/merchants/components/Transactions/transactionTransforms'

export interface PayoutSummary {
  payoutType: string
  payoutTypeFriendlyName: string
  organizationName: string
  organizationId: string
  payoutPeriodEnd: number
  invoiceDate: number
  totalPaidOut: number
}

export function PayoutDetails() {
  const { merchantPayoutIds } = useParams()
  const toast = useToast()
  const { data, loading } = useMerchantPayoutsQuery({
    variables: {
      ids: merchantPayoutIds!.split(',')
    }
  })

  function getPayoutTypeFriendlyName(payoutType: PayoutType) {
    switch (payoutType) {
      case PayoutType.Discounts:
        return 'Discount Reimbursement'
      case PayoutType.MerchantReferrals:
        return 'Discount Reimbursement'
      case PayoutType.Tokens:
        return 'Token Reimbursement'
      default:
        return 'Orders'
    }
  }

  function generateSummaryData(payoutDetails?: MerchantPayoutInfo[]) {
    if (!payoutDetails?.length) {
      return null
    }

    let firstPayout = payoutDetails.concat([]).sort((a, b) => a.createdOn - b.createdOn)[0]
    let totalPaidOut = payoutDetails.reduce((prev, next) => {
      if (!prev) {
        return next.payoutAmount!!
      }
      prev += next.payoutAmount!!
      return prev
    }, 0)

    return {
      payoutType: firstPayout.payoutType!!,
      payoutTypeFriendlyName: getPayoutTypeFriendlyName(firstPayout.payoutType!!),
      invoiceDate: firstPayout.createdOn,
      organizationName: firstPayout.merchant!!.organization!!.name!!,
      organizationId: firstPayout.merchant!!.organization!!.id,
      payoutPeriodEnd: firstPayout.periodEndDate!!,
      totalPaidOut: totalPaidOut
    } as PayoutSummary
  }

  const summaryData = generateSummaryData(data?.merchantPayouts)
  function handleReportClick() {
    requestAmericanoHttpDownload(
      `/payout-report/organization/${summaryData!.organizationId}?payoutIds=${merchantPayoutIds}&payoutType=${
        summaryData!.payoutType
      }`,
      'PayoutReport.pdf'
    )
    toast.success(`Downloading your report...`)
  }

  if (loading)
    return (
      <div style={loadingStyle}>
        <CircularProgress />
        <Typography>Generating your report</Typography>
      </div>
    )

  return (
    <>
      {!!summaryData && (
        <>
          <Section
            title='Summary'
            addon={
              <Button
                onClick={handleReportClick}
                endIcon={<PictureAsPdfIcon />}
                label='Download Payout Report'
                style={{ margin: 'auto' }}
              />
            }
          >
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <TextDisplay title='Payout Type' titleVariant='subtitle2'>
                <Typography>{summaryData.payoutTypeFriendlyName}</Typography>
              </TextDisplay>
              <TextDisplay title='Organization' titleVariant='subtitle2'>
                <Typography>{summaryData.organizationName}</Typography>
              </TextDisplay>
              <TextDisplay title='Payout Period End' titleVariant='subtitle2'>
                <Typography>{readableDateTimeFromInstant(summaryData.payoutPeriodEnd)}</Typography>
              </TextDisplay>
              <TextDisplay title='Invoice Date' titleVariant='subtitle2'>
                <Typography>{readableDateTimeFromInstant(summaryData.invoiceDate)}</Typography>
              </TextDisplay>
              <TextDisplay title='Total Paid Out' titleVariant='subtitle2'>
                <Typography>{formatUsCentsAsUsDollar(summaryData.totalPaidOut)}</Typography>
              </TextDisplay>
            </div>
          </Section>
        </>
      )}
      {summaryData &&
        data?.merchantPayouts.map((payout) => {
          const orders =
            (payout.orders || payout.tokenOrders || payout.discountOrders)?.map((o) =>
              orderReportingDetailsToOrderRow(o!)
            ) || []
          const referrals = payout.referrals?.map((r) => merchantReferralToRow(r!)) || []
          const orderSummary = generateSummaryOfOrders(orders, referrals)
          return (
            <Section title={`${summaryData.payoutTypeFriendlyName} Payouts for ${payout.merchant.name}`}>
              <Section
                title={`${readableDateFromInstant(payout.periodStartDate)} through ${readableDateFromInstant(
                  payout.periodEndDate
                )}`}
                variant='subsection'
              >
                {!!payout.stripePaymentUrl && (
                  <TextDisplay titleVariant='subtitle2' title='Stripe Payment:'>
                    <a href={payout.stripePaymentUrl} target='_blank' rel='noopener noreferrer'>
                      View Payment
                    </a>
                  </TextDisplay>
                )}
                {payout.payoutType !== PayoutType.MerchantReferrals && (
                  <>
                    <Section title='Order Summary' variant='subsection'>
                      <OrderSummaryDataGrid orderSummary={orderSummary} loading={loading} />
                    </Section>
                    {!!orders.length && (
                      <Section title='Order Details' variant='subsection'>
                        <OrdersDataGrid
                          orders={orders}
                          loading={loading}
                          organizationId={summaryData.organizationId}
                          merchantId={payout.merchant.id}
                        />
                      </Section>
                    )}
                  </>
                )}
                {payout.payoutType === PayoutType.MerchantReferrals && (
                  <MerchantReferralsDataGrid referrals={referrals} loading={loading} />
                )}
              </Section>
            </Section>
          )
        })}
    </>
  )
}

const loadingStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}
