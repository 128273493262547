import { CircularProgress, MenuItem } from '@material-ui/core'
import { React } from '@ungap/global-this'
import { SelectCategoryFragment } from 'graphql/types.generated'

import { SelectField, SelectFieldProps } from '../../../../components/form/SelectField'

interface CategorySelectProps extends SelectFieldProps {
  label: string
  name: string
  defaultValue?: ID
  categories: SelectCategoryFragment[]
  loading: boolean
}

export function CategorySelect(props: CategorySelectProps) {
  const { loading, categories, ...rest } = props

  if (loading) {
    return <CircularProgress title='Loading categories' />
  }

  return (
    <SelectField {...rest}>
      {categories.length && (
        <MenuItem value=''>
          <em>Select a category</em>
        </MenuItem>
      )}
      {categories.map((category) => (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      ))}
      {!categories.length && (
        <MenuItem value=''>
          <em>No categories found</em>
        </MenuItem>
      )}
    </SelectField>
  )
}
