import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { Navigate, Route, Routes } from 'react-router-dom'
import { OrderDetails } from 'views/merchants/components/OrderDetails/OrderDetails'
import { ProductSales } from 'views/merchants/components/Transactions/ProductSales'
import { TransactionSummary } from 'views/merchants/components/Transactions/TransactionSummary'
import { OrganizationPayouts } from 'views/organizations/components/OrganizationPayouts'
import { PayoutDetails } from 'views/organizations/components/PayoutDetails'

interface ReportContainerProps {
  merchantId: ID
  organizationId: ID
}

export function ReportContainer(props: ReportContainerProps) {
  const { merchantId, organizationId } = props
  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/reports/transactions`,
          paths: [
            `/organizations/:organizationId/merchants/:merchantId/reports/transactions`,
            `/organizations/:organizationId/merchants/:merchantId/reports/order/*`
          ],
          text: 'Orders'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/reports/productSales`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/reports/productSales`],
          text: 'Product Sales'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/reports/payouts`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/reports/payouts/*`],
          text: 'Payouts'
        }
      ]}
    >
      <Routes>
        <Route path={`transactions`} element={<TransactionSummary merchantId={merchantId} />} />
        <Route path={`order/:orderId`} element={<OrderDetails />} />
        <Route path={`productSales`} element={<ProductSales merchantId={merchantId} />} />
        <Route path={`payouts/payoutDetails/:merchantPayoutIds`} element={<PayoutDetails />} />
        <Route
          path={`payouts/*`}
          element={
            <OrganizationPayouts
              organizationId={organizationId}
              payoutDetailBaseUrl={`/organizations/${organizationId}/merchants/${merchantId}/reports/payouts/payoutDetails/`}
            />
          }
        />
        <Route path={`*`} element={<Navigate to={'Transactions'} replace={true} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
