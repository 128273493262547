import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import { useController } from 'react-hook-form'

import { useFieldError } from '../../hooks/useFieldError'

type DateFieldProps = Omit<KeyboardDatePickerProps, 'onChange' | 'value'> & {
  name: string
  defaultValue?: Date
}

export function DateField(props: DateFieldProps) {
  const { name, defaultValue, ...rest } = props
  const error = useFieldError<any>({ name })

  const {
    field: { ref, onChange, value, ...fieldRest }
  } = useController({
    name,
    defaultValue: defaultValue || new Date()
  })

  return (
    <KeyboardDatePicker
      {...fieldRest}
      {...rest}
      format='MM/dd/yyyy'
      margin='normal'
      disableToolbar
      value={typeof value !== 'undefined' ? new Date(value) : undefined}
      inputRef={ref}
      inputVariant='outlined'
      inputProps={{ disabled: true }}
      onChange={(date) => {
        onChange(date)
      }}
      error={!!error}
      helperText={error?.message}
    />
  )
}
