import { CircularProgress } from '@material-ui/core'
import { useUpdatePersonalInfoMutation, useUserInfoQuery } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React from 'react'

import { UserProfileForm } from './UserProfileForm'
import { UserProfileFormModel } from './UserProfileValidator'

interface UpdateUserProfileProps {
  onClose: () => void
}

export function UpdateUserProfile(props: UpdateUserProfileProps) {
  const { onClose } = props
  const toast = useToast()
  const [execute] = useUpdatePersonalInfoMutation()
  const { data, loading } = useUserInfoQuery({ fetchPolicy: 'cache-first' })

  async function handleSubmit(values: UserProfileFormModel) {
    try {
      const res = await execute({
        variables: {
          input: {
            contactEmail: values.contactEmail,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber
          }
        }
      })
      if (res.data?.updateMyProfile) {
        toast.success('Profile updated')
        onClose()
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  function handleCancel() {
    onClose()
  }

  return (
    <>
      {loading && <CircularProgress size={14}></CircularProgress>}
      {!loading && data && data.me && (
        <UserProfileForm onCancel={handleCancel} onSubmit={handleSubmit} initialValues={data.me.profile} />
      )}
    </>
  )
}
