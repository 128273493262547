import { Section } from '@bloom-coffee/steamed-milk'
import { Box, Card, CardContent, Grid } from '@material-ui/core'
import { TextDisplay } from 'components/layout/TextDisplay'
import { SubscriptionInvoiceInfo } from 'models/Subscriptions'
import { readableDateFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'

interface SubscriptionInvoicesProps {
  subscriptionInvoices: SubscriptionInvoiceInfo[]
}

export function SubscriptionInvoices(props: SubscriptionInvoicesProps) {
  const { subscriptionInvoices } = props
  const sortedInvoices = [...subscriptionInvoices].sort(function (a, b) {
    if (a.createdOn!! > b.createdOn!!) return 1
    if (a.createdOn!! < b.createdOn!!) return -1
    return 0
  })
  return (
    <>
      {subscriptionInvoices.length > 0 && (
        <Grid container spacing={8} alignItems='center'>
          <Grid item xs={8}>
            <Section variant='subsection' title='Subscription Invoices'>
              {sortedInvoices.map((invoice, index) => (
                <Card variant='outlined' key={index}>
                  <CardContent>
                    <Box flexDirection='row' display='flex' flexWrap='wrap'>
                      <TextDisplay titleVariant='subtitle2' title='Created Date:'>
                        {readableDateFromInstant(invoice.createdOn!)}
                      </TextDisplay>
                      <TextDisplay titleVariant='subtitle2' title='Finalized Date:'>
                        {readableDateFromInstant(invoice.finalizedOn!)}
                      </TextDisplay>
                      <TextDisplay titleVariant='subtitle2' title='Paid Date:'>
                        {readableDateFromInstant(invoice.paidOn!)}
                      </TextDisplay>
                      <TextDisplay titleVariant='subtitle2' title='Invoice Status:'>
                        {`${invoice.status[0].toUpperCase()}${invoice.status.slice(1)}`}
                      </TextDisplay>
                      <TextDisplay titleVariant='subtitle2' title='Total:'>
                        {formatUsCentsAsUsDollar(invoice.costUsCents!)}
                      </TextDisplay>
                      {invoice.subscriptionInvoiceLines!.length > 0 && (
                        <TextDisplay titleVariant='subtitle2' title='Invoice Items:'>
                          <Box flexDirection='column' display='flex' flexWrap='wrap'>
                            {invoice.subscriptionInvoiceLines!.map((invoiceLine, index) => (
                              <Box key={index}>{`${invoiceLine.quantity!!} X ${
                                invoiceLine.subscriptionProduct!!.name
                              }: ${formatUsCentsAsUsDollar(invoiceLine.costUsCents!)}`}</Box>
                            ))}
                          </Box>
                        </TextDisplay>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Section>
          </Grid>
        </Grid>
      )}
    </>
  )
}
