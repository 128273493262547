import { Button, Color, Section } from '@bloom-coffee/steamed-milk'
import { Box, CircularProgress, FormControl, Typography } from '@material-ui/core'
import { useGetFreshLocationsQuery } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { FreshBrand, getFreshBrands } from 'util/freshToGoUtil'

import { FreshToGoLogin } from './FreshToGoLogin'
import { ImportFreshLocations } from './ImportFreshLocations'

export interface ImportFreshToGoProps {
  onCancel: () => void
  onSuccess: () => void
  organizationId: string
}

export function ImportFreshToGoCafes(props: ImportFreshToGoProps) {
  const { onCancel, onSuccess, organizationId } = props

  const toast = useToast()
  const [token, setToken] = useState<string | null>()
  const [brandId, setBrandId] = useState<string | null>()
  const [brands, setBrands] = useState<FreshBrand[]>([])
  const [loadingBrands, setLoadingBrands] = useState(false)

  const { data: locationData, loading: loadingLocations } = useGetFreshLocationsQuery({
    variables: { organizationId, brandId: brandId || '', token: token || '' },
    fetchPolicy: 'cache-first',
    skip: !brandId || !token
  })

  async function loggedIn(tokenResponse: string) {
    setToken(tokenResponse)
    loadBrands(tokenResponse)
  }

  async function loadBrands(userToken: string) {
    setLoadingBrands(true)
    try {
      let brandResponse = await getFreshBrands(userToken)
      setBrands(brandResponse)
      if (brandResponse.length === 1) {
        setBrandId(brandResponse[0].id)
      }
    } catch (e: any) {
      toast.error('Unable to load Brands from Fresh')
    } finally {
      setLoadingBrands(false)
    }
  }

  return (
    <>
      {!token && <FreshToGoLogin onCancel={onCancel} onSuccess={loggedIn} />}
      {!!token && !!brands.length && !brandId && (
        <Section title='Which Fresh Brand is your Cafe under?' variant='subsection'>
          <FormControl style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {brands.map((brand) => (
              <Button key={brand.id} onClick={() => setBrandId(brand.id)} label={brand.name} style={brandButtonStyle} />
            ))}
          </FormControl>
        </Section>
      )}
      {(!!loadingBrands || !!loadingLocations) && (
        <Box display='flex' alignItems='center' flexDirection='column' height={100}>
          {!!loadingBrands && <Typography>Fetching Fresh Brands</Typography>}
          {!!loadingLocations && <Typography>Fetching Fresh Locations</Typography>}
          <CircularProgress />
        </Box>
      )}

      {!!locationData && !!token && !!brandId && (
        <ImportFreshLocations
          onCancel={onCancel}
          onSuccess={onSuccess}
          organizationId={organizationId}
          token={token!}
          brandId={brandId!}
          locations={locationData.getFreshLocations}
        ></ImportFreshLocations>
      )}
    </>
  )
}

const brandButtonStyle: React.CSSProperties = {
  margin: 5,
  backgroundColor: Color.RDY_MALIBU
}
