import { Button } from '@bloom-coffee/steamed-milk'
import { DialogContentText } from '@material-ui/core'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { OrderDetailsQueryDocument, useRefundOrderMutation } from 'graphql/types.generated'
import { OrderInfo } from 'models/Order'
import React, { useEffect, useState } from 'react'

interface RefundOrderProps {
  order: OrderInfo
}

export function RefundOrder(props: RefundOrderProps) {
  const { order } = props

  const [tokenRefundDelta, setTokenRefundDelta] = useState(0)
  const [paymentRefundDelta, setPaymentRefundDelta] = useState(0)
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const [execute, { loading: refundingOrder }] = useRefundOrderMutation()

  useEffect(() => {
    if (order.transactions?.length) {
      let totalTokenCharge = 0
      let totalPaymentCharge = 0
      let totalTokenRefunded = 0
      let totalPaymentRefunded = 0

      for (let transaction of order.transactions) {
        totalPaymentCharge +=
          transaction.subtotalUsCents! + transaction.taxUsCents! + transaction.tipUsCents! + transaction.feeUsCents!
        totalTokenCharge += transaction.tokenValue

        if (transaction.refunds) {
          for (let refund of transaction.refunds) {
            totalPaymentRefunded += refund.amountUsCents
            totalTokenRefunded += refund.tokenValue
          }
        }
      }

      setPaymentRefundDelta(totalPaymentCharge - totalPaymentRefunded)
      setTokenRefundDelta(totalTokenCharge - totalTokenRefunded)
    }
  }, [order])

  async function confirmRefundOrder() {
    await execute({
      variables: { id: order.id },
      refetchQueries: [{ query: OrderDetailsQueryDocument, variables: { id: order.id } }]
    })
    setConfirmationOpen(false)
  }

  return (
    <div>
      {(tokenRefundDelta > 0 || paymentRefundDelta > 0) && (
        <div>
          <Button
            label={refundingOrder ? 'Loading...' : 'Refund Full Order'}
            onClick={() => setConfirmationOpen(true)}
            disabled={refundingOrder}
          />
        </div>
      )}
      <ConfirmationDialog
        title='Refund Entire Order'
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={confirmRefundOrder}
        loading={refundingOrder}
      >
        <DialogContentText>Are you sure you want to refund this entire order?</DialogContentText>
      </ConfirmationDialog>
    </div>
  )
}
