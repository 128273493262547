import { ApolloCache, FetchResult } from '@apollo/client'

import {
  ArchiveOrganizationPaymentAccountMutation,
  useArchiveOrganizationPaymentAccountMutation,
  ValidatePaymentAccountDocument
} from '../graphql/types.generated'
import { useToast } from './useToast'

export function useArchiveOrganizationPaymentAccount() {
  const toast = useToast()
  const [execute, { loading }] = useArchiveOrganizationPaymentAccountMutation()

  function updateCache(
    cache: ApolloCache<ArchiveOrganizationPaymentAccountMutation>,
    mutationResult: FetchResult<ArchiveOrganizationPaymentAccountMutation>
  ) {
    const idToRemove = mutationResult.data?.archiveOrganizationPaymentAccount.id

    if (idToRemove) {
      cache.writeQuery({
        query: ValidatePaymentAccountDocument,
        data: {
          validatePaymentAccount: {
            hasAccount: false,
            chargesEnabled: false
          }
        },
        variables: {
          organizationId: idToRemove,
          merchantId: null
        }
      })
    }
  }
  async function archive(organizationId: string, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { id: organizationId },
        update: updateCache
      })

      if (res.data?.archiveOrganizationPaymentAccount) {
        onSuccess()
      } else {
        throw new Error(`Could not archive organization payment account: ${res.errors}`)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  return { archive, loading }
}
