import { OrderItemSummaryFragment, OrderSummaryFragment } from 'graphql/types.generated'
import { OrderReportingDetailsInfo } from 'models/Order'

export interface OrderSummary {
  id: number
  startDate?: number
  endDate?: number
  totalTokens: number
  totalTokenValue: number
  totalTokenTipValue: number
  totalTokenFeeValue: number
  totalSubTotal: number
  totalTaxes: number
  totalTips: number
  totalCustomerFees: number
  totalMerchantFees: number
  totalOrders: number
  totalNetMerchantPayout: number
  totalReferralPayout: number
  totalReferrals: number
  totalMerchantDiscountFeeUsCents: number
  totalRdyPromotionDiscountValue: number
  totalRdyTokens: number
  totalOrganizationTokens: number
}

export interface OrderDetails {
  id: string
  date: number
  userId: string | null
  name: string
  tokens: number
  tokenValue: number
  tokenTipValue: number
  tokenFeeValue: number
  subTotal: number
  taxes: number
  tip: number
  customerFee: number
  merchantFee: number
  netMerchantPayout: number
  merchantDiscountFeeUsCents: number
  rdyPromotionDiscountValue: number
  rdyPromotionName: string
  rdyTokens: number
  organizationTokens: number
}

export interface MerchantReferralDetails {
  id: string
  referralDate: number
  payout: number
}

export function merchantReferralToRow(merchantReferral: any): MerchantReferralDetails {
  return {
    id: merchantReferral.id,
    referralDate: merchantReferral.createdOn,
    payout: 100
  }
}

export function generateSummaryOfOrders(
  orders: OrderDetails[],
  referrals: MerchantReferralDetails[],
  startDate?: number,
  endDate?: number
): OrderSummary {
  let summary = {
    id: 1,
    startDate: startDate,
    endDate: endDate,
    totalTokens: 0,
    totalSubTotal: 0,
    totalTaxes: 0,
    totalTips: 0,
    totalCustomerFees: 0,
    totalMerchantFees: 0,
    totalOrders: 0,
    totalNetMerchantPayout: 0,
    totalTokenValue: 0,
    totalReferralPayout: 0,
    totalReferrals: 0,
    totalTokenTipValue: 0,
    totalTokenFeeValue: 0,
    totalMerchantDiscountFeeUsCents: 0,
    totalRdyPromotionDiscountValue: 0,
    totalRdyTokens: 0,
    totalOrganizationTokens: 0
  } as OrderSummary

  for (let order of orders) {
    summary.totalTokens += order.tokens
    summary.totalSubTotal += order.subTotal
    summary.totalTaxes += order.taxes
    summary.totalTips += order.tip
    summary.totalCustomerFees += order.customerFee
    summary.totalMerchantFees += order.merchantFee
    summary.totalNetMerchantPayout += order.netMerchantPayout
    summary.totalTokenValue += order.tokenValue
    summary.totalTokenTipValue += order.tokenTipValue
    summary.totalTokenFeeValue += order.tokenFeeValue
    summary.totalRdyPromotionDiscountValue += order.rdyPromotionDiscountValue
    summary.totalMerchantDiscountFeeUsCents += order.merchantDiscountFeeUsCents
    summary.totalRdyTokens += order.rdyTokens
    summary.totalOrganizationTokens += order.organizationTokens
    summary.totalOrders++
  }

  for (let referral of referrals) {
    summary.totalReferralPayout += referral.payout
    summary.totalReferrals++
  }
  return summary
}

export function orderReportingDetailsToOrderRow(order: OrderReportingDetailsInfo): OrderDetails {
  return {
    id: order.id,
    date: order.date,
    userId: order.userId,
    name: `${order.firstName} ${order.lastName}`,
    tokens: order.tokens,
    subTotal: order.subTotal,
    taxes: order.taxes,
    tip: order.tip,
    customerFee: order.customerFee,
    merchantFee: order.merchantFee!,
    netMerchantPayout: order.netMerchantPayout,
    tokenValue: order.tokenValue,
    tokenTipValue: order.tokenTipValue,
    tokenFeeValue: order.tokenFeeValue,
    merchantDiscountFeeUsCents: order.merchantDiscountFeeUsCents,
    rdyPromotionDiscountValue: order.rdyPromotionDiscountValue,
    rdyPromotionName: order.rdyPromotionName,
    rdyTokens: order.rdyPaidTokenCount,
    organizationTokens: order.organizationPaidTokenCount
  }
}
export function generateProductSummaries(orders: OrderSummaryFragment[]): ProductSummary[] {
  const orderItems = orders.map((o) => o.items).flat()

  return orderItems.reduce((productSummaries: ProductSummary[], orderItem: OrderItemSummaryFragment) => {
    let productSummary = productSummaries.filter(
      (summary) => summary.orderItem.orderableProduct.id === orderItem.orderableProduct.id
    )[0]
    if (!productSummary) {
      productSummary = {
        id: productSummaries.length,
        orderItem: orderItem,
        categoryName: orderItem.orderableProduct.category.name,
        productName: orderItem.orderableProduct.name,
        sales: 0,
        sold: 0
      }
      productSummaries.push(productSummary)
    }
    productSummary.sales += orderItem.subtotalChargeUsCents
    productSummary.sold += 1
    return productSummaries
  }, [])
}

export function generateCategorySummaries(itemSummaries: ProductSummary[]): CategorySummary[] {
  return itemSummaries.reduce((categorySummaries: CategorySummary[], productSummary: ProductSummary) => {
    const categoryName = productSummary.orderItem.orderableProduct.category.name

    let categorySummary = categorySummaries.filter(
      (summary) => summary.categoryName === productSummary.orderItem.orderableProduct.category.name
    )[0]
    if (!categorySummary) {
      categorySummary = {
        id: categorySummaries.length,
        categoryName,
        products: [],
        sales: 0,
        sold: 0
      }
      categorySummaries.push(categorySummary)
    }
    categorySummary.sales += productSummary.sales
    categorySummary.sold += productSummary.sold
    categorySummary.products.push(productSummary)
    return categorySummaries
  }, [])
}

export interface CategorySummary {
  id: number
  categoryName: string
  products: ProductSummary[]
  sales: number
  sold: number
}

export interface ProductSummary {
  id: number
  productName: string
  categoryName: string
  orderItem: OrderItemSummaryFragment
  sales: number
  sold: number
}
