import * as yup from 'yup'

import { transform } from '../../../../validation/transform'

export const subscriptionPlanSchema = yup.object({
  id: yup.string().optional(),
  name: yup.string().label('Name').required(),
  recurringInterval: yup.string().label('Recurring Interval').required(),
  costUsCents: yup
    .number()
    .transform(transform.nullable)
    .label('Price')
    .required()
    .typeError('Price needs to be a number')
})

export type SubscriptionPlanFormModel = yup.InferType<typeof subscriptionPlanSchema>
