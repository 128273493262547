import { Section } from '@bloom-coffee/steamed-milk'
import { Card, CardContent, CircularProgress, Typography } from '@material-ui/core'
import { TextDisplay } from 'components/layout/TextDisplay'
import { useOrderDetailsQueryQuery } from 'graphql/types.generated'
import { OrderTransactionRefundInfo } from 'models/Order'
import { useParams } from 'react-router-dom'
import { readableDateTimeFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'

import { RefundOrder } from './RefundOrder'
import { RefundOrderItem } from './RefundOrderItem'
import { RefundTransactionDetails } from './RefundTransactionDetails'

export function OrderDetails() {
  const { orderId } = useParams()

  const { data, loading } = useOrderDetailsQueryQuery({
    variables: {
      id: orderId!
    }
  })

  if (loading) {
    return <CircularProgress />
  }

  const addedToQueueEvent = data?.order.events.find((e) => e.name === 'ADDED_TO_QUEUE')
  const startedEvent = data?.order.events.find((e) => e.name === 'PREPARING')
  const completedEvent = data?.order.events.find((e) => e.name === 'ITEMS_CREATED')
  const tokenValue = data?.order?.transactions?.length ? data?.order.transactions[0].tokenValueUsCents : 0
  const refunds =
    (data?.order?.transactions?.flatMap((t) => t.refunds).filter((r) => !!r) as OrderTransactionRefundInfo[]) ||
    ([] as OrderTransactionRefundInfo[])

  return (
    <>
      {data?.order && (
        <div>
          <Section title='Order Details'>
            <div style={subsectionStyle}>
              <TextDisplay titleVariant='subtitle2' title='Order Id:'>
                {data.order.id}
              </TextDisplay>
              <TextDisplay titleVariant='subtitle2' title='Customer Name:'>
                {`${data.order.user.profile.firstName} ${data.order.user.profile.lastName}`}
              </TextDisplay>
              <TextDisplay titleVariant='subtitle2' title='Created On:'>
                {readableDateTimeFromInstant(data?.order.createdOn)}
              </TextDisplay>
              {!!addedToQueueEvent && (
                <TextDisplay titleVariant='subtitle2' title='Added To Queue:'>
                  {readableDateTimeFromInstant(addedToQueueEvent.createdOn)}
                </TextDisplay>
              )}
              {!!startedEvent && (
                <TextDisplay titleVariant='subtitle2' title='Started Preparing:'>
                  {readableDateTimeFromInstant(startedEvent.createdOn)}
                </TextDisplay>
              )}
              {!!completedEvent && (
                <TextDisplay titleVariant='subtitle2' title='Completed:'>
                  {readableDateTimeFromInstant(completedEvent.createdOn)}
                </TextDisplay>
              )}
            </div>
            <RefundOrder order={data.order} />
          </Section>

          {!!data.order?.transactions?.length && (
            <Section title='Transaction Details'>
              <div style={subsectionStyle}>
                <TextDisplay titleVariant='subtitle2' title='Sub Total:'>
                  {formatUsCentsAsUsDollar(data.order.transactions[0].subtotalUsCents)}
                </TextDisplay>
                <TextDisplay titleVariant='subtitle2' title='Taxes:'>
                  {formatUsCentsAsUsDollar(data.order.transactions[0].taxUsCents)}
                </TextDisplay>
                <TextDisplay titleVariant='subtitle2' title='Tip:'>
                  {formatUsCentsAsUsDollar(data.order.transactions[0].tipUsCents)}
                </TextDisplay>
                <TextDisplay titleVariant='subtitle2' title='Customer Fee:'>
                  {formatUsCentsAsUsDollar(data.order.transactions[0].feeUsCents)}
                </TextDisplay>
                <TextDisplay titleVariant='subtitle2' title='Merchant Fee:'>
                  {formatUsCentsAsUsDollar(data.order.transactions[0].merchantFeeUsCents)}
                </TextDisplay>
                {!!data.order.transactions[0].tokenValue && (
                  <TextDisplay titleVariant='subtitle2' title='Tokens:'>
                    {data.order.transactions[0].tokenValue}
                  </TextDisplay>
                )}
                {!!tokenValue && (
                  <TextDisplay titleVariant='subtitle2' title='Token Value:'>
                    {formatUsCentsAsUsDollar(tokenValue)}
                  </TextDisplay>
                )}
                {!!data.order.transactions[0].tokenTipUsCents && (
                  <TextDisplay titleVariant='subtitle2' title='Token Tip:'>
                    {formatUsCentsAsUsDollar(data.order.transactions[0].tokenTipUsCents)}
                  </TextDisplay>
                )}
                {!!data.order.transactions[0].merchantTokenFeeUsCents && (
                  <TextDisplay titleVariant='subtitle2' title='Token Fee:'>
                    {formatUsCentsAsUsDollar(data.order.transactions[0].merchantTokenFeeUsCents)}
                  </TextDisplay>
                )}
                {!!data.order.transactions[0].discountUsCents && (
                  <TextDisplay titleVariant='subtitle2' title='Discount:'>
                    {formatUsCentsAsUsDollar(data.order.transactions[0].discountUsCents)}
                  </TextDisplay>
                )}
                {!!data.order.transactions[0].stripePaymentUrl && (
                  <TextDisplay titleVariant='subtitle2' title='Stripe Payment:'>
                    <a href={data.order.transactions[0].stripePaymentUrl} target='_blank' rel='noopener noreferrer'>
                      View Payment
                    </a>
                  </TextDisplay>
                )}
              </div>
            </Section>
          )}

          {!!refunds.length && (
            <Section title='Refund Transactions'>
              {refunds.map((refund: OrderTransactionRefundInfo) => (
                <RefundTransactionDetails refund={refund} key={refund.id} />
              ))}
            </Section>
          )}
          <Section title='Items'>
            <div style={subsectionStyle}>
              {data?.order.items.map((orderItem) => {
                return (
                  <div key={orderItem.id} style={{ margin: 10 }}>
                    <Card variant='outlined'>
                      <CardContent>
                        <Section title={orderItem.name} variant={'subsection'}>
                          <div style={subsectionStyle}>
                            <TextDisplay titleVariant='subtitle2' title='Sub Total'>
                              {!!orderItem.subtotalChargeUsCents && (
                                <Typography>{formatUsCentsAsUsDollar(orderItem.subtotalChargeUsCents)}</Typography>
                              )}
                              {!!orderItem.subtotalChargeTokens && (
                                <Typography>{`${orderItem.subtotalChargeTokens} Token(s)`}</Typography>
                              )}
                            </TextDisplay>
                            <TextDisplay titleVariant='subtitle2' title='Base Price'>
                              <Typography>{`${formatUsCentsAsUsDollar(orderItem.priceCents)} or ${
                                orderItem.priceTokens
                              } Token(s)`}</Typography>
                            </TextDisplay>
                          </div>
                        </Section>
                        <Section title='Modifiers' variant={'subsection'}>
                          <div style={subsectionStyle}>
                            {!orderItem.selectedModifiers && <Typography>None</Typography>}
                            {orderItem.selectedModifiers?.map((modifier) => {
                              return (
                                <TextDisplay
                                  titleVariant='subtitle2'
                                  title={modifier.originalTopModifier?.name}
                                  key={modifier.id}
                                >
                                  {`${modifier.originalModifier.name} - ${formatUsCentsAsUsDollar(
                                    modifier.priceCents
                                  )}`}
                                </TextDisplay>
                              )
                            })}
                          </div>
                        </Section>
                        <RefundOrderItem orderItem={orderItem} orderId={orderId!!} />
                      </CardContent>
                    </Card>
                  </div>
                )
              })}
            </div>
          </Section>
        </div>
      )}
    </>
  )
}

const subsectionStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}
