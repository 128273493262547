import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Grid, IconButton, Paper, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { TopModifierFragment } from 'graphql/types.generated'
import React, { useState } from 'react'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { UpdateModifierForm } from 'views/modifiers/UpdateModifier/UpdateModifierForm'

import { ModifierForProductFormModel } from './productValidator'

interface ModifierForProductFormSectionProps {
  defaultValues: Partial<ModifierForProductFormModel>
  onRemove: () => void
  onClose: (updatedModifier?: TopModifierFragment) => void
  isSynced?: boolean
}

export const ModifierForProductFormSection = (props: ModifierForProductFormSectionProps) => {
  const { defaultValues, onRemove, onClose, isSynced } = props
  const [isEditing, setIsEditing] = useState(false)

  function getPriceRange() {
    let prices = defaultValues.options?.map((o) => o.priceCents) ?? []
    let minPrice = Math.min(...prices)
    let maxPrice = Math.max(...prices)

    if (minPrice >= 0 && maxPrice >= 0) {
      return `${formatUsCentsAsUsDollar(minPrice)} - ${formatUsCentsAsUsDollar(maxPrice)}`
    }
  }
  return (
    <div style={{ marginBottom: 1, width: '100%', paddingLeft: 2 }}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={2}>
          <Typography>{defaultValues.name}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography>{defaultValues.options?.map((c) => c.name).join(', ')}</Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography>{getPriceRange()}</Typography>
        </Grid>

        <Grid item xs={1} style={{ textAlign: 'right' }}>
          <Typography variant='body2' color={defaultValues.active ? 'textPrimary' : 'primary'}>
            {defaultValues.active ? 'Active' : 'Inactive'}
          </Typography>
        </Grid>

        <Grid item xs={1} style={{ textAlign: 'right' }}>
          <IconButton onClick={() => setIsEditing(true)}>
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          <IconButton onClick={onRemove} disabled={isSynced}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      {isEditing && (
        <Paper style={{ paddingLeft: 30, paddingRight: 30 }}>
          <Section
            title={'Editing Modifier'}
            addon={<Button label='Close' theme='cancel' endIcon={<CloseIcon />} onClick={() => setIsEditing(false)} />}
            variant='subsection'
          >
            <UpdateModifierForm
              inlineEdit={true}
              modifierId={defaultValues.id!!}
              onClose={(updatedModifier?: TopModifierFragment) => {
                onClose(updatedModifier)
                setIsEditing(false)
              }}
            />
          </Section>
        </Paper>
      )}
    </div>
  )
}
