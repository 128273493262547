import { Section } from '@bloom-coffee/steamed-milk'
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@material-ui/core'
import { Maybe, ModifierChildSelectionType } from 'graphql/types.generated'
import { useFieldError } from 'hooks/useFieldError'
import { PropsWithChildren } from 'react'
import { useFormContext } from 'react-hook-form'

export interface ModifierTypeRadioProps {
  label: string
  name: string
  allowedChildSelectionTypes?: ModifierChildSelectionType[]
  defaultValue?: ModifierChildSelectionType
  disabled?: boolean
}

const defaultAllowedTypes = [ModifierChildSelectionType.MultiSelect, ModifierChildSelectionType.SingleSelect]

export function ModifierTypeRadio(props: PropsWithChildren<ModifierTypeRadioProps>) {
  const {
    allowedChildSelectionTypes = defaultAllowedTypes,
    defaultValue = allowedChildSelectionTypes[0],
    label,
    name,
    disabled
  } = props
  const error = useFieldError<any>({ name })
  const { register } = useFormContext()

  return (
    <FormControl>
      <Section title={label} variant='subsection'>
        <RadioGroup defaultValue={defaultValue}>
          {allowedChildSelectionTypes.map((allowedSelectionType: ModifierChildSelectionType) => {
            return (
              <FormControlLabel
                value={allowedSelectionType}
                label={selectionTypeToReadable(allowedSelectionType)}
                name={name}
                control={<Radio disabled={disabled} inputRef={register} />}
              />
            )
          })}
        </RadioGroup>
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      </Section>
    </FormControl>
  )
}

function selectionTypeToReadable(selectionType: Maybe<ModifierChildSelectionType>): string {
  switch (selectionType) {
    case ModifierChildSelectionType.MultiSelect:
      return 'Multiple Selections Allowed'
    case ModifierChildSelectionType.SingleSelect:
      return 'One Selection Allowed'
    default:
      return ''
  }
}
