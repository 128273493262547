import { Color } from '@bloom-coffee/steamed-milk'
import { useCafeValidationStatus } from 'hooks/useCafeValidationStatus/useCafeValidationStatus'
import { useNavigate } from 'react-router-dom'

import { GoLiveStep, GoLiveStepDetails } from './GoLiveStep'
interface GoLiveDashboardProps {
  merchantId: ID
  organizationId: ID
}
export function GoLiveTracker(props: GoLiveDashboardProps) {
  const { merchantId, organizationId } = props
  const navigate = useNavigate()
  const {
    areCafeDetailsComplete,
    isBillingComplete,
    isMenuComplete,
    areContactsSetup,
    isKdsComplete,
    goLive,
    setUpBilling,
    setUpContacts,
    setUpMenu,
    setUpKds,
    isReadyToGoLive
  } = useCafeValidationStatus(merchantId, organizationId)

  const goLiveSteps: GoLiveStepDetails[] = [
    {
      stepName: 'Cafe Details',
      stepDescription: 'The cafe’s name, address, and hours',
      setupAction: () => navigate(`/organizations/${organizationId}/merchants/${merchantId}/home/details`),
      required: true,
      paths: ['/organizations/:organizationId/merchants/:merchantId/home/details'],
      isComplete: areCafeDetailsComplete,
      disabled: false
    },
    {
      stepName: 'Cafe Contacts',
      stepDescription: 'The managers and baristas at your cafe',
      setupAction: setUpContacts,
      required: true,
      paths: ['/organizations/:organizationId/merchants/:merchantId/home/contacts'],
      isComplete: areContactsSetup,
      disabled: false
    },
    {
      stepName: 'Menu',
      stepDescription: 'The items, modifiers and pricing',
      setupAction: setUpMenu,
      required: true,
      paths: ['/organizations/:organizationId/merchants/:merchantId/menu/*'],
      isComplete: isMenuComplete,
      disabled: false
    },
    {
      stepName: 'Billing',
      stepDescription: 'The payout account and RDY subscription',
      setupAction: setUpBilling,
      required: true,
      paths: ['/organizations/:organizationId/merchants/:merchantId/billing/*'],
      isComplete: isBillingComplete,
      disabled: false
    },
    {
      stepName: 'Tablet',
      stepDescription: 'The device that will show the orders',
      setupAction: setUpKds,
      required: true,
      paths: ['/organizations/:organizationId/merchants/:merchantId/home/devices'],
      isComplete: isKdsComplete,
      disabled: false
    },
    {
      stepName: 'Go Live',
      stepDescription: 'Start taking orders!',
      setupAction: goLive,
      required: false,
      paths: [],
      isComplete: () => false,
      disabled: !isReadyToGoLive()
    }
  ]

  return (
    <div>
      <div style={trackerTitleStyle}>Complete each section in order to Go Live!</div>
      <div style={trackerStyle}>
        {goLiveSteps.map((step, index) => (
          <GoLiveStep key={index} stepDetails={step} index={index} lastStep={goLiveSteps.length - 1 === index} />
        ))}
      </div>
      <div style={relativePosition}>
        <div style={trackerBackground}></div>
      </div>
    </div>
  )
}

const buttonScale = 50
const trackerTitleStyle: React.CSSProperties = {
  paddingTop: 10,
  textAlign: 'center',
  fontFamily: 'Raw-Bold',
  fontSize: 18,
  color: Color.RDY_RED
}
const trackerBackground: React.CSSProperties = {
  width: 400,
  height: 90,
  borderRadius: 25,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: -1
}

const relativePosition: React.CSSProperties = {
  position: 'relative',
  width: 0,
  height: 0
}

const trackerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: 5,
  height: buttonScale + 5
}
