import { useAuth0 } from '@auth0/auth0-react'

import { HeaderTitleBar } from './HeaderTitleBar'
export function OnboardingHeader() {
  const user = useAuth0()
  return (
    <div>
      <HeaderTitleBar title={`Welcome to RDY ${user.user?.name}`} subTitle={` Let's get your cafe RDY!`} />
    </div>
  )
}
