import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { Requisition } from './models'
import { RequisitionCustomerDetails } from './RequisitionCustomerDetails'
import { RequisitionPurchaseContainer } from './RequisitionPurchaseContainer'

interface RequisitionContainerProps {
  requisition: Requisition
}
export const RequisitionContainer = (props: RequisitionContainerProps) => {
  const { requisition } = props
  const purchases = requisition.purchases?.slice() || []
  const sortedPurchases = purchases.sort((p1, p2) => {
    const p1C = p1.createdOn || 0
    const p2c = p2.createdOn || 0
    if (p1C > p2c) return -1
    if (p1C < p2c) return 1
    return 0
  })

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Text variant='header2'>Customer</Text>
        <RequisitionCustomerDetails requisition={requisition} />
      </div>
      <div style={{ height: 1, backgroundColor: Color.GREY_600, marginTop: 8, marginBottom: 8 }} />
      <div style={{ marginBottom: 8 }}>
        <Text variant='header2'>Purchases</Text>
        <div style={{ paddingLeft: 8 }}>
          {sortedPurchases.map((p, i) => (
            <div key={`${p.id}.${i}`} style={{ marginBottom: 15 }}>
              <RequisitionPurchaseContainer purchase={p} />
              <div style={{ height: 1, backgroundColor: Color.GREY_600, marginTop: 8, marginBottom: 8 }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
