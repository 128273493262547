import { Frequency, PromotionCostOwner, PromotionType, TokenTypeName } from 'graphql/types.generated'
import { transform } from 'validation/transform'
import * as yup from 'yup'

export const promotionSchema = yup.object({
  numberOfTokens: yup.number().label('Number of Tokens').optional(),
  start: yup.string().label('Start Date*').required(),
  end: yup.string().label('End Date*').nullable().optional(),
  recurring: yup.boolean().label('Token Paid Out at Intervals').required().default(false),
  newUserOnly: yup.boolean().label('New User Only').required(),
  dispenseRewardOnEntry: yup.boolean().label('Give Token On Entering Promo').optional(),
  frequency: yup.mixed<Frequency>().label('Interval Frequency').optional(),
  tokenType: yup.mixed<TokenTypeName>().label('Token Type').optional(),
  customerFacingName: yup.string().label('Promotion Name').required(),
  costOwner: yup.mixed<PromotionCostOwner>().label('Cost Owner').required(),
  flatDiscountUsCents: yup.number().label('Flat Discount (Cents)').optional(),
  percentageDiscount: yup.number().label('Percentage Discount').optional(),
  merchantIds: yup.array(yup.string()).label('Merchants').optional(),
  type: yup.mixed<PromotionType>().label('Type').required(),
  limitPerUser: yup.number().label('Limit Per User').optional().default(1),
  maxDiscountUsCents: yup.number().nullable(true).transform(transform.nullable).label('Max Discount (Cents)'),
  promoCode: yup.string().label('Promo Code').optional(),
  productTags: yup.array(yup.string().nullable(false)).label('Product Tags').nullable(true).optional()
})

export type PromotionFormModel = yup.InferType<typeof promotionSchema>
