import { CircularProgress, Typography } from '@material-ui/core'
import { Maybe, useLibraryImagesQuery, useProductImagesByMerchantQuery } from 'graphql/types.generated'
import React from 'react'
import { ProductImageFormModel } from 'views/products/CreateProduct/productValidator'

import { ImageOption } from './ImageOption'

interface LibraryImageSelectionProps {
  merchantId: string
  productImages: ProductImageFormModel[]
  updateProductImages: (images: ProductImageFormModel[]) => void
}

export const LibraryImageSelection = (props: LibraryImageSelectionProps) => {
  const { updateProductImages, productImages, merchantId } = props

  const { data: libraryImagesData, loading: loadingLibraryImages } = useLibraryImagesQuery({
    fetchPolicy: 'cache-first'
  })

  const { data: productImagesData, loading: loadingProductImages } = useProductImagesByMerchantQuery({
    variables: { merchantId: merchantId },
    fetchPolicy: 'cache-first'
  })

  const uploadedProductImages = [
    ...new Set(productImagesData?.productImagesByMerchant?.filter((i) => !i.libraryImage)?.map((i) => i.imageUri) ?? [])
  ]

  async function selectImage(filename: string, libraryImageId: Maybe<string>) {
    let newProductImages = [...productImages, { imageUri: filename, libraryImageId: libraryImageId }]
    updateProductImages(newProductImages)
  }

  return (
    <div>
      {(loadingLibraryImages || loadingProductImages) && <CircularProgress />}
      {!!libraryImagesData?.libraryImages?.length && (
        <div>
          <Typography variant='subtitle1'>Library Images</Typography>
          <div style={imagesContainerStyle}>
            {libraryImagesData.libraryImages.map((i, index) => (
              <ImageOption
                key={`library-${index}`}
                imageUri={i.imageUri}
                libraryImageId={i.id}
                selectImage={selectImage}
              />
            ))}
          </div>
        </div>
      )}
      {!!uploadedProductImages.length && (
        <div>
          <Typography variant='subtitle1'>Uploaded Images</Typography>
          <div style={imagesContainerStyle}>
            {uploadedProductImages.map((i, index) => (
              <ImageOption key={`existing-${index}`} imageUri={i} selectImage={selectImage} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const imagesContainerStyle: React.CSSProperties = { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }
