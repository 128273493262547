import { RecurringInterval, useCreateSubscriptionPlanMutation } from 'graphql/types.generated'
import { SubscriptionPlanFormModel } from 'views/admin/subscriptions/components/subscriptionPlanValidator'

import { useToast } from '../useToast'

export function useCreateSubscriptionPlan() {
  const toast = useToast()
  const [execute, { loading }] = useCreateSubscriptionPlanMutation()

  async function create(input: SubscriptionPlanFormModel, onSuccess: () => void) {
    try {
      const { recurringInterval: recurringIntervalString, ...rest } = input

      const recurringInterval = !!recurringIntervalString ? (recurringIntervalString as RecurringInterval) : null

      if (recurringInterval == null) {
        toast.error('Recurring Interval must be set')
        return
      }

      const res = await execute({
        variables: { input: { ...rest, recurringInterval } }
      })
      if (res.data?.createSubscriptionPlan) {
        toast.success(`Subscription Plan created: ${res.data.createSubscriptionPlan.name}`)
        onSuccess()
      }
    } catch (e) {
      toast.error(`Could not create subscription plan ${e}`)
    }
  }

  return { create, loading }
}
