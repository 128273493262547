import { Button } from '@bloom-coffee/steamed-milk'
import { useMerchantForSyncQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import { ResyncFreshMenuModal } from 'views/merchants/components/FreshMenu/ResyncFreshMenuModal'

interface SyncFreshMenuButtonProps {
  onSuccess: () => void
  merchantId: string
  style?: React.CSSProperties
}

export function SyncFreshMenuButton(props: SyncFreshMenuButtonProps) {
  const { merchantId, onSuccess, style } = props
  const [showFreshModal, setShowFreshModal] = useState(false)

  const { data: merchData } = useMerchantForSyncQuery({
    variables: {
      id: merchantId
    },
    fetchPolicy: 'cache-first'
  })

  return (
    <div style={!!merchData?.merchant?.freshLocation ? style : {}}>
      {!!merchData?.merchant?.freshLocation?.locationId && (
        <Button onClick={() => setShowFreshModal(true)} label='Resync Fresh menu' />
      )}
      {showFreshModal && (
        <ResyncFreshMenuModal
          merchantId={merchantId}
          onCancel={() => setShowFreshModal(false)}
          onSuccess={() => {
            setShowFreshModal(false)
            onSuccess()
          }}
        />
      )}
    </div>
  )
}
