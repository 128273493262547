import { MenuButton, MenuButtonDetails } from './MenuButton'

export interface MenuBarProps {
  menuButtonDetails: MenuButtonDetails[]
  style?: React.CSSProperties
  defaultButtonStyle: React.CSSProperties
  activeButtonStyle: React.CSSProperties
}

export function MenuBar(props: MenuBarProps) {
  const { menuButtonDetails, style, defaultButtonStyle, activeButtonStyle } = props

  return (
    <div style={style}>
      {menuButtonDetails.map((buttonDetails, index) => (
        <MenuButton
          key={index}
          menuButtonDetails={buttonDetails}
          defaultStyle={defaultButtonStyle}
          activeStyle={activeButtonStyle}
        />
      ))}
    </div>
  )
}
