import { RoleMatcher, usePermissions } from 'hooks/usePermissions/usePermissions'
import { Navigate } from 'react-router-dom'

interface PrivateRouteProps {
  roleMatchers: RoleMatcher[]
  children: React.ReactNode
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { roleMatchers, children } = props
  const { hasAnyRole } = usePermissions()
  return hasAnyRole(...roleMatchers) ? <div>{children}</div> : <Navigate to='/' />
}
