import { Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import {
  DiningOption,
  GeoFenceType,
  MerchantLocationInput,
  useUpdateMerchantDetailsQuery,
  useUpdateMerchantMutation
} from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { MerchantForm } from '../components/MerchantForm/MerchantForm'
import { MerchantFormModel } from '../components/MerchantForm/merchantValidator'
import { ArchiveContainer } from './ArchiveContainer'
import { MoveToDifferentOrganizationButton } from './MoveMerchantToDifferentOrganizationButton/MoveToDifferentOrganizationButton'

export function UpdateMerchant() {
  const navigate = useNavigate()
  const { merchantId, organizationId } = useParams()
  const [execute, { loading: saving }] = useUpdateMerchantMutation()
  const errorHandler = useErrorHandler()
  const toast = useToast()
  const { data, loading } = useUpdateMerchantDetailsQuery({
    variables: {
      id: merchantId!
    }
  })

  async function saveMerchant(values: MerchantFormModel) {
    const {
      diningOptions: diningOptionStrings,
      location: locationForm,
      operationHours,
      taxRate: nullableTaxRate,
      name
    } = values

    const { geoFenceType: geoFenceTypeString, ...locationRest } = locationForm
    const location = locationRest as MerchantLocationInput
    location.geoFenceType = geoFenceTypeString as GeoFenceType

    const diningOptions = diningOptionStrings as DiningOption[]
    try {
      await execute({
        variables: {
          input: {
            id: merchantId!,
            organizationId: null,
            name,
            diningOptions,
            location,
            taxRate: nullableTaxRate!,
            hours:
              operationHours
                ?.filter((h) => !!h.weekDays?.length)
                .map((h) => {
                  return {
                    weekDay: h.weekDays[0],
                    timeIntervals: h.timeIntervals
                  }
                }) ?? []
          }
        }
      })
      toast.success('Cafe Details Saved')
      navigate(`/organizations/${organizationId}/merchants/${merchantId}`, { replace: true })
    } catch (e) {
      errorHandler(e)
    }
  }

  if (loading) {
    return <CircularProgress />
  }

  if (!data?.merchant) {
    return <Alert color='error'>Cafe not found.</Alert>
  }

  return (
    <Section
      title='Update Cafe'
      addon={
        <div style={addonStyle}>
          <HasAnyRole roleMatchers={[roles.admin]}>
            <MoveToDifferentOrganizationButton merchantId={merchantId!} />
          </HasAnyRole>
          <ArchiveContainer
            style={{ marginLeft: 10 }}
            variant='merchant'
            merchantId={merchantId}
            organizationId={organizationId}
            onSuccess={() => navigate(`/organizations/${organizationId}`)}
          />
        </div>
      }
    >
      <MerchantForm
        onCancel={() => navigate(-1)}
        onSubmit={saveMerchant}
        defaultValues={data.merchant}
        disableSubmit={saving}
      />
    </Section>
  )
}
const addonStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}
