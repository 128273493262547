import { Button } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { TimeField } from 'components/form/TimeField'
import { WeekDaysField } from 'components/form/WeekDaysField'
import React, { Fragment } from 'react'
import { DefaultValues, FormProvider, useFieldArray, useForm } from 'react-hook-form'

import { DayHoursFormModel, dayHoursSchema } from './validator'

interface HoursModalProps {
  defaultValues?: DefaultValues<DayHoursFormModel>
  onCancel(): void
  onSubmit(values: DayHoursFormModel): void
}

export function HoursModal(props: HoursModalProps) {
  const { defaultValues, onCancel, onSubmit } = props

  const styles = useStyles()
  const formProps = useForm({
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(dayHoursSchema)
  })
  const { handleSubmit, control, formState } = formProps

  const { append, fields, remove } = useFieldArray({
    name: 'timeIntervals',
    keyName: 'key' as any,
    control
  })

  function handleAddRange() {
    append({})
  }

  return (
    <Dialog open>
      <DialogTitle>Set hours</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <FormProvider {...formProps}>
          <WeekDaysField name='weekDays' defaultValues={defaultValues?.weekDays} />
          {!fields.length && (
            <Typography align='center' color='textSecondary'>
              None
            </Typography>
          )}
          <Grid container spacing={2}>
            {fields.map((field, index) => {
              function handleRemove() {
                remove(index)
              }
              return (
                <Fragment key={(field as any).key}>
                  <Grid item xs={5}>
                    <TimeField
                      fullWidth
                      name={`timeIntervals[${index}].start`}
                      label='Start'
                      defaultValue={field.start}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TimeField fullWidth name={`timeIntervals[${index}].end`} label='End' defaultValue={field.end} />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={handleRemove}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Fragment>
              )
            })}
            <Grid item xs={12}>
              <Button onClick={handleAddRange} label={'Add interval'} />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <DefaultFormFooter onCancel={onCancel} onSubmit={handleSubmit(onSubmit)} submitting={formState.isSubmitting} />
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 600
  }
}))
