import { Switch as MuiSwitch, Typography } from '@material-ui/core'
import { OrganziationSettingsDocument, useUpdateOrganizationBooleanSettingMutation } from 'graphql/types.generated'
import React, { useState } from 'react'

interface OrganizationBooleanSettingContainerProps {
  organizationId: string
  name: string
  description: string
  initialValue: boolean
}

export const OrganizationBooleanSettingContainer = (props: OrganizationBooleanSettingContainerProps) => {
  const { organizationId, name, description, initialValue } = props
  const [newValue, setNewValue] = useState<boolean>(initialValue)

  const [execute, { error }] = useUpdateOrganizationBooleanSettingMutation()
  async function handleSwitch(checked: boolean) {
    setNewValue(checked)
    execute({
      variables: { organizationId, name, value: checked },
      refetchQueries: [{ query: OrganziationSettingsDocument, variables: { organizationId } }]
    })
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
          <Typography variant='body2'>{description}</Typography>
        </div>

        <div>
          <MuiSwitch checked={newValue} onChange={(e) => handleSwitch(e.target.checked)} color='primary' />
        </div>
      </div>

      <div>
        {error && (
          <Typography variant='body1' color='error'>
            {error}
          </Typography>
        )}
      </div>
    </div>
  )
}
