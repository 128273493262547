import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import { useCreateMerchantPromotion } from 'hooks/useCreateMerchantPromotion/useCreateMerchantPromotion'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { MerchantPromotionForm } from './components/MerchantPromotionForm'
import { MerchantPromotionFormModel } from './components/merchantPromotionValidator'

const useStyles = makeStyles((theme) => ({
  successContainer: {}
}))

export const CreateMerchantPromotion = () => {
  const navigate = useNavigate()
  const styles = useStyles()

  const { merchantId } = useParams()
  const { loading, create } = useCreateMerchantPromotion()

  const [showSuccess, toggleSuccess] = useState(false)

  function handleSubmit(formInput: MerchantPromotionFormModel) {
    create(merchantId!, formInput, () => toggleSuccess(true))
  }

  return (
    <Section title='Create Promotion'>
      {showSuccess ? (
        <Card className={styles.successContainer}>
          <CardContent>
            <Typography variant='subtitle2'>Successfully submitted promotion.</Typography>
            <Typography variant='subtitle1'>
              RDY will review your promotion and you will receive an email upon approval.
            </Typography>
          </CardContent>
          <CardActions>
            <Button label='Okay' onClick={() => navigate(-1)} />
          </CardActions>
        </Card>
      ) : (
        <MerchantPromotionForm onCancel={() => navigate(-1)} onSubmit={handleSubmit} disableSubmit={loading} />
      )}
    </Section>
  )
}
