import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, DialogContentText } from '@material-ui/core'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { KdsListDocument, MyKdssDocument, useUnlinkKdsMutation } from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import React, { useState } from 'react'

interface UnlinkKdsButtonProps {
  kdsId: ID
  merchantId: ID
}

export function UnlinkKdsButton(props: UnlinkKdsButtonProps) {
  const { kdsId, merchantId } = props
  const [opened, setOpened] = useState(false)
  const [update, { loading }] = useUnlinkKdsMutation()
  const errorHandler = useErrorHandler()

  async function handleConfirm() {
    try {
      await update({
        variables: {
          id: kdsId
        },
        refetchQueries: [{ query: KdsListDocument, variables: { merchantId } }, { query: MyKdssDocument }],
        awaitRefetchQueries: true
      })
      setOpened(false)
    } catch (e) {
      errorHandler(e)
    }
  }

  return (
    <>
      <Button
        onClick={() => setOpened(true)}
        disabled={loading}
        label='Unlink'
        endIcon={loading && <CircularProgress color='inherit' size={16} />}
      />
      <ConfirmationDialog
        title='Unlink Tablet'
        open={opened}
        onClose={() => setOpened(false)}
        onConfirm={handleConfirm}
      >
        <DialogContentText>
          Are you sure you want to unlink this KDS device? <br /> Make sure to also logout from the KDS device to remove
          all your info.
        </DialogContentText>
      </ConfirmationDialog>
    </>
  )
}
