export function moveItem<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function sortOrderSort(a: any, b: any) {
  if (a.sortOrder == null || a.sortOrder === undefined) {
    return 1
  }

  if (b.sortOrder == null || b.sortOrder === undefined) {
    return -1
  }

  if (a.sortOrder === b.sortOrder) {
    return 0
  }

  return a.sortOrder < b.sortOrder ? -1 : 1
}
