import { Button } from '@bloom-coffee/steamed-milk'
import { DialogContentText } from '@material-ui/core'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { OrderDetailsQueryDocument, useRefundOrderItemMutation } from 'graphql/types.generated'
import { OrderItemInfo } from 'models/Order'
import React, { useState } from 'react'
import { formatUsCentsAsUsDollar } from 'util/formatting'

interface RefundOrderItemProps {
  orderItem: OrderItemInfo
  orderId: string
}

export function RefundOrderItem(props: RefundOrderItemProps) {
  const { orderItem, orderId } = props

  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [execute, { loading }] = useRefundOrderItemMutation()

  let infoText = 'Refund'

  if ((orderItem.subtotalChargeTokens ?? 0) > 0) {
    infoText += ` ${orderItem.subtotalChargeTokens!!} token(s)`
  }

  if ((orderItem.subtotalChargeUsCents ?? 0) > 0) {
    infoText += ` ${formatUsCentsAsUsDollar(orderItem.subtotalChargeUsCents!!)} plus tax`
  }

  async function confirmRefundOrderItem() {
    await execute({
      variables: { id: orderItem.id },
      refetchQueries: [{ query: OrderDetailsQueryDocument, variables: { id: orderId } }]
    })
    setConfirmationOpen(false)
  }

  return (
    <div>
      {!orderItem.refunded && (
        <div>
          <Button
            label={loading ? 'Loading...' : 'Refund Item'}
            onClick={() => setConfirmationOpen(true)}
            disabled={loading}
          />
        </div>
      )}

      <ConfirmationDialog
        title={infoText}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={confirmRefundOrderItem}
        loading={loading}
      >
        <DialogContentText>Are you sure you want to refund this item?</DialogContentText>
      </ConfirmationDialog>
    </div>
  )
}
