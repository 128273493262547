import { Button } from '@bloom-coffee/steamed-milk'
import { DialogContentText } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useArchiveTopModifier } from 'hooks/useArchiveTopModifier'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { UpdateModifierUsage } from 'views/modifiers/UpdateModifierUsage/UpdateModifierUsage'

import { TopModifierFragment } from '../../../../graphql/types.generated'
import { ModifierOptionForTopModifiersList } from './ModifierOptionForTopModifiersList'

interface TopModifierDetailsContainerProps {
  merchantId: string
  modifier: TopModifierFragment
  onEdit: (id: string) => void
}

export const TopModifierDetailsContainer = (props: TopModifierDetailsContainerProps) => {
  const { modifier, onEdit, merchantId } = props

  const [showModifierUsageModal, setShowModifierUsageModal] = useState(false)
  const { archive, loading: loadingArchive } = useArchiveTopModifier()

  const [showDeletePrompt, setShowDeletePrompt] = useState(false)
  const toast = useToast()
  async function handleDelete() {
    await archive(modifier.id, () => toast.success('Deleted Modifier'))
  }

  return (
    <div>
      <div style={modifierDetailsStyle}>
        <ModifierOptionForTopModifiersList
          selectedIds={modifier.defaultSelectionIds || []}
          options={modifier.allChildren || []}
        />
      </div>

      <div style={actionsStyle}>
        <Button
          style={buttonStyle}
          disabled={modifier.isSynced}
          onClick={() => {
            setShowModifierUsageModal(true)
          }}
          label='Apply to Products'
        />
        <Button style={buttonStyle} endIcon={<EditIcon />} onClick={() => onEdit(modifier.id)} />
        <Button
          style={buttonStyle}
          disabled={loadingArchive || modifier.isSynced}
          theme='warning'
          endIcon={<DeleteIcon />}
          onClick={handleDelete}
        />
      </div>
      <UpdateModifierUsage
        merchantId={merchantId}
        modifierId={modifier.id}
        disabled={modifier.isSynced}
        onClose={() => {
          setShowModifierUsageModal(false)
        }}
        open={showModifierUsageModal}
      ></UpdateModifierUsage>
      <ConfirmationDialog
        title='Are you sure?'
        open={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={handleDelete}
      >
        <DialogContentText>Are you sure you want to delete this Modifier?</DialogContentText>
      </ConfirmationDialog>
    </div>
  )
}

const modifierDetailsStyle: React.CSSProperties = {
  marginBottom: 2,
  marginLeft: 2,
  width: '100%'
}

const actionsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: 10
}

const buttonStyle: React.CSSProperties = {
  marginRight: 5
}
