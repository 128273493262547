import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Box, CircularProgress, DialogContentText, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { TextDisplay } from 'components/layout/TextDisplay'
import {
  Maybe,
  SubscriptionStatus,
  useCancelStripeSubscriptionMutation,
  useMerchantSubscriptionFeesByOrganizationIdQuery,
  useMerchantSubscriptionsByOrganizationIdQuery,
  useOrganizationInvoicesQuery,
  useUpdateMerchantsOnOrgSubscriptionMutation,
  useViewOrganizationQuery
} from 'graphql/types.generated'
import { useCreatePaymentIntent } from 'hooks/useCreatePaymentIntent/useCreatePaymentIntent'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import { MerchantInfo } from 'models/Merchant'
import { StripeSubscriptionInfo, SubscriptionPaymentIntentInfo } from 'models/Subscriptions'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { formatRecurringIntervalUnits, getSubscriptionStatusFriendlyName } from 'util/subscriptions'
import { ActivationPayment } from 'views/admin/subscriptions/StripeCheckoutForm/ActivationPayment'
import { SubscriptionPayment } from 'views/admin/subscriptions/StripeCheckoutForm/SubscriptionPayment'
import { SetUpSubscription } from 'views/merchants/components/SetUpSubscription/SetUpSubscription'
import { SubscriptionInvoices } from 'views/merchants/components/SubscriptionInvoices/SubscriptionInvoices'

import { MerchantPlatformPricingGrid } from './MerchantPlatformPricingGrid'
import { MerchantSubscriptionsGrid } from './MerchantSubscriptionsGrid'
import { OrganizationPlatformPricing } from './OrganizationPlatformPricing'

interface OrganizationSubscriptionPaymentProps {
  organizationId: ID
}

export function OrganizationSubscriptionPayment(props: OrganizationSubscriptionPaymentProps) {
  const { organizationId } = props
  const styles = useStyles()
  const navigate = useNavigate()
  const toast = useToast()

  const {
    data,
    loading: loadingSubscriptions,
    refetch: refetchSubscriptions
  } = useMerchantSubscriptionsByOrganizationIdQuery({
    variables: {
      organizationId: organizationId
    },
    notifyOnNetworkStatusChange: true
  })

  const { data: feeData, refetch: refetchFees } = useMerchantSubscriptionFeesByOrganizationIdQuery({
    variables: {
      organizationId: organizationId
    }
  })

  const { data: invoiceData } = useOrganizationInvoicesQuery({
    variables: {
      organizationId: organizationId
    }
  })

  const { data: organizationData, loading: loadingMerchants } = useViewOrganizationQuery({
    variables: {
      id: organizationId!
    }
  })

  const [updateSubscription, { loading: loadingUpdateSubscription }] = useUpdateMerchantsOnOrgSubscriptionMutation()
  const [cancelSubscription, { loading: loadingCancelSubscription }] = useCancelStripeSubscriptionMutation()

  const { createPaymentIntent } = useCreatePaymentIntent({
    organizationId: organizationId!,
    organizationManaged: true,
    onError: (error) => toast.error(error),
    onComplete: (value) => {
      setSelectedMerchantSubscriptionIds([])
      refreshSubscriptionData()
      setSubscriptionPaymentIntent(value ?? null)
      setAwaitingPayment(true)
    }
  })

  const [merchantSubscriptionIdToBeRemoved, setMerchantSubscriptionIdToBeRemoved] = useState<string | null>(null)

  const [selectedMerchantSubscriptionIds, setSelectedMerchantSubscriptionIds] = useState<string[]>([])
  const [subscriptionPaymentIntent, setSubscriptionPaymentIntent] = useState<Maybe<SubscriptionPaymentIntentInfo>>(null)
  const [updatingPayment, setUpdatingPayment] = useState(false)
  const [awaitingPayment, setAwaitingPayment] = useState(false)
  const [initializedMerchantsChangingPlans, setInitializedMerchantsChangingPlans] = useState(false)
  const [merchantsToCreatePaymentIntentFor, setMerchantsToCreatePaymentIntentFor] = useState<MerchantInfo[]>([])

  const [merchantsChangingPlans, setMerchantsChangingPlans] = useState<MerchantInfo[]>([])

  const merchantSubscriptions = data?.merchantSubscriptionsByOrganizationId ?? []

  const allOrganizationMerchantSubscriptions =
    data?.merchantSubscriptionsByOrganizationId.filter(
      (merchantSubscription) => merchantSubscription.organizationManaged
    ) ?? []

  const organizationMerchantSubscriptionsNonPlatform =
    allOrganizationMerchantSubscriptions.filter((merchantSubscription) => !merchantSubscription.platformPricing) ?? []

  const orgStripeSubscriptions = [
    ...new Set(
      allOrganizationMerchantSubscriptions
        .filter((oms) => !!oms.stripeSubscription)
        .map((oms) => oms?.stripeSubscription)
    )
  ]

  const organizationMerchantSubscriptionFees = feeData?.merchantSubscriptionFeesByOrganizationId ?? []

  const trialEndDate = allOrganizationMerchantSubscriptions.find((ms) => !!ms.trialEndDate)
    ? Math.min(...allOrganizationMerchantSubscriptions.filter((ms) => !!ms.trialEndDate).map((ms) => ms.trialEndDate!!))
    : null

  const uninitializedMerchantSubscriptions =
    data?.merchantSubscriptionsByOrganizationId.filter(
      (merchantSubscription) =>
        !merchantSubscription.organizationManaged &&
        !merchantSubscription.stripeSubscription &&
        !merchantSubscription.platformPricing
    ) ?? []

  const independentPlatformMerchantSubscriptions =
    data?.merchantSubscriptionsByOrganizationId.filter(
      (merchantSubscription) => !merchantSubscription.organizationManaged && !!merchantSubscription?.platformPricing
    ) ?? []

  const independentSubscriptionMerchantSubscriptions =
    data?.merchantSubscriptionsByOrganizationId.filter(
      (merchantSubscription) =>
        !merchantSubscription.organizationManaged &&
        !merchantSubscription?.platformPricing &&
        !!merchantSubscription?.stripeSubscription
    ) ?? []

  const merchantsWithoutSubs =
    organizationData?.organization?.merchants?.filter(
      (m) => merchantSubscriptions.filter((ms) => ms.merchant.id === m.id).length === 0
    ) ?? []

  const platformPricedMerchantSubscriptions =
    data?.merchantSubscriptionsByOrganizationId.filter(
      (merchantSubscription) => !!merchantSubscription?.platformPricing && !!merchantSubscription.organizationManaged
    ) ?? []

  const unpaidInstantPlatformPricedMerchantSubscriptions =
    platformPricedMerchantSubscriptions.filter(
      (merchantSubscription) =>
        !merchantSubscription.termPricing &&
        ['PENDING_PAYMENT', 'PAYMENT_FAILED'].indexOf(merchantSubscription!!.subscriptionStatus!!) >= 0
    ) ?? []

  const instantPricedMerchantSubsAwaitingPayment = unpaidInstantPlatformPricedMerchantSubscriptions.filter(
    (ms) => ms.stripeSubscription != null
  )

  const merchantsEligibleForPricingChange =
    organizationData?.organization?.merchants?.filter((m) => {
      const merchantSub = merchantSubscriptions.find((ms) => ms.merchant.id === m.id)
      if (!merchantSub) return false

      if (!merchantSub.organizationManaged) {
        return false
      }
      const onFreePlan =
        merchantSub?.subscriptionStatus === 'COMPLETE' &&
        merchantSub.termPricing === false &&
        merchantSub?.platformPricing?.instantPriceUsCents === 0

      return (
        !!merchantSub?.platformPricing &&
        !!merchantSub?.subscriptionStatus &&
        (['CANCELLED', 'PENDING_PAYMENT'].indexOf(merchantSub!!.subscriptionStatus!!) >= 0 || onFreePlan)
      )
    }) ?? []

  const termSubscriptionsPendingPayment =
    platformPricedMerchantSubscriptions.filter(
      (ms) => ms.termPricing && ms.platformPricing && ms.subscriptionStatus === SubscriptionStatus.PendingPayment
    ) ?? []

  const merchantsForPlatformPricing =
    organizationData?.organization?.merchants?.filter(
      (m) =>
        !independentSubscriptionMerchantSubscriptions.find((ms) => ms.merchant.id === m.id) &&
        !independentPlatformMerchantSubscriptions.find((ms) => ms.merchant.id === m.id) &&
        !organizationMerchantSubscriptionsNonPlatform.find((ms) => ms.merchant.id === m.id) &&
        !uninitializedMerchantSubscriptions.find((ms) => ms.merchant.id === m.id)
    ) ?? []

  async function onCancelSub(stripeSubscriptionId: string) {
    try {
      await cancelSubscription({
        variables: {
          stripeSubscriptionId: stripeSubscriptionId
        }
      })
      toast.success('Subscription Canceled')
      refreshSubscriptionData()
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  async function removeMerchantFromSubscription(merchantSubscriptionId: string) {
    let merchantIds = merchantSubscriptions
      .filter((ms) => ms.id !== merchantSubscriptionId && ms.organizationManaged && !ms.platformPricing)
      .map((ms) => ms.merchant.id)

    let merchantSubscription = merchantSubscriptions.find((ms) => ms.id === merchantSubscriptionId)
    if (!merchantIds.length && merchantSubscription?.stripeSubscription?.stripeSubscriptionId) {
      onCancelSub(merchantSubscription.stripeSubscription.id)
      return
    }

    await updateMerchantsOnSubscription(merchantIds)
  }

  async function addMerchantsToSubscription() {
    let merchantIds = merchantSubscriptions
      .filter(
        (ms) =>
          !!allOrganizationMerchantSubscriptions.find((oms) => oms.organizationManaged && oms.id === ms.id) ||
          selectedMerchantSubscriptionIds.includes(ms.id)
      )
      .map((ms) => ms.merchant.id)

    await updateMerchantsOnSubscription(merchantIds)
  }

  async function updateMerchantsOnSubscription(merchantIds: string[]) {
    try {
      await updateSubscription({
        variables: {
          merchantIds
        }
      })

      setSelectedMerchantSubscriptionIds([])
      refreshSubscriptionData()
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  function refreshSubscriptionData() {
    refetchSubscriptions()
    refetchFees()
  }

  function allowPaymentUpdate(orgStripeSubscription: StripeSubscriptionInfo) {
    return (
      !!orgStripeSubscription &&
      orgStripeSubscription?.subscriptionStatus !== SubscriptionStatus.PendingPayment &&
      orgStripeSubscription?.subscriptionStatus !== SubscriptionStatus.Cancelled &&
      orgStripeSubscription?.subscriptionStatus !== SubscriptionStatus.Complete
    )
  }

  async function platformPriceSelected() {
    await refetchSubscriptions()
    setMerchantsToCreatePaymentIntentFor(merchantsChangingPlans)
    setMerchantsChangingPlans([])
  }

  useEffect(() => {
    if (
      !loadingSubscriptions &&
      !initializedMerchantsChangingPlans &&
      !loadingMerchants &&
      !!organizationData?.organization?.merchants &&
      !data?.merchantSubscriptionsByOrganizationId.length &&
      !merchantsChangingPlans.length
    ) {
      setMerchantsChangingPlans(organizationData?.organization?.merchants)
      setInitializedMerchantsChangingPlans(true)
    }
  }, [
    organizationData,
    data,
    merchantsChangingPlans,
    loadingMerchants,
    initializedMerchantsChangingPlans,
    loadingSubscriptions
  ])

  useEffect(() => {
    if (merchantsToCreatePaymentIntentFor.length) {
      const subs = data?.merchantSubscriptionsByOrganizationId ?? []
      let merchantSubsReadyForPaymentIntent = subs.filter(
        (ms) =>
          !!merchantsToCreatePaymentIntentFor.find((m) => m.id === ms.merchant.id) &&
          ms.subscriptionStatus !== SubscriptionStatus.Complete
      )
      if (merchantSubsReadyForPaymentIntent.length) {
        createPaymentIntent(merchantSubsReadyForPaymentIntent)
      }
      setMerchantsToCreatePaymentIntentFor([])
    }
  }, [merchantsToCreatePaymentIntentFor, data, createPaymentIntent])

  if (loadingSubscriptions) return <CircularProgress />

  if (!loadingSubscriptions && merchantsChangingPlans.length)
    return (
      <OrganizationPlatformPricing
        merchants={merchantsChangingPlans}
        organizationId={organizationId}
        merchantSubscriptions={merchantSubscriptions}
        onComplete={platformPriceSelected}
      />
    )

  return (
    <>
      {!orgStripeSubscriptions.length && !!uninitializedMerchantSubscriptions.length && (
        <Section title='Choose cafes to set up the subscription payment:' variant='subsection'>
          <MerchantSubscriptionsGrid
            loading={loadingSubscriptions}
            merchantSubscriptions={uninitializedMerchantSubscriptions}
            merchantSubscriptionFees={organizationMerchantSubscriptionFees}
            selectable={true}
            isRowSelectable={(merchantSubscription) => !merchantSubscription.stripeSubscription}
            setSelectedMerchantSubscriptionIds={setSelectedMerchantSubscriptionIds}
            defaultSelectedMerchantSubscriptionId={uninitializedMerchantSubscriptions.map((ms) => ms.id)}
          />
          <SetUpSubscription
            merchantSubscriptions={merchantSubscriptions.filter((ms) =>
              selectedMerchantSubscriptionIds.includes(ms.id)
            )}
            organizationId={organizationId!!}
            onError={(error) => toast.error(error)}
            organizationManaged={true}
            onComplete={(value) => {
              setSelectedMerchantSubscriptionIds([])
              refreshSubscriptionData()
              setSubscriptionPaymentIntent(value ?? null)
              setAwaitingPayment(true)
            }}
          />
        </Section>
      )}
      {!merchantsChangingPlans.length && !!merchantsForPlatformPricing.length && (
        <Section title='Cafe Activations' variant='subsection'>
          <MerchantPlatformPricingGrid
            loading={loadingSubscriptions}
            merchants={merchantsForPlatformPricing}
            merchantSubscriptions={platformPricedMerchantSubscriptions}
          />
          <div style={containerStyle}>
            {((!!merchantsEligibleForPricingChange.length && !merchantsChangingPlans.length) ||
              !!merchantsWithoutSubs.length) && (
              <Button
                label='Choose Plans'
                style={buttonStyle}
                onClick={() =>
                  setMerchantsChangingPlans(merchantsEligibleForPricingChange.concat(merchantsWithoutSubs))
                }
              />
            )}
            {!!platformPricedMerchantSubscriptions.filter(
              (ms) => ms.termPricing && ms.subscriptionStatus === SubscriptionStatus.PendingPayment
            ).length && (
              <SetUpSubscription
                style={buttonStyle}
                merchantSubscriptions={platformPricedMerchantSubscriptions.filter(
                  (ms) => ms.subscriptionStatus === SubscriptionStatus.PendingPayment
                )}
                organizationId={organizationId!!}
                onError={(error) => toast.error(error)}
                organizationManaged={true}
                onComplete={(value) => {
                  setSelectedMerchantSubscriptionIds([])
                  refreshSubscriptionData()
                  setSubscriptionPaymentIntent(value ?? null)
                  setAwaitingPayment(true)
                }}
              />
            )}
            {!!unpaidInstantPlatformPricedMerchantSubscriptions.length && !termSubscriptionsPendingPayment.length && (
              <SetUpSubscription
                style={buttonStyle}
                merchantSubscriptions={unpaidInstantPlatformPricedMerchantSubscriptions}
                organizationId={organizationId!!}
                onError={(error) => toast.error(error)}
                organizationManaged={true}
                onComplete={(value) => {
                  setSelectedMerchantSubscriptionIds([])
                  refreshSubscriptionData()
                  setSubscriptionPaymentIntent(value ?? null)
                  setAwaitingPayment(true)
                }}
              />
            )}
          </div>
        </Section>
      )}

      {!!allOrganizationMerchantSubscriptions.length &&
        !!orgStripeSubscriptions &&
        orgStripeSubscriptions.map((orgStripeSubscription) => (
          <div key={orgStripeSubscription!!.id}>
            {!!uninitializedMerchantSubscriptions.length && (
              <Section title='Cafes ready to be added to the subscription' variant='subsection'>
                <MerchantSubscriptionsGrid
                  loading={loadingSubscriptions}
                  merchantSubscriptions={uninitializedMerchantSubscriptions}
                  merchantSubscriptionFees={organizationMerchantSubscriptionFees}
                  selectable={true}
                  isRowSelectable={(merchantSubscription) => true}
                  defaultSelectedMerchantSubscriptionId={uninitializedMerchantSubscriptions.map((ms) => ms.id)}
                  setSelectedMerchantSubscriptionIds={setSelectedMerchantSubscriptionIds}
                />
                <Button
                  label='Add selected Cafes'
                  onClick={() => addMerchantsToSubscription()}
                  endIcon={loadingUpdateSubscription && <CircularProgress />}
                />
              </Section>
            )}
            {organizationMerchantSubscriptionsNonPlatform.length > 0 &&
              orgStripeSubscription!!.id === organizationMerchantSubscriptionsNonPlatform[0].stripeSubscription?.id && (
                <Section title='Cafes Subscriptions' variant='subsection'>
                  <MerchantSubscriptionsGrid
                    loading={loadingSubscriptions}
                    merchantSubscriptions={organizationMerchantSubscriptionsNonPlatform}
                    merchantSubscriptionFees={organizationMerchantSubscriptionFees}
                    selectable={false}
                    onRemove={(merchantSubscriptionId) => setMerchantSubscriptionIdToBeRemoved(merchantSubscriptionId)}
                  />
                </Section>
              )}
            <Alert
              severity={
                orgStripeSubscription?.subscriptionStatus === SubscriptionStatus.Current ||
                orgStripeSubscription?.subscriptionStatus === SubscriptionStatus.Complete
                  ? 'success'
                  : 'warning'
              }
              className={styles.alert}
              action={
                <Box>
                  {allowPaymentUpdate(orgStripeSubscription!!) && !updatingPayment && (
                    <Button label='Update Payment Method' onClick={() => setUpdatingPayment(true)} />
                  )}
                  {orgStripeSubscription?.subscriptionStatus === SubscriptionStatus.PendingPayment && !awaitingPayment && (
                    <SetUpSubscription
                      merchantSubscriptions={allOrganizationMerchantSubscriptions.filter(
                        (ms) =>
                          ms.stripeSubscription?.stripeSubscriptionId === orgStripeSubscription.stripeSubscriptionId
                      )}
                      organizationId={organizationId!!}
                      onError={(error) => toast.error(error)}
                      organizationManaged={true}
                      onComplete={(value) => {
                        setSelectedMerchantSubscriptionIds([])
                        refreshSubscriptionData()
                        setSubscriptionPaymentIntent(value ?? null)
                        setAwaitingPayment(true)
                      }}
                    />
                  )}
                </Box>
              }
            >
              <Box flexDirection='row' display='flex' flexWrap='wrap'>
                <TextDisplay titleVariant='subtitle2' title='Cost:'>
                  {`${formatUsCentsAsUsDollar(
                    allOrganizationMerchantSubscriptions
                      .filter(
                        (ms) =>
                          ms.stripeSubscription?.stripeSubscriptionId === orgStripeSubscription!!.stripeSubscriptionId
                      )
                      .map((item) => item.subscriptionPlan?.costUsCents ?? 0)
                      .reduce((prev, next) => prev + next)
                  )} / ${formatRecurringIntervalUnits(
                    allOrganizationMerchantSubscriptions.filter(
                      (ms) =>
                        ms.stripeSubscription?.stripeSubscriptionId === orgStripeSubscription!!.stripeSubscriptionId &&
                        !!ms.subscriptionPlan?.recurringInterval
                    )[0].subscriptionPlan!!.recurringInterval!!
                  )}`}
                </TextDisplay>
                <TextDisplay titleVariant='subtitle2' title='Subscription Status:'>
                  {getSubscriptionStatusFriendlyName(orgStripeSubscription?.subscriptionStatus)}
                </TextDisplay>
                <TextDisplay titleVariant='subtitle2' title='Cafes:'>
                  {merchantSubscriptions
                    .filter(
                      (ms) =>
                        ms.stripeSubscription?.id === orgStripeSubscription!!.id &&
                        (ms.termPricing || !ms.platformPricing)
                    )
                    .map((ms) => (
                      <div>{ms.merchant.name}</div>
                    ))}
                </TextDisplay>
                {!!instantPricedMerchantSubsAwaitingPayment.length && (
                  <TextDisplay titleVariant='subtitle2' title='Pending One Time Payments:'>
                    <Box flexDirection='column' display='flex' flexWrap='wrap'>
                      {instantPricedMerchantSubsAwaitingPayment
                        .filter((ms) => ms.stripeSubscription?.id === orgStripeSubscription!!.id)
                        .map((ms) => (
                          <Box>{`${ms.platformPricing!!.name} - ${formatUsCentsAsUsDollar(
                            ms.platformPricing!!.instantPriceUsCents!!
                          )}`}</Box>
                        ))}
                    </Box>
                  </TextDisplay>
                )}
                {!!feeData && feeData.merchantSubscriptionFeesByOrganizationId.length > 0 && (
                  <TextDisplay titleVariant='subtitle2' title='Pending Fees:'>
                    <Box flexDirection='column' display='flex' flexWrap='wrap'>
                      {feeData.merchantSubscriptionFeesByOrganizationId.map((fee) => (
                        <Box>{`${fee.subscriptionFee.name} - ${formatUsCentsAsUsDollar(
                          fee.subscriptionFee.costUsCents
                        )}`}</Box>
                      ))}
                    </Box>
                  </TextDisplay>
                )}
              </Box>
              <HasAnyRole roleMatchers={[roles.admin]}>
                <Button
                  onClick={() => onCancelSub(orgStripeSubscription!!.id)}
                  label='Cancel Entire Subscription (admin only)'
                  theme='warning'
                  endIcon={loadingCancelSubscription && <CircularProgress color='inherit' size={20} />}
                ></Button>
              </HasAnyRole>
            </Alert>

            {orgStripeSubscription?.subscriptionStatus === SubscriptionStatus.PastDue && (
              <Box color='Red'>The saved payment method will be retried at the next retry interval.</Box>
            )}

            <SubscriptionPayment
              stripeSubscription={orgStripeSubscription}
              awaitingPayment={awaitingPayment}
              onComplete={() => {
                refreshSubscriptionData()
                setSubscriptionPaymentIntent(null)
                setAwaitingPayment(false)
                setUpdatingPayment(false)
              }}
              onCancel={() => {
                setSubscriptionPaymentIntent(null)
                setAwaitingPayment(false)
                setUpdatingPayment(false)
              }}
              subscriptionPaymentIntent={subscriptionPaymentIntent}
              trialEndDate={trialEndDate}
              updatingPayment={updatingPayment}
            />
          </div>
        ))}

      {!!independentSubscriptionMerchantSubscriptions.length && (
        <Section title='Cafe Managed Subscriptions' variant='subsection'>
          <MerchantSubscriptionsGrid
            loading={loadingSubscriptions}
            merchantSubscriptions={independentSubscriptionMerchantSubscriptions}
            merchantSubscriptionFees={organizationMerchantSubscriptionFees}
            selectable={false}
            isRowSelectable={(merchantSubscription) => false}
            onRowClick={(merchantId: string) =>
              navigate(`/organizations/${organizationId}/merchants/${merchantId}/billing`)
            }
          />
        </Section>
      )}

      {!!independentPlatformMerchantSubscriptions.length && (
        <Section title='Cafe Managed Activations' variant='subsection'>
          <MerchantPlatformPricingGrid
            loading={loadingSubscriptions}
            merchants={
              organizationData?.organization?.merchants?.filter(
                (m) => !!independentPlatformMerchantSubscriptions.find((ms) => ms.merchant.id === m.id)
              ) ?? []
            }
            merchantSubscriptions={independentPlatformMerchantSubscriptions}
          />
        </Section>
      )}

      {!!unpaidInstantPlatformPricedMerchantSubscriptions.length && !termSubscriptionsPendingPayment.length && (
        <ActivationPayment
          merchantIds={unpaidInstantPlatformPricedMerchantSubscriptions.map((ms) => ms.merchant.id)}
          organizationId={organizationId!!}
          awaitingPayment={awaitingPayment}
          onComplete={() => {
            refetchSubscriptions()
            setSubscriptionPaymentIntent(null)
            setAwaitingPayment(false)
            setUpdatingPayment(false)
          }}
          onCancel={() => {
            setSubscriptionPaymentIntent(null)
            setAwaitingPayment(false)
            setUpdatingPayment(false)
          }}
          subscriptionPaymentIntent={subscriptionPaymentIntent}
        />
      )}
      {!!invoiceData && <SubscriptionInvoices subscriptionInvoices={invoiceData.organizationInvoices} />}

      <ConfirmationDialog
        title='Remove Cafe from the Subscription?'
        open={!!merchantSubscriptionIdToBeRemoved}
        confirmDisabled={loadingCancelSubscription || loadingUpdateSubscription}
        onClose={() => setMerchantSubscriptionIdToBeRemoved(null)}
        onConfirm={() => {
          removeMerchantFromSubscription(merchantSubscriptionIdToBeRemoved!)
          setMerchantSubscriptionIdToBeRemoved(null)
        }}
      >
        <DialogContentText>Are you sure you want to remove this Cafe from the Subscription?</DialogContentText>
      </ConfirmationDialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: 20
  },
  alert: {
    marginBottom: 20
  }
}))

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const buttonStyle: React.CSSProperties = {
  margin: 5,
  display: 'flex',
  flexDirection: 'row'
}
