import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import { useNavigate } from 'react-router-dom'

import {
  FeatureFlagName,
  SubscriptionStatus,
  useCafeValidationQueryQuery,
  useFeatureFlagQuery,
  useMerchantSubscriptionByMerchantIdQuery,
  useUpdateMerchantActiveMutation,
  useValidatePaymentAccountQuery
} from '../../graphql/types.generated'
import { useErrorHandler } from '../useErrorHandler'

export function useCafeValidationStatus(merchantId: string, organizationId: string) {
  const { hasAnyRole } = usePermissions()
  const errorHandler = useErrorHandler()
  const navigate = useNavigate()

  const { data: subscriptionRequiredFeatureFlag, loading: featureFlagLoading } = useFeatureFlagQuery({
    variables: {
      merchantId,
      featureFlagName: FeatureFlagName.SubscriptionRequired
    }
  })

  const { data: merchantData, loading: loadingMerchant } = useCafeValidationQueryQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })

  const {
    data: merchantPaymentAccountValidationData,
    loading: loadingMerchantPaymentAccountValidation
  } = useValidatePaymentAccountQuery({
    variables: {
      organizationId: organizationId,
      merchantId: merchantId
    }
  })

  const {
    data: organizationPaymentAccountValidationData,
    loading: loadingOrganizationPaymentAccountValidation
  } = useValidatePaymentAccountQuery({
    variables: {
      organizationId: organizationId,
      merchantId: null
    }
  })

  const {
    data: subscriptionData,
    loading: loadingSubscription,
    refetch: refetchSubscriptionData
  } = useMerchantSubscriptionByMerchantIdQuery({
    variables: {
      merchantId: merchantId
    }
  })

  const [update, { loading }] = useUpdateMerchantActiveMutation()

  async function goLive() {
    update({
      variables: {
        id: merchantId,
        active: true
      }
    }).catch(errorHandler)
  }

  function isBillingComplete() {
    if (
      !organizationPaymentAccountValidationData?.validatePaymentAccount?.chargesEnabled &&
      !merchantPaymentAccountValidationData?.validatePaymentAccount?.chargesEnabled
    ) {
      return false
    }

    if (
      !!subscriptionRequiredFeatureFlag?.featureFlag &&
      subscriptionRequiredFeatureFlag.featureFlag.enabled === false
    ) {
      return true
    }

    return (
      subscriptionData?.merchantSubscriptionByMerchantId?.subscriptionStatus === SubscriptionStatus.Current ||
      subscriptionData?.merchantSubscriptionByMerchantId?.subscriptionStatus === SubscriptionStatus.Complete
    )
  }

  function areCafeDetailsComplete() {
    return !!merchantData?.merchant?.taxRate || merchantData?.merchant?.taxRate === 0
  }

  function isMenuComplete() {
    return !!merchantData?.merchant?.products?.length
  }

  function areContactsSetup() {
    return !!merchantData?.merchant?.merchantContacts?.length
  }

  function isKdsComplete() {
    if (hasAnyRole(roles.admin)) {
      return true
    }
    return !!merchantData?.merchant?.kdss?.length || !!merchantData?.merchant?.kdsIntegrations?.length
  }
  function updateValidation() {
    refetchSubscriptionData()
  }

  return {
    areCafeDetailsComplete,
    goLive,
    isBillingComplete,
    isMenuComplete,
    areContactsSetup,
    isKdsComplete,
    isReadyToGoLive: () => isBillingComplete() && isMenuComplete() && areContactsSetup() && isKdsComplete(),
    setUpBilling: () => navigate(`/organizations/${organizationId}/merchants/${merchantId}/billing`),
    setUpMenu: () => navigate(`/organizations/${organizationId}/merchants/${merchantId}/menu`),
    setUpContacts: () => navigate(`/organizations/${organizationId}/merchants/${merchantId}/home/contacts`),
    setUpKds: () => navigate(`/organizations/${organizationId}/merchants/${merchantId}/home/devices`),
    updateValidation,
    loading:
      loading ||
      loadingSubscription ||
      loadingOrganizationPaymentAccountValidation ||
      featureFlagLoading ||
      loadingMerchantPaymentAccountValidation ||
      loadingMerchant
  }
}
