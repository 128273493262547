import { yupResolver } from '@hookform/resolvers/yup'
import { InfoButton } from 'components/InfoButton'
import React from 'react'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../components/form/DefaultFormFooter'
import { PriceField } from '../../../components/form/PriceField'
import { SwitchField } from '../../../components/form/SwitchField'
import { TextField } from '../../../components/form/TextField'
import { ModifierOptionFormModel, modifierOptionSchema } from './modifierOptionValidator'

interface ModifierOptionFormProps {
  onCancel: () => void
  onSubmit: (values: ModifierOptionFormModel) => void
  initialValues: DefaultValues<ModifierOptionFormModel> | undefined
  disableSubmit?: boolean
  isSynced?: boolean
}

export const ModifierOptionForm = (props: ModifierOptionFormProps) => {
  const { onCancel, onSubmit, initialValues, disableSubmit, isSynced } = props
  const formProps = useForm<ModifierOptionFormModel>({
    defaultValues: initialValues,
    resolver: yupResolver(modifierOptionSchema),
    shouldUnregister: false
  })

  const { register, handleSubmit, formState, watch, setValue } = formProps

  const modifierOptionName = watch('name')
  const modifierOptionSelectedName = watch('selectedName')
  const modifierOptionPrintableName = watch('printableName')

  function updateNames() {
    if (modifierOptionName && modifierOptionName.length) {
      if (!modifierOptionSelectedName?.length && !modifierOptionPrintableName?.length) {
        setValue('selectedName', modifierOptionName)
        setValue('printableName', modifierOptionName)
      }
    }
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <div style={fieldIconContainerStyle}>
            <TextField
              style={fieldStyle}
              name='name'
              label='Name'
              inputRef={register}
              defaultValue={initialValues?.name}
              onBlur={updateNames}
            />
            <InfoButton style={infoButtonStyle} title='Name'>
              This is what will appear when a customer is selecting the modifier
            </InfoButton>
          </div>
          <div style={fieldIconContainerStyle}>
            <TextField
              style={fieldWithIconStyle}
              name='selectedName'
              label='Name when selected'
              inputRef={register}
              defaultValue={initialValues?.selectedName ?? ''}
            />
            <InfoButton style={infoButtonStyle} title='Name when selected'>
              This is how the modifier will appear on the KDS and order receipt when this item is selected
            </InfoButton>
          </div>
          <div style={fieldIconContainerStyle}>
            <TextField
              style={fieldWithIconStyle}
              name='printableName'
              label='Printer Output'
              inputRef={register}
              defaultValue={initialValues?.printableName ?? ''}
            />
            <InfoButton style={infoButtonStyle} title='Printer Name'>
              Printed names are sometimes abbreviated. This will be how it's printed on the ticket. If nothing is
              entered here, nothing will print on the ticket
            </InfoButton>
          </div>

          <PriceField
            style={smallFieldStyle}
            disabled={isSynced}
            name='priceCents'
            label='Price'
            defaultValue={initialValues?.priceCents}
          />
          <SwitchField name={'active'} disabled={isSynced} label='Active' defaultValue={initialValues?.active} />
        </div>
        <div>
          <div>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

const infoButtonStyle: React.CSSProperties = {
  marginTop: 10
}

const fieldStyle: React.CSSProperties = {
  minWidth: 200,
  margin: 10
}
const smallFieldStyle: React.CSSProperties = {
  minWidth: 100,
  margin: 10
}

const fieldWithIconStyle: React.CSSProperties = {
  ...fieldStyle,
  marginLeft: 10,
  marginRight: 1,
  marginBottom: 10,
  marginTop: 10
}

const fieldIconContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}
