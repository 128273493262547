import { Section } from '@bloom-coffee/steamed-milk'
import { useCreatePromoCode } from 'hooks/useCreatePromoCode/useCreatePromoCode'
import { useNavigate, useParams } from 'react-router-dom'

import { PromoCodeForm } from './components/PromoCodeForm'
import { PromoCodeFormModel } from './components/promoCodeValidator'

export const CreatePromoCode = () => {
  const navigate = useNavigate()
  const { promotionId } = useParams()

  const { loading, create } = useCreatePromoCode()

  function handleSubmit(input: PromoCodeFormModel) {
    create(input, () => navigate(-1))
  }

  return (
    <Section title={`Create Promo Code for ${promotionId}`}>
      <PromoCodeForm
        promotionId={promotionId!}
        onCancel={() => navigate(-1)}
        onSubmit={handleSubmit}
        disableSubmit={loading}
      />
    </Section>
  )
}
