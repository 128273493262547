import { Button } from '@bloom-coffee/steamed-milk'
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography
} from '@material-ui/core'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { SelectField } from 'components/form/SelectField'
import { TextField } from 'components/form/TextField'
import {
  ExternalDataSource,
  IntegrationType,
  KdsIntegrationListDocument,
  useCreateMerchantKdsIntegrationMutation,
  useMerchantSourceSystemQuery
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { KdsIntegrationLocation, requestFreshKdsInformation, sendFreshKdsTestOrder } from 'util/freshKdsUtils'

type KdsIntegrationSetupInput = {
  accessKey: string
  type: IntegrationType
}

interface AddKdsIntegrationFormProps {
  merchantId: ID
  defaultIntegrationType: IntegrationType
  onCancel: () => void
  onSuccess: () => void
}

export function AddKdsIntegrationModal(props: AddKdsIntegrationFormProps) {
  const { merchantId, onSuccess, onCancel, defaultIntegrationType } = props

  const [candidateAccessKey, setCandidateAccessKey] = useState<string>()
  const [sendOrdersToRdy, setSendOrdersToRdy] = useState(false)
  const [candidateLocations, setCandidateLocations] = useState<KdsIntegrationLocation[]>([])

  const toast = useToast()
  const formProps = useForm<KdsIntegrationSetupInput>({})

  const [executeSave] = useCreateMerchantKdsIntegrationMutation()

  const { register, handleSubmit } = formProps

  const { data: merchantSourceSystemData } = useMerchantSourceSystemQuery({
    variables: {
      merchantId: merchantId
    },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (
      defaultIntegrationType === IntegrationType.Chowly &&
      merchantSourceSystemData?.merchant?.source === ExternalDataSource.Chowly &&
      !!merchantSourceSystemData?.merchant?.sourceSystemId
    ) {
      formProps.setValue('accessKey', merchantSourceSystemData.merchant.sourceSystemId)
      setSendOrdersToRdy(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantSourceSystemData, defaultIntegrationType])

  async function checkAccessKey(input: KdsIntegrationSetupInput) {
    if (input.type === IntegrationType.Chowly) {
      handleSave(input.type, input.accessKey, 'Chowly Location', input.accessKey)
      return
    }
    try {
      const _candidateLocations = await requestFreshKdsInformation(input.accessKey)
      setCandidateLocations(_candidateLocations)
      setCandidateAccessKey(input.accessKey)
    } catch (e) {
      toast.error(`Error: ${e.message}`)
    }
  }

  async function handleCheckConnection(location: KdsIntegrationLocation) {
    const { type, id: locationId } = location
    if (type === IntegrationType.FreshKds) {
      const response = await sendFreshKdsTestOrder(candidateAccessKey!, locationId)
      if (response.success) {
        toast.success(`Successfully sent test order to ${location.name}`)
      } else {
        toast.error(`Error sending test order: ${response.message}`)
      }
    }
  }

  async function handleSave(type: IntegrationType, locationId: string, displayName: string, accessKey: string) {
    try {
      await executeSave({
        variables: {
          input: {
            merchantId,
            type,
            accessKey,
            locationId,
            displayName,
            sendOrdersToRdy
          }
        },
        refetchQueries: [{ query: KdsIntegrationListDocument, variables: { merchantId } }]
      })
      toast.success('Saved KDS integration')
      onSuccess()
    } catch (e) {
      toast.error(`Error: ${e.message}`)
    }
  }
  return (
    <Dialog open={true}>
      <DialogTitle>Add KDS Integration</DialogTitle>
      <DialogContent>
        {!!candidateLocations?.length && (
          <div>
            {candidateLocations.map((location, index) => {
              return (
                <div
                  style={{ display: 'flex', flexDirection: 'column', marginRight: 10, marginBottom: 10 }}
                  key={`${location}.${index}`}
                >
                  <Typography>{location.name}</Typography>
                  <Typography>{`${location.address.line1}\n${location.address.city}, ${location.address.state}`}</Typography>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      onClick={() => handleCheckConnection(location)}
                      label='Send Test Order'
                      style={{ marginRight: 10 }}
                    />
                    <Button
                      label='Add'
                      onClick={() => handleSave(location.type, location.id, location.name, candidateAccessKey!)}
                    />
                  </div>
                </div>
              )
            })}
            <Button onClick={onCancel} label='Cancel' theme='cancel' />
          </div>
        )}
        {!candidateLocations.length && (
          <FormProvider {...formProps}>
            <form onSubmit={handleSubmit(checkAccessKey)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name='accessKey'
                    label='Access Key'
                    inputRef={register}
                    defaultValue={
                      defaultIntegrationType === IntegrationType.Chowly &&
                      merchantSourceSystemData?.merchant?.source === ExternalDataSource.Chowly
                        ? merchantSourceSystemData.merchant.sourceSystemId
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <SelectField fullWidth name='type' label='Integration App' defaultValue={defaultIntegrationType}>
                    <MenuItem value={IntegrationType.FreshKds}>Fresh KDS</MenuItem>
                    <MenuItem value={IntegrationType.Chowly}>Chowly</MenuItem>
                  </SelectField>
                </Grid>

                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Checkbox
                      edge='start'
                      checked={sendOrdersToRdy}
                      onChange={(event, checked) => {
                        setSendOrdersToRdy(checked)
                      }}
                    />
                  }
                  label={'Also Show Orders on RDY Cafe'}
                />
                <Grid item>
                  <DefaultFormFooter onSubmit={handleSubmit(checkAccessKey)} onCancel={onCancel} saveLabel='Continue' />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        )}
      </DialogContent>
    </Dialog>
  )
}
