import { PlatformPricingType, useSavePlatformPricingMutation } from 'graphql/types.generated'
import { PlatformPricingFormModel } from 'views/admin/subscriptions/components/platformPricingValidator'

import { useToast } from '../useToast'

export function useSavePlaformPricing() {
  const toast = useToast()
  const [execute, { loading }] = useSavePlatformPricingMutation()

  async function create(input: PlatformPricingFormModel, onSuccess: () => void) {
    try {
      const {
        platformPricingType: platformPricingTypeString,
        subscriptionMonthlyFeeUsCents: subscriptionMonthlyFeeUsCentsValue,
        subscriptionTerm: subscriptionTermValue,
        ...rest
      } = input

      const platformPricingType = !!platformPricingTypeString
        ? (platformPricingTypeString as PlatformPricingType)
        : null

      if (platformPricingType == null) {
        toast.error('Type must be set')
        return
      }

      const subscriptionMonthlyFeeUsCents = !!subscriptionMonthlyFeeUsCentsValue
        ? subscriptionMonthlyFeeUsCentsValue
        : null
      const subscriptionTerm = !!subscriptionTermValue ? subscriptionTermValue : null

      const res = await execute({
        variables: { input: { ...rest, platformPricingType, subscriptionMonthlyFeeUsCents, subscriptionTerm } }
      })
      if (res.data?.savePlatformPricing) {
        toast.success(`Platform Pricing saved: ${res.data.savePlatformPricing.name}`)
        onSuccess()
      }
    } catch (e) {
      toast.error(`Could not save Platform Pricing ${e}`)
    }
  }

  return { create, loading }
}
