import { ApolloCache, FetchResult } from '@apollo/client'
import {
  ArchiveMerchantPromotionMutation,
  MerchantPromotionInfoFragment,
  useArchiveMerchantPromotionMutation
} from 'graphql/types.generated'

import { useToast } from '../useToast'

export function useArchiveMerchantPromotion() {
  const toast = useToast()
  const [execute, { loading }] = useArchiveMerchantPromotionMutation()

  function updateCache(
    cache: ApolloCache<ArchiveMerchantPromotionMutation>,
    mutationResult: FetchResult<ArchiveMerchantPromotionMutation>
  ) {
    const idToRemove = mutationResult.data?.archivePromotion.id

    if (idToRemove) {
      cache.modify({
        fields: {
          promotionsByMerchant(existingPromotions = [], { readField }) {
            return existingPromotions.filter(
              (pif: MerchantPromotionInfoFragment) => idToRemove !== readField('id', pif)
            )
          }
        }
      })
    }
  }

  async function archive(id: string, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { id },
        update: updateCache
      })

      if (res.data?.archivePromotion?.archived) {
        onSuccess()
      } else {
        throw new Error(`${res.errors}`)
      }
    } catch (e) {
      toast.error(`${e}`)
    }
  }

  return { archive, loading }
}
