import { ReactComponent as ThumbsUp } from 'assets/svg/glyphs/RDY_thumbsup_glyph.svg'
import { useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { ColorHex } from 'theme/ThemeConstants'

export interface GoLiveStepDetails {
  stepName: string
  stepDescription: string
  setupAction: () => void
  required: boolean
  isComplete: () => boolean
  disabled: boolean
  paths: string[]
}

interface GoLiveStepProps {
  stepDetails: GoLiveStepDetails
  index: number
  lastStep: boolean
}

export function GoLiveStep(props: GoLiveStepProps) {
  const { stepDetails, index, lastStep } = props
  const { stepName, stepDescription, setupAction, isComplete: isCompleteFn, disabled, paths } = stepDetails

  const [isHovered, setIsHovered] = useState<boolean>(false)
  let location = useLocation()

  let isCurrentRoute = paths.find((path) => !!matchPath(path, location.pathname))

  const isComplete = isCompleteFn()

  return (
    <div style={stepContainer} key={index}>
      {!lastStep && (
        <div style={relativePosition}>
          <button
            style={{
              ...actionButton,
              ...(isHovered ? hovered : {}),
              color: isComplete ? 'white' : 'inherit'
            }}
            onClick={setupAction}
            onMouseEnter={(e) => {
              setIsHovered(true)
            }}
            onMouseLeave={(e) => {
              setIsHovered(false)
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: isComplete ? 15 : 0
              }}
            >
              <div style={{ ...stepNameStyle, ...(isHovered ? hovered : {}) }}>{stepName}</div>
              {isComplete && <ThumbsUp style={doneIcon} />}
            </div>
            <div style={stepDescriptionStyle}>{stepDescription}</div>
          </button>
        </div>
      )}
      {lastStep && (
        <div style={relativePosition}>
          <button
            style={{ ...goLiveButton, ...(isHovered ? hovered : {}) }}
            onClick={setupAction}
            disabled={disabled}
            onMouseEnter={(e) => {
              setIsHovered(true)
            }}
            onMouseLeave={(e) => {
              setIsHovered(false)
            }}
          >
            <div style={{ ...finalStepNameStyle, ...(isHovered ? hovered : {}) }}>{stepName}</div>
            <div style={stepDescriptionStyle}>{stepDescription}</div>
          </button>
        </div>
      )}
      <div style={relativePosition}>
        {lastStep && (
          <div style={buttonGlyphContainer}>
            <div style={goLiveGlyph}></div>
          </div>
        )}
        {!lastStep && (
          <div style={buttonGlyphContainer}>
            {index === 0 && (
              <div
                style={{
                  ...buttonGlyphStart,
                  ...(isCurrentRoute ? activeRouteButton : {}),
                  ...(isComplete ? completeButton : {})
                }}
              ></div>
            )}
            {index > 0 && (
              <div
                style={{
                  ...buttonGlyphLeft,
                  ...(isCurrentRoute ? activeRouteButton : {}),
                  ...(isComplete ? completeButton : {})
                }}
              ></div>
            )}
            <div
              style={{
                ...buttonGlyphCenter,
                ...(isCurrentRoute ? activeRouteButton : {}),
                ...(isComplete ? completeButton : {})
              }}
            ></div>
            <div
              style={{
                ...buttonGlyphRight,
                ...(isCurrentRoute ? activeRouteButton : {}),
                ...(isComplete ? completeButton : {})
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  )
}

const buttonScale = 50
const goLiveButton: React.CSSProperties = {
  width: buttonScale * 3,
  height: buttonScale * 1.4,
  position: 'relative',
  left: -20,
  top: -8,
  backgroundColor: 'transparent',
  zIndex: 20,
  border: 'none'
}
const hovered: React.CSSProperties = {
  textDecoration: 'underline',
  cursor: 'pointer'
}

const goLiveGlyph: React.CSSProperties = {
  backgroundColor: ColorHex.RDY_PINK,
  border: 'solid 2px',
  borderColor: ColorHex.RDY_FOREST,
  width: buttonScale * 3,
  height: buttonScale * 1.4,
  position: 'relative',
  left: -20,
  top: -8,
  borderRadius: 20,
  zIndex: 15
}

const relativePosition: React.CSSProperties = {
  position: 'relative',
  width: 0,
  height: 0
}

const stepNameStyle: React.CSSProperties = {
  fontFamily: 'Raw-Bold',
  fontSize: 16
}
const finalStepNameStyle: React.CSSProperties = {
  ...stepNameStyle,
  fontSize: 24
}
const stepContainer: React.CSSProperties = {
  display: 'flex',
  width: buttonScale * 3.1,
  cursor: 'pointer'
}

const buttonGlyphContainer: React.CSSProperties = {
  backgroundColor: 'transparent'
}

const buttonBase: React.CSSProperties = {
  backgroundColor: ColorHex.RDY_MALIBU,
  position: 'absolute',
  left: 0,
  top: 0,
  height: buttonScale * 1.1,
  width: buttonScale
}

const buttonGlyphStart: React.CSSProperties = {
  ...buttonBase,
  borderRadius: buttonScale / 2
}
const buttonGlyphLeft: React.CSSProperties = {
  ...buttonBase,
  transform: 'skew(-20deg)'
}
const buttonGlyphCenter: React.CSSProperties = {
  ...buttonBase,
  left: buttonScale * 0.5,
  width: buttonScale * 2
}
const buttonGlyphRight: React.CSSProperties = {
  ...buttonBase,
  left: buttonScale * 2,
  transform: 'skew(-20deg)'
}

const completeButton: React.CSSProperties = {
  backgroundColor: ColorHex.RDY_LIGHT_FOREST,
  color: ColorHex.WHITE,
  top: -5,
  height: buttonScale * 1.3
}

const activeRouteButton: React.CSSProperties = {
  backgroundColor: ColorHex.RDY_MALIBU,
  color: 'black',
  top: -5,
  height: buttonScale * 1.3
}

const stepDescriptionStyle: React.CSSProperties = {
  fontFamily: 'Matter-Medium',
  fontSize: 11,
  width: buttonScale * 2.5,
  paddingLeft: 5
}

const actionButton: React.CSSProperties = {
  position: 'absolute',
  backgroundColor: 'transparent',
  zIndex: 10,
  left: 0,
  top: buttonScale / 15,
  width: buttonScale * 3,
  border: 'none'
}
const doneIcon: React.CSSProperties = {
  fill: 'white',
  height: 15,
  paddingLeft: 10
}
