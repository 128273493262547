export const transform = {
  nullable(v: any, o: any) {
    return o === '' ? null : v
  },

  price(v: any, o: any) {
    if (typeof o === 'string' && o !== '') {
      return Number(o.toString().replace(/[^0-9-]/g, ''))
    }
    return null
  },

  dateToEpoch(date: Date | undefined | null): number | undefined {
    if (date) {
      return date.getTime()
    }
  }
}
