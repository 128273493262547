import { dayHoursSchema } from 'components/hours/validator'
import { ModifierChildSelectionType, OriginType } from 'graphql/types.generated'
import { transform } from 'validation/transform'
import * as yup from 'yup'

import { modifierOptionSchema } from '../../modifiers/CreateModifierOption/modifierOptionValidator'

const modifierForProductSchema = yup.object({
  id: yup.string().nullable(false).required(),
  name: yup.string().label('Name').required(),
  printableName: yup.string().label('Name for Printer (Optional)').nullable(true),
  description: yup.string().label('Description').nullable(true).optional(),
  active: yup.boolean().label('Active').required().default(true),
  priceCents: yup
    .number()
    .transform(transform.nullable)
    .label('Price')
    .default(0)
    .optional()
    .typeError('Price needs to be a number'),
  childSelectionType: yup
    .mixed<ModifierChildSelectionType>()
    .oneOf(Object.values(ModifierChildSelectionType), 'Selection type is a required field')
    .label('Selection type')
    .required(),
  options: yup.array(modifierOptionSchema).optional()
})

export const productImageSchema = yup.object({
  imageUri: yup.string().nullable(false).required(),
  libraryImageId: yup.string().nullable(true).optional()
})

export const productSchema = yup.object({
  id: yup.string().optional(),
  name: yup.string().label('Name').required(),
  printableName: yup.string().label('Name for Printer (Optional)').nullable(true),
  description: yup.string().label('Description').nullable(true).optional().default(null),
  active: yup.boolean().label('Active').required().default(true),
  priceCents: yup
    .number()
    .transform(transform.nullable)
    .label('Price')
    .required()
    .typeError('Price needs to be a number'),
  sizeInGrams: yup
    .number()
    .transform(transform.nullable)
    .label('Size(g)')
    .typeError('Size needs to be a number')
    .nullable(true)
    .optional()
    .default(null)
    .test('size required for beans', 'Size is Required', function (value) {
      return value == null || value === undefined || value > 0
    }),
  originType: yup.string().label('OriginType').nullable(true).optional().default(null),
  catalogIds: yup.array(yup.string().nullable(false)).label('Catalogs').required(),
  categoryId: yup.string().label('Category').required(),
  modifiers: yup.array(modifierForProductSchema).label('Modifiers').nullable(true).optional(),
  coffeeTags: yup.array(yup.string().nullable(false)).label('Coffee Tags').nullable(true).optional(),
  teaTags: yup.array(yup.string().nullable(false)).label('Tea Tags').nullable(true).optional(),
  beanOriginTags: yup
    .array(yup.string().nullable(false))
    .label('Bean Origin Tags')
    .nullable(true)
    .optional()
    .test('single origin', 'One origin is required for Single Origin', function (value) {
      return this.parent.originType !== OriginType.SingleOrigin || (!!value && value.length === 1)
    })
    .test('blend', 'More than one origin is required for Blends', function (value) {
      return this.parent.originType !== OriginType.Blend || (!!value && value.length >= 1)
    }),
  flavorNoteTags: yup
    .array(yup.string().nullable(false))
    .label('Flavor Note Tags')
    .nullable(true)
    .optional()
    .test('max 3', 'There is a maximum of three (3) flavor notes allowed', function (value) {
      return !value || value.length <= 3
    }),
  productImages: yup.array(productImageSchema).label('productImages').nullable(true).optional(),
  roaster: yup.string().label('Roaster').nullable(true).optional().default(null),
  roastType: yup.string().label('Roast Type').nullable(true).optional().default(null),
  inventoryType: yup.string().label('Inventory Type').nullable(false).optional().default('ALWAYS_AVAILABLE'),
  currentQuantity: yup.number().label('Current Quantity').nullable(true).optional().default(0),
  restockQuantity: yup.number().label('Restock Quantity').nullable(true).optional().default(null),
  restockDays: yup.array(yup.number().nullable(false)).label('Restock Days').nullable(true).optional(),
  currentlyAvailable: yup.boolean().label('Currently Available').required().default(null),
  availabilityWindows: yup.array(dayHoursSchema).label('Availability Windows').nullable(true).optional(),
  taxRate: yup
    .number()
    .typeError('Please enter a valid tax rate')
    .test(
      'taxRateValidation',
      'Please enter a valid tax rate',
      (value: any) => !isNaN(value) && value >= 0 && value < 100
    )
    .label('Tax Rate')
    .nullable(true)
    .notRequired()
    .default(null),
  suspendedUntil: yup.string().label(`86'd Until`).nullable().optional(),
  pickUpPriceUsCents: yup
    .number()
    .transform(transform.nullable)
    .label('Pickup Price')
    .typeError('Price needs to be a number')
    .nullable()
    .optional(),
  sourceSystem: yup.string().label(`Source`).nullable().optional()
})

export type ProductFormModel = yup.InferType<typeof productSchema>
export type ProductImageFormModel = yup.InferType<typeof productImageSchema>
export type ModifierForProductFormModel = yup.InferType<typeof modifierForProductSchema>
