import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

interface SwitchFieldProps {
  label: string
  name: string
  defaultValue?: boolean
  onChange?: (value: boolean) => void
  disabled?: boolean
}

export function SwitchField(props: SwitchFieldProps) {
  const { name, defaultValue, label, onChange, disabled } = props
  const { control } = useFormContext()

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ?? true}
          render={(controlProps) => (
            <MuiSwitch
              disabled={disabled}
              {...controlProps}
              onChange={(e) => {
                controlProps.onChange(e.target.checked)
                if (onChange) {
                  onChange(e.target.checked)
                }
              }}
              checked={controlProps.value}
            />
          )}
        />
      }
      label={label}
    />
  )
}
