import { Button } from '@bloom-coffee/steamed-milk'
import { DialogContentText } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useArchivePromotion } from 'hooks/useArchivePromotion/useArchivePromotion'
import React, { useState } from 'react'

import { useArchiveMerchant } from '../../../hooks/useArchiveMerchant'
import { useArchiveOrganization } from '../../../hooks/useArchiveOrganization'

interface ArchiveContainerProps {
  variant: 'merchant' | 'organization' | 'promotion'
  organizationId?: string
  merchantId?: string
  promotionId?: string
  onSuccess: () => void
  style?: React.CSSProperties
}

export const ArchiveContainer = (props: ArchiveContainerProps) => {
  const { merchantId, organizationId, promotionId, onSuccess, variant, style } = props

  const [showDeletePrompt, toggleDeletePrompt] = useState(false)
  const { archive: archiveOrganization, loading: loadingDeleteOrganization } = useArchiveOrganization()
  const { archive: archiveMerchant, loading: loadingDeleteMerchant } = useArchiveMerchant()
  const { archive: archivePromotion, loading: loadingArchivePromotion } = useArchivePromotion()

  async function handleArchive() {
    switch (variant) {
      case 'merchant':
        await archiveMerchant(merchantId!!, organizationId!!, onSuccess)
        break
      case 'organization':
        await archiveOrganization(organizationId!!, onSuccess)
        break
      case 'promotion':
        await archivePromotion(promotionId!!, onSuccess)
    }
  }

  const loading = loadingDeleteOrganization || loadingDeleteMerchant || loadingArchivePromotion

  return (
    <div style={style}>
      <Button disabled={loading} theme='warning' endIcon={<DeleteIcon />} onClick={() => toggleDeletePrompt(true)} />
      <ConfirmationDialog
        title='Are you sure?'
        open={showDeletePrompt}
        onClose={() => toggleDeletePrompt(false)}
        onConfirm={handleArchive}
      >
        <DialogContentText>Are you sure you want to delete this {variant}?</DialogContentText>
      </ConfirmationDialog>
    </div>
  )
}
