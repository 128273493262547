import { Button } from '@bloom-coffee/steamed-milk'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { config } from 'config'
import { roles } from 'hooks/usePermissions/usePermissions'
import React from 'react'

import { MyKdssList } from './components/MyKdssList/MyKdssList'

export function MyDevices() {
  function handleAuthorizeDevice() {
    window.open(`https://${config.auth0Domain}/activate`, '_blank')
  }

  return (
    <>
      <HasAnyRole roleMatchers={[roles.anyOrganizationAdmin]}>
        <Button label='Authorize tablet' onClick={handleAuthorizeDevice} />
      </HasAnyRole>
      <MyKdssList />
    </>
  )
}
