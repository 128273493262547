//replace with material-ui v5 pickers once released
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers'
import { useFieldError } from 'hooks/useFieldError'
import { useController } from 'react-hook-form'
import { parseTime } from 'util/date'

type TimeFieldProps = Omit<KeyboardTimePickerProps, 'onChange' | 'value'> & {
  name: string
  defaultValue?: string
}

export function TimeField(props: TimeFieldProps) {
  const { name, defaultValue, ...rest } = props
  const error = useFieldError<any>({ name })

  const {
    field: { ref, onChange, value, ...fieldRest }
  } = useController({
    name,
    defaultValue: defaultValue || null
  })

  return (
    <KeyboardTimePicker
      {...fieldRest}
      {...rest}
      value={value ? parseTime(value) : null}
      inputRef={ref}
      inputVariant='outlined'
      InputProps={{
        disabled: true
      }}
      onChange={(date, value) => {
        onChange(value)
      }}
      minutesStep={5}
      error={!!error}
      helperText={error?.message}
    />
  )
}
