import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { GetServingLocationsByMerchantIdDocument, useGenerateQrCodeDisplayMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { FulfillmentLocationInfo } from 'models/FulfillmentLocation'
import React, { useEffect } from 'react'
import { getFilename } from 'util/stringUtils'

export interface ServingLocationQRCodeSignProps {
  servingLocation: FulfillmentLocationInfo
  merchantId: string
}

export const ServingLocationQRCodeSign = (props: ServingLocationQRCodeSignProps) => {
  const { servingLocation, merchantId } = props
  const toast = useToast()

  const [execute, { loading }] = useGenerateQrCodeDisplayMutation({
    variables: {
      fulfillmentLocationId: servingLocation.id
    },
    refetchQueries: [{ query: GetServingLocationsByMerchantIdDocument, variables: { merchantId: merchantId! } }]
  })

  useEffect(() => {
    async function createQRCodeDisplay() {
      try {
        await execute()
      } catch (e: any) {
        toast.error(e)
      }
    }
    if (!servingLocation.qrCodeDisplayUrl && !loading) {
      createQRCodeDisplay()
    }
  }, [execute, loading, servingLocation, toast])

  function download() {
    var element = document.createElement('a')
    element.href = servingLocation.qrCodeDisplayUrl!! // URL.createObjectURL(file)
    element.download = getFilename(servingLocation.displayName!!, '.png')
    element.click()
  }

  if (!servingLocation.qrCodeDisplayUrl || loading) {
    return <CircularProgress />
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div style={{ alignItems: 'center' }}>
          <Typography variant='subtitle2'>QR Code value:</Typography>
          <Typography variant='subtitle1'>{servingLocation.appUrl}</Typography>
        </div>
      </Grid>

      <Grid item xs={12}>
        <img
          style={{ width: 300, height: 'auto', border: 'solid 1px', borderRadius: 5, padding: 5 }}
          src={servingLocation.qrCodeDisplayUrl!!}
          alt={`{servingLocation.displayName!!}`}
        ></img>
      </Grid>

      <Grid item xs={12}>
        <Button label='Download' onClick={download} />
      </Grid>
    </Grid>
  )
}
