import { Navigate, Route, Routes } from 'react-router-dom'

import { MerchantFeesContainer } from './components/MerchantFeesContainer'
import { NotificationsContainer } from './components/NotificationsContainer'
import { OrganizationContainer } from './components/OrganizationContainer'
import { PromotionsContainer } from './components/PromotionsContainer'
import { RequisitionsRootMenuContainer } from './requisitions/RequisitionsRootMenuContainer'

export const AdminRoot = () => {
  return (
    <Routes>
      <Route path={`promotions/*`} element={<PromotionsContainer />} />
      <Route path={`notifications/*`} element={<NotificationsContainer />} />
      <Route path={`merchantFees/*`} element={<MerchantFeesContainer />} />
      <Route path={`organizations/*`} element={<OrganizationContainer />} />
      <Route path={`requisitions/*`} element={<RequisitionsRootMenuContainer />} />
      <Route path={'*'} element={<Navigate to={'organizations'} replace={true} />} />
    </Routes>
  )
}
