import { Footer } from 'components/layout/Footer/Footer'
import { Header } from 'components/layout/Header/Header'
import { PrivateRoute } from 'components/router/PrivateRoute'
import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import { logger } from 'logger'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AdminRoot } from './admin/AdminRoot'
import { OnboardingRoot } from './onboarding/OnboardingRoot'
import { OrganizationsRoot } from './organizations/OrganizationsRoot'
import { SquareLoginRedirect } from './square/SquareLoginRedirect'

export function Home() {
  const { hasAnyRole } = usePermissions()

  const hasRole = hasAnyRole(roles.anyOrganizationMember)
  logger.debug('Home', `has role: ${hasRole}`)

  return (
    <div style={homeStyle}>
      <Header />
      <div style={homeContent}>
        {!hasRole && <OnboardingRoot />}
        {hasRole && (
          <Routes>
            <Route
              path='/admin/*'
              element={
                <PrivateRoute roleMatchers={[roles.admin]}>
                  <AdminRoot />
                </PrivateRoute>
              }
            />
            <Route path='/square' element={<SquareLoginRedirect />} />
            <Route path='/organizations/*' element={<OrganizationsRoot />} />
            <Route path='*' element={<Navigate to={'/organizations'} replace={true} />} />
          </Routes>
        )}
      </div>
      <Footer />
    </div>
  )
}

const homeStyle: React.CSSProperties = {
  position: 'relative',
  minHeight: '100vh'
}
const homeContent: React.CSSProperties = {
  paddingBottom: 180
}
