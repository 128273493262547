import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { Route, Routes } from 'react-router-dom'

import { OrganizationPayouts } from './OrganizationPayouts'
import { PayoutDetails } from './PayoutDetails'

interface PayoutContainerProps {
  organizationId: ID
}

export function PayoutContainer(props: PayoutContainerProps) {
  const { organizationId } = props

  return (
    <MenuedContentContainer menuButtonDetails={[]}>
      <Routes>
        <Route path={`payoutDetails/:merchantPayoutIds`} element={<PayoutDetails />} />
        <Route
          path={`*`}
          element={
            <OrganizationPayouts
              organizationId={organizationId}
              payoutDetailBaseUrl={`/organizations/${organizationId}/payouts/payoutDetails/`}
            />
          }
        />
      </Routes>
    </MenuedContentContainer>
  )
}
