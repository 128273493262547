import { Button } from '@bloom-coffee/steamed-milk'
import { Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { dayTitles } from 'util/date'

import { HoursModal } from './HoursModal'
import { DayHoursFormModel, DayHoursModel } from './validator'

interface DaySectionProps {
  saveHours: (hours: DayHoursFormModel) => void
  value: DayHoursModel
  disableEditing?: boolean
}

export function DaySection(props: DaySectionProps) {
  const { value, saveHours, disableEditing } = props
  const [editing, setEditing] = useState(false)

  async function handleSubmit(values: DayHoursFormModel) {
    saveHours(values)
    setEditing(false)
  }

  function handleEdit() {
    setEditing(true)
  }

  return (
    <>
      {editing && (
        <HoursModal
          defaultValues={{ timeIntervals: value.timeIntervals, weekDays: [value.weekDay] }}
          onCancel={() => setEditing(false)}
          onSubmit={handleSubmit}
        />
      )}
      <div style={weekStyle}>
        <Typography align='center' gutterBottom>
          {dayTitles[value.weekDay]}
        </Typography>
        {!value.timeIntervals.length && (
          <Typography align='center' variant='body2' gutterBottom color='textSecondary'>
            None
          </Typography>
        )}
        {value.timeIntervals.map((timeInterval, index) => (
          <Typography align='center' variant='body2' gutterBottom key={index}>
            <span style={{ whiteSpace: 'nowrap' }}>{timeInterval.start}</span>
            {' to '}
            <span style={{ whiteSpace: 'nowrap' }}>{timeInterval.end}</span>
          </Typography>
        ))}
        <Button style={{ margin: 'auto' }} disabled={disableEditing} onClick={handleEdit} label='Update' />
      </div>
    </>
  )
}

const weekStyle: React.CSSProperties = {
  border: '1px solid rgb(224 224 224)',
  borderRadius: 5,
  padding: 10
}
