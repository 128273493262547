import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import {
  Maybe,
  SubscriptionStatus,
  useCancelStripeSubscriptionMutation,
  useSetMerchantSubscriptionMutation,
  useSubscriptionPlansQuery
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { MerchantSubscriptionInfo } from 'models/Subscriptions'
import React, { useState } from 'react'
import { formatSubscriptionPlanCost } from 'util/subscriptions'

interface SetMerchantSubscriptionProps {
  merchantId: string
  merchantSubscription?: Maybe<MerchantSubscriptionInfo>
}

export const SetMerchantSubscription = (props: SetMerchantSubscriptionProps) => {
  const { merchantId, merchantSubscription } = props
  const styles = useStyles()
  const toast = useToast()
  const [subscriptionPlanId, setSubscriptionPlanId] = useState<string | null>(
    merchantSubscription?.subscriptionPlan?.id ?? null
  )
  const [trialEndDate, setTrialEndDate] = useState<Date | null>(
    merchantSubscription?.trialEndDate ? new Date(merchantSubscription.trialEndDate) : null
  )
  const { data: subscriptionPlansData, loading: loadingSubscriptionPlans } = useSubscriptionPlansQuery()
  const [setMerchantSubscriptionPlan, { loading: loadingSetMerchantSubPlan }] = useSetMerchantSubscriptionMutation()
  const [cancelSubscription, { loading: loadingCancelSubscription }] = useCancelStripeSubscriptionMutation()

  async function assignSubscriptionPlan() {
    if (!subscriptionPlanId) {
      toast.error('Invalid Subscription Plan')
      return
    }

    try {
      await setMerchantSubscriptionPlan({
        variables: {
          input: {
            subscriptionProductId: subscriptionPlanId,
            merchantId: merchantId,
            trialEndDate: trialEndDate?.getTime(),
            platformPricingId: null,
            termPricing: null
          }
        }
      })
      toast.success('Subscription Plan Updated')
    } catch (e: any) {
      toast.error(e.message)
    }
  }
  async function onCancelSub() {
    try {
      await cancelSubscription({
        variables: {
          stripeSubscriptionId: merchantSubscription!!.stripeSubscription!!.id
        }
      })
      toast.success('Subscription Canceled')
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  return (
    <Section title='Assign Subscription'>
      {(loadingSubscriptionPlans || loadingSetMerchantSubPlan) && <CircularProgress />}
      {!!subscriptionPlansData && (
        <div style={{ flexDirection: 'column', display: 'flex', alignContent: 'left' }}>
          <div
            style={{ flexDirection: 'row', display: 'flex', alignContent: 'left', paddingBottom: 2, flexWrap: 'wrap' }}
          >
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignContent: 'left',
                paddingBottom: 1,
                flexWrap: 'wrap'
              }}
            >
              <InputLabel>Subscription Plan Type</InputLabel>
              <Select
                className={clsx(styles.dropdown)}
                fullWidth={false}
                label='Subscription Plan Type'
                onChange={(e) => setSubscriptionPlanId(e.target.value as string)}
                defaultValue={merchantSubscription?.subscriptionPlan?.id}
              >
                <MenuItem value=''>
                  <em>Select a Subscription Plan</em>
                </MenuItem>
                {subscriptionPlansData.subscriptionPlans.map((plan) => {
                  return <MenuItem value={plan.id}>{`${plan.name} - ${formatSubscriptionPlanCost(plan)}`}</MenuItem>
                })}
              </Select>
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignContent: 'left',
                paddingBottom: 1
              }}
            >
              <InputLabel>Billing Start Date (Optional)</InputLabel>
              <KeyboardDatePicker
                value={trialEndDate}
                onChange={(date) => setTrialEndDate(date)}
                format='MM/dd/yyyy'
                className={clsx(styles.dateField)}
              />
            </div>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex', alignContent: 'left', flexWrap: 'wrap', paddingTop: 5 }}>
            <Button onClick={assignSubscriptionPlan} disabled={!subscriptionPlanId} label='Assign Plan to Merchant' />
            {!!merchantSubscription?.stripeSubscription &&
              merchantSubscription?.subscriptionStatus !== SubscriptionStatus.Complete && (
                <Button
                  onClick={onCancelSub}
                  theme='warning'
                  label='Cancel Subscription'
                  style={{ marginLeft: 10 }}
                  endIcon={loadingCancelSubscription && <CircularProgress color='inherit' size={20} />}
                />
              )}
          </div>
        </div>
      )}
    </Section>
  )
}

const useStyles = makeStyles((theme) => ({
  dropdown: { maxWidth: 400 },
  dateField: { maxWidth: 200 }
}))
