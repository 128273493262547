import { useViewOrganizationQuery } from 'graphql/types.generated'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { HeaderMenuBar } from './HeaderMenuBar'
import { HeaderTitleBar } from './HeaderTitleBar'
import { UserTitle } from './UserTitle'

export function OrganizationHeader() {
  const { organizationId } = useParams()

  const { data } = useViewOrganizationQuery({
    variables: {
      id: organizationId!
    }
  })
  const [orgTitleStyle, setOrgTitleStyle] = useState(orgTitleBaseStyle)
  const navigate = useNavigate()
  return (
    <div>
      <HeaderTitleBar
        title={
          <>
            {!!data?.organization?.name && (
              <div
                style={orgTitleStyle}
                onClick={() => navigate(`/organizations/${organizationId}`)}
                onMouseEnter={(e) => {
                  setOrgTitleStyle(orgTitleHoverStyle)
                }}
                onMouseLeave={(e) => {
                  setOrgTitleStyle(orgTitleBaseStyle)
                }}
              >
                {data.organization.name}
              </div>
            )}
          </>
        }
        subTitle={<UserTitle />}
      />
      <HeaderMenuBar
        menuButtonDetails={[
          {
            link: `/organizations/${organizationId}/merchants`,
            paths: [
              '/organizations/:organizationId/merchants',
              '/organizations/:organizationId/merchants/new',
              '/organizations/:organizationId/merchants/importFromSquare'
            ],
            text: 'My Cafes'
          },
          {
            link: `/organizations/${organizationId}/billing`,
            paths: ['/organizations/:organizationId/billing/*'],
            text: 'Billing'
          },
          {
            link: `/organizations/${organizationId}/payouts`,
            paths: ['/organizations/:organizationId/payouts/*'],
            text: 'Payouts'
          },
          {
            link: `/organizations/${organizationId}/settings`,
            paths: ['/organizations/:organizationId/settings/*'],
            text: 'Settings'
          }
        ]}
      />
    </div>
  )
}

const orgTitleBaseStyle: React.CSSProperties = { height: 40 }
const orgTitleHoverStyle: React.CSSProperties = {
  ...orgTitleBaseStyle,
  cursor: 'pointer',
  textDecoration: 'underline'
}
