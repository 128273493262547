import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, DialogContentText } from '@material-ui/core'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { KdsIntegrationListDocument, useUpdateMerchantKdsIntegrationActiveMutation } from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import React, { useState } from 'react'

interface UpdateKdsIntegrationActiveButtonProps {
  kdsIntegrationId: ID
  currentlyActive: boolean
  merchantId: ID
}

export function UpdateKdsIntegrationActiveButton(props: UpdateKdsIntegrationActiveButtonProps) {
  const { kdsIntegrationId, merchantId, currentlyActive } = props
  const [opened, setOpened] = useState(false)
  const [update, { loading }] = useUpdateMerchantKdsIntegrationActiveMutation()
  const errorHandler = useErrorHandler()

  async function handleConfirm() {
    try {
      await update({
        variables: {
          id: kdsIntegrationId,
          active: !currentlyActive
        },
        refetchQueries: [{ query: KdsIntegrationListDocument, variables: { merchantId } }]
      })
      setOpened(false)
    } catch (e) {
      errorHandler(e)
    }
  }

  return (
    <>
      <Button
        style={buttonStyle}
        onClick={currentlyActive ? () => setOpened(true) : handleConfirm}
        disabled={loading}
        label={currentlyActive ? 'Inactivate' : 'Activate'}
        endIcon={loading && <CircularProgress color='inherit' size={16} />}
      ></Button>
      <ConfirmationDialog
        title='Inactivate KDS Integration'
        open={opened}
        onClose={() => setOpened(false)}
        onConfirm={handleConfirm}
      >
        <DialogContentText>
          Inactivate this KDS Integration? Orders will no longer be sent to this location.
        </DialogContentText>
      </ConfirmationDialog>
    </>
  )
}

const buttonStyle: React.CSSProperties = {
  marginRight: 5
}
