import { parse } from 'date-fns'

export const dayTitles = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function parseTime(value: string) {
  return parse(value, 'hh:mm aa', Date.now())
}

function instantToDate(instant: number): Date {
  return new Date(instant)
}
function militaryToReadable12Hour(mHour: number, minutes: number): string {
  let sHour = 12
  if (mHour === 0) {
    sHour = 12
  } else if (mHour <= 12) {
    sHour = mHour
  } else if (mHour > 12) {
    sHour = mHour - 12
  }
  let amPm = mHour < 11 || mHour === 24 ? 'AM' : 'PM'

  const mins = minutes < 10 ? `0${minutes}` : minutes.toString()
  return `${sHour}:${mins} ${amPm}`
}

export function readableDateFromInstant(instant?: number): string {
  if (!instant) return ''
  const date = instantToDate(instant)
  return date.toLocaleString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  } as Intl.DateTimeFormatOptions)
}

export function readableTimeFromInstant(instant: number): string {
  const date = instantToDate(instant)
  return militaryToReadable12Hour(date.getHours(), date.getMinutes())
}

export function readableDateTimeFromInstant(instant: number): string {
  const date = instantToDate(instant)
  return date.toLocaleString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  } as Intl.DateTimeFormatOptions)
}
