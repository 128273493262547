import { Button } from '@bloom-coffee/steamed-milk'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
var QRCode = require('qrcode.react')

export interface QRCodeContainerProps {
  link: string
  downloadFilename?: string
}

export const QRCodeContainer = (props: QRCodeContainerProps) => {
  const { link, downloadFilename } = props

  const qrCodeId = 'qrCodeId'

  function download() {
    const canvas = document.getElementById(qrCodeId) as HTMLCanvasElement
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = downloadFilename ?? 'QRCode.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div style={{ alignItems: 'center' }}>
          <Typography variant='subtitle2'>QR Code value:</Typography>
          <Typography variant='subtitle1'>{link}</Typography>
        </div>
      </Grid>

      <Grid item xs={12}>
        {link && <QRCode id={qrCodeId} size={296} value={link} />}
      </Grid>

      <Grid item xs={12}>
        <Button label='Download' onClick={download} />
      </Grid>
    </Grid>
  )
}
