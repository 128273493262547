import { CategoryType, ProductTag, ProductTagType } from 'graphql/types.generated'

const flavoredTag = 'Flavored'
const icedTag = 'Iced'
const espressoTags = ['Cappuccino', 'Latte', 'Cortado', 'Espresso', 'Americano', 'Flat White', 'Macchiato', 'Mocha']
const brewTags = ['Cold Brew', 'Drip', 'Filter', 'Pour Over']
const teaTags = ['Black', 'Green', 'Jasmine', 'Herbal', 'Matcha', 'Chai']

const categoryTypesAllowingCoffeeTags = [CategoryType.Coffee, CategoryType.SpecialtyBeverages]
const categoryTypesAllowingTeaTags = [CategoryType.Tea]
const categoryTypesAllowingFlavorNoteTags = [CategoryType.RetailBeans]
const categoryTypesAllowingBeanOriginTags = [CategoryType.RetailBeans]
const flavorValues = [
  'Almond',
  'Apple',
  'Banana',
  'Blackberry',
  'Blackcurrant',
  'Blue',
  'Blueberry',
  'Butter',
  'Butterscotch',
  'Candied',
  'Candy',
  'Cantaloupe',
  'Caramel',
  'Cherry',
  'Chipotle',
  'Chocolate',
  'Cinnamon',
  'Citrus',
  'Coconut',
  'Cookie',
  'Cranberry',
  'Crème',
  'Cucumber',
  'Cupcake',
  'Elderflower',
  'Fruit',
  'Fudge',
  'Ginger',
  'Grapefruit',
  'Grenadine',
  'Guava',
  'Habanero',
  'Hazelnut',
  'Hibiscus',
  'Honey',
  'Huckleberry',
  'Irish',
  'Kiwi',
  'Lavender',
  'Lemon',
  'Lime',
  'Lychee',
  'Macadamia',
  'Mandarin',
  'Mango',
  'Maple',
  'Marshmallow',
  'Melon',
  'Mint',
  // 'Mocha',
  'Nut',
  'Orange',
  'Passion',
  'Peach',
  'Peanut',
  'Pear',
  'Pecan',
  'Peppermint',
  'Pie',
  'Pineapple',
  'Pineberry',
  'Pistachio',
  'Pomegranate',
  'Praline',
  'Pumpkin',
  'Raspberry',
  'Rose',
  'Smoke',
  'Spice',
  'Strawberry',
  'Tangerine',
  'Tart',
  'Tiki',
  'Tiramisu',
  'Toffee',
  'Turmeric',
  'Vanilla',
  'Violet',
  'Watermelon',
  'Wild'
]

export const flavorNoteTagOptions = ['Fruity', 'Floral', 'Bold']
export const beanOriginTagOptions = [
  'Australia',
  'Bolivia',
  'Burundi',
  'Brazil',
  'Cameroon',
  'Chile',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Democratic Republic of the Congo',
  'Dominican Republic',
  'El Salvador',
  'Ecuador',
  'Ethiopia',
  'Guatemala',
  'Guinea',
  'Haiti',
  'Honduras ',
  'India',
  'Indonesia',
  'Ivory Coast',
  'Jamaica',
  'Kenya',
  'Kukgar',
  'Madagascar',
  'Malawi',
  'Mexico',
  'Nepal',
  'Nicaragua',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Rwanda',
  'Tanzania',
  'Thailand',
  'Timor',
  'Togo',
  'Uganda',
  'United States of America',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
]

export const allowedCoffeeTags = [icedTag, flavoredTag, ...espressoTags, ...brewTags]
export const allowedTeaTags = [icedTag, ...teaTags]
export const allDrinkTags = [icedTag, ...teaTags, flavoredTag, ...espressoTags, ...brewTags]

export function areTagsAllowedForCategory(categoryType: CategoryType, tagType: ProductTagType): boolean {
  switch (tagType) {
    case ProductTagType.Coffee:
      return categoryTypesAllowingCoffeeTags.indexOf(categoryType) >= 0
    case ProductTagType.Tea:
      return categoryTypesAllowingTeaTags.indexOf(categoryType) >= 0
    case ProductTagType.FlavorNotes:
      return categoryTypesAllowingFlavorNoteTags.indexOf(categoryType) >= 0
    case ProductTagType.BeanOrigin:
      return categoryTypesAllowingBeanOriginTags.indexOf(categoryType) >= 0
    default:
      return false
  }
}

export function getCoffeeTagSuggestions(productName: string): string[] {
  let newTags = []
  let flavorMatches = flavorValues.filter((t) => productName.toLowerCase().indexOf(t.toLowerCase()) >= 0)
  if (flavorMatches.length) {
    newTags.push(flavoredTag)
  }

  if (isProductCold(productName)) {
    newTags.push(icedTag)
  }

  const coffeeTags = [...espressoTags, ...brewTags]
  let drinkMatches = coffeeTags.filter((t) => productName.toLowerCase().indexOf(t.toLowerCase()) >= 0)
  drinkMatches.forEach((t) => newTags.push(t))

  return newTags
}

export function getTeaTagSuggestions(productName: string): string[] {
  let newTags = []
  let flavorMatches = flavorValues.filter((t) => productName.toLowerCase().indexOf(t.toLowerCase()) >= 0)
  if (flavorMatches.length) {
    newTags.push(flavoredTag)
  }

  if (isProductCold(productName)) {
    newTags.push(icedTag)
  }

  let drinkMatches = teaTags.filter((t) => productName.toLowerCase().indexOf(t.toLowerCase()) >= 0)
  drinkMatches.forEach((t) => newTags.push(t))
  return newTags
}

export function generateFullTagsFromStrings(tags: (string | undefined)[], tagType: ProductTagType) {
  return (
    tags?.map((t) => {
      return {
        value: t,
        tagType: tagType
      } as ProductTag
    }) || []
  )
}

export function isProductCold(name: string): boolean {
  const coldWords = ['iced', 'cold']
  return textContainsWords(name, coldWords)
}

function textContainsWords(text: string, words: string[]): boolean {
  let found = false
  words.forEach((w) => {
    if (text.toLowerCase().indexOf(w) >= 0) {
      found = true
    }
  })
  return found
}
