import { Button, Section } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  lighten,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { Alert } from '@material-ui/lab'
import ImageAsset from 'assets/ImageResource'
import SquareLogo from 'assets/img/SquareLogo.png'
import clsx from 'clsx'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { NumericField } from 'components/form/NumericField'
import { days } from 'components/form/WeekDaysField'
import { HoursDisplay } from 'components/hours/HoursDisplay'
import { DayHoursFormModel } from 'components/hours/validator'
import {
  CategoryType,
  ExternalDataSource,
  InventoryType,
  Maybe,
  ProductTagType,
  TopModifierFragment,
  useCatalogListQuery
} from 'graphql/types.generated'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { DefaultValues, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import {
  convertAndGroupAvailabilityWindows,
  convertDayHoursModel,
  convertToDayHoursFormModel,
  groupAvailabilityWindows
} from 'util/merchantHours'
import { getProductIcon } from 'util/productImage'
import { generateFullTagsFromStrings } from 'util/productTags'
import {
  ModifierForProductFormModel,
  ProductFormModel,
  ProductImageFormModel,
  productSchema
} from 'views/products/CreateProduct/productValidator'

import { ErrorMessage } from '../../../../components/form/ErrorMessage'
import { SwitchField } from '../../../../components/form/SwitchField'
import { ModifierForProductFormSection } from '../../CreateProduct/ModifierForProductFormSection'
import { SelectModifiersContainer } from '../../CreateProduct/SelectModifiersContainer'
import { convertTopModiferToFormModel } from '../../UpdateProduct/convertProductDetailsToFormModel'
import { ImageSelectionLink } from '../ImageSelection/ImageSelectionLink'
import { ProductDetails } from './ProductDetails'
import { ProductPricing } from './ProductPricing'
import { ProductTags } from './ProductTags'
import { SuspendedUntil } from './SuspendedUntil'

const categoriesWithoutModifiers = [CategoryType.RetailBeans]

interface ProductFormProps {
  organizationId: ID
  merchantId: ID
  initialValues?: DefaultValues<ProductFormModel> | undefined
  onCancel(): void
  onSubmit(values: ProductFormModel): void
  disableSubmit?: boolean
  isSynced?: boolean
}
export function ProductForm(props: ProductFormProps) {
  const styles = useStyles()

  const { initialValues, onCancel, onSubmit, merchantId, organizationId, disableSubmit, isSynced } = props
  const formProps = useForm<ProductFormModel>({
    defaultValues: initialValues,
    resolver: yupResolver(productSchema),
    shouldUnregister: false
  })

  const { control, register, handleSubmit, formState, setValue, getValues, watch } = formProps

  const { append, fields, remove, move, swap } = useFieldArray<ModifierForProductFormModel>({
    control,
    name: 'modifiers',
    keyName: 'key' as any
  })

  const { data: catalogData, loading: loadingCatalogs } = useCatalogListQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })

  const productName = watch('name')
  const catalogIds = watch('catalogIds')
  const coffeeTagValues = watch('coffeeTags', []) as string[]
  const teaTagValues = watch('teaTags', []) as string[]
  const currentlyAvailable = watch('currentlyAvailable')

  const [showModifierSelection, setShowModifierSelection] = useState(false)
  const [defaultImage, setDefaultImage] = useState<ImageAsset>()
  const [productImages, setProductImages] = useState<ProductImageFormModel[]>(
    (props.initialValues?.productImages as ProductImageFormModel[]) || []
  )

  const [categoryType, setCategoryType] = useState<CategoryType | null>()
  const [inventoryType, setInventoryType] = useState<InventoryType>(
    (props.initialValues?.inventoryType as InventoryType) || InventoryType.AlwaysAvailable
  )
  const [restockDays, setRestockDays] = useState<number[]>((props.initialValues?.restockDays as number[]) ?? [])
  const [alwaysAvailable, setAlwaysAvailable] = useState(!props.initialValues?.availabilityWindows?.length)
  const [editingInventory, setEditingInventory] = useState(
    props.initialValues?.inventoryType === InventoryType.LimitedQuantity ||
      props.initialValues?.currentlyAvailable === false
  )

  function handleSelection(modifier: ModifierForProductFormModel) {
    append(modifier)
    setShowModifierSelection(false)
  }
  function updateProductImages(images: ProductImageFormModel[]) {
    setProductImages(images)
    setValue('productImages', images)
  }

  function updateRestockDays(days: number[]) {
    setRestockDays(days)
    setValue('restockDays', days)
  }

  const updateDefaultImage = useCallback(
    (name: string, coffeeTags: string[], teaTags: string[], categoryType: CategoryType) => {
      const productTags = generateFullTagsFromStrings(coffeeTags, ProductTagType.Coffee).concat(
        generateFullTagsFromStrings(teaTags, ProductTagType.Tea)
      )
      const updatedDefault = getProductIcon(name, productTags, [], [], categoryType, initialValues?.id || '0')
      if (updatedDefault.imageAsset?.type === 'image') {
        setDefaultImage(updatedDefault?.imageAsset?.icon)
      } else {
        setDefaultImage(undefined)
      }
    },
    [initialValues?.id]
  )
  useEffect(() => {
    if (productName && coffeeTagValues && teaTagValues && categoryType) {
      updateDefaultImage(productName, coffeeTagValues, teaTagValues, categoryType)
    }
  }, [productName, coffeeTagValues, teaTagValues, categoryType, updateDefaultImage])

  function modifiersAreAllowed() {
    return modifiersAreAllowedByCategory(categoryType)
  }

  function modifiersAreAllowedByCategory(categoryType: Maybe<CategoryType>) {
    return !categoryType || categoriesWithoutModifiers.indexOf(categoryType) === -1
  }

  async function deleteImage(index: number) {
    let newImags = productImages.slice()
    newImags.splice(index, 1)
    updateProductImages(newImags)
  }

  async function handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return
    }
    move(result.source.index, result.destination.index)
  }

  function updateModifierDetails(updatedModifier?: TopModifierFragment) {
    if (!updatedModifier) {
      return
    }
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].id !== updatedModifier.id) {
        continue
      }
      let updatedModifierField = convertTopModiferToFormModel(updatedModifier!!)
      append(updatedModifierField)
      swap(i, fields.length)
      remove(fields.length)
    }
  }

  const [availabilityWindows, setAvailabilityWindows] = useState(
    convertAndGroupAvailabilityWindows(props.initialValues?.availabilityWindows) ?? []
  )

  async function saveAvailability(value: DayHoursFormModel) {
    let updatedAvailabilityWindows = availabilityWindows.filter((aw) => !value.weekDays.includes(aw.weekDay))

    updatedAvailabilityWindows.push(...convertDayHoursModel(value))

    let updated = groupAvailabilityWindows(updatedAvailabilityWindows)

    setAvailabilityWindows(updated)
    setValue(
      'availabilityWindows',
      updated.map((u) => convertToDayHoursFormModel(u))
    )
  }

  function updateAlwaysAvailable(value: boolean) {
    setAlwaysAvailable(value)
    if (value) {
      setAvailabilityWindows([])
      setValue('availabilityWindows', [])
    }
  }

  function categoryUpdated(categoryType: CategoryType | null) {
    setCategoryType(categoryType)

    if (!modifiersAreAllowedByCategory(categoryType) && getValues('modifiers')?.length) {
      setValue('modifiers', [])
    }
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={formContainerStyle}>
          <div style={flexRowStyle}>
            <div style={mainDetailsStyle}>
              {isSynced && (
                <Alert
                  severity='info'
                  style={{ marginBottom: 10 }}
                  icon={
                    initialValues?.sourceSystem === ExternalDataSource.Square ? (
                      <img style={{ width: 50, paddingRight: 10, height: 42 }} src={SquareLogo} alt={'Square Sync'} />
                    ) : (
                      <></>
                    )
                  }
                >
                  {`This product is being synced${
                    initialValues?.sourceSystem ? ` from ${initialValues.sourceSystem}` : ''
                  }. To make changes to pricing, modifiers or options please make changes in
                  the source system. Changes to names, pictures, and categories can be made here and will not be overrided by the syncing process.`}
                </Alert>
              )}
              <ProductDetails
                initialValues={initialValues}
                updateCategoryType={categoryUpdated}
                isSynced={isSynced}
                merchantId={merchantId}
              />
              <ProductPricing merchantId={merchantId} isSynced={isSynced} initialValues={initialValues} />
              <ProductTags categoryType={categoryType ?? null} initialValues={initialValues} />
            </div>
            <Section title='Product Image' variant='subsection' style={imageContainerStyle}>
              {!!defaultImage && !productImages.length && (
                <div className={clsx(styles.defaultImageContainer)}>
                  <img src={(defaultImage as any).default} className={clsx(styles.defaultImage)} alt='' />
                  <Typography variant='subtitle2'>
                    This image is automatically generated based on product category and tags.
                  </Typography>
                  <Typography variant='subtitle2'>You can upload your own image below</Typography>
                </div>
              )}
              {productImages.length === 0 && (
                <ImageSelectionLink
                  merchantId={merchantId}
                  updateProductImages={updateProductImages}
                  productImages={productImages}
                />
              )}
              {!!productImages &&
                productImages.length > 0 &&
                productImages.map((productImage, index) => {
                  return (
                    <Paper key={index} variant={'outlined'} className={clsx(styles.imageContainer)} elevation={0}>
                      <Button theme='warning' key={index} endIcon={<DeleteIcon />} onClick={() => deleteImage(index)} />
                      <img src={productImage.imageUri} alt=''></img>
                    </Paper>
                  )
                })}
            </Section>
          </div>

          <Section title='Menus' variant='subsection'>
            {loadingCatalogs && <CircularProgress />}
            {catalogData?.merchant?.catalogs?.map((catalog, index) => {
              return (
                <div key={`${catalog!!.id}${index}`} style={{ marginLeft: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        edge='start'
                        checked={catalogIds.includes(catalog!!.id)}
                        onChange={(event, checked) => {
                          const index = catalogIds.indexOf(catalog!!.id)
                          const updatedCatalogIds = [...catalogIds]

                          if (checked && index === -1) {
                            updatedCatalogIds.push(catalog!!.id)
                          } else if (index > -1) {
                            updatedCatalogIds.splice(index, 1)
                          }
                          setValue('catalogIds', updatedCatalogIds)
                        }}
                      />
                    }
                    label={catalog!!.name}
                  />
                </div>
              )
            })}
            {!catalogData?.merchant?.catalogs?.length && <Typography>No Menus</Typography>}
          </Section>

          {modifiersAreAllowed() && (
            <Section title='Modifiers' variant='subsection'>
              <ErrorMessage name={'modifiers'} />

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='modifiers' isDropDisabled={isSynced}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      className={clsx(styles.dropContainer, snapshot.isDraggingOver && styles.dropContainerDragging, {
                        width: '100%'
                      })}
                      ref={provided.innerRef}
                    >
                      {fields.map((field, index) => {
                        function handleRemove() {
                          remove(index)
                        }

                        return (
                          <Draggable key={`test[${index}]`} draggableId={`item-${index}`} index={index}>
                            {(provided, snapshot) => (
                              <Paper
                                variant={snapshot.isDragging ? 'elevation' : 'outlined'}
                                className={clsx(styles.listItem, snapshot.isDragging && styles.listItemDragging)}
                                elevation={snapshot.isDragging ? 4 : 0}
                                {...provided.draggableProps}
                                innerRef={provided.innerRef}
                              >
                                <span {...provided.dragHandleProps} className={clsx(styles.dragHandle)}>
                                  <DragIndicatorIcon style={{ float: 'left' }} />
                                </span>
                                <ModifierForProductFormSection
                                  isSynced={isSynced}
                                  key={(field as any).key}
                                  defaultValues={field}
                                  onRemove={handleRemove}
                                  onClose={updateModifierDetails}
                                />
                              </Paper>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {!showModifierSelection && (
                <Button
                  disabled={isSynced}
                  label='Add Modifier'
                  endIcon={<AddIcon />}
                  onClick={() => setShowModifierSelection(true)}
                />
              )}
              {showModifierSelection && (
                <SelectModifiersContainer
                  handleSelection={handleSelection}
                  merchantId={merchantId}
                  organizationId={organizationId}
                  onCancel={() => setShowModifierSelection(false)}
                />
              )}
            </Section>
          )}

          <Accordion
            style={accordianStyle}
            expanded={!alwaysAvailable}
            onChange={(e) => updateAlwaysAvailable(!alwaysAvailable)}
          >
            <AccordionSummary
              style={{
                fontFamily: 'Matter-Medium',
                fontSize: 'large',
                fontWeight: '300'
              }}
            >
              Limited Availability Schedule
            </AccordionSummary>
            <AccordionDetails>
              <HoursDisplay disableEditing={isSynced} values={availabilityWindows} saveHours={saveAvailability} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={accordianStyle}
            expanded={inventoryType === InventoryType.LimitedQuantity || !currentlyAvailable || editingInventory}
            onChange={(e) => setEditingInventory(!editingInventory)}
          >
            <AccordionSummary
              style={{
                fontFamily: 'Matter-Medium',
                fontSize: 'large',
                fontWeight: '300'
              }}
            >
              Limited Inventory Management
            </AccordionSummary>
            <AccordionDetails>
              <div style={flexRowStyle}>
                <Section title='Item Inventory' variant='subsection'>
                  <RadioGroup
                    row
                    aria-label='Inventory Type'
                    name='inventoryType'
                    defaultValue={initialValues?.inventoryType ?? InventoryType.AlwaysAvailable}
                    onChange={(event) => {
                      setInventoryType(getValues('inventoryType') as InventoryType)
                    }}
                    style={{ paddingBottom: 10 }}
                  >
                    <FormControlLabel
                      value={InventoryType.AlwaysAvailable}
                      key={InventoryType.AlwaysAvailable}
                      control={<Radio disabled={isSynced} color='primary' />}
                      label='Always Available'
                      inputRef={register}
                    />
                    <FormControlLabel
                      value={InventoryType.LimitedQuantity}
                      key={InventoryType.LimitedQuantity}
                      control={<Radio disabled={isSynced} color='primary' />}
                      label='Limited Quantity'
                      inputRef={register}
                    />
                  </RadioGroup>
                  {inventoryType === InventoryType.AlwaysAvailable && (
                    <SwitchField disabled={isSynced} name='currentlyAvailable' label='Available' />
                  )}
                  {inventoryType === InventoryType.LimitedQuantity && (
                    <NumericField
                      disabled={isSynced}
                      fullWidth
                      name='currentQuantity'
                      label='Current Quantity'
                      defaultValue={initialValues?.currentQuantity ?? 0}
                    />
                  )}
                </Section>
                <Section
                  title='Automatic Inventory Restocking'
                  variant='subsection'
                  subTitle={
                    inventoryType === InventoryType.LimitedQuantity
                      ? 'Before the cafe opens on the selected day(s), the Current Quantity will be increased to the Restock Quantity if the Current Quantity is less'
                      : 'Before the cafe opens on the selected day(s), the product will be made available again'
                  }
                >
                  <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
                    {days.map((d, index) => {
                      function toggleDay() {
                        if (!restockDays.includes(index)) {
                          updateRestockDays([...restockDays, index])
                        } else {
                          updateRestockDays(restockDays.filter((item) => item !== index))
                        }
                      }
                      return (
                        <div key={index} style={{ flexDirection: 'column' }}>
                          <div style={{ alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>
                            {d}
                          </div>
                          <Checkbox disabled={isSynced} onChange={toggleDay} checked={restockDays.includes(index)} />
                        </div>
                      )
                    })}
                  </div>
                  {inventoryType === InventoryType.LimitedQuantity && (
                    <NumericField
                      disabled={isSynced}
                      fullWidth
                      name='restockQuantity'
                      label='Restock Quantity'
                      defaultValue={initialValues?.restockQuantity ?? 0}
                    />
                  )}
                </Section>
              </div>
            </AccordionDetails>
          </Accordion>
          {!!initialValues?.id && <SuspendedUntil />}
          <DefaultFormFooter
            disabled={disableSubmit}
            onCancel={onCancel}
            onSubmit={handleSubmit(onSubmit)}
            submitting={formState.isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  )
}

const accordianStyle: React.CSSProperties = {
  marginLeft: 20,
  marginRight: 20,
  marginTop: 5,
  marginBottom: 5
}

const mainDetailsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap'
}
const imageContainerStyle: React.CSSProperties = {
  alignContent: 'flex-start',
  alignSelf: 'flex-start'
}
const flexRowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}

const formContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column'
}

const useStyles = makeStyles((theme) => ({
  switchWithLabel: {
    display: 'flex',
    flexDirection: 'row',
    verticalAlign: 'middle',
    alignItems: 'center'
  },
  verticalStretch: {
    alignSelf: 'stretch'
  },
  verticalSpace: {
    paddingTop: 10
  },
  flexFloatRight: {
    alignSelf: 'flex-end',
    alignContent: 'flex-end'
  },
  flexColumn: {
    flexDirection: 'column'
  },
  flexRow: {
    flexDirection: 'row',
    alignContent: 'flex-end'
  },
  hidden: {
    display: 'none'
  },
  imageContainer: {
    width: 'fit-content',
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  },
  dropContainer: {
    transition: '0.1s all ease-in-out'
  },
  dropContainerDragging: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8)
  },
  listItem: {
    alignItems: 'top',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    width: '100%'
  },
  listItemDragging: {
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  dragHandle: {
    paddingTop: 13
  },
  defaultImageContainer: {
    display: 'flex',
    width: '100%',
    //maxWidth: 200,
    flexDirection: 'column',
    marginBottom: 12
  },
  defaultImage: {
    alignSelf: 'left',
    width: '50%'
  }
}))
