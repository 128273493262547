const parseValue = (number: string) => {
  return Number(number.toString().replace(/[^0-9-]/g, ''))
}

export function formatValueAsPrice(value: number) {
  return formatter.format(value)
}

export function fromCents(value: any) {
  if (typeof value === 'string') {
    return parseValue(value)
  }
  return value === 0 ? 0 : value / 100
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export function convertUnitsFromCentsToDollars(cents: number): number {
  return cents / 100
}
export function formatUsCentsAsUsDollar(cents: number): string {
  const dollars = (cents / 100).toFixed(2).toString()
  return `$ ${dollars}`
}
export function formatPercentage(percent: number): string {
  return `${percent}%`
}

export function formatUsPhoneWithBracketsAndHyphen(input: string | undefined | null): string | undefined {
  if (!input) {
    return
  }

  try {
    let phoneString = input
    if (phoneString.length === 12 && phoneString.startsWith('+1')) {
      phoneString = phoneString.slice(-10)
    }

    phoneString = stripNonNumericCharacters(phoneString)

    if (phoneString.length > 10 || phoneString.length < 10) {
      throw new Error('Phone not correct number of US digits')
    }

    return '(' + phoneString.substring(0, 3) + ') ' + phoneString.substring(3, 6) + '-' + phoneString.substring(6, 10)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export function formatUsPhoneInTenDigits(input: string): string | undefined {
  try {
    let phoneString = input
    if (phoneString.length === 12 && phoneString.startsWith('+1')) {
      phoneString = phoneString.slice(-10)
    }

    phoneString = stripNonNumericCharacters(phoneString)

    if (phoneString.length > 10 || phoneString.length < 10) {
      throw new Error('Phone not correct number of US digits')
    }

    return phoneString
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export function stripNonNumericCharacters(input: string): string {
  return input.replace(/\D/g, '')
}

export function capitalizeFirst(input: string): string {
  if (input.length === 0) {
    return input
  }

  return input.charAt(0).toUpperCase() + input.slice(1)
}
