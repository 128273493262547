import { ModifierOptionForModifierFormModel } from 'views/modifiers/CreateModifier/modifierValidator'

import {
  ExternalDataSource,
  InventoryType,
  Maybe,
  ModifierOptionFragment,
  OriginType,
  ProductDetailsFragment,
  ProductTagType,
  TopModifierFragment
} from '../../../graphql/types.generated'
import { ModifierForProductFormModel, ProductFormModel } from '../CreateProduct/productValidator'

export function convertProductDetailsToFormModel(product: Maybe<ProductDetailsFragment>): ProductFormModel | undefined {
  if (!product) {
    return undefined
  }

  return {
    id: product.id,
    name: product.name,
    printableName: product.printableName,
    description: product.description || null,
    active: product.active,
    priceCents: product.priceCents,
    sizeInGrams: product.sizeInGrams || null,
    originType: product.originType as OriginType,
    catalogIds: product.catalogs?.map((c) => c.id) ?? [],
    categoryId: product.category.id,
    modifiers: product.allModifiers?.map((modifier) => ({
      id: modifier.id,
      name: modifier.name,
      printableName: modifier.printableName,
      active: modifier.active,
      description: modifier.description,
      priceCents: modifier.priceCents,
      childSelectionType: modifier.childSelectionType!,
      options:
        modifier.allChildren?.map((option) => ({
          id: option.id,
          name: option.name,
          printableName: option.printableName,
          active: option.active,
          priceCents: option.priceCents,
          selectedByDefault: option.selected!,
          selectedName: option.selectedName,
          selected: (modifier.defaultSelectionIds ?? []).indexOf(option.id) >= 0,
          currentQuantity: option.inventory?.currentQuantity === undefined ? null : option.inventory?.currentQuantity,
          currentlyAvailable:
            option.inventory?.currentlyAvailable === false ? false : option.inventory?.currentlyAvailable ?? true
        })) ?? []
    })),
    coffeeTags: product.productTags?.filter((pt) => pt.tagType === ProductTagType.Coffee).map((pt) => pt.value) || [],
    teaTags: product.productTags?.filter((pt) => pt.tagType === ProductTagType.Tea).map((pt) => pt.value) || [],
    beanOriginTags:
      product.productTags?.filter((pt) => pt.tagType === ProductTagType.BeanOrigin).map((pt) => pt.value) || [],
    flavorNoteTags:
      product.productTags?.filter((pt) => pt.tagType === ProductTagType.FlavorNotes).map((pt) => pt.value) || [],
    productImages:
      [...(product.images ?? [])]
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((pi) => {
          return { imageUri: pi.imageUri, libraryImageId: pi.libraryImage?.id }
        }) || [],
    roastType: product.roastType ?? null,
    roaster: product.roaster || null,
    currentQuantity: product.inventory?.currentQuantity === undefined ? null : product.inventory?.currentQuantity,
    currentlyAvailable:
      product.inventory?.currentlyAvailable === false ? false : product.inventory?.currentlyAvailable ?? true,
    inventoryType: product.inventory?.inventoryType || InventoryType.AlwaysAvailable,
    restockDays: product.inventory?.restockDays?.map((d) => d.dayOfWeek) || [],
    restockQuantity: product.inventory?.restockQuantity || null,
    taxRate: product.taxRate || null,
    pickUpPriceUsCents: product.pickUpPriceUsCents || null,
    suspendedUntil: product.suspendedUntil ? new Date(product.suspendedUntil).toString() : null,
    availabilityWindows: product.availabilityWindows?.map((aw) => {
      return {
        weekDays: [aw.dayOfWeek],
        timeIntervals: [
          {
            start: aw.start,
            end: aw.end
          }
        ]
      }
    }),
    sourceSystem: product.source
  }
}

export function getProductSource(source: Maybe<ExternalDataSource>): string | null {
  switch (source) {
    case ExternalDataSource.Square: {
      return 'Square'
    }
    case ExternalDataSource.Chowly: {
      return 'Chowly'
    }
    case ExternalDataSource.Fresh: {
      return 'Fresh'
    }
    default: {
      return null
    }
  }
}

export function convertTopModiferToFormModel(modifier: TopModifierFragment): ModifierForProductFormModel {
  return {
    id: modifier.id,
    name: modifier.name,
    printableName: modifier.printableName,
    priceCents: modifier.priceCents,
    active: true,
    childSelectionType: modifier.childSelectionType!,
    description: modifier.description,
    options: modifier.allChildren?.map((mo: ModifierOptionFragment) => {
      return {
        id: mo.id,
        name: mo.name,
        printableName: mo.printableName,
        priceCents: mo.priceCents,
        active: true,
        selectedByDefault: false,
        selectedName: mo.selectedName || undefined,
        currentQuantity: mo.inventory?.currentQuantity === undefined ? null : mo.inventory?.currentQuantity,
        currentlyAvailable:
          mo.inventory?.currentlyAvailable === false ? false : mo.inventory?.currentlyAvailable ?? true
      }
    })
  }
}

export function convertModiferOptionToFormModel(modifier: ModifierOptionFragment): ModifierOptionForModifierFormModel {
  return {
    id: modifier.id,
    name: modifier.name,
    selectedName: modifier.selectedName || '',
    printableName: modifier.printableName,
    priceCents: modifier.priceCents,
    active: true,
    selectedByDefault: modifier.selected || false,
    currentQuantity: modifier.inventory?.currentQuantity === undefined ? null : modifier.inventory?.currentQuantity,
    currentlyAvailable:
      modifier.inventory?.currentlyAvailable === false ? false : modifier.inventory?.currentlyAvailable ?? true
  }
}
