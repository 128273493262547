import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { BackdropLoading } from 'components/layout/BackdropLoading'
import { client, setTokenRetriever } from 'graphql/client'
import { PropsWithChildren, useEffect, useState } from 'react'
import { setHttpClientTokenRetriever } from 'util/httpClient'

export function GraphQLProvider(props: PropsWithChildren<{}>) {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      setTokenRetriever(getAccessTokenSilently)
      setHttpClientTokenRetriever(getAccessTokenSilently)
    } else {
      setTokenRetriever(async () => null)
      setHttpClientTokenRetriever(async () => null)
    }
    setInitialized(true)
  }, [getAccessTokenSilently, isAuthenticated])

  if (!initialized || isLoading) {
    return <BackdropLoading />
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}
