import { useAuth0 } from '@auth0/auth0-react'
import { BackdropLoading } from 'components/layout/BackdropLoading'
import { useUserRolesQuery } from 'graphql/types.generated'
import { createContext, PropsWithChildren, useContext } from 'react'

interface PermissionContextModel {
  authorities: string[]
}

const PermissionContext = createContext<PermissionContextModel>({ authorities: [] })

export function usePermissions() {
  const { authorities } = useContext(PermissionContext)

  return {
    hasAnyRole(...roleMatchers: RoleMatcher[]) {
      const roleList = roleMatchers.concat(roles.admin) // include global admin by default
      return authorities.some((authority) => roleList.some((roleMatcher) => roleMatcher(authority)))
    }
  }
}

export function PermissionProvider(props: PropsWithChildren<{}>) {
  const { isLoading } = useAuth0()
  const { data, loading } = useUserRolesQuery({
    fetchPolicy: 'cache-first'
  })

  if (loading || isLoading) {
    return <BackdropLoading message='Loading permissions' />
  }

  return (
    <PermissionContext.Provider value={{ authorities: data?.me?.roles || [] }}>
      {props.children}
    </PermissionContext.Provider>
  )
}

export const roles = {
  admin: (role: string) => role === 'ADMIN',
  anyOrganizationMember: (role: string) => role.startsWith(`organization:`),
  anyOrganizationAdmin: (role: string) => role.startsWith(`organization:ADMIN:`),
  anyMerchantAdmin: (role: string) => role.startsWith(`merchant:ADMIN:`),
  organizationAdmin: (organizationId: ID) => (role: string) => role === `organization:ADMIN:${organizationId}`,
  organizationMember: (organizationId: ID) => (role: string) =>
    role.startsWith('organization:') && role.endsWith(`:${organizationId}`),
  merchantAdmin: (merchantId: ID) => (role: string) => role === `merchant:ADMIN:${merchantId}`,
  treasuryContact: (organizationId: ID) => (role: string) => role === `organization:TREASURY_CONTACT:${organizationId}`
}

export type RoleMatcher = (role: string) => boolean
