import { Button } from '@bloom-coffee/steamed-milk'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'

import TagDropdown from './TagDropdown'

interface TagSelectorProps {
  tagOptions: string[]
  initialTags: string[]
  onTagsChanged: (value: string[]) => void
  label: string
  disabled?: boolean
}

export default function TagSelector(props: TagSelectorProps) {
  const { tagOptions, onTagsChanged, label, initialTags, disabled } = props
  const [tags, setTags] = React.useState<string[]>(initialTags)

  function addTag(tagValue: string) {
    let updatedTagValues = tags.concat() || []
    updatedTagValues.push(tagValue)

    setTags(updatedTagValues)
    onTagsChanged(updatedTagValues)
  }

  function removeTag(tagValue: string) {
    let updatedTagValues = tags.concat() || []

    const tagIndex = updatedTagValues.indexOf(tagValue)
    if (tagIndex >= 0) {
      updatedTagValues.splice(tagIndex, 1)
    }

    setTags(updatedTagValues)
    onTagsChanged(updatedTagValues)
  }

  return (
    <div style={tagContainer}>
      <TagDropdown tagOptions={tagOptions} onTagChange={(value) => addTag(value)} label={label} />
      <div style={tagList}>
        {!!tags.length &&
          tags.map((tagValue) => (
            <Button
              disabled={disabled}
              key={tagValue}
              endIcon={<DeleteIcon style={{ height: 18 }} />}
              theme='action'
              onClick={() => removeTag(tagValue)}
              label={tagValue}
              style={tagButtonStyle}
            />
          ))}
      </div>
    </div>
  )
}

const tagButtonStyle: React.CSSProperties = {
  margin: 5,
  backgroundColor: '#c7f8ff'
}

const tagList: React.CSSProperties = {
  display: 'Flex',
  flexDirection: 'row',
  flexFlow: 'wrap',
  maxWidth: 400
}

const tagContainer: React.CSSProperties = {
  display: 'Flex',
  flexDirection: 'row'
}
