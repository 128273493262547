import { MessageType, PushAppAction } from 'graphql/types.generated'
import * as yup from 'yup'

export const marketPushSchema = yup.object({
  title: yup.string().label('Title').required(),
  body: yup.string().label('Body').required(),
  analyticsTag: yup.string().label('Analytics Tag').required(),
  marketId: yup.string().label('Market').required(),
  messageType: yup.mixed<MessageType>().oneOf(Object.values(MessageType), 'Required').label('Message Type').required(),
  appAction: yup.mixed<PushAppAction>().label('App Action').optional()
})

export type MarketPushFormModel = yup.InferType<typeof marketPushSchema>
