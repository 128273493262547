import { DataGrid, GridValueFormatterParams } from '@mui/x-data-grid'
import { readableDateTimeFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'

import { OrderSummary } from './transactionTransforms'

interface OrderSummaryDataGridProps {
  orderSummary: OrderSummary
  loading: boolean
}

export function OrderSummaryDataGrid(props: OrderSummaryDataGridProps) {
  const { orderSummary, loading } = props

  return (
    <DataGrid
      loading={loading}
      autoHeight
      hideFooterPagination
      hideFooter
      hideFooterRowCount
      disableSelectionOnClick
      rows={(!loading && [orderSummary]) || []}
      columns={[
        {
          field: 'totalOrders',
          headerName: 'Orders',
          sortable: false,
          width: 100
        },
        {
          field: 'startDate',
          headerName: 'Start Date',
          sortable: false,
          width: 180,
          hide: !orderSummary.startDate,
          valueFormatter: (params: GridValueFormatterParams) => readableDateTimeFromInstant(params.value as number)
        },
        {
          field: 'endDate',
          headerName: 'End Date',
          sortable: false,
          width: 180,
          hide: !orderSummary.endDate,
          valueFormatter: (params: GridValueFormatterParams) => readableDateTimeFromInstant(params.value as number)
        },
        {
          field: 'totalRdyTokens',
          headerName: 'RDY Tokens',
          sortable: false,
          width: 150,
          description: 'This is the number of RDY tokens used for these orders'
        },
        {
          field: 'totalOrganizationTokens',
          headerName: 'Cafe Tokens',
          sortable: false,
          width: 150,
          description: 'This is the number of Cafe tokens used for these orders'
        },
        {
          field: 'totalTokenValue',
          headerName: 'Token Value',
          sortable: false,
          width: 130,
          description: 'This is the value of tokens used for these orders',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalTokenTipValue',
          headerName: 'Token Tips',
          sortable: false,
          width: 130,
          description: 'This is the tip amount added for these orders',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalTokenFeeValue',
          headerName: 'Token Fees',
          sortable: false,
          width: 130,
          description: 'This is fee aplied to the sum of the Token Value and Token Tip',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalSubTotal',
          headerName: 'Sub Totals',
          sortable: false,
          width: 120,
          description: 'This is the sum of all items and modifiers for these orders',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalTaxes',
          headerName: 'Taxes',
          sortable: false,
          width: 100,
          description: 'These taxes are paid by the customer, then collected and remitted by RDY',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalTips',
          headerName: 'Tips',
          sortable: false,
          width: 80,
          description: 'Tip totals for these orders',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalCustomerFees',
          headerName: 'Customer Fees',
          sortable: false,
          width: 150,
          description: 'This is the sum of all the customer fees for these orders',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalMerchantFees',
          headerName: 'Merchant Fees',
          sortable: false,
          width: 150,
          description: 'This is the sum of all the merchant fees for these orders',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalMerchantDiscountFeeUsCents',
          headerName: 'RDY Discount Fee',
          description: 'This is the merchant fee associated with the RDY discounts',
          sortable: false,
          width: 140,
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalRdyPromotionDiscountValue',
          headerName: 'RDY Discounts',
          description: 'This is the discounted value that RDY has applied to the order',
          sortable: false,
          width: 140,
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalNetMerchantPayout',
          headerName: 'Order Payout',
          sortable: false,
          width: 140,
          description: 'This is the sum of the net payouts for these orders',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        },
        {
          field: 'totalReferralPayout',
          headerName: 'Total Referral Payout',
          sortable: false,
          width: 200,
          description: 'This is the sum of the net payouts for the barista referrals',
          valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
        }
      ]}
    />
  )
}
