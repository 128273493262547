/* eslint-disable no-console */
import { config } from './config'

type LogType = 'ERROR' | 'INFO' | 'WARN' | 'DEBUG'

export interface LogMessage {
  message: string
  time: string
  type?: LogType
}

const logLevelToIndex: Record<string, number> = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4
}
const appLogLevelIndex = logLevelToIndex[config.logLevel] || 0

export const logger = {
  wrapContext(context: string, message: string): string {
    return `[${context}] ${message}`
  },
  async execute(context: string, message: string, type: LogType) {
    const msg = this.wrapContext(context, message)

    switch (type) {
      case 'DEBUG':
        console.log(msg)
        break
      case 'INFO':
        console.info(msg)
        break
      case 'ERROR':
        console.error(msg)
        break
      case 'WARN':
        console.warn(msg)
        break
      default:
        console.log(msg)
    }
  },
  async debug(context: string, message: string, shouldStore: boolean = false) {
    if (appLogLevelIndex >= 4) {
      await this.execute(context, message, 'DEBUG')
    }
  },
  async info(context: string, message: string, shouldStore: boolean = false) {
    if (appLogLevelIndex >= 3) {
      await this.execute(context, message, 'INFO')
    }
  },
  async warn(context: string, message: string, shouldStore: boolean = false) {
    if (appLogLevelIndex >= 2) {
      await this.execute(context, message, 'WARN')
    }
  },
  async error(context: string, message: string, shouldStore: boolean = false) {
    if (appLogLevelIndex >= 1) {
      await this.execute(context, message, 'ERROR')
    }
  }
}
