import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import React from 'react'

import { OnboardingSteps } from './OnboardingSteps'

export function OnboardingRoot() {
  return (
    <MenuedContentContainer menuButtonDetails={[]}>
      <OnboardingSteps />
    </MenuedContentContainer>
  )
}
