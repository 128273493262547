import { List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { PromotionTopInfoFragment } from 'graphql/types.generated'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 700,
    backgroundColor: theme.palette.background.paper
  }
}))

interface PromotionsListProps {
  promotions: PromotionTopInfoFragment[]
}

export const PromotionsList = (props: PromotionsListProps) => {
  const { promotions } = props

  const classes = useStyles()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  return (
    <List className={classes.root}>
      {promotions.map((p: PromotionTopInfoFragment, index: number) => {
        function handleView() {
          navigate(`${pathname}/${p.id}`)
        }

        return (
          <ListItem key={p.id} role={undefined} dense button onClick={handleView}>
            <ListItemText id={`${index}-co`} primary={`${new Date(p.createdOn).toDateString()}`} />
            <ListItemText id={`${index}-cfn`} primary={`${p.customerFacingName}`} />
            <ListItemText id={`${index}-m`} primary={`${p.merchants?.map((m) => m.name).join(', ') || 'Global'}`} />
            <ListItemSecondaryAction>
              <ArrowForwardIos style={{ height: 14 }} />
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </List>
  )
}
