import { Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import {
  InventoryType,
  OriginType,
  ProductImageInput,
  ProductListDocument,
  ProductTagInput,
  ProductTagType,
  RoastType,
  TopModifierInput,
  useCreateProductMutation,
  useMerchantCatalogQuery
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'
import { generateFullTagsFromStrings } from 'util/productTags'

import { ProductForm } from '../components/ProductForm/ProductForm'
import { ProductFormModel, productSchema } from './productValidator'

export function CreateProduct() {
  const navigate = useNavigate()
  const toast = useToast()
  const { merchantId, organizationId } = useParams()
  const [execute] = useCreateProductMutation()

  const { data, loading } = useMerchantCatalogQuery({
    variables: {
      merchantId: merchantId!
    }
  })

  const defaultCatalogIds = data?.merchant?.activeCatalog ? [data?.merchant?.activeCatalog?.id] : []

  async function handleSubmit(values: ProductFormModel) {
    productSchema.validate(values)
    const {
      catalogIds,
      modifiers,
      coffeeTags,
      flavorNoteTags,
      beanOriginTags,
      teaTags,
      originType: originTypeString,
      roastType: roastTypeString,
      productImages,
      description,
      roaster,
      currentQuantity,
      currentlyAvailable,
      inventoryType: inventoryTypeString,
      restockDays,
      restockQuantity,
      availabilityWindows,
      taxRate,
      suspendedUntil,
      pickUpPriceUsCents,
      ...rest
    } = values

    const topModifiers = modifiers?.map((m, index) => {
      return { modifierId: m.id, sortOrder: index } as TopModifierInput
    })

    const tags = generateFullTagsFromStrings(coffeeTags || [], ProductTagType.Coffee)
      .concat(generateFullTagsFromStrings(flavorNoteTags || [], ProductTagType.FlavorNotes))
      .concat(generateFullTagsFromStrings(beanOriginTags || [], ProductTagType.BeanOrigin))
      .concat(generateFullTagsFromStrings(teaTags || [], ProductTagType.Tea))

    const originType = !!originTypeString ? (originTypeString as OriginType) : null
    const roastType = !!roastTypeString ? (roastTypeString as RoastType) : null
    const inventoryType = inventoryTypeString as InventoryType

    const productImageInputs = productImages?.map((productImage, index) => {
      return {
        imageUri: productImage.imageUri,
        libraryImageId: productImage.libraryImageId,
        sortOrder: index
      } as ProductImageInput
    })

    try {
      const res = await execute({
        variables: {
          input: {
            ...rest,
            merchantId: merchantId!!,
            catalogIds: (catalogIds.filter((c) => !!c) as string[]) ?? [],
            description: description === '' ? null : description,
            roaster: roaster === '' ? null : roaster,
            originType,
            roastType,
            topModifiers,
            productTags: (tags || []) as ProductTagInput[],
            images: productImageInputs || [],
            inventory: {
              currentQuantity: currentQuantity ?? 0,
              currentlyAvailable,
              inventoryType,
              restockDays: restockDays as number[],
              restockQuantity
            },
            availabilityWindows: availabilityWindows?.flatMap((aw) =>
              aw.timeIntervals.flatMap((ti) => {
                return {
                  dayOfWeek: aw.weekDays[0],
                  start: ti.start,
                  end: ti.end
                }
              })
            )
          }
        },
        refetchQueries: [
          {
            query: ProductListDocument,
            variables: { merchantId: merchantId! }
          }
        ]
      })
      if (res.data?.createProduct) {
        toast.success('Product created')
        navigate(-1)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  function handleCancel() {
    navigate(-1)
  }

  return (
    <Section title={'New Product'}>
      {loading && <CircularProgress />}
      {!loading && !!defaultCatalogIds && (
        <ProductForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          initialValues={{ catalogIds: defaultCatalogIds }}
          merchantId={merchantId!}
          organizationId={organizationId!}
        />
      )}
    </Section>
  )
}
