import { ApolloClient, ApolloLink, split } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client'
import { config } from 'config'
// @ts-ignore
import { extractFiles } from 'extract-files'

import { graphQLCache } from './cache'

const authLink = setContext(async (_, { headers }) => {
  if (!getToken) {
    return headers
  }

  const token = await getToken()
  if (!token) {
    return headers
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const terminatingLinkOptions = {
  uri: config.graphqlApiUrl
}

const wsLink = new WebSocketLink({
  uri: config.graphqlWsUrl,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = await getToken()
      return {
        token
      }
    }
  }
})
const terminationSplit = split(
  (operation) => extractFiles(operation).files.size > 0,
  createUploadLink(terminatingLinkOptions),
  new BatchHttpLink(terminatingLinkOptions)
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  authLink.concat(terminationSplit)
)

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value)
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }

  return forward(operation)
})

export const client = new ApolloClient({
  link: cleanTypenameLink.concat(splitLink),
  cache: graphQLCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    },
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
})

let getToken: () => Promise<string | undefined | null>

export function setTokenRetriever(tokenRetriever: () => Promise<string | undefined | null>) {
  getToken = tokenRetriever
}
