import { Maybe } from 'graphql/types.generated'
import React, { useState } from 'react'
import { ColorHex } from 'theme/ThemeConstants'

interface ImageOptionProps {
  imageUri: string
  libraryImageId?: Maybe<string>
  selectImage: (filename: string, libraryImageId: Maybe<string>) => void
}

export const ImageOption = (props: ImageOptionProps) => {
  const { imageUri, libraryImageId, selectImage } = props

  const [contentHovered, setContentHovered] = useState(false)

  return (
    <div
      style={{
        cursor: 'pointer',
        position: 'relative'
      }}
      onMouseEnter={(e) => {
        setContentHovered(true)
      }}
      onMouseLeave={(e) => {
        setContentHovered(false)
      }}
      onClick={() => selectImage(imageUri, libraryImageId)}
    >
      <img
        style={{
          ...imageStyle,
          ...(contentHovered
            ? {
                opacity: 0.3
              }
            : {})
        }}
        src={imageUri}
        alt=''
      ></img>
      <span
        style={{
          ...selectStyle,
          ...(contentHovered
            ? {
                opacity: 1
              }
            : { display: 'none' })
        }}
      >
        SELECT
      </span>
    </div>
  )
}

const imageStyle: React.CSSProperties = {
  width: 100,
  height: 100,
  margin: 5,
  opacity: 1,
  display: 'block',
  transition: '.5s ease',
  backfaceVisibility: 'hidden'
}
const selectStyle: React.CSSProperties = {
  backgroundColor: ColorHex.RDY_PINK,
  color: ColorHex.RDY_BLACK,
  padding: 5,
  borderRadius: 4,
  fontFamily: 'Matter-Medium',
  border: '1px solid',
  borderColor: ColorHex.RDY_BLACK,
  transition: '.5s ease',
  opacity: 0,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center'
}
