import { Backdrop, CircularProgress, makeStyles, Typography } from '@material-ui/core'

interface BackdropLoadingProps {
  message?: string
  open?: boolean
}

export function BackdropLoading(props: BackdropLoadingProps) {
  const { message, open = true } = props
  const styles = useStyles()
  return (
    <Backdrop open={open}>
      <div className={styles.container}>
        <CircularProgress color='inherit' />
        {message && <Typography className={styles.message}>{message}</Typography>}
      </div>
    </Backdrop>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  message: {
    marginTop: theme.spacing(2)
  }
}))
