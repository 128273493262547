import { Grid } from '@material-ui/core'
import { TextField } from 'components/form/TextField'
import { useFormContext } from 'react-hook-form'

import { MerchantLocationFormModel } from './merchantValidator'

interface MerchantLocationFormSectionProps {
  name: string
  defaultValues?: Partial<MerchantLocationFormModel>
  style?: React.CSSProperties
}

export function MerchantLocationFormSection(props: MerchantLocationFormSectionProps) {
  const { name, defaultValues, style } = props
  const { register } = useFormContext()

  return (
    <Grid style={style} container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name={`${name}.street`}
          label='Street'
          inputRef={register()}
          defaultValue={defaultValues?.street}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth name={`${name}.street2`} label='Street line 2' inputRef={register()} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name={`${name}.city`}
          label='City'
          inputRef={register()}
          defaultValue={defaultValues?.city}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name={`${name}.state`}
          label='State'
          inputRef={register()}
          defaultValue={defaultValues?.state}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name={`${name}.zipCode`}
          label='Zip Code'
          inputRef={register()}
          defaultValue={defaultValues?.zipCode}
        />
      </Grid>
      <Grid item>
        <TextField
          name={`${name}.countryCode`}
          label='Country Code'
          inputRef={register()}
          defaultValue={defaultValues?.countryCode}
        />
      </Grid>
    </Grid>
  )
}
