import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  Maybe,
  SubscriptionStatus,
  useStripeSubscriptionQuery,
  useUpdateSubscriptionPaymentMethodMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { SubscriptionPaymentIntentInfo } from 'models/Subscriptions'
import React, { useEffect, useState } from 'react'
import { theme } from 'theme/theme'
import { formatUsCentsAsUsDollar } from 'util/formatting'

interface StripeSubscriptionCheckoutFormProps {
  subscriptionPaymentIntent: Maybe<SubscriptionPaymentIntentInfo>
  onComplete: () => void
  onCancel: () => void
  stripeSubscriptionId: string
  settingDefaultPaymentMethod: boolean
}

export function StripeSubscriptionCheckoutForm(props: StripeSubscriptionCheckoutFormProps) {
  const { onComplete, stripeSubscriptionId, settingDefaultPaymentMethod, onCancel, subscriptionPaymentIntent } = props
  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [pollingUntil, setPollingUntil] = useState<number | null>()

  const { refetch } = useStripeSubscriptionQuery({
    variables: {
      id: stripeSubscriptionId
    }
  })

  const [execute] = useUpdateSubscriptionPaymentMethodMutation()

  async function handleSubmit(event: { preventDefault: () => void }) {
    event.preventDefault()
    setLoading(true)

    if (!stripe || !elements) {
      return
    }

    if (settingDefaultPaymentMethod || !subscriptionPaymentIntent?.clientSecret) {
      const updateResult = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!
      })

      if (updateResult.error) {
        toast.error(updateResult.error.message || 'Unable to process payment method')
        setLoading(false)
      } else {
        var paymentMethodId = updateResult.paymentMethod.id
        try {
          await execute({
            variables: {
              paymentMethodId: paymentMethodId,
              stripeSubscriptionId: stripeSubscriptionId
            }
          })
          setPollingUntil(Date.now() + 30000)
        } catch (e: any) {
          toast.error(e.message || 'Unable to update payment method')
          setLoading(false)
        }
      }
    } else {
      const result = await stripe.confirmCardPayment(subscriptionPaymentIntent?.clientSecret!, {
        payment_method: {
          card: elements.getElement(CardElement)!
        }
      })

      if (result.error) {
        toast.error(result.error.message || 'Unable to process payment')
        setLoading(false)
      } else {
        setPollingUntil(Date.now() + 30000)
      }
    }
  }

  useEffect(() => {
    async function delay(time: number) {
      return new Promise((resolve) => setTimeout(resolve, time))
    }
    async function isPaymentProcessed() {
      try {
        var res = await refetch()
        return (
          !!res?.data?.stripeSubscription?.subscriptionStatus &&
          res.data.stripeSubscription.subscriptionStatus !== SubscriptionStatus.PendingPayment
        )
      } catch (e: any) {
        toast.error(`Error fetching payment status: ${e}`)
        return false
      }
    }
    async function startPolling() {
      while (Date.now() < pollingUntil!) {
        var paymentIsProcessed = await isPaymentProcessed()
        if (paymentIsProcessed) {
          setLoading(false)
          onComplete()
          return
        }
        await delay(1000)
      }
      toast.error(
        'Timed out waiting for payment status. Please refresh this page to see the updated subscription status'
      )
      setPollingUntil(null)
    }
    if (!pollingUntil) {
      return
    }
    startPolling()
  }, [onComplete, pollingUntil, refetch, toast])

  return (
    <form onSubmit={handleSubmit}>
      <Box padding={1}>
        {(!!subscriptionPaymentIntent?.lineItems || !!subscriptionPaymentIntent?.costUsCents) && (
          <Box display='flex' flexDirection='column' paddingBottom={3}>
            {!!subscriptionPaymentIntent?.lineItems &&
              subscriptionPaymentIntent?.lineItems.map((li, index) => (
                <Box display='flex' flexDirection='row' justifyContent='space-between' key={`${li}.${index}`}>
                  <Typography variant='caption'>{li.name}</Typography>
                  <Typography variant='caption'>{formatUsCentsAsUsDollar(li.costUsCents)}</Typography>
                </Box>
              ))}
            {!!subscriptionPaymentIntent?.costUsCents && (
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Typography variant='subtitle2'>Total</Typography>
                <Typography variant='subtitle2'>
                  {formatUsCentsAsUsDollar(subscriptionPaymentIntent?.costUsCents)}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Section title='Enter your Credit Card Information:' variant='subsection'>
          <Box padding={1} border={`1px solid ${theme.palette.divider}`}>
            <CardElement />
          </Box>
          <Box padding={1}>
            <Typography variant='caption'>
              This will be set as the default payment method for future payments
            </Typography>
          </Box>
        </Section>
        <div style={actionsStyle}>
          <Button style={buttonStyle} onClick={() => onCancel()} disabled={!stripe} label='Cancel' theme='cancel' />
          <Button
            style={buttonStyle}
            type='submit'
            disabled={!stripe || loading}
            label=' Submit Payment'
            onClick={handleSubmit}
            endIcon={loading && <CircularProgress color='inherit' size={20} />}
          />
        </div>
      </Box>
    </form>
  )
}

const actionsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const buttonStyle: React.CSSProperties = {
  marginRight: 5
}
