import { Route, Routes } from 'react-router-dom'

import { Home } from './Home'
import { AcceptInvite } from './invite/AcceptInvite/AcceptInvite'

export function LoggedIn() {
  return (
    <>
      <Routes>
        <Route path='acceptInvite/:token' element={<AcceptInvite />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </>
  )
}
