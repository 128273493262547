import { Section } from '@bloom-coffee/steamed-milk'
import { useCreateMerchantFeeConfig } from 'hooks/useCreateMerchantFeeConfig/useCreateMerchantFeeConfig'
import { useNavigate } from 'react-router-dom'

import { MerchantFeeConfigForm } from '../components/MerchantFeeConfigForm'
import { MerchantFeeConfigFormModel } from '../components/merchantFeeConfigValidator'

export const CreateMerchantFeeConfig = () => {
  const navigate = useNavigate()

  const { loading, create } = useCreateMerchantFeeConfig()

  function handleSubmit(input: MerchantFeeConfigFormModel) {
    create(input, () => navigate(-1))
  }

  return (
    <Section title={`Create New Merchant Transaction Fee`}>
      <MerchantFeeConfigForm onCancel={() => navigate(-1)} onSubmit={handleSubmit} disableSubmit={loading} />
    </Section>
  )
}
