import { Grid } from '@material-ui/core'
import { DefaultFormFooter, DefaultFormFooterProps } from 'components/form/DefaultFormFooter'
import { TextField } from 'components/form/TextField'
import { FormProvider, useForm } from 'react-hook-form'

interface OrganizationFormProps {
  initialValues?: OrganizationFormModel | undefined
  onCancel?(): void
  onSubmit(values: OrganizationFormModel): void
  FooterComponent?(props: DefaultFormFooterProps): JSX.Element
}

export function OrganizationForm(props: OrganizationFormProps) {
  const { initialValues, onCancel, onSubmit, FooterComponent = DefaultFormFooter } = props
  const formProps = useForm<OrganizationFormModel>({
    defaultValues: initialValues
  })

  const { register, handleSubmit } = formProps

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formProps}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth name='name' label='Name' inputRef={register({ required: 'Name is required' })} />
          </Grid>
          <Grid item>
            <FooterComponent onCancel={onCancel} onSubmit={handleSubmit(onSubmit)} />
          </Grid>
        </Grid>
      </FormProvider>
    </form>
  )
}

export interface OrganizationFormModel {
  name: string
}
