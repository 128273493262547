import { Section } from '@bloom-coffee/steamed-milk'
import { useCreateMerchantContactMutation, ViewMerchantDocument } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'

import { MerchantContactForm } from './MerchantContactForm'
import { MerchantContactFormModel } from './merchantContactValidator'

export function CreateMerchantContact() {
  const navigate = useNavigate()
  const toast = useToast()
  const { merchantId, organizationId } = useParams()
  const [execute] = useCreateMerchantContactMutation()

  async function handleSubmit(values: MerchantContactFormModel) {
    try {
      const res = await execute({
        variables: {
          input: {
            merchantId: merchantId!,
            ...values
          }
        },
        refetchQueries: [{ query: ViewMerchantDocument, variables: { id: merchantId! } }]
      })
      if (res.data?.createMerchantContact) {
        toast.success('Contact created')
        navigate(`/organizations/${organizationId!}/merchants/${merchantId!}/home/contacts`)
      }
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  function handleCancel() {
    navigate(-1)
  }

  return (
    <Section title={'New Contact'}>
      <MerchantContactForm onCancel={handleCancel} onSubmit={handleSubmit} initialValues={{}} />
    </Section>
  )
}
