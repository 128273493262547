import { yupResolver } from '@hookform/resolvers/yup'
import { UserProfile } from 'graphql/types.generated'
import { FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../../components/form/DefaultFormFooter'
import { TextField } from '../../../../components/form/TextField'
import { UserProfileFormModel, userProfileSchema } from './UserProfileValidator'

interface UserProfileFormProps {
  onCancel: () => void
  onSubmit: (values: UserProfileFormModel) => void
  initialValues: UserProfile
  disableSubmit?: boolean
}

export function UserProfileForm(props: UserProfileFormProps) {
  const { onCancel, onSubmit, initialValues, disableSubmit } = props

  const formProps = useForm<UserProfileFormModel>({
    defaultValues: {
      contactEmail: initialValues.contactEmail || undefined,
      firstName: initialValues.firstName || undefined,
      lastName: initialValues.lastName || undefined,
      phoneNumber: initialValues.phoneNumber || undefined
    },
    resolver: yupResolver(userProfileSchema),
    shouldUnregister: false
  })

  const { register, handleSubmit, formState } = formProps

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={rowStyle}>
            <TextField
              fullWidth
              name='firstName'
              label='First Name'
              inputRef={register()}
              defaultValue={initialValues?.firstName}
              style={fieldStyle}
            />
            <TextField
              fullWidth
              name='lastName'
              label='Last Name'
              inputRef={register()}
              defaultValue={initialValues?.lastName}
              style={fieldStyle}
            />
          </div>
          <div style={rowStyle}>
            <TextField
              fullWidth
              name='contactEmail'
              label='Email'
              inputRef={register()}
              defaultValue={initialValues?.contactEmail}
              style={fieldStyle}
            />
            <TextField
              fullWidth
              name='phoneNumber'
              label='Phone Number'
              inputRef={register()}
              defaultValue={initialValues?.phoneNumber}
              style={fieldStyle}
            />
          </div>
          <DefaultFormFooter
            style={rowStyle}
            disabled={disableSubmit}
            onCancel={onCancel}
            onSubmit={handleSubmit(onSubmit)}
            submitting={formState.isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  )
}

const rowStyle: React.CSSProperties = { display: 'flex', flexDirection: 'row', paddingTop: 10 }

const fieldStyle: React.CSSProperties = { padding: 10 }
