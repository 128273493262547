import { Section } from '@bloom-coffee/steamed-milk'
import {
  FulfillmentLocationType,
  GetServingLocationsByMerchantIdDocument,
  useCreateFulfillmentLocationMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'

import { ServingLocationForm } from './ServingLocationForm'
import { ServingLocationFormModel } from './servingLocationValidator'

export function CreateServingLocation() {
  const navigate = useNavigate()
  const toast = useToast()
  const { merchantId, organizationId } = useParams()
  const [execute] = useCreateFulfillmentLocationMutation()

  async function handleSubmit(values: ServingLocationFormModel) {
    try {
      const res = await execute({
        variables: {
          input: {
            merchantId: merchantId!,
            fulfillmentLocationType: FulfillmentLocationType.TableService,
            ...values
          }
        },
        refetchQueries: [{ query: GetServingLocationsByMerchantIdDocument, variables: { merchantId: merchantId! } }]
      })
      if (res.data?.createFulfillmentLocation) {
        toast.success('Serving Location created')
        navigate(`/organizations/${organizationId!}/merchants/${merchantId!}/home/servingLocations`)
      }
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  function handleCancel() {
    navigate(-1)
  }

  return (
    <Section title={'New Serving Location'}>
      <ServingLocationForm onCancel={handleCancel} onSubmit={handleSubmit} initialValues={{}} />
    </Section>
  )
}
