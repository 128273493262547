import { Button } from '@bloom-coffee/steamed-milk'
import { DataGrid, GridSelectionModel } from '@mui/x-data-grid'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { roles } from 'hooks/usePermissions/usePermissions'
import { MerchantSubscriptionFeeInfo, MerchantSubscriptionInfo } from 'models/Subscriptions'
import React, { useEffect, useState } from 'react'
import { readableDateFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { formatSubscriptionPlanCost } from 'util/subscriptions'

interface MerchantSubscriptionsGridProps {
  loading: boolean
  selectable: boolean
  merchantSubscriptions: MerchantSubscriptionInfo[]
  merchantSubscriptionFees: MerchantSubscriptionFeeInfo[]
  defaultSelectedMerchantSubscriptionId?: string[]
  setSelectedMerchantSubscriptionIds?: (merchantSubscriptionIds: string[]) => void
  isRowSelectable?: (merchantSubscription: MerchantSubscriptionInfo) => boolean
  onRemove?: (merchantSubscriptionID: string) => void
  onRowClick?: (mechantId: string) => void
}

export function MerchantSubscriptionsGrid(props: MerchantSubscriptionsGridProps) {
  const {
    loading,
    merchantSubscriptions,
    selectable,
    setSelectedMerchantSubscriptionIds,
    isRowSelectable,
    merchantSubscriptionFees,
    defaultSelectedMerchantSubscriptionId,
    onRemove,
    onRowClick
  } = props

  const [selectionModel, setSelectionModel] = useState(defaultSelectedMerchantSubscriptionId ?? [])

  function merchantFeesByMerchantId(merchantId: string) {
    return merchantSubscriptionFees.filter((msf) => msf.merchant?.id === merchantId)
  }

  useEffect(() => {
    if (!!setSelectedMerchantSubscriptionIds) {
      setSelectedMerchantSubscriptionIds(selectionModel ?? [])
    }
  }, [selectionModel, setSelectedMerchantSubscriptionIds])

  return (
    <DataGrid
      loading={loading}
      autoHeight
      disableSelectionOnClick
      hideFooterPagination={true}
      hideFooter={true}
      checkboxSelection={selectable}
      selectionModel={selectionModel}
      onRowClick={(params) => onRowClick?.(params.row.merchant.id)}
      style={{ marginBottom: 10 }}
      isRowSelectable={(params) =>
        (!!isRowSelectable && isRowSelectable(params.row as MerchantSubscriptionInfo)) || false
      }
      onSelectionModelChange={(params: GridSelectionModel) => {
        const values = params.map((value) => value.toString())
        setSelectionModel(values)
      }}
      rows={merchantSubscriptions}
      columns={[
        {
          field: 'merchantName',
          headerName: 'Merchant Name',
          sortable: true,
          width: 300,
          valueGetter(params) {
            return params.row.merchant.name
          }
        },
        {
          field: 'subscriptionName',
          headerName: 'Subscription Name',
          sortable: true,
          width: 200,
          valueGetter(params) {
            return params.row.subscriptionPlan?.name
          }
        },
        {
          field: 'subscriptionCost',
          headerName: 'Cost',
          sortable: true,
          width: 175,
          valueGetter(params) {
            return !!params.row.subscriptionPlan ? formatSubscriptionPlanCost(params.row.subscriptionPlan) : ''
          }
        },
        {
          field: 'trialEndDate',
          headerName: 'Billing Start Date',
          sortable: true,
          width: 200,
          hide: !merchantSubscriptions.find((ms) => !!ms.trialEndDate),
          valueGetter(params) {
            return readableDateFromInstant(params.row.trialEndDate)
          }
        },
        {
          field: 'merchantFees',
          headerName: 'Outstanding Fees',
          sortable: true,
          width: 300,
          hide: merchantSubscriptionFees.length === 0,
          renderCell(params) {
            return (
              <>
                {merchantFeesByMerchantId(params.row.merchant.id).map((fee) => (
                  <div>{`${fee.subscriptionFee!!.name} - ${formatUsCentsAsUsDollar(
                    fee.subscriptionFee!!.costUsCents!!
                  )}`}</div>
                ))}
              </>
            )
          }
        },
        {
          field: '',
          sortable: true,
          width: 300,
          hide: !onRemove,
          renderCell(params) {
            return (
              <HasAnyRole roleMatchers={[roles.admin]}>
                <Button
                  onClick={() => !!onRemove && onRemove(params.row.id)}
                  label='Cancel Cafe (admin only)'
                  theme='warning'
                />
              </HasAnyRole>
            )
          }
        }
      ]}
    />
  )
}
