import { Text } from '@bloom-coffee/steamed-milk'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import { ColorHex } from 'theme/ThemeConstants'

import { SquareLocationSelectionConfig, SquareSyncVariant } from './SquareUtils'

interface SquareLocationContainerProps {
  variant: SquareSyncVariant
  config: SquareLocationSelectionConfig
  clickDisabled: boolean
  onCreateNewMerchantClick: (config: SquareLocationSelectionConfig, checked: boolean) => void
  onCreateKdsIntegrationClick: (config: SquareLocationSelectionConfig, checked: boolean) => void
  onSyncProductsClick: (config: SquareLocationSelectionConfig, checked: boolean) => void
  onOnlyWebProductsClick: (config: SquareLocationSelectionConfig, checked: boolean) => void
  onKeepMenuSyncedClick: (config: SquareLocationSelectionConfig, checked: boolean) => void
}

export const SquareLocationContainer = (props: SquareLocationContainerProps) => {
  const {
    variant,
    config,
    clickDisabled,
    onCreateKdsIntegrationClick,
    onCreateNewMerchantClick,
    onSyncProductsClick,
    onOnlyWebProductsClick,
    onKeepMenuSyncedClick
  } = props

  return (
    <div>
      <div>
        <Text variant='subheader1'>{config.info.name}</Text>
      </div>

      <div>
        <Text variant='body1'>{config.info.address?.line1}</Text>
      </div>

      <div>
        {variant === 'CreateMerchant' && (
          <div>
            <div>
              <FormControlLabel
                label='Create New Merchant'
                control={
                  <Checkbox
                    disabled={clickDisabled}
                    onChange={(e) => onCreateNewMerchantClick(config, !!e.target.checked)}
                    checked={config.createMerchant}
                  />
                }
              />
            </div>
            <div>
              <Text variant='body3' color={ColorHex.BLUE_GREY_500}>
                RDY will attempt to sync your Square location's address, catalog, hours, and staff.
              </Text>
            </div>
          </div>
        )}

        {(variant === 'KdsIntegration' || config.createMerchant) && (
          <div>
            <div>
              <FormControlLabel
                label='Send RDY Orders to this Location'
                control={
                  <Checkbox
                    disabled={clickDisabled}
                    onChange={(e) => onCreateKdsIntegrationClick(config, !!e.target.checked)}
                    checked={config.createKdsIntegration}
                  />
                }
              />
            </div>
            <div>
              <Text variant='body3' color={ColorHex.BLUE_GREY_500}>
                RDY orders will appear on your Square POS as typical online orders.
              </Text>
            </div>
          </div>
        )}

        {variant === 'SyncProducts' && (
          <div>
            <div>
              <FormControlLabel
                label='Sync Square Products'
                control={
                  <Checkbox
                    disabled={clickDisabled}
                    onChange={(e) => onSyncProductsClick(config, !!e.target.checked)}
                    checked={config.syncProducts}
                  />
                }
              />
            </div>
            <div>
              <Text variant='subheader2' color={ColorHex.RED}>
                Your existing menu will be replaced with your Square catalog.
              </Text>
            </div>
          </div>
        )}
        {(variant === 'SyncProducts' || variant === 'CreateMerchant') && (
          <div>
            <div>
              <FormControlLabel
                label='Keep Menu Synced'
                control={
                  <Checkbox
                    disabled={clickDisabled}
                    onChange={(e) => onKeepMenuSyncedClick(config, !!e.target.checked)}
                    checked={config.keepMenuSynced}
                  />
                }
              />
            </div>
            <div>
              <Text variant='body3' color={ColorHex.BLUE_GREY_500}>
                Changes to products will be synced automatically
              </Text>
            </div>
          </div>
        )}

        {!!config.info.merchantHasSquareSite && (config.syncProducts || config.createMerchant) && (
          <div>
            <div>
              <FormControlLabel
                label='Only Import Products from Square Site'
                control={
                  <Checkbox
                    disabled={clickDisabled}
                    onChange={(e) => onOnlyWebProductsClick(config, !!e.target.checked)}
                    checked={config.onlyWebCatalogItems}
                  />
                }
              />
            </div>
            <div>
              <Text variant='body3' color={ColorHex.BLUE_GREY_500}>
                RDY will attempt to import only the products in your catalog that are available on your Square site, if
                a Square site exists.
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
