import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
export interface DefaultFormFooterProps {
  onCancel?(): void
  onSubmit(): void
  saveLabel?: string
  submitting?: boolean
  disabled?: boolean
  style?: React.CSSProperties
}

export function DefaultFormFooter(props: DefaultFormFooterProps) {
  const { saveLabel = 'Save', submitting, onCancel, onSubmit, disabled, style } = props
  return (
    <div style={{ ...containerStyle, ...style }}>
      {onCancel && (
        <Button
          onClick={onCancel}
          disabled={submitting}
          label='Cancel'
          theme='cancel'
          size='medium'
          style={buttonStyle}
        />
      )}
      <Button
        theme='action'
        type='submit'
        size='medium'
        onClick={onSubmit}
        disabled={submitting || disabled}
        endIcon={submitting && <CircularProgress color='inherit' size={20} />}
        label={saveLabel}
        style={buttonStyle}
      />
    </div>
  )
}

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}
const buttonStyle: React.CSSProperties = {
  margin: 5,
  display: 'flex',
  flexDirection: 'row'
}
