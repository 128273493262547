import { Button, Section } from '@bloom-coffee/steamed-milk'
import DeleteIcon from '@material-ui/icons/Delete'
import { DataGrid } from '@mui/x-data-grid'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { QRCodeLink } from 'components/widgets/QRCodeLink'
import {
  GetServingLocationsByMerchantIdDocument,
  useArchiveFulfillmentLocationMutation,
  useGetServingLocationsByMerchantIdQuery
} from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import { FulfillmentLocationInfo } from 'models/FulfillmentLocation'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getFilename } from 'util/stringUtils'

import { QRCodeSignLink } from './QRCodeSignLink'
export const ServingLocationsContainer = () => {
  const { merchantId, organizationId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()

  const { data, loading } = useGetServingLocationsByMerchantIdQuery({
    variables: {
      merchantId: merchantId!
    },
    fetchPolicy: 'cache-first'
  })

  const [execute] = useArchiveFulfillmentLocationMutation()

  async function handleArchive(fulfillmentLocationId: string) {
    try {
      await execute({
        variables: {
          fulfillmentLocationId: fulfillmentLocationId!
        },
        refetchQueries: [{ query: GetServingLocationsByMerchantIdDocument, variables: { merchantId: merchantId! } }]
      })
      toast.success('Serving Location deleted')
    } catch (e: any) {
      toast.error(e.toString())
    }
  }
  function createServingLocation() {
    navigate(`${pathname}/new`)
  }

  return (
    <Section
      title='Serving Locations'
      addon={<Button label='Create Serving Location' onClick={createServingLocation} />}
    >
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rows={data?.getServingLocationsByMerchantId || []}
        loading={loading}
        columns={[
          { field: 'displayName', headerName: 'Display Name', sortable: true, width: 200 },
          { field: 'webUrl', headerName: 'Web Ordering Url', sortable: true, width: 400 },
          { field: 'appUrl', headerName: 'App Url', sortable: true, width: 400 },
          {
            field: 'qrCode',
            headerName: 'QR Code',
            sortable: true,
            width: 300,
            renderCell(params) {
              return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <QRCodeLink link={params.row.appUrl} downloadFilename={getFilename(params.row.displayName, '.png')} />
                  <QRCodeSignLink servingLocation={params.row as FulfillmentLocationInfo} merchantId={merchantId!!} />
                </div>
              )
            }
          },
          {
            field: '',
            headerName: '',
            sortable: true,
            width: 150,
            renderCell(params) {
              return (
                <HasAnyRole
                  roleMatchers={[roles.organizationAdmin(organizationId!!), roles.merchantAdmin(merchantId!!)]}
                >
                  <Button endIcon={<DeleteIcon />} theme='action' onClick={() => handleArchive(params.row.id)} />
                </HasAnyRole>
              )
            }
          }
        ]}
      />
    </Section>
  )
}
