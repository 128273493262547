import * as yup from 'yup'

import { transform } from '../../../../../validation/transform'

export const promoCodeSchema = yup.object({
  code: yup.string().label('Code').optional(),
  promotionId: yup.string().label('Promotion').required(),
  limitTotalUses: yup.number().transform(transform.nullable).label('Limit Total Uses').nullable().optional(),
  validStart: yup.string().label('Start Date*').required(),
  validEnd: yup.string().label('End Date*').nullable().optional()
})

export type PromoCodeFormModel = yup.InferType<typeof promoCodeSchema>
