import { ArrowForwardIos } from '@material-ui/icons'
import { Merchant } from 'graphql/types.generated'
import { useCafeValidationStatus } from 'hooks/useCafeValidationStatus/useCafeValidationStatus'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface MerchantCardProps {
  organizationId: ID
  merchant: Merchant
}

export function MerchantCard(props: MerchantCardProps) {
  const { merchant, organizationId } = props
  const navigate = useNavigate()
  const { isReadyToGoLive } = useCafeValidationStatus(merchant.id, organizationId)

  return (
    <div key={merchant.id} onClick={() => navigate(merchant.id)} style={merchantStyle}>
      <div style={merchantNameStyle}>{merchant.name}</div>
      <div style={merchantStatusContainerStyle}>
        <div style={merchantStatusStyle}>
          {merchant.active ? 'Live' : isReadyToGoLive() ? 'Offline' : 'Awaiting Setup'}
        </div>
        <ArrowForwardIos />
      </div>
    </div>
  )
}

const merchantNameStyle: React.CSSProperties = {
  fontFamily: 'Raw-Bold',
  fontSize: 20
}

const merchantStatusContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 'auto',
  marginBottom: 'auto'
}

const merchantStatusStyle: React.CSSProperties = {
  fontFamily: 'Matter-Medium',
  fontSize: 14,
  paddingTop: 3,
  paddingRight: 10
}

const merchantStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  minWidth: 300,
  border: 'solid 1px',
  borderColor: 'rgb(224,224,224)',
  padding: 10,
  borderRadius: 5,
  margin: 3,
  cursor: 'pointer'
}
