import { Card, CardContent } from '@material-ui/core'
import { PromotionInfoFragment, useAdjudicatePromotionMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React from 'react'

import { AdjudicatePromotionForm } from './AdjudicatePromotionForm'
import { AdjudicatePromotionFormModel } from './adjudicatePromotionValidator'

interface AdjudicatePromotionProps {
  onFinish: () => void
  promotion: PromotionInfoFragment
}

export const AdjudicatePromotion = (props: AdjudicatePromotionProps) => {
  const { promotion, onFinish } = props

  const toast = useToast()
  const [execute, { loading }] = useAdjudicatePromotionMutation()

  async function handleSubmit(form: AdjudicatePromotionFormModel) {
    try {
      const res = await execute({
        variables: { id: promotion.id, status: form.status, reason: form.reason }
      })

      if (res.data?.adjudicatePromotion?.status === form.status) {
        onFinish()
      } else {
        throw new Error('Status not updated')
      }
    } catch (e) {
      toast.error(`${e}`)
    }
  }

  return (
    <Card>
      <CardContent>
        <AdjudicatePromotionForm onSubmit={handleSubmit} onCancel={onFinish} disableSubmit={loading} />
      </CardContent>
    </Card>
  )
}
