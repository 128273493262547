import { Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { Setting } from 'views/merchants/components/MerchantSettings/useSettings'

import { OrganizationSettingContainer } from './OrganizationSettingContainer'
import { useSettings } from './useOrganizationSettings'

interface OrganizationSettingsContainerProps {
  organizationId: string
}

export const OrganizationSettingsContainer = (props: OrganizationSettingsContainerProps) => {
  const { organizationId } = props
  const { settings, loading } = useSettings(organizationId)

  return (
    <Section title='Settings' addon={<>{loading && <CircularProgress />}</>}>
      {Object.keys(settings).map((ky: string, index: number) => (
        <Section key={`${ky}.${index}`} title={ky} variant='subsection'>
          {settings[ky].map((setting: Setting, i: number) => (
            <OrganizationSettingContainer
              key={`${i}.${setting.name}`}
              organizationId={organizationId}
              setting={setting}
            />
          ))}
        </Section>
      ))}
    </Section>
  )
}
