import { useToast } from './useToast'

export const useErrorHandler = () => {
  const toast = useToast()

  const onGraphQLError = (error: any) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.debug('[GraphQL error]', error)
    }

    if (error.message) {
      toast.error(error.message)
    } else {
      toast.error('Unexpected error.')
      throw error
    }
  }

  return (error: any) => {
    if (error?.graphQLErrors) {
      error.graphQLErrors.forEach(onGraphQLError)
    } else if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.debug('[General Error]', error.message)
      throw error
    } else {
      toast.error('Unexpected error.')
      throw error
    }
  }
}
