import { Maybe, useLeadReceivedMutation } from 'graphql/types.generated'
import { useState } from 'react'

interface CafeLeadDetails {
  platformPricingId: Maybe<string>
  termPricing: boolean
  organizationName: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

const LOCAL_STORAGE_CAFE_LEAD_DETAILS = 'CafeLeadDetails'

export function useCafeLeadDetailsStorage() {
  const [saveLead] = useLeadReceivedMutation()

  function getCafeLeadDetails(): Maybe<CafeLeadDetails> {
    const current = localStorage.getItem(LOCAL_STORAGE_CAFE_LEAD_DETAILS)
    if (current) {
      return JSON.parse(current) as CafeLeadDetails
    }
    return null
  }

  const [cafeLeadDetails, setCafeLeadDetails] = useState<Maybe<CafeLeadDetails>>(getCafeLeadDetails)

  function saveCafeLeadDetails(data: CafeLeadDetails) {
    localStorage.setItem(LOCAL_STORAGE_CAFE_LEAD_DETAILS, JSON.stringify(data))
    setCafeLeadDetails(data)
    try {
      saveLead({
        variables: {
          input: {
            email: data.email,
            organizationName: data.organizationName,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            platformPricingId: data.platformPricingId,
            termSubscription: data.termPricing
          }
        }
      })
    } catch (e) {}
  }

  return { saveCafeLeadDetails, cafeLeadDetails }
}
