import { CircularProgress } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useFetchTopModifierQuery } from '../../../graphql/types.generated'
import { useUpdateModifier } from '../../../hooks/useUpdateModifier'
import { ModifierForm } from '../CreateModifier/ModifierForm'
import { ModifierFormModel } from '../CreateModifier/modifierValidator'
import { convertTopModifierToFormModel } from './convertTopModifierToFormModel'
import { UpdateModifierProps } from './UpdateModifier'

export const UpdateModifierForm = (props: UpdateModifierProps) => {
  const navigate = useNavigate()

  const { modifierId: paramModifierId, merchantId } = useParams()
  const { inlineEdit, onClose } = props
  const modifierId = props.modifierId ?? paramModifierId

  const { updateModifier, loading: loadingUpdate } = useUpdateModifier(merchantId!)
  const { data, loading, refetch } = useFetchTopModifierQuery({
    variables: {
      id: modifierId!
    }
  })
  const initialValues = useMemo(() => convertTopModifierToFormModel(data?.modifier), [data?.modifier])

  async function handleSubmit(input: ModifierFormModel) {
    const { options, id, allowedChildSelectionTypes, ...rest } = input

    const updatedOptions = options.map((o, i) => {
      return {
        modifierId: o.id,
        sortOrder: i
      }
    })
    const defaultSelectionIds = options.filter((o) => o.selectedByDefault).map((o) => o.id)
    await updateModifier(
      {
        id: id!,
        selectedName: undefined,
        options: updatedOptions,
        defaultSelectionIds,
        ...rest
      },
      () => done(true)
    )
  }

  async function done(refechModifier?: boolean) {
    if (!inlineEdit) {
      navigate(-1)
    } else if (!!onClose) {
      if (refechModifier) {
        var ret = await refetch()
        onClose(ret.data.modifier)
      } else {
        onClose()
      }
    }
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <ModifierForm
          isSynced={data?.modifier.isSynced}
          disableSubmit={loadingUpdate}
          merchantId={merchantId!}
          onCancel={() => done()}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      )}
    </>
  )
}
