import { Section } from '@bloom-coffee/steamed-milk'
import { Card, CardContent, CircularProgress, Typography } from '@material-ui/core'
import { useActiveCatalogPreviewQuery } from 'graphql/types.generated'
import { EmptyProductList } from 'views/products/components/CreateTempalteMenuButton.tsx/EmptyProductList'

import { SuggestedItemsSection } from '../../UpdateSuggestedItems/components/SuggestedItemsSection'

interface ActiveCatalogPreviewProps {
  organizationId: string
  merchantId: ID
}

export function ActiveCatalogPreview(props: ActiveCatalogPreviewProps) {
  const { organizationId, merchantId } = props
  const { data, loading, refetch } = useActiveCatalogPreviewQuery({
    variables: {
      merchantId
    }
  })

  if (loading) {
    return <CircularProgress />
  }

  const catalog = data?.merchant?.activeCatalog

  if (catalog?.categories?.every((c) => !c.activeProducts?.length)) {
    return <EmptyProductList organizationId={organizationId} merchantId={merchantId} onProductUpdate={refetch} />
  }

  return (
    <Section
      title='Catalog preview'
      subTitle='Your menu items will appear in the below order on your page within the RDY app.'
    >
      <SuggestedItemsSection suggestedItems={catalog?.suggestedItems} />
      {catalog?.categories?.map((category) => {
        const noProducts = (category.activeProducts?.length ?? 0) === 0
        return (
          <Section title={category.name} key={category.id} variant='subsection'>
            <Card variant='outlined'>
              <CardContent>
                {category.activeProducts?.map((product) => (
                  <Typography key={product.id}>{product.name}</Typography>
                ))}
                {noProducts && (
                  <Typography color='textSecondary'>
                    <em>No products added</em>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Section>
        )
      })}
      {!catalog?.categories?.length && (
        <Typography>
          <em>No Categories found</em>
        </Typography>
      )}
    </Section>
  )
}
