import {
  CategorySummary,
  MerchantReferralDetails,
  OrderDetails,
  OrderSummary,
  ProductSummary
} from 'views/merchants/components/Transactions/transactionTransforms'
import XLSX from 'xlsx'

import { readableDateTimeFromInstant } from './date'
import { convertUnitsFromCentsToDollars } from './formatting'

const currencyStringFormat = '$#,##0.00'
function s2ab(s: any) {
  var buf = new ArrayBuffer(s.length)
  var view = new Uint8Array(buf)
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

const download = (blob: Blob, name: string) => {
  const url = window.URL.createObjectURL(blob)
  let a = document.createElement('a')
  a.href = url
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}

export function exportToExcel(
  orderSummary: OrderSummary,
  orders: OrderDetails[],
  merchantReferrals: MerchantReferralDetails[]
) {
  const exportableSummary = orderSummaryToExcelSummary(orderSummary)
  const exportableOrders = orderTransactionsToExcelOrders(orders)
  const exportableMerchantReferrals = merchantReferralsToExcelReferrals(merchantReferrals)

  const summarySheet = XLSX.utils.json_to_sheet(exportableSummary, { skipHeader: true })
  sheet_set_column_formats(summarySheet, [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], currencyStringFormat)

  const transactionSheet = XLSX.utils.json_to_sheet(exportableOrders, { skipHeader: true })
  sheet_set_column_formats(transactionSheet, [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], currencyStringFormat)

  const referralSheet = XLSX.utils.json_to_sheet(exportableMerchantReferrals, { skipHeader: true })
  sheet_set_column_format(referralSheet, 1, currencyStringFormat)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, summarySheet, 'Summary')
  XLSX.utils.book_append_sheet(workbook, transactionSheet, 'Orders')
  XLSX.utils.book_append_sheet(workbook, referralSheet, 'Referrals')

  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })

  download(blob, 'OrdersSummary.xlsx')
}

export function exportItemSummariesToExcel(
  startDate: number,
  endDate: number,
  productSummaries: ProductSummary[],
  categorySummmaries: CategorySummary[]
) {
  const excelCategories = categorySummariesToExcelDto(categorySummmaries)
  const excelProducts = productSummariesToExcelDto(productSummaries)

  const categorySheet = XLSX.utils.json_to_sheet(excelCategories, { skipHeader: true })
  sheet_set_column_format(categorySheet, 2, currencyStringFormat)
  const productSheet = XLSX.utils.json_to_sheet(excelProducts, { skipHeader: true })
  sheet_set_column_format(productSheet, 3, currencyStringFormat)

  const dateRangeSheet = XLSX.utils.json_to_sheet(
    [
      ['Start Date', 'End Date'],
      [readableDateTimeFromInstant(startDate), readableDateTimeFromInstant(endDate)]
    ],
    { skipHeader: true }
  )
  sheet_set_column_format(productSheet, 3, currencyStringFormat)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, dateRangeSheet, 'DateRange')
  XLSX.utils.book_append_sheet(workbook, categorySheet, 'Categories')
  XLSX.utils.book_append_sheet(workbook, productSheet, 'Products')

  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })

  download(blob, 'ItemSummary.xlsx')
}

function sheet_set_column_formats(worksheet: XLSX.WorkSheet, columnIndicies: number[], numberFormatString: string) {
  for (let columnIndex of columnIndicies) {
    sheet_set_column_format(worksheet, columnIndex, numberFormatString)
  }
}

function sheet_set_column_format(worksheet: XLSX.WorkSheet, columnIndex: number, numberFormatString: string) {
  var range = XLSX.utils.decode_range(worksheet['!ref']!!)
  /* this loop starts on the second row, as it assumes the first row is a header */
  for (var R = range.s.r + 1; R <= range.e.r; ++R) {
    var cell = worksheet[XLSX.utils.encode_cell({ r: R, c: columnIndex })]
    if (!cell) continue
    cell.z = numberFormatString
  }
}

export function orderSummaryToExcelSummary(orderSummary: OrderSummary) {
  return [
    [
      'Orders',
      'Start Date',
      'End Date',
      'RDY Tokens',
      'Organization Tokens',
      'Token Value',
      'Token Tips',
      'Token Fees',
      'Sub Totals',
      'Taxes',
      'Tips',
      'Customer Fees',
      'Merchant Fees',
      'RDY Discount Fee',
      'RDY Discounts',
      'Order Payout',
      'Referral Payout'
    ],
    [
      orderSummary.totalOrders,
      readableDateTimeFromInstant(orderSummary.startDate!!),
      readableDateTimeFromInstant(orderSummary.endDate!!),
      orderSummary.totalRdyTokens,
      orderSummary.totalOrganizationTokens,
      convertUnitsFromCentsToDollars(orderSummary.totalTokenValue),
      convertUnitsFromCentsToDollars(orderSummary.totalTokenTipValue),
      convertUnitsFromCentsToDollars(orderSummary.totalTokenFeeValue),
      convertUnitsFromCentsToDollars(orderSummary.totalSubTotal),
      convertUnitsFromCentsToDollars(orderSummary.totalTaxes),
      convertUnitsFromCentsToDollars(orderSummary.totalTips),
      convertUnitsFromCentsToDollars(orderSummary.totalCustomerFees),
      convertUnitsFromCentsToDollars(orderSummary.totalMerchantFees),
      convertUnitsFromCentsToDollars(orderSummary.totalMerchantDiscountFeeUsCents),
      convertUnitsFromCentsToDollars(orderSummary.totalRdyPromotionDiscountValue),
      convertUnitsFromCentsToDollars(orderSummary.totalNetMerchantPayout),
      convertUnitsFromCentsToDollars(orderSummary.totalReferralPayout)
    ]
  ]
}

export function orderTransactionsToExcelOrders(orders: OrderDetails[]) {
  return [
    [
      'Order Id',
      'Order Date',
      'Customer Name',
      'RDY Tokens',
      'Organization Tokens',
      'Token Value',
      'Token Tips',
      'Token Fee',
      'Sub Total',
      'Taxes',
      'Tip',
      'Customer Fee',
      'Merchant Fee',
      'RDY Discount Fee',
      'RDY Discount',
      'Order Payout'
    ],
    ...orders.map((order: OrderDetails) => [
      order.id,
      readableDateTimeFromInstant(order.date),
      order.name,
      order.rdyTokens,
      order.organizationTokens,
      convertUnitsFromCentsToDollars(order.tokenValue),
      convertUnitsFromCentsToDollars(order.tokenTipValue),
      convertUnitsFromCentsToDollars(order.tokenFeeValue),
      convertUnitsFromCentsToDollars(order.subTotal),
      convertUnitsFromCentsToDollars(order.taxes),
      convertUnitsFromCentsToDollars(order.tip),
      convertUnitsFromCentsToDollars(order.customerFee),
      convertUnitsFromCentsToDollars(order.merchantFee),
      convertUnitsFromCentsToDollars(order.merchantDiscountFeeUsCents),
      convertUnitsFromCentsToDollars(order.rdyPromotionDiscountValue),
      convertUnitsFromCentsToDollars(order.netMerchantPayout)
    ])
  ]
}

function merchantReferralsToExcelReferrals(referrals: MerchantReferralDetails[]) {
  return [
    ['Referral Date', 'Payout'],
    ...referrals.map((referral: MerchantReferralDetails) => [
      readableDateTimeFromInstant(referral.referralDate),
      convertUnitsFromCentsToDollars(referral.payout)
    ])
  ]
}

function productSummariesToExcelDto(productSummaries: ProductSummary[]) {
  return [
    ['Product', 'Category', 'Count Sold', 'Sales'],
    ...productSummaries.map((productSummary: ProductSummary) => [
      productSummary.productName,
      productSummary.categoryName,
      productSummary.sold,
      convertUnitsFromCentsToDollars(productSummary.sales)
    ])
  ]
}

function categorySummariesToExcelDto(categorySummmaries: CategorySummary[]) {
  return [
    ['Category', 'Count Sold', 'Sales'],
    ...categorySummmaries.map((categorySummmary: CategorySummary) => [
      categorySummmary.categoryName,
      categorySummmary.sold,
      convertUnitsFromCentsToDollars(categorySummmary.sales)
    ])
  ]
}
