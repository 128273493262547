import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps
} from '@material-ui/core'
import { useFieldError } from 'hooks/useFieldError'
import { PropsWithChildren } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export interface SelectFieldProps extends MuiSelectProps {
  label: string
  name: string
  style?: React.CSSProperties
}

export function SelectField(props: PropsWithChildren<SelectFieldProps>) {
  const { children, defaultValue, fullWidth, label, name, onChange: onChangeOverride, style, disabled } = props
  const error = useFieldError<any>({ name })
  const { control } = useFormContext()

  return (
    <FormControl fullWidth={fullWidth} variant='outlined' style={style}>
      <InputLabel error={!!error}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        render={({ onChange, ...props }) => (
          <MuiSelect
            disabled={disabled}
            fullWidth={fullWidth}
            {...props}
            label={label}
            error={!!error}
            inputProps={{ name }}
            onChange={(e, c) => {
              if (onChange) onChange(e, c)
              if (onChangeOverride) onChangeOverride(e, c)
            }}
          >
            {children}
          </MuiSelect>
        )}
      />
      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  )
}
