import { Button, Section } from '@bloom-coffee/steamed-milk'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'

import { HasAnyRole } from '../../../../components/auth/HasAnyRole'
import { TopModifierFragment, useTopModifiersByMerchantQuery } from '../../../../graphql/types.generated'
import { roles } from '../../../../hooks/usePermissions/usePermissions'
import { TopModifierDetailsContainer } from './TopModifierDetailsContainer'

interface TopModifiersListContainerProps {
  merchantId: string
  organizationId: string
  createTopModifier: () => void
  onEdit: (id: string) => void
}

const useStyles = makeStyles((theme) => ({
  heading: {
    flexBasis: '90%',
    flexShrink: 0
  }
}))

export const TopModifiersListContainer = (props: TopModifiersListContainerProps) => {
  const { merchantId, organizationId, createTopModifier, onEdit } = props
  const styles = useStyles()

  const { data, loading } = useTopModifiersByMerchantQuery({
    variables: { merchantId },
    fetchPolicy: 'cache-first'
  })

  return (
    <Section
      title='Product Modifiers'
      addon={
        <>
          <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId), roles.merchantAdmin(merchantId)]}>
            <Button endIcon={<AddIcon />} label='Add Modifier' onClick={createTopModifier} />
          </HasAnyRole>
        </>
      }
      variant='grid'
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        data?.topModifiersByMerchant?.map((tm: TopModifierFragment) => {
          return (
            <Accordion key={tm.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label='Expand'
                aria-controls='additional-actions1-content'
                id={`${tm.id}`}
              >
                <Typography className={styles.heading}>{tm.name}</Typography>
                {!tm.active && <Typography color='primary'>Inactive</Typography>}
              </AccordionSummary>
              <AccordionDetails>
                <TopModifierDetailsContainer onEdit={onEdit} modifier={tm} merchantId={merchantId} />
              </AccordionDetails>
            </Accordion>
          )
        })
      )}
    </Section>
  )
}
