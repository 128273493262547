import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@material-ui/core'
import { DateTimeField } from 'components/form/DateTimeField'
import { FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../../../components/form/DefaultFormFooter'
import { TextField } from '../../../../../components/form/TextField'
import { PromoCodeFormModel, promoCodeSchema } from './promoCodeValidator'

interface PromoCodeFormProps {
  onCancel: () => void
  onSubmit: (values: PromoCodeFormModel) => void
  disableSubmit?: boolean
  promotionId: string
}

export const PromoCodeForm = (props: PromoCodeFormProps) => {
  const { onCancel, onSubmit, disableSubmit, promotionId } = props

  const formProps = useForm<PromoCodeFormModel>({
    resolver: yupResolver(promoCodeSchema),
    shouldUnregister: false,
    defaultValues: { promotionId }
  })

  const { register, handleSubmit, formState } = formProps
  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={4}>
            <TextField fullWidth label='Code' name='code' inputRef={register()} />
          </Grid>

          <Grid item xs={4}>
            <TextField fullWidth label='Limit Total Uses' name='limitTotalUses' inputRef={register()} />
          </Grid>

          <Grid item xs={6}>
            <DateTimeField name='validStart' label='Start Date*' defaultValue={new Date().toString()} />
          </Grid>

          <Grid item xs={6}>
            <DateTimeField name='validEnd' label='End Date*' />
          </Grid>

          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body2' color='secondary'>
              *Timezone is local to your computer
            </Typography>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
