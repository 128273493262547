import { makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { PaymentAccountValidation } from 'graphql/types.generated'
interface AccountStatusProps {
  validatePaymentResult: PaymentAccountValidation
}

export function AccountStatus(props: AccountStatusProps) {
  const { validatePaymentResult } = props
  const classes = useStyles()

  return (
    <>
      <Alert severity={validatePaymentResult.chargesEnabled ? 'success' : 'warning'} className={classes.alert}>
        <>
          {validatePaymentResult.chargesEnabled && (
            <Typography>Your Stripe account is set up and ready to start accepting payments.</Typography>
          )}
          {!validatePaymentResult.chargesEnabled && (
            <Typography>
              This Stripe account configuration is not complete. Click the "Setup/Update Stripe Account" button to
              finish the Stripe account set up.
            </Typography>
          )}
        </>
      </Alert>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: 20
  }
}))
