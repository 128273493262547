import { Button } from '@bloom-coffee/steamed-milk'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { CatalogProductFragment } from 'graphql/types.generated'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

interface ItemEntryProps {
  item: CatalogProductFragment
  index: number
  onRemove(): void
}

export function ItemEntry(props: ItemEntryProps) {
  const { item, index, onRemove } = props
  const styles = useStyles()

  return (
    <Grid item xs={12}>
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <Paper
            variant={snapshot.isDragging ? 'elevation' : 'outlined'}
            className={clsx(styles.listItem, snapshot.isDragging && styles.listItemDragging)}
            elevation={snapshot.isDragging ? 4 : 0}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            innerRef={provided.innerRef}
          >
            <div className={styles.listItemName}>
              <Typography>{item.name}&nbsp;</Typography>
              <Typography color='textSecondary' variant='body2'>
                - {positions[index]}
              </Typography>
            </div>
            <Button onClick={onRemove} label='Remove' />
          </Paper>
        )}
      </Draggable>
    </Grid>
  )
}

const positions = ['Top left', 'Top right', 'Bottom left', 'Bottom right']

const useStyles = makeStyles((theme) => ({
  listItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1)
  },
  listItemName: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  listItemDragging: {
    border: '1px solid',
    borderColor: theme.palette.primary.main
  }
}))
