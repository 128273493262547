import { useNavigate, useParams } from 'react-router-dom'

import {
  TopModifierFragment,
  TopModifiersByMerchantDocument,
  useCreateModifierMutation
} from '../../../graphql/types.generated'
import { useToast } from '../../../hooks/useToast'
import { ModifierForm } from './ModifierForm'
import { ModifierFormModel } from './modifierValidator'

interface CreateModifierProps {
  onCreate?: (modifier: TopModifierFragment) => void
  inlineCreate?: boolean
  onClose?: () => void
}

export const CreateModifier = (props: CreateModifierProps) => {
  const { onCreate, inlineCreate, onClose } = props
  const navigate = useNavigate()
  const toast = useToast()
  const { merchantId } = useParams()
  const [execute] = useCreateModifierMutation()

  async function handleSubmit(input: ModifierFormModel) {
    try {
      const { options, allowedChildSelectionTypes, ...rest } = input

      const updatedOptions = options.map((o, i) => {
        return {
          modifierId: o.id,
          sortOrder: i
        }
      })
      const defaultSelectionIds = options.filter((o) => o.selectedByDefault).map((o) => o.id)

      const res = await execute({
        variables: {
          input: {
            ...rest,
            selectedName: undefined,
            printableName: undefined,
            merchantId: merchantId!,
            options: updatedOptions,
            defaultSelectionIds
          }
        },
        refetchQueries: [
          {
            query: TopModifiersByMerchantDocument,
            variables: { merchantId: merchantId }
          }
        ]
      })

      if (res.data?.createModifier) {
        toast.success('Modifier created')
        if (!!onCreate) {
          onCreate(res.data?.createModifier)
        } else {
          navigate(-1)
        }
      }
    } catch (e) {
      toast.error(e)
    }
  }

  function onCancel() {
    if (!!onClose) {
      onClose()
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <ModifierForm
        merchantId={merchantId!}
        onSubmit={handleSubmit}
        initialValues={{}}
        onCancel={onCancel}
        submitLabel={inlineCreate ? 'Add' : undefined}
      />
    </>
  )
}
