import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, DialogContentText } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ModifierOptionFragment, useFetchModifierOptionQuery } from '../../../graphql/types.generated'
import { useArchiveModifierOption } from '../../../hooks/useArchiveModifierOption'
import { UpdateModifierOptionForm } from './UpdateModifierOptionForm'

export interface UpdateModifierOptionProps {
  modifierId?: string
  onClose?: (updatedModifier?: ModifierOptionFragment) => void
}

export const UpdateModifierOption = (props: UpdateModifierOptionProps) => {
  const navigate = useNavigate()
  const { merchantId, modifierId: paramsModiferId } = useParams()

  const modifierId = props.modifierId ?? paramsModiferId!
  const { onClose } = props
  const [showDeletePrompt, setShowDeletePrompt] = useState(false)

  const { archive, loading: loadingArchive } = useArchiveModifierOption(merchantId!)
  const { data, loading } = useFetchModifierOptionQuery({
    variables: {
      id: modifierId
    }
  })

  async function handleDelete() {
    await archive(modifierId, () => navigate(-1))
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Section
          title={`Update ${data?.modifier.name}`}
          addon={
            <Button
              disabled={loadingArchive || loading || data?.modifier?.isSynced}
              theme='warning'
              endIcon={<DeleteIcon />}
              onClick={handleDelete}
            />
          }
        >
          <UpdateModifierOptionForm modifierId={modifierId} onClose={onClose} isSynced={data?.modifier?.isSynced} />
          <ConfirmationDialog
            title='Are you sure?'
            open={showDeletePrompt}
            onClose={() => setShowDeletePrompt(false)}
            onConfirm={handleDelete}
          >
            <DialogContentText>Are you sure you want to delete this Modifier Option?</DialogContentText>
          </ConfirmationDialog>
        </Section>
      )}
    </>
  )
}
