import { Button } from '@bloom-coffee/steamed-milk'
import { Typography } from '@material-ui/core'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import { DataGrid } from '@mui/x-data-grid'
import { usePromotionsByMerchantQuery } from 'graphql/types.generated'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatUsCentsAsUsDollar } from 'util/formatting'

export const MerchantPromotionsList = () => {
  const { merchantId } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { data, loading } = usePromotionsByMerchantQuery({
    variables: { merchantId: merchantId! },
    fetchPolicy: 'cache-first'
  })

  if (data?.promotionsByMerchant?.length === 0 && !loading) {
    return <h3 style={{ textAlign: 'center' }}>No Promotions</h3>
  }

  return (
    <DataGrid
      loading={loading}
      autoHeight
      hideFooterPagination
      disableSelectionOnClick
      rows={data?.promotionsByMerchant || []}
      columns={[
        { field: 'status', headerName: 'Status', sortable: true, width: 100 },
        { field: 'customerFacingName', headerName: 'Promotion Name', sortable: true, width: 200 },
        {
          field: 'start',
          headerName: 'Start',
          sortable: true,
          width: 135,
          renderCell(params) {
            const d = new Date(params.row.start)
            return <Typography variant='body2'>{d.toDateString()}</Typography>
          }
        },
        {
          field: 'end',
          headerName: 'End',
          sortable: false,
          width: 135,
          renderCell(params) {
            const d = params.row.end ? new Date(params.row.end).toDateString() : 'Indefinite'
            return <Typography variant='body2'>{d}</Typography>
          }
        },
        {
          field: 'newUserOnly',
          headerName: 'New Users Only',
          sortable: false,
          width: 140,
          renderCell(params) {
            const d = params.row.newUserOnly ? 'Yes' : 'No'
            return <Typography variant='body2'>{d}</Typography>
          }
        },
        {
          field: 'limitPerUser',
          headerName: 'Limit Per User',
          sortable: false,
          width: 140,
          renderCell(params) {
            return <Typography variant='body2'>{params.row.limitPerUser}</Typography>
          }
        },
        {
          field: 'flatDiscountUsCents',
          headerName: 'Flat Discount',
          sortable: false,
          width: 140,
          renderCell(params) {
            return (
              <Typography variant='body2'>
                {params.row.flatDiscountUsCents ? formatUsCentsAsUsDollar(params.row.flatDiscountUsCents) : 'None'}
              </Typography>
            )
          }
        },
        {
          field: 'percentageDiscount',
          headerName: 'Percentage Discount',
          sortable: false,
          width: 160,
          renderCell(params) {
            return (
              <Typography variant='body2'>
                {params.row.percentageDiscount ? `${params.row.percentageDiscount}%` : 'None'}
              </Typography>
            )
          }
        },
        {
          field: 'maxDiscountUsCents',
          headerName: 'Max Discount',
          sortable: false,
          width: 140,
          renderCell(params) {
            return (
              <Typography variant='body2'>
                {params.row.maxDiscountUsCents ? formatUsCentsAsUsDollar(params.row.maxDiscountUsCents) : 'None'}
              </Typography>
            )
          }
        },
        {
          field: '',
          headerName: '',
          sortable: false,
          width: 150,
          renderCell(params) {
            function handleView() {
              navigate(`${pathname}/${params.row.id}`)
            }
            return <Button label='View' onClick={handleView} endIcon={<ArrowForwardIos style={{ height: 14 }} />} />
          }
        }
      ]}
    />
  )
}
