import { Button, Color } from '@bloom-coffee/steamed-milk'
import { CircularProgress, TextField } from '@material-ui/core'
import { useConfirmedRequisitionsLazyQuery } from 'graphql/types.generated'
import { logger } from 'logger'
import React, { useState } from 'react'

import { RequisitionCustomerDetails } from './RequisitionCustomerDetails'

export const ConfirmedRequisitionsContainer = () => {
  const [yearStr, setYearStr] = useState('2023')
  const [monthStr, setMonthStr] = useState('1')

  const [execute, { data, loading }] = useConfirmedRequisitionsLazyQuery({ fetchPolicy: 'cache-first' })

  function handleButton() {
    try {
      const year = parseInt(yearStr, 10)
      const month = parseInt(monthStr, 10)
      if (year > 2020 && year < 2100 && month >= 1 && month <= 12) {
        execute({ variables: { year, month } })
      } else {
        throw new Error('unreasonable years')
      }
    } catch (e) {
      logger.error('ConfirmRequisitionsContainer', `${e}`)
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
        <TextField label='Year' value={yearStr} onChange={(e) => setYearStr(e.target.value)} />

        <TextField label='Month' value={monthStr} onChange={(e) => setMonthStr(e.target.value)} />
        <div>
          <Button label='Fetch' theme='action' size='medium' onClick={() => handleButton()} />
        </div>
      </div>

      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )}

      <div>
        {data?.confirmedRequisitions.map((r, index) => (
          <div
            key={`${r.id}.${index}`}
            style={{
              border: `1px solid ${Color.RDY_FOREST}`,
              paddingTop: 12,
              paddingLeft: 12,
              paddingRight: 12,
              paddingBottom: 24,
              marginBottom: 30,
              backgroundColor: Color.WHITE
            }}
          >
            <RequisitionCustomerDetails requisition={r} />
          </div>
        ))}
      </div>
    </div>
  )
}
