import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core'
import { useFieldError } from 'hooks/useFieldError'
import { useController } from 'react-hook-form'

type PriceFieldProps = MuiTextFieldProps & {
  name: string
}

/**
 * Format values as cents
 *
 */
export function PriceField(props: PriceFieldProps) {
  const { name, defaultValue = 0, ...rest } = props
  const error = useFieldError<any>({ name })

  const {
    //name causes a bug on defaultValue
    field: { name: excludedName, value, onChange, ...fieldProps }
  } = useController({
    name,
    defaultValue
  })

  return (
    <MuiTextField
      name={name}
      {...rest}
      {...fieldProps}
      error={!!error}
      helperText={error?.message}
      variant='outlined'
      onChange={(e) => onChange(parseValue(e.target.value))}
      value={formatValue(value)}
    />
  )
}

const parseValue = (rawValue: string) => {
  if (rawValue === '$0.0') {
    return null
  }
  return Number(rawValue.toString().replace(/[^0-9-]/g, ''))
}

function formatValue(value: number | null) {
  if (value === null) {
    return ''
  }
  const dollars = value / 100
  return formatter.format(dollars)
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})
