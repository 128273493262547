import { useAuth0 } from '@auth0/auth0-react'
import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ColorHex } from 'theme/ThemeConstants'

import { AdminHeader } from './AdminHeader'
import { MerchantHeader } from './MerchantHeader'
import { OnboardingHeader } from './OnboardingHeader'
import { OrganizationHeader } from './OrganizationHeader'
import { SignUpHeader } from './SignUpHeader'

export function Header() {
  const { isAuthenticated, isLoading } = useAuth0()
  const { hasAnyRole } = usePermissions()
  const hasRole = hasAnyRole(roles.anyOrganizationMember)

  return (
    <div style={mainHeaderStyle}>
      <div style={titleHeaderStyle}>
        {!isAuthenticated && <SignUpHeader />}
        {!hasRole && !isLoading && isAuthenticated && <OnboardingHeader />}
        {hasRole && !isLoading && isAuthenticated && (
          <Routes>
            <Route path='/admin/*' element={<AdminHeader />} />
            <Route path={`/organizations/:organizationId/merchants/new`} element={<OrganizationHeader />} />
            <Route
              path={`/organizations/:organizationId/merchants/importFromSquare`}
              element={<OrganizationHeader />}
            />
            <Route path={`/organizations/:organizationId/merchants/:merchantId/*`} element={<MerchantHeader />} />
            <Route path={`/organizations/:organizationId/*`} element={<OrganizationHeader />} />
            <Route path='/square' element={<OnboardingHeader />} />
          </Routes>
        )}
      </div>
    </div>
  )
}

const mainHeaderStyle: React.CSSProperties = {
  width: '100%',
  backgroundColor: ColorHex.RDY_FOREST,
  display: 'flex',
  flexDirection: 'row'
}

const titleHeaderStyle: React.CSSProperties = {
  color: ColorHex.WHITE,
  display: 'flex',
  flex: 1,
  flexDirection: 'column'
}
