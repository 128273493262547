import { Section } from '@bloom-coffee/steamed-milk'
import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ActiveCatalogPreview } from 'views/catalogs/components/ActiveCatalogPreview/ActiveCatalogPreview'
import { CatalogList } from 'views/catalogs/components/CatalogList/CatalogList'
import { CreateCatalog } from 'views/catalogs/components/CatalogList/CreateCatalog'
import { UpdateSuggestedItems } from 'views/catalogs/UpdateSuggestedItems/UpdateSuggestedItems'
import { CreateModifier } from 'views/modifiers/CreateModifier/CreateModifier'
import { CreateModifierOption } from 'views/modifiers/CreateModifierOption/CreateModifierOption'
import { UpdateModifier } from 'views/modifiers/UpdateModifier/UpdateModifier'
import { UpdateModifierOption } from 'views/modifiers/UpdateModifierOption/UpdateModifierOption'
import { CreateCategory } from 'views/products/components/CreateCategory/CreateCategory'
import { CreateProduct } from 'views/products/CreateProduct/CreateProduct'
import { ProductManagement } from 'views/products/ProductManagement/ProductManagement'
import { UpdateProduct } from 'views/products/UpdateProduct/UpdateProduct'
import { ImportProductsFromSquareContainer } from 'views/square/ImportProductsFromSquareContainer'

import { ModifiersContainer } from '../../../modifiers/ModifiersContainer'

interface MenuContainerProps {
  merchantId: ID
  organizationId: ID
}

export function MenuContainer(props: MenuContainerProps) {
  const { merchantId, organizationId } = props

  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/menu/products`,
          paths: [
            `/organizations/:organizationId/merchants/:merchantId/menu/products/*`,
            `/organizations/:organizationId/merchants/:merchantId/menu/categories/*`
          ],
          text: 'Products'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/menu/modifiers`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/menu/modifiers/*`],
          text: 'Modifiers'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/menu/catalogPreview`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/menu/catalogPreview/*`],
          text: 'Suggested Items'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/menu/menus`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/menu/menus/*`],
          text: 'Menus'
        }
      ]}
    >
      <Routes>
        <Route path={`menus`} element={<CatalogList merchantId={merchantId} />} />
        <Route path={`menus/new`} element={<CreateCatalog merchantId={merchantId} />} />
        <Route
          path={`catalogPreview`}
          element={<ActiveCatalogPreview organizationId={organizationId} merchantId={merchantId} />}
        />
        <Route
          path={`products`}
          element={<ProductManagement merchantId={merchantId} organizationId={organizationId} />}
        />
        <Route
          path={`modifiers`}
          element={<ModifiersContainer merchantId={merchantId} organizationId={organizationId} />}
        />
        <Route path={`categories/new`} element={<CreateCategory />} />
        <Route path={`catalogPreview/updateSuggested`} element={<UpdateSuggestedItems />} />
        <Route path={`modifiers/options/:modifierId/edit`} element={<UpdateModifierOption />} />
        <Route path={`modifiers/:modifierId/edit`} element={<UpdateModifier />} />
        <Route path={`modifiers/options/new`} element={<CreateModifierOption />} />
        <Route
          path={`modifiers/new`}
          element={
            <Section title={'Create a new Modifier'}>
              <CreateModifier />
            </Section>
          }
        />
        <Route path='/products/importFromSquare' element={<ImportProductsFromSquareContainer />} />
        <Route path={`products/new`} element={<CreateProduct />} />
        <Route path={`products/:productId/edit`} element={<UpdateProduct />} />
        <Route path={`*`} element={<Navigate to={'products'} replace={true} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
