import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Grid, makeStyles, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { CreateModifier } from 'views/modifiers/CreateModifier/CreateModifier'

import { TopModifierFragment, useTopModifiersByMerchantQuery } from '../../../graphql/types.generated'
import { convertTopModiferToFormModel } from '../UpdateProduct/convertProductDetailsToFormModel'
import { ModifiersForSelectionContainer } from './ModifiersForSelectionContainer'
import { ModifierForProductFormModel } from './productValidator'

interface SelectModifiersContainerProps {
  organizationId: string
  merchantId: string
  onCancel: () => void
  handleSelection: (option: ModifierForProductFormModel) => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 24
  }
}))

export const SelectModifiersContainer = (props: SelectModifiersContainerProps) => {
  const { merchantId, onCancel, handleSelection } = props

  const styles = useStyles()
  const { data, loading } = useTopModifiersByMerchantQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })
  const [showCreateModifier, setShowCreateModifier] = useState(false)

  function handleSelectedFragment(modifier: TopModifierFragment) {
    return handleSelection(convertTopModiferToFormModel(modifier))
  }

  function handleCreate() {
    setShowCreateModifier(true)
  }

  return (
    <Paper className={styles.container}>
      <Section
        title={showCreateModifier ? 'Create a Modifier' : 'Select a Modifier'}
        addon={<Button endIcon={<CloseIcon />} onClick={onCancel} label='Close' theme='cancel' />}
        variant='subsection'
      >
        {!showCreateModifier && (
          <Grid container spacing={3}>
            <Grid item xs={4}>
              {loading ? (
                <CircularProgress />
              ) : (
                <ModifiersForSelectionContainer
                  onSelect={handleSelectedFragment}
                  modifiers={data?.topModifiersByMerchant || []}
                />
              )}
            </Grid>

            <Grid item>
              <Button label='Create A New Modifier' endIcon={<AddIcon />} onClick={handleCreate} />
            </Grid>
          </Grid>
        )}
        {showCreateModifier && (
          <CreateModifier onCreate={handleSelectedFragment} inlineCreate={true} onClose={onCancel} />
        )}
      </Section>
    </Paper>
  )
}
