import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../../components/form/DefaultFormFooter'
import { TextField } from '../../../../components/form/TextField'
import { ServingLocationFormModel, servingLocationSchema } from './servingLocationValidator'

interface ServingLocationFormProps {
  onCancel: () => void
  onSubmit: (values: ServingLocationFormModel) => void
  initialValues: DefaultValues<ServingLocationFormModel> | undefined
}

export function ServingLocationForm(props: ServingLocationFormProps) {
  const { onCancel, onSubmit, initialValues } = props

  const formProps = useForm<ServingLocationFormModel>({
    defaultValues: initialValues,
    resolver: yupResolver(servingLocationSchema),
    shouldUnregister: false
  })
  const { register, handleSubmit, formState } = formProps

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          autoFocus
          name='displayName'
          label='Display Name'
          inputRef={register()}
          defaultValue={initialValues?.displayName}
        />
        <DefaultFormFooter onCancel={onCancel} onSubmit={handleSubmit(onSubmit)} submitting={formState.isSubmitting} />
      </form>
    </FormProvider>
  )
}
