import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import React from 'react'

export interface WizardFooterProps {
  nextLabel?: string
  onBack?(): void
  onSubmit(): void
  loading: boolean
}

export function WizardFooter(props: WizardFooterProps) {
  const { nextLabel = 'Next', onBack, onSubmit, loading } = props
  return (
    <div style={actionsStyle}>
      {onBack && <Button style={buttonStyle} onClick={onBack} label='Back' theme='cancel'></Button>}
      <Button
        onClick={onSubmit}
        label={nextLabel}
        disabled={loading}
        endIcon={loading && <CircularProgress color='inherit' size={20} />}
      />
    </div>
  )
}

const actionsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const buttonStyle: React.CSSProperties = {
  marginRight: 5
}
