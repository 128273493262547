import { ApolloCache, FetchResult } from '@apollo/client'

import {
  ArchiveOrganizationMutation,
  OrganizationDetailsFragment,
  useArchiveOrganizationMutation
} from '../graphql/types.generated'
import { useToast } from './useToast'

export function useArchiveOrganization() {
  const toast = useToast()
  const [execute, { loading }] = useArchiveOrganizationMutation()

  function updateCache(
    cache: ApolloCache<ArchiveOrganizationMutation>,
    mutationResult: FetchResult<ArchiveOrganizationMutation>
  ) {
    const idToRemove = mutationResult.data?.archiveOrganization.id

    if (idToRemove) {
      cache.modify({
        fields: {
          organizations(existingOrgs, { readField }) {
            return existingOrgs.filter((org: OrganizationDetailsFragment) => idToRemove !== readField('id', org))
          }
        }
      })
    }
  }

  async function archive(id: string, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { id },
        update: updateCache
      })

      if (res.data?.archiveOrganization) {
        onSuccess()
      } else {
        throw new Error(`Could not archive organization: ${res.errors}`)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  return { archive, loading }
}
