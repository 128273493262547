import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem } from '@material-ui/core'
import { SelectField } from 'components/form/SelectField'
import { PromotionStatus } from 'graphql/types.generated'
import { FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../../../components/form/DefaultFormFooter'
import { TextField } from '../../../../../components/form/TextField'
import { AdjudicatePromotionFormModel, adjudicatePromotionSchema } from './adjudicatePromotionValidator'

interface AdjudicatePromotionFormProps {
  onCancel: () => void
  onSubmit: (values: AdjudicatePromotionFormModel) => void
  disableSubmit?: boolean
}

export const AdjudicatePromotionForm = (props: AdjudicatePromotionFormProps) => {
  const { onCancel, onSubmit, disableSubmit } = props

  const formProps = useForm<AdjudicatePromotionFormModel>({
    resolver: yupResolver(adjudicatePromotionSchema),
    shouldUnregister: false
  })

  const { register, handleSubmit, formState } = formProps
  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <SelectField fullWidth label='Status' name='status'>
              <MenuItem value={undefined}>
                <em>Select a status</em>
              </MenuItem>

              {Object.values(PromotionStatus).map((pt, index) => (
                <MenuItem key={`${pt}.${index}`} value={pt}>
                  {pt}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Reason (optional)'
              name='reason'
              multiline
              inputRef={register()}
              rows={5}
              variant='outlined'
            />
          </Grid>

          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
