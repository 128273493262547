import { Typography } from '@material-ui/core'
import { OutputDestination } from 'graphql/types.generated'
import React from 'react'

import { OutputStationCategoryButton } from './OutputStationCategoryButton'

interface DeviceOutputStationContainerProps {
  outputDestination: OutputDestination
  addUpdatedSetting: (categoryOutputDestinationId: ID, active: boolean) => void
}

export const DeviceOutputStationContainer = (props: DeviceOutputStationContainerProps) => {
  const { outputDestination, addUpdatedSetting } = props

  return (
    <div>
      <Typography variant='h6'>{outputDestination.kdsIntegrationDevice?.name}</Typography>
      <Typography>
        The following product categories will be sent to {outputDestination.kdsIntegrationDevice?.name || 'this device'}
        :
      </Typography>
      <div style={categoryContainerStyle}>
        {outputDestination.categorySettings?.map((cs) => (
          <OutputStationCategoryButton key={cs.id} setting={cs} addUpdatedSetting={addUpdatedSetting} />
        ))}
      </div>
    </div>
  )
}

const categoryContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}
