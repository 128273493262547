import { Button, Color, Section } from '@bloom-coffee/steamed-milk'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as FreshLogo } from 'assets/svg/freshLogo.svg'
import { FreshToGoLogin } from 'components/FreshToGoIntegration/FreshToGoLogin'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { FreshBrand, getFreshBrands } from 'util/freshToGoUtil'

import { ResyncFreshMenu } from './ResyncFreshMenu'

interface ResyncFreshMenuModalProps {
  onCancel: () => void
  onSuccess: () => void
  merchantId: string
}

export function ResyncFreshMenuModal(props: ResyncFreshMenuModalProps) {
  const { onCancel, onSuccess, merchantId } = props

  const styles = useStyles()
  const toast = useToast()
  const [token, setToken] = useState<string | null>()
  const [brandId, setBrandId] = useState<string | null>()
  const [brands, setBrands] = useState<FreshBrand[]>([])
  const [loadingBrands, setLoadingBrands] = useState(false)

  async function loggedIn(tokenResponse: string) {
    setToken(tokenResponse)
    loadBrands(tokenResponse)
  }

  async function loadBrands(userToken: string) {
    setLoadingBrands(true)
    try {
      let brandResponse = await getFreshBrands(userToken)
      setBrands(brandResponse)
      if (brandResponse.length === 1) {
        setBrandId(brandResponse[0].id)
      }
    } catch (e: any) {
      toast.error('Unable to load Brands from Fresh')
    } finally {
      setLoadingBrands(false)
    }
  }

  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle disableTypography>
        <div className={styles.dialogTitle}>
          <FreshLogo style={{ width: 120, height: 'auto' }} />
          <h2>Resync Fresh Menu</h2>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {!token && <FreshToGoLogin onCancel={onCancel} onSuccess={loggedIn} />}
        {!!token && !!brands.length && !brandId && (
          <Section title='Which Fresh Brand is your Cafe under?'>
            <FormControl style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {brands.map((brand) => (
                <Button onClick={() => setBrandId(brand.id)} label={brand.name} style={brandButtonStyle}></Button>
              ))}
            </FormControl>
          </Section>
        )}
        {!!loadingBrands && (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: 100 }}>
            {!!loadingBrands && <Typography>Fetching Fresh Brands</Typography>}
            <CircularProgress />
          </div>
        )}

        {!!token && !!brandId && (
          <ResyncFreshMenu
            onCancel={onCancel}
            onSuccess={onSuccess}
            merchantId={merchantId}
            token={token!}
            brandId={brandId!}
          ></ResyncFreshMenu>
        )}
      </DialogContent>
    </Dialog>
  )
}

const brandButtonStyle: React.CSSProperties = {
  margin: 5,
  backgroundColor: Color.RDY_MALIBU
}

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: theme.palette.primary.main,
    paddingBottom: 0,
    borderBottom: '1px solid'
  }
}))
