import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Typography } from '@material-ui/core'
import {
  useMerchantReferralsByMerchantIdQuery,
  useOrderReportingDetailsByMerchantIdQuery
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { readableDateFromInstant } from 'util/date'
import { exportToExcel } from 'util/exportToExcel'

import { MerchantReferralsDataGrid } from './MerchantReferralsDataGrid'
import { OrdersDataGrid } from './OrdersDataGrid'
import { TransactionSearchFormModel } from './orderSearchValidator'
import { OrderSummaryDataGrid } from './OrderSummaryDataGrid'
import { TransactionSearchForm } from './TransactionSearchForm'
import {
  generateSummaryOfOrders,
  merchantReferralToRow,
  orderReportingDetailsToOrderRow
} from './transactionTransforms'
interface MerchantOrderListProps {
  merchantId: string
}

const thrityDays = 1000 * 60 * 60 * 24 * 30

export function TransactionSummary(props: MerchantOrderListProps) {
  const { organizationId } = useParams()
  const { merchantId } = props

  const toast = useToast()
  const [dates, setDates] = useState({
    startDate: Date.now() - thrityDays,
    endDate: Date.now()
  })

  const {
    data: orderData,
    loading: loadingOrders,
    error: orderError,
    refetch: refetchOrders
  } = useOrderReportingDetailsByMerchantIdQuery({
    variables: { id: merchantId, startDate: dates.startDate, endDate: dates.endDate },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  const {
    data: referralData,
    loading: loadingReferrals,
    error: referralError,
    refetch: refetchReferrals
  } = useMerchantReferralsByMerchantIdQuery({
    variables: { id: merchantId, startDate: dates.startDate, endDate: dates.endDate },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (orderError || referralError) {
      toast.error('Error retrieving the report. Please try again')
    }
  }, [orderError, referralError, toast])

  const loading = loadingOrders && loadingReferrals

  const flattenedOrders = !!orderError
    ? []
    : orderData?.orderReportingDetailsByMerchantId?.map((o) => orderReportingDetailsToOrderRow(o!)) || []
  const referrals = !!referralError
    ? []
    : referralData?.merchantReferralsByMerchantId?.map((r) => merchantReferralToRow(r!)) || []

  const orderSummary = generateSummaryOfOrders(flattenedOrders, referrals, dates.startDate, dates.endDate)
  function handleSubmit(input: TransactionSearchFormModel) {
    let updatedStartDate = new Date(input.startDate).getTime()
    let updatedEndDate = new Date(input.endDate).getTime()

    if (dates.startDate === updatedStartDate && dates.endDate === updatedEndDate) {
      refetchOrders()
      refetchReferrals()
    } else {
      setDates({
        startDate: updatedStartDate,
        endDate: updatedEndDate
      })
    }
  }

  function exportOrdersToExcel() {
    exportToExcel(orderSummary, flattenedOrders, referrals)
  }

  return (
    <div>
      <Section title='Search' subTitle='Select a date range to view transaction records for your cafe.'>
        <div style={{ marginBottom: 1 }}>
          <TransactionSearchForm onSubmit={handleSubmit} disableSubmit={loading} />
        </div>
      </Section>
      {!loading && (
        <Section title='Report Date Range' addon={<Button label='Export to Excel' onClick={exportOrdersToExcel} />}>
          <Typography>{`${readableDateFromInstant(dates.startDate)} - ${readableDateFromInstant(
            dates.endDate
          )}`}</Typography>
        </Section>
      )}
      <Section title='Summary' variant='grid'>
        <OrderSummaryDataGrid orderSummary={orderSummary} loading={loading} />
      </Section>
      <Section title='Orders' variant='grid'>
        <OrdersDataGrid
          orders={flattenedOrders}
          loading={loadingOrders}
          organizationId={organizationId!}
          merchantId={merchantId}
        />
      </Section>
      {!!referrals.length && (
        <Section title='Barista Referrals' variant='grid'>
          <MerchantReferralsDataGrid referrals={referrals} loading={loadingReferrals} />
        </Section>
      )}
    </div>
  )
}
