import { DataGrid } from '@mui/x-data-grid'
import { Maybe } from 'graphql/types.generated'
import { MerchantInfo } from 'models/Merchant'
import { MerchantSubscriptionInfo } from 'models/Subscriptions'
import React from 'react'
import { formatPlatformPricing, getSubscriptionStatusFriendlyName } from 'util/subscriptions'

interface MerchantPlatformPricingGridProps {
  loading: boolean
  merchants: MerchantInfo[]
  merchantSubscriptions: MerchantSubscriptionInfo[]
}

export function MerchantPlatformPricingGrid(props: MerchantPlatformPricingGridProps) {
  const { loading, merchants, merchantSubscriptions } = props

  function mapMerchantSubscriptions() {
    const obj: {
      [id: string]: Maybe<MerchantSubscriptionInfo>
    } = {}
    for (let i = 0; i < merchants.length; i++) {
      const merchantId = merchants[i]!.id
      obj[merchantId] = merchantSubscriptions.find((ms) => ms.merchant?.id === merchantId)
    }
    return obj
  }
  const merchantSubscriptionMap = mapMerchantSubscriptions()

  return (
    <DataGrid
      loading={loading}
      autoHeight
      disableSelectionOnClick
      hideFooterPagination={true}
      hideFooter={true}
      style={{ marginBottom: 10 }}
      rows={merchants}
      columns={[
        {
          field: 'merchantName',
          headerName: 'Merchant Name',
          sortable: true,
          width: 300,
          valueGetter(params) {
            return params.row.name
          }
        },
        {
          field: 'subscriptionStatus',
          headerName: 'Status',
          sortable: true,
          width: 200,
          valueGetter(params) {
            const subscription = merchantSubscriptionMap[params.row.id]
            return getSubscriptionStatusFriendlyName(subscription?.subscriptionStatus)
          }
        },
        {
          field: 'platformPricing.name',
          headerName: 'Plan',
          sortable: true,
          width: 200,
          renderCell(params) {
            const subscription = merchantSubscriptionMap[params.row.id]
            return subscription?.platformPricing?.name
          }
        },
        {
          field: 'subscriptionCost',
          headerName: 'Cost',
          sortable: true,
          width: 250,
          valueGetter(params) {
            const subscription = merchantSubscriptionMap[params.row.id]
            if (!subscription?.platformPricing) {
              return null
            }
            return formatPlatformPricing(subscription?.termPricing ?? false, subscription?.platformPricing)
          }
        }
      ]}
    />
  )
}
