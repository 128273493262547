import { SettingValueType } from 'graphql/types.generated'
import React from 'react'
import { Setting } from 'views/merchants/components/MerchantSettings/useSettings'

import { OrganizationBooleanSettingContainer } from './OrganizationBooleanSettingContainer'
import { OrganizationNumericSettingContainer } from './OrganizationNumericSettingContainer'

interface OrganizationSettingContainerProps {
  organizationId: string
  setting: Setting
}

export const OrganizationSettingContainer = (props: OrganizationSettingContainerProps) => {
  const { setting, organizationId } = props
  const { name, description, value, valueType } = setting

  if (valueType === SettingValueType.Boolean) {
    return (
      <OrganizationBooleanSettingContainer
        organizationId={organizationId}
        name={name}
        description={description}
        initialValue={value as boolean}
      />
    )
  }

  if (valueType === SettingValueType.Numeric) {
    return (
      <OrganizationNumericSettingContainer
        organizationId={organizationId}
        name={name}
        description={description}
        initialValue={value as number}
      />
    )
  }

  return null
}
