import { Button, Section } from '@bloom-coffee/steamed-milk'
import { DialogContentText, Paper, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { DataGrid } from '@mui/x-data-grid'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useArchiveModifierOption } from 'hooks/useArchiveModifierOption'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'

import { HasAnyRole } from '../../../../components/auth/HasAnyRole'
import { useModifiersWithoutChildrenByMerchantQuery } from '../../../../graphql/types.generated'
import { roles } from '../../../../hooks/usePermissions/usePermissions'
import { formatValueAsPrice, fromCents } from '../../../../util/formatting'

interface ModifierOptionsListContainerProps {
  merchantId: string
  organizationId: string
  onEdit: (id: string) => void
  createModifierOption: () => void
}

export const ModifierOptionsListContainer = (props: ModifierOptionsListContainerProps) => {
  const { merchantId, organizationId, onEdit, createModifierOption } = props

  const { data, loading } = useModifiersWithoutChildrenByMerchantQuery({
    variables: { merchantId },
    fetchPolicy: 'cache-first'
  })

  const toast = useToast()
  const { archiveAll, loading: loadingArchive } = useArchiveModifierOption(merchantId)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)

  async function deleteSelected() {
    setShowDeleteConfirmationModal(false)

    await archiveAll(selectedIds, () => toast.success('Deleted Modifiers'))
    setSelectedIds([])
  }

  return (
    <Section
      title={'Modifier Options'}
      variant='grid'
      subTitle='Add Modifiers for your Menu Products. Examples include: Milk options, Beverage size, Espresso options'
      addon={
        <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId), roles.merchantAdmin(merchantId)]}>
          <div style={addonStyle}>
            <Button
              endIcon={<AddIcon />}
              label='Add Modifier Option'
              onClick={createModifierOption}
              style={buttonStyle}
            />
            <Button
              endIcon={<DeleteIcon />}
              theme='warning'
              style={buttonStyle}
              onClick={() => {
                if (!selectedIds.length) {
                  toast.error('No modifiers selected')
                  return
                }
                setShowDeleteConfirmationModal(true)
              }}
              disabled={loadingArchive}
            />
          </div>
        </HasAnyRole>
      }
    >
      <Paper>
        <DataGrid
          loading={loading}
          autoHeight
          disableSelectionOnClick
          checkboxSelection={true}
          onSelectionModelChange={(params) => {
            setSelectedIds(params.map((value) => value.toString()))
          }}
          rows={data?.modifiersWithoutChildrenByMerchant || []}
          columns={[
            { field: 'name', headerName: 'Name', sortable: true, disableColumnMenu: true, width: 250 },
            { field: 'selectedName', headerName: 'Selected Name', sortable: true, disableColumnMenu: true, width: 160 },
            { field: 'printableName', headerName: 'Printer Name', sortable: true, disableColumnMenu: true, width: 160 },
            {
              field: 'priceCents',
              headerName: 'Price',
              width: 100,
              sortable: true,
              disableColumnMenu: true,
              renderCell(params) {
                return <Typography variant='body2'> {formatValueAsPrice(fromCents(params.row.priceCents))}</Typography>
              }
            },
            {
              field: 'active',
              headerName: 'Active',
              sortable: true,
              width: 110,
              disableColumnMenu: true,
              renderCell(params) {
                return (
                  <Typography variant='body2' color={params.row.active ? 'textPrimary' : 'primary'}>
                    {params.row.active ? 'Active' : 'Inactive'}
                  </Typography>
                )
              }
            },
            {
              field: '',
              headerName: '',
              sortable: false,
              disableColumnMenu: true,
              width: 120,
              renderCell(params) {
                function handleEdit() {
                  onEdit(params.row.id.toString())
                }
                return (
                  <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId), roles.merchantAdmin(merchantId)]}>
                    <Button onClick={handleEdit} endIcon={<EditIcon />} disabled={loadingArchive} />
                  </HasAnyRole>
                )
              }
            }
          ]}
        />
      </Paper>

      <ConfirmationDialog
        title='Delete Modifer Options?'
        open={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        onConfirm={deleteSelected}
      >
        <DialogContentText>Are you sure you want to delete the selected modifier options?</DialogContentText>
      </ConfirmationDialog>
    </Section>
  )
}

const addonStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}

const buttonStyle: React.CSSProperties = {
  marginLeft: 5
}
