import { Section } from '@bloom-coffee/steamed-milk'
import { useCreatePromotion } from 'hooks/useCreatePromotion/useCreatePromotion'
import { useNavigate } from 'react-router-dom'

import { PromotionForm } from './components/PromotionForm'
import { PromotionFormModel } from './components/promotionValidator'

export const CreatePromotion = () => {
  const navigate = useNavigate()
  const { loading, create } = useCreatePromotion()

  function handleSubmit(input: PromotionFormModel) {
    create(input, () => navigate(-1))
  }

  return (
    <Section title='Create Promotion'>
      <PromotionForm onCancel={() => navigate(-1)} onSubmit={handleSubmit} disableSubmit={loading} />
    </Section>
  )
}
