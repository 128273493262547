import { Box, TextField, Typography } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import React from 'react'
import { formatValueAsPrice, fromCents } from 'util/formatting'

import { ModifierOptionFragment, TopModifierFragment } from '../../../graphql/types.generated'

interface ModifiersForSelectionContainerProps {
  modifiers: TopModifierFragment[]
  onSelect: (bm: TopModifierFragment) => void
}

export const ModifiersForSelectionContainer = (props: ModifiersForSelectionContainerProps) => {
  const { modifiers, onSelect } = props

  const filterOptions = createFilterOptions({
    stringify: (option: TopModifierFragment) => option.name + option.children?.map((c) => c.name).join()
  })

  return (
    <Autocomplete
      options={modifiers}
      getOptionLabel={(option) => {
        return option.name
      }}
      onChange={(event, value) => {
        if (!!value) {
          onSelect(value)
        }
      }}
      filterOptions={filterOptions}
      renderOption={(option, { selected }) => (
        <Box display='flex' flexDirection='column' alignItems='flex-start'>
          <Typography variant='subtitle2'>{option.name.toUpperCase()}</Typography>
          {option.children?.map((c: ModifierOptionFragment) => {
            return (
              <Box display='flex' flexDirection='row'>
                <Typography variant='subtitle2' color='textSecondary' style={{ marginRight: 5 }}>
                  {`${c.name}`}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {`(${formatValueAsPrice(fromCents(c.priceCents))}`}
                  {option.defaultSelectionIds?.includes(c.id) && ', default'}
                  {')'}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label={'Modifier'} variant='outlined' />}
    />
  )
}
