import {
  Maybe,
  useCreatePlatformPricingPaymentIntentMutation,
  useCreateStripeSubscriptionMutation
} from 'graphql/types.generated'
import { MerchantSubscriptionInfo, SubscriptionPaymentIntentInfo } from 'models/Subscriptions'

interface CreatePaymentIntentProps {
  organizationId: ID
  organizationManaged: boolean
  onComplete: (subscriptionPaymentIntent: Maybe<SubscriptionPaymentIntentInfo>) => void
  onError: (error: string) => void
  onStartSave?: () => void
}
export function useCreatePaymentIntent(props: CreatePaymentIntentProps) {
  const { onComplete, organizationManaged, onError, onStartSave } = props

  //const [startedPolling, setStartedPolling] = useState<Maybe<Date>>(null)
  const [createSubscription, { loading: loadingCreateSubscription }] = useCreateStripeSubscriptionMutation()
  const [getPaymentIntent, { loading: loadingPaymentIntent }] = useCreatePlatformPricingPaymentIntentMutation()

  // const { data, startPolling, stopPolling } = useMerchantSubscriptionsByOrganizationIdQuery({
  //   variables: {
  //     organizationId: organizationId
  //   }
  // })

  // useEffect(() => {
  //   if (startedPolling == null) {
  //     return
  //   }

  //   var pollingDuration = new Date().getTime() - startedPolling.getTime()

  //   var updatedMerchantSubscriptions =
  //     data?.merchantSubscriptionsByOrganizationId.filter(
  //       (ums) => merchantSubscriptions.find((ms) => ums.id === ms.id) != null
  //     ) ?? []
  //   if (
  //     updatedMerchantSubscriptions.every((e) => e.subscriptionStatus !== SubscriptionStatus.PendingPayment) ||
  //     pollingDuration > 5000
  //   ) {
  //     setStartedPolling(null)
  //     stopPolling()
  //   }
  //   return () => {
  //     setStartedPolling(null)
  //     stopPolling()
  //   }
  // }, [data, merchantSubscriptions, startedPolling, stopPolling])

  async function createOneTimePaymentIntent(merchantSubscriptions: MerchantSubscriptionInfo[]) {
    try {
      if (!merchantSubscriptions.length) {
        onError('Please select a Cafe')
        return
      }
      onStartSave?.()
      //setStartedPolling(new Date())
      const res = await getPaymentIntent({
        variables: {
          merchantIds: merchantSubscriptions.map((ms) => ms.merchant!!.id)
        }
      })

      onComplete(res.data?.createPlatformPricingPaymentIntent ?? null)
      //startPolling(1000)
    } catch (e: any) {
      onError(e.message)
    }
  }

  async function onCreateSub(merchantSubscriptions: MerchantSubscriptionInfo[]) {
    try {
      if (!merchantSubscriptions.length) {
        onError('Please select a Cafe')
        return
      }
      onStartSave?.()
      //setStartedPolling(new Date())
      const res = await createSubscription({
        variables: {
          merchantIds: merchantSubscriptions.map((ms) => ms.merchant!!.id),
          organizationManaged: organizationManaged
        }
      })

      onComplete(res.data?.createStripeSubscription ?? null)
      //startPolling(1000)
    } catch (e: any) {
      onError(e.message)
    }
  }

  function createPaymentIntent(merchantSubscriptions: MerchantSubscriptionInfo[]) {
    const oneTimePayment = merchantSubscriptions.every((ms) => !ms.termPricing && !!ms.platformPricing)
    oneTimePayment ? createOneTimePaymentIntent(merchantSubscriptions) : onCreateSub(merchantSubscriptions)
  }

  return { createPaymentIntent, loading: loadingPaymentIntent || loadingCreateSubscription }
}
