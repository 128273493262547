import { Button } from '@bloom-coffee/steamed-milk'
import { Grid } from '@material-ui/core'
import { CheckboxField } from 'components/form/CheckboxField'
import { DateTimeField } from 'components/form/DateTimeField'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { Modal } from 'components/layout/Modal/Modal'
import { PromotionDocument, useUpdatePromotionMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { PromotionInfo } from 'models/Promotion'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface QRCodeLinkProps {
  promotion: PromotionInfo
}

export const UpdatePromotionDates = (props: QRCodeLinkProps) => {
  const { promotion } = props
  const formProps = useForm<PromotionUpdateModel>()
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false)
  const [execute, { loading }] = useUpdatePromotionMutation()
  const toast = useToast()

  const { handleSubmit } = formProps

  async function onSubmit(model: PromotionUpdateModel) {
    const start = new Date(model.start).getTime()
    const end = model.end ? new Date(model.end).getTime() : undefined

    try {
      await execute({
        variables: {
          input: {
            promotionId: promotion.id,
            end: end,
            start: start,
            updateDatesOnAllCodes: model.updateDatesOnAllCodes
          }
        },
        refetchQueries: [
          {
            query: PromotionDocument,
            variables: {
              id: promotion.id
            }
          }
        ]
      })
      toast.success('Updated Promotion')
      setUpdateModalOpen(false)
    } catch (e: any) {
      toast.error(`Could not update promotion: ${e}`)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Button label='Update Dates' onClick={() => setUpdateModalOpen(true)} />
      <Modal open={!!updateModalOpen} onClose={() => setUpdateModalOpen(false)} title='Update Promotion Dates'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...formProps}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DateTimeField name='start' label='Start Date' defaultValue={new Date(promotion.start!!).toString()} />
              </Grid>
              <Grid item xs={12}>
                <DateTimeField
                  name='end'
                  label='End Date'
                  defaultValue={promotion.end ? new Date(promotion.end).toString() : undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxField
                  name='updateDatesOnAllCodes'
                  label='Apply to all Promo Codes'
                  defaultValue={false}
                ></CheckboxField>
              </Grid>
              <Grid item>
                <DefaultFormFooter
                  onCancel={() => setUpdateModalOpen(false)}
                  onSubmit={handleSubmit(onSubmit)}
                  saveLabel='Update Dates'
                  submitting={loading}
                />
              </Grid>
            </Grid>
          </FormProvider>
        </form>
      </Modal>
    </div>
  )
}

export interface PromotionUpdateModel {
  start: string
  end: string | null | undefined
  updateDatesOnAllCodes: boolean
}
