//Logic from Cappuccino, probably belongs in shared library

export const StringUtils = {
  URL_ENCODED_COMMA: '%2C',
  URL_ENCODED_BAR: '%7C',

  UPPERCASE_ALPHA: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  LOWERCASE_ALPHA: 'abcdefghijklmnopqrstuvwxyz',
  NUMERIC: '0123456789',

  generateRandomAlphaNumeric: (length: number) => {
    let result = ''
    const charSet = StringUtils.LOWERCASE_ALPHA + StringUtils.LOWERCASE_ALPHA + StringUtils.NUMERIC
    const charSetLength = charSet.length
    for (let i = 0; i < length; i++) {
      result += charSet.charAt(Math.floor(Math.random() * charSetLength))
    }
    return result
  }
}

export function getFilename(displayName: string, extension: string) {
  return displayName.replace(/\W/g, '') + extension
}
