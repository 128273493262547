import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { ContractType, Maybe, useFetchOrganizationContractSignaturesQuery } from 'graphql/types.generated'
import { useCreatePaymentIntent } from 'hooks/useCreatePaymentIntent/useCreatePaymentIntent'
import { MerchantSubscriptionInfo, SubscriptionPaymentIntentInfo } from 'models/Subscriptions'
import React, { useState } from 'react'
import { TermsAgreement } from 'views/onboarding/TermsAgreement'

interface SetUpSubscriptionProps {
  merchantSubscriptions: MerchantSubscriptionInfo[]
  organizationId: ID
  organizationManaged: boolean
  onComplete: (subscriptionPaymentIntent: Maybe<SubscriptionPaymentIntentInfo>) => void
  onError: (error: string) => void
  onStartSave?: () => void
  title?: string | null
  style?: React.CSSProperties
}

export function SetUpSubscription(props: SetUpSubscriptionProps) {
  const {
    merchantSubscriptions,
    onComplete,
    organizationId,
    organizationManaged,
    onError,
    title,
    onStartSave,
    style
  } = props

  const [hidingTerms, setHidingTerms] = useState(true)

  const { createPaymentIntent, loading } = useCreatePaymentIntent({
    organizationId,
    organizationManaged,
    onComplete,
    onError,
    onStartSave
  })

  const { data: signedContractData, loading: loadingContractSignatures } = useFetchOrganizationContractSignaturesQuery({
    variables: {
      id: organizationId
    }
  })

  function setupPayment() {
    if (!!signedContractData?.organization?.contractSignatures.length) {
      createPaymentIntent(merchantSubscriptions)
    } else {
      setHidingTerms(false)
    }
  }

  return (
    <>
      <Button
        style={style}
        label={title ?? 'Set up Payment'}
        onClick={setupPayment}
        endIcon={(loading || loadingContractSignatures) && <CircularProgress color='inherit' size={20} />}
      />

      <TermsAgreement
        organizationId={organizationId!!}
        contractType={ContractType.StandardContract}
        hidden={hidingTerms}
        onComplete={() => createPaymentIntent(merchantSubscriptions)}
        onCancel={() => setHidingTerms(true)}
        onAdminSkip={() => createPaymentIntent(merchantSubscriptions)}
      />
    </>
  )
}
