import { DataGrid, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { readableDateTimeFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'

import { OrderDetails } from './transactionTransforms'

interface OrdersDataGridProps {
  orders: OrderDetails[]
  loading: boolean
  organizationId: string
  merchantId: string
}

export function OrdersDataGrid(props: OrdersDataGridProps) {
  const { orders, loading, organizationId, merchantId } = props

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'date',
      sort: 'desc'
    }
  ])

  return (
    <div style={{ height: 500 }}>
      <DataGrid
        loading={loading}
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={(model) => {
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model)
          }
        }}
        rows={(!loading && orders) || []}
        columns={[
          {
            field: 'id',
            headerName: 'ID',
            sortable: false,
            width: 80,
            renderCell: (params: any) => {
              return (
                <Link to={`/organizations/${organizationId}/merchants/${merchantId}/reports/order/${params.value}`}>
                  {params.value}
                </Link>
              )
            }
          },
          {
            field: 'date',
            headerName: 'Order Date',
            sortable: true,
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => readableDateTimeFromInstant(params.value as number)
          },
          { field: 'name', headerName: 'Customer Name', sortable: false, width: 170 },
          {
            field: 'rdyTokens',
            headerName: 'RDY Tokens',
            description: 'This is the number of RDY paid tokens',
            sortable: false,
            width: 150
          },
          {
            field: 'organizationTokens',
            headerName: 'Cafe Tokens',
            description: 'This is the number of Cafe paid tokens',
            sortable: false,
            width: 150
          },
          {
            field: 'tokenValue',
            headerName: 'Token Value',
            sortable: false,
            width: 130,
            description: 'This is the value of tokens used for this orders',
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'tokenTipValue',
            headerName: 'Token Tip',
            sortable: false,
            width: 130,
            description: 'This is the tip added to the token order',
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'tokenFeeValue',
            headerName: 'Token Fee',
            sortable: false,
            width: 130,
            description: 'This is fee applied to the sum of the Token Value and Token Tip',
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'subTotal',
            headerName: 'Sub Total',
            description: 'This is the total price of all items and modifiers for the order',
            sortable: false,
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'taxes',
            headerName: 'Taxes',
            description: 'These taxes are paid by the customer, then collected and remitted by RDY',
            sortable: false,
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'tip',
            headerName: 'Tip',
            description: 'Tip amount chosen and paid by the customer',
            sortable: false,
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'customerFee',
            headerName: 'Customer Fee',
            description: 'This is 6% of the sum of the Sub Total, Taxes, and Tips or $0.50, whichever is greater',
            sortable: false,
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'merchantFee',
            headerName: 'Merchant Fee',
            description: 'This is fee applied to the sum of the Sub Total, Taxes, and Tips',
            sortable: false,
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'merchantDiscountFeeUsCents',
            headerName: 'RDY Discount Fee',
            description: 'This is the merchant fee associated with the RDY discounts',
            sortable: false,
            width: 180,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'rdyPromotionDiscountValue',
            headerName: 'RDY Discount',
            description: 'This is the discounted value that RDY has applied to the order',
            sortable: false,
            width: 140,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: 'netMerchantPayout',
            headerName: 'Order Payout',
            description:
              'This is the total paid to the merchant for this order, which is Sub Total plus Tip less the Merchant Fee',
            sortable: false,
            width: 140,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          }
        ]}
      />
    </div>
  )
}
