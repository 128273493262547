import { Chip, Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { User } from 'graphql/types.generated'
import { OrganizationUserMenu } from 'views/organizations/components/OrganizationUserMenu'

interface UserListProps {
  organizationId: ID
  users: User[]
}

export function UserList(props: UserListProps) {
  const { organizationId, users } = props

  return (
    <DataGrid
      autoHeight
      hideFooterPagination
      disableSelectionOnClick
      rows={users || []}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          sortable: false,
          width: 300,
          renderCell(params) {
            const profile = params.row.profile
            return <Typography>{`${profile.firstName ?? ''} ${profile.lastName ?? ''}`}</Typography>
          }
        },
        {
          field: 'email',
          headerName: 'Email',
          sortable: false,
          width: 300,
          renderCell(params) {
            return <Typography>{params.row.profile.contactEmail}</Typography>
          }
        },
        {
          field: 'role',
          headerName: 'Roles',
          sortable: false,
          width: 300,
          renderCell(params) {
            const orgRoles = params.row.roles
              ?.filter((role: string) => role.startsWith('organization:') && role.split(':')[2] === organizationId)
              .map((orgRole: any) => orgRole.split(':')[1])

            return orgRoles.map((orgRole: any, index: number) => <Chip key={index} label={orgRole}></Chip>)
          }
        },
        {
          field: '',
          headerName: '',
          sortable: false,
          width: 150,
          renderCell(params) {
            return <OrganizationUserMenu organizationId={organizationId} user={params.row as User} />
          }
        }
      ]}
    />
  )
}
