import { Color, Section, Text } from '@bloom-coffee/steamed-milk'
import { ReactComponent as FreshLogo } from 'assets/svg/freshLogo.svg'
import { ImportFreshToGoCafes } from 'components/FreshToGoIntegration/ImportFreshToGoCafes'
import { DiningOption, GeoFenceType, MerchantLocationInput, useCreateMerchantMutation } from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SquareButton } from 'views/square/SquareButton'

import { MerchantForm } from '../components/MerchantForm/MerchantForm'
import { MerchantFormModel } from '../components/MerchantForm/merchantValidator'

export function CreateMerchant() {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const errorHandler = useErrorHandler()
  const [hasFreshToGo, setHasFreshToGo] = useState(false)

  const [execute, { loading }] = useCreateMerchantMutation()

  async function saveMerchant(data: MerchantFormModel) {
    const {
      diningOptions: diningOptionStrings,
      location: locationForm,
      operationHours,
      taxRate: nullableTaxRate,
      ...rest
    } = data
    const diningOptions = diningOptionStrings as DiningOption[]

    const { geoFenceType: geoFenceTypeString, ...locationRest } = locationForm
    const location = locationRest as MerchantLocationInput
    location.geoFenceType = geoFenceTypeString as GeoFenceType
    try {
      const createMerchantResponse = await execute({
        variables: {
          input: {
            id: null,
            organizationId: organizationId,
            ...rest,
            diningOptions,
            taxRate: nullableTaxRate!,
            location,
            hours:
              operationHours?.map((h) => {
                return {
                  weekDay: h.weekDays[0],
                  timeIntervals: h.timeIntervals
                }
              }) ?? []
          }
        }
      })
      navigate(`/organizations/${organizationId}/merchants/${createMerchantResponse.data?.createMerchant.id}`)
    } catch (e) {
      errorHandler(e)
    }
  }

  return (
    <Section
      title={
        hasFreshToGo ? (
          <div>
            <span>Import Cafes From </span>
            <FreshLogo style={{ width: 100, height: 'auto' }} />
          </div>
        ) : (
          'Set up a New Cafe'
        )
      }
    >
      {hasFreshToGo && (
        <ImportFreshToGoCafes
          onCancel={() => {
            setHasFreshToGo(false)
          }}
          onSuccess={() => {
            navigate(-1)
          }}
          organizationId={organizationId!}
        />
      )}
      {!hasFreshToGo && (
        <>
          <div>
            <Text variant='header2'>Import a Cafe</Text>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 24
            }}
          >
            <div style={{ marginLeft: 12, marginRight: 12 }}>
              <SquareButton
                title='Import from Square'
                organizationId={organizationId!}
                merchantId={null}
                variant='CreateMerchant'
              />
            </div>

            <div
              style={{
                marginLeft: 12,
                marginRight: 12,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <button style={{ margin: 10, borderRadius: 5, cursor: 'pointer' }} onClick={() => setHasFreshToGo(true)}>
                <FreshLogo style={{ width: 150, height: 'auto' }} />
              </button>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ flex: 1, height: 1, backgroundColor: Color.GREY_300 }} />
            <div style={{ paddingLeft: 12, paddingRight: 12 }}>
              <Text variant='subheader1'>OR</Text>
            </div>
            <div style={{ flex: 1, height: 1, backgroundColor: Color.GREY_300 }} />
          </div>

          <div style={{ marginBottom: 20 }}>
            <Text variant='header2'>Create Your Own</Text>
          </div>
          <div>
            <MerchantForm onCancel={() => navigate(-1)} onSubmit={saveMerchant} disableSubmit={loading} />
          </div>
        </>
      )}
    </Section>
  )
}
