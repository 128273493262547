import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Typography } from '@material-ui/core'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import {
  CategoryOutputDestinationInput,
  KdsIntegrationDetailsDocument,
  MerchantOutputDestinationsDocument,
  OutputDestination,
  useKdsIntegrationDetailsQuery,
  useMerchantOutputDestinationsQuery,
  useSetupKdsIntegrationDevicesMutation,
  useUpdateOutputDestinationsMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { DeviceOutputStationContainer } from './DeviceOutputStationContainer'

export const KdsIntegrationDetailsContainer = () => {
  const { integrationId, merchantId } = useParams()
  const toast = useToast()

  const { data: kdsIntegrationData, loading: loadingIntegrations } = useKdsIntegrationDetailsQuery({
    variables: { id: integrationId! }
  })
  const { data: outputDestinationData, loading: loadingOutputDestinations } = useMerchantOutputDestinationsQuery({
    variables: {
      merchantId: merchantId!
    }
  })
  const [executeSetupDevices, { loading: setupDevicesLoading }] = useSetupKdsIntegrationDevicesMutation()
  const [executeUpdateSettings, { loading: updateSettingsLoading }] = useUpdateOutputDestinationsMutation()

  const [integrationOutputStations, setIntegrationOutputStations] = useState<OutputDestination[]>([])
  const [pendingUpdates, setPendingUpdates] = useState<CategoryOutputDestinationInput[]>([])
  const FooterComponent = DefaultFormFooter

  useEffect(() => {
    if (outputDestinationData?.merchant?.outputDestinations && kdsIntegrationData?.kdsIntegration?.devices) {
      const outputDestinations = outputDestinationData.merchant.outputDestinations as OutputDestination[]
      const kdsIntegrationDevices = kdsIntegrationData.kdsIntegration.devices || []

      setIntegrationOutputStations(
        outputDestinations.filter((od) =>
          kdsIntegrationDevices.map((device) => device.id).includes(od.kdsIntegrationDevice!!.id)
        )
      )
    }
  }, [outputDestinationData, kdsIntegrationData])

  async function handleSync() {
    try {
      await executeSetupDevices({
        variables: {
          integrationId: integrationId!
        },
        refetchQueries: [
          { query: KdsIntegrationDetailsDocument, variables: { id: integrationId } },
          { query: MerchantOutputDestinationsDocument, variables: { merchantId } }
        ]
      })
      toast.success('Devices Synced')
    } catch (e) {
      toast.error(`Could not sync devices: ${e.message}`)
    }
  }

  async function handleSave() {
    try {
      await executeUpdateSettings({
        variables: {
          input: {
            merchantId: merchantId!,
            categoryOutputDestinations: pendingUpdates
          }
        }
      })
      setPendingUpdates([])
      toast.success('Updated Device Settings')
    } catch (e) {
      toast.error(e.message)
    }
  }

  function addUpdatedSetting(categoryOutputDestinationId: ID, active: boolean) {
    const _pendingUpdates = [...pendingUpdates]
    const existingUpdate = _pendingUpdates.find((u) => u.categoryOutputDestinationId === categoryOutputDestinationId)
    if (existingUpdate) {
      existingUpdate.active = active
    } else {
      _pendingUpdates.push({ categoryOutputDestinationId, active })
    }

    setPendingUpdates(_pendingUpdates)
  }

  return (
    <Section
      title='KDS Integration Settings'
      addon={<Button label='Sync Devices' onClick={handleSync} disabled={setupDevicesLoading} />}
    >
      <Typography variant='subtitle2'>
        Configure product categories to be sent to specific devices. If you don't see a device below, click "Sync
        Devices"
      </Typography>

      <div style={{ paddingTop: 3 }}>
        {!integrationOutputStations.length && !loadingIntegrations && !loadingOutputDestinations && (
          <Typography align='center' variant='h6'>
            No configurations found. Orders will be displayed on all devices
          </Typography>
        )}
        {!!integrationOutputStations.length && (
          <>
            {integrationOutputStations.map((io) => (
              <DeviceOutputStationContainer key={io.id} outputDestination={io} addUpdatedSetting={addUpdatedSetting} />
            ))}
            <FooterComponent
              onSubmit={handleSave}
              disabled={!pendingUpdates.length}
              submitting={updateSettingsLoading}
            />
          </>
        )}
      </div>
    </Section>
  )
}
