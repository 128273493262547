import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { DataGrid, GridSortModel } from '@mui/x-data-grid'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { useOrganizationListQuery } from 'graphql/types.generated'
import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function OrganizationList() {
  const navigate = useNavigate()
  const { hasAnyRole } = usePermissions()

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc'
    }
  ])

  const [orgTextFilter, setOrgTextFilter] = useState<string | null>(null)

  const { data, loading } = useOrganizationListQuery()

  const organizations = (data?.organizations || []).filter(
    (o) => !orgTextFilter || orgTextFilter === '' || o.name.toLowerCase().indexOf(orgTextFilter.toLowerCase()) !== -1
  )

  if (data?.organizations.length === 1 && !hasAnyRole(roles.admin)) {
    navigate(`/organizations/${data.organizations[0].id}`, { replace: true })
  }

  if (loading) return <CircularProgress />

  return (
    <Section
      title='Organizations'
      addon={
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <HasAnyRole roleMatchers={[roles.admin]}>
            <Button label='New Organization' onClick={() => navigate('/organizations/new')} />
          </HasAnyRole>
          <TextField
            style={{ marginLeft: 10 }}
            size='small'
            variant='outlined'
            onChange={(e) => setOrgTextFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
      }
      variant='grid'
    >
      <DataGrid
        loading={loading}
        autoHeight
        disableSelectionOnClick
        rows={organizations}
        sortModel={sortModel}
        onSortModelChange={(model) => {
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model)
          }
        }}
        onRowClick={(params) => navigate(`/organizations/${params.row.id}/merchants`)}
        columns={[{ field: 'name', headerName: 'Name', sortable: true, width: 700 }]}
      />
    </Section>
  )
}
