import axios from 'axios'
import { config } from 'config'

const FreshUserBaseUrl = config.freshUserBaseUrl

interface FreshUserAuthResponse {
  token: string
}

interface FreshBrandSearch {
  results: FreshBrand[]
}

export interface FreshBrand {
  id: string
  name: string
}

export async function freshToGoLogin(username: string, password: string): Promise<string> {
  const httpResponse = await axios.request({
    method: 'POST',
    url: `${FreshUserBaseUrl}/auth`,
    data: {
      audience: 'fresh-tools-web',
      password: password,
      username: username
    }
  })
  return getTokenFromResponse(httpResponse)
}

export async function getFreshBrands(token: string): Promise<FreshBrand[]> {
  const httpResponse = await axios.request({
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: 'GET',
    url: `${FreshUserBaseUrl}/brands?pageNumber=1`
  })
  return getBrandsFromResponse(httpResponse)
}

function getTokenFromResponse(httpResponse: any): string {
  if (httpResponse.status !== 200) {
    if (!!httpResponse.statusText) {
      throw new Error(httpResponse.statusText)
    } else {
      throw new Error('Unable to login. Check your credentials and try again')
    }
  } else {
    const response: FreshUserAuthResponse = httpResponse.data
    return response.token
  }
}

function getBrandsFromResponse(httpResponse: any): FreshBrand[] {
  if (httpResponse.status !== 200) {
    if (!!httpResponse.statusText) {
      throw new Error(httpResponse.statusText)
    } else {
      throw new Error('Unable to login. Check your credentials and try again')
    }
  } else {
    const response: FreshBrandSearch = httpResponse.data
    return response.results
  }
}
