import { PromotionStatus, PromotionTopInfoFragment, usePromotionsQuery } from 'graphql/types.generated'
import { useEffect, useState } from 'react'

type StatusToPromotions = Record<PromotionStatus, PromotionTopInfoFragment[]>

const intitialPromotionsState: StatusToPromotions = {
  [PromotionStatus.Approved]: [],
  [PromotionStatus.Pending]: [],
  [PromotionStatus.Rejected]: []
}

export function usePromotions() {
  const [promotions, setPromotions] = useState<StatusToPromotions>(intitialPromotionsState)
  const { data, loading } = usePromotionsQuery({ fetchPolicy: 'cache-first' })

  useEffect(() => {
    if (data?.promotions) {
      const _promotions = { ...intitialPromotionsState }
      for (let promotion of data.promotions) {
        const curr = _promotions[promotion.status]
        _promotions[promotion.status] = [...curr, promotion]
      }
      setPromotions(_promotions)
    }
  }, [data])

  return { loading, promotions }
}
