import { Color, Text } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { Maybe, useHasValidSquareOAuthTokenQuery } from 'graphql/types.generated'
import { logger } from 'logger'
import React from 'react'

import { SquareLocationsContainer } from './SquareLocationsContainer'
import { getSquareAuthorizationUrl, SquareSyncVariant, storeSquareRedirectDetails } from './SquareUtils'

interface SquareSyncContainerProps {
  organizationId: string
  merchantId: Maybe<string>
  variant: SquareSyncVariant
}

export const SquareSyncContainer = (props: SquareSyncContainerProps) => {
  const { organizationId, merchantId, variant } = props

  const tag = 'SquareSyncContainer'

  const {
    data: oAuthTokenData,
    loading: loadingOAuthTokenData,
    error: oAuthTokenError
  } = useHasValidSquareOAuthTokenQuery({
    variables: { organizationId },
    fetchPolicy: 'cache-first'
  })

  async function handleSquareLoginClick() {
    logger.debug(tag, 'creating csrf token')
    storeSquareRedirectDetails(organizationId, variant, merchantId)
    window.location.href = getSquareAuthorizationUrl()
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 36,
        backgroundColor: Color.WHITE
      }}
    >
      {loadingOAuthTokenData && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>
            <Text variant='header3'>Checking Square Credentials</Text>
          </div>
          <div>
            <CircularProgress />
          </div>
        </div>
      )}

      {(oAuthTokenData?.hasValidSquareOAuthToken === false || !!oAuthTokenError?.message) && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: 12 }}>
            <Text variant='header3'>Please log in to Square</Text>
          </div>
          <div style={{ paddingBottom: 24 }}>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: Color.RDY_BLACK,
                height: 44,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 4,
                border: 'none'
              }}
              onClick={() => handleSquareLoginClick()}
            >
              <Text variant='subheader2' color={Color.WHITE}>
                Log In
              </Text>
            </button>
          </div>
        </div>
      )}

      {oAuthTokenData?.hasValidSquareOAuthToken === true && (
        <div>
          <SquareLocationsContainer organizationId={organizationId} merchantId={merchantId} variant={variant} />
        </div>
      )}
    </div>
  )
}
