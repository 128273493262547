import { Section } from '@bloom-coffee/steamed-milk'
import { useSavePlaformPricing } from 'hooks/useSavePlatformPricing/useSavePlaformPricing'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PlatformPricingForm } from '../components/PlatformPricingForm'
import { PlatformPricingFormModel } from '../components/platformPricingValidator'

export const SavePlatformPricing = () => {
  const navigate = useNavigate()

  const { loading, create } = useSavePlaformPricing()

  function handleSubmit(input: PlatformPricingFormModel) {
    create(input, () => navigate(-1))
  }

  return (
    <Section title={`Create new Subscription Plan`}>
      <PlatformPricingForm onCancel={() => navigate(-1)} onSubmit={handleSubmit} disableSubmit={loading} />
    </Section>
  )
}
