import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useValidatePaymentAccountQuery } from 'graphql/types.generated'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PaymentAccount } from './PaymentAccount'

interface MerchantPaymentAccountProps {
  organizationId: ID
  merchantId: ID
}

export function MerchantPaymentAccount(props: MerchantPaymentAccountProps) {
  const { organizationId, merchantId } = props
  const navigate = useNavigate()
  const classes = useStyles()

  const { data: merchantData, loading: loadingMerchant } = useValidatePaymentAccountQuery({
    variables: {
      organizationId: organizationId,
      merchantId: merchantId
    }
  })

  const { data: organizationData, loading: loadingOrganization } = useValidatePaymentAccountQuery({
    variables: {
      organizationId: organizationId,
      merchantId: null
    }
  })

  async function setupMerchantPaymentAccount() {
    navigate(`/organizations/${organizationId}/merchants/${merchantId}/billing/setupPaymentAccount`)
  }
  async function setupOrganizationPaymentAccount() {
    navigate(`/organizations/${organizationId}/billing/setupPaymentAccount`)
  }

  if (loadingMerchant || loadingOrganization) return <CircularProgress />

  if (!!merchantData?.validatePaymentAccount?.hasAccount) {
    return <PaymentAccount organizationId={organizationId} merchantId={merchantId} />
  }

  return (
    <Section title='Payout Account' subTitle='Add an account to accept payments for your sales on RDY'>
      {!merchantData?.validatePaymentAccount?.hasAccount && (
        <>
          {!!organizationData?.validatePaymentAccount?.hasAccount && (
            <>
              {organizationData.validatePaymentAccount.chargesEnabled && (
                <div>
                  <Alert severity={'success'} className={classes.alert}>
                    <>
                      <Typography>
                        The payout account has been set up for the whole organization and is shared across all cafes
                      </Typography>
                    </>
                  </Alert>
                  <Typography variant='subtitle2'>
                    If you'd like this cafe's payouts to go to a separate account, you can set up a Cafe-Specific payout
                    account
                  </Typography>
                  <Button
                    label='Set up cafe-specific payout account'
                    onClick={setupMerchantPaymentAccount}
                    style={buttonStyle}
                  ></Button>
                </div>
              )}
              {!organizationData.validatePaymentAccount.chargesEnabled && (
                <div>
                  <Alert
                    severity={'warning'}
                    className={classes.alert}
                    action={
                      <Button
                        label='Finish setting up payout account'
                        onClick={setupOrganizationPaymentAccount}
                        style={buttonStyle}
                      />
                    }
                  >
                    <>
                      <Typography>
                        The payout account for all cafes is incomplete. Would you like to finish setting it up or would
                        you like to set up a cafes specific payout account?
                      </Typography>
                    </>
                  </Alert>
                  <Button
                    label='Set up cafe-specific payout account'
                    onClick={setupMerchantPaymentAccount}
                    style={buttonStyle}
                  />
                </div>
              )}
            </>
          )}
          {!organizationData?.validatePaymentAccount?.hasAccount && (
            <div>
              <Typography>
                Setup one payout account for all of your cafes, or setup a separate account for each cafe
              </Typography>
              <div style={buttonContainerStyle}>
                <Button
                  label='Set up one payout account for all cafes'
                  onClick={setupOrganizationPaymentAccount}
                  style={buttonStyle}
                />
                <Button
                  label='Set up cafe-specific payout account'
                  onClick={setupMerchantPaymentAccount}
                  style={buttonStyle}
                />
              </div>
            </div>
          )}
        </>
      )}
    </Section>
  )
}

const buttonContainerStyle: React.CSSProperties = {
  marginTop: 10,
  display: 'flex',
  flexDirection: 'row'
}
const buttonStyle: React.CSSProperties = {
  marginRight: 3
}

const useStyles = makeStyles((theme) => ({
  doneIcon: {
    color: 'green',
    marginRight: 10
  },
  bottomSpacer: {
    marginBottom: 15,
    alignItems: 'center'
  },
  alert: {
    bottomMargin: 20
  }
}))
