import { Box, TextField, Typography } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import React from 'react'

import { ModifierOptionFragment } from '../../../graphql/types.generated'
import { formatValueAsPrice, fromCents } from '../../../util/formatting'

interface ModifierOptionsContainerProps {
  modifierOptions: ModifierOptionFragment[]
  onSelect: (bm: ModifierOptionFragment) => void
  disableEditing?: boolean
}

export const ModifierOptionsContainer = (props: ModifierOptionsContainerProps) => {
  const { modifierOptions, onSelect, disableEditing } = props

  const filterOptions = createFilterOptions({
    stringify: (option: ModifierOptionFragment) => option.name + option.selectedName
  })

  return (
    <Autocomplete
      disabled={disableEditing}
      options={modifierOptions}
      getOptionLabel={(option) => {
        return option.name
      }}
      onChange={(event, value) => {
        if (!!value) {
          onSelect(value)
        }
      }}
      filterOptions={filterOptions}
      renderOption={(option, { selected }) => (
        <Box display='flex' flexDirection='column' alignItems='flex-start'>
          <Box display='flex' flexDirection='row' alignItems='flex-start'>
            <Typography variant='subtitle2'>{option.name.toUpperCase()}</Typography>
            {!!option.selectedName && (
              <Typography variant='body2' style={{ paddingLeft: 5 }}>
                {`(${option.selectedName!!.toUpperCase()})`}
              </Typography>
            )}
          </Box>

          <Typography variant='body2' color='textSecondary'>
            {formatValueAsPrice(fromCents(option.priceCents))}
          </Typography>
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label={'Option'} variant='outlined' />}
    />
  )
}
