import { Button } from '@bloom-coffee/steamed-milk'
import { Checkbox, DialogContentText, makeStyles, Paper, Switch } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { TextInPlaceEditor } from 'components/TextInPlaceEditor'
import {
  ProductListDocument,
  useArchiveProductMutation,
  useDuplicateProductMutation,
  useUpdateProductActiveMutation,
  useUpdateProductNameMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { ProductInfo } from 'models/Product'
import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { getDefaultRawItemPriceTag } from 'util/ProductUtils'

interface ProductCardProps {
  merchantId: string
  organizationId: string
  product: ProductInfo
  index: number
  checked: boolean
  checkChanged: (checked: boolean) => void
}

export function ProductCard(props: ProductCardProps) {
  const { merchantId, organizationId, product, index, checked, checkChanged } = props
  const styles = useStyles()
  const toast = useToast()
  const navigate = useNavigate()

  const refetchQueries = [{ query: ProductListDocument, variables: { merchantId } }]
  const [execute, { loading }] = useUpdateProductNameMutation()
  const [executeUpdateProductActive] = useUpdateProductActiveMutation()
  const [executeDelete] = useArchiveProductMutation()
  const [executeDuplicate] = useDuplicateProductMutation()

  const [deleteProductId, setShowDeleteProductIdPrompt] = useState<string | undefined>()

  async function updateProductName(newName: string): Promise<boolean> {
    try {
      await execute({
        variables: {
          productId: product.id,
          name: newName
        },
        refetchQueries
      })
      toast.success(`Updated Name to ${newName}`)
      return true
    } catch (e: any) {
      toast.error(e.toString())
    }
    return false
  }
  async function updateProductActive(productId: string, active: boolean) {
    await executeUpdateProductActive({
      variables: {
        productId: productId,
        active: active
      },
      refetchQueries
    })
  }
  async function handleDelete() {
    try {
      await executeDelete({
        variables: { id: deleteProductId!! },
        refetchQueries
      })
      toast.success(`Successfully deleted product`)
      setShowDeleteProductIdPrompt(undefined)
    } catch (e) {
      toast.error(e.toString())
    }
  }
  async function handleDuplicate(productId: string) {
    try {
      await executeDuplicate({
        variables: { productId: productId },
        refetchQueries
      })
      toast.success(`Successfully copied product`)
    } catch (e) {
      toast.error(e.toString())
    }
  }

  function handleEdit() {
    navigate(`/organizations/${organizationId}/merchants/${merchantId}/menu/products/${product.id}/edit`)
  }
  return (
    <>
      <Draggable key={product.id} draggableId={product.id} index={index}>
        {(provided, snapshot) => (
          <Paper
            variant={snapshot.isDragging ? 'elevation' : 'outlined'}
            className={clsx(styles.listItem, snapshot.isDragging && styles.listItemDragging)}
            elevation={snapshot.isDragging ? 4 : 0}
            {...provided.draggableProps}
            innerRef={provided.innerRef}
          >
            <span {...provided.dragHandleProps} className={clsx(styles.dragHandle)}>
              <DragIndicatorIcon />
            </span>
            <div className={styles.listItemName}>
              <Checkbox onChange={(e, checked) => checkChanged(checked)} checked={checked} />
              <TextInPlaceEditor
                style={nameStyle}
                value={product.name}
                label='Product Name'
                onSubmit={updateProductName}
                onCancel={() => {}}
                loading={loading}
              />
            </div>
            <div style={productColumnStyle}>
              {!!product.images?.length && (
                <div>
                  <span>Image</span>
                  <CheckIcon style={{ height: 14 }} />
                </div>
              )}
            </div>
            <div style={productColumnStyle}>
              {formatUsCentsAsUsDollar(getDefaultRawItemPriceTag(product).priceUsCents)}
            </div>

            <div style={productColumnStyle}>
              {product.active ? 'Active' : 'Inactive'}
              <Switch checked={!!product.active} onChange={(e) => updateProductActive(product.id, !product.active)} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', ...productColumnStyle }}>
              <Button style={buttonStyle} onClick={handleEdit} endIcon={<EditIcon />} />
              <Button
                style={buttonStyle}
                onClick={() => setShowDeleteProductIdPrompt(product.id)}
                endIcon={<DeleteIcon />}
              />
              {product.isSynced && (
                <Button style={buttonStyle} onClick={() => handleDuplicate(product.id)} endIcon={<FilterNoneIcon />} />
              )}
            </div>
          </Paper>
        )}
      </Draggable>
      <ConfirmationDialog
        title='Are you sure?'
        open={!!deleteProductId}
        onClose={() => setShowDeleteProductIdPrompt(undefined)}
        onConfirm={handleDelete}
      >
        <DialogContentText>Are you sure you want to delete this Product?</DialogContentText>
      </ConfirmationDialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    marginTop: 5
  },
  listItemName: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  listItemDragging: {
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  dragHandle: {},
  productColumn: {
    marginLeft: 5,
    marginRight: 5
  }
}))

const productColumnStyle: React.CSSProperties = {
  marginLeft: 10,
  marginRight: 10
}

const buttonStyle: React.CSSProperties = {
  marginLeft: 5
}

const nameStyle: React.CSSProperties = {
  marginLeft: 10
}
