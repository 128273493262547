import { Button } from '@bloom-coffee/steamed-milk'
import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { Modal } from 'components/layout/Modal/Modal'
import { ProductListDocument, useCreateTemplateMenuMutation, useProductListLazyQuery } from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { ImportChowlyMenu } from 'views/chowly/ImportChowlyMenu'
import { SquareButton } from 'views/square/SquareButton'

import { CopyAllProductsFromMerchantButton } from '../CopyProductsAllFromMerchantButton/CopyAllProductsFromMerchantButton'
import { SyncFreshMenuButton } from '../SyncFreshMenuButton'
interface ImportMenuLinkProps {
  organizationId: string
  merchantId: string
}

export const ImportMenuLink = (props: ImportMenuLinkProps) => {
  const { organizationId, merchantId } = props
  const [showModal, setShowModal] = useState(false)
  const toast = useToast()

  const [fetchProducts] = useProductListLazyQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'network-only'
  })

  const [execute, { loading }] = useCreateTemplateMenuMutation({
    variables: { merchantId },
    refetchQueries: [{ query: ProductListDocument, variables: { merchantId } }],
    awaitRefetchQueries: true
  })

  async function handleClick() {
    try {
      await execute()
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <div>
      <Button label='Import Menu' onClick={() => setShowModal(true)} />

      <Modal open={showModal} onClose={() => setShowModal(false)} title='Import Menu'>
        <Alert severity={'warning'} style={{ marginBottom: 10 }}>
          <Typography>
            Choose the integration option below to import your menu. Please note that importing your menu from an
            external source will remove the existing menu and replace it with the imported one.
          </Typography>
        </Alert>
        <div style={optionStyle}>
          <HasAnyRole roleMatchers={[roles.admin]}>
            <CopyAllProductsFromMerchantButton style={buttonStyle} merchantId={merchantId} />
          </HasAnyRole>
          <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId), roles.merchantAdmin(merchantId)]}>
            <Button label='Standard Menu' style={buttonStyle} disabled={loading} onClick={handleClick} />
            <SquareButton
              style={buttonStyle}
              title='Import from Square'
              organizationId={organizationId}
              merchantId={merchantId}
              variant='SyncProducts'
            />
            <SyncFreshMenuButton merchantId={merchantId} style={buttonStyle} onSuccess={fetchProducts} />
            <ImportChowlyMenu merchantId={merchantId} style={buttonStyle} />
          </HasAnyRole>
        </div>
      </Modal>
    </div>
  )
}

const buttonStyle: React.CSSProperties = {
  marginLeft: 5,
  marginBottom: 5
}

const optionStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}
