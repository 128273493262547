import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { DataGrid } from '@mui/x-data-grid'
import {
  DefaultSubscriptionProductsDocument,
  DefaultSuscriptionType,
  useDefaultSubscriptionProductsQuery,
  useSetDefaultSubscriptionProductMutation,
  useSubscriptionFeesQuery
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatUsCentsAsUsDollar } from 'util/formatting'

export const SubscriptionFees = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const { data: defaultData } = useDefaultSubscriptionProductsQuery()
  const { data, loading, refetch } = useSubscriptionFeesQuery()
  const [execute] = useSetDefaultSubscriptionProductMutation()

  async function makeDefault(subscriptionProductId: string, defaultSuscriptionType: DefaultSuscriptionType) {
    try {
      await execute({
        variables: { subscriptionProductId, defaultSuscriptionType },
        refetchQueries: [{ query: DefaultSubscriptionProductsDocument }]
      })
      await refetch()
      toast.success('Default Updated')
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  function isDefault(subscriptionProductId: string, defaultSuscriptionType: DefaultSuscriptionType) {
    return (
      !!defaultData &&
      !!defaultData.defaultSubscriptionProducts.find(
        (d) =>
          d.defaultSubscriptionType === defaultSuscriptionType && d.subscriptionProduct?.id === subscriptionProductId
      )
    )
  }

  return (
    <Section
      title='Subscription Fees'
      variant='grid'
      addon={
        <Button
          endIcon={<AddIcon />}
          onClick={() => navigate('/admin/merchantFees/subscriptionFees/new')}
          label='Create Fee'
        />
      }
    >
      {loading && <CircularProgress />}
      {data && (
        <DataGrid
          loading={loading}
          autoHeight
          disableSelectionOnClick
          rows={data.subscriptionFees || []}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              sortable: true,
              width: 500,
              renderCell(params) {
                return <Typography>{params.row.name}</Typography>
              }
            },
            {
              field: 'createdOn',
              headerName: 'Created Date',
              sortable: true,
              width: 200,
              renderCell(params) {
                return <Typography>{new Date(params.row.createdOn).toDateString()}</Typography>
              }
            },
            {
              field: 'costUsCents',
              headerName: 'Cost',
              sortable: true,
              width: 200,
              renderCell(params) {
                return <Typography>{formatUsCentsAsUsDollar(params.row.costUsCents)}</Typography>
              }
            },
            {
              field: 'standardDefault',
              headerName: 'Standard Default',
              sortable: true,
              width: 200,
              renderCell(params) {
                return (
                  <>
                    {!!isDefault(params.row.id, DefaultSuscriptionType.StandardFee) && (
                      <Typography>Standard Default</Typography>
                    )}
                    {!isDefault(params.row.id, DefaultSuscriptionType.StandardFee) && (
                      <Button
                        label='Make Standard Default'
                        onClick={() => makeDefault(params.row.id, DefaultSuscriptionType.StandardFee)}
                      />
                    )}
                  </>
                )
              }
            },
            {
              field: 'freshDefault',
              headerName: 'Fresh Default',
              sortable: true,
              width: 200,
              renderCell(params) {
                return (
                  <>
                    {!!isDefault(params.row.id, DefaultSuscriptionType.FreshFee) && (
                      <Typography>Fresh Default</Typography>
                    )}
                    {!isDefault(params.row.id, DefaultSuscriptionType.FreshFee) && (
                      <Button
                        label='Make Fresh Default'
                        onClick={() => makeDefault(params.row.id, DefaultSuscriptionType.FreshFee)}
                      />
                    )}
                  </>
                )
              }
            }
          ]}
        />
      )}
    </Section>
  )
}
