import { useWindowSize } from '@bloom-coffee/steamed-milk'
import { ReactComponent as RdyHomeGlyph } from 'assets/svg/glyphs/RDY_home_glyph.svg'
import { ReactComponent as RdyLogo } from 'assets/svg/glyphs/RDY_logo_primary.svg'
import { useNavigate } from 'react-router-dom'
import { ColorHex } from 'theme/ThemeConstants'

import { MainMenu } from './MainMenu'

export interface HeaderTitleBarProps {
  title: React.ReactNode
  subTitle?: React.ReactNode
  action?: React.ReactNode
}
export function HeaderTitleBar(props: HeaderTitleBarProps) {
  const { title, subTitle, action } = props
  const navigate = useNavigate()
  const [windowSize] = useWindowSize()

  return (
    <div style={headerTitleContainerStyle}>
      <div style={logoTitleContainerStyle}>
        <div style={logoContainerStyle} onClick={() => navigate('/')}>
          {windowSize !== 'small' && <RdyHomeGlyph style={homeStyle} />}
          <RdyLogo style={rdyStyle} />
        </div>
        <div style={titleContainerStyle}>
          <div style={titleStyle}> {title}</div>
          {subTitle && <div style={subTitleStyle}>{subTitle}</div>}
        </div>
      </div>
      <div style={actionMenuStyle}>
        <div style={actionStyle}>{action}</div>
        <MainMenu />
      </div>
    </div>
  )
}

const actionStyle: React.CSSProperties = {
  paddingTop: 20
}
const actionMenuStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap-reverse'
}
const logoContainerStyle: React.CSSProperties = {
  backgroundColor: ColorHex.RDY_FOREST,
  paddingTop: 0,
  paddingRight: 10,
  paddingLeft: 10,
  paddingBottom: 0,
  margin: 0,
  cursor: 'pointer'
}
const headerTitleContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}
const logoTitleContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flex: 2
}

const titleContainerStyle: React.CSSProperties = {
  marginTop: 12,
  marginLeft: 10,
  display: 'flex',
  flexDirection: 'column'
}

const titleStyle: React.CSSProperties = {
  flexWrap: 'wrap',
  color: ColorHex.WHITE,
  fontFamily: 'Raw-Bold',
  fontSize: 24,
  height: 30,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}
const subTitleStyle: React.CSSProperties = {
  color: ColorHex.WHITE,
  fontFamily: 'Matter-Medium',
  fontSize: 12
}

const homeStyle: React.CSSProperties = {
  color: ColorHex.RDY_MALIBU,
  fill: ColorHex.RDY_MALIBU,
  width: 30,
  height: 30,
  padding: 0,
  marginBottom: 17
}

const rdyStyle: React.CSSProperties = {
  color: ColorHex.RDY_MALIBU,
  fill: ColorHex.RDY_MALIBU,
  width: 60,
  height: 60,
  padding: 0,
  paddingLeft: 10,
  margin: 0
}
