import { useWindowSize } from '@bloom-coffee/steamed-milk'
import React from 'react'
import { ColorHex } from 'theme/ThemeConstants'

import { MenuBar } from '../MenuBar'
import { MenuButtonDetails } from '../MenuButton'
export interface HeaderMenuBarProps {
  menuButtonDetails: MenuButtonDetails[]
}

export function HeaderMenuBar(props: HeaderMenuBarProps) {
  const { menuButtonDetails } = props

  const [windowSize] = useWindowSize()

  const defaultHeaderButtonStyles = {
    small: smallHeaderButtonStyle,
    medium: mediumHeaderButtonStyle,
    large: defaultHeaderButtonStyle
  }
  const activeHeaderButtonStyles = {
    small: smallActiveHeaderButtonStyle,
    medium: mediumActiveHeaderButtonStyle,
    large: activeHeaderButtonStyle
  }

  return (
    <MenuBar
      style={menuStyle}
      menuButtonDetails={menuButtonDetails}
      defaultButtonStyle={defaultHeaderButtonStyles[windowSize]}
      activeButtonStyle={activeHeaderButtonStyles[windowSize]}
    ></MenuBar>
  )
}
const menuStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap'
}

const defaultHeaderButtonStyle: React.CSSProperties = {
  width: '20%',
  fontFamily: 'Raw-Bold',
  fontSize: 24,
  height: 35,
  minWidth: 200,
  borderRadius: 5,
  border: 'solid 1px',
  borderColor: ColorHex.RDY_MALIBU,
  margin: 10,
  backgroundColor: ColorHex.RDY_FOREST,
  color: ColorHex.RDY_MALIBU
}
const smallHeaderButtonStyle: React.CSSProperties = {
  ...defaultHeaderButtonStyle,
  fontFamily: 'Raw-Bold',
  fontSize: 13,
  height: 30,
  minWidth: 100,
  margin: 5
}
const mediumHeaderButtonStyle: React.CSSProperties = {
  ...defaultHeaderButtonStyle,
  fontSize: 14,
  height: 30,
  minWidth: 100,
  margin: 5
}

const activeHeaderButtonStyle: React.CSSProperties = {
  ...defaultHeaderButtonStyle,
  backgroundColor: ColorHex.RDY_MALIBU,
  color: ColorHex.RDY_FOREST,
  borderColor: ColorHex.RDY_FOREST
}

const smallActiveHeaderButtonStyle: React.CSSProperties = {
  ...smallHeaderButtonStyle,
  backgroundColor: ColorHex.RDY_MALIBU,
  color: ColorHex.RDY_FOREST,
  borderColor: ColorHex.RDY_FOREST
}

const mediumActiveHeaderButtonStyle: React.CSSProperties = {
  ...mediumHeaderButtonStyle,
  backgroundColor: ColorHex.RDY_MALIBU,
  color: ColorHex.RDY_FOREST,
  borderColor: ColorHex.RDY_FOREST
}
