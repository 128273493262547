import { useAuth0 } from '@auth0/auth0-react'
import { BackdropLoading } from 'components/layout/BackdropLoading'
import { logger } from 'logger'
import React, { useEffect, useState } from 'react'

import { LoggedIn } from './LoggedIn'
import { Unauthenticated } from './Unauthenticated'

export function ContentRoot() {
  const [isAuth, setIsAuth] = useState<boolean | undefined>()
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    logger.debug('ContentRoot', 'fetching access token')
    getAccessTokenSilently()
      .then((result) => setIsAuth(!!result))
      .catch((_) => setIsAuth(false))
  }, [getAccessTokenSilently])

  return (
    <>
      {typeof isAuth === undefined && <BackdropLoading message='authenticating' />}
      {isAuth === true && <LoggedIn />}
      {isAuth === false && <Unauthenticated />}
    </>
  )
}
