import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { Merchant, useViewOrganizationQuery } from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { MerchantList } from 'views/merchants/components/MerchantList'
import { CreateMerchant } from 'views/merchants/CreateMerchant/CreateMerchant'
import { ViewMerchant } from 'views/merchants/ViewMerchant/ViewMerchant'
import { ImportMerchantFromSquareContainer } from 'views/square/ImportMerchantFromSquareContainer'

interface MerchantsContainerProps {
  organizationId: ID
}

export function MerchantsContainer(props: MerchantsContainerProps) {
  const { organizationId } = props
  const navigate = useNavigate()

  const { data, loading } = useViewOrganizationQuery({
    variables: {
      id: organizationId!
    }
  })
  if (loading) {
    return <CircularProgress />
  }

  function addMerchant() {
    navigate(`/organizations/${organizationId}/merchants/new`)
  }

  return (
    <Routes>
      <Route
        path={'new'}
        element={
          <MenuedContentContainer menuButtonDetails={[]}>
            <CreateMerchant />
          </MenuedContentContainer>
        }
      />
      <Route path='importFromSquare' element={<ImportMerchantFromSquareContainer />} />
      <Route
        path={'/'}
        element={
          <MenuedContentContainer menuButtonDetails={[]}>
            <Section
              addon={
                <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId)]}>
                  <Button label='Add Cafe' onClick={addMerchant} />
                </HasAnyRole>
              }
              title='Cafes'
              variant='grid'
            >
              {!loading && (
                <MerchantList merchants={data?.organization?.merchants as Merchant[]} organizationId={organizationId} />
              )}
            </Section>
          </MenuedContentContainer>
        }
      />
      <Route path={`:merchantId/*`} element={<ViewMerchant />} />
    </Routes>
  )
}
