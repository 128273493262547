import { ApolloCache, FetchResult } from '@apollo/client'
import { Button } from '@bloom-coffee/steamed-milk'
import { makeStyles, Paper } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import {
  DeleteMerchantImageMutation,
  MerchantImage,
  MerchantImageDetailsFragment,
  useDeleteMerchantImageMutation
} from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

interface MerchantImageDetailProps {
  merchantImage: MerchantImage
  index: number
}

export function MerchantImageDetail(props: MerchantImageDetailProps) {
  const { merchantImage, index } = props
  const styles = useStyles()
  const errorHandler = useErrorHandler()
  const [execute, { loading }] = useDeleteMerchantImageMutation()

  function updateCache(
    cache: ApolloCache<DeleteMerchantImageMutation>,
    mutationResult: FetchResult<DeleteMerchantImageMutation>
  ) {
    cache.modify({
      fields: {
        merchantImagesByMerchant(existingImages, { readField }) {
          return existingImages.filter((rc: MerchantImageDetailsFragment) => merchantImage.id !== readField('id', rc))
        }
      }
    })
  }

  async function deleteClicked() {
    try {
      await execute({
        variables: {
          id: merchantImage.id
        },
        update: updateCache
      })
    } catch (e) {
      errorHandler(e)
    }
  }

  return (
    <>
      <Draggable key={merchantImage.id} draggableId={merchantImage.id} index={index}>
        {(provided, snapshot) => (
          <Paper
            variant={snapshot.isDragging ? 'elevation' : 'outlined'}
            className={clsx(styles.listItem, snapshot.isDragging && styles.listItemDragging)}
            elevation={snapshot.isDragging ? 4 : 0}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            innerRef={provided.innerRef}
          >
            <Button
              theme='warning'
              disabled={loading}
              endIcon={<DeleteIcon />}
              onClick={() => deleteClicked()}
              label='Remove'
            />
            <img style={imageStyle} src={merchantImage.imageUri} alt=''></img>
          </Paper>
        )}
      </Draggable>
    </>
  )
}

const imageStyle: React.CSSProperties = {
  width: 250,
  height: 'auto'
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    width: 'auto'
  },
  listItemDragging: {
    border: '1px solid',
    borderColor: theme.palette.primary.main
  }
}))
