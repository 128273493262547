import { Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import {
  CategorySelectDocument,
  CategoryType,
  ProductListDocument,
  useCreateCategoryMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'

import { CategoryForm } from './CategoryForm'
import { CategoryFormModel, categorySchema } from './categoryValidator'
export function CreateCategory() {
  const navigate = useNavigate()
  const toast = useToast()
  const { merchantId } = useParams()
  const [execute, { loading }] = useCreateCategoryMutation()

  async function handleSubmit(values: CategoryFormModel) {
    categorySchema.validate(values)
    const { type: typeString, ...rest } = values

    const type = !!typeString ? (typeString as CategoryType) : null
    try {
      const res = await execute({
        variables: {
          input: {
            merchantId: merchantId!!,
            sortOrder: null,
            type,
            ...rest
          }
        },
        refetchQueries: [
          {
            query: ProductListDocument,
            variables: { merchantId: merchantId! }
          },
          { query: CategorySelectDocument, variables: { merchantId } }
        ]
      })
      if (res.data?.createCategory) {
        toast.success('Category created')
        navigate(-1)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  function handleCancel() {
    navigate(-1)
  }

  return (
    <Section title={'New Category'}>
      {loading && <CircularProgress />}
      {!loading && <CategoryForm onCancel={handleCancel} onSubmit={handleSubmit} />}
    </Section>
  )
}
