import { useWindowSize } from '@bloom-coffee/steamed-milk'
import { PropsWithChildren } from 'react'
import { ColorHex } from 'theme/ThemeConstants'

import { MenuButtonDetails } from '../MenuButton'
import { WindowSizeStyle } from '../WindowSizeStyle'
import { SideMenuBar } from './SideMenuBar'

export interface MenuedContentContainerProps {
  menuButtonDetails: MenuButtonDetails[]
}
export function MenuedContentContainer(props: PropsWithChildren<MenuedContentContainerProps>) {
  const { menuButtonDetails, children } = props

  const [windowSize] = useWindowSize()

  const currentContentStyle = {
    small: smallContentStyle,
    medium: mediumContentStyle,
    large: contentStyle
  }

  const currentNoMenuContentStyle: WindowSizeStyle = {
    small: smallNoMenuContentStyle,
    medium: meduimNoMenuContentStyle,
    large: noMenuContentStyle
  }
  const currentMenuedContentContainerStyle: WindowSizeStyle = {
    small: smallMenuedContentContainerStyle,
    medium: mediumMenuedContentContainerStyle,
    large: menuedContentContainerStyle
  }

  return (
    <div style={currentMenuedContentContainerStyle[windowSize]}>
      {!!menuButtonDetails.length && <SideMenuBar size={windowSize} menuButtonDetails={menuButtonDetails} />}
      <div style={!!menuButtonDetails.length ? currentContentStyle[windowSize] : currentNoMenuContentStyle[windowSize]}>
        {children}
      </div>
    </div>
  )
}

const menuedContentContainerStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row'
}
const mediumMenuedContentContainerStyle: React.CSSProperties = {
  ...menuedContentContainerStyle,
  flexDirection: 'column'
}
const smallMenuedContentContainerStyle: React.CSSProperties = {
  ...menuedContentContainerStyle,
  flexDirection: 'column'
}

const contentStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  backgroundColor: ColorHex.RDY_BEIGE,
  minHeight: 350,
  paddingLeft: 80,
  paddingRight: 80,
  paddingTop: 60,
  paddingBottom: 60,
  marginTop: 28,
  marginBottom: 28,
  border: 'solid 1px',
  borderRight: 'none',
  borderColor: ColorHex.RDY_FOREST
}

const mediumContentStyle: React.CSSProperties = {
  ...contentStyle,
  marginTop: 10,
  marginBottom: 10,
  paddingLeft: 40,
  paddingRight: 20
}
const smallContentStyle: React.CSSProperties = {
  ...contentStyle,
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: 0,
  paddingRight: 0
}

const noMenuContentStyle: React.CSSProperties = {
  ...contentStyle,
  paddingLeft: 120,
  paddingRight: 120
}
const meduimNoMenuContentStyle: React.CSSProperties = {
  ...contentStyle,
  paddingLeft: 60,
  paddingRight: 60
}

const smallNoMenuContentStyle: React.CSSProperties = {
  ...noMenuContentStyle,
  paddingLeft: 0,
  paddingRight: 0
}
