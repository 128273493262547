import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, TextField, Typography } from '@material-ui/core'
import { OrganziationSettingsDocument, useUpdateOrganizationNumericSettingMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'

interface OrganizationNumericSettingContainerProps {
  organizationId: string
  name: string
  description: string
  initialValue: number
}

export const OrganizationNumericSettingContainer = (props: OrganizationNumericSettingContainerProps) => {
  const toast = useToast()
  const { organizationId, name, description, initialValue } = props
  const [newValue, setNewValue] = useState<string>(initialValue.toString())

  const [execute, { error, loading }] = useUpdateOrganizationNumericSettingMutation()
  async function handleSave() {
    let newNumericValue = +newValue

    if (newNumericValue <= 0 || isNaN(newNumericValue)) {
      toast.error('Please enter a valid value')
    }

    await execute({
      variables: { organizationId, name, value: newNumericValue },
      refetchQueries: [{ query: OrganziationSettingsDocument, variables: { organizationId } }]
    })
    toast.success('Saved setting')
  }
  const parseValue = (number: string) => {
    return Number(number.toString().replace(/[^0-9-]/g, ''))
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
          <Typography variant='body2'>{description}</Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
          <TextField
            size='small'
            variant='outlined'
            onChange={(e) => setNewValue(parseValue(e.target.value).toString())}
            value={newValue}
          />

          <Button
            style={{ marginLeft: 10 }}
            label={'Save'}
            onClick={handleSave}
            endIcon={loading && <CircularProgress size={16} />}
          />
        </div>
      </div>

      <div>
        {error && (
          <Typography variant='body1' color='error'>
            {error}
          </Typography>
        )}
      </div>
    </div>
  )
}
