import { Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { QRCodeLink } from 'components/widgets/QRCodeLink'
import { PromoCodeInfoFragment } from 'graphql/types.generated'
import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import { PromoCodeInfo } from 'models/Promotion'
import React from 'react'

import { UpdatePromotionCodeDates } from './UpdatePromotionCodeDates'

interface PromoCodesListProps {
  promotionId: string
  codes: PromoCodeInfoFragment[]
}

export const PromoCodesList = (props: PromoCodesListProps) => {
  const { codes, promotionId } = props
  const { hasAnyRole } = usePermissions()

  return (
    <DataGrid
      autoHeight
      disableSelectionOnClick
      rows={codes}
      columns={[
        { field: 'code', headerName: 'Code', sortable: false, width: 150 },
        {
          field: 'validStart',
          headerName: 'Valid Start',
          width: 150,
          renderCell(params) {
            const d = new Date(params.row.validStart)
            return <Typography variant='body2'>{d.toDateString()}</Typography>
          }
        },
        {
          field: 'validEnd',
          headerName: 'Valid Thru',
          width: 150,
          renderCell(params) {
            const d = params.row.validEnd ? new Date(params.row.validEnd).toDateString() : 'Indefinite'
            return <Typography variant='body2'>{d}</Typography>
          }
        },
        {
          field: '',
          headerName: '',
          width: 150,
          hide: !hasAnyRole(roles.admin),
          renderCell(params) {
            return <UpdatePromotionCodeDates promoCode={params.row as PromoCodeInfo} promotionId={promotionId} />
          }
        },
        {
          field: 'limitTotalUses',
          headerName: 'Limit Total Uses',
          width: 150,
          renderCell(params) {
            return <Typography variant='body2'>{params.row.limitTotalUses}</Typography>
          }
        },
        {
          field: 'deepLink',
          headerName: 'Deep Link',
          width: 400,
          renderCell(params) {
            return <>{params.row.deepLink && <QRCodeLink link={params.row.deepLink} />}</>
          }
        }
      ]}
    />
  )
}
