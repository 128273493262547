export const config = {
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  baseApiUrl: process.env.REACT_APP_BASE_API_URL || '',
  graphqlApiUrl: process.env.REACT_APP_GRAPHQL_API_URL || '',
  graphqlWsUrl: process.env.REACT_APP_GRAPHQL_WS_URL || '',
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  freshKDSBaseUrl: process.env.REACT_APP_FRESHKDS_BASE_URL || '',
  freshUserBaseUrl: process.env.REACT_APP_FRESH_USER_BASE_URL || '',
  latteBaseUrl: process.env.REACT_APP_LATTE_BASE_URL || 'https://order-dev.rdy.xyz',
  version: '2.2.8',
  squareClientId: process.env.REACT_APP_SQUARE_CLIENT_ID || '',
  squareConnectUrl: process.env.REACT_APP_SQUARE_CONNECT_URL || 'https://connect.squareupsandbox.com',
  logLevel: process.env.REACT_APP_MOCHA_LOG_LEVEL || 'ERROR'
}
