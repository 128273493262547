import { isBefore } from 'date-fns'
import { parseTime } from 'util/date'
import { rules } from 'validation/rules'
import * as yup from 'yup'

export const timeIntervalSchema = yup.object({
  start: yup.string().label('Starts').nullable().required(),
  end: yup
    .string()
    .label('Ends')
    .nullable()
    .required()
    .test({
      message: 'Should be after Start',
      test(value, context) {
        if (context.parent.start && value) {
          return isBefore(parseTime(context.parent.start), parseTime(value))
        }
        return true
      }
    })
})

export const dayHoursSchema = yup.object({
  weekDays: yup
    .array(yup.number().required())
    .required()
    .test(rules.arrayNotEmpty('You need to select at least one day')),
  timeIntervals: yup.array(timeIntervalSchema).required()
})

export type DayHoursFormModel = yup.InferType<typeof dayHoursSchema>

export interface DayHoursModel {
  weekDay: number
  timeIntervals: TimeInterval[]
}

interface TimeInterval {
  start: string
  end: string
}
