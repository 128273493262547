import { Button } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { TextField } from 'components/form/TextField'
import {
  ModifiersWithoutChildrenByMerchantDocument,
  ProductListDocument,
  TopModifiersByMerchantDocument,
  useCopyAllProductsFromMerchantMutation
} from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { CopyAllProductsModel, copyAllProductsSchema } from './validator'

interface CopyAllProductsFromMerchantButtonProps {
  merchantId: ID
  style?: React.CSSProperties
}

export function CopyAllProductsFromMerchantButton(props: CopyAllProductsFromMerchantButtonProps) {
  const { merchantId, style } = props
  const toast = useToast()
  const styles = useStyles()
  const [opened, setOpened] = useState(false)
  const errorHandler = useErrorHandler()
  const [execute] = useCopyAllProductsFromMerchantMutation()

  const formProps = useForm<CopyAllProductsModel>({
    resolver: yupResolver(copyAllProductsSchema)
  })

  const { register, handleSubmit, formState } = formProps

  function toggleModal() {
    setOpened((old) => !old)
  }

  async function copyAll(values: CopyAllProductsModel) {
    try {
      await execute({
        variables: {
          input: {
            fromMerchantId: values.fromMerchantId,
            toMerchantId: merchantId
          }
        },
        refetchQueries: [
          { query: ProductListDocument, variables: { merchantId } },
          { query: TopModifiersByMerchantDocument, variables: { merchantId } },
          { query: ModifiersWithoutChildrenByMerchantDocument, variables: { merchantId } }
        ],
        awaitRefetchQueries: true
      })
      toggleModal()
      toast.success('Products copied')
    } catch (e) {
      toast.error(e.message)
      errorHandler(e)
    }
  }

  return (
    <div style={style}>
      <Button onClick={toggleModal} label='Copy all from another Cafe' />
      <Dialog open={opened} onClose={toggleModal}>
        <DialogTitle>Copy all products from</DialogTitle>
        <DialogContent className={styles.dialog}>
          <FormProvider {...formProps}>
            <form onSubmit={handleSubmit(copyAll)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name='fromMerchantId' label='From cafe' inputRef={register} />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <DefaultFormFooter
            onCancel={toggleModal}
            onSubmit={handleSubmit(copyAll)}
            saveLabel='Copy all'
            submitting={formState.isSubmitting}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 400
  }
}))
