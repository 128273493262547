import { Button } from '@bloom-coffee/steamed-milk'
import { Modal } from 'components/layout/Modal/Modal'
import React, { useState } from 'react'
import { QRCodeContainer } from 'views/qrcode/QRCodeContainer'

interface QRCodeLinkProps {
  link: string
  downloadFilename?: string
}

export const QRCodeLink = (props: QRCodeLinkProps) => {
  const { link, downloadFilename } = props
  const [linkForModal, setLinkForModal] = useState<string>()

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Button label='QR Code' onClick={() => setLinkForModal(link)} />

      <Modal open={!!linkForModal} onClose={() => setLinkForModal(undefined)} title='QR Code'>
        <QRCodeContainer link={link} downloadFilename={downloadFilename} />
      </Modal>
    </div>
  )
}
