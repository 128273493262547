import * as yup from 'yup'

import { transform } from '../../../validation/transform'

export const modifierOptionSchema = yup.object({
  name: yup.string().label('Name').required(),
  active: yup.boolean().label('Active').required().default(true),
  selectedName: yup.string().label('Selected name').nullable(true),
  printableName: yup.string().label('Printer Output (if blank, nothing will print when selected)').nullable(true),
  priceCents: yup
    .number()
    .transform(transform.nullable)
    .label('Price')
    .required()
    .typeError('Price needs to be a number'),
  currentQuantity: yup.number().label('Current Quantity').nullable(true).optional().default(0),
  currentlyAvailable: yup.boolean().label('Currently Available').nullable(true).optional().default(null)
})

export type ModifierOptionFormModel = yup.InferType<typeof modifierOptionSchema>
