import { Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { useKdsListQuery } from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'

import { UnlinkKdsButton } from '../UnlinkKdsButton/UnlinkKdsButton'

interface KdsListProps {
  merchantId: ID
  organizationId: ID
}

export function KdsList(props: KdsListProps) {
  const { merchantId, organizationId } = props
  const { data, loading } = useKdsListQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })

  return (
    <>
      <DataGrid
        loading={loading}
        autoHeight
        hideFooterPagination
        disableSelectionOnClick
        rows={data?.merchant?.kdss || []}
        columns={[
          {
            field: 'authorizedBy',
            headerName: 'Linked Account',
            sortable: false,
            width: 300,
            renderCell(params) {
              return <Typography>{params.row.authorizedBy.profile.contactEmail}</Typography>
            }
          },
          { field: 'uuid', headerName: 'Tablet Unique Identifier', sortable: false, width: 500 },
          { field: 'appVersion', headerName: 'App Version', width: 200 },
          {
            field: 'active',
            headerName: 'Active/Inactive',
            sortable: false,
            width: 200,
            renderCell(params) {
              return <Typography>{params.row.active ? 'Active' : 'Inactive'}</Typography>
            }
          },
          {
            field: '',
            headerName: '',
            sortable: false,
            width: 130,
            renderCell(params) {
              return (
                <div>
                  <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId), roles.merchantAdmin(merchantId)]}>
                    <UnlinkKdsButton kdsId={`${params.row.id}`} merchantId={merchantId} />
                  </HasAnyRole>
                </div>
              )
            }
          }
        ]}
      />
    </>
  )
}
