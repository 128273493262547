import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Grid, makeStyles, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'

import { ModifierOptionFragment, useModifiersWithoutChildrenByMerchantQuery } from '../../../graphql/types.generated'
import { CreateModifierOptionContainer } from '../CreateModifierOption/CreateModifierOptionContainer'
import { ModifierOptionsContainer } from '../CreateModifierOption/ModifierOptionsContainer'
import { ModifierOptionForModifierFormModel } from './modifierValidator'

interface SelectOptionsContainerProps {
  merchantId: string
  onCancel: () => void
  handleSelection: (option: ModifierOptionForModifierFormModel) => void
  disableEditing?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 24
  }
}))

function convertToFormModel(option: ModifierOptionFragment): ModifierOptionForModifierFormModel {
  return {
    id: option.id,
    name: option.name,
    printableName: option.printableName,
    priceCents: option.priceCents,
    active: true,
    selectedByDefault: false,
    selectedName: option.selectedName || undefined,
    currentQuantity: option.inventory?.currentQuantity === undefined ? null : option.inventory?.currentQuantity,
    currentlyAvailable:
      option.inventory?.currentlyAvailable === false ? false : option.inventory?.currentlyAvailable ?? true
  }
}

export const SelectOptionsContainer = (props: SelectOptionsContainerProps) => {
  const [showCreate, setShowCreate] = useState(false)
  const { merchantId, onCancel, handleSelection, disableEditing } = props

  const styles = useStyles()
  const { data, loading } = useModifiersWithoutChildrenByMerchantQuery({
    variables: { merchantId: merchantId },
    fetchPolicy: 'cache-first'
  })

  function handleSelectedFragment(option: ModifierOptionFragment) {
    return handleSelection(convertToFormModel(option))
  }

  function selectCreatedFragment(option: ModifierOptionFragment) {
    setShowCreate(false)
    return handleSelectedFragment(option)
  }

  return (
    <>
      {!showCreate && (
        <Paper className={styles.container}>
          <Section
            title={'Select an Option'}
            addon={<Button endIcon={<CloseIcon />} onClick={onCancel} theme='cancel' label='Close' />}
            variant='subsection'
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <ModifierOptionsContainer
                    disableEditing={disableEditing}
                    onSelect={handleSelectedFragment}
                    modifierOptions={data?.modifiersWithoutChildrenByMerchant || []}
                  />
                )}
              </Grid>

              <Grid item></Grid>

              <Grid item>
                <Button
                  disabled={disableEditing}
                  label='Create A New Option'
                  endIcon={<AddIcon />}
                  onClick={() => setShowCreate(true)}
                />
              </Grid>
            </Grid>
          </Section>
        </Paper>
      )}
      {showCreate && (
        <Paper className={styles.container}>
          <CreateModifierOptionContainer
            inlineCreate={true}
            merchantId={merchantId}
            onFinish={selectCreatedFragment}
            onCancel={() => setShowCreate(false)}
          />
        </Paper>
      )}
    </>
  )
}
