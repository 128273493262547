import { useOrganizationListQuery } from 'graphql/types.generated'
import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import { Navigate, Route, Routes } from 'react-router-dom'
import { OnboardingSteps } from 'views/onboarding/OnboardingSteps'

import { CreateOrganization } from './CreateOrganization/CreateOrganization'
import { OrganizationRoot } from './OrganizationRoot'
export function OrganizationsRoot() {
  const { hasAnyRole } = usePermissions()
  const { data, loading } = useOrganizationListQuery()

  return (
    <>
      <Routes>
        <Route path={`new`} element={<CreateOrganization />} />
        <Route path={`:organizationId/*`} element={<OrganizationRoot />} />
        <Route
          path={'*'}
          element={
            <>
              {!!hasAnyRole(roles.admin) && <Navigate to={'/admin'} replace={true} />}
              {!loading && !!data?.organizations && data.organizations.length >= 1 && (
                <Navigate to={`${data.organizations[0].id}`} replace={true} />
              )}
              {!loading && !!data?.organizations && data.organizations.length === 0 && <OnboardingSteps />}
            </>
          }
        />
      </Routes>
    </>
  )
}
