import { Section } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, FormControlLabel, MenuItem, Radio } from '@material-ui/core'
import { DefaultFormFooter, DefaultFormFooterProps } from 'components/form/DefaultFormFooter'
import { SelectField } from 'components/form/SelectField'
import { TextField } from 'components/form/TextField'
import { HoursDisplay } from 'components/hours/HoursDisplay'
import { DayHoursFormModel, DayHoursModel } from 'components/hours/validator'
import { DiningOption, GeoFenceType } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { convertDayHoursModel, convertToDayHoursFormModel, groupMerchantHours } from 'util/merchantHours'

import { MerchantLocationFormSection } from './MerchantLocationFormSection'
import { MerchantFormModel, merchantSchema } from './merchantValidator'

interface MerchantFormProps {
  defaultValues?: Partial<MerchantFormModel>
  disableSubmit?: boolean
  onCancel?(): void
  onSubmit(values: MerchantFormModel): void
  FooterComponent?(props: DefaultFormFooterProps): JSX.Element
}

export function MerchantForm(props: MerchantFormProps) {
  const { defaultValues, onCancel, onSubmit, FooterComponent = DefaultFormFooter, disableSubmit } = props
  const formProps = useForm<MerchantFormModel>({
    defaultValues,
    shouldUnregister: false, // keep values without fields (eg 'id')
    resolver: yupResolver(merchantSchema)
  })
  const { register, handleSubmit, setValue, formState } = formProps

  const defaultHours: DayHoursModel[] = [0, 1, 2, 3, 4, 5, 6].map((dayOfWeek) => {
    return {
      weekDay: dayOfWeek,
      timeIntervals: [{ start: '07:00 AM', end: '07:00 PM' }]
    }
  })
  const [diningOptions, setDiningOptions] = React.useState<string[]>(
    (defaultValues?.diningOptions as string[] | null | undefined) ?? [DiningOption.ForHere, DiningOption.Takeaway]
  )

  const [hours, setHours] = useState(!!defaultValues ? groupMerchantHours(defaultValues?.operationHours) : defaultHours)
  const [showForHereDiningOptions, setShowForHereDiningOptions] = useState(
    !!defaultValues &&
      !!defaultValues.diningOptions?.find((x) => x === DiningOption.ForHere || x === DiningOption.TableService)
  )

  useEffect(() => {
    setValue('operationHours', hours?.map((u) => convertToDayHoursFormModel(u)) ?? [])
  }, [hours, setValue])

  useEffect(() => {
    setValue('diningOptions', diningOptions)
    setShowForHereDiningOptions(
      !!diningOptions?.find((x) => x === DiningOption.ForHere || x === DiningOption.TableService)
    )
  }, [diningOptions, setValue])

  async function updateHours(value: DayHoursFormModel) {
    let updatedAvailabilityWindows = hours.filter((aw) => !value.weekDays.includes(aw.weekDay))
    updatedAvailabilityWindows.push(...convertDayHoursModel(value))
    // let updated = groupAvailabilityWindows(updatedAvailabilityWindows)
    setHours(updatedAvailabilityWindows)
  }

  function toggleDiningOption(diningOption: DiningOption) {
    let updatedDiningOptions = [...diningOptions]
    toggleDiningOptionValue(updatedDiningOptions, diningOption)

    if (diningOption === DiningOption.ForHere) {
      toggleDiningOptionValue(updatedDiningOptions, DiningOption.TableService)
    }

    if (diningOption === DiningOption.TableService) {
      toggleDiningOptionValue(updatedDiningOptions, DiningOption.ForHere)
    }

    setDiningOptions(updatedDiningOptions)
  }

  function toggleDiningOptionValue(updatedDiningOptions: string[], diningOption: DiningOption) {
    let existingIndex = updatedDiningOptions.indexOf(diningOption)
    if (existingIndex >= 0) {
      updatedDiningOptions.splice(existingIndex, 1)
    } else {
      updatedDiningOptions.push(diningOption)
    }
  }

  function setDiningOption(diningOption: DiningOption, checked: boolean) {
    let existingIndex = diningOptions.indexOf(diningOption)

    if (checked && existingIndex >= 0) {
      return
    }

    if (!checked && existingIndex < 0) {
      return
    }

    let updatedDiningOptions = [...diningOptions]
    if (existingIndex >= 0 && !checked) {
      updatedDiningOptions.splice(existingIndex, 1)
    } else if (checked && existingIndex < 0) {
      updatedDiningOptions.push(diningOption)
    }
    setDiningOptions(updatedDiningOptions)
  }

  function removeDiningOptions(diningOptionsToRemove: string[]) {
    let updatedDiningOptions = diningOptions.filter((d) => diningOptionsToRemove.indexOf(d) < 0)
    setDiningOptions(updatedDiningOptions)
  }

  function diningOptionIsChecked(diningOption: DiningOption) {
    return diningOptions.indexOf(diningOption) >= 0
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Section title='Cafe Name' variant='subsection'>
          <TextField style={fieldStyle} fullWidth name='name' label='Cafe Name' inputRef={register()} />
        </Section>
        <Section title='Location' variant='subsection'>
          <MerchantLocationFormSection name='location' />
        </Section>
        <Section title='Operation Hours' variant='subsection'>
          <HoursDisplay values={hours} saveHours={updateHours} />
        </Section>
        <Section title='Dining Options' variant='subsection'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              label='For Here'
              control={
                <Checkbox
                  onChange={(e) => {
                    let checked = !!e.target.checked
                    if (checked) {
                      setDiningOption(DiningOption.ForHere, true)
                    } else {
                      removeDiningOptions([DiningOption.ForHere, DiningOption.TableService])
                    }
                  }}
                  checked={showForHereDiningOptions}
                />
              }
            />
            {showForHereDiningOptions && (
              <div style={{ marginLeft: 20 }}>
                <FormControlLabel
                  label='Self Seating'
                  control={
                    <Radio
                      color='primary'
                      value={DiningOption.ForHere}
                      checked={diningOptionIsChecked(DiningOption.ForHere)}
                      onChange={(event, checked) => {
                        toggleDiningOption(DiningOption.ForHere)
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label='Table Service'
                  control={
                    <Radio
                      color='primary'
                      value={DiningOption.TableService}
                      checked={diningOptionIsChecked(DiningOption.TableService)}
                      onChange={(event, checked) => {
                        toggleDiningOption(DiningOption.TableService)
                      }}
                    />
                  }
                />
              </div>
            )}
            <FormControlLabel
              label='Takeaway'
              control={
                <Checkbox
                  onChange={(e) => setDiningOption(DiningOption.Takeaway, !!e.target.checked)}
                  checked={diningOptionIsChecked(DiningOption.Takeaway)}
                />
              }
            />
          </div>
        </Section>
        <Section title='Additional Cafe Details' variant='subsection'>
          <TextField style={fieldStyle} name='taxRate' label='Tax Rate (%)' inputRef={register()} />

          <SelectField
            label='Smart Prep Setup'
            name={`location.geoFenceType`}
            defaultValue={defaultValues?.location?.geoFenceType ?? GeoFenceType.DrivingDistance}
            style={selectStyle}
            inputRef={register()}
          >
            <MenuItem value={GeoFenceType.DrivingDistance}>Most of my customers drive to my cafe</MenuItem>
            <MenuItem value={GeoFenceType.WalkingDistance}>Most of my customers walk to my cafe</MenuItem>
          </SelectField>
        </Section>

        <FooterComponent
          disabled={disableSubmit}
          style={messageStyle}
          onCancel={onCancel}
          onSubmit={handleSubmit(onSubmit)}
          submitting={formState.isSubmitting}
        />
      </form>
    </FormProvider>
  )
}

const messageStyle: React.CSSProperties = {
  marginTop: 25,
  marginBottom: 25
}

const selectStyle: React.CSSProperties = {
  minWidth: 200,
  marginRight: 5,
  marginBottom: 10
}
const fieldStyle: React.CSSProperties = {
  marginRight: 5,
  marginBottom: 10
}
