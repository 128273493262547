import { createTheme } from '@material-ui/core/styles'

import { ColorHex } from './ThemeConstants'

export const theme = createTheme({
  palette: {
    primary: {
      main: ColorHex.RDY_LIGHT_FOREST
    },
    secondary: {
      main: ColorHex.RDY_LIGHT_FOREST
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: ColorHex.RDY_LIGHT_FOREST,
        color: ColorHex.WHITE
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: ColorHex.RDY_LIGHT_FOREST
      }
    },
    MuiDataGrid: {
      root: {
        border: 'none'
      }
    }
  }
})
