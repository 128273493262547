import { Button } from '@bloom-coffee/steamed-milk'
import { Modal, Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import chowlyLogo from 'assets/img/chowlyLogo.png'
import { ReactComponent as FreshLogo } from 'assets/svg/freshLogo.svg'
import { IntegrationType, useKdsIntegrationListQuery } from 'graphql/types.generated'
import { logger } from 'logger'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { SquareButton } from 'views/square/SquareButton'

import { AddKdsIntegrationModal } from './AddKdsIntegrationModal/AddKdsIntegrationModal'
import { ArchiveKdsIntegrationButton } from './ArchiveKdsIntegrationButton/ArchiveKdsIntegrationButton'
import { UpdateKdsIntegrationActiveButton } from './UpdateKdsIntegrationActiveButton/UpdateKdsIntegrationActiveButton'

interface FreshKdsListProps {
  merchantId: ID
  organizationId: ID
}

const CustomerFacingIntegrationName: Record<IntegrationType, string> = {
  FRESH_KDS: 'Fresh KDS',
  SQUARE: 'Square',
  CHOWLY: 'Chowly'
}

export function KdsIntegarationList(props: FreshKdsListProps) {
  const { merchantId, organizationId } = props
  const [showModal, setShowModal] = useState(false)
  const [integrationType, setIntegrationType] = useState(IntegrationType.FreshKds)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [refetchedFromParams, setRefetchedFromParams] = useState(false)

  const { data, loading, refetch } = useKdsIntegrationListQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (!refetchedFromParams && searchParams.get('refetchIntegrations')) {
      logger.debug('KdsIntegrationList', 'refetching integrations')
      refetch()
      setRefetchedFromParams(true)
    }
  }, [refetch, refetchedFromParams, searchParams])

  function hideModal() {
    setShowModal(false)
  }

  function navigateToIntegrationDetails(integrationId: ID) {
    navigate(`/organizations/${organizationId}/merchants/${merchantId}/home/kdsIntegrations/${integrationId}`)
  }

  return (
    <>
      {data?.merchant?.kdsIntegrations?.length && (
        <DataGrid
          loading={loading}
          autoHeight
          hideFooterPagination
          disableSelectionOnClick
          rows={data?.merchant?.kdsIntegrations || []}
          columns={[
            { field: 'displayName', headerName: 'Name', sortable: false, width: 300 },
            {
              field: 'type',
              headerName: 'Type',
              sortable: false,
              width: 150,
              renderCell(params) {
                return <Typography>{CustomerFacingIntegrationName[params.row.type as IntegrationType]}</Typography>
              }
            },
            {
              field: 'active',
              headerName: 'Active/Inactive',
              sortable: false,
              width: 200,
              renderCell(params) {
                return <Typography>{params.row.active ? 'Active' : 'Inactive'}</Typography>
              }
            },
            {
              field: 'sendOrdersToRdy',
              headerName: 'Show Orders on RDY Cafe',
              sortable: false,
              width: 220,
              renderCell(params) {
                return <Typography>{params.row.sendOrdersToRdy ? 'Yes' : 'No'}</Typography>
              }
            },
            {
              field: '',
              headerName: '',
              sortable: false,
              width: 400,
              renderCell(params) {
                return (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <UpdateKdsIntegrationActiveButton
                      kdsIntegrationId={`${params.row.id}`}
                      merchantId={merchantId}
                      currentlyActive={params.row.active}
                    />
                    <ArchiveKdsIntegrationButton kdsIntegrationId={`${params.row.id}`} merchantId={merchantId} />

                    {params.row.type === IntegrationType.FreshKds && (
                      <Button onClick={() => navigateToIntegrationDetails(params.row.id)} label='Setup Tablets' />
                    )}
                  </div>
                )
              }
            }
          ]}
        />
      )}
      {!data?.merchant?.kdsIntegrations?.filter((i) => i.active).length && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 12 }}>
          <div>
            <SquareButton
              title='Square'
              organizationId={organizationId}
              merchantId={merchantId}
              variant='KdsIntegration'
            />
          </div>

          <div>
            <button
              style={{ margin: 10, borderRadius: 5, cursor: 'pointer' }}
              onClick={() => {
                setIntegrationType(IntegrationType.FreshKds)
                setShowModal(true)
              }}
            >
              <FreshLogo style={{ width: 200, height: 'auto' }} />
            </button>
          </div>
          <div>
            <button
              style={{ margin: 10, borderRadius: 5, cursor: 'pointer' }}
              onClick={() => {
                setIntegrationType(IntegrationType.Chowly)
                setShowModal(true)
              }}
            >
              <img style={{ width: 120, height: 'auto' }} src={chowlyLogo} alt={'Chowly Integration'} />
            </button>
          </div>
        </div>
      )}
      <Modal open={showModal}>
        <div>
          <AddKdsIntegrationModal
            merchantId={merchantId}
            onSuccess={hideModal}
            onCancel={hideModal}
            defaultIntegrationType={integrationType}
          />
        </div>
      </Modal>
    </>
  )
}
