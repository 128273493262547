import { ApolloCache, FetchResult } from '@apollo/client'
import { PromotionFormModel } from 'views/admin/promotions/CreatePromotion/components/promotionValidator'

import {
  CreatePromotionMutation,
  PromotionInfoFragmentDoc,
  useCreatePromotionMutation
} from '../../graphql/types.generated'
import { useToast } from '../useToast'

export function useCreatePromotion() {
  const toast = useToast()
  const [execute, { loading }] = useCreatePromotionMutation({ update: updateCache })

  function updateCache(
    cache: ApolloCache<CreatePromotionMutation>,
    mutationResult: FetchResult<CreatePromotionMutation>
  ) {
    const newPromotion = mutationResult.data?.createPromotion
    if (newPromotion) {
      cache.modify({
        fields: {
          promotions(existingPromotions = []) {
            const newPromotionRef = cache.writeFragment({
              data: newPromotion,
              fragment: PromotionInfoFragmentDoc,
              fragmentName: 'PromotionInfo'
            })
            return [...existingPromotions, newPromotionRef]
          }
        }
      })
    }
  }

  async function create(input: PromotionFormModel, onSuccess: () => void) {
    try {
      const start = new Date(input.start).getTime()
      const end = input.end ? new Date(input.end).getTime() : undefined
      const tokenType = input.tokenType || null

      const res = await execute({
        variables: {
          input: {
            ...input,
            start,
            end,
            tokenType,
            notifyUsersOn: undefined,
            perUserPeriodSeconds: undefined,
            productTags: (input.productTags?.filter((pt) => !!pt) ?? []) as string[]
          }
        }
      })
      if (res.data?.createPromotion) {
        toast.success(`Promotion created: ${res.data.createPromotion.customerFacingName}`)
        onSuccess()
      }
    } catch (e) {
      toast.error(`Could not create promotion ${e}`)
    }
  }

  return { create, loading }
}
