import { Card, CardContent, Grid, lighten, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { CatalogProductFragment } from 'graphql/types.generated'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { moveItem } from 'util/sorting'

import { ItemEntry } from './ItemEntry'

interface ItemListProps {
  items: CatalogProductFragment[]
  onChange(items: CatalogProductFragment[]): void
}

export function ItemList(props: ItemListProps) {
  const { items, onChange } = props
  const styles = useStyles()

  function handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return
    }

    const reorderedItems = moveItem(items, result.source.index, result.destination.index)

    onChange(reorderedItems)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='suggestedItems'>
        {(provided, snapshot) => (
          <Card
            {...provided.droppableProps}
            className={clsx(styles.dropContainer, snapshot.isDraggingOver && styles.dropContainerDragging)}
            ref={provided.innerRef}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color='textSecondary'>Drag items to reorder</Typography>
                </Grid>
                {items.map((item, index) => {
                  function handleRemove() {
                    onChange(items.filter((product) => product.id !== item.id))
                  }
                  return <ItemEntry key={item.id} item={item} index={index} onRemove={handleRemove} />
                })}
                {provided.placeholder}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const useStyles = makeStyles((theme) => ({
  dropContainer: {
    transition: '0.1s all ease-in-out'
  },
  dropContainerDragging: {
    backgroundColor: lighten(theme.palette.primary.light, 0.8)
  }
}))
