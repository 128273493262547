import { TestConfig } from 'yup'

export const rules = {
  arrayNotEmpty(message: string): TestConfig<any, any> {
    return {
      message,
      test: (value) => (value?.length ?? 0) > 0
    }
  }
}
