import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem, Typography } from '@material-ui/core'
import { CheckboxField } from 'components/form/CheckboxField'
import { DateTimeField } from 'components/form/DateTimeField'
import TagSelector from 'components/TagSelector'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { allowedCoffeeTags } from 'util/productTags'

import { DefaultFormFooter } from '../../../../../components/form/DefaultFormFooter'
import { SelectField } from '../../../../../components/form/SelectField'
import { TextField } from '../../../../../components/form/TextField'
import { Frequency, PromotionCostOwner, PromotionType, TokenTypeName } from '../../../../../graphql/types.generated'
import { PromotionFormModel, promotionSchema } from './promotionValidator'

interface PromotionFormProps {
  onCancel: () => void
  onSubmit: (values: PromotionFormModel) => void
  disableSubmit?: boolean
}

export const PromotionForm = (props: PromotionFormProps) => {
  const { onCancel, onSubmit, disableSubmit } = props

  const formProps = useForm<PromotionFormModel>({
    resolver: yupResolver(promotionSchema),
    shouldUnregister: false,
    defaultValues: { frequency: null }
  })

  function updateTags(tags: string[]) {
    formProps.setValue('productTags', tags)
  }

  const { register, handleSubmit, formState } = formProps
  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={3}>
            <TextField fullWidth label='Promotion Name' name='customerFacingName' inputRef={register()} />
          </Grid>

          <Grid item xs={3}>
            <TextField fullWidth label='Promo Code' name='promoCode' inputRef={register()} />
          </Grid>

          <Grid item xs={3}>
            <SelectField fullWidth label='Type' name='type'>
              <MenuItem value={undefined}>
                <em>Select a Promo Type</em>
              </MenuItem>

              {Object.values(PromotionType).map((pt, index) => (
                <MenuItem key={`${pt}.${index}`} value={pt}>
                  {pt}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={6}>
            <DateTimeField name='start' label='Start Date*' defaultValue={new Date().toString()} />
          </Grid>

          <Grid item xs={6}>
            <DateTimeField name='end' label='End Date*' />
          </Grid>

          <Grid item xs={6}>
            <SelectField fullWidth label='Cost Owner' name='costOwner'>
              <MenuItem value={undefined}>
                <em>Who is paying?</em>
              </MenuItem>

              {Object.values(PromotionCostOwner).map((co, index) => (
                <MenuItem key={`${co}.${index}`} value={co}>
                  {co}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={6}>
            <CheckboxField name='newUserOnly' label='New Users Only' defaultValue={false}></CheckboxField>
          </Grid>

          <Grid item xs={12}>
            <TagSelector
              tagOptions={allowedCoffeeTags}
              onTagsChanged={(value: string[]) => updateTags(value)}
              label={'Products'}
              initialTags={[]}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Discount Promos</Typography>
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label='Flat Discount (Cents)'
              defaultValue={0}
              name='flatDiscountUsCents'
              inputRef={register()}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label='Percentage Discount'
              defaultValue={0}
              name='percentageDiscount'
              inputRef={register()}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label='Max Discount (Cents)'
              defaultValue={undefined}
              name='maxDiscountUsCents'
              inputRef={register()}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField fullWidth label='Limit Per User' name='limitPerUser' defaultValue={1} inputRef={register()} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle1'>Token Promos</Typography>
          </Grid>

          <Grid item xs={4}>
            <SelectField fullWidth label='Token Type' name='tokenType'>
              <MenuItem value={undefined}>
                <em>Token Type</em>
              </MenuItem>

              {Object.values(TokenTypeName).map((tt, index) => (
                <MenuItem key={`${tt}.${index}`} value={tt}>
                  {tt}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              label='Number of Tokens'
              name='numberOfTokens'
              defaultValue={0}
              inputRef={register()}
            />
          </Grid>

          <Grid item xs={4}>
            <CheckboxField name='recurring' label='Token Paid Out at Intervals' defaultValue={false}></CheckboxField>
          </Grid>

          <Grid item xs={8}>
            <SelectField fullWidth label='Interval Frequency' name='frequency'>
              <MenuItem value={undefined}>
                <em>Frequency</em>
              </MenuItem>

              {Object.values(Frequency).map((fr, index) => (
                <MenuItem key={`${fr}.${index}`} value={fr}>
                  {fr}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={12}>
            <CheckboxField
              name='dispenseRewardOnEntry'
              label='Give Token On Entering Promo'
              defaultValue={false}
            ></CheckboxField>
          </Grid>

          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body2' color='secondary'>
              *Timezone is local to your computer
            </Typography>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
