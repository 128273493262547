import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, DialogContentText } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import {
  InventoryType,
  OriginType,
  ProductImageInput,
  ProductListDocument,
  ProductTagType,
  RoastType,
  TopModifierInput,
  useArchiveProductMutation,
  useProductDetailsQuery,
  useUpdateProductMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { generateFullTagsFromStrings } from 'util/productTags'

import { ProductForm } from '../components/ProductForm/ProductForm'
import { ProductFormModel } from '../CreateProduct/productValidator'
import { convertProductDetailsToFormModel } from './convertProductDetailsToFormModel'

export function UpdateProduct() {
  const navigate = useNavigate()
  const toast = useToast()
  const { productId, merchantId, organizationId } = useParams()
  const { data, loading } = useProductDetailsQuery({
    variables: {
      id: productId!
    }
  })

  const [archive, { loading: loadingArchive }] = useArchiveProductMutation()

  const initialValues = useMemo(() => convertProductDetailsToFormModel(data?.product), [data?.product])
  const [execute, { loading: loadingUpdate }] = useUpdateProductMutation()

  const [showDeletePrompt, setShowDeletePrompt] = useState(false)
  async function handleSubmit(values: ProductFormModel) {
    const {
      catalogIds,
      modifiers,
      id,
      coffeeTags,
      teaTags,
      flavorNoteTags,
      beanOriginTags,
      originType: originTypeString,
      roastType: roastTypeString,
      productImages,
      description,
      roaster,
      currentQuantity,
      currentlyAvailable,
      inventoryType: inventoryTypeString,
      restockDays,
      restockQuantity,
      availabilityWindows,
      suspendedUntil: suspendedUntilString,
      sourceSystem,
      ...rest
    } = values
    const topModifiers = modifiers?.map((m, index) => {
      return { modifierId: m.id, sortOrder: index } as TopModifierInput
    })
    const tags = generateFullTagsFromStrings(coffeeTags || [], ProductTagType.Coffee)
      .concat(generateFullTagsFromStrings(flavorNoteTags || [], ProductTagType.FlavorNotes))
      .concat(generateFullTagsFromStrings(beanOriginTags || [], ProductTagType.BeanOrigin))
      .concat(generateFullTagsFromStrings(teaTags || [], ProductTagType.Tea))

    const productImageInputs = productImages?.map((productImage, index) => {
      return {
        imageUri: productImage.imageUri,
        libraryImageId: productImage.libraryImageId,
        sortOrder: index
      } as ProductImageInput
    })
    const originType = !!originTypeString ? (originTypeString as OriginType) : null
    const roastType = !!roastTypeString ? (roastTypeString as RoastType) : null
    const inventoryType = inventoryTypeString as InventoryType
    const suspendedUntil = suspendedUntilString ? new Date(suspendedUntilString).getTime() : undefined

    try {
      const res = await execute({
        variables: {
          input: {
            id: id!,
            ...rest,
            catalogIds: (catalogIds.filter((c) => !!c) as string[]) ?? [],
            originType,
            roastType,
            topModifiers,
            productTags: tags,
            description: description === '' ? null : description,
            roaster: roaster === '' ? null : roaster,
            images: productImageInputs || [],
            suspendedUntil,
            inventory: {
              currentQuantity: currentQuantity ?? 0,
              currentlyAvailable,
              inventoryType,
              restockDays: restockDays as number[],
              restockQuantity
            },
            availabilityWindows: availabilityWindows?.flatMap((aw) =>
              aw.timeIntervals.flatMap((ti) => {
                return {
                  dayOfWeek: aw.weekDays[0],
                  start: ti.start,
                  end: ti.end
                }
              })
            )
          }
        },
        refetchQueries: [
          {
            query: ProductListDocument,
            variables: { merchantId: merchantId! }
          }
        ]
      })
      if (res.data?.updateProduct) {
        toast.success(`${values.name} updated`)
        navigate(-1)
      }
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  async function handleDelete() {
    await archive({
      variables: {
        id: productId!
      },
      refetchQueries: [{ query: ProductListDocument, variables: { merchantId: merchantId! } }]
    })
    navigate(-1)
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Section
          title={`Update ${data?.product?.name}`}
          addon={
            <Button
              theme='warning'
              disabled={loadingArchive || loading}
              onClick={handleDelete}
              endIcon={<DeleteIcon />}
            />
          }
        >
          <ProductForm
            disableSubmit={loadingArchive || loadingUpdate}
            organizationId={organizationId!}
            merchantId={merchantId!}
            onCancel={() => navigate(-1)}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            isSynced={data?.product?.isSynced}
          />
          <ConfirmationDialog
            title='Are you sure?'
            open={showDeletePrompt}
            onClose={() => setShowDeletePrompt(false)}
            onConfirm={handleDelete}
          >
            <DialogContentText>Are you sure you want to delete this Product?</DialogContentText>
          </ConfirmationDialog>
        </Section>
      )}
    </>
  )
}
