import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, makeStyles, MenuItem, Select } from '@material-ui/core'
import clsx from 'clsx'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import {
  useMerchantFeeConfigsQuery,
  useSetMerchantFeeConfigForMerchantMutation,
  useViewMerchantQuery
} from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { formatPercentage, formatUsCentsAsUsDollar } from 'util/formatting'

interface SetTransactionFeeProps {
  merchantId: ID
}

export function SetTransactionFee(props: SetTransactionFeeProps) {
  const { merchantId } = props
  const toast = useToast()
  const styles = useStyles()

  const { data, loading: loadingMerchant } = useViewMerchantQuery({
    variables: {
      id: merchantId
    }
  })
  const { data: feeData, loading: loadingList } = useMerchantFeeConfigsQuery()

  const [execute, { loading: loadingSave }] = useSetMerchantFeeConfigForMerchantMutation()

  const [merchantFeeConfigId, setMerchantFeeConfigId] = useState<string | null>(null)
  useEffect(() => {
    if (data?.merchant) {
      setMerchantFeeConfigId(data.merchant.merchantFeeConfig.id)
    }
  }, [data, setMerchantFeeConfigId])

  async function assignMerchantFeeConfig() {
    try {
      execute({
        variables: {
          merchantFeeConfigId: merchantFeeConfigId!!,
          merchantId: merchantId
        }
      })
      toast.success('Merchant Transaction Fee Updated')
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  return (
    <Section title='Merchant Transaction Fee'>
      {loadingMerchant && <CircularProgress />}
      {!loadingMerchant && !loadingList && (
        <HasAnyRole roleMatchers={[roles.admin]}>
          <div style={{ flexDirection: 'column', display: 'flex', alignContent: 'left', paddingLeft: 1 }}>
            <Select
              className={clsx(styles.dropdown)}
              fullWidth={false}
              onChange={(e) => setMerchantFeeConfigId(e.target.value as string)}
              defaultValue={merchantFeeConfigId}
            >
              <MenuItem value=''>
                <em>Select a Fee</em>
              </MenuItem>
              {feeData?.merchantFeeConfigs.map((fee) => {
                return (
                  <MenuItem key={fee.id} value={fee.id}>{`${formatPercentage(
                    fee.percentageFee
                  )} + ${formatUsCentsAsUsDollar(fee.staticFeeInUsCents)}`}</MenuItem>
                )
              })}
            </Select>
          </div>
          <Button
            label='Assign Fee Structure'
            onClick={assignMerchantFeeConfig}
            disabled={!merchantFeeConfigId}
            endIcon={loadingSave && <CircularProgress color='inherit' size={20} />}
          />
        </HasAnyRole>
      )}
    </Section>
  )
}

const useStyles = makeStyles((theme) => ({
  dropdown: { maxWidth: 300 }
}))
