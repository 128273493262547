import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { Maybe } from 'graphql/types.generated'
import { SubscriptionPaymentIntentInfo } from 'models/Subscriptions'
import React from 'react'

import { StripeActivationCheckoutForm } from './StripeActivationCheckoutForm'

interface ActivationPaymentProps {
  onComplete: () => void
  onCancel: () => void
  subscriptionPaymentIntent?: Maybe<SubscriptionPaymentIntentInfo>
  awaitingPayment: boolean
  merchantIds: string[]
  organizationId: string
}

export function ActivationPayment(props: ActivationPaymentProps) {
  const { onComplete, onCancel, subscriptionPaymentIntent, awaitingPayment, organizationId, merchantIds } = props

  return (
    <>
      {awaitingPayment && (
        <Dialog open={true}>
          <DialogTitle>{'Submit Payment'}</DialogTitle>
          <DialogContent>
            <StripeActivationCheckoutForm
              organizationId={organizationId}
              merchantIds={merchantIds}
              subscriptionPaymentIntent={subscriptionPaymentIntent}
              onComplete={() => {
                onComplete()
              }}
              onCancel={() => {
                onCancel()
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
