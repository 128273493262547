import { Button, Section } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, lighten, makeStyles, Paper, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import clsx from 'clsx'
import { ModifierChildSelectionType, ModifierOptionFragment } from 'graphql/types.generated'
import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { DefaultValues, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { convertModiferOptionToFormModel } from 'views/products/UpdateProduct/convertProductDetailsToFormModel'

import { DefaultFormFooter } from '../../../components/form/DefaultFormFooter'
import { ErrorMessage } from '../../../components/form/ErrorMessage'
import { SwitchField } from '../../../components/form/SwitchField'
import { TextField } from '../../../components/form/TextField'
import { ModifierOptionFormSection } from './ModifierOptionFormSection'
import { ModifierTypeRadio } from './ModifierTypeRadio'
import { ModifierFormModel, ModifierOptionForModifierFormModel, modifierSchema } from './modifierValidator'
import { SelectOptionsContainer } from './SelectOptionsContainer'

interface ModifierFormProps {
  merchantId: string
  onCancel: () => void
  onSubmit: (values: ModifierFormModel) => void
  initialValues: DefaultValues<ModifierFormModel> | undefined
  disableSubmit?: boolean
  submitLabel?: string
  isSynced?: boolean
}

export function ModifierForm(props: ModifierFormProps) {
  const { onCancel, onSubmit, initialValues, merchantId, disableSubmit, submitLabel, isSynced } = props
  const [showOptionsSelection, setShowOptionSelection] = useState(false)

  const styles = useStyles()
  const formProps = useForm<ModifierFormModel>({
    defaultValues: initialValues,
    resolver: yupResolver(modifierSchema),
    shouldUnregister: false
  })
  const { control, register, handleSubmit, formState } = formProps
  const { append, fields, remove, swap, move } = useFieldArray<ModifierOptionForModifierFormModel>({
    control,
    name: 'options',
    keyName: 'key' as any
  })

  function handleSelection(modifier: ModifierOptionForModifierFormModel) {
    append(modifier)
  }

  function handleOptionDefaultSelectionChange(optionId: string | undefined, index: number, defaultChecked: boolean) {
    const selectionType = formProps.getValues('childSelectionType')
    if (selectionType === ModifierChildSelectionType.MultiSelect || !optionId || !defaultChecked) {
      updateSelectedByDetailsOnFields((field) => (field.id === optionId ? defaultChecked : field.selectedByDefault))

      return
    } else {
      updateSelectedByDetailsOnFields((field) => field.id === optionId)
    }
  }

  function updateSelectedByDetailsOnFields(updateFunction: (field: any) => boolean) {
    let initialLength = fields.length
    for (let i = 0; i < initialLength; i++) {
      const updatedField = fields[i]
      updatedField.selectedByDefault = updateFunction(fields[i])
      append(updatedField)
      formProps.setValue(`options[${i}].selectedByDefault`, updatedField.selectedByDefault)
    }
    for (let i = 0; i < initialLength; i++) {
      remove(0)
    }
  }

  function updateModifierOptionDetails(updatedModifierOption?: ModifierOptionFragment, selectedByDefault?: boolean) {
    if (!updatedModifierOption) {
      return
    }
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].id !== updatedModifierOption.id) {
        continue
      }
      let updatedModifierField = convertModiferOptionToFormModel(updatedModifierOption!!)
      updatedModifierField.selectedByDefault = !!selectedByDefault
      append(updatedModifierField)
      swap(i, fields.length)
      remove(fields.length)
    }
  }

  async function handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return
    }
    move(result.source.index, result.destination.index)
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={6}>
            <TextField fullWidth name='name' label='Name' inputRef={register()} defaultValue={initialValues?.name} />
          </Grid>
          <Grid item xs={6}>
            <SwitchField disabled={isSynced} name='active' label='Active' />
          </Grid>
          <Grid item xs={12}>
            <ModifierTypeRadio
              disabled={isSynced}
              name='childSelectionType'
              allowedChildSelectionTypes={initialValues?.allowedChildSelectionTypes}
              label='Selection type'
              defaultValue={initialValues?.childSelectionType}
            />
          </Grid>
          <Grid item xs={12}>
            <Section title='Options' variant='subsection'>
              <ErrorMessage name={'options'} />
              {!!fields.length && (
                <div style={{ marginBottom: 1, marginLeft: 5 }}>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={1}>
                      <Typography className={clsx(styles.columnHeader)}>Is Default</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={clsx(styles.columnHeader)}>Option Name</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={clsx(styles.columnHeader)}>Printed Name</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography className={clsx(styles.columnHeader)}>Price</Typography>
                    </Grid>
                  </Grid>
                </div>
              )}

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='modifiers' isDropDisabled={isSynced}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      className={clsx(styles.dropContainer, snapshot.isDraggingOver && styles.dropContainerDragging, {
                        width: '100%'
                      })}
                      ref={provided.innerRef}
                    >
                      {fields.map((field, index) => {
                        function handleRemove() {
                          remove(index)
                        }

                        return (
                          <Draggable key={`test[${index}]`} draggableId={`item-${index}`} index={index}>
                            {(provided, snapshot) => (
                              <Paper
                                variant={snapshot.isDragging ? 'elevation' : 'outlined'}
                                className={clsx(styles.listItem, snapshot.isDragging && styles.listItemDragging)}
                                elevation={snapshot.isDragging ? 4 : 0}
                                {...provided.draggableProps}
                                innerRef={provided.innerRef}
                              >
                                <span {...provided.dragHandleProps} className={clsx(styles.dragHandle)}>
                                  <DragIndicatorIcon />
                                </span>
                                <ModifierOptionFormSection
                                  isSynced={isSynced}
                                  key={(field as any).key}
                                  name={`options[${index}]`}
                                  defaultValues={field}
                                  onRemove={handleRemove}
                                  onClose={updateModifierOptionDetails}
                                  onSelectedByDefaultChange={(value: boolean) => {
                                    handleOptionDefaultSelectionChange(field.id, index, value)
                                  }}
                                />
                              </Paper>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {!showOptionsSelection ? (
                <Button
                  disabled={isSynced}
                  label='Add Option'
                  endIcon={<AddIcon />}
                  onClick={() => setShowOptionSelection(true)}
                />
              ) : (
                <SelectOptionsContainer
                  disableEditing={isSynced}
                  handleSelection={handleSelection}
                  merchantId={merchantId}
                  onCancel={() => setShowOptionSelection(false)}
                />
              )}
            </Section>
          </Grid>

          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
              saveLabel={submitLabel}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  columnHeader: {
    fontWeight: 'bold'
  },
  dropContainer: {
    transition: '0.1s all ease-in-out'
  },
  dropContainerDragging: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8)
  },
  listItem: { alignItems: 'top', display: 'flex', flexDirection: 'row', padding: theme.spacing(1), width: '100%' },
  listItemDragging: {
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  dragHandle: {
    paddingTop: 13
  }
}))
