import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { Maybe, SubscriptionStatus } from 'graphql/types.generated'
import { StripeSubscriptionInfo, SubscriptionPaymentIntentInfo } from 'models/Subscriptions'

import { StripeSubscriptionCheckoutForm } from './StripeSubscriptionCheckoutForm'

interface SubscriptionPaymentProps {
  onComplete: () => void
  onCancel: () => void
  stripeSubscription?: Maybe<StripeSubscriptionInfo>
  trialEndDate?: Maybe<number>
  subscriptionPaymentIntent?: Maybe<SubscriptionPaymentIntentInfo>
  awaitingPayment: boolean
  updatingPayment: boolean
}

export function SubscriptionPayment(props: SubscriptionPaymentProps) {
  const {
    stripeSubscription,
    onComplete,
    onCancel,
    subscriptionPaymentIntent,
    awaitingPayment,
    updatingPayment
  } = props

  function showCheckoutForm() {
    let needsSetup = awaitingPayment && stripeSubscription?.subscriptionStatus === SubscriptionStatus.PendingPayment
    return !!stripeSubscription && (needsSetup || updatingPayment)
  }

  return (
    <>
      {showCheckoutForm() && (
        <Dialog open={true}>
          <DialogTitle>{updatingPayment ? 'Update Payment' : 'Submit Payment'}</DialogTitle>
          <DialogContent>
            <StripeSubscriptionCheckoutForm
              subscriptionPaymentIntent={subscriptionPaymentIntent}
              onComplete={() => {
                onComplete()
              }}
              onCancel={() => {
                onCancel()
              }}
              stripeSubscriptionId={stripeSubscription!.id}
              settingDefaultPaymentMethod={updatingPayment}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
