import { Section } from '@bloom-coffee/steamed-milk'
import { useCreateSubscriptionPlan } from 'hooks/useCreateSubscriptionPlan/useCreateSubscriptionPlan'
import { useNavigate } from 'react-router-dom'

import { SubscriptionPlanForm } from '../components/SubscriptionPlanForm'
import { SubscriptionPlanFormModel } from '../components/subscriptionPlanValidator'

export const CreateSubscriptionPlan = () => {
  const navigate = useNavigate()

  const { loading, create } = useCreateSubscriptionPlan()

  function handleSubmit(input: SubscriptionPlanFormModel) {
    create(input, () => navigate(-1))
  }

  return (
    <Section title={`Create new Subscription Plan`}>
      <SubscriptionPlanForm onCancel={() => navigate(-1)} onSubmit={handleSubmit} disableSubmit={loading} />
    </Section>
  )
}
