import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Paper } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import {
  CatalogProductFragment,
  CategoryType,
  useCatalogSuggestedItemsQuery,
  useUpdateSuggestedItemsMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CatalogProductSelect } from 'views/products/components/CatalogProductSelect/CatalogProductSelect'

import { ItemList } from './components/ItemList'

const defaultFilterOptions = createFilterOptions<CatalogProductFragment>()

export function UpdateSuggestedItems() {
  const { merchantId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { data, loading } = useCatalogSuggestedItemsQuery({
    variables: {
      merchantId: merchantId!
    }
  })
  const [selectedItems, setSelectedItems] = useState<CatalogProductFragment[]>([])
  const [update] = useUpdateSuggestedItemsMutation()

  useEffect(() => {
    setSelectedItems(data?.merchant?.activeCatalog?.suggestedItems?.flatMap((item) => item.product) ?? [])
  }, [data])

  function handleChange(event: ChangeEvent<{}>, product: CatalogProductFragment | null) {
    if (product) {
      setSelectedItems((curr) => [...curr, product])
    }
  }

  const catalogId = data?.merchant?.activeCatalog?.id as string

  async function handleSave() {
    await update({
      variables: {
        input: {
          catalogId,
          items: selectedItems.map((item) => ({
            productId: item.id
          }))
        }
      }
    })
    toast.success('Suggested items updated.')
    navigate(-1)
  }

  const selectedItemsIds = selectedItems.map((item) => item.id)

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Section title='Update suggested Items' subTitle='Select up to 4 products'>
      <div>
        {!!selectedItems.length && <ItemList items={selectedItems} onChange={setSelectedItems} />}
        {selectedItems.length < 4 && (
          <div>
            <Paper variant='outlined'>
              <CatalogProductSelect
                key={selectedItems.length}
                catalogId={catalogId}
                label='Select a product to add'
                name='product'
                filterOptions={(options, state) =>
                  defaultFilterOptions(
                    options.filter(
                      (option) =>
                        !selectedItemsIds.includes(option.id) && option.category.type !== CategoryType.RetailBeans
                    ),
                    state
                  )
                }
                onChange={handleChange}
              />
            </Paper>
          </div>
        )}
        <div style={actionsStyle}>
          <Button style={buttonStyle} label='Cancel' theme='cancel' onClick={() => navigate(-1)}></Button>
          <Button style={buttonStyle} onClick={handleSave} label='Save' />
        </div>
      </div>
    </Section>
  )
}

const actionsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: 10
}

const buttonStyle: React.CSSProperties = {
  marginRight: 5
}
