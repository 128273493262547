import { Section } from '@bloom-coffee/steamed-milk'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { ErrorMessage } from 'components/form/ErrorMessage'
import TagSelector from 'components/TagSelector'
import { CategoryType, OriginType, ProductTagType } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { DefaultValues, useFormContext } from 'react-hook-form'
import {
  allowedCoffeeTags,
  allowedTeaTags,
  areTagsAllowedForCategory,
  beanOriginTagOptions,
  flavorNoteTagOptions
} from 'util/productTags'
import { ProductTagsContainer } from 'views/products/CreateProduct/ProductTagsContainer'
import { ProductFormModel } from 'views/products/CreateProduct/productValidator'

const categoriesWithOriginType = [CategoryType.RetailBeans]

interface ProductTagProps {
  initialValues?: DefaultValues<ProductFormModel> | undefined
  categoryType: CategoryType | null
}

export function ProductTags(props: ProductTagProps) {
  const { initialValues, categoryType } = props
  const [originHelpText, setOriginHelpText] = useState<string>('')
  const { register, setValue, watch } = useFormContext()

  const originType = watch('originType')
  const productName = watch('name')

  const [beanOriginTagValues, setBeanOriginTagValues] = useState<string[]>(
    (props.initialValues?.beanOriginTags as string[]) || []
  )
  const [flavorNoteTagValues, setFlavorNoteTagValues] = useState<string[]>(
    (props.initialValues?.flavorNoteTags as string[]) || []
  )

  const [coffeeTagValues, setCoffeeTagValues] = useState<string[]>((props.initialValues?.coffeeTags as string[]) || [])
  const [teaTagValues, setTeaTagValues] = useState<string[]>((props.initialValues?.teaTags as string[]) || [])

  function areTagsAllowed(tagType: ProductTagType) {
    return categoryType && areTagsAllowedForCategory(categoryType, tagType)
  }
  function updateCoffeeTags(tags: string[]) {
    setCoffeeTagValues(tags)
    setValue('coffeeTags', tags)
  }

  function updateTeaTags(tags: string[]) {
    setTeaTagValues(tags)
    setValue('teaTags', tags)
  }

  useEffect(() => {
    setOriginHelpText(
      originType === OriginType.Blend ? 'Choose the countries of origin' : 'Choose one country of origin'
    )
  }, [originType])

  function originTypeIsAllowed() {
    return categoryType && categoriesWithOriginType.indexOf(categoryType) >= 0
  }

  function updateFlavorNoteTags(tags: string[]) {
    setFlavorNoteTagValues(tags)
    setValue('flavorNoteTags', tags)
  }

  function updateBeanOriginTags(tags: string[]) {
    setBeanOriginTagValues(tags)
    setValue('beanOriginTags', tags)
  }

  useEffect(() => {
    if (!categoryType) {
      return
    }

    if (!originTypeIsAllowed()) {
      setValue('originType', null)
    } else if (!originType) {
      setValue('originType', OriginType.SingleOrigin)
    }

    if (!areTagsAllowedForCategory(categoryType, ProductTagType.BeanOrigin)) {
      updateBeanOriginTags([])
    }
    if (!areTagsAllowedForCategory(categoryType, ProductTagType.FlavorNotes)) {
      updateFlavorNoteTags([])
    }
    if (!areTagsAllowedForCategory(categoryType, ProductTagType.Coffee)) {
      updateCoffeeTags([])
    }
    if (!areTagsAllowedForCategory(categoryType, ProductTagType.Tea)) {
      updateTeaTags([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryType, setValue])

  return (
    <div>
      {areTagsAllowed(ProductTagType.Coffee) && (
        <ProductTagsContainer
          categoryType={categoryType}
          productName={productName}
          tagType={ProductTagType.Coffee}
          allowedTags={allowedCoffeeTags}
          selectedValues={coffeeTagValues}
          updateSelections={updateCoffeeTags}
        />
      )}

      {areTagsAllowed(ProductTagType.Tea) && (
        <ProductTagsContainer
          categoryType={categoryType}
          productName={productName}
          tagType={ProductTagType.Tea}
          allowedTags={allowedTeaTags}
          selectedValues={teaTagValues}
          updateSelections={updateTeaTags}
        />
      )}

      {categoryType === CategoryType.RetailBeans && (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {areTagsAllowed(ProductTagType.FlavorNotes) && (
            <div>
              <Section title='Flavor Notes' variant='subsection'>
                <Section title={'Add up to three flavor notes'} variant='subsection'>
                  <TagSelector
                    tagOptions={flavorNoteTagOptions}
                    onTagsChanged={(value: string[]) => updateFlavorNoteTags(value)}
                    label={'Flavor Notes'}
                    initialTags={flavorNoteTagValues}
                  />
                </Section>
              </Section>
              <ErrorMessage name={'flavorNoteTags'} />
            </div>
          )}

          {areTagsAllowed(ProductTagType.BeanOrigin) && (
            <Section title='Bean Origin(s)' variant='subsection'>
              <RadioGroup
                row
                aria-label='Bean Origin'
                name='originType'
                defaultValue={initialValues?.originType || OriginType.SingleOrigin}
              >
                <FormControlLabel
                  value={OriginType.SingleOrigin}
                  key={OriginType.SingleOrigin}
                  control={<Radio color='primary' />}
                  label='Single Origin'
                  inputRef={register}
                />
                <FormControlLabel
                  value={OriginType.Blend}
                  key={OriginType.Blend}
                  control={<Radio color='primary' />}
                  label='Blend'
                  inputRef={register}
                />
              </RadioGroup>
              <Section title={originHelpText} variant='subsection'>
                <TagSelector
                  label={'Bean Origin(s)'}
                  onTagsChanged={(value: string[]) => updateBeanOriginTags(value)}
                  tagOptions={beanOriginTagOptions}
                  initialTags={beanOriginTagValues}
                />
              </Section>
              <ErrorMessage name={'beanOriginTags'} />
            </Section>
          )}
        </div>
      )}
    </div>
  )
}
