import { Section } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSendPushToMarketMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { SendPushForm } from '../SendPushForm'
import { MarketPushFormModel, marketPushSchema } from './components/sendMarketPushValidator'

export const SendMarketPush = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [execute, { loading }] = useSendPushToMarketMutation()

  async function handleSubmit(form: MarketPushFormModel) {
    try {
      await execute({
        variables: {
          input: {
            messageType: form.messageType,
            marketId: form.marketId,
            content: {
              title: form.title,
              body: form.body,
              analyticsTag: form.analyticsTag,
              appAction: form.appAction || undefined
            }
          }
        }
      })
      toast.success(`Successfully sent ${form.body} to market id ${form.marketId}`)
    } catch (e) {
      toast.error(e.toString())
    }
  }

  const formProps = useForm<MarketPushFormModel>({
    resolver: yupResolver(marketPushSchema),
    shouldUnregister: false
  })

  return (
    <Section title='Send Push to Market'>
      <SendPushForm
        formProps={formProps}
        type='market'
        onCancel={() => navigate('/admin')}
        onSubmit={handleSubmit}
        disableSubmit={loading}
      />
    </Section>
  )
}
