import { Section } from '@bloom-coffee/steamed-milk'
import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { useCreateOrganizationMutation } from 'graphql/types.generated'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { OrganizationForm, OrganizationFormModel } from '../components/OrganizationForm'

export function CreateOrganization() {
  const navigate = useNavigate()
  const [execute] = useCreateOrganizationMutation()

  async function saveOrganization(data: OrganizationFormModel) {
    const organizationResponse = await execute({
      variables: {
        input: data
      }
    })

    navigate(`/organizations/${organizationResponse.data?.createOrganization.id}`)
  }

  return (
    <MenuedContentContainer menuButtonDetails={[]}>
      <Section title='New Organization'>
        <OrganizationForm onCancel={() => navigate(-1)} onSubmit={saveOrganization} />
      </Section>
    </MenuedContentContainer>
  )
}
