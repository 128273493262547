import { Section } from '@bloom-coffee/steamed-milk'
import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { PaymentAccount } from 'components/payments/PaymentAccount/PaymentAccount'
import { SetupPaymentAccount } from 'components/payments/SetupPaymentAccount/SetupPaymentAccount'
import { Navigate, Route, Routes } from 'react-router-dom'

import { OrganizationSubscriptionPayment } from './MerchantSubscriptions/OrganizationSubscriptionPayment'

interface OrganizationBillingContainerProps {
  organizationId: ID
}

export function OrganizationBillingContainer(props: OrganizationBillingContainerProps) {
  const { organizationId } = props

  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/organizations/${organizationId}/billing/payoutAccount`,
          paths: [
            `/organizations/:organizationId/billing/payoutAccount`,
            `/organizations/:organizationId/billing/setupPaymentAccount`
          ],
          text: 'Payout Account'
        },
        {
          link: `/organizations/${organizationId}/billing/subscription`,
          paths: [`/organizations/:organizationId/billing/subscription`],
          text: 'Activation'
        }
      ]}
    >
      <Routes>
        <Route path={`payoutAccount`} element={<PaymentAccount organizationId={organizationId} />} />
        <Route path={`setupPaymentAccount`} element={<SetupPaymentAccount />} />
        <Route
          path={`subscription`}
          element={
            <Section title='Activation' subTitle='View the Activation payment applied to your account'>
              <OrganizationSubscriptionPayment organizationId={organizationId} />
            </Section>
          }
        />
        <Route path={`*`} element={<Navigate to={'payoutAccount'} replace={true} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
