import axios from 'axios'
import { config } from 'config'

export async function requestAmericanoHttpDownload(relativeUrl: string, filename: string) {
  const headers: any = {
    'content-type': 'application/json'
  }
  if (getToken) {
    const token = await getToken()

    if (token) {
      headers['authorization'] = `Bearer ${token}`
    }
  }

  await axios
    .request({
      headers,
      method: 'GET',
      url: config.baseApiUrl + relativeUrl,
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
}

let getToken: () => Promise<string | undefined | null>

export function setHttpClientTokenRetriever(tokenRetriever: () => Promise<string | undefined | null>) {
  getToken = tokenRetriever
}
