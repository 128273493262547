import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ConfirmedRequisitionsContainer } from './ConfirmedRequisitionsContainer'
import { RequisitionSchedulesContainer } from './RequisitionSchedulesContainer'

export const RequisitionsRootMenuContainer = () => {
  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: '/admin/requisitions/confirmed',
          paths: ['/admin/requisitions/confirmed/*'],
          text: 'Confirmed'
        },
        {
          link: '/admin/requisitions/scheduled',
          paths: ['/admin/requisitions/scheduled/*'],
          text: 'Scheduled'
        }
      ]}
    >
      <Routes>
        <Route path='confirmed' element={<ConfirmedRequisitionsContainer />} />
        <Route path='scheduled' element={<RequisitionSchedulesContainer />} />
      </Routes>
    </MenuedContentContainer>
  )
}
