import { Button } from '@bloom-coffee/steamed-milk'
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles
} from '@material-ui/core'
import { useToast } from 'hooks/useToast'
import React, { useEffect, useState } from 'react'

import { useProductsWithModifiersQuery, useUpdateTopModifierUsageMutation } from '../../../graphql/types.generated'

export interface UpdateModifierUsageProps {
  modifierId: string
  merchantId: string
  disabled: boolean
  open: boolean
  onClose: () => void
}

export const UpdateModifierUsage = (props: UpdateModifierUsageProps) => {
  const { modifierId, merchantId, disabled, open, onClose } = props

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const styles = useStyles()
  const toast = useToast()

  const { data, loading } = useProductsWithModifiersQuery({
    variables: {
      merchantId
    }
  })

  const [execute, { loading: saveLoading }] = useUpdateTopModifierUsageMutation()

  const products = data?.merchant?.products ?? []

  useEffect(() => {
    if (data) {
      const productIdsWithModifier = (data?.merchant?.products ?? [])
        .filter((p) => p?.modifiers?.filter((m) => m.id === modifierId).length)
        .map((p) => p!!.id)
      setSelectedIds(productIdsWithModifier)
    }
  }, [data, modifierId])

  function toggleProduct(id: string, checked: boolean) {
    var updatedSelectedIds = selectedIds.slice()
    if (checked) {
      updatedSelectedIds.push(id)
    } else if (updatedSelectedIds.indexOf(id) >= 0) {
      updatedSelectedIds.splice(updatedSelectedIds.indexOf(id), 1)
    }
    setSelectedIds(updatedSelectedIds)
  }

  async function save() {
    try {
      const res = await execute({
        variables: {
          input: {
            modifierId,
            productIds: selectedIds
          }
        }
      })
      if (res.data?.updateTopModifierUsage) {
        toast.success('Updated Products!')
        onClose()
      } else {
        throw new Error(`Could not update products: ${res.errors}`)
      }
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Products using this modifier</DialogTitle>
      <DialogContent className={styles.dialog}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div
            style={{
              maxHeight: 400,
              width: 300,
              overflowY: 'scroll',
              flexDirection: 'column',
              paddingLeft: 20,
              paddingRight: 20
            }}
          >
            {products
              .filter((p) => !!p)
              .map((product) => {
                return (
                  <div key={product!!.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          edge='start'
                          checked={selectedIds.includes(product!!.id)}
                          onChange={(event, checked) => {
                            toggleProduct(product!!.id, checked)
                          }}
                          tabIndex={-1}
                          disabled={disabled}
                        />
                      }
                      label={product!!.name}
                    />
                  </div>
                )
              })}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} label='Cancel' theme='cancel' />
        <Button onClick={save} label='Save' disabled={loading || saveLoading} />
      </DialogActions>
    </Dialog>
  )
}
const useStyles = makeStyles((theme) => ({
  dialog: {}
}))
