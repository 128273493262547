import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem } from '@material-ui/core'
import { NumericField } from 'components/form/NumericField'
import { PriceField } from 'components/form/PriceField'
import { SelectField } from 'components/form/SelectField'
import { PlatformPricingType } from 'graphql/types.generated'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../../components/form/DefaultFormFooter'
import { TextField } from '../../../../components/form/TextField'
import { PlatformPricingFormModel, platformPricingSchema } from './platformPricingValidator'

interface PlatformPricingFormProps {
  onCancel: () => void
  onSubmit: (values: PlatformPricingFormModel) => void
  disableSubmit?: boolean
  initialValues?: DefaultValues<PlatformPricingFormModel> | undefined
}

export const PlatformPricingForm = (props: PlatformPricingFormProps) => {
  const { onCancel, onSubmit, disableSubmit, initialValues } = props

  const formProps = useForm<PlatformPricingFormModel>({
    resolver: yupResolver(platformPricingSchema),
    shouldUnregister: false
  })

  const { register, handleSubmit, formState } = formProps
  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={4}>
            <TextField fullWidth label='Name' name='name' inputRef={register()} />
          </Grid>

          <Grid item xs={4}>
            <PriceField
              fullWidth
              name='instantPriceUsCents'
              label='Instant Price'
              defaultValue={initialValues?.instantPriceUsCents}
            />
          </Grid>

          <Grid item xs={4}>
            <PriceField
              fullWidth
              name='subscriptionMonthlyFeeUsCents'
              label='Subscription Price'
              defaultValue={initialValues?.subscriptionMonthlyFeeUsCents}
            />
          </Grid>

          <Grid item xs={4}>
            <NumericField
              fullWidth
              name='subscriptionTerm'
              label='Subscription Term'
              defaultValue={initialValues?.subscriptionTerm}
            />
          </Grid>

          <Grid item xs={4}>
            <SelectField fullWidth label='Type' name='platformPricingType'>
              <MenuItem value=''>
                <em>Select a Type</em>
              </MenuItem>
              <MenuItem value={PlatformPricingType.Instant}>Instant</MenuItem>
              <MenuItem value={PlatformPricingType.Standard}>Standard</MenuItem>
              <MenuItem value={PlatformPricingType.Signature}>Signature</MenuItem>
            </SelectField>{' '}
          </Grid>
          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
