import { useDetectOutsideClick } from '@bloom-coffee/steamed-milk'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { PropsWithChildren, useEffect, useRef } from 'react'

interface ModalProps {
  open: boolean
  title: string
  onClose: () => void
  style?: React.CSSProperties
  disableClickOutClose?: boolean
}

export function Modal(props: PropsWithChildren<ModalProps>) {
  const { title, onClose, children, open, style, disableClickOutClose } = props

  const modalRef = useRef<HTMLDivElement>(null)
  const { isActive: isOpen, setIsActive: setIsOpen } = useDetectOutsideClick(modalRef, open, disableClickOutClose)

  useEffect(() => {
    setIsOpen(open)
  }, [open, setIsOpen])

  useEffect(() => {
    if (!isOpen && open) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Dialog open={open} onClose={onClose} style={style}>
      <DialogTitle disableTypography style={dialogTitle}>
        <h2>{title}</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

const dialogTitle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: 0,
  borderBottom: '1px solid',
  minWidth: 250
}
