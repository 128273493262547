import { yupResolver } from '@hookform/resolvers/yup'
import { DateField } from 'components/form/DateField'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { TransactionSearchFormModel, transactionSearchSchema } from './orderSearchValidator'

interface TransactionSerachFormProps {
  onSubmit: (values: TransactionSearchFormModel) => void
  disableSubmit?: boolean
}

export const TransactionSearchForm = (props: TransactionSerachFormProps) => {
  const { onSubmit, disableSubmit } = props

  const formProps = useForm<TransactionSearchFormModel>({
    resolver: yupResolver(transactionSearchSchema),
    shouldUnregister: false
  })

  const startDate = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
  const endDate = new Date()

  function preSubmit(values: TransactionSearchFormModel) {
    const startDate = new Date(values.startDate)
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date(values.endDate)
    endDate.setHours(23, 59, 59, 999)

    onSubmit({
      startDate: startDate.toString(),
      endDate: endDate.toString()
    })
  }

  const { handleSubmit, formState } = formProps
  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(preSubmit)} style={formStyle}>
        <DateField style={fieldStyle} name='startDate' label='Start Date' defaultValue={startDate} />
        <DateField style={fieldStyle} name='endDate' label='End Date' defaultValue={endDate} />

        <div style={fieldStyle}>
          <DefaultFormFooter
            disabled={disableSubmit}
            onSubmit={handleSubmit(preSubmit)}
            submitting={formState.isSubmitting}
            saveLabel='Search'
          />
        </div>
      </form>
    </FormProvider>
  )
}

const formStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}

const fieldStyle: React.CSSProperties = {
  padding: 10
}
