import { Color } from '@bloom-coffee/steamed-milk'
import { Radio } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { PlatformPricingType } from 'graphql/types.generated'
import { PlatformPricingInfo } from 'models/Subscriptions'
import React from 'react'
import { formatUsCentsAsUsDollar } from 'util/formatting'

interface PlatformPlanProps {
  platformPricing: PlatformPricingInfo
  termPricing?: boolean
  includedCheckCount?: number
  selected?: boolean
  select?: (termPricing: boolean) => void
}

export function PlatformPlan(props: PlatformPlanProps) {
  const { platformPricing, includedCheckCount, selected, select, termPricing } = props
  const tagLinesByPlatformPricingType: { [key in PlatformPricingType]: string } = {
    [PlatformPricingType.Instant]: 'Get online fast and start accepting orders',
    [PlatformPricingType.Standard]: 'Custom assets that match your cafe',
    [PlatformPricingType.Signature]: 'The entire RDY suite'
  }
  const featureDescriptionsByPlatformPricingType: { [key in PlatformPricingType]: string[] } = {
    [PlatformPricingType.Instant]: [
      'Merchant Provides tablet and stand',
      'Generic takeaway cards',
      'Live Software Updates',
      'RDY Web App - Early Access',
      'Price Optimizer - Early Access'
    ],
    [PlatformPricingType.Standard]: [
      'RDY Cafe tablet + restaurant grade stand included',
      'Instant + custom branded point of sale, pick-up signage',
      'Live Software Updates',
      'RDY Web App - Early Access',
      'Price Optimizer - Early Access'
    ],
    [PlatformPricingType.Signature]: [
      'RDY Cafe tablet + restaurant grade stand included',
      'Standard + custom A-frame signage and window decal',
      'Live Software Updates',
      'RDY Web App - Early Access',
      'Price Optimizer - Early Access'
    ]
  }
  return (
    <div style={selected ? selectedStyle : unselectedStyle}>
      <div style={{ ...priceNameStyle, ...(selected ? { fontSize: 30, fontWeight: 'bold' } : {}) }}>
        {platformPricing?.name}
      </div>
      <div style={{ height: 18, alignSelf: 'center', margin: 5 }}>
        {tagLinesByPlatformPricingType[platformPricing.platformPricingType]}
      </div>
      {!select && (
        <div
          style={{
            ...pricingContainerStyle,
            ...(!platformPricing.subscriptionProduct ? { justifyContent: 'center' } : {})
          }}
        >
          <div style={singlePricingContainerStyle}>
            <div style={twoPriceOption}>One-time Payment</div>
            <div style={!platformPricing.subscriptionProduct ? onePriceOption : twoPriceOption}>
              {formatUsCentsAsUsDollar(platformPricing.instantPriceUsCents)}
            </div>
          </div>

          {!!platformPricing.subscriptionProduct && (
            <div style={singlePricingContainerStyle}>
              <div style={twoPriceOption}>6 month contract, then free</div>
              <div style={twoPriceOption}>
                {`${formatUsCentsAsUsDollar(platformPricing.subscriptionProduct!!.costUsCents!!)} / Month`}
              </div>
            </div>
          )}
        </div>
      )}
      <div style={featureListStyle}>
        {featureDescriptionsByPlatformPricingType[platformPricing.platformPricingType].map((desc) => {
          return (
            <div style={featureStyle} key={desc}>
              <CheckIcon style={{ height: 12, marginTop: 3 }} />
              <span>{desc}</span>
            </div>
          )
        })}

        {!!includedCheckCount &&
          [...Array(includedCheckCount)].map((value, index) => {
            return (
              <div style={checkStyle} key={index}>
                <CheckIcon />
              </div>
            )
          })}
      </div>
      {!!select && (
        <div style={buttonContainer}>
          <div style={selectionCell}>
            <Radio
              color='primary'
              value={!termPricing && selected}
              checked={!termPricing && selected}
              onChange={(event, checked) => {
                select(false)
              }}
            />
            <div
              style={radioLabel}
              onClick={() => {
                select(false)
              }}
            >{`${formatUsCentsAsUsDollar(platformPricing.instantPriceUsCents!!)} - One Time Payment`}</div>
          </div>
          {!!platformPricing.subscriptionProduct && (
            <div style={selectionCell}>
              <Radio
                color='primary'
                value={termPricing && selected}
                checked={termPricing && selected}
                onChange={(event, checked) => {
                  select(true)
                }}
              />
              <div
                style={radioLabel}
                onClick={() => {
                  select(true)
                }}
              >
                {`${formatUsCentsAsUsDollar(platformPricing.subscriptionProduct!!.costUsCents!!)} / Month for ${
                  platformPricing.subscriptionTerm
                } Months`}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const baseStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: 275,
  padding: 10,
  border: 'solid 1px'
}

const selectedStyle: React.CSSProperties = {
  ...baseStyle,
  backgroundColor: Color.RDY_MALIBU
}
const unselectedStyle: React.CSSProperties = {
  ...baseStyle,
  backgroundColor: Color.RDY_BEIGE
}

const selectionCell: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const radioLabel: React.CSSProperties = {
  marginTop: 'auto',
  marginBottom: 'auto'
}

const priceNameStyle: React.CSSProperties = {
  height: 50,
  fontSize: 24,
  alignSelf: 'center'
}
const pricingContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: 50,
  alignItems: 'center',
  backgroundColor: Color.RDY_DARK_GREY,
  color: Color.WHITE,
  marginLeft: -10,
  marginRight: -10
}
const singlePricingContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 5
}
const featureListStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 10
}

const featureBaseStyle: React.CSSProperties = {
  height: 40,
  paddingBottom: 10
}

const featureStyle: React.CSSProperties = {
  ...featureBaseStyle,
  display: 'flex',
  flexDirection: 'row'
}

const checkStyle: React.CSSProperties = {
  ...featureBaseStyle,
  alignSelf: 'center'
}

const onePriceOption: React.CSSProperties = {
  fontSize: 24
}

const twoPriceOption: React.CSSProperties = {
  fontSize: 12
}

const buttonContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column'
}
