import { ColorHex } from 'theme/ThemeConstants'

export function Footer() {
  return (
    <div style={mainHeaderStyle}>
      <div style={titleHeaderStyle}>
        <div style={linkContainerStyle}>
          <a style={linkStyle} href='mailto:support@rdy.xyz?subject=RDY Feedback'>
            Feedback
          </a>
        </div>
        <div style={linkContainerStyle}>
          <a style={linkStyle} href='https://rdy.xyz/privacy-policy'>
            Privacy Policy
          </a>
        </div>
        <div style={linkContainerStyle}>
          <a style={linkStyle} href='https://rdy.xyz/terms'>
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  )
}

const mainHeaderStyle: React.CSSProperties = {
  width: '100%',
  minHeight: 60,
  backgroundColor: ColorHex.RDY_FOREST,
  position: 'absolute',
  bottom: 0,
  left: 0
}

const titleHeaderStyle: React.CSSProperties = {
  color: ColorHex.WHITE,
  backgroundColor: ColorHex.RDY_FOREST,
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: 10
}

const linkContainerStyle: React.CSSProperties = {
  padding: 10
}

const linkStyle: React.CSSProperties = {
  fontSize: 16,
  fontFamily: 'Matter-Medium',
  color: ColorHex.WHITE,
  textDecoration: 'none'
}
