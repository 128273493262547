import { Maybe } from 'graphql/jsutils/Maybe'
import { SettingValueType, useOrganziationSettingsQuery } from 'graphql/types.generated'
import { logger } from 'logger'
import { useEffect, useState } from 'react'
import { Setting } from 'views/merchants/components/MerchantSettings/useSettings'

type TSettingValue = boolean | string | null | undefined

function parseToBoolean(input: string | null | undefined, defaultValue: boolean): boolean {
  if (input) {
    if (input === '1' || input.trim().toLowerCase() === 'true') {
      return true
    }

    if (input === '0' || input.trim().toLowerCase() === 'false') {
      return false
    }
  }

  return defaultValue
}

export type CategoryToSettings = Record<string, Setting[]>

function parseValue(value: Maybe<string>, valueType: SettingValueType): TSettingValue {
  if (valueType === SettingValueType.Boolean) {
    return parseToBoolean(value, false)
  }
  return value
}

export function useSettings(organizationId: string) {
  const [settings, setSettings] = useState<CategoryToSettings>({})

  const { data, loading } = useOrganziationSettingsQuery({ variables: { organizationId }, fetchPolicy: 'cache-first' })

  useEffect(() => {
    logger.debug('useSettings', 'mapping settings data to readable')
    if (data?.organization?.settings?.length) {
      logger.debug('useSettings', 'settings response ' + JSON.stringify(data.organization.settings))

      const _settings: Record<string, Setting[]> = {}
      for (let setting of data.organization.settings) {
        const _curr: Setting[] = _settings[setting.category] || []
        _settings[setting.category] = [
          ..._curr,
          {
            name: setting.name,
            description: setting.description,
            valueType: setting.valueType,
            value: parseValue(setting.value, setting.valueType)
          }
        ]
      }
      setSettings(_settings)
    }
  }, [data])

  return { settings, loading }
}
