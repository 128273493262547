import { Button, Color, Text } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { Maybe, useHasValidSquareOAuthTokenQuery } from 'graphql/types.generated'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { getSquareAuthorizationUrl, SquareSyncVariant, storeSquareRedirectDetails } from './SquareUtils'

interface SquareButtonProps {
  title: string
  organizationId: string
  merchantId: Maybe<string>
  variant: SquareSyncVariant
  style?: React.CSSProperties
}

export const SquareButton = (props: SquareButtonProps) => {
  const { title, organizationId, merchantId, variant, style } = props

  const { data: oAuthTokenData, loading: loadingOAuthTokenData } = useHasValidSquareOAuthTokenQuery({
    variables: { organizationId },
    fetchPolicy: 'cache-first'
  })
  const navigate = useNavigate()

  async function handleClick() {
    if (
      typeof oAuthTokenData?.hasValidSquareOAuthToken !== 'undefined' &&
      oAuthTokenData.hasValidSquareOAuthToken !== null
    ) {
      if (oAuthTokenData.hasValidSquareOAuthToken) {
        if (variant === 'CreateMerchant') {
          navigate(`/organizations/${organizationId}/merchants/importFromSquare`)
        } else if (variant === 'KdsIntegration') {
          navigate(`/organizations/${organizationId}/merchants/${merchantId}/home/kdsIntegrations/square/new`)
        } else if (variant === 'SyncProducts') {
          navigate(`/organizations/${organizationId}/merchants/${merchantId}/menu/products/importFromSquare`)
        }
      } else {
        storeSquareRedirectDetails(organizationId, variant, merchantId)
        window.location.href = getSquareAuthorizationUrl()
      }
    }
  }

  return (
    <div style={style}>
      {loadingOAuthTokenData ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>
            <Text variant='header3'>Loading Square</Text>
          </div>
          <div>
            <CircularProgress />
          </div>
        </div>
      ) : (
        <Button
          style={{ backgroundColor: Color.RDY_BLACK, color: Color.WHITE }}
          label={title}
          onClick={() => handleClick()}
        />
      )}
    </div>
  )
}
