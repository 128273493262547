import { FormHelperText, makeStyles } from '@material-ui/core'
import { useFieldError } from 'hooks/useFieldError'

interface ErrorMessageProps {
  name: string
}

export function ErrorMessage(props: ErrorMessageProps) {
  const { name } = props
  const error = useFieldError<any>({ name })
  const styles = useStyles()

  if (!error || !error.message) {
    return null
  }

  return (
    <FormHelperText className={styles.message} error>
      {error.message}
    </FormHelperText>
  )
}

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: theme.spacing(1)
  }
}))
