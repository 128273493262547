//TODO: This belongs in a shared library

import ImageAsset from 'assets/ImageResource'
import { CategoryType, Maybe, ProductTag, ProductTagType } from 'graphql/types.generated'
import { ModifierInfo, ProductImageInfo, ProductInfo } from 'models/Product'
import { ColorHex } from 'theme/ThemeConstants'

const ESPRESSO_TAG = 'Espresso'
const CORTADO_TAG = 'Cortado'
const CAPPUCCINO_TAG = 'Cappuccino'
const LATTE_TAG = 'Latte'
const FLAVORED_TAG = 'Flavored'
const AMERICANO_TAG = 'Americano'
const FLAT_WHITE_TAG = 'Flat White'
const MACCHIATO_TAG = 'Macchiato'
const MOCHA_TAG = 'Mocha'
const COLD_BREW_TAG = 'Cold Brew'
const DRIP_COFFEE_TAG = 'Drip'
const FILTER_COFFEE_TAG = 'Filter'
const POUROVER_TAG = 'Pour Over'
const MATCHA_TAG = 'Matcha'
const CHAI_TAG = 'Chai'

export interface ProductIconDetails {
  imageAsset?: ImageAssetDetails
  imageUri?: string
  color: ColorHex
}

export interface ImageAssetDetails {
  icon: ImageAsset
  type: 'image' | 'glyph'
}

export function getProductIconFromProduct(product: ProductInfo): ProductIconDetails {
  return getProductIcon(
    product.name,
    product.productTags || [],
    product.modifiers || [],
    product.images || [],
    product.category?.type,
    product.id
  )
}

export function getProductIcon(
  _name: string,
  tags: ProductTag[],
  _modifiers: ModifierInfo[] = [],
  _images: ProductImageInfo[],
  categoryType: Maybe<CategoryType>,
  productId: string
): ProductIconDetails {
  const isCold = false // Lifted from Cappuccino - doesn't matter here
  let imageAsset: ImageAssetDetails, imageUri

  switch (categoryType) {
    case CategoryType.SpecialtyBeverages:
      imageAsset = getSpecialtyIconFromTags(productId, tags, isCold)
      break
    case CategoryType.Coffee:
      imageAsset = getCoffeeIconFromTags(tags, isCold)
      break
    case CategoryType.Tea:
      imageAsset = getTeaIconFromTag(tags, isCold)
      break
    case CategoryType.NotCoffee:
      imageAsset = {
        icon: isCold ? ImageAsset.PRODUCT_GLYPH_DRINK_ICED : ImageAsset.PRODUCT_GLYPH_DRINK,
        type: 'glyph'
      }
      break
    case CategoryType.Food:
      imageAsset = { icon: ImageAsset.PRODUCT_GLYPH_FOOD, type: 'glyph' }
      break
    case CategoryType.RetailMerch:
      imageAsset = { icon: ImageAsset.PRODUCT_GLYPH_RETAIL, type: 'glyph' }
      break
    case CategoryType.RetailBeans:
      imageAsset = { icon: ImageAsset.PRODUCT_GLYPH_RETAIL, type: 'glyph' }
      break
    case CategoryType.KitchenFood:
      imageAsset = { icon: ImageAsset.PRODUCT_GLYPH_FOOD, type: 'glyph' }
      break
    default:
      imageAsset = { icon: ImageAsset.PRODUCT_IMAGE_COFFEE, type: 'image' }
  }

  return {
    imageAsset,
    imageUri,
    color: isCold ? ColorHex.RDY_MALIBU : ColorHex.RDY_PINK
  }
}

function getSpecialtyIconFromTags(productId: string, tags: ProductTag[], isCold: boolean): ImageAssetDetails {
  let icon = isCold ? ImageAsset.PRODUCT_GLYPH_DRINK_ICED : ImageAsset.PRODUCT_GLYPH_DRINK
  let type: 'image' | 'glyph' = 'glyph'
  let dynamicIconIndex

  const espressoDynamicImages = [
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_ESPRESSO_1,
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_ESPRESSO_3
  ]

  const latteDynamicImages = [
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_LATTE_2,
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_LATTE_ALT,
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_MILK_STEAM
  ]

  tags
    .filter((t) => t.value !== FLAVORED_TAG && t.tagType === ProductTagType.Coffee)
    .forEach((ct) => {
      switch (ct.value) {
        case ESPRESSO_TAG:
          dynamicIconIndex = parseInt(productId) % espressoDynamicImages.length
          icon = espressoDynamicImages[dynamicIconIndex]
          type = 'image'
          break
        case LATTE_TAG:
          const allDynamicImages = [...espressoDynamicImages, ...latteDynamicImages]
          dynamicIconIndex = parseInt(productId) % allDynamicImages.length
          icon = allDynamicImages[dynamicIconIndex]
          type = 'image'
          break
      }
    })

  if (tags.map((t) => t.value).includes(ESPRESSO_TAG)) {
    const iconIndex = parseInt(productId) % espressoDynamicImages.length
    return { icon: espressoDynamicImages[iconIndex], type: 'image' }
  }

  const allDynamicImages = [...espressoDynamicImages, ...latteDynamicImages]
  const iconIndex = parseInt(productId) % allDynamicImages.length
  icon = allDynamicImages[iconIndex]
  type = 'image'

  return { icon, type }
}

function getCoffeeIconFromTags(tags: ProductTag[], isCold: boolean): ImageAssetDetails {
  let icon = isCold ? ImageAsset.PRODUCT_GLYPH_DRINK_ICED : ImageAsset.PRODUCT_GLYPH_DRINK
  let type: 'image' | 'glyph' = 'glyph'

  tags
    .filter((t) => t.value !== FLAVORED_TAG && t.tagType === ProductTagType.Coffee)
    .forEach((ct) => {
      switch (ct.value) {
        case ESPRESSO_TAG:
          icon = isCold ? ImageAsset.PRODUCT_IMAGE_ESPRESSO_ICED : ImageAsset.PRODUCT_IMAGE_ESPRESSO
          type = 'image'
          break
        case CORTADO_TAG:
          icon = ImageAsset.PRODUCT_IMAGE_CORTADO
          type = 'image'
          break
        case LATTE_TAG:
          icon = isCold ? ImageAsset.PRODUCT_IMAGE_LATTE_ICED : ImageAsset.PRODUCT_IMAGE_LATTE
          type = 'image'
          break
        case CAPPUCCINO_TAG:
          icon = ImageAsset.PRODUCT_IMAGE_CAPPUCCINO
          type = 'image'
          break
        case AMERICANO_TAG:
          icon = isCold ? ImageAsset.PRODUCT_IMAGE_AMERICANO_ICED : ImageAsset.PRODUCT_IMAGE_AMERICANO
          type = 'image'
          break
        case FLAT_WHITE_TAG:
          icon = ImageAsset.PRODUCT_IMAGE_FLAT_WHITE
          type = 'image'
          break
        case MACCHIATO_TAG:
          icon = ImageAsset.PRODUCT_IMAGE_MACCHIATO
          type = 'image'
          break
        case MOCHA_TAG:
          icon = isCold ? ImageAsset.PRODUCT_IMAGE_LATTE_ICED : ImageAsset.PRODUCT_IMAGE_MOCHA
          type = 'image'
          break
        case COLD_BREW_TAG:
          icon = ImageAsset.PRODUCT_IMAGE_COLD_BREW
          type = 'image'
          break
        case FILTER_COFFEE_TAG:
        case DRIP_COFFEE_TAG:
          icon = isCold ? ImageAsset.PRODUCT_IMAGE_COFFEE_ICED : ImageAsset.PRODUCT_IMAGE_COFFEE
          type = 'image'
          break
        case POUROVER_TAG:
          icon = isCold ? ImageAsset.PRODUCT_IMAGE_COFFEE_ICED : ImageAsset.PRODUCT_IMAGE_POUROVER
          type = 'image'
          break
      }
    })
  return { icon, type }
}

function getTeaIconFromTag(tags: ProductTag[], isCold: boolean): ImageAssetDetails {
  let icon = isCold ? ImageAsset.PRODUCT_GLYPH_TEA_ICED : ImageAsset.PRODUCT_GLYPH_TEA
  let type: 'image' | 'glyph' = 'glyph'

  tags
    .filter((t) => t.value !== FLAVORED_TAG && t.tagType === ProductTagType.Tea)
    .forEach((ct) => {
      switch (ct.value) {
        case MATCHA_TAG:
          icon = isCold ? ImageAsset.PRODUCT_IMAGE_MATCHA_ICED : ImageAsset.PRODUCT_IMAGE_MATCHA
          type = 'image'
          break
        case CHAI_TAG:
          icon = isCold ? ImageAsset.PRODUCT_IMAGE_CHAI_ICED : ImageAsset.PRODUCT_IMAGE_CHAI
          type = 'image'
          break
      }
    })
  return { icon, type }
}
