import { ApolloCache, FetchResult } from '@apollo/client'
import { MerchantPromotionFormModel } from 'views/merchants/components/MerchantPromotions/CreateMerchantPromotion/components/merchantPromotionValidator'

import {
  CreateMerchantPromotionMutation,
  MerchantPromotionInfoFragmentDoc,
  PromotionCostOwner,
  PromotionType,
  useCreateMerchantPromotionMutation
} from '../../graphql/types.generated'
import { useToast } from '../useToast'

export function useCreateMerchantPromotion() {
  const toast = useToast()
  const [execute, { loading }] = useCreateMerchantPromotionMutation({ update: updateCache })

  function updateCache(
    cache: ApolloCache<CreateMerchantPromotionMutation>,
    mutationResult: FetchResult<CreateMerchantPromotionMutation>
  ) {
    const newPromotion = mutationResult.data?.createMerchantPromotion
    if (newPromotion) {
      cache.modify({
        fields: {
          promotionsByMerchant(existingPromotions = []) {
            const newPromotionRef = cache.writeFragment({
              data: newPromotion,
              fragment: MerchantPromotionInfoFragmentDoc,
              fragmentName: 'MerchantPromotionInfo'
            })
            return [...existingPromotions, newPromotionRef]
          }
        }
      })
    }
  }

  async function create(merchantId: ID, input: MerchantPromotionFormModel, onSuccess: () => void) {
    try {
      const start = new Date(input.start).getTime()
      const end = input.end ? new Date(input.end).getTime() : undefined
      const notifyUsersOn = input.notifyUsersOn ? new Date(input.notifyUsersOn).getTime() : null
      const res = await execute({
        variables: {
          input: {
            ...input,
            start,
            end,
            notifyUsersOn,
            costOwner: PromotionCostOwner.Merchant,
            type: PromotionType.Discount,
            frequency: undefined,
            tokenType: undefined,
            recurring: false,
            numberOfTokens: undefined,
            perUserPeriodSeconds: undefined, // for now
            merchantIds: [merchantId],
            dispenseRewardOnEntry: undefined,
            productTags: (input.productTags?.filter((pt) => !!pt) ?? []) as string[]
          }
        }
      })
      if (res.data?.createMerchantPromotion) {
        toast.success(`Promotion created: ${res.data.createMerchantPromotion.customerFacingName}`)
        onSuccess()
      }
    } catch (e) {
      toast.error(`Could not create promotion ${e}`)
    }
  }

  return { create, loading }
}
