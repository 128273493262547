import { useArchiveMerchantMutation, ViewOrganizationDocument } from '../graphql/types.generated'
import { useToast } from './useToast'

export function useArchiveMerchant() {
  const toast = useToast()
  const [execute, { loading }] = useArchiveMerchantMutation()

  async function archive(id: string, organizationId: string, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { id },
        refetchQueries: [
          {
            query: ViewOrganizationDocument,
            variables: {
              id: organizationId
            }
          }
        ]
      })

      if (res.data?.archiveMerchant) {
        onSuccess()
      } else {
        throw new Error(`Could not archive merchant: ${res.errors}`)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  return { archive, loading }
}
