import { RoleMatcher, usePermissions } from 'hooks/usePermissions/usePermissions'
import React, { PropsWithChildren } from 'react'

interface HasAnyRoleProps {
  roleMatchers: RoleMatcher[]
  fallback?: React.ReactNode
}

export const HasAnyRole = (props: PropsWithChildren<HasAnyRoleProps>) => {
  const { children, fallback, roleMatchers } = props
  const { hasAnyRole } = usePermissions()

  if (hasAnyRole(...roleMatchers)) {
    return <>{children}</>
  }

  return fallback ? <>{fallback}</> : null
}
