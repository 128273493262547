import { HeaderMenuBar } from './HeaderMenuBar'
import { HeaderTitleBar } from './HeaderTitleBar'
import { UserTitle } from './UserTitle'

export function AdminHeader() {
  return (
    <div>
      <HeaderTitleBar title={'Admin'} subTitle={<UserTitle />} />
      <HeaderMenuBar
        menuButtonDetails={[
          {
            link: `/admin/organizations`,
            paths: ['/admin/organizations/*'],
            text: 'Organizations'
          },
          {
            link: `/admin/promotions`,
            paths: ['/admin/promotions/*'],
            text: 'Promotions'
          },
          {
            link: `/admin/notifications`,
            paths: ['/admin/notifications/*'],
            text: 'Notifications'
          },
          {
            link: `/admin/merchantFees`,
            paths: ['/admin/merchantFees/*'],
            text: 'Merchant Fees'
          },
          {
            link: '/admin/requisitions',
            paths: ['/admin/requisitions/*'],
            text: 'Beans'
          }
        ]}
      />
    </div>
  )
}
