import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { TextDisplay } from 'components/layout/TextDisplay'
import {
  Maybe,
  SubscriptionStatus,
  useCancelStripeSubscriptionMutation,
  useMerchantInvoicesQuery,
  useMerchantSubscriptionFeesByMerchantIdQuery,
  useMerchantSubscriptionsByOrganizationIdQuery,
  useUpdateMerchantsOnOrgSubscriptionMutation
} from 'graphql/types.generated'
import { useCafeValidationStatus } from 'hooks/useCafeValidationStatus/useCafeValidationStatus'
import { useCreatePaymentIntent } from 'hooks/useCreatePaymentIntent/useCreatePaymentIntent'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import { SubscriptionPaymentIntentInfo } from 'models/Subscriptions'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { readableDateFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { formatSubscriptionPlanCost, getSubscriptionStatusFriendlyName } from 'util/subscriptions'
import { ActivationPayment } from 'views/admin/subscriptions/StripeCheckoutForm/ActivationPayment'
import { SubscriptionPayment } from 'views/admin/subscriptions/StripeCheckoutForm/SubscriptionPayment'
import { OrganizationSubscriptionPayment } from 'views/organizations/components/MerchantSubscriptions/OrganizationSubscriptionPayment'

import { MerchantPlatformPricing } from '../PlatformPricing/MerchantPlatformPricing'
import { SetUpSubscription } from '../SetUpSubscription/SetUpSubscription'
import { SubscriptionInvoices } from '../SubscriptionInvoices/SubscriptionInvoices'

interface MerchantSubscriptionProps {
  merchantId: ID
}

export function MerchantSubscriptionPayment(props: MerchantSubscriptionProps) {
  const { merchantId } = props
  const { organizationId } = useParams()
  const toast = useToast()
  const styles = useStyles()
  const { updateValidation } = useCafeValidationStatus(merchantId, organizationId!!)

  const [subscriptionPaymentIntent, setSubscriptionPaymentIntent] = useState<Maybe<SubscriptionPaymentIntentInfo>>(null)
  const [awaitingPayment, setAwaitingPayment] = useState(false)
  const [updatingPayment, setUpdatingPayment] = useState(false)
  const [savingSubscription, setSavingSubscription] = useState(false)
  const [setupQuestionAsked, setSetupQuestionAsked] = useState(false)
  const [creatingOrgSub, setCreatingOrgSub] = useState(false)
  const [updatingPlatformPricing, setUpdatingPlatformPricing] = useState(false)

  const { data: feeData, refetch: refetchFees } = useMerchantSubscriptionFeesByMerchantIdQuery({
    variables: {
      merchantId: merchantId
    }
  })
  const { data: invoiceData } = useMerchantInvoicesQuery({
    variables: {
      merchantId: merchantId
    }
  })

  const {
    data: orgSubscriptions,
    loading: loadingSubscriptions,
    refetch: refetchSubscriptions
  } = useMerchantSubscriptionsByOrganizationIdQuery({
    variables: {
      organizationId: organizationId!
    }
  })
  const [updateSubscription, { loading: loadingUpdateSubscription }] = useUpdateMerchantsOnOrgSubscriptionMutation()
  const [cancelSubscription, { loading: loadingCancelSubscription }] = useCancelStripeSubscriptionMutation()
  const [shouldCreatePaymentIntent, setShouldCreatePaymentIntent] = useState(false)

  const merchantSubscriptions = orgSubscriptions?.merchantSubscriptionsByOrganizationId ?? []
  const organizationMerchantSubscriptions =
    merchantSubscriptions.filter((merchantSubscription) => merchantSubscription.organizationManaged) ?? []

  const merchantSubscription = merchantSubscriptions.find((ms) => ms.merchant.id === merchantId)
  const isOrgManagedSubscription = !!merchantSubscription?.organizationManaged

  const { createPaymentIntent } = useCreatePaymentIntent({
    organizationId: organizationId!,
    organizationManaged: false,
    onError: (error) => toast.error(error),
    onComplete: (value) => {
      setSubscriptionPaymentIntent(value ?? null)
      setAwaitingPayment(true)
      setSavingSubscription(false)
    },
    onStartSave: () => {
      setSetupQuestionAsked(true)
      setSavingSubscription(true)
    }
  })

  useEffect(() => {
    if (
      shouldCreatePaymentIntent &&
      !!merchantSubscription &&
      merchantSubscription.subscriptionStatus !== SubscriptionStatus.Complete &&
      merchantSubscription.subscriptionStatus !== SubscriptionStatus.Current
    ) {
      setShouldCreatePaymentIntent(false)
      createPaymentIntent([merchantSubscription])
    }
  }, [shouldCreatePaymentIntent, createPaymentIntent, merchantSubscription])

  function allowPaymentUpdate() {
    return (
      !!merchantSubscription?.stripeSubscription &&
      merchantSubscription?.subscriptionStatus !== SubscriptionStatus.PendingPayment &&
      merchantSubscription?.subscriptionStatus !== SubscriptionStatus.Cancelled &&
      merchantSubscription?.subscriptionStatus !== SubscriptionStatus.Complete
    )
  }

  async function onCancelSub() {
    try {
      await cancelSubscription({
        variables: {
          stripeSubscriptionId: merchantSubscription!!.stripeSubscription!!.id
        }
      })
      toast.success('Subscription Canceled')
      setSetupQuestionAsked(false)
      refetchSubscriptions()
      refetchFees()
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  async function addMerchantToOrgSubscription() {
    let merchantIds = merchantSubscriptions
      .filter(
        (ms) =>
          merchantId === ms.merchant.id ||
          !!organizationMerchantSubscriptions.find((oms) => oms.organizationManaged && oms.id === ms.id)
      )
      .map((ms) => ms.merchant.id)

    try {
      await updateSubscription({
        variables: {
          merchantIds
        }
      })

      setSetupQuestionAsked(false)
      toast.success('Added Cafe to the Existing Subscription')
      refetchSubscriptions()
      refetchFees()
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  function canUpdatePlatformPricing() {
    const onFreePlan =
      merchantSubscription?.subscriptionStatus === 'COMPLETE' &&
      merchantSubscription.termPricing === false &&
      merchantSubscription?.platformPricing?.instantPriceUsCents === 0

    return (
      !!merchantSubscription &&
      !!merchantSubscription.platformPricing &&
      !!merchantSubscription.subscriptionStatus &&
      !updatingPlatformPricing &&
      (['CANCELLED', 'PENDING_PAYMENT'].indexOf(merchantSubscription.subscriptionStatus) >= 0 || onFreePlan)
    )
  }

  async function pricingSelected() {
    setUpdatingPlatformPricing(false)
    await refetchSubscriptions()
    updateValidation()
    setShouldCreatePaymentIntent(true)
  }

  if (loadingSubscriptions) {
    return <CircularProgress />
  }

  if (isOrgManagedSubscription || creatingOrgSub) {
    return <OrganizationSubscriptionPayment organizationId={organizationId!} />
  }

  if ((!loadingSubscriptions && !merchantSubscription) || updatingPlatformPricing) {
    return (
      <MerchantPlatformPricing merchantId={merchantId} organizationId={organizationId!!} onComplete={pricingSelected} />
    )
  }
  return (
    <>
      {!isOrgManagedSubscription && !!merchantSubscription && (
        <>
          <Alert
            severity={
              merchantSubscription?.subscriptionStatus === SubscriptionStatus.Current ||
              merchantSubscription?.subscriptionStatus === SubscriptionStatus.Complete
                ? 'success'
                : 'warning'
            }
            className={styles.alert}
          >
            <div>
              <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
                <TextDisplay titleVariant='subtitle2' title='Plan:'>
                  {merchantSubscription.subscriptionPlan?.name ?? merchantSubscription.platformPricing?.name}
                </TextDisplay>
                <TextDisplay titleVariant='subtitle2' title='Cost:'>
                  {merchantSubscription.subscriptionPlan &&
                    formatSubscriptionPlanCost(merchantSubscription.subscriptionPlan)}

                  {!merchantSubscription.termPricing &&
                    !!merchantSubscription.platformPricing &&
                    formatUsCentsAsUsDollar(merchantSubscription.platformPricing.instantPriceUsCents)}
                </TextDisplay>
                <TextDisplay titleVariant='subtitle2' title='Subscription Status:'>
                  {getSubscriptionStatusFriendlyName(merchantSubscription.subscriptionStatus)}
                </TextDisplay>
                {!!merchantSubscription.trialEndDate && merchantSubscription.trialEndDate > Date.now() && (
                  <TextDisplay titleVariant='subtitle2' title='Billing Start Date:'>
                    {readableDateFromInstant(merchantSubscription.trialEndDate!!)}
                  </TextDisplay>
                )}
                {!!feeData && feeData.merchantSubscriptionFeesByMerchantId.length > 0 && (
                  <TextDisplay titleVariant='subtitle2' title='Pending Fees:'>
                    <div style={{ flexDirection: 'column', display: 'flex', flexWrap: 'wrap' }}>
                      {feeData.merchantSubscriptionFeesByMerchantId.map((fee) => (
                        <div key={fee.id}>{`${fee.subscriptionFee.name} - ${formatUsCentsAsUsDollar(
                          fee.subscriptionFee.costUsCents
                        )}`}</div>
                      ))}
                    </div>
                  </TextDisplay>
                )}
              </div>
              {merchantSubscription?.stripeSubscription?.subscriptionStatus === SubscriptionStatus.PastDue && (
                <div color='Red'>The saved payment method will be retried at the next retry interval.</div>
              )}

              {!setupQuestionAsked &&
                !merchantSubscription?.stripeSubscription &&
                !merchantSubscription.platformPricing &&
                !awaitingPayment && (
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='subtitle2'>
                        If you'd like to use a single credit card for all cafes, you can use an Organization
                        Subscription
                      </Typography>
                      <div>
                        {!!organizationMerchantSubscriptions?.length && (
                          <Button
                            label='Add to existing organization subscription'
                            onClick={() => {
                              setSetupQuestionAsked(true)
                              addMerchantToOrgSubscription()
                            }}
                          />
                        )}
                        {!organizationMerchantSubscriptions?.length && (
                          <Button
                            label='Set up organization subscription'
                            onClick={() => {
                              setSetupQuestionAsked(true)
                              setCreatingOrgSub(true)
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='subtitle2'>
                        If you'd like to use a different credit card each cafe, you can set up a Cafe Specific
                        Subscription
                      </Typography>
                      <div>
                        <SetUpSubscription
                          organizationId={organizationId!!}
                          merchantSubscriptions={[merchantSubscription]}
                          onError={(error) => toast.error(error)}
                          organizationManaged={false}
                          onComplete={(value) => {
                            setSubscriptionPaymentIntent(value ?? null)
                            setAwaitingPayment(true)
                            setSavingSubscription(false)
                          }}
                          title={'Set up Cafe Specific Subscription'}
                          onStartSave={() => {
                            setSetupQuestionAsked(true)
                            setSavingSubscription(true)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              <div style={containerStyle}>
                {allowPaymentUpdate() && !updatingPayment && (
                  <Button style={buttonStyle} label='Update Payment Method' onClick={() => setUpdatingPayment(true)} />
                )}
                {canUpdatePlatformPricing() && (
                  <Button style={buttonStyle} label='Update Plan' onClick={() => setUpdatingPlatformPricing(true)} />
                )}

                {(savingSubscription || loadingUpdateSubscription) && <CircularProgress />}

                {!!merchantSubscription?.platformPricing &&
                  merchantSubscription?.subscriptionStatus === SubscriptionStatus.PendingPayment &&
                  !awaitingPayment && (
                    <SetUpSubscription
                      style={buttonStyle}
                      organizationId={organizationId!!}
                      onError={(error) => toast.error(error)}
                      organizationManaged={false}
                      onComplete={(value) => {
                        setSubscriptionPaymentIntent(value ?? null)
                        setAwaitingPayment(true)
                      }}
                      merchantSubscriptions={[merchantSubscription]}
                    />
                  )}
                {merchantSubscription?.stripeSubscription &&
                  merchantSubscription?.subscriptionStatus !== SubscriptionStatus.Complete && (
                    <HasAnyRole roleMatchers={[roles.admin]}>
                      <Button
                        onClick={onCancelSub}
                        style={buttonStyle}
                        theme='warning'
                        label='Cancel Subscription (admin only)'
                        endIcon={loadingCancelSubscription && <CircularProgress color='inherit' size={20} />}
                      />
                    </HasAnyRole>
                  )}
              </div>
            </div>
          </Alert>
          {!merchantSubscription.termPricing && merchantSubscription.platformPricing != null && (
            <ActivationPayment
              merchantIds={[merchantId]}
              organizationId={organizationId!!}
              awaitingPayment={awaitingPayment}
              onComplete={() => {
                refetchSubscriptions()
                refetchFees()
                updateValidation()
                setSubscriptionPaymentIntent(null)
                setAwaitingPayment(false)
                setUpdatingPayment(false)
              }}
              onCancel={() => {
                setSubscriptionPaymentIntent(null)
                setAwaitingPayment(false)
                setUpdatingPayment(false)
              }}
              subscriptionPaymentIntent={subscriptionPaymentIntent}
            />
          )}
          {(merchantSubscription.termPricing === true || merchantSubscription.platformPricing == null) && (
            <SubscriptionPayment
              stripeSubscription={merchantSubscription.stripeSubscription}
              awaitingPayment={awaitingPayment}
              onComplete={() => {
                refetchSubscriptions()
                refetchFees()
                setSubscriptionPaymentIntent(null)
                setAwaitingPayment(false)
                setUpdatingPayment(false)
              }}
              onCancel={() => {
                setSubscriptionPaymentIntent(null)
                setAwaitingPayment(false)
                setUpdatingPayment(false)
              }}
              updatingPayment={updatingPayment}
              trialEndDate={merchantSubscription.trialEndDate}
              subscriptionPaymentIntent={subscriptionPaymentIntent}
            />
          )}
          {!!invoiceData && <SubscriptionInvoices subscriptionInvoices={invoiceData.merchantInvoices} />}
        </>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: 20
  },
  alert: {
    marginBottom: 20
  }
}))

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const buttonStyle: React.CSSProperties = {
  margin: 5,
  display: 'flex',
  flexDirection: 'row'
}
