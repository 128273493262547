export default class ImageAsset {
  public static PRODUCT_IMAGE_COFFEE = require('./img/products/hot/coffee.jpg')
  public static PRODUCT_IMAGE_POUROVER = require('./img/products/hot/pourover.jpg')
  public static PRODUCT_IMAGE_AMERICANO = require('./img/products/hot/americano.jpg')
  public static PRODUCT_IMAGE_MACCHIATO = require('./img/products/hot/macchiato.jpg')
  public static PRODUCT_IMAGE_CAPPUCCINO = require('./img/products/hot/cappuccino.jpg')
  public static PRODUCT_IMAGE_FLAT_WHITE = require('./img/products/hot/flat_white.jpg')
  public static PRODUCT_IMAGE_MOCHA = require('./img/products/hot/mocha.jpg')
  public static PRODUCT_IMAGE_CORTADO = require('./img/products/hot/cortado.jpg')
  public static PRODUCT_IMAGE_ESPRESSO = require('./img/products/hot/espresso.jpg')
  public static PRODUCT_IMAGE_LATTE = require('./img/products/hot/latte.jpg')
  public static PRODUCT_IMAGE_MATCHA = require('./img/products/hot/matcha_latte.jpg')
  public static PRODUCT_IMAGE_CHAI = require('./img/products/hot/chai_latte.jpg')

  public static PRODUCT_IMAGE_COFFEE_ICED = require('./img/products/iced/coffee.jpg')
  public static PRODUCT_IMAGE_AMERICANO_ICED = require('./img/products/iced/americano.jpg')
  public static PRODUCT_IMAGE_ESPRESSO_ICED = require('./img/products/iced/espresso.jpg')
  public static PRODUCT_IMAGE_LATTE_ICED = require('./img/products/iced/latte.jpg')
  public static PRODUCT_IMAGE_COLD_BREW = require('./img/products/iced/nitro_cold_brew.jpg')
  public static PRODUCT_IMAGE_MATCHA_ICED = require('./img/products/iced/matcha_latte.jpg')
  public static PRODUCT_IMAGE_CHAI_ICED = require('./img/products/iced/chai_latte.jpg')

  public static PRODUCT_IMAGE_DYNAMIC_ESPRESSO_1 = require('./img/products/dynamic/espresso_pour_1.jpg')
  public static PRODUCT_IMAGE_DYNAMIC_ESPRESSO_3 = require('./img/products/dynamic/espresso_pour_3.jpg')
  public static PRODUCT_IMAGE_DYNAMIC_LATTE_2 = require('./img/products/dynamic/latte_art_pour_2.jpg')
  public static PRODUCT_IMAGE_DYNAMIC_LATTE_ALT = require('./img/products/dynamic/latte_art_pour_alt.jpg')
  public static PRODUCT_IMAGE_DYNAMIC_MILK_STEAM = require('./img/products/dynamic/milk_steam.jpg')

  public static PRODUCT_GLYPH_TEA = require('./img/glyphs/tea.png')
  public static PRODUCT_GLYPH_DRINK = require('./img/glyphs/coffee.png')
  public static PRODUCT_GLYPH_TEA_ICED = require('./img/glyphs/tea_iced.png')
  public static PRODUCT_GLYPH_DRINK_ICED = require('./img/glyphs/coffee_iced.png')
  public static PRODUCT_GLYPH_FOOD = require('./img/glyphs/food_bag.png')
  public static PRODUCT_GLYPH_RETAIL = require('./img/glyphs/retail.png')
  public static RDY_HOME = require('./svg/glyphs/RDY_home_glyph.svg')
  public static RDY_LOGO_PRIMARY = require('./svg/glyphs/RDY_logo_primary.svg')

  public static HERO_RDY_PASS_1 = require('./img/heroImages/RDYPass.jpg')
  public static HERO_RDY_PASS_2 = require('./img/heroImages/BackporchRoastersRDYPass.jpg')
  public static HERO_RDY_PASS_3 = require('./img/heroImages/GrindersRDY.jpg')
}
