import { useCreateSubscriptionFeeMutation } from 'graphql/types.generated'
import { SubscriptionFeeFormModel } from 'views/admin/subscriptions/components/subscriptionFeeValidator'

import { useToast } from '../useToast'

export function useCreateSubscriptionFee() {
  const toast = useToast()
  const [execute, { loading }] = useCreateSubscriptionFeeMutation()

  async function create(input: SubscriptionFeeFormModel, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { input }
      })
      if (res.data?.createSubscriptionFee) {
        toast.success(`Subscription Fee created: ${res.data.createSubscriptionFee.name}`)
        onSuccess()
      }
    } catch (e) {
      toast.error(`Could not create subscription fee ${e}`)
    }
  }

  return { create, loading }
}
