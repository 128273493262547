import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import clsx from 'clsx'
import { TextDisplay } from 'components/layout/TextDisplay'
import {
  useAddMerchantSubscriptionFeeMutation,
  useMerchantSubscriptionFeesByMerchantIdQuery,
  useSubscriptionFeesQuery
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { formatUsCentsAsUsDollar } from 'util/formatting'

interface AddMerchantSubscriptionFeeProps {
  merchantId: string
}

export const AddMerchantSubscriptionFee = (props: AddMerchantSubscriptionFeeProps) => {
  const { merchantId } = props
  const styles = useStyles()
  const toast = useToast()
  const [subscriptionFeeId, setSubscriptionFeeId] = useState<string | null>(null)
  const { data: subscriptionFeesData, loading: loadingSubscriptionPlans } = useSubscriptionFeesQuery()
  const [addMerchantSubscriptionFee, { loading: loadingSetMerchantSubPlan }] = useAddMerchantSubscriptionFeeMutation()
  const { data: feeData, refetch } = useMerchantSubscriptionFeesByMerchantIdQuery({
    variables: {
      merchantId: merchantId
    }
  })

  async function assignSubscriptionFee() {
    if (!subscriptionFeeId) {
      toast.error('Invalid Subscription Fee')
      return
    }

    try {
      await addMerchantSubscriptionFee({
        variables: {
          input: {
            subscriptionProductId: subscriptionFeeId,
            merchantId: merchantId
          }
        }
      })
      toast.success('Added Fee')
      refetch()
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  return (
    <Section title='Add Subscription Fee'>
      {(loadingSubscriptionPlans || loadingSetMerchantSubPlan) && <CircularProgress />}
      {!!subscriptionFeesData && (
        <div style={{ flexDirection: 'column', display: 'flex', alignContent: 'left' }}>
          <div style={{ flexDirection: 'column', display: 'flex', alignContent: 'left', paddingLeft: 1 }}>
            <InputLabel>One Time Subscription Fee</InputLabel>
            <Select
              className={clsx(styles.dropdown)}
              fullWidth={false}
              label='Subscription Fee'
              onChange={(e) => setSubscriptionFeeId(e.target.value as string)}
            >
              <MenuItem value=''>
                <em>Select a One Time Subscription Fee</em>
              </MenuItem>
              {subscriptionFeesData.subscriptionFees.map((plan) => {
                return (
                  <MenuItem key={plan.id} value={plan.id}>{`${plan.name} - ${formatUsCentsAsUsDollar(
                    plan.costUsCents
                  )}`}</MenuItem>
                )
              })}
            </Select>
          </div>
          <div style={{ paddingTop: 5 }}>
            <Button onClick={assignSubscriptionFee} disabled={!subscriptionFeeId} label='Add Fee' />
          </div>
        </div>
      )}
      {!!feeData?.merchantSubscriptionFeesByMerchantId && feeData.merchantSubscriptionFeesByMerchantId.length > 0 && (
        <TextDisplay title='Unpaid Fees' titleVariant='subtitle2'>
          {feeData.merchantSubscriptionFeesByMerchantId.map((fee) => (
            <div>{`${fee.subscriptionFee!!.name} - ${formatUsCentsAsUsDollar(
              fee.subscriptionFee!!.costUsCents!!
            )}`}</div>
          ))}
        </TextDisplay>
      )}
    </Section>
  )
}
const useStyles = makeStyles((theme) => ({
  dropdown: { maxWidth: 300 }
}))
