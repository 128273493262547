import { useCreateMerchantFeeConfigMutation } from 'graphql/types.generated'
import { MerchantFeeConfigFormModel } from 'views/admin/merchantFeeConfig/components/merchantFeeConfigValidator'

import { useToast } from '../useToast'

export function useCreateMerchantFeeConfig() {
  const toast = useToast()
  const [execute, { loading }] = useCreateMerchantFeeConfigMutation()

  async function create(input: MerchantFeeConfigFormModel, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { input }
      })
      if (res.data?.createMerchantFeeConfig) {
        toast.success(`Merchant Fee Config Created`)
        onSuccess()
      }
    } catch (e) {
      toast.error(`Could not create merchant fee config ${e}`)
    }
  }

  return { create, loading }
}
