import { Section } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSendPushToUsersMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { SendPushForm } from '../SendPushForm'
import { UserPushFormModel, userPushSchema } from './components/sendUserPushValidator'

export const SendUserPush = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [execute, { loading }] = useSendPushToUsersMutation()

  async function handleSubmit(form: UserPushFormModel) {
    try {
      await execute({
        variables: {
          input: {
            messageType: form.messageType,
            userIds: form.userIds ? form.userIds?.split(',').map((e: string) => e.trim()) : undefined,
            content: {
              title: form.title,
              body: form.body,
              analyticsTag: form.analyticsTag,
              appAction: form.appAction || undefined
            }
          }
        }
      })
      toast.success(`Successfully sent ${form.body}`)
    } catch (e) {
      toast.error(e.toString())
    }
  }

  const formProps = useForm<UserPushFormModel>({
    resolver: yupResolver(userPushSchema),
    shouldUnregister: false
  })

  return (
    <Section title='Send Push'>
      <SendPushForm
        formProps={formProps}
        type='user'
        onCancel={() => navigate('/admin')}
        onSubmit={handleSubmit}
        disableSubmit={loading}
      />
    </Section>
  )
}
