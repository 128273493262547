import { FieldErrors, get, useFormContext } from 'react-hook-form'

interface UseFieldErrorOptions {
  name: string
}

export function useFieldError<T>(options: UseFieldErrorOptions): FieldErrors<T> | undefined {
  const { name } = options
  const methods = useFormContext()
  return get(methods.errors, name)
}
