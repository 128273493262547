import { ApolloCache, FetchResult } from '@apollo/client'
import { Text } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import {
  GenerateSquareOAuthTokenMutation,
  HasValidSquareOAuthTokenDocument,
  HasValidSquareOAuthTokenQuery,
  useGenerateSquareOAuthTokenMutation
} from 'graphql/types.generated'
import { logger } from 'logger'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { SquareSyncContainer } from './SquareSyncContainer'
import { fetchSquareRedirectDetails } from './SquareUtils'

export const SquareLoginRedirect = () => {
  const [searchParams] = useSearchParams()
  const data = fetchSquareRedirectDetails()

  const [
    generateOAuthToken,
    { data: oAuthTokenData, loading: generatingOAuthToken, error: generateOAuthTokenError }
  ] = useGenerateSquareOAuthTokenMutation()

  useEffect(() => {
    const code = searchParams.get('code')
    if (
      !!code &&
      !generatingOAuthToken &&
      !generateOAuthTokenError?.message &&
      (typeof oAuthTokenData?.generateSquareOAuthToken === 'undefined' ||
        oAuthTokenData.generateSquareOAuthToken === null)
    ) {
      logger.debug('SquareLoginRedirect', 'Generating Square OAuth Token')
      generateOAuthToken({ variables: { code }, update: updateOAuthTokenCache })
    }
  }, [searchParams, generateOAuthToken, oAuthTokenData, generatingOAuthToken, generateOAuthTokenError])

  function updateOAuthTokenCache(
    cache: ApolloCache<GenerateSquareOAuthTokenMutation>,
    mutationResult: FetchResult<GenerateSquareOAuthTokenMutation>
  ) {
    const result = mutationResult.data?.generateSquareOAuthToken
    if (result === true) {
      cache.writeQuery<HasValidSquareOAuthTokenQuery>({
        query: HasValidSquareOAuthTokenDocument,
        data: { hasValidSquareOAuthToken: result }
      })
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 36 }}>
      {generatingOAuthToken && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>
            <Text variant='header3'>Validating Square Login</Text>
          </div>
          <div>
            <CircularProgress />
          </div>
        </div>
      )}

      {!!generateOAuthTokenError?.message && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>
            <Text variant='error'>{`${generateOAuthTokenError.message}. Please log in again.`}</Text>
          </div>
        </div>
      )}

      {oAuthTokenData?.generateSquareOAuthToken === true && (
        <SquareSyncContainer
          organizationId={data['organizationId']}
          merchantId={data['merchantId']}
          variant={data['variant']}
        />
      )}
    </div>
  )
}
