import { OrderTransactionRefundInfo } from 'models/Order'
import React from 'react'
import { readableDateFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'

export const RefundTransactionDetails = (props: { refund: OrderTransactionRefundInfo }) => {
  const { refund } = props

  return (
    <div>
      {refund.amountUsCents > 0 && (
        <div key={`refund.${refund.id}`}>{`${formatUsCentsAsUsDollar(
          refund.amountUsCents
        )} refunded on ${readableDateFromInstant(refund.initializedOn)}`}</div>
      )}

      {refund.tokenValue > 0 && (
        <div key={`refund.${refund.id}`}>{`${refund.tokenValue} token(s) refunded on ${readableDateFromInstant(
          refund.initializedOn
        )}`}</div>
      )}
    </div>
  )
}
