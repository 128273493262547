import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core'
import { useFieldError } from 'hooks/useFieldError'
import { useController } from 'react-hook-form'

type DecimalFieldProps = MuiTextFieldProps & {
  name: string
}

export function DecimalField(props: DecimalFieldProps) {
  const { name, defaultValue = 0, ...rest } = props
  const error = useFieldError<any>({ name })

  const {
    //name causes a bug on defaultValue
    field: { name: excludedName, value, onChange, ...fieldProps }
  } = useController({
    name,
    defaultValue
  })

  return (
    <MuiTextField
      {...rest}
      {...fieldProps}
      error={!!error}
      helperText={error?.message}
      variant='outlined'
      onChange={(e) => onChange(parseValue(e.target.value))}
      value={value ?? defaultValue}
    />
  )
}

const parseValue = (number: string) => {
  var match = number.toString().match(/[-]{0,1}[\d]*[.]{0,1}[\d]*/g)
  if (match) {
    return match[0]
  } else return ''
}
