import { Merchant } from 'graphql/types.generated'
import React from 'react'

import { MerchantCard } from './MerchantCard'

interface MerchantListProps {
  organizationId: ID
  merchants: Merchant[]
}

export function MerchantList(props: MerchantListProps) {
  const { merchants, organizationId } = props

  return (
    <div style={merchantsStyle}>
      {(!merchants || !merchants?.length) && (
        <div>There are no cafes for this organization. Click Add Cafe to get started.</div>
      )}
      {(merchants ?? []).map((merchant) => (
        <MerchantCard key={merchant.id} organizationId={organizationId} merchant={merchant} />
      ))}
    </div>
  )
}

const merchantsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 10
}
