import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import {
  ContractType,
  Maybe,
  useContractByContractTypeQuery,
  useFetchOrganizationContractSignaturesQuery,
  useSaveContractSignatureMutation
} from 'graphql/types.generated'
import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import React, { useRef, useState } from 'react'

interface TermsAgreementProps {
  organizationId: ID
  contractType: ContractType
  hidden: boolean
  onComplete: () => void
  onCancel: () => void
  onAdminSkip: () => void
}

export function TermsAgreement(props: TermsAgreementProps) {
  const toast = useToast()
  const { hasAnyRole } = usePermissions()
  const { organizationId, contractType, hidden, onComplete, onCancel, onAdminSkip } = props
  const [scrolledToBottom, setScrolledToBottom] = useState(false)

  const listInnerRef = useRef()
  const { data, loading, refetch } = useFetchOrganizationContractSignaturesQuery({
    variables: {
      id: organizationId
    }
  })

  const { data: contracts, loading: loadingContracts } = useContractByContractTypeQuery({
    variables: {
      contractType: contractType
    }
  })

  const [execute, { loading: loadingContractSave }] = useSaveContractSignatureMutation()

  const contract = contracts?.contractByContractType?.length ? contracts?.contractByContractType[0] : null

  function handleScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (scrolledToBottom) {
      toast.error('scrolledToBottom is true')
      return
    }
    const scrollContainer = e.target as Element
    const distanceFromBottomScroll = Math.abs(
      scrollContainer.scrollHeight - Math.ceil(scrollContainer.scrollTop) - scrollContainer.clientHeight
    )
    if (distanceFromBottomScroll < 5) {
      setScrolledToBottom(true)
    }
  }

  async function onAgree() {
    if (!contract) {
      return
    }
    const scrollContainer = listInnerRef.current as Maybe<Element>
    if (!scrolledToBottom && !!scrollContainer && scrollContainer?.scrollHeight > scrollContainer?.clientHeight) {
      toast.error('Please read and scroll to the bottom of the terms agreement')
      return
    }

    try {
      await execute({
        variables: {
          contractId: contract.id,
          organizationId: organizationId
        }
      })
      await refetch()
    } catch (e: any) {
      toast.error(e.message)
    }
    onComplete()
  }

  if (loading || loadingContracts) {
    return <CircularProgress />
  }
  if (data?.organization?.contractSignatures.length) {
    return <></>
  }

  if (!contract) {
    return <></>
  }

  return (
    <>
      <Dialog open={!hidden}>
        <DialogTitle>Terms Agreement</DialogTitle>
        <DialogContent style={{ maxHeight: 300 }} onScroll={handleScroll} ref={listInnerRef}>
          <div dangerouslySetInnerHTML={{ __html: contract.content }}></div>
        </DialogContent>
        <DialogActions>
          <div style={containerStyle}>
            <Button
              style={buttonStyle}
              onClick={onAgree}
              label={'Agree'}
              disabled={loadingContractSave || loading || loadingContracts}
            />
            <Button
              style={buttonStyle}
              onClick={onCancel}
              theme={'cancel'}
              label={'Cancel'}
              disabled={loadingContractSave || loading || loadingContracts}
            />
            {hasAnyRole(roles.admin) && (
              <Button
                style={buttonStyle}
                onClick={onAdminSkip ?? onCancel}
                theme={'cancel'}
                label={'Skip (Admin - only)'}
                disabled={loadingContractSave || loading || loadingContracts}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}
const buttonStyle: React.CSSProperties = {
  margin: 5,
  display: 'flex',
  flexDirection: 'row'
}
