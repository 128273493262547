import { useAuth0 } from '@auth0/auth0-react'
import { Footer } from 'components/layout/Footer/Footer'
import { Header } from 'components/layout/Header/Header'
import React, { useEffect, useState } from 'react'
import { matchPath, Route, Routes, useLocation } from 'react-router-dom'
import { ColorHex } from 'theme/ThemeConstants'

import { SignUpForm } from './onboarding/signUpForm/SignUpForm'
import { SignUpHome } from './onboarding/signUpForm/SignUpHome'

export function Unauthenticated() {
  const [isFreshOnboardingPath, setIsFreshOnboardingPath] = useState(false)

  const { loginWithRedirect } = useAuth0()
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (!matchPath('/acceptinvite/*', pathname)) {
      return
    }
    const fullPath = `${pathname}${search}`
    loginWithRedirect({
      signUp: true,
      appState: {
        returnTo: fullPath
      },
      callbackUrlPath: fullPath
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginWithRedirect, pathname, search])

  const isSignUp = !!matchPath('/signUp', pathname)
  useEffect(() => {
    if (!!matchPath('/fresh-onboarding', pathname)) {
      setIsFreshOnboardingPath(true)
    }
  }, [pathname])

  return (
    <div style={{ ...homeStyle, ...(isSignUp ? {} : { background: ColorHex.RDY_BEIGE }) }}>
      <Header />
      <div style={homeContent}>
        <Routes>
          <Route path='signUp' element={<SignUpForm isFreshOnboarding={isFreshOnboardingPath} />} />
          <Route path='acceptinvite/*' element={<></>} />
          <Route path='*' element={<SignUpHome />} />
        </Routes>
      </div>

      <Footer />
    </div>
  )
}

const homeStyle: React.CSSProperties = {
  position: 'relative',
  minHeight: '100vh'
}
const homeContent: React.CSSProperties = {
  paddingBottom: 180
}
