import { Section } from '@bloom-coffee/steamed-milk'
import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { MerchantPaymentAccount } from 'components/payments/PaymentAccount/MerchantPaymentAccount'
import { SetupPaymentAccount } from 'components/payments/SetupPaymentAccount/SetupPaymentAccount'
import { PrivateRoute } from 'components/router/PrivateRoute'
import { roles, usePermissions } from 'hooks/usePermissions/usePermissions'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ManageMerchantSubscription } from 'views/merchants/components/ManageMerchantSubscription/ManageMerchantSubscription'
import { MerchantSubscriptionPayment } from 'views/merchants/components/MerchantSubscription/MerchantSubscriptionPayment'
import { SetTransactionFee } from 'views/merchants/components/SetTransactionFee/SetTransactionFee'

interface BillingContainerProps {
  merchantId: ID
  organizationId: ID
}

export function BillingContainer(props: BillingContainerProps) {
  const { merchantId, organizationId } = props

  const { hasAnyRole } = usePermissions()

  const adminRoutes = hasAnyRole(roles.admin)
    ? [
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/billing/subscriptionSetup`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/billing/subscriptionSetup`],
          text: 'Subscription Setup (Admin)'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/billing/merchantFee`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/billing/merchantFee`],
          text: 'Merchant Fee (Admin)'
        }
      ]
    : []
  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/billing/payoutAccount`,
          paths: [
            `/organizations/:organizationId/merchants/:merchantId/billing/payoutAccount`,
            `/organizations/:organizationId/merchants/:merchantId/billing/setupPaymentAccount`
          ],
          text: 'Payout Account'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/billing/subscription`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/billing/subscription`],
          text: 'Activation'
        },
        ...adminRoutes
      ]}
    >
      <Routes>
        <Route
          path={`payoutAccount`}
          element={<MerchantPaymentAccount organizationId={organizationId} merchantId={merchantId} />}
        />
        <Route path={`setupPaymentAccount`} element={<SetupPaymentAccount />} />
        <Route
          path={`subscription`}
          element={
            <Section title='Activation' subTitle='View the Activation Payment applied to your account'>
              <MerchantSubscriptionPayment merchantId={merchantId} />
            </Section>
          }
        />{' '}
        <Route
          path={`merchantFee`}
          element={
            <PrivateRoute roleMatchers={[roles.admin]}>
              <SetTransactionFee merchantId={merchantId} />
            </PrivateRoute>
          }
        />
        <Route
          path={`subscriptionSetup`}
          element={
            <PrivateRoute roleMatchers={[roles.admin]}>
              <ManageMerchantSubscription merchantId={merchantId} />
            </PrivateRoute>
          }
        />
        <Route path={`*`} element={<Navigate to={'payoutAccount'} replace={true} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
