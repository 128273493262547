import { isValidEmail, isValidUsPhoneWithoutCountryCode } from 'validation/ValidationUtils'
import * as yup from 'yup'

export const merchantContactSchema = yup.object({
  id: yup.string().nullable(true).optional(),
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Lst Name').required(),
  email: yup
    .string()
    .label('Email')
    .test({
      message: 'Invalid email',
      test: (inputVal) => {
        if (inputVal) {
          return isValidEmail(inputVal)
        }
        return true
      }
    })
    .required(),
  phoneNumber: yup
    .string()
    .label('Phone number')
    .test({
      message: 'Invalid phone number',
      test: (inputVal) => {
        if (inputVal) {
          return isValidUsPhoneWithoutCountryCode(inputVal)
        }
        return true
      }
    })
    .required()
})

export type MerchantContactFormModel = yup.InferType<typeof merchantContactSchema>
