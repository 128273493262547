import { useAuth0 } from '@auth0/auth0-react'
import { Button, useWindowSize } from '@bloom-coffee/steamed-milk'
import ImageAsset from 'assets/ImageResource'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ColorHex } from 'theme/ThemeConstants'

export function SignUpHome() {
  const { loginWithRedirect } = useAuth0()
  const navigate = useNavigate()

  const [windowSize] = useWindowSize()

  return (
    <div>
      <div
        style={{
          background: ColorHex.RDY_BEIGE,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <div style={leftStyle}>
          <div>
            <div style={marketingContent}>
              <h2 style={marketingTitle}>RDY is the order ahead app for independent coffee. </h2>
              <p style={marketingText}>
                We’ve designed the first mobile ordering platform that works for you, not the other way around. We’ve
                learned that if your customers love ordering online, they’ll return more frequently and they’ll spend
                more each time they visit.
              </p>

              <p style={marketingText}>
                Customers order via the RDY mobile app, which is partnered with a powerful menu and storefront
                management portal. We built RDY to match the customer service and quality you offer your customers
                in-store, read on to learn more.
              </p>
            </div>
            <div style={buttonContainer}>
              <Button style={buttonStyle} onClick={() => navigate('/signUp')} label='Sign Up' />
              <Button style={buttonStyle} onClick={loginWithRedirect} label='Log In' />
            </div>
          </div>
        </div>
        {windowSize === 'large' && (
          <div style={rightStyle}>
            <div style={heroImage}>
              <div style={imageFrame1}>
                <img style={imageStyle} src={ImageAsset.HERO_RDY_PASS_1.default} alt={'RDY Pass in Action'} />
              </div>
              <div style={imageFrame2}>
                <img style={imageStyle} src={ImageAsset.HERO_RDY_PASS_2.default} alt={'RDY Pass in Action Again'} />
              </div>
              <div style={imageFrame3}>
                <img style={imageStyle} src={ImageAsset.HERO_RDY_PASS_3.default} alt={'Order Ahead'} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <h3 style={marketingTitle}>You can have your cafe live in just a few minutes!</h3>
        <iframe
          style={{
            width: windowSize !== 'small' ? 480 : 300,
            height: windowSize !== 'small' ? 270 : 150
          }}
          src='https://www.youtube.com/embed/MsQKEfMgMqQ'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
const heroImage: React.CSSProperties = {
  position: 'relative',
  top: 0,
  left: 0
}
const imageStyle: React.CSSProperties = {
  height: 'auto',
  maxWidth: 180
}
const imageFrame1: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 110,
  padding: 20,
  zIndex: 2,
  background: ColorHex.RDY_BEIGE
}
const imageFrame2: React.CSSProperties = {
  position: 'absolute',
  top: 160,
  left: 0,
  padding: 20,
  zIndex: 3,
  background: ColorHex.RDY_BEIGE
}

const imageFrame3: React.CSSProperties = {
  position: 'absolute',
  top: 200,
  left: 220,
  padding: 20,
  zIndex: 1,
  background: ColorHex.RDY_BEIGE
}
const rightStyle: React.CSSProperties = {
  width: 425,
  paddingTop: 30,
  background: ColorHex.RDY_BEIGE
}

const leftStyle: React.CSSProperties = {
  width: 500,
  paddingTop: 30,
  paddingLeft: 10,
  background: ColorHex.RDY_BEIGE
}

const marketingContent: React.CSSProperties = {
  maxWidth: 900,

  marginLeft: 'auto',
  marginRight: 'auto'
}
const marketingTitle: React.CSSProperties = {
  fontFamily: 'Raw-Bold',
  fontSize: 24
}

const marketingText: React.CSSProperties = {
  fontFamily: 'Matter-Medium',
  fontSize: 18
}

const buttonContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginTop: 10
}

const buttonStyle = {
  borderRadius: 15,
  fontSize: 18,
  height: 40,
  width: 150,
  marginTop: 10,
  marginLeft: 10,
  marginRight: 10
}
