import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, DialogContentText, Grid, Modal, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { usePromotionQuery } from 'graphql/types.generated'
import { useArchivePromotion } from 'hooks/useArchivePromotion/useArchivePromotion'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatUsCentsAsUsDollar } from 'util/formatting'

import { AdjudicatePromotion } from './components/AdjudicatePromotion'
import { PromoCodesList } from './components/PromoCodesList'
import { UpdatePromotionDates } from './components/UpdatePromotionDates'

export const Promotion = () => {
  const navigate = useNavigate()
  const { promotionId } = useParams()

  const [showDeletePrompt, toggleDeletePrompt] = useState(false)
  const [showAdjudicate, toggleAdjudicate] = useState(false)

  const { archive, loading: archiving } = useArchivePromotion()

  const { data, loading } = usePromotionQuery({
    variables: { id: promotionId! },
    fetchPolicy: 'cache-first'
  })

  function goBack() {
    navigate(-1)
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Section
          title={`Promotion ${data?.promotion.customerFacingName}`}
          addon={
            <>
              <ConfirmationDialog
                title='Are you sure?'
                open={showDeletePrompt}
                onClose={() => toggleDeletePrompt(false)}
                onConfirm={() => archive(promotionId!, goBack)}
              >
                <DialogContentText>Are you sure you want to delete this promotion?</DialogContentText>
              </ConfirmationDialog>
              <Button
                disabled={archiving}
                theme='warning'
                endIcon={<DeleteIcon />}
                onClick={() => toggleDeletePrompt(true)}
              />
            </>
          }
        >
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={3}>
              <Typography variant='caption'>Status</Typography>
              <div>
                <Button
                  disabled={loading}
                  label={data?.promotion.status ?? ''}
                  endIcon={<EditIcon />}
                  onClick={() => toggleAdjudicate(true)}
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <Typography variant='caption'>Promo Type</Typography>
              <Typography variant='subtitle2'>{data?.promotion.type}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant='caption'>Cost Owner</Typography>
              <Typography variant='subtitle2'>{data?.promotion.costOwner}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant='caption'>Created On</Typography>
              <Typography variant='subtitle2'>
                {data?.promotion.createdOn ? new Date(data?.promotion.createdOn).toDateString() : undefined}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={3}>
                  <Typography variant='caption'>Start</Typography>
                  <Typography variant='subtitle2'>
                    {data?.promotion.start
                      ? `${new Date(data?.promotion.start).toDateString()} ${new Date(
                          data?.promotion.start
                        ).toTimeString()}`
                      : undefined}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='caption'>End</Typography>
                  <Typography variant='subtitle2'>
                    {data?.promotion.end
                      ? `${new Date(data?.promotion.end).toDateString()} ${new Date(
                          data?.promotion.end
                        ).toTimeString()}`
                      : undefined}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <UpdatePromotionDates promotion={data?.promotion!!} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Typography variant='caption'>Flat Discount</Typography>
              <Typography variant='subtitle2'>
                {formatUsCentsAsUsDollar(data?.promotion.flatDiscountUsCents || 0)}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant='caption'>Percentage Discount</Typography>
              <Typography variant='subtitle2'>{`${data?.promotion.percentageDiscount}%`}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant='caption'>Max Discount</Typography>
              <Typography variant='subtitle2'>
                {data?.promotion.maxDiscountUsCents
                  ? formatUsCentsAsUsDollar(data.promotion.maxDiscountUsCents)
                  : 'None'}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant='caption'>New Users Only</Typography>
              <Typography variant='subtitle2'>{data?.promotion.newUserOnly ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='caption'>Limit Per User</Typography>
              <Typography variant='subtitle2'>{data?.promotion.limitPerUser}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='caption'>Product Tags</Typography>
              <Typography variant='subtitle2'>
                {data?.promotion.productTags ? data?.promotion.productTags.join(', ') : 'No'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems='flex-end'>
            <Button
              disabled={loading}
              label=' Create Promo Code'
              endIcon={<AddIcon />}
              onClick={() => navigate(`/admin/promotions/${data?.promotion.id}/code`)}
            />
          </Grid>

          <PromoCodesList codes={data?.promotion.codes || []} promotionId={data?.promotion.id!!} />

          <Modal open={showAdjudicate} aria-labelledby='adj-promo' aria-describedby='adj-promo-description'>
            <>
              {data?.promotion && (
                <AdjudicatePromotion promotion={data.promotion} onFinish={() => toggleAdjudicate(false)} />
              )}
            </>
          </Modal>
        </Section>
      )}
    </>
  )
}
