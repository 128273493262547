import { HasAnyRole } from 'components/auth/HasAnyRole'
import { roles } from 'hooks/usePermissions/usePermissions'
import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { NotAllowed } from 'views/NotAllowed'

import { PayoutDetailsRerouter } from './components/PayoutDetailsRerouter'
import { ViewOrganization } from './ViewOrganization/ViewOrganization'

export function OrganizationRoot() {
  const { organizationId } = useParams()
  return (
    <>
      <HasAnyRole roleMatchers={[roles.organizationMember(organizationId!)]} fallback={<NotAllowed />}>
        <Routes>
          <Route path={'payoutDetails/:merchantPayoutIds'} element={<PayoutDetailsRerouter />} />
          <Route path={'*'} element={<ViewOrganization />} />
        </Routes>
      </HasAnyRole>
    </>
  )
}
