import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Typography } from '@material-ui/core'
import { DataGrid, GridValueFormatterParams } from '@mui/x-data-grid'
import { useOrdersByMerchantIdQuery } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { readableDateFromInstant } from 'util/date'
import { exportItemSummariesToExcel } from 'util/exportToExcel'
import { formatUsCentsAsUsDollar } from 'util/formatting'

import { TransactionSearchFormModel } from './orderSearchValidator'
import { TransactionSearchForm } from './TransactionSearchForm'
import { generateCategorySummaries, generateProductSummaries } from './transactionTransforms'
interface MerchantOrderListProps {
  merchantId: string
}

const thrityDays = 1000 * 60 * 60 * 24 * 30

export function ProductSales(props: MerchantOrderListProps) {
  const { merchantId } = props
  const toast = useToast()

  const [dates, setDates] = useState({
    startDate: Date.now() - thrityDays,
    endDate: Date.now()
  })

  const { data: orderData, loading, error, refetch } = useOrdersByMerchantIdQuery({
    variables: { id: merchantId, startDate: dates.startDate, endDate: dates.endDate },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (error) {
      toast.error('Error retrieving the report. Please try again')
    }
  }, [error, toast])

  const productSummaries = generateProductSummaries(!!error ? [] : orderData?.ordersByMerchantId ?? [])

  const categorySummmaries = generateCategorySummaries(productSummaries)

  function handleSubmit(input: TransactionSearchFormModel) {
    let updatedStartDate = new Date(input.startDate).getTime()
    let updatedEndDate = new Date(input.endDate).getTime()

    if (dates.startDate === updatedStartDate && dates.endDate === updatedEndDate) {
      refetch()
    } else {
      setDates({
        startDate: updatedStartDate,
        endDate: updatedEndDate
      })
    }
  }
  function exportOrdersToExcel() {
    exportItemSummariesToExcel(dates.startDate, dates.endDate, productSummaries, categorySummmaries)
  }
  return (
    <>
      <Section title='Search' subTitle='Select a date range to view product sales for your cafe.'>
        <div style={{ marginBottom: 1 }}>
          <TransactionSearchForm onSubmit={handleSubmit} disableSubmit={loading} />
        </div>
      </Section>
      {!loading && (
        <Section title='Report Date Range' addon={<Button label='Export to Excel' onClick={exportOrdersToExcel} />}>
          <Typography>{`${readableDateFromInstant(dates.startDate)} - ${readableDateFromInstant(
            dates.endDate
          )}`}</Typography>
        </Section>
      )}
      <Section title='Category Summary' variant='grid'>
        <DataGrid
          loading={loading}
          autoHeight
          hideFooterPagination
          hideFooter
          hideFooterRowCount
          disableSelectionOnClick
          rows={(!loading && categorySummmaries) || []}
          columns={[
            {
              field: 'categoryName',
              headerName: 'Category',
              width: 200
            },
            {
              field: 'sales',
              headerName: 'Sales',
              width: 200,
              valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
            },
            {
              field: 'sold',
              headerName: 'Count Sold',
              width: 200
            }
          ]}
        />
      </Section>
      <Section title='Product Sales Summaries' variant='grid'>
        <DataGrid
          loading={loading}
          autoHeight
          hideFooterPagination
          hideFooter
          hideFooterRowCount
          disableSelectionOnClick
          rows={(!loading && productSummaries) || []}
          columns={[
            {
              field: 'productName',
              headerName: 'Product',
              width: 200
            },
            {
              field: 'categoryName',
              headerName: 'Category',
              width: 200
            },
            {
              field: 'sales',
              headerName: 'Sales',
              width: 200,
              valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
            },
            {
              field: 'sold',
              headerName: 'Count Sold',
              width: 200
            }
          ]}
        />
      </Section>
    </>
  )
}
