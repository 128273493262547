import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CreateModifierOptionContainer } from './CreateModifierOptionContainer'

export const CreateModifierOption = () => {
  const { merchantId } = useParams()
  const navigate = useNavigate()

  return (
    <>
      <CreateModifierOptionContainer
        inlineCreate={false}
        merchantId={merchantId!}
        onFinish={(option) => navigate(-1)}
        onCancel={() => navigate(-1)}
      />
    </>
  )
}
