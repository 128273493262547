import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { Navigate, Route, Routes } from 'react-router-dom'

import { SendMarketPush } from '../push/SendMarketPush/SendMarketPush'
import { SendUserPush } from '../push/SendUserPush/SendUserPush'

export function NotificationsContainer() {
  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/admin/notifications/sendUser`,
          paths: [`/admin/notifications/sendUser`],
          text: 'Send User Push'
        },
        {
          link: `/admin/notifications/sendMarket`,
          paths: [`/admin/notifications/sendMarket`],
          text: 'Send Market Push'
        }
      ]}
    >
      <Routes>
        <Route path={`sendUser`} element={<SendUserPush />} />
        <Route path={`sendMarket`} element={<SendMarketPush />} />
        <Route path={`*`} element={<Navigate to={'/admin/notifications/sendUser'} replace={true} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
