import { useState } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

export interface MenuButtonDetails {
  paths: string[]
  link?: string
  action?: () => void
  text: string
}

export interface MenuButtonProps {
  menuButtonDetails: MenuButtonDetails
  defaultStyle: React.CSSProperties
  activeStyle: React.CSSProperties
}

export function MenuButton(props: MenuButtonProps) {
  const { menuButtonDetails, defaultStyle, activeStyle } = props
  const { paths, link, action, text } = menuButtonDetails
  let navigate = useNavigate()
  let location = useLocation()
  let match = paths.find((path) => !!matchPath(path, location.pathname))

  const [hovered, setHovered] = useState(false)

  function onClick() {
    if (!!link) navigate(link)
    if (!!action) action()
  }
  return (
    <button
      style={{ cursor: 'pointer', ...(hovered ? hoverStyle : {}), ...(!!match ? activeStyle : defaultStyle) }}
      onClick={onClick}
      onMouseEnter={(e) => {
        setHovered(true)
      }}
      onMouseLeave={(e) => {
        setHovered(false)
      }}
    >
      {text}
    </button>
  )
}

const hoverStyle: React.CSSProperties = {
  textDecoration: 'underline'
}
