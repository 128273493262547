import * as yup from 'yup'

import { transform } from '../../../../validation/transform'

export const platformPricingSchema = yup.object({
  name: yup.string().label('Name').required(),
  platformPricingType: yup.string().label('Type').required(),
  instantPriceUsCents: yup
    .number()
    .transform(transform.nullable)
    .label('Instant Price')
    .required()
    .typeError('Instant Price needs to be a number'),
  subscriptionMonthlyFeeUsCents: yup
    .number()
    .transform(transform.nullable)
    .label('Monthly Price')
    .optional()
    .typeError('Monthly Price needs to be a number'),
  subscriptionTerm: yup
    .number()
    .transform(transform.nullable)
    .label('Subscription Term')
    .optional()
    .typeError('Subscription Term needs to be a number')
})

export type PlatformPricingFormModel = yup.InferType<typeof platformPricingSchema>
