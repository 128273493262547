import { Button } from '@bloom-coffee/steamed-milk'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { TextField } from 'components/form/TextField'
import { Modal } from 'components/layout/Modal/Modal'
import {
  ExternalDataSource,
  useMerchantSourceSystemQuery,
  useUpdateMerchantSourceSystemMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface ImportChowlyMenuProps {
  merchantId: string
  style?: React.CSSProperties
}

export const ImportChowlyMenu = (props: ImportChowlyMenuProps) => {
  const { merchantId, style } = props
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false)
  const [execute, { loading }] = useUpdateMerchantSourceSystemMutation()
  const { data } = useMerchantSourceSystemQuery({
    variables: {
      merchantId: merchantId
    },
    fetchPolicy: 'cache-first'
  })
  const formProps = useForm<ChowlyMenuSyncModel>()

  const { register, handleSubmit } = formProps
  const toast = useToast()

  async function onSubmit(values: ChowlyMenuSyncModel) {
    try {
      await execute({
        variables: {
          merchantId: merchantId!!,
          sourceSystem: ExternalDataSource.Chowly,
          sourceSystemId: values.sourceSystemId,
          syncMenu: true
        }
      })
      toast.success('Menu Synced')
      setUpdateModalOpen(false)
    } catch (e: any) {
      toast.error(`Could not sync menu: ${e}`)
    }
  }

  return (
    <div style={style}>
      <Button label='Import from Chowly' onClick={() => setUpdateModalOpen(true)} />
      <Modal open={!!updateModalOpen} onClose={() => setUpdateModalOpen(false)} title={`Sync Chowly Menu`}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...formProps}>
              <TextField
                style={{ margin: 10, width: 300 }}
                variant='outlined'
                name='sourceSystemId'
                label='Location API Key'
                defaultValue={
                  data?.merchant?.source === ExternalDataSource.Chowly ? data?.merchant?.sourceSystemId : null
                }
                inputRef={register({ required: 'Location API Key is required' })}
              />
              <DefaultFormFooter
                onCancel={() => setUpdateModalOpen(false)}
                onSubmit={handleSubmit(onSubmit)}
                submitting={loading}
                saveLabel='Import Menu'
              />
            </FormProvider>
          </form>
        </div>
      </Modal>
    </div>
  )
}

export interface ChowlyMenuSyncModel {
  sourceSystemId: string
}
