import { Section } from '@bloom-coffee/steamed-milk'
import { useNavigate } from 'react-router-dom'

import { GrantTokensForm } from './components/GrantTokensForm'

export const GrantTokens = () => {
  const navigate = useNavigate()
  return (
    <Section title='Grant Reward'>
      <GrantTokensForm onCancel={() => navigate(-1)} />
    </Section>
  )
}
