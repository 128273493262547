import { yupResolver } from '@hookform/resolvers/yup'
import { MenuItem, TextField } from '@material-ui/core'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { SelectField } from 'components/form/SelectField'
import { CategoryType } from 'graphql/types.generated'
import React from 'react'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'

import { CategoryFormModel, categorySchema } from './categoryValidator'

interface CategoryFormProps {
  initialValues?: DefaultValues<CategoryFormModel> | undefined
  onCancel(): void
  onSubmit(values: CategoryFormModel): void
  loading?: boolean
}
export function CategoryForm(props: CategoryFormProps) {
  const { initialValues, onCancel, onSubmit, loading } = props
  const formProps = useForm<CategoryFormModel>({
    defaultValues: initialValues,
    resolver: yupResolver(categorySchema),
    shouldUnregister: false
  })
  const { register, handleSubmit } = formProps

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formProps}>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <TextField
            style={{ margin: 10, width: 300 }}
            variant='outlined'
            name='name'
            label='Name'
            inputRef={register({ required: 'Name is required' })}
          />
          <SelectField
            style={{ width: 200, margin: 10 }}
            label='Category Type'
            name={'type'}
            defaultValue={initialValues?.type}
            inputRef={register()}
          >
            <MenuItem value={CategoryType.SpecialtyBeverages}>Specialty Beverages</MenuItem>
            <MenuItem value={CategoryType.Coffee}>Coffee</MenuItem>
            <MenuItem value={CategoryType.Tea}>Tea</MenuItem>
            <MenuItem value={CategoryType.NotCoffee}>Not Coffee</MenuItem>
            <MenuItem value={CategoryType.Food}>Food</MenuItem>
            <MenuItem value={CategoryType.KitchenFood}>Kitchen Food</MenuItem>
            <MenuItem value={CategoryType.RetailMerch}>Retail Merch</MenuItem>
            <MenuItem value={CategoryType.RetailBeans}>Retail Beans</MenuItem>
            <MenuItem value={CategoryType.Beer}>Beer</MenuItem>
            <MenuItem value={CategoryType.Wine}>Wine</MenuItem>
            <MenuItem value={CategoryType.Cocktails}>Cocktails</MenuItem>
            <MenuItem value={CategoryType.NonAlcoholic}>Non Alcoholic</MenuItem>
            <MenuItem value={CategoryType.Seltzers}>Seltzers</MenuItem>
          </SelectField>
        </div>
        <DefaultFormFooter
          onCancel={onCancel}
          onSubmit={handleSubmit(onSubmit)}
          submitting={loading}
          saveLabel='Create Category'
        />
      </FormProvider>
    </form>
  )
}
