import axios from 'axios'
import { config } from 'config'
import { IntegrationType } from 'graphql/types.generated'

import { StringUtils } from './stringUtils'

const FreshKdsBaseUrl = config.freshKDSBaseUrl

export interface KdsIntegrationLocation {
  id: ID
  type: IntegrationType
  number: number
  name: string
  address: {
    zip: number
    city: string
    line1: string
    line2?: string
    state: string
  }
}

export interface SendTestOrderResponse {
  success: boolean
  message?: string
}
interface FreshKdsOrderResponse {
  orderId: string
  deliveryStatuses: FreshKdsDeliveryStatus[]
}
interface FreshKdsDeliveryStatus {
  device: string
  status: string
  type?: string
}

export async function requestFreshKdsInformation(accessKey: string): Promise<KdsIntegrationLocation[]> {
  const headers: any = {
    'x-integration-token': accessKey
  }
  let locations: KdsIntegrationLocation[] = []

  try {
    const httpResponse = await axios.request({
      headers,
      method: 'GET',
      url: `${FreshKdsBaseUrl}/integrators/kds-information`
    })
    locations = httpResponse.data.map((location: any) => ({
      ...location,
      type: IntegrationType.FreshKds
    })) as KdsIntegrationLocation[]
  } catch (e) {
    throw new Error('Invalid Access Token')
  }

  if (!locations.length) {
    throw new Error('No locations found')
  }
  return locations
}

export async function sendFreshKdsTestOrder(accessKey: string, locationId: ID): Promise<SendTestOrderResponse> {
  const headers: any = {
    'x-integration-token': accessKey,
    'x-location-id': locationId,
    'x-device-ids': 'all'
  }

  const testOrder = {
    id: StringUtils.generateRandomAlphaNumeric(16),
    name: 'RDY Partners Test',
    time: new Date().toISOString(),
    mode: 'ToGo',
    terminal: 'RDY',
    items: [
      {
        name: 'Latte',
        qty: 1,
        mods: ['Iced', 'Oat Milk']
      },
      {
        name: 'Americano',
        qty: 1,
        mods: []
      }
    ]
  }

  try {
    const httpResponse = await axios.request({
      headers,
      method: 'POST',
      url: `${FreshKdsBaseUrl}/integrators/kds-orders`,
      data: testOrder
    })

    return parseFreshKdsTestOrderResponse(httpResponse)
  } catch (e) {
    return { success: false }
  }
}

function parseFreshKdsTestOrderResponse(httpResponse: any): SendTestOrderResponse {
  let success = false,
    message = 'Could not send test order'

  if (httpResponse.status !== 200) {
    if (!!httpResponse.statusText) {
      message = httpResponse.statusText
    }
  } else {
    const response: FreshKdsOrderResponse = httpResponse.data
    const deliveryStatuses = response.deliveryStatuses

    //if order sent successfully to one device, return success
    if (deliveryStatuses.some((ds) => ds.status === 'success')) {
      success = true
    } else if (deliveryStatuses.length) {
      //return first error message
      message = deliveryStatuses[0].status
    }
  }

  return { success, message }
}
