import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { DataGrid } from '@mui/x-data-grid'
import { useGetPlatformPricingQuery } from 'graphql/types.generated'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatUsCentsAsUsDollar } from 'util/formatting'

export const PlatformPricingSetup = () => {
  const navigate = useNavigate()
  const { data, loading } = useGetPlatformPricingQuery()

  return (
    <Section
      title='Platform Pricing'
      variant='grid'
      addon={
        <Button
          endIcon={<AddIcon />}
          label='Create Pricing'
          onClick={() => navigate('/admin/merchantFees/platformPricing/save')}
        />
      }
    >
      {loading && <CircularProgress />}
      {data && (
        <DataGrid
          loading={loading}
          autoHeight
          disableSelectionOnClick
          rows={data.getPlatformPricing || []}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              sortable: true,
              width: 250,
              renderCell(params) {
                return <Typography>{params.row.name}</Typography>
              }
            },
            {
              field: 'createdOn',
              headerName: 'Created Date',
              sortable: true,
              width: 200,
              renderCell(params) {
                return <Typography>{new Date(params.row.createdOn).toDateString()}</Typography>
              }
            },
            {
              field: 'instantPriceUsCents',
              headerName: 'Instant Price',
              sortable: true,
              width: 200,
              renderCell(params) {
                return <Typography>{formatUsCentsAsUsDollar(params.row.instantPriceUsCents)}</Typography>
              }
            },
            {
              field: 'subscriptionTerm',
              headerName: 'Subscription Term',
              sortable: true,
              width: 200,
              renderCell(params) {
                return <Typography>{params.row.subscriptionTerm}</Typography>
              }
            },
            {
              field: 'subscriptionProduct',
              headerName: 'Monthly Cost',
              sortable: true,
              width: 200,
              renderCell(params) {
                return (
                  <>
                    {!!params.row.subscriptionProduct && (
                      <Typography>{formatUsCentsAsUsDollar(params.row.subscriptionProduct!!.costUsCents)}</Typography>
                    )}
                  </>
                )
              }
            },
            {
              field: 'platformPricingType',
              headerName: 'Type',
              sortable: true,
              width: 200,
              renderCell(params) {
                return <Typography>{params.row.platformPricingType}</Typography>
              }
            }
          ]}
        />
      )}
    </Section>
  )
}
