import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Typography } from '@material-ui/core'
import { Maybe, ProductListDocument, useMerchantForSyncQuery, useSyncFreshMenuMutation } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { ColorHex } from 'theme/ThemeConstants'

interface ResyncFreshMenuProps {
  onCancel: () => void
  onSuccess: () => void
  merchantId: string
  token: string
  brandId: string
}

export function ResyncFreshMenu(props: ResyncFreshMenuProps) {
  const { onCancel, onSuccess, merchantId, token, brandId } = props

  const [startedPolling, setStartedPolling] = useState<Maybe<Date>>(null)

  const { data, loading, startPolling, stopPolling } = useMerchantForSyncQuery({
    variables: {
      id: merchantId
    }
  })

  const [execute, { loading: syncingMenu }] = useSyncFreshMenuMutation()

  async function resyncFreshMenu() {
    try {
      await execute({
        variables: {
          merchantId: merchantId,
          brandId: brandId!,
          token: token!
        },
        refetchQueries: [
          {
            query: ProductListDocument,
            variables: {
              merchantId
            }
          }
        ]
      })
      onSuccess()
    } catch (e: any) {
      setStartedPolling(new Date())
      startPolling(1000)
    }
  }

  useEffect(() => {
    if (startedPolling == null) {
      return
    }

    if (
      data?.merchant?.freshLocation?.lastSyncCompleted &&
      data?.merchant?.freshLocation?.lastSyncCompleted > startedPolling.getTime()
    ) {
      onSuccess()
      stopPolling()
      setStartedPolling(null)
    }
  }, [data, startedPolling, stopPolling, onSuccess])

  return (
    <>
      {!loading && (
        <>
          {(syncingMenu || startedPolling) && (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: 100 }}>
              <Typography>Syncing the menu</Typography>
              <CircularProgress />
            </div>
          )}
          <div style={importingDetails}>
            <Typography variant='body1'>
              Syncing the menu will replace the current menu with the Fresh menu for this location. Any changes to the
              current menu will be replaced.
            </Typography>
          </div>
          {!!(syncingMenu || startedPolling) && (
            <div style={importingDetails}>
              <Typography variant='body1'>
                Importing the menu may take a few minutes. An email will be sent when the sync is complete. Closing this
                window will not affect the import.
              </Typography>
            </div>
          )}
          <div style={importingDetails}>
            <Typography variant='body1'>
              For support please email <a href={'mailto:support@rdy.xyz'}>support@rdy.xyz</a>
            </Typography>
          </div>
          <div style={actionsStyle}>
            <Button style={buttonStyle} disabled={!!startedPolling} onClick={resyncFreshMenu} label='Re-sync Menu' />
            <Button style={buttonStyle} onClick={onCancel} theme='cancel' label='Close' />
          </div>
        </>
      )}
    </>
  )
}

const actionsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const buttonStyle: React.CSSProperties = {
  marginRight: 5
}

const importingDetails: React.CSSProperties = {
  padding: 10,
  backgroundColor: ColorHex.RDY_BEIGE,
  border: '1px solid',
  borderRadius: 5,
  margin: 2
}
