import { ModifierInfo, ProductInfo } from 'models/Product'

export interface SelectedModifier {
  topModifier: ModifierInfo
  secondLevelModifier: ModifierInfo
}

export interface PriceTag {
  priceUsCents: number
  priceTokens: number
}

export function getDefaultRawItemPriceTag(product: ProductInfo): PriceTag {
  const selectedModifiers: SelectedModifier[] = []

  for (let topModifier of product.modifiers || []) {
    for (let secondLevelModifier of topModifier.children || []) {
      if (topModifier.defaultSelectionIds?.includes(secondLevelModifier.id)) {
        selectedModifiers.push({ topModifier, secondLevelModifier })
      }
    }
  }

  return getRawItemPriceTag(product, selectedModifiers)
}

export function getRawItemPriceTag(
  product: ProductInfo,
  selectedModifiers: SelectedModifier[],
  quantity: number = 1
): PriceTag {
  let priceTokens = product.priceTokens || 0
  let priceUsCents = product.priceCents || 0

  for (let selectedModifier of selectedModifiers) {
    const { topModifier, secondLevelModifier } = selectedModifier

    priceTokens += topModifier.priceTokens || 0
    priceUsCents += topModifier.priceCents || 0

    priceTokens += secondLevelModifier.priceTokens || 0
    priceUsCents += secondLevelModifier.priceCents || 0
  }

  priceUsCents = priceUsCents * quantity
  priceTokens = priceTokens * quantity

  return { priceTokens, priceUsCents }
}
