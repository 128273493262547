import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** java.time.Instant implementation */
  Instant: number;
  /** java.time.LocalTime implementation */
  LocalTime: string;
  Upload: File;
};

export type Action = {
  __typename?: 'Action';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: ActionName;
};

export enum ActionName {
  AcceptReferral = 'AcceptReferral',
  AddBirthday = 'AddBirthday',
  AddFavoriteMerchant = 'AddFavoriteMerchant',
  AddFavoriteOrder = 'AddFavoriteOrder',
  AddHomeAddress = 'AddHomeAddress',
  AddWorkAddress = 'AddWorkAddress',
  CompleteBasicProfile = 'CompleteBasicProfile',
  CompleteReferral = 'CompleteReferral',
  PurchaseOrder = 'PurchaseOrder',
  Refer = 'Refer',
  SocialShare = 'SocialShare'
}

export type AddMerchantSubscriptionFeeInput = {
  subscriptionProductId: Scalars['ID'];
  merchantId: Scalars['ID'];
};

export type AddressInput = {
  street: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export enum AppType {
  Cappuccino = 'CAPPUCCINO',
  Cupping = 'CUPPING',
  Doppio = 'DOPPIO',
  Latte = 'LATTE',
  Macchiato = 'MACCHIATO',
  Mocha = 'MOCHA'
}

export type AppVersion = {
  __typename?: 'AppVersion';
  minimumIosVersion: Scalars['String'];
  minimumAndroidVersion: Scalars['String'];
  displayMessage: Scalars['String'];
};

export type AvailabilityWindow = {
  __typename?: 'AvailabilityWindow';
  id: Scalars['ID'];
  dayOfWeek: Scalars['Int'];
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type AvailabilityWindowInput = {
  dayOfWeek: Scalars['Int'];
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type CafeLeadInput = {
  organizationName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  platformPricingId: Maybe<Scalars['ID']>;
  termSubscription: Maybe<Scalars['Boolean']>;
};

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String'];
  last4: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  items: Maybe<Array<CartItem>>;
};

export type CartInput = {
  items: Array<CartItemInput>;
};

export type CartItem = {
  __typename?: 'CartItem';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  orderableProduct: Product;
  selectedModifiers: Maybe<Array<Modifier>>;
};

export type CartItemInput = {
  id: Maybe<Scalars['ID']>;
  active: Maybe<Scalars['Boolean']>;
  productId: Scalars['ID'];
  modifiers: Maybe<Array<CartItemModifierInput>>;
};

export type CartItemModifierInput = {
  id: Maybe<Scalars['ID']>;
  active: Maybe<Scalars['Boolean']>;
  parentModifierId: Scalars['ID'];
  selectedModifierId: Scalars['ID'];
};

export type Catalog = {
  __typename?: 'Catalog';
  id: Scalars['ID'];
  name: Scalars['String'];
  sourceSystemId: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  categories: Maybe<Array<Category>>;
  products: Maybe<Array<Product>>;
  activeProducts: Maybe<Array<Product>>;
  activeCategories: Maybe<Array<Category>>;
  availabilityWindows: Maybe<Array<CatalogAvailabilityWindow>>;
  active: Scalars['Boolean'];
  suggestedItems: Maybe<Array<SuggestedItem>>;
  topProducts: Maybe<Array<Product>>;
  trendingProducts: Maybe<Array<Product>>;
  suggestedProducts: Maybe<Array<Product>>;
  isDefault: Scalars['Boolean'];
};

export type CatalogAvailabilityWindow = {
  __typename?: 'CatalogAvailabilityWindow';
  id: Scalars['ID'];
  dayOfWeek: DayOfWeek;
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type CatalogAvailabilityWindowInput = {
  dayOfWeek: DayOfWeek;
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  products: Maybe<Array<Product>>;
  activeProducts: Maybe<Array<Product>>;
  active: Scalars['Boolean'];
  type: Maybe<CategoryType>;
  sortOrder: Maybe<Scalars['Int']>;
};

export type CategoryOutputDestination = {
  __typename?: 'CategoryOutputDestination';
  id: Scalars['ID'];
  category: Category;
  active: Scalars['Boolean'];
};

export type CategoryOutputDestinationInput = {
  categoryOutputDestinationId: Scalars['ID'];
  active: Scalars['Boolean'];
};

export enum CategoryType {
  SpecialtyBeverages = 'SPECIALTY_BEVERAGES',
  Coffee = 'COFFEE',
  Tea = 'TEA',
  NotCoffee = 'NOT_COFFEE',
  Food = 'FOOD',
  KitchenFood = 'KITCHEN_FOOD',
  RetailMerch = 'RETAIL_MERCH',
  RetailBeans = 'RETAIL_BEANS',
  Beer = 'BEER',
  Wine = 'WINE',
  Cocktails = 'COCKTAILS',
  NonAlcoholic = 'NON_ALCOHOLIC',
  Seltzers = 'SELTZERS'
}

export type CheckoutConfirmation = {
  __typename?: 'CheckoutConfirmation';
  order: Order;
  rewardData: Maybe<RewardData>;
  paymentIntent: Maybe<PaymentIntent>;
  completedReferral: Maybe<Referral>;
  organizationRewardData: Array<RewardData>;
};

export type CheckoutOrderInput = {
  order: OrderInput;
  timing: OrderTiming;
  placeInSeconds: Maybe<Scalars['Int']>;
  tipUsCents: Scalars['Int'];
  shouldConfirmPayment: Scalars['Boolean'];
  coordinates: Maybe<Coordinates>;
  paymentMethodId: Maybe<Scalars['String']>;
  diningOption: Maybe<OrderDiningOption>;
  servingLocationId: Maybe<Scalars['ID']>;
};

export type Contract = {
  __typename?: 'Contract';
  id: Scalars['ID'];
  content: Scalars['String'];
  contractType: ContractType;
};

export type ContractInput = {
  contractType: ContractType;
  content: Scalars['String'];
};

export type ContractSignature = {
  __typename?: 'ContractSignature';
  id: Scalars['ID'];
  contract: Contract;
  user: User;
  organization: Organization;
  createdOn: Scalars['Instant'];
};

export enum ContractType {
  FreshContract = 'FRESH_CONTRACT',
  StandardContract = 'STANDARD_CONTRACT'
}

export type Coordinates = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  altitude: Maybe<Scalars['Float']>;
  accuracy: Maybe<Scalars['Float']>;
  altitudeAccuracy: Maybe<Scalars['Float']>;
  heading: Maybe<Scalars['Float']>;
  speed: Maybe<Scalars['Float']>;
};

export type CopyAllProductsFromMerchantInput = {
  fromMerchantId: Scalars['ID'];
  toMerchantId: Scalars['ID'];
};

export type CreateCatalogInput = {
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  availabilityWindows: Array<CatalogAvailabilityWindowInput>;
  productIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateCategoryInput = {
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  sortOrder: Maybe<Scalars['Int']>;
  type: Maybe<CategoryType>;
};

export type CreateFulfillmentLocationInput = {
  merchantId: Scalars['ID'];
  displayName: Scalars['String'];
  fulfillmentLocationType: FulfillmentLocationType;
};

export type CreateMerchantContactInput = {
  merchantId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateMerchantFeeConfigInput = {
  staticFeeInUsCents: Scalars['Int'];
  percentageFee: Scalars['Float'];
};

export type CreateMerchantKdsIntegrationInput = {
  merchantId: Scalars['ID'];
  type: IntegrationType;
  accessKey: Scalars['String'];
  locationId: Scalars['String'];
  displayName: Scalars['String'];
  sendOrdersToRdy: Scalars['Boolean'];
};

export type CreateOrderEventsInput = {
  orderId: Scalars['ID'];
  eventNames: Array<OrderEventName>;
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
};

export type CreateProductInput = {
  merchantId: Scalars['ID'];
  categoryId: Scalars['ID'];
  catalogIds: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  priceCents: Scalars['Int'];
  sizeInGrams: Maybe<Scalars['Int']>;
  originType: Maybe<OriginType>;
  roaster: Maybe<Scalars['String']>;
  roastType: Maybe<RoastType>;
  topModifiers: Maybe<Array<TopModifierInput>>;
  productTags: Maybe<Array<ProductTagInput>>;
  images: Maybe<Array<ProductImageInput>>;
  inventory: Maybe<ProductInventoryInput>;
  availabilityWindows: Maybe<Array<AvailabilityWindowInput>>;
};

export type CreateQuestInput = {
  name: Scalars['String'];
  codename: Scalars['String'];
  description: Scalars['String'];
  start: DateTimeInfoInput;
  end: DateTimeInfoInput;
  topStep: CreateQuestStepInput;
};

export type CreateQuestStepEffectInput = {
  rdyPoints: Maybe<RdyPointsInput>;
  organizationId: Maybe<Scalars['ID']>;
};

export type CreateQuestStepEffectModifierInput = {
  questStepEffectId: Scalars['ID'];
  modifications: QuestStepEffectModificationsInput;
};

export type CreateQuestStepInput = {
  name: Scalars['String'];
  action: Maybe<ActionName>;
  sortOrder: Maybe<Scalars['Int']>;
  completeAfterIterations: Maybe<Scalars['Int']>;
  rules: Maybe<QuestStepRulesInput>;
  effects: Maybe<Array<CreateQuestStepEffectInput>>;
  children: Maybe<Array<CreateQuestStepInput>>;
};

export type CreateSubscriptionFeeInput = {
  costUsCents: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateSubscriptionPlanInput = {
  costUsCents: Scalars['Int'];
  name: Scalars['String'];
  recurringInterval: RecurringInterval;
};

export type CsrfToken = {
  __typename?: 'CsrfToken';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  userId: Scalars['ID'];
  value: Scalars['String'];
};

export type CurrencySummary = {
  __typename?: 'CurrencySummary';
  subtotal: Scalars['Int'];
  fee: Scalars['Int'];
  tax: Scalars['Int'];
  tip: Scalars['Int'];
  total: Scalars['Int'];
  discount: Scalars['Int'];
  merchantFee: Scalars['Int'];
};

export enum CurrencyType {
  BloomToken = 'BLOOM_TOKEN',
  UsCents = 'US_CENTS'
}

export type DateTimeInfo = {
  __typename?: 'DateTimeInfo';
  dayOfWeek: Maybe<DayOfWeek>;
  day: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['Int']>;
};

export type DateTimeInfoInput = {
  dayOfWeek: Maybe<DayOfWeek>;
  day: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['Int']>;
};

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type DeepLinkClick = {
  __typename?: 'DeepLinkClick';
  userId: Scalars['ID'];
  link: Scalars['String'];
  clickedOn: Maybe<Scalars['Instant']>;
  parsedViaMobileOn: Maybe<Scalars['Instant']>;
  customData: Maybe<Array<KeyValue>>;
};

export type DeepLinkClickInput = {
  link: Scalars['String'];
  clickedOn: Maybe<Scalars['Instant']>;
  parsedViaMobileOn: Maybe<Scalars['Instant']>;
  customData: Maybe<Array<KeyValueInput>>;
};

export type DefaultSubscriptionProduct = {
  __typename?: 'DefaultSubscriptionProduct';
  subscriptionProduct: Maybe<SubscriptionProduct>;
  defaultSubscriptionType: Maybe<DefaultSuscriptionType>;
};

export enum DefaultSuscriptionType {
  StandardSubscription = 'STANDARD_SUBSCRIPTION',
  StandardFee = 'STANDARD_FEE',
  FreshSubscription = 'FRESH_SUBSCRIPTION',
  FreshFee = 'FRESH_FEE'
}

export type DeviceInfoInput = {
  deviceId: Scalars['String'];
  os: Maybe<Scalars['String']>;
  apnsToken: Maybe<Scalars['String']>;
  fcmToken: Maybe<Scalars['String']>;
};

export enum DiningOption {
  ForHere = 'FOR_HERE',
  Takeaway = 'TAKEAWAY',
  TableService = 'TABLE_SERVICE'
}

export type DiscountPromotionData = {
  __typename?: 'DiscountPromotionData';
  promotion: Promotion;
  usCentsDeductedFromSubtotal: Scalars['Int'];
};

export type DuplicateUser = {
  __typename?: 'DuplicateUser';
  id: Scalars['ID'];
  accessIdentifier: Scalars['String'];
};

export type ExternalClientSync = {
  __typename?: 'ExternalClientSync';
  id: Scalars['ID'];
  startedOn: Scalars['Instant'];
  completedOn: Maybe<Scalars['Instant']>;
  externalSourceId: Maybe<Scalars['String']>;
  events: Maybe<Array<Maybe<ExternalClientSyncEvent>>>;
  identifier: Maybe<Scalars['String']>;
};

export type ExternalClientSyncEvent = {
  __typename?: 'ExternalClientSyncEvent';
  timestamp: Scalars['Instant'];
  eventType: Scalars['String'];
  url: Maybe<Scalars['String']>;
  response: Maybe<Scalars['String']>;
};

export enum ExternalDataSource {
  Chowly = 'CHOWLY',
  Fresh = 'FRESH',
  Square = 'SQUARE'
}

export type FavoriteMerchant = {
  __typename?: 'FavoriteMerchant';
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
  merchant: Merchant;
  user: User;
};

export type FavoriteOrder = {
  __typename?: 'FavoriteOrder';
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
  order: Order;
  user: User;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  name: FeatureFlagName;
  enabled: Scalars['Boolean'];
};

export enum FeatureFlagName {
  PaymentRequired = 'PAYMENT_REQUIRED',
  MerchantPayoutEnabled = 'MERCHANT_PAYOUT_ENABLED',
  RdyPassV1 = 'RDY_PASS_V1',
  Refunds = 'REFUNDS',
  OrderNotes = 'ORDER_NOTES',
  SubscriptionRequired = 'SUBSCRIPTION_REQUIRED',
  WebOrdering = 'WEB_ORDERING'
}

export enum Frequency {
  Weekly = 'WEEKLY'
}

export type FreshAddress = {
  __typename?: 'FreshAddress';
  line1: Scalars['String'];
  line2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  phone: Maybe<Scalars['String']>;
};

export type FreshLocation = {
  __typename?: 'FreshLocation';
  id: Scalars['String'];
  name: Scalars['String'];
  address: FreshAddress;
};

export type FreshLocationSync = {
  __typename?: 'FreshLocationSync';
  locationId: Scalars['String'];
  lastSyncCompleted: Maybe<Scalars['Instant']>;
};

export type FulfillmentLocation = {
  __typename?: 'FulfillmentLocation';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlSafeName: Scalars['String'];
  appUrl: Maybe<Scalars['String']>;
  webUrl: Maybe<Scalars['String']>;
  fulfillmentLocationType: Maybe<FulfillmentLocationType>;
  qrCodeDisplayUrl: Maybe<Scalars['String']>;
};

export enum FulfillmentLocationType {
  PickUp = 'PICK_UP',
  TableService = 'TABLE_SERVICE'
}

export type GenerateOrderPaymentSummaryInput = {
  order: OrderInput;
  tipUsCents: Scalars['Int'];
  diningOption: Maybe<OrderDiningOption>;
  timing: Maybe<OrderTiming>;
  placeInSeconds: Maybe<Scalars['Int']>;
};

export enum GeoFenceType {
  WalkingDistance = 'WalkingDistance',
  DrivingDistance = 'DrivingDistance'
}

export type GrantTokensInput = {
  userId: Scalars['ID'];
  tokenType: TokenTypeName;
  numberOfTokens: Scalars['Int'];
};

export type InitializeOrderInput = {
  userProfile: OrderPaymentIntentUserProfile;
  order: OrderInput;
  timing: OrderTiming;
  placeInSeconds: Maybe<Scalars['Int']>;
  tipUsCents: Scalars['Int'];
  diningOption: Maybe<OrderDiningOption>;
  servingLocationId: Maybe<Scalars['ID']>;
};

export type InitializeOrderOutput = {
  __typename?: 'InitializeOrderOutput';
  order: Order;
  paymentIntent: Maybe<InitializeOrderPaymentIntent>;
};

export type InitializeOrderPaymentIntent = {
  __typename?: 'InitializeOrderPaymentIntent';
  id: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type InitializeReferralInput = {
  referralCode: Scalars['String'];
  deviceId: Scalars['String'];
};

export type InitializeRequisitionInput = {
  itemIds: Array<Scalars['ID']>;
  fulfillment: RequisitionFulfillmentInput;
  code: Maybe<Scalars['String']>;
};


export enum IntegrationType {
  FreshKds = 'FRESH_KDS',
  Square = 'SQUARE',
  Chowly = 'CHOWLY'
}

export type Inventory = {
  __typename?: 'Inventory';
  id: Scalars['ID'];
  inventoryType: InventoryType;
  restockQuantity: Maybe<Scalars['Int']>;
  currentQuantity: Maybe<Scalars['Int']>;
  currentlyAvailable: Scalars['Boolean'];
  restockDays: Maybe<Array<InventoryRestockDay>>;
};

export enum InventoryAvailability {
  Available = 'AVAILABLE',
  LowStock = 'LOW_STOCK',
  NotAvailable = 'NOT_AVAILABLE'
}

export type InventoryRestockDay = {
  __typename?: 'InventoryRestockDay';
  dayOfWeek: Scalars['Int'];
};

export enum InventoryType {
  AlwaysAvailable = 'ALWAYS_AVAILABLE',
  LimitedQuantity = 'LIMITED_QUANTITY'
}

export type IoTDevice = {
  __typename?: 'IoTDevice';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  merchantId: Maybe<Scalars['ID']>;
  type: IoTDeviceType;
  positions: Maybe<Array<IoTPosition>>;
};

export enum IoTDeviceType {
  Printer = 'PRINTER',
  RdyPassV1 = 'RDY_PASS_V1'
}

export type IoTEvent = {
  __typename?: 'IoTEvent';
  id: Scalars['ID'];
  name: IoTEventName;
  position: IoTPosition;
};

export type IoTEventInput = {
  name: IoTEventName;
  deviceUuid: Scalars['String'];
  positionCode: Scalars['String'];
};

export enum IoTEventName {
  ObjectMovedToDevice = 'OBJECT_MOVED_TO_DEVICE',
  ObjectRemovedFromDevice = 'OBJECT_REMOVED_FROM_DEVICE'
}

export type IoTPosition = {
  __typename?: 'IoTPosition';
  id: Scalars['ID'];
  code: PositionCode;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID'];
  name: Scalars['String'];
  priceCents: Scalars['Int'];
  category: ItemCategory;
  shippingRequired: Scalars['Boolean'];
  scheduleFrequency: Maybe<RequisitionScheduleFrequency>;
};

export enum ItemCategory {
  Beans = 'Beans'
}

export type Kds = {
  __typename?: 'Kds';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  merchant: Merchant;
  authorizedBy: User;
  active: Scalars['Boolean'];
  /** Orders that are not on queue yet */
  placedOrders: Maybe<Array<Order>>;
  orderQueue: Maybe<Array<Order>>;
  todaysCompletedOrders: Maybe<Array<Order>>;
  completedOrders: Array<Order>;
  os: Maybe<Scalars['String']>;
  appVersion: Maybe<Scalars['String']>;
};


export type KdsTodaysCompletedOrdersArgs = {
  offset: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
};


export type KdsCompletedOrdersArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type KdsEvent = {
  __typename?: 'KdsEvent';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  timestamp: Maybe<Scalars['Instant']>;
  name: Scalars['String'];
  extras: Maybe<Scalars['String']>;
};

export type KdsEventInput = {
  name: Scalars['String'];
  timestamp: Maybe<Scalars['Instant']>;
  kdsUuid: Scalars['String'];
  extras: Maybe<Scalars['String']>;
};

export type KdsIntegrationDevice = {
  __typename?: 'KdsIntegrationDevice';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  name: Scalars['String'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type KeyValueInput = {
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type Lead = {
  __typename?: 'Lead';
  name: Maybe<Scalars['String']>;
  phoneNumberHasAccount: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
};

export type LeadInput = {
  name: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};

export type LibraryImage = {
  __typename?: 'LibraryImage';
  id: Scalars['ID'];
  imageUri: Scalars['String'];
  imageCode: Scalars['String'];
};


export type LoginResponse = {
  __typename?: 'LoginResponse';
  type: LoginResponseType;
  duplicateAccounts: Maybe<Array<Maybe<DuplicateUser>>>;
  me: Maybe<User>;
};

export enum LoginResponseType {
  ExistingUser = 'EXISTING_USER',
  NewUser = 'NEW_USER',
  DuplicateUser = 'DUPLICATE_USER'
}

export type Market = {
  __typename?: 'Market';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  geographicCenterLatitude: Maybe<Scalars['Float']>;
  geographicCenterLongitude: Maybe<Scalars['Float']>;
  geographicRadiusKm: Maybe<Scalars['Int']>;
  launchPromotion: Maybe<Promotion>;
};

export type MarketInput = {
  id: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  geographicCenterLatitude: Maybe<Scalars['Float']>;
  geographicCenterLongitude: Maybe<Scalars['Float']>;
  geographicRadiusKm: Maybe<Scalars['Int']>;
  launchPromotionId: Maybe<Scalars['ID']>;
};

export type MarketPushInput = {
  messageType: MessageType;
  marketId: Scalars['ID'];
  content: PushContentInput;
};

export enum MarketType {
  Geo = 'GEO'
}

export type MenuSyncConfig = {
  __typename?: 'MenuSyncConfig';
  id: Scalars['ID'];
  merchant: Merchant;
  source: ExternalDataSource;
  externalOrganizationId: Scalars['String'];
  externalLocationId: Scalars['String'];
  onlyWebCatalogItems: Scalars['Boolean'];
  active: Scalars['Boolean'];
};

export type Merchant = {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  organization: Organization;
  location: MerchantLocation;
  active: Scalars['Boolean'];
  catalogs: Maybe<Array<Catalog>>;
  activeCatalog: Maybe<Catalog>;
  products: Maybe<Array<Product>>;
  operationHours: Maybe<Array<MerchantHours>>;
  kdss: Maybe<Array<Kds>>;
  archived: Scalars['Boolean'];
  taxRate: Maybe<Scalars['Float']>;
  images: Maybe<Array<MerchantImage>>;
  merchantContacts: Maybe<Array<MerchantContact>>;
  diningOptions: Array<DiningOption>;
  deepLinks: Maybe<Array<MerchantDeepLink>>;
  stripeCustomerId: Maybe<Scalars['String']>;
  merchantFeeConfig: MerchantFeeConfig;
  kdsIntegrations: Maybe<Array<MerchantKdsIntegration>>;
  outputDestinations: Maybe<Array<OutputDestination>>;
  freshLocation: Maybe<FreshLocationSync>;
  readableId: Maybe<Scalars['String']>;
  settings: Array<MerchantSettingOutput>;
  source: Maybe<ExternalDataSource>;
  sourceSystemId: Maybe<Scalars['String']>;
  servingLocations: Maybe<Array<FulfillmentLocation>>;
  categories: Maybe<Array<Category>>;
  /** legacy */
  diningOption: Maybe<MerchantDiningOption>;
};

export type MerchantContact = {
  __typename?: 'MerchantContact';
  id: Scalars['ID'];
  merchant: Merchant;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MerchantDeepLink = {
  __typename?: 'MerchantDeepLink';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  link: Scalars['String'];
  description: Scalars['String'];
};

export enum MerchantDiningOption {
  ForHere = 'FOR_HERE',
  Takeaway = 'TAKEAWAY',
  ForHereAndTakeaway = 'FOR_HERE_AND_TAKEAWAY'
}

export type MerchantFeeConfig = {
  __typename?: 'MerchantFeeConfig';
  id: Scalars['ID'];
  default: Scalars['Boolean'];
  staticFeeInUsCents: Scalars['Int'];
  percentageFee: Scalars['Float'];
};

export type MerchantHours = {
  __typename?: 'MerchantHours';
  id: Scalars['ID'];
  merchant: Merchant;
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
  durationMinutes: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  isActive: Scalars['Boolean'];
};

export type MerchantHoursInput = {
  weekDay: Scalars['Int'];
  timeIntervals: Maybe<Array<TimeIntervalInput>>;
};

export type MerchantImage = {
  __typename?: 'MerchantImage';
  id: Scalars['ID'];
  imageUri: Scalars['String'];
  createdOn: Scalars['Instant'];
  sortOrder: Scalars['Int'];
};

export type MerchantImageInput = {
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export type MerchantInput = {
  id: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organizationId: Maybe<Scalars['ID']>;
  location: MerchantLocationInput;
  hours: Array<MerchantHoursInput>;
  taxRate: Scalars['Float'];
  diningOptions: Array<DiningOption>;
};

export type MerchantKdsIntegration = {
  __typename?: 'MerchantKdsIntegration';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  type: IntegrationType;
  locationId: Scalars['String'];
  displayName: Scalars['String'];
  active: Scalars['Boolean'];
  devices: Maybe<Array<KdsIntegrationDevice>>;
  sendOrdersToRdy: Scalars['Boolean'];
};

export type MerchantLocation = {
  __typename?: 'MerchantLocation';
  id: Scalars['ID'];
  street: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  zipCode: Scalars['String'];
  timeZone: Maybe<Scalars['String']>;
  geoFenceType: GeoFenceType;
};

export type MerchantLocationInput = {
  id: Maybe<Scalars['ID']>;
  street: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  geoFenceType: GeoFenceType;
};

export type MerchantPayout = {
  __typename?: 'MerchantPayout';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  merchant: Merchant;
  periodStartDate: Scalars['Instant'];
  periodEndDate: Scalars['Instant'];
  payoutAmount: Scalars['Int'];
  payoutType: PayoutType;
  manualPayout: Scalars['Boolean'];
  orders: Maybe<Array<OrderReportingDetails>>;
  tokenOrders: Maybe<Array<OrderReportingDetails>>;
  referrals: Maybe<Array<MerchantReferral>>;
  discountOrders: Maybe<Array<OrderReportingDetails>>;
  stripePaymentUrl: Maybe<Scalars['String']>;
  paidOn: Maybe<Scalars['Instant']>;
};

export type MerchantReferral = {
  __typename?: 'MerchantReferral';
  id: Scalars['ID'];
  deepLinkClick: DeepLinkClick;
  createdOn: Scalars['Instant'];
};

export type MerchantSettingOutput = {
  __typename?: 'MerchantSettingOutput';
  name: Scalars['String'];
  description: Scalars['String'];
  category: Scalars['String'];
  value: Maybe<Scalars['String']>;
  valueType: SettingValueType;
};

export type MerchantSubscription = {
  __typename?: 'MerchantSubscription';
  id: Scalars['ID'];
  subscriptionPlan: Maybe<SubscriptionProduct>;
  merchant: Merchant;
  stripeSubscription: Maybe<StripeSubscription>;
  stripeSetupDate: Maybe<Scalars['Instant']>;
  trialEndDate: Maybe<Scalars['Instant']>;
  organizationManaged: Scalars['Boolean'];
  platformPricing: Maybe<PlatformPricing>;
  termPricing: Maybe<Scalars['Boolean']>;
  subscriptionStatus: Maybe<SubscriptionStatus>;
};

export type MerchantSubscriptionFee = {
  __typename?: 'MerchantSubscriptionFee';
  id: Scalars['ID'];
  subscriptionFee: SubscriptionProduct;
  merchant: Merchant;
  stripeInvoiceItemId: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
};

export enum MessageType {
  EmailOrderStatusOfflineKdsAdmin = 'EMAIL_ORDER_STATUS_OFFLINE_KDS_ADMIN',
  EmailStaleOrderRecap = 'EMAIL_STALE_ORDER_RECAP',
  PushUser = 'PUSH_USER',
  PushMarket = 'PUSH_MARKET',
  PushGeneral = 'PUSH_GENERAL',
  PushOrderStatus = 'PUSH_ORDER_STATUS',
  PushOrderStatusPreparing = 'PUSH_ORDER_STATUS_PREPARING',
  PushOrderStatusCompleted = 'PUSH_ORDER_STATUS_COMPLETED',
  PushOrderSuggestion = 'PUSH_ORDER_SUGGESTION',
  PushOrderSuggestionSecondOrder = 'PUSH_ORDER_SUGGESTION_SECOND_ORDER',
  PushPromotion = 'PUSH_PROMOTION',
  SmsOrderStatusOfflineKdsAdmin = 'SMS_ORDER_STATUS_OFFLINE_KDS_ADMIN',
  TeamsOrderStatusOfflineKds = 'TEAMS_ORDER_STATUS_OFFLINE_KDS',
  SmsOrderStatusStaleUser = 'SMS_ORDER_STATUS_STALE_USER',
  TeamsOrderStatusStale = 'TEAMS_ORDER_STATUS_STALE'
}

export type Modifier = {
  __typename?: 'Modifier';
  id: Scalars['ID'];
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  childSelectionType: Maybe<ModifierChildSelectionType>;
  children: Maybe<Array<Modifier>>;
  allChildren: Maybe<Array<Modifier>>;
  priceCents: Scalars['Int'];
  priceTokens: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  selectedName: Maybe<Scalars['String']>;
  defaultSelectionIds: Maybe<Array<Scalars['ID']>>;
  merchantId: Maybe<Scalars['ID']>;
  archived: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  inventory: Maybe<Inventory>;
  availability: Maybe<InventoryAvailability>;
  /** legacy */
  selected: Maybe<Scalars['Boolean']>;
};

export type ModifierChildInput = {
  modifierId: Scalars['ID'];
  sortOrder: Maybe<Scalars['Int']>;
};

export enum ModifierChildSelectionType {
  SingleSelect = 'SINGLE_SELECT',
  MultiSelect = 'MULTI_SELECT'
}

export type ModifierInput = {
  id: Maybe<Scalars['ID']>;
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  childSelectionType: Maybe<ModifierChildSelectionType>;
  priceCents: Maybe<Scalars['Int']>;
  selectedName: Maybe<Scalars['String']>;
  defaultSelectionIds: Maybe<Array<Scalars['ID']>>;
  options: Maybe<Array<ModifierChildInput>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  setFeatureFlag: Maybe<FeatureFlag>;
  createCsrfToken: CsrfToken;
  assignRdyInternalSquareProductionAccessToken: Scalars['Boolean'];
  leadReceived: Scalars['Boolean'];
  addToMyCart: Cart;
  updateMyCart: Cart;
  createCatalog: Catalog;
  updateCatalog: Catalog;
  deleteCatalog: Scalars['Boolean'];
  updateSuggestedItems: Catalog;
  copyAllProductsFromMerchant: Scalars['Boolean'];
  processInventoryRestocks: Scalars['Boolean'];
  createProduct: Product;
  updateProduct: Product;
  duplicateProduct: Product;
  updateProductActive: Product;
  updateProductsActive: Array<Product>;
  archiveProduct: Product;
  archiveProducts: Array<Product>;
  createModifier: Modifier;
  updateModifier: Modifier;
  updateModifierActive: Modifier;
  archiveModifier: Modifier;
  archiveModifiers: Array<Modifier>;
  updateModifierDefaultSelections: Modifier;
  updateProductInventory: Inventory;
  updateTopModifierUsage: Array<Product>;
  createTemplateMenu: Maybe<Array<Product>>;
  createCategory: Maybe<Category>;
  updateCategorySortOrders: Maybe<Scalars['Boolean']>;
  updateProductSortOrders: Maybe<Scalars['Boolean']>;
  updateCategoryName: Category;
  updateProductName: Product;
  archiveCategory: Maybe<Scalars['Boolean']>;
  updateProductCategories: Maybe<Scalars['Boolean']>;
  saveContractSignature: ContractSignature;
  saveContract: Contract;
  updateMenuSyncConfigActive: Maybe<MenuSyncConfig>;
  /** Fresh */
  syncFreshMenu: Scalars['Boolean'];
  createMerchantFromLocation: Maybe<Merchant>;
  syncSquareMenu: Scalars['Boolean'];
  /** Square */
  updateSquareOrderStates: Scalars['Boolean'];
  generateSquareOAuthToken: Scalars['Boolean'];
  refreshSquareOAuthTokens: Scalars['Int'];
  syncSquareLocations: Array<Merchant>;
  archiveFulfillmentLocation: Scalars['Boolean'];
  createFulfillmentLocation: FulfillmentLocation;
  generateQRCodeDisplay: Scalars['String'];
  uploadMerchantImage: MerchantImage;
  updateMerchantImages: Array<MerchantImage>;
  deleteMerchantImage: Scalars['Boolean'];
  uploadImage: Scalars['String'];
  uploadImageByUrl: Scalars['String'];
  registerIoTDevice: IoTDevice;
  processIoTEvent: IoTEvent;
  setUpPass: PassConfig;
  registerKds: Kds;
  unlinkKds: Scalars['Boolean'];
  updateKdsVersionInfo: Kds;
  kdsHeartbeat: Scalars['Boolean'];
  kdsHeartbeatByDeviceUuid: Scalars['Boolean'];
  checkKdsHealth: Scalars['Boolean'];
  createKdsEvents: Array<KdsEvent>;
  createMerchantKdsIntegration: MerchantKdsIntegration;
  archiveMerchantKdsIntegration: Scalars['Boolean'];
  updateMerchantKdsIntegrationActive: MerchantKdsIntegration;
  setupKdsIntegrationDevices: MerchantKdsIntegration;
  trackMyLocation: Maybe<Scalars['Boolean']>;
  saveDeviceLocation: Maybe<Scalars['Boolean']>;
  archiveMerchant: Merchant;
  createMerchant: Merchant;
  updateMerchant: Merchant;
  updateMerchantActive: Merchant;
  archiveMerchantPaymentAccount: Merchant;
  setMerchantPaymentAccount: Merchant;
  createMarket: Market;
  updateMarket: Market;
  moveMerchantToDifferentOrganization: Scalars['Boolean'];
  sendStaleOrderRecap: Scalars['Boolean'];
  updateMerchantReadableId: Merchant;
  updateMerchantBooleanSetting: MerchantSettingOutput;
  updateMerchantSourceSystem: Merchant;
  createMerchantContact: MerchantContact;
  updateMerchantContact: MerchantContact;
  deleteMerchantContact: Scalars['Boolean'];
  archiveMerchantFeeConfig: Scalars['Boolean'];
  createMerchantFeeConfig: MerchantFeeConfig;
  setDefaultMerchantFeeConfig: Array<MerchantFeeConfig>;
  setMerchantFeeConfigForMerchant: Merchant;
  createSubscriptionPlan: SubscriptionProduct;
  createSubscriptionFee: SubscriptionProduct;
  setMerchantSubscription: MerchantSubscription;
  setMerchantSubscriptions: Array<MerchantSubscription>;
  addMerchantSubscriptionFee: MerchantSubscriptionFee;
  createStripeSubscription: Maybe<SubscriptionPaymentIntent>;
  refreshSubscriptionStatus: Array<MerchantSubscription>;
  updateSubscriptionPaymentMethod: StripeSubscription;
  updateMerchantsOnOrgSubscription: Array<MerchantSubscription>;
  cancelStripeSubscription: Array<MerchantSubscription>;
  updateTrialSubscriptions: Maybe<Scalars['Boolean']>;
  migrateTrialingSubscriptions: Maybe<Scalars['Boolean']>;
  setDefaultSubscriptionProduct: Maybe<Scalars['Boolean']>;
  savePlatformPricing: Maybe<PlatformPricing>;
  createPlatformPricingPaymentIntent: Maybe<SubscriptionPaymentIntent>;
  sendSupportEmail: Scalars['Boolean'];
  sendPushToMarket: Scalars['Boolean'];
  sendPushToUsers: Scalars['Boolean'];
  processLeads: Array<Lead>;
  syncCustomerUserToCustomerIO: Scalars['Boolean'];
  syncPartnerUserToCustomerIO: Scalars['Boolean'];
  syncOrganizationToCustomerIO: Scalars['Boolean'];
  validateOrder: Maybe<Scalars['Boolean']>;
  generateOrderPaymentSummary: PaymentSummary;
  checkoutOrder: CheckoutConfirmation;
  createOrderEvents: Order;
  addOrderToQueue: Maybe<Order>;
  checkPendingOrderPaymentIntents: Maybe<Scalars['Boolean']>;
  checkPendingTimerOrders: Maybe<Scalars['Boolean']>;
  checkStaleOrders: Maybe<Scalars['Boolean']>;
  checkPendingFreshKdsOrders: Maybe<Scalars['Boolean']>;
  processOrderItemEvent: OrderItemEvent;
  refundOrder: Maybe<Array<OrderTransactionRefund>>;
  refundOrderItem: Maybe<Array<OrderTransactionRefund>>;
  updateOutputDestinationCategories: Array<OutputDestination>;
  initializeOrder: InitializeOrderOutput;
  createOrganization: Organization;
  updateOrganization: Organization;
  archiveOrganization: Organization;
  setupOrganization: Organization;
  setOrganizationPaymentAccount: Organization;
  archiveOrganizationPaymentAccount: Organization;
  addOrganizationUserRole: Maybe<Scalars['Boolean']>;
  removeOrganizationUserRole: Maybe<Scalars['Boolean']>;
  updateOrganizationReadableId: Organization;
  updateOrganizationBooleanSetting: OrganizationSettingOutput;
  updateOrganizationNumericSetting: OrganizationSettingOutput;
  saveMyPaymentMethod: PaymentMethod;
  deletePaymentMethod: Maybe<PaymentMethod>;
  setupPaymentAccount: Scalars['String'];
  createSetupIntent: Scalars['String'];
  syncPayout: Scalars['Boolean'];
  generatePayout: MerchantPayout;
  payoutMerchant: Maybe<MerchantPayout>;
  prepareMerchantPayouts: Scalars['Boolean'];
  createPromotion: Promotion;
  createMerchantPromotion: Promotion;
  adjudicatePromotion: Promotion;
  archivePromotion: Promotion;
  createPromoCode: PromoCode;
  processPromoCode: ProcessPromoCodeResponse;
  checkPromotionRewardDispensation: Maybe<Scalars['Boolean']>;
  checkEnterUserInMarketLaunchPromo: Array<Token>;
  notifyPendingPromotions: Maybe<Scalars['Boolean']>;
  notifyActivePromotions: Maybe<Scalars['Boolean']>;
  checkStaleUserPromotions: Maybe<Scalars['Boolean']>;
  updatePromotion: Promotion;
  updatePromotionCode: PromoCode;
  createQuest: Quest;
  archiveQuest: Quest;
  updateQuestStatus: Quest;
  createQuestStepEffectModifier: QuestStepEffectModifier;
  createRdyPoints: UserRdyPoints;
  grantTokens: Array<Token>;
  redeemPoints: RewardData;
  redeemPointsByOrganization: RewardData;
  initializeReferral: Referral;
  initializeRequisition: RequisitionPurchase;
  updateRequisition: Requisition;
  initializeScheduledRequisitions: Array<RequisitionPurchase>;
  confirmRequisitionPurchases: Array<RequisitionPurchasePaymentIntent>;
  chargeSchedule: RequisitionPurchase;
  deleteMyAccount: Scalars['Boolean'];
  updateMyDeviceInfo: Maybe<Scalars['Boolean']>;
  updateMyProfile: UserProfile;
  acceptUserInvite: User;
  sendUserInvite: Scalars['String'];
  updateMyFavoriteMerchant: Maybe<FavoriteMerchant>;
  processDeepLinkClicks: Maybe<Array<DeepLinkClick>>;
  linkAccount: User;
  validateAndCreateUser: LoginResponse;
  updateMyFavoriteOrder: Maybe<FavoriteOrder>;
  updateBooleanSetting: UserSettingOutput;
};


export type MutationSetFeatureFlagArgs = {
  featureFlagName: Maybe<FeatureFlagName>;
  enabled: Scalars['Boolean'];
  organizationId: Maybe<Scalars['ID']>;
  merchantId: Maybe<Scalars['ID']>;
};


export type MutationCreateCsrfTokenArgs = {
  data: Maybe<Scalars['String']>;
};


export type MutationAssignRdyInternalSquareProductionAccessTokenArgs = {
  userId: Scalars['ID'];
};


export type MutationLeadReceivedArgs = {
  input: CafeLeadInput;
};


export type MutationAddToMyCartArgs = {
  input: CartInput;
};


export type MutationUpdateMyCartArgs = {
  input: CartInput;
};


export type MutationCreateCatalogArgs = {
  input: CreateCatalogInput;
};


export type MutationUpdateCatalogArgs = {
  input: UpdateCatalogInput;
};


export type MutationDeleteCatalogArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateSuggestedItemsArgs = {
  input: UpdateSuggestedItemsInput;
};


export type MutationCopyAllProductsFromMerchantArgs = {
  input: CopyAllProductsFromMerchantInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationDuplicateProductArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateProductActiveArgs = {
  productId: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationUpdateProductsActiveArgs = {
  productIds: Array<Scalars['ID']>;
  active: Scalars['Boolean'];
};


export type MutationArchiveProductArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateModifierArgs = {
  input: ModifierInput;
};


export type MutationUpdateModifierArgs = {
  input: UpdateModifierInput;
};


export type MutationUpdateModifierActiveArgs = {
  modifierId: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationArchiveModifierArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveModifiersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateModifierDefaultSelectionsArgs = {
  topModifierId: Scalars['ID'];
  selectionIds: Array<Scalars['ID']>;
};


export type MutationUpdateProductInventoryArgs = {
  productId: Scalars['ID'];
  input: ProductInventoryInput;
};


export type MutationUpdateTopModifierUsageArgs = {
  input: Maybe<UpdateModifierUsageInput>;
};


export type MutationCreateTemplateMenuArgs = {
  merchantId: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  input: Maybe<CreateCategoryInput>;
};


export type MutationUpdateCategorySortOrdersArgs = {
  inputs: Array<UpdateSortOrderInput>;
};


export type MutationUpdateProductSortOrdersArgs = {
  inputs: Array<UpdateSortOrderInput>;
};


export type MutationUpdateCategoryNameArgs = {
  categoryId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateProductNameArgs = {
  productId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationArchiveCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type MutationUpdateProductCategoriesArgs = {
  inputs: Array<UpdateProductCategoryInput>;
};


export type MutationSaveContractSignatureArgs = {
  contractId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type MutationSaveContractArgs = {
  input: ContractInput;
};


export type MutationUpdateMenuSyncConfigActiveArgs = {
  merchantId: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationSyncFreshMenuArgs = {
  merchantId: Scalars['ID'];
  brandId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCreateMerchantFromLocationArgs = {
  organizationId: Scalars['ID'];
  freshLocationId: Maybe<Scalars['String']>;
  brandId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSyncSquareMenuArgs = {
  merchantId: Scalars['ID'];
  baseUrl: Scalars['String'];
  onlyProductsWithCategories: Scalars['Boolean'];
  doNotImportCategories: Scalars['Boolean'];
};


export type MutationGenerateSquareOAuthTokenArgs = {
  code: Scalars['String'];
};


export type MutationSyncSquareLocationsArgs = {
  organizationId: Scalars['ID'];
  inputs: Array<SquareSyncInput>;
};


export type MutationArchiveFulfillmentLocationArgs = {
  fulfillmentLocationId: Scalars['ID'];
};


export type MutationCreateFulfillmentLocationArgs = {
  input: CreateFulfillmentLocationInput;
};


export type MutationGenerateQrCodeDisplayArgs = {
  fulfillmentLocationId: Scalars['ID'];
};


export type MutationUploadMerchantImageArgs = {
  file: Scalars['Upload'];
  merchantId: Scalars['ID'];
  sortOrder: Scalars['Int'];
};


export type MutationUpdateMerchantImagesArgs = {
  input: Maybe<Array<MerchantImageInput>>;
  merchantId: Scalars['ID'];
};


export type MutationDeleteMerchantImageArgs = {
  id: Scalars['ID'];
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadImageByUrlArgs = {
  url: Scalars['String'];
};


export type MutationRegisterIoTDeviceArgs = {
  input: RegisterIoTDeviceInput;
};


export type MutationProcessIoTEventArgs = {
  input: IoTEventInput;
};


export type MutationSetUpPassArgs = {
  deviceUuid: Scalars['String'];
  orderId: Scalars['ID'];
};


export type MutationRegisterKdsArgs = {
  input: RegisterKdsInput;
};


export type MutationUnlinkKdsArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateKdsVersionInfoArgs = {
  id: Scalars['ID'];
  os: Scalars['String'];
  appVersion: Scalars['String'];
};


export type MutationKdsHeartbeatArgs = {
  id: Scalars['ID'];
};


export type MutationKdsHeartbeatByDeviceUuidArgs = {
  deviceUuid: Scalars['String'];
};


export type MutationCreateKdsEventsArgs = {
  events: Array<KdsEventInput>;
};


export type MutationCreateMerchantKdsIntegrationArgs = {
  input: CreateMerchantKdsIntegrationInput;
};


export type MutationArchiveMerchantKdsIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMerchantKdsIntegrationActiveArgs = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationSetupKdsIntegrationDevicesArgs = {
  integrationId: Scalars['ID'];
};


export type MutationTrackMyLocationArgs = {
  coordinates: Coordinates;
};


export type MutationSaveDeviceLocationArgs = {
  deviceId: Scalars['String'];
  coordinates: Coordinates;
};


export type MutationArchiveMerchantArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMerchantArgs = {
  input: MerchantInput;
};


export type MutationUpdateMerchantArgs = {
  input: MerchantInput;
};


export type MutationUpdateMerchantActiveArgs = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationArchiveMerchantPaymentAccountArgs = {
  id: Scalars['ID'];
};


export type MutationSetMerchantPaymentAccountArgs = {
  id: Scalars['ID'];
  stripeAccountId: Scalars['String'];
};


export type MutationCreateMarketArgs = {
  input: MarketInput;
};


export type MutationUpdateMarketArgs = {
  input: MarketInput;
};


export type MutationMoveMerchantToDifferentOrganizationArgs = {
  input: MoveMerchantToDifferentOrganizationInput;
};


export type MutationSendStaleOrderRecapArgs = {
  hourToSendEmailPst: Maybe<Scalars['Int']>;
};


export type MutationUpdateMerchantReadableIdArgs = {
  merchantId: Scalars['ID'];
  readableId: Scalars['String'];
};


export type MutationUpdateMerchantBooleanSettingArgs = {
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Boolean'];
};


export type MutationUpdateMerchantSourceSystemArgs = {
  merchantId: Scalars['ID'];
  sourceSystem: ExternalDataSource;
  sourceSystemId: Scalars['String'];
  syncMenu: Scalars['Boolean'];
};


export type MutationCreateMerchantContactArgs = {
  input: CreateMerchantContactInput;
};


export type MutationUpdateMerchantContactArgs = {
  input: UpdateMerchantContactInput;
};


export type MutationDeleteMerchantContactArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveMerchantFeeConfigArgs = {
  merchantFeeConfigId: Scalars['ID'];
};


export type MutationCreateMerchantFeeConfigArgs = {
  input: CreateMerchantFeeConfigInput;
};


export type MutationSetDefaultMerchantFeeConfigArgs = {
  merchantFeeConfigId: Scalars['ID'];
};


export type MutationSetMerchantFeeConfigForMerchantArgs = {
  merchantId: Scalars['ID'];
  merchantFeeConfigId: Scalars['ID'];
};


export type MutationCreateSubscriptionPlanArgs = {
  input: CreateSubscriptionPlanInput;
};


export type MutationCreateSubscriptionFeeArgs = {
  input: CreateSubscriptionFeeInput;
};


export type MutationSetMerchantSubscriptionArgs = {
  input: SetMerchantSubscriptionInput;
};


export type MutationSetMerchantSubscriptionsArgs = {
  inputs: Array<SetMerchantSubscriptionInput>;
};


export type MutationAddMerchantSubscriptionFeeArgs = {
  input: AddMerchantSubscriptionFeeInput;
};


export type MutationCreateStripeSubscriptionArgs = {
  merchantIds: Array<Scalars['ID']>;
  organizationManaged: Scalars['Boolean'];
};


export type MutationRefreshSubscriptionStatusArgs = {
  merchantIds: Array<Scalars['ID']>;
};


export type MutationUpdateSubscriptionPaymentMethodArgs = {
  stripeSubscriptionId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
};


export type MutationUpdateMerchantsOnOrgSubscriptionArgs = {
  merchantIds: Array<Scalars['ID']>;
};


export type MutationCancelStripeSubscriptionArgs = {
  stripeSubscriptionId: Scalars['ID'];
};


export type MutationUpdateTrialSubscriptionsArgs = {
  currentDate: Maybe<Scalars['Instant']>;
};


export type MutationSetDefaultSubscriptionProductArgs = {
  subscriptionProductId: Scalars['ID'];
  defaultSuscriptionType: DefaultSuscriptionType;
};


export type MutationSavePlatformPricingArgs = {
  input: PlatformPricingInput;
};


export type MutationCreatePlatformPricingPaymentIntentArgs = {
  merchantIds: Array<Scalars['ID']>;
};


export type MutationSendSupportEmailArgs = {
  input: SendSupportEmailInput;
};


export type MutationSendPushToMarketArgs = {
  input: MarketPushInput;
};


export type MutationSendPushToUsersArgs = {
  input: UserPushInput;
};


export type MutationProcessLeadsArgs = {
  leads: Array<LeadInput>;
};


export type MutationSyncCustomerUserToCustomerIoArgs = {
  userId: Scalars['ID'];
};


export type MutationSyncPartnerUserToCustomerIoArgs = {
  userId: Scalars['ID'];
};


export type MutationSyncOrganizationToCustomerIoArgs = {
  organizationId: Scalars['ID'];
};


export type MutationValidateOrderArgs = {
  input: OrderInput;
};


export type MutationGenerateOrderPaymentSummaryArgs = {
  input: GenerateOrderPaymentSummaryInput;
};


export type MutationCheckoutOrderArgs = {
  input: CheckoutOrderInput;
};


export type MutationCreateOrderEventsArgs = {
  input: CreateOrderEventsInput;
};


export type MutationAddOrderToQueueArgs = {
  id: Scalars['ID'];
};


export type MutationCheckPendingOrderPaymentIntentsArgs = {
  since: Maybe<Scalars['Instant']>;
};


export type MutationProcessOrderItemEventArgs = {
  input: OrderItemEventInput;
};


export type MutationRefundOrderArgs = {
  id: Scalars['ID'];
};


export type MutationRefundOrderItemArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateOutputDestinationCategoriesArgs = {
  input: Maybe<UpdateOutputDestinationCategoriesInput>;
};


export type MutationInitializeOrderArgs = {
  input: InitializeOrderInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationArchiveOrganizationArgs = {
  id: Scalars['ID'];
};


export type MutationSetupOrganizationArgs = {
  input: SetupOrganizationInput;
};


export type MutationSetOrganizationPaymentAccountArgs = {
  id: Scalars['ID'];
  stripeAccountId: Scalars['String'];
};


export type MutationArchiveOrganizationPaymentAccountArgs = {
  id: Scalars['ID'];
};


export type MutationAddOrganizationUserRoleArgs = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: Maybe<Role>;
};


export type MutationRemoveOrganizationUserRoleArgs = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: Maybe<Role>;
};


export type MutationUpdateOrganizationReadableIdArgs = {
  organizationId: Scalars['ID'];
  readableId: Scalars['String'];
};


export type MutationUpdateOrganizationBooleanSettingArgs = {
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Boolean'];
};


export type MutationUpdateOrganizationNumericSettingArgs = {
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Int'];
};


export type MutationSaveMyPaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationSetupPaymentAccountArgs = {
  organizationId: Scalars['ID'];
  merchantId: Maybe<Scalars['ID']>;
};


export type MutationSyncPayoutArgs = {
  stripeAccountId: Scalars['String'];
  payoutId: Scalars['String'];
};


export type MutationGeneratePayoutArgs = {
  merchantId: Scalars['ID'];
  endDate: Scalars['Instant'];
  payoutType: PayoutType;
};


export type MutationPayoutMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type MutationPrepareMerchantPayoutsArgs = {
  endDate: Scalars['Instant'];
};


export type MutationCreatePromotionArgs = {
  input: PromotionInput;
};


export type MutationCreateMerchantPromotionArgs = {
  input: PromotionInput;
};


export type MutationAdjudicatePromotionArgs = {
  id: Scalars['ID'];
  status: PromotionStatus;
  reason: Maybe<Scalars['String']>;
};


export type MutationArchivePromotionArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePromoCodeArgs = {
  input: PromoCodeInput;
};


export type MutationProcessPromoCodeArgs = {
  code: Scalars['String'];
};


export type MutationCheckEnterUserInMarketLaunchPromoArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type MutationCheckStaleUserPromotionsArgs = {
  hourOfExecutionPst: Scalars['Int'];
};


export type MutationUpdatePromotionArgs = {
  input: PromotionUpdateInput;
};


export type MutationUpdatePromotionCodeArgs = {
  input: PromotionCodeUpdateInput;
};


export type MutationCreateQuestArgs = {
  input: CreateQuestInput;
};


export type MutationArchiveQuestArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateQuestStatusArgs = {
  questId: Scalars['ID'];
  status: QuestStatus;
};


export type MutationCreateQuestStepEffectModifierArgs = {
  input: CreateQuestStepEffectModifierInput;
};


export type MutationCreateRdyPointsArgs = {
  userId: Scalars['ID'];
  points: Scalars['Int'];
};


export type MutationGrantTokensArgs = {
  input: GrantTokensInput;
};


export type MutationRedeemPointsByOrganizationArgs = {
  organizationId: Maybe<Scalars['ID']>;
};


export type MutationInitializeReferralArgs = {
  input: Maybe<InitializeReferralInput>;
};


export type MutationInitializeRequisitionArgs = {
  input: InitializeRequisitionInput;
};


export type MutationUpdateRequisitionArgs = {
  input: UpdateRequisitionInput;
};


export type MutationInitializeScheduledRequisitionsArgs = {
  limit: Scalars['Int'];
  dayOfMonthOverride: Maybe<Scalars['Int']>;
};


export type MutationConfirmRequisitionPurchasesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationChargeScheduleArgs = {
  scheduleId: Scalars['ID'];
};


export type MutationUpdateMyDeviceInfoArgs = {
  input: DeviceInfoInput;
};


export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput;
};


export type MutationAcceptUserInviteArgs = {
  token: Scalars['String'];
};


export type MutationSendUserInviteArgs = {
  input: SendUserInviteInput;
};


export type MutationUpdateMyFavoriteMerchantArgs = {
  merchantId: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
};


export type MutationProcessDeepLinkClicksArgs = {
  clicks: Array<DeepLinkClickInput>;
};


export type MutationLinkAccountArgs = {
  secondaryAccessToken: Scalars['String'];
};


export type MutationValidateAndCreateUserArgs = {
  overrideDuplicateCheck: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMyFavoriteOrderArgs = {
  orderId: Scalars['ID'];
  isFavorite: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateBooleanSettingArgs = {
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type NearbyMerchantsInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  uuid: Maybe<Scalars['String']>;
  createdOn: Scalars['Instant'];
  items: Array<OrderItem>;
  events: Array<OrderEvent>;
  user: User;
  addedToQueueAt: Maybe<Scalars['Instant']>;
  timerTriggerAt: Maybe<Scalars['Instant']>;
  transactions: Maybe<Array<OrderTransaction>>;
  merchant: Merchant;
  state: Maybe<OrderState>;
  diningOption: Maybe<OrderDiningOption>;
  rewards: Maybe<Array<UserRewardAction>>;
  rdyPoints: Maybe<Array<UserRdyPoints>>;
  expectedRewardPoints: Scalars['Int'];
  sourceClient: Maybe<RdyClient>;
  fulfillmentLocation: Maybe<FulfillmentLocation>;
};

export enum OrderDiningOption {
  ForHere = 'FOR_HERE',
  Takeaway = 'TAKEAWAY',
  TableService = 'TABLE_SERVICE'
}

export type OrderEvent = {
  __typename?: 'OrderEvent';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: OrderEventName;
};

export enum OrderEventName {
  AddedToFreshKdsQueue = 'ADDED_TO_FRESH_KDS_QUEUE',
  AddedToFreshKdsQueueWhileKdsUnhealthy = 'ADDED_TO_FRESH_KDS_QUEUE_WHILE_KDS_UNHEALTHY',
  AddedToQueue = 'ADDED_TO_QUEUE',
  AddedToQueueWhileKdsUnhealthy = 'ADDED_TO_QUEUE_WHILE_KDS_UNHEALTHY',
  AllItemsSentToPrinter = 'ALL_ITEMS_SENT_TO_PRINTER',
  AutoCompletedAsSquareOrder = 'AUTO_COMPLETED_AS_SQUARE_ORDER',
  AutoStarted = 'AUTO_STARTED',
  ChowlyOrderConfirmed = 'CHOWLY_ORDER_CONFIRMED',
  ChowlyOrderError = 'CHOWLY_ORDER_ERROR',
  DidNotStartPreparingQuickly = 'DID_NOT_START_PREPARING_QUICKLY',
  EnteredKdsLessFlow = 'ENTERED_KDS_LESS_FLOW',
  ItemsCreated = 'ITEMS_CREATED',
  ItemsReadyForTraditionalPass = 'ITEMS_READY_FOR_TRADITIONAL_PASS',
  ItemsPlacedOnRdyPass = 'ITEMS_PLACED_ON_RDY_PASS',
  ItemsSplitBetweenFreshKds = 'ITEMS_SPLIT_BETWEEN_FRESH_KDS',
  ManuallyClearedFromFreshKdsQueue = 'MANUALLY_CLEARED_FROM_FRESH_KDS_QUEUE',
  ManuallyClearedFromKdsQueue = 'MANUALLY_CLEARED_FROM_KDS_QUEUE',
  ManuallyStarted = 'MANUALLY_STARTED',
  MarkedAsPickedUpViaSquare = 'MARKED_AS_PICKED_UP_VIA_SQUARE',
  MarkedAsPreparingViaSquare = 'MARKED_AS_PREPARING_VIA_SQUARE',
  MarkedAsCompleteViaSquare = 'MARKED_AS_COMPLETE_VIA_SQUARE',
  MarkedAsStale = 'MARKED_AS_STALE',
  PassedToCafe = 'PASSED_TO_CAFE',
  PaymentFulfilled = 'PAYMENT_FULFILLED',
  PaymentRejected = 'PAYMENT_REJECTED',
  PickedUp = 'PICKED_UP',
  PickedUpFromRdyPass = 'PICKED_UP_FROM_RDY_PASS',
  Placed = 'PLACED',
  Preparing = 'PREPARING',
  PrintFailed = 'PRINT_FAILED',
  PrintSucceeded = 'PRINT_SUCCEEDED',
  PendingTriggerFromApi = 'PENDING_TRIGGER_FROM_API',
  PendingTriggerFromLocation = 'PENDING_TRIGGER_FROM_LOCATION',
  PendingTriggerFromAsapSelection = 'PENDING_TRIGGER_FROM_ASAP_SELECTION',
  PendingTriggerFromTimerSelection = 'PENDING_TRIGGER_FROM_TIMER_SELECTION',
  SentToSquareLocation = 'SENT_TO_SQUARE_LOCATION',
  ShownInKds = 'SHOWN_IN_KDS',
  ShownInKdsQueue = 'SHOWN_IN_KDS_QUEUE',
  ShownInKdsPending = 'SHOWN_IN_KDS_PENDING',
  SkippedRdyPass = 'SKIPPED_RDY_PASS',
  TimedOutAfterAutoStart = 'TIMED_OUT_AFTER_AUTO_START',
  TokenBalanceRejected = 'TOKEN_BALANCE_REJECTED',
  TriggeredFromApi = 'TRIGGERED_FROM_API',
  TriggeredFromLocation = 'TRIGGERED_FROM_LOCATION',
  TriggeredFromAsapSelection = 'TRIGGERED_FROM_ASAP_SELECTION',
  TriggeredFromTimerSelection = 'TRIGGERED_FROM_TIMER_SELECTION'
}

export type OrderInput = {
  items: Array<OrderItemInput>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  orderableProduct: Product;
  name: Scalars['String'];
  priceTokens: Scalars['Int'];
  priceCents: Scalars['Int'];
  subtotalChargeTokens: Scalars['Int'];
  subtotalChargeUsCents: Scalars['Int'];
  selectedModifiers: Maybe<Array<OrderItemModifier>>;
  state: OrderItemState;
  order: Order;
  events: Maybe<Array<OrderItemEvent>>;
  refunded: Scalars['Boolean'];
  notes: Maybe<Scalars['String']>;
  destinations: Maybe<Array<OutputDestination>>;
};

export type OrderItemEvent = {
  __typename?: 'OrderItemEvent';
  id: Scalars['ID'];
  name: OrderItemEventName;
  ioTPosition: Maybe<IoTPosition>;
};

export type OrderItemEventInput = {
  name: OrderItemEventName;
  orderItemId: Scalars['ID'];
  deviceUuid: Maybe<Scalars['String']>;
  positionCode: Maybe<PositionCode>;
};

export enum OrderItemEventName {
  ManuallyMarkedAsPickedUp = 'MANUALLY_MARKED_AS_PICKED_UP',
  PutativelyMovedToDevice = 'PUTATIVELY_MOVED_TO_DEVICE',
  PutativelyRemovedFromDevice = 'PUTATIVELY_REMOVED_FROM_DEVICE',
  ShouldMoveToDevice = 'SHOULD_MOVE_TO_DEVICE',
  SkippedDeviceError = 'SKIPPED_DEVICE_ERROR',
  SkippedDeviceFlagDisabled = 'SKIPPED_DEVICE_FLAG_DISABLED',
  SkippedDeviceManual = 'SKIPPED_DEVICE_MANUAL',
  SkippedDeviceNotPassable = 'SKIPPED_DEVICE_NOT_PASSABLE',
  SkippedDeviceNotConnected = 'SKIPPED_DEVICE_NOT_CONNECTED',
  SkippedDevicePositionsFull = 'SKIPPED_DEVICE_POSITIONS_FULL',
  SkippedDeviceTooManyItems = 'SKIPPED_DEVICE_TOO_MANY_ITEMS',
  SentToFreshKdsDevice = 'SENT_TO_FRESH_KDS_DEVICE',
  ShownOnFreshKdsDevice = 'SHOWN_ON_FRESH_KDS_DEVICE'
}

export type OrderItemInput = {
  currencyType: CurrencyType;
  productId: Scalars['ID'];
  modifiers: Maybe<Array<OrderItemModifierInput>>;
  notes: Maybe<Scalars['String']>;
};

export type OrderItemModifier = {
  __typename?: 'OrderItemModifier';
  id: Scalars['ID'];
  priceCents: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  originalModifierId: Scalars['ID'];
  originalTopModifierId: Maybe<Scalars['ID']>;
  originalModifier: Modifier;
  originalTopModifier: Maybe<Modifier>;
};

export type OrderItemModifierInput = {
  parentModifierId: Scalars['ID'];
  selectedModifierId: Scalars['ID'];
};

export type OrderItemPosition = {
  __typename?: 'OrderItemPosition';
  orderItemId: Scalars['ID'];
  deviceUuid: Scalars['String'];
  positionCode: PositionCode;
};

export enum OrderItemState {
  Pending = 'PENDING',
  Prepared = 'PREPARED'
}

export type OrderPaymentIntentUserProfile = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type OrderReportingDetails = {
  __typename?: 'OrderReportingDetails';
  id: Scalars['ID'];
  date: Scalars['Instant'];
  userId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  tokens: Scalars['Int'];
  tokenValue: Scalars['Int'];
  tokenTipValue: Scalars['Int'];
  tokenFeeValue: Scalars['Int'];
  subTotal: Scalars['Int'];
  taxes: Scalars['Int'];
  tip: Scalars['Int'];
  customerFee: Scalars['Int'];
  merchantFee: Scalars['Int'];
  netMerchantPayout: Scalars['Int'];
  merchantDiscountFeeUsCents: Scalars['Int'];
  rdyPromotionDiscountValue: Scalars['Int'];
  rdyPromotionName: Scalars['String'];
  rdyPaidTokenCount: Scalars['Int'];
  organizationPaidTokenCount: Scalars['Int'];
};

export type OrderState = {
  __typename?: 'OrderState';
  name: OrderStateName;
  timestamp: Maybe<Scalars['Instant']>;
};

export enum OrderStateName {
  PaymentFulfilled = 'PAYMENT_FULFILLED',
  PaymentRejected = 'PAYMENT_REJECTED',
  Pending = 'PENDING',
  PickedUpFromRdyPass = 'PICKED_UP_FROM_RDY_PASS',
  Preparing = 'PREPARING',
  ReadyForPickupWithRdyPass = 'READY_FOR_PICKUP_WITH_RDY_PASS',
  ReadyForPickupWithTraditionalPass = 'READY_FOR_PICKUP_WITH_TRADITIONAL_PASS',
  Stale = 'STALE'
}

export enum OrderTiming {
  Asap = 'ASAP',
  OnTimer = 'ON_TIMER',
  OnArrival = 'ON_ARRIVAL',
  /** LEGACY */
  UseLocation = 'USE_LOCATION'
}

export type OrderTransaction = {
  __typename?: 'OrderTransaction';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  tokenValue: Scalars['Int'];
  subtotalUsCents: Scalars['Int'];
  feeUsCents: Scalars['Int'];
  taxUsCents: Scalars['Int'];
  tipUsCents: Scalars['Int'];
  tokenTipUsCents: Scalars['Int'];
  discountUsCents: Scalars['Int'];
  merchantFeeUsCents: Scalars['Int'];
  merchantTokenFeeUsCents: Scalars['Int'];
  merchantDiscountFeeUsCents: Scalars['Int'];
  stripePaymentIntentId: Maybe<Scalars['String']>;
  refunds: Maybe<Array<OrderTransactionRefund>>;
  promotions: Maybe<Array<Promotion>>;
  transactionPromotions: Maybe<Array<OrderTransactionPromotion>>;
  stripePaymentUrl: Maybe<Scalars['String']>;
  tokenValueUsCents: Scalars['Int'];
};

export type OrderTransactionPromotion = {
  __typename?: 'OrderTransactionPromotion';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  usCentsDeductedFromSubtotal: Scalars['Int'];
  promotion: Promotion;
};

export type OrderTransactionRefund = {
  __typename?: 'OrderTransactionRefund';
  id: Scalars['ID'];
  initializedOn: Scalars['Instant'];
  tokenValue: Scalars['Int'];
  amountUsCents: Scalars['Int'];
  stripeId: Maybe<Scalars['String']>;
  appFeeRefundUsCents: Scalars['Int'];
  appFeeRefundStripeId: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  merchants: Maybe<Array<Merchant>>;
  /** Should be used only to fetch for a single Organization */
  users: Maybe<Array<User>>;
  active: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  stripeCustomerId: Maybe<Scalars['String']>;
  readableId: Maybe<Scalars['String']>;
  contractSignatures: Array<ContractSignature>;
  settings: Array<OrganizationSettingOutput>;
};

export type OrganizationSettingOutput = {
  __typename?: 'OrganizationSettingOutput';
  name: Scalars['String'];
  description: Scalars['String'];
  category: Scalars['String'];
  value: Maybe<Scalars['String']>;
  valueType: SettingValueType;
};

export enum OriginType {
  SingleOrigin = 'SINGLE_ORIGIN',
  Blend = 'BLEND'
}

export type OutputDestination = {
  __typename?: 'OutputDestination';
  id: Scalars['ID'];
  type: OutputDestinationType;
  kdsIntegrationDevice: Maybe<KdsIntegrationDevice>;
  categorySettings: Maybe<Array<CategoryOutputDestination>>;
};

export enum OutputDestinationType {
  FreshKdsDevice = 'FRESH_KDS_DEVICE'
}

export type PassConfig = {
  __typename?: 'PassConfig';
  status: PassStatus;
  message: Scalars['String'];
  skipEvent: OrderItemEventName;
  orderItemPositions: Maybe<Array<OrderItemPosition>>;
};

export enum PassStatus {
  UseRdyPass = 'USE_RDY_PASS',
  RdyPassFull = 'RDY_PASS_FULL',
  TooManyOrderItemsForRdyPass = 'TOO_MANY_ORDER_ITEMS_FOR_RDY_PASS',
  NoRdyPassableItems = 'NO_RDY_PASSABLE_ITEMS'
}

export type PaymentAccountValidation = {
  __typename?: 'PaymentAccountValidation';
  hasAccount: Scalars['Boolean'];
  chargesEnabled: Scalars['Boolean'];
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  paymentSummary: PaymentSummary;
  paymentIntentId: Scalars['String'];
  stripeClientSecret: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  status: Scalars['String'];
};

export enum PaymentIntentStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL',
  RequiresPayment = 'REQUIRES_PAYMENT',
  Pending = 'PENDING'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['String'];
  card: Maybe<Card>;
};

export type PaymentMethodInput = {
  id: Scalars['String'];
};

export type PaymentSummary = {
  __typename?: 'PaymentSummary';
  bloomTokens: Scalars['Int'];
  usCents: CurrencySummary;
  promotionsUsedForDiscount: Maybe<Array<DiscountPromotionData>>;
};

export enum PayoutType {
  MerchantReferrals = 'MERCHANT_REFERRALS',
  Tokens = 'TOKENS',
  Orders = 'ORDERS',
  Discounts = 'DISCOUNTS'
}

export type PlatformPricing = {
  __typename?: 'PlatformPricing';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdOn: Scalars['Instant'];
  instantPriceUsCents: Scalars['Int'];
  platformPricingType: PlatformPricingType;
  subscriptionTerm: Maybe<Scalars['Int']>;
  subscriptionProduct: Maybe<SubscriptionProduct>;
};

export type PlatformPricingInput = {
  name: Scalars['String'];
  instantPriceUsCents: Scalars['Int'];
  platformPricingType: PlatformPricingType;
  subscriptionTerm: Maybe<Scalars['Int']>;
  subscriptionMonthlyFeeUsCents: Maybe<Scalars['Int']>;
};

export enum PlatformPricingType {
  Instant = 'INSTANT',
  Standard = 'STANDARD',
  Signature = 'SIGNATURE'
}

export enum PositionCode {
  RdyPass_0 = 'RDY_PASS_0',
  RdyPass_1 = 'RDY_PASS_1',
  RdyPass_2 = 'RDY_PASS_2',
  RdyPass_3 = 'RDY_PASS_3'
}

export type PositionInput = {
  deviceUuid: Scalars['String'];
  positionCode: PositionCode;
};

export type PositionStatus = {
  __typename?: 'PositionStatus';
  deviceUuid: Scalars['String'];
  positionCode: PositionCode;
  orderItem: Maybe<OrderItem>;
};

export type PriceTagInput = {
  itemIds: Array<Scalars['ID']>;
  shippingAddress: Maybe<AddressInput>;
  code: Maybe<Scalars['String']>;
};

export type ProcessPromoCodeResponse = {
  __typename?: 'ProcessPromoCodeResponse';
  promoTitle: Scalars['String'];
  benefactor: Scalars['String'];
  numTokensReceived: Scalars['Int'];
  potentialPercentageDiscount: Scalars['Int'];
  potentialFlatDiscount: Scalars['Int'];
  expiresOn: Maybe<Scalars['Instant']>;
  maxDiscount: Maybe<Scalars['Int']>;
  merchantName: Maybe<Scalars['String']>;
  productTags: Maybe<Array<Scalars['String']>>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  category: Category;
  active: Scalars['Boolean'];
  priceCents: Scalars['Int'];
  sizeInGrams: Maybe<Scalars['Int']>;
  originType: Maybe<OriginType>;
  priceTokens: Scalars['Int'];
  roaster: Maybe<Scalars['String']>;
  roastType: Maybe<RoastType>;
  modifiers: Maybe<Array<Modifier>>;
  allModifiers: Maybe<Array<Modifier>>;
  merchant: Merchant;
  archived: Scalars['Boolean'];
  tags: Maybe<Array<Scalars['String']>>;
  /** deprecated used in capp <= 1.9.x */
  productTags: Maybe<Array<ProductTag>>;
  images: Maybe<Array<ProductImage>>;
  inventory: Maybe<Inventory>;
  availability: Maybe<InventoryAvailability>;
  availabilityWindows: Maybe<Array<AvailabilityWindow>>;
  suspendedUntil: Maybe<Scalars['Instant']>;
  taxRate: Maybe<Scalars['Float']>;
  pickUpPriceUsCents: Maybe<Scalars['Int']>;
  isSynced: Scalars['Boolean'];
  catalogs: Maybe<Array<Catalog>>;
  sortOrder: Maybe<Scalars['Int']>;
  source: Maybe<ExternalDataSource>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  id: Scalars['ID'];
  imageUri: Scalars['String'];
  createdOn: Scalars['Instant'];
  sortOrder: Scalars['Int'];
  libraryImage: Maybe<LibraryImage>;
};

export type ProductImageInput = {
  imageUri: Scalars['String'];
  sortOrder: Scalars['Int'];
  sourceImageUri: Maybe<Scalars['String']>;
  libraryImageId: Maybe<Scalars['ID']>;
};

export type ProductInventoryInput = {
  inventoryType: InventoryType;
  restockQuantity: Maybe<Scalars['Int']>;
  currentQuantity: Maybe<Scalars['Int']>;
  currentlyAvailable: Scalars['Boolean'];
  restockDays: Maybe<Array<Scalars['Int']>>;
};

export type ProductTag = {
  __typename?: 'ProductTag';
  value: Scalars['String'];
  tagType: ProductTagType;
};

export type ProductTagInput = {
  value: Scalars['String'];
  tagType: ProductTagType;
};

export enum ProductTagType {
  Coffee = 'COFFEE',
  Tea = 'TEA',
  BeanOrigin = 'BEAN_ORIGIN',
  FlavorNotes = 'FLAVOR_NOTES'
}

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  promotion: Promotion;
  validStart: Scalars['Instant'];
  validEnd: Maybe<Scalars['Instant']>;
  limitTotalUses: Maybe<Scalars['Int']>;
  deepLink: Maybe<Scalars['String']>;
};

export type PromoCodeInput = {
  promotionId: Scalars['ID'];
  validStart: Scalars['Instant'];
  validEnd: Maybe<Scalars['Instant']>;
  limitTotalUses: Maybe<Scalars['Int']>;
  code: Maybe<Scalars['String']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  customerFacingName: Scalars['String'];
  costOwner: PromotionCostOwner;
  recurring: Scalars['Boolean'];
  start: Scalars['Instant'];
  end: Maybe<Scalars['Instant']>;
  perUserPeriodSeconds: Maybe<Scalars['Int']>;
  frequency: Maybe<Frequency>;
  tokenType: Maybe<TokenType>;
  numberOfTokens: Scalars['Int'];
  dispenseRewardOnEntry: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  newUserOnly: Scalars['Boolean'];
  codes: Maybe<Array<PromoCode>>;
  type: PromotionType;
  limitPerUser: Maybe<Scalars['Int']>;
  flatDiscountUsCents: Maybe<Scalars['Int']>;
  percentageDiscount: Maybe<Scalars['Int']>;
  status: PromotionStatus;
  merchants: Maybe<Array<Merchant>>;
  maxDiscountUsCents: Maybe<Scalars['Int']>;
  productTags: Maybe<Array<Scalars['String']>>;
};

export type PromotionCodeUpdateInput = {
  promotionCodeId: Scalars['ID'];
  validStart: Scalars['Instant'];
  validEnd: Maybe<Scalars['Instant']>;
};

export enum PromotionCostOwner {
  Rdy = 'RDY',
  Merchant = 'MERCHANT'
}

export type PromotionInput = {
  type: PromotionType;
  customerFacingName: Scalars['String'];
  costOwner: PromotionCostOwner;
  recurring: Scalars['Boolean'];
  start: Scalars['Instant'];
  end: Maybe<Scalars['Instant']>;
  perUserPeriodSeconds: Maybe<Scalars['Int']>;
  frequency: Maybe<Frequency>;
  tokenType: Maybe<TokenTypeName>;
  numberOfTokens: Maybe<Scalars['Int']>;
  newUserOnly: Maybe<Scalars['Boolean']>;
  dispenseRewardOnEntry: Maybe<Scalars['Boolean']>;
  limitPerUser: Maybe<Scalars['Int']>;
  flatDiscountUsCents: Maybe<Scalars['Int']>;
  percentageDiscount: Maybe<Scalars['Int']>;
  merchantIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  maxDiscountUsCents: Maybe<Scalars['Int']>;
  promoCode: Maybe<Scalars['String']>;
  notifyUsersOn: Maybe<Scalars['Instant']>;
  productTags: Maybe<Array<Scalars['String']>>;
};

export enum PromotionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export enum PromotionType {
  Discount = 'DISCOUNT',
  Token = 'TOKEN'
}

export type PromotionUpdateInput = {
  promotionId: Scalars['ID'];
  start: Scalars['Instant'];
  end: Maybe<Scalars['Instant']>;
  updateDatesOnAllCodes: Maybe<Scalars['Boolean']>;
};

export enum PushAppAction {
  Vibrate = 'VIBRATE'
}

export type PushContentInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  analyticsTag: Maybe<Scalars['String']>;
  appAction: Maybe<PushAppAction>;
};

export type Query = {
  __typename?: 'Query';
  featureFlag: Maybe<FeatureFlag>;
  appVersion: Maybe<AppVersion>;
  cart: Maybe<Cart>;
  myCart: Maybe<Cart>;
  catalog: Maybe<Catalog>;
  catalogs: Array<Catalog>;
  categories: Array<Category>;
  product: Maybe<Product>;
  products: Array<Product>;
  modifier: Modifier;
  modifiers: Array<Modifier>;
  topModifiersByMerchant: Maybe<Array<Modifier>>;
  modifiersWithoutChildrenByMerchant: Maybe<Array<Modifier>>;
  suggestedProducts: Maybe<Array<SuggestedProduct>>;
  contractByContractType: Array<Contract>;
  getFreshLocations: Array<FreshLocation>;
  getExternalClientSyncs: Array<ExternalClientSync>;
  getExternalClientSyncsByMerchantId: Array<ExternalClientSync>;
  squareLocations: Array<SquareLocation>;
  hasValidSquareOAuthToken: Scalars['Boolean'];
  getMenuSyncConfig: Maybe<MenuSyncConfig>;
  getServingLocationsByMerchantId: Array<FulfillmentLocation>;
  merchantImagesByMerchant: Array<MerchantImage>;
  productImagesByMerchant: Array<ProductImage>;
  libraryImages: Array<LibraryImage>;
  positionStatuses: Array<PositionStatus>;
  kds: Maybe<Kds>;
  kdsById: Kds;
  kdsIntegration: Maybe<MerchantKdsIntegration>;
  merchant: Maybe<Merchant>;
  merchantByReadableId: Maybe<Merchant>;
  merchants: Array<Merchant>;
  merchantsById: Array<Merchant>;
  merchantsByOrganizationId: Array<Merchant>;
  merchantsByReadableOrganizationId: Array<Merchant>;
  nearbyMerchants: Array<Merchant>;
  myFavoriteMerchants: Maybe<Array<Merchant>>;
  myPreviousMerchants: Maybe<Array<Merchant>>;
  markets: Maybe<Array<Market>>;
  suggestedMerchants: Array<Merchant>;
  newMerchants: Array<Merchant>;
  merchantReferralsByMerchantId: Array<MerchantReferral>;
  merchantContact: Maybe<MerchantContact>;
  merchantFeeConfigs: Array<MerchantFeeConfig>;
  stripeSubscription: StripeSubscription;
  merchantSubscription: MerchantSubscription;
  merchantSubscriptionByMerchantId: Maybe<MerchantSubscription>;
  merchantSubscriptionsByOrganizationId: Array<MerchantSubscription>;
  subscriptionPlans: Array<SubscriptionProduct>;
  subscriptionFees: Array<SubscriptionProduct>;
  merchantSubscriptionFeesByMerchantId: Array<MerchantSubscriptionFee>;
  merchantSubscriptionFeesByOrganizationId: Array<MerchantSubscriptionFee>;
  organizationInvoices: Array<SubscriptionInvoice>;
  merchantInvoices: Array<SubscriptionInvoice>;
  defaultSubscriptionProducts: Array<DefaultSubscriptionProduct>;
  getPlatformPricing: Array<PlatformPricing>;
  orderTriggerRadius: Scalars['Int'];
  myOrders: Maybe<Array<Order>>;
  orders: Maybe<Array<Order>>;
  myOrderHistory: Maybe<Array<Order>>;
  myFavoriteOrders: Maybe<Array<Order>>;
  orderEvents: Array<OrderEvent>;
  order: Order;
  orderByUuid: Order;
  ordersByMerchantId: Array<Order>;
  orderReportingDetailsByMerchantId: Array<OrderReportingDetails>;
  ordersByDateRange: Array<Order>;
  organization: Maybe<Organization>;
  organizations: Array<Organization>;
  organizationPayouts: Array<MerchantPayout>;
  myPaymentMethods: Maybe<Array<PaymentMethod>>;
  validatePaymentAccount: Maybe<PaymentAccountValidation>;
  getAccountsNeedingSyncing: Array<Scalars['String']>;
  getPayoutsNeedingSyncing: Array<Scalars['String']>;
  merchantsRequiringPayout: Array<Merchant>;
  merchantPayouts: Array<MerchantPayout>;
  getMerchantsWithUnpaidPayouts: Array<Merchant>;
  promotions: Maybe<Array<Promotion>>;
  promotion: Promotion;
  promotionsByMerchant: Maybe<Array<Promotion>>;
  quests: Array<Quest>;
  quest: Quest;
  questByCodename: Quest;
  tokenTypes: Array<TokenType>;
  myRewardDetails: Array<RewardData>;
  myRewardData: RewardData;
  liveQuests: Array<Quest>;
  userQuestSteps: Array<UserQuestStep>;
  pendingReferral: Maybe<Referral>;
  myCompletedReferrals: Array<Referral>;
  myReferralCode: Scalars['String'];
  beans: Array<Item>;
  priceTag: RequisitionPriceTag;
  schedules: Array<RequisitionSchedule>;
  singles: Array<Requisition>;
  confirmedRequisitions: Array<Requisition>;
  /** Current logged User */
  me: Maybe<User>;
  userInvite: UserInvite;
  userGroups: Maybe<Array<UserGroup>>;
  myUnusedDiscountPromotions: Maybe<Array<Promotion>>;
};


export type QueryFeatureFlagArgs = {
  merchantId: Scalars['ID'];
  featureFlagName: FeatureFlagName;
};


export type QueryAppVersionArgs = {
  appType: AppType;
};


export type QueryCartArgs = {
  userId: Scalars['ID'];
};


export type QueryCatalogArgs = {
  id: Scalars['ID'];
};


export type QueryCatalogsArgs = {
  merchantIds: Array<Scalars['ID']>;
};


export type QueryCategoriesArgs = {
  merchantId: Scalars['ID'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryModifierArgs = {
  id: Scalars['ID'];
};


export type QueryModifiersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryTopModifiersByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QueryModifiersWithoutChildrenByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QuerySuggestedProductsArgs = {
  coordinates: Maybe<Coordinates>;
};


export type QueryContractByContractTypeArgs = {
  contractType: ContractType;
};


export type QueryGetFreshLocationsArgs = {
  organizationId: Scalars['ID'];
  brandId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryGetExternalClientSyncsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetExternalClientSyncsByMerchantIdArgs = {
  merchantId: Scalars['ID'];
};


export type QuerySquareLocationsArgs = {
  organizationId: Scalars['ID'];
  filter: SquareLocationQueryFilter;
};


export type QueryHasValidSquareOAuthTokenArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetMenuSyncConfigArgs = {
  merchantId: Scalars['ID'];
};


export type QueryGetServingLocationsByMerchantIdArgs = {
  merchantId: Scalars['ID'];
};


export type QueryMerchantImagesByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QueryProductImagesByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QueryPositionStatusesArgs = {
  input: Array<PositionInput>;
};


export type QueryKdsArgs = {
  uuid: Scalars['String'];
};


export type QueryKdsByIdArgs = {
  id: Scalars['ID'];
};


export type QueryKdsIntegrationArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantByReadableIdArgs = {
  readableId: Scalars['String'];
};


export type QueryMerchantsArgs = {
  active: Maybe<Scalars['Boolean']>;
};


export type QueryMerchantsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryMerchantsByOrganizationIdArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantsByReadableOrganizationIdArgs = {
  readableOrganizationId: Scalars['ID'];
};


export type QueryNearbyMerchantsArgs = {
  input: NearbyMerchantsInput;
};


export type QueryMarketsArgs = {
  marketType: Maybe<MarketType>;
};


export type QuerySuggestedMerchantsArgs = {
  coordinates: Maybe<Coordinates>;
};


export type QueryNewMerchantsArgs = {
  coordinates: Maybe<Coordinates>;
};


export type QueryMerchantReferralsByMerchantIdArgs = {
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
};


export type QueryMerchantContactArgs = {
  id: Scalars['ID'];
};


export type QueryStripeSubscriptionArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantSubscriptionArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantSubscriptionByMerchantIdArgs = {
  merchantId: Scalars['ID'];
};


export type QueryMerchantSubscriptionsByOrganizationIdArgs = {
  organizationId: Scalars['ID'];
};


export type QueryMerchantSubscriptionFeesByMerchantIdArgs = {
  merchantId: Scalars['ID'];
};


export type QueryMerchantSubscriptionFeesByOrganizationIdArgs = {
  organizationId: Scalars['ID'];
};


export type QueryOrganizationInvoicesArgs = {
  organizationId: Maybe<Scalars['ID']>;
};


export type QueryMerchantInvoicesArgs = {
  merchantId: Maybe<Scalars['ID']>;
};


export type QueryOrderTriggerRadiusArgs = {
  merchantId: Scalars['ID'];
};


export type QueryOrdersArgs = {
  after: Scalars['Instant'];
};


export type QueryMyOrderHistoryArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryOrderEventsArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderByUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryOrdersByMerchantIdArgs = {
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
};


export type QueryOrderReportingDetailsByMerchantIdArgs = {
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
};


export type QueryOrdersByDateRangeArgs = {
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationPayoutsArgs = {
  id: Scalars['ID'];
};


export type QueryValidatePaymentAccountArgs = {
  organizationId: Scalars['ID'];
  merchantId: Maybe<Scalars['ID']>;
};


export type QueryGetAccountsNeedingSyncingArgs = {
  seedTransactionLimit: Scalars['Int'];
};


export type QueryGetPayoutsNeedingSyncingArgs = {
  stripeAccountId: Scalars['String'];
};


export type QueryMerchantsRequiringPayoutArgs = {
  endDate: Scalars['Instant'];
  payoutType: PayoutType;
};


export type QueryMerchantPayoutsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetMerchantsWithUnpaidPayoutsArgs = {
  endDate: Scalars['Instant'];
};


export type QueryPromotionArgs = {
  id: Scalars['ID'];
};


export type QueryPromotionsByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QueryQuestArgs = {
  id: Scalars['ID'];
};


export type QueryQuestByCodenameArgs = {
  codename: Scalars['String'];
};


export type QueryUserQuestStepsArgs = {
  id: Scalars['ID'];
  start: Scalars['Instant'];
  end: Scalars['Instant'];
};


export type QueryPendingReferralArgs = {
  deviceId: Scalars['String'];
};


export type QueryPriceTagArgs = {
  input: PriceTagInput;
};


export type QuerySinglesArgs = {
  createdOnAfter: Scalars['Instant'];
};


export type QueryConfirmedRequisitionsArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
};


export type QueryUserInviteArgs = {
  token: Maybe<Scalars['String']>;
};

export type Quest = {
  __typename?: 'Quest';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: Scalars['String'];
  codename: Scalars['String'];
  status: QuestStatus;
  description: Scalars['String'];
  start: DateTimeInfo;
  end: DateTimeInfo;
  steps: Maybe<Array<QuestStep>>;
  actualStart: Maybe<Scalars['Instant']>;
  actualEnd: Maybe<Scalars['Instant']>;
};


export type QuestActualStartArgs = {
  timezone: Scalars['String'];
};


export type QuestActualEndArgs = {
  timezone: Scalars['String'];
};

export enum QuestStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type QuestStep = {
  __typename?: 'QuestStep';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: Scalars['String'];
  action: Maybe<Action>;
  effects: Maybe<Array<QuestStepEffect>>;
  rules: Maybe<QuestStepRules>;
  sortOrder: Maybe<Scalars['Int']>;
  completeAfterIterations: Maybe<Scalars['Int']>;
  parentStepId: Maybe<Scalars['ID']>;
};

export type QuestStepEffect = {
  __typename?: 'QuestStepEffect';
  id: Scalars['ID'];
  rdyPoints: Maybe<RdyPointsData>;
  modifiers: Maybe<Array<QuestStepEffectModifier>>;
};

export type QuestStepEffectModifications = {
  __typename?: 'QuestStepEffectModifications';
  rdyPointsMultiple: Maybe<Scalars['Float']>;
  rdyPointsAddition: Maybe<Scalars['Int']>;
};

export type QuestStepEffectModificationsInput = {
  rdyPointsMultiple: Maybe<Scalars['Float']>;
  rdyPointsAddition: Maybe<Scalars['Int']>;
};

export type QuestStepEffectModifier = {
  __typename?: 'QuestStepEffectModifier';
  id: Scalars['ID'];
  modifications: QuestStepEffectModifications;
};

export type QuestStepRules = {
  __typename?: 'QuestStepRules';
  uniqueBy: Maybe<UniqueByRule>;
  start: Maybe<DateTimeInfo>;
  end: Maybe<DateTimeInfo>;
  organizationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  excludeOrganizationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type QuestStepRulesInput = {
  uniqueBy: Maybe<UniqueByRuleInput>;
  start: Maybe<DateTimeInfoInput>;
  end: Maybe<DateTimeInfoInput>;
  organizationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  excludeOrganizationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum RdyClient {
  Cappuccino = 'CAPPUCCINO',
  Cupping = 'CUPPING',
  Doppio = 'DOPPIO',
  Latte = 'LATTE',
  Macchiato = 'MACCHIATO',
  Mocha = 'MOCHA'
}

export type RdyPointsData = {
  __typename?: 'RdyPointsData';
  flat: Maybe<Scalars['Int']>;
  perDollar: Maybe<Scalars['Int']>;
};

export type RdyPointsInput = {
  flat: Maybe<Scalars['Int']>;
  perDollar: Maybe<Scalars['Int']>;
};

export enum RecurringInterval {
  Month = 'MONTH',
  Year = 'YEAR'
}

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['ID'];
  initializedOn: Scalars['Instant'];
  completedOn: Maybe<Scalars['Instant']>;
  state: ReferralState;
  referredUserFirstName: Maybe<Scalars['String']>;
  referringUserFirstName: Maybe<Scalars['String']>;
};

export enum ReferralState {
  Completed = 'COMPLETED',
  Initialized = 'INITIALIZED'
}

export type RegisterIoTDeviceInput = {
  uuid: Scalars['String'];
  type: IoTDeviceType;
  merchantId: Scalars['ID'];
};

export type RegisterKdsInput = {
  uuid: Scalars['String'];
  merchantId: Scalars['ID'];
};

export type Requisition = {
  __typename?: 'Requisition';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  createdOn: Scalars['Instant'];
  items: Maybe<Array<RequisitionItem>>;
  purchases: Maybe<Array<RequisitionPurchase>>;
  fulfillmentLocation: Maybe<RequisitionFulfillmentLocation>;
  schedule: Maybe<RequisitionSchedule>;
};

export type RequisitionFulfillmentInput = {
  paymentMethodId: Scalars['String'];
  shippingAddress: Maybe<AddressInput>;
  schedule: Maybe<RequisitionScheduleInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type RequisitionFulfillmentLocation = {
  __typename?: 'RequisitionFulfillmentLocation';
  id: Scalars['ID'];
  street: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type RequisitionItem = {
  __typename?: 'RequisitionItem';
  id: Scalars['ID'];
  item: Item;
  cents: Scalars['Int'];
};

export type RequisitionPriceTag = {
  __typename?: 'RequisitionPriceTag';
  subtotal: Maybe<Scalars['Int']>;
  tax: Maybe<Scalars['Int']>;
  discount: Maybe<RequisitionPriceTagDiscount>;
};

export type RequisitionPriceTagDiscount = {
  __typename?: 'RequisitionPriceTagDiscount';
  total: Scalars['Int'];
  discountId: Scalars['ID'];
  name: Scalars['String'];
};

export type RequisitionPurchase = {
  __typename?: 'RequisitionPurchase';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  subtotal: Scalars['Int'];
  tax: Scalars['Int'];
  discount: Scalars['Int'];
  paymentIntent: Maybe<RequisitionPurchasePaymentIntent>;
};

export type RequisitionPurchasePaymentIntent = {
  __typename?: 'RequisitionPurchasePaymentIntent';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  stripeId: Scalars['String'];
  stripeClientSecret: Scalars['String'];
  status: PaymentIntentStatus;
};

export type RequisitionSchedule = {
  __typename?: 'RequisitionSchedule';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  paymentMethodId: Scalars['String'];
  paused: Scalars['Boolean'];
  canceled: Scalars['Boolean'];
  frequency: RequisitionScheduleFrequency;
  nextScheduledPayment: Scalars['String'];
  status: RequisitionScheduleStatus;
  requisition: Requisition;
};

export enum RequisitionScheduleFrequency {
  EndOfMonth = 'EndOfMonth'
}

export type RequisitionScheduleInput = {
  frequency: RequisitionScheduleFrequency;
  paused: Maybe<Scalars['Boolean']>;
  archived: Maybe<Scalars['Boolean']>;
};

export enum RequisitionScheduleStatus {
  PaymentFailed = 'PaymentFailed',
  Canceled = 'Canceled',
  Active = 'Active'
}

export enum RewardAction {
  Redeem = 'REDEEM',
  PurchaseOrder = 'PURCHASE_ORDER',
  AdminGrant = 'ADMIN_GRANT',
  RedactPurchaseOrder = 'REDACT_PURCHASE_ORDER'
}

export type RewardData = {
  __typename?: 'RewardData';
  pointBalance: Scalars['Int'];
  tokens: Array<Token>;
  potentialTokens: Scalars['Int'];
  pointsPerToken: Scalars['Int'];
  organization: Maybe<Organization>;
};

export enum RoastType {
  Light = 'LIGHT',
  Medium = 'MEDIUM',
  Dark = 'DARK'
}

export enum Role {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  TreasuryContact = 'TREASURY_CONTACT'
}

export type SendSupportEmailInput = {
  contactEmail: Scalars['String'];
  reason: Scalars['String'];
  details: Scalars['String'];
};

export type SendUserInviteInput = {
  organizationId: Scalars['ID'];
  email: Scalars['String'];
  /** frontend url to have the token concatenated at the end */
  acceptUrl: Scalars['String'];
  roles: Array<Role>;
};

export type SetMerchantSubscriptionInput = {
  platformPricingId: Maybe<Scalars['ID']>;
  subscriptionProductId: Maybe<Scalars['ID']>;
  merchantId: Scalars['ID'];
  trialEndDate: Maybe<Scalars['Instant']>;
  termPricing: Maybe<Scalars['Boolean']>;
};

export enum SettingValueType {
  Boolean = 'BOOLEAN',
  String = 'STRING',
  Numeric = 'NUMERIC'
}

export type SetupOrganizationInput = {
  name: Scalars['String'];
  merchant: Maybe<MerchantInput>;
};

export type SquareAddress = {
  __typename?: 'SquareAddress';
  line1: Maybe<Scalars['String']>;
  line2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
};

export type SquareLocation = {
  __typename?: 'SquareLocation';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  address: Maybe<SquareAddress>;
  phoneNumber: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  description: Maybe<Scalars['String']>;
  merchantHasSquareSite: Scalars['Boolean'];
};

export type SquareLocationQueryFilter = {
  availableForMerchantId: Maybe<Scalars['ID']>;
};

export type SquareSyncInput = {
  identifier: Scalars['String'];
  locationId: Scalars['String'];
  createKdsIntegration: Scalars['Boolean'];
  createMerchant: Scalars['Boolean'];
  syncProducts: Scalars['Boolean'];
  onlyWebCatalogItems: Scalars['Boolean'];
  merchantId: Maybe<Scalars['ID']>;
  keepMenuSynced: Scalars['Boolean'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id: Scalars['ID'];
  stripeSubscriptionId: Scalars['String'];
  stripePaymentIntentId: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  subscriptionStatus: Maybe<SubscriptionStatus>;
};

export type Subscription = {
  __typename?: 'Subscription';
  orderAddedToKdsQueue: Order;
  orderInQueue: Order;
};


export type SubscriptionOrderAddedToKdsQueueArgs = {
  kdsUuid: Scalars['String'];
};


export type SubscriptionOrderInQueueArgs = {
  kdsId: Scalars['ID'];
};

export type SubscriptionInvoice = {
  __typename?: 'SubscriptionInvoice';
  subscriptionInvoiceLines: Array<SubscriptionInvoiceLine>;
  status: Scalars['String'];
  costUsCents: Scalars['Int'];
  createdOn: Scalars['Instant'];
  finalizedOn: Maybe<Scalars['Instant']>;
  paidOn: Maybe<Scalars['Instant']>;
};

export type SubscriptionInvoiceLine = {
  __typename?: 'SubscriptionInvoiceLine';
  costUsCents: Scalars['Int'];
  quantity: Scalars['Int'];
  subscriptionProduct: Maybe<SubscriptionProduct>;
};

export type SubscriptionPaymentIntent = {
  __typename?: 'SubscriptionPaymentIntent';
  merchantSubscriptions: Array<MerchantSubscription>;
  clientSecret: Maybe<Scalars['String']>;
  paymentIntentId: Maybe<Scalars['String']>;
  costUsCents: Maybe<Scalars['Int']>;
  lineItems: Maybe<Array<SubscriptionPaymentIntentLineItem>>;
};

export type SubscriptionPaymentIntentLineItem = {
  __typename?: 'SubscriptionPaymentIntentLineItem';
  costUsCents: Scalars['Int'];
  name: Scalars['String'];
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  id: Scalars['ID'];
  costUsCents: Scalars['Int'];
  name: Scalars['String'];
  recurringInterval: Maybe<RecurringInterval>;
  createdOn: Scalars['Instant'];
};

export enum SubscriptionStatus {
  Current = 'CURRENT',
  PaymentFailed = 'PAYMENT_FAILED',
  Cancelled = 'CANCELLED',
  PendingPayment = 'PENDING_PAYMENT',
  PastDue = 'PAST_DUE',
  Complete = 'COMPLETE'
}

export type SuggestedItem = {
  __typename?: 'SuggestedItem';
  id: Scalars['ID'];
  product: Product;
};

export type SuggestedItemInput = {
  productId: Scalars['ID'];
};

export type SuggestedProduct = {
  __typename?: 'SuggestedProduct';
  product: Product;
  suggestedModifierIds: Maybe<Array<Scalars['Int']>>;
};

export type TimeIntervalInput = {
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  userId: Scalars['ID'];
  expirationTimestamp: Maybe<Scalars['Instant']>;
  spent: Scalars['Boolean'];
  tokenType: TokenType;
};

export type TokenType = {
  __typename?: 'TokenType';
  id: Scalars['ID'];
  name: TokenTypeName;
  customerFacingName: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  expirationMilli: Maybe<Scalars['Instant']>;
};

export enum TokenTypeName {
  AcceptReferral = 'ACCEPT_REFERRAL',
  AdminGrant = 'ADMIN_GRANT',
  Oatly = 'OATLY',
  PointsRedemption = 'POINTS_REDEMPTION',
  PromotionNoExp = 'PROMOTION_NO_EXP',
  RdyNoExp = 'RDY_NO_EXP',
  RdyExpOneWeek = 'RDY_EXP_ONE_WEEK',
  Refer = 'REFER',
  OrganizationReward = 'ORGANIZATION_REWARD'
}

export type TopModifierInput = {
  modifierId: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export enum UniqueByContext {
  QuestWindow = 'QuestWindow',
  UserLifetime = 'UserLifetime'
}

export type UniqueByRule = {
  __typename?: 'UniqueByRule';
  context: UniqueByContext;
  city: Maybe<Scalars['Boolean']>;
  merchant: Maybe<Scalars['Boolean']>;
  order: Maybe<Scalars['Boolean']>;
  referredDevice: Maybe<Scalars['Boolean']>;
};

export type UniqueByRuleInput = {
  context: UniqueByContext;
  city: Maybe<Scalars['Boolean']>;
  order: Maybe<Scalars['Boolean']>;
  referredDevice: Maybe<Scalars['Boolean']>;
  merchant: Maybe<Scalars['Boolean']>;
};

export type UpdateCatalogInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  availabilityWindows: Array<Maybe<CatalogAvailabilityWindowInput>>;
  productIds: Array<Scalars['ID']>;
};

export type UpdateMerchantContactInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type UpdateModifierInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  childSelectionType: Maybe<ModifierChildSelectionType>;
  priceCents: Maybe<Scalars['Int']>;
  selectedName: Maybe<Scalars['String']>;
  defaultSelectionIds: Maybe<Array<Scalars['ID']>>;
  options: Maybe<Array<ModifierChildInput>>;
};

export type UpdateModifierUsageInput = {
  modifierId: Scalars['ID'];
  productIds: Array<Scalars['ID']>;
};

export type UpdateMyProfileInput = {
  contactEmail: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateOutputDestinationCategoriesInput = {
  merchantId: Scalars['ID'];
  categoryOutputDestinations: Array<CategoryOutputDestinationInput>;
};

export type UpdateProductCategoryInput = {
  productId: Scalars['ID'];
  categoryId: Scalars['ID'];
};

export type UpdateProductInput = {
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  catalogIds: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  priceCents: Scalars['Int'];
  sizeInGrams: Maybe<Scalars['Int']>;
  originType: Maybe<OriginType>;
  roaster: Maybe<Scalars['String']>;
  roastType: Maybe<RoastType>;
  topModifiers: Maybe<Array<TopModifierInput>>;
  productTags: Maybe<Array<ProductTagInput>>;
  images: Maybe<Array<ProductImageInput>>;
  inventory: Maybe<ProductInventoryInput>;
  availabilityWindows: Maybe<Array<AvailabilityWindowInput>>;
  suspendedUntil: Maybe<Scalars['Instant']>;
  taxRate: Maybe<Scalars['Float']>;
  pickUpPriceUsCents: Maybe<Scalars['Int']>;
};

export type UpdateRequisitionInput = {
  id: Scalars['ID'];
  fulfillment: RequisitionFulfillmentInput;
};

export type UpdateSortOrderInput = {
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export type UpdateSuggestedItemsInput = {
  catalogId: Scalars['ID'];
  items: Array<SuggestedItemInput>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  roles: Maybe<Array<Scalars['String']>>;
  accessIdentifier: Scalars['String'];
  profile: UserProfile;
  cart: Maybe<Cart>;
  kdss: Maybe<Array<Kds>>;
  orders: Maybe<Array<Order>>;
  orderCount: Scalars['Int'];
  settings: Maybe<Array<UserSettingOutput>>;
  requisitions: Maybe<Array<Requisition>>;
  stripeCustomerId: Maybe<Scalars['String']>;
  numberRdyPoints: Scalars['Int'];
  tokens: Maybe<Array<Token>>;
  actions: Maybe<Array<UserAction>>;
  questSteps: Maybe<Array<UserQuestStep>>;
};

export type UserAction = {
  __typename?: 'UserAction';
  id: Scalars['ID'];
  action: Action;
  data: Maybe<UserActionData>;
};

export type UserActionData = {
  __typename?: 'UserActionData';
  centsSpent: Maybe<Scalars['Int']>;
  referringUserId: Maybe<Scalars['ID']>;
  referredUserId: Maybe<Scalars['ID']>;
  referredDeviceId: Maybe<Scalars['String']>;
  merchantId: Maybe<Scalars['ID']>;
  merchantName: Maybe<Scalars['String']>;
  orderId: Maybe<Scalars['String']>;
  favoriteMerchantId: Maybe<Scalars['ID']>;
};

export enum UserGroup {
  All = 'ALL'
}

export type UserInvite = {
  __typename?: 'UserInvite';
  organization: Organization;
  invitedBy: User;
  roles: Array<Role>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  id: Scalars['ID'];
  contactEmail: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  loginEmail: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};

export type UserPushInput = {
  messageType: MessageType;
  userIds: Maybe<Array<Scalars['ID']>>;
  content: PushContentInput;
};

export type UserQuestStep = {
  __typename?: 'UserQuestStep';
  id: Scalars['ID'];
  completedOn: Scalars['Instant'];
  questStep: QuestStep;
  userAction: Maybe<UserAction>;
  effects: Maybe<Array<UserQuestStepEffect>>;
};

export type UserQuestStepEffect = {
  __typename?: 'UserQuestStepEffect';
  id: Scalars['ID'];
  receivedOn: Scalars['Instant'];
  effect: QuestStepEffect;
  rdyPoints: Maybe<UserRdyPoints>;
};

export type UserRdyPoints = {
  __typename?: 'UserRdyPoints';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  user: User;
  points: Scalars['Int'];
};

export type UserRewardAction = {
  __typename?: 'UserRewardAction';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  points: Scalars['Int'];
  description: Scalars['String'];
  action: RewardAction;
  organizationId: Maybe<Scalars['ID']>;
};

export type UserSettingOutput = {
  __typename?: 'UserSettingOutput';
  id: Scalars['ID'];
  name: Scalars['String'];
  readableName: Scalars['String'];
  category: Scalars['String'];
  value: Maybe<Scalars['String']>;
  valueType: SettingValueType;
};

export type MoveMerchantToDifferentOrganizationInput = {
  merchantId: Scalars['ID'];
  toOrganizationId: Scalars['ID'];
};

export type CreateMerchantFromLocationMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  freshLocationId: Scalars['String'];
  brandId: Scalars['String'];
  token: Scalars['String'];
}>;


export type CreateMerchantFromLocationMutation = (
  { __typename?: 'Mutation' }
  & { createMerchantFromLocation: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
    & { freshLocation: Maybe<(
      { __typename?: 'FreshLocationSync' }
      & Pick<FreshLocationSync, 'locationId' | 'lastSyncCompleted'>
    )> }
  )> }
);

export type GetExternalClientSyncsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetExternalClientSyncsQuery = (
  { __typename?: 'Query' }
  & { getExternalClientSyncs: Array<(
    { __typename?: 'ExternalClientSync' }
    & Pick<ExternalClientSync, 'startedOn' | 'completedOn' | 'externalSourceId' | 'identifier'>
    & { events: Maybe<Array<Maybe<(
      { __typename?: 'ExternalClientSyncEvent' }
      & Pick<ExternalClientSyncEvent, 'timestamp' | 'eventType' | 'url' | 'response'>
    )>>> }
  )> }
);

export type GetFreshLocationsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  brandId: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetFreshLocationsQuery = (
  { __typename?: 'Query' }
  & { getFreshLocations: Array<(
    { __typename?: 'FreshLocation' }
    & Pick<FreshLocation, 'id' | 'name'>
    & { address: (
      { __typename?: 'FreshAddress' }
      & Pick<FreshAddress, 'line1' | 'line2' | 'city' | 'state' | 'zip' | 'phone'>
    ) }
  )> }
);

export type GetMerchantsWithFreshLocationByOrganizationIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMerchantsWithFreshLocationByOrganizationIdQuery = (
  { __typename?: 'Query' }
  & { organization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { merchants: Maybe<Array<(
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'name'>
      & { freshLocation: Maybe<(
        { __typename?: 'FreshLocationSync' }
        & Pick<FreshLocationSync, 'locationId' | 'lastSyncCompleted'>
      )> }
    )>> }
  )> }
);

export type ArchiveMerchantMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveMerchantMutation = (
  { __typename?: 'Mutation' }
  & { archiveMerchant: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
  ) }
);

export type FeatureFlagQueryVariables = Exact<{
  merchantId: Scalars['ID'];
  featureFlagName: FeatureFlagName;
}>;


export type FeatureFlagQuery = (
  { __typename?: 'Query' }
  & { featureFlag: Maybe<(
    { __typename?: 'FeatureFlag' }
    & Pick<FeatureFlag, 'name' | 'enabled'>
  )> }
);

export type MerchantReferralsByMerchantIdQueryVariables = Exact<{
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
}>;


export type MerchantReferralsByMerchantIdQuery = (
  { __typename?: 'Query' }
  & { merchantReferralsByMerchantId: Array<(
    { __typename?: 'MerchantReferral' }
    & Pick<MerchantReferral, 'id' | 'createdOn'>
  )> }
);

export type UploadMerchantImageMutationVariables = Exact<{
  file: Scalars['Upload'];
  merchantId: Scalars['ID'];
  sortOrder: Scalars['Int'];
}>;


export type UploadMerchantImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadMerchantImage: (
    { __typename?: 'MerchantImage' }
    & MerchantImageDetailsFragment
  ) }
);

export type ArchiveModifierOptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveModifierOptionMutation = (
  { __typename?: 'Mutation' }
  & { archiveModifier: (
    { __typename?: 'Modifier' }
    & ModifierOptionFragment
  ) }
);

export type ArchiveModifierOptionsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ArchiveModifierOptionsMutation = (
  { __typename?: 'Mutation' }
  & { archiveModifiers: Array<(
    { __typename?: 'Modifier' }
    & ModifierOptionFragment
  )> }
);

export type ArchiveTopModifierMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveTopModifierMutation = (
  { __typename?: 'Mutation' }
  & { archiveModifier: (
    { __typename?: 'Modifier' }
    & TopModifierFragment
  ) }
);

export type CreateModifierMutationVariables = Exact<{
  input: ModifierInput;
}>;


export type CreateModifierMutation = (
  { __typename?: 'Mutation' }
  & { createModifier: (
    { __typename?: 'Modifier' }
    & TopModifierFragment
  ) }
);

export type CreateModifierOptionMutationVariables = Exact<{
  input: ModifierInput;
}>;


export type CreateModifierOptionMutation = (
  { __typename?: 'Mutation' }
  & { createModifier: (
    { __typename?: 'Modifier' }
    & ModifierOptionFragment
  ) }
);

export type FetchModifierOptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchModifierOptionQuery = (
  { __typename?: 'Query' }
  & { modifier: (
    { __typename?: 'Modifier' }
    & ModifierOptionFragment
  ) }
);

export type FetchTopModifierQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchTopModifierQuery = (
  { __typename?: 'Query' }
  & { modifier: (
    { __typename?: 'Modifier' }
    & TopModifierFragment
  ) }
);

export type ModifierOptionFragment = (
  { __typename?: 'Modifier' }
  & Pick<Modifier, 'id' | 'name' | 'printableName' | 'description' | 'childSelectionType' | 'defaultSelectionIds' | 'priceCents' | 'selected' | 'selectedName' | 'active' | 'archived' | 'isSynced'>
  & { inventory: Maybe<(
    { __typename?: 'Inventory' }
    & InventoryDetailsFragment
  )> }
);

export type ModifiersWithoutChildrenByMerchantQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type ModifiersWithoutChildrenByMerchantQuery = (
  { __typename?: 'Query' }
  & { modifiersWithoutChildrenByMerchant: Maybe<Array<(
    { __typename?: 'Modifier' }
    & ModifierOptionFragment
  )>> }
);

export type TopModifierFragment = (
  { __typename?: 'Modifier' }
  & Pick<Modifier, 'id' | 'name' | 'printableName' | 'description' | 'childSelectionType' | 'priceCents' | 'active' | 'defaultSelectionIds' | 'archived' | 'isSynced'>
  & { children: Maybe<Array<(
    { __typename?: 'Modifier' }
    & ModifierOptionFragment
  )>>, allChildren: Maybe<Array<(
    { __typename?: 'Modifier' }
    & ModifierOptionFragment
  )>> }
);

export type TopModifiersByMerchantQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type TopModifiersByMerchantQuery = (
  { __typename?: 'Query' }
  & { topModifiersByMerchant: Maybe<Array<(
    { __typename?: 'Modifier' }
    & TopModifierFragment
  )>> }
);

export type UpdateModifierOptionMutationVariables = Exact<{
  input: UpdateModifierInput;
}>;


export type UpdateModifierOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateModifier: (
    { __typename?: 'Modifier' }
    & ModifierOptionFragment
  ) }
);

export type UpdateTopModifierMutationVariables = Exact<{
  input: UpdateModifierInput;
}>;


export type UpdateTopModifierMutation = (
  { __typename?: 'Mutation' }
  & { updateModifier: (
    { __typename?: 'Modifier' }
    & TopModifierFragment
  ) }
);

export type OrderItemSummaryFragment = (
  { __typename?: 'OrderItem' }
  & Pick<OrderItem, 'id' | 'name' | 'priceCents' | 'subtotalChargeUsCents' | 'subtotalChargeTokens' | 'refunded'>
  & { orderableProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
    & { category: (
      { __typename?: 'Category' }
      & Pick<Category, 'name'>
    ) }
  ), selectedModifiers: Maybe<Array<(
    { __typename?: 'OrderItemModifier' }
    & Pick<OrderItemModifier, 'priceCents'>
  )>> }
);

export type OrderReportingDetailsByMerchantIdQueryVariables = Exact<{
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
}>;


export type OrderReportingDetailsByMerchantIdQuery = (
  { __typename?: 'Query' }
  & { orderReportingDetailsByMerchantId: Array<(
    { __typename?: 'OrderReportingDetails' }
    & Pick<OrderReportingDetails, 'id' | 'date' | 'userId' | 'firstName' | 'lastName' | 'tokens' | 'tokenValue' | 'tokenTipValue' | 'tokenFeeValue' | 'subTotal' | 'taxes' | 'tip' | 'customerFee' | 'merchantFee' | 'netMerchantPayout' | 'merchantDiscountFeeUsCents' | 'rdyPromotionDiscountValue' | 'rdyPromotionName' | 'rdyPaidTokenCount' | 'organizationPaidTokenCount'>
  )> }
);

export type OrderSummaryFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'createdOn'>
  & { transactions: Maybe<Array<(
    { __typename?: 'OrderTransaction' }
    & Pick<OrderTransaction, 'id' | 'tokenValue' | 'subtotalUsCents' | 'discountUsCents' | 'feeUsCents' | 'taxUsCents' | 'tipUsCents' | 'tokenTipUsCents' | 'merchantFeeUsCents' | 'merchantTokenFeeUsCents' | 'merchantDiscountFeeUsCents'>
    & { transactionPromotions: Maybe<Array<(
      { __typename?: 'OrderTransactionPromotion' }
      & Pick<OrderTransactionPromotion, 'id' | 'createdOn' | 'usCentsDeductedFromSubtotal'>
      & { promotion: (
        { __typename?: 'Promotion' }
        & Pick<Promotion, 'id' | 'createdOn' | 'customerFacingName' | 'costOwner'>
      ) }
    )>> }
  )>>, user: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile: (
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'firstName' | 'lastName'>
    ) }
  ), items: Array<(
    { __typename?: 'OrderItem' }
    & OrderItemSummaryFragment
  )> }
);

export type OrdersByMerchantIdQueryVariables = Exact<{
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
}>;


export type OrdersByMerchantIdQuery = (
  { __typename?: 'Query' }
  & { ordersByMerchantId: Array<(
    { __typename?: 'Order' }
    & OrderSummaryFragment
  )> }
);

export type AddOrganizationUserRoleMutationVariables = Exact<{
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: Maybe<Role>;
}>;


export type AddOrganizationUserRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addOrganizationUserRole'>
);

export type ArchiveOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { archiveOrganization: (
    { __typename?: 'Organization' }
    & OrganizationDetailsFragment
  ) }
);

export type MerchantPayoutDetailsFragment = (
  { __typename?: 'MerchantPayout' }
  & Pick<MerchantPayout, 'id' | 'createdOn' | 'periodStartDate' | 'periodEndDate' | 'payoutAmount' | 'payoutType' | 'manualPayout'>
  & { merchant: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
  ) }
);

export type OrganizationDetailsFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
);

export type OrganizationListQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationListQuery = (
  { __typename?: 'Query' }
  & { organizations: Array<(
    { __typename?: 'Organization' }
    & OrganizationDetailsFragment
  )> }
);

export type OrganizationPayoutsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganizationPayoutsQuery = (
  { __typename?: 'Query' }
  & { organizationPayouts: Array<(
    { __typename?: 'MerchantPayout' }
    & MerchantPayoutDetailsFragment
  )> }
);

export type RemoveOrganizationUserRoleMutationVariables = Exact<{
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: Maybe<Role>;
}>;


export type RemoveOrganizationUserRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOrganizationUserRole'>
);

export type ArchiveMerchantPaymentAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveMerchantPaymentAccountMutation = (
  { __typename?: 'Mutation' }
  & { archiveMerchantPaymentAccount: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    ) }
  ) }
);

export type ArchiveOrganizationPaymentAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveOrganizationPaymentAccountMutation = (
  { __typename?: 'Mutation' }
  & { archiveOrganizationPaymentAccount: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  ) }
);

export type SetupPaymentAccountMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  merchantId: Maybe<Scalars['ID']>;
}>;


export type SetupPaymentAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setupPaymentAccount'>
);

export type ValidatePaymentAccountQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  merchantId: Maybe<Scalars['ID']>;
}>;


export type ValidatePaymentAccountQuery = (
  { __typename?: 'Query' }
  & { validatePaymentAccount: Maybe<(
    { __typename?: 'PaymentAccountValidation' }
    & Pick<PaymentAccountValidation, 'hasAccount' | 'chargesEnabled'>
  )> }
);

export type ArchiveProductMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveProductMutation = (
  { __typename?: 'Mutation' }
  & { archiveProduct: (
    { __typename?: 'Product' }
    & ProductDetailsFragment
  ) }
);

export type ArchiveProductsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ArchiveProductsMutation = (
  { __typename?: 'Mutation' }
  & { archiveProducts: Array<(
    { __typename?: 'Product' }
    & ProductDetailsFragment
  )> }
);

export type InventoryDetailsFragment = (
  { __typename?: 'Inventory' }
  & Pick<Inventory, 'inventoryType' | 'restockQuantity' | 'currentQuantity' | 'currentlyAvailable'>
  & { restockDays: Maybe<Array<(
    { __typename?: 'InventoryRestockDay' }
    & Pick<InventoryRestockDay, 'dayOfWeek'>
  )>> }
);

export type ModifierDetailsFragment = (
  { __typename?: 'Modifier' }
  & Pick<Modifier, 'id' | 'name' | 'printableName' | 'description' | 'active' | 'childSelectionType' | 'priceCents' | 'defaultSelectionIds' | 'isSynced'>
  & { allChildren: Maybe<Array<(
    { __typename?: 'Modifier' }
    & Pick<Modifier, 'id' | 'name' | 'printableName' | 'active' | 'priceCents' | 'selectedName' | 'selected'>
    & { inventory: Maybe<(
      { __typename?: 'Inventory' }
      & InventoryDetailsFragment
    )> }
  )>> }
);

export type ProductDetailsFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name' | 'printableName' | 'description' | 'active' | 'archived' | 'priceCents' | 'sizeInGrams' | 'originType' | 'roaster' | 'roastType' | 'isSynced' | 'taxRate' | 'pickUpPriceUsCents' | 'suspendedUntil' | 'source'>
  & { catalogs: Maybe<Array<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'name'>
  )>>, category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'type'>
  ), modifiers: Maybe<Array<(
    { __typename?: 'Modifier' }
    & ModifierDetailsFragment
  )>>, allModifiers: Maybe<Array<(
    { __typename?: 'Modifier' }
    & ModifierDetailsFragment
  )>>, productTags: Maybe<Array<(
    { __typename?: 'ProductTag' }
    & Pick<ProductTag, 'value' | 'tagType'>
  )>>, images: Maybe<Array<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'imageUri' | 'createdOn' | 'sortOrder'>
    & { libraryImage: Maybe<(
      { __typename?: 'LibraryImage' }
      & Pick<LibraryImage, 'id' | 'imageUri'>
    )> }
  )>>, inventory: Maybe<(
    { __typename?: 'Inventory' }
    & InventoryDetailsFragment
  )>, availabilityWindows: Maybe<Array<(
    { __typename?: 'AvailabilityWindow' }
    & Pick<AvailabilityWindow, 'dayOfWeek' | 'start' | 'end'>
  )>> }
);

export type ProductDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductDetailsQuery = (
  { __typename?: 'Query' }
  & { product: Maybe<(
    { __typename?: 'Product' }
    & ProductDetailsFragment
  )> }
);

export type UploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadImage'>
);

export type UploadImageByUrlMutationVariables = Exact<{
  url: Scalars['String'];
}>;


export type UploadImageByUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadImageByUrl'>
);

export type ArchiveMerchantPromotionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveMerchantPromotionMutation = (
  { __typename?: 'Mutation' }
  & { archivePromotion: (
    { __typename?: 'Promotion' }
    & Pick<Promotion, 'id' | 'customerFacingName' | 'archived'>
  ) }
);

export type ArchivePromotionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchivePromotionMutation = (
  { __typename?: 'Mutation' }
  & { archivePromotion: (
    { __typename?: 'Promotion' }
    & PromotionInfoFragment
  ) }
);

export type LeadReceivedMutationVariables = Exact<{
  input: CafeLeadInput;
}>;


export type LeadReceivedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leadReceived'>
);

export type CafeValidationQueryQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type CafeValidationQueryQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'taxRate'>
    & { kdsIntegrations: Maybe<Array<(
      { __typename?: 'MerchantKdsIntegration' }
      & Pick<MerchantKdsIntegration, 'id' | 'type' | 'locationId' | 'displayName' | 'active'>
    )>>, merchantContacts: Maybe<Array<(
      { __typename?: 'MerchantContact' }
      & MerchantContactDetailsFragment
    )>>, kdss: Maybe<Array<(
      { __typename?: 'Kds' }
      & Pick<Kds, 'id' | 'uuid' | 'active'>
      & { authorizedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { profile: (
          { __typename?: 'UserProfile' }
          & Pick<UserProfile, 'id' | 'contactEmail'>
        ) }
      ) }
    )>>, products: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'active'>
      & { category: (
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type UpdateMerchantActiveMutationVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type UpdateMerchantActiveMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchantActive: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'active'>
  ) }
);

export type CreateMerchantFeeConfigMutationVariables = Exact<{
  input: CreateMerchantFeeConfigInput;
}>;


export type CreateMerchantFeeConfigMutation = (
  { __typename?: 'Mutation' }
  & { createMerchantFeeConfig: (
    { __typename?: 'MerchantFeeConfig' }
    & Pick<MerchantFeeConfig, 'id' | 'default' | 'staticFeeInUsCents' | 'percentageFee'>
  ) }
);

export type CreateMerchantPromotionMutationVariables = Exact<{
  input: PromotionInput;
}>;


export type CreateMerchantPromotionMutation = (
  { __typename?: 'Mutation' }
  & { createMerchantPromotion: (
    { __typename?: 'Promotion' }
    & MerchantPromotionInfoFragment
  ) }
);

export type CreatePromoCodeMutationVariables = Exact<{
  input: PromoCodeInput;
}>;


export type CreatePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { createPromoCode: (
    { __typename?: 'PromoCode' }
    & PromoCodeInfoFragment
  ) }
);

export type CreatePromotionMutationVariables = Exact<{
  input: PromotionInput;
}>;


export type CreatePromotionMutation = (
  { __typename?: 'Mutation' }
  & { createPromotion: (
    { __typename?: 'Promotion' }
    & PromotionInfoFragment
  ) }
);

export type CreateSubscriptionFeeMutationVariables = Exact<{
  input: CreateSubscriptionFeeInput;
}>;


export type CreateSubscriptionFeeMutation = (
  { __typename?: 'Mutation' }
  & { createSubscriptionFee: (
    { __typename?: 'SubscriptionProduct' }
    & Pick<SubscriptionProduct, 'id' | 'costUsCents' | 'name'>
  ) }
);

export type CreateSubscriptionPlanMutationVariables = Exact<{
  input: CreateSubscriptionPlanInput;
}>;


export type CreateSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & { createSubscriptionPlan: (
    { __typename?: 'SubscriptionProduct' }
    & Pick<SubscriptionProduct, 'id' | 'costUsCents' | 'name'>
  ) }
);

export type UserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRolesQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'roles'>
  )> }
);

export type PromotionTopInfoFragment = (
  { __typename?: 'Promotion' }
  & Pick<Promotion, 'id' | 'status' | 'customerFacingName' | 'createdOn'>
  & { merchants: Maybe<Array<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
  )>> }
);

export type PromotionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PromotionsQuery = (
  { __typename?: 'Query' }
  & { promotions: Maybe<Array<(
    { __typename?: 'Promotion' }
    & PromotionTopInfoFragment
  )>> }
);

export type MerchantFeeConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type MerchantFeeConfigsQuery = (
  { __typename?: 'Query' }
  & { merchantFeeConfigs: Array<(
    { __typename?: 'MerchantFeeConfig' }
    & Pick<MerchantFeeConfig, 'id' | 'default' | 'staticFeeInUsCents' | 'percentageFee'>
  )> }
);

export type SetDefaultMerchantFeeConfigMutationVariables = Exact<{
  merchantFeeConfigId: Scalars['ID'];
}>;


export type SetDefaultMerchantFeeConfigMutation = (
  { __typename?: 'Mutation' }
  & { setDefaultMerchantFeeConfig: Array<(
    { __typename?: 'MerchantFeeConfig' }
    & Pick<MerchantFeeConfig, 'id' | 'default' | 'staticFeeInUsCents' | 'percentageFee'>
  )> }
);

export type AdjudicatePromotionMutationVariables = Exact<{
  id: Scalars['ID'];
  status: PromotionStatus;
  reason: Maybe<Scalars['String']>;
}>;


export type AdjudicatePromotionMutation = (
  { __typename?: 'Mutation' }
  & { adjudicatePromotion: (
    { __typename?: 'Promotion' }
    & Pick<Promotion, 'id' | 'status'>
  ) }
);

export type PromoCodeInfoFragment = (
  { __typename?: 'PromoCode' }
  & Pick<PromoCode, 'id' | 'createdOn' | 'code' | 'validStart' | 'validEnd' | 'archived' | 'limitTotalUses' | 'deepLink'>
);

export type PromotionInfoFragment = (
  { __typename?: 'Promotion' }
  & Pick<Promotion, 'id' | 'createdOn' | 'customerFacingName' | 'costOwner' | 'start' | 'end' | 'newUserOnly' | 'recurring' | 'frequency' | 'type' | 'limitPerUser' | 'flatDiscountUsCents' | 'percentageDiscount' | 'maxDiscountUsCents' | 'status' | 'dispenseRewardOnEntry' | 'archived' | 'numberOfTokens' | 'productTags'>
  & { tokenType: Maybe<(
    { __typename?: 'TokenType' }
    & Pick<TokenType, 'id' | 'name'>
  )>, merchants: Maybe<Array<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
  )>>, codes: Maybe<Array<(
    { __typename?: 'PromoCode' }
    & PromoCodeInfoFragment
  )>> }
);

export type PromotionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PromotionQuery = (
  { __typename?: 'Query' }
  & { promotion: (
    { __typename?: 'Promotion' }
    & PromotionInfoFragment
  ) }
);

export type UpdatePromotionCodeMutationVariables = Exact<{
  input: PromotionCodeUpdateInput;
}>;


export type UpdatePromotionCodeMutation = (
  { __typename?: 'Mutation' }
  & { updatePromotionCode: (
    { __typename?: 'PromoCode' }
    & PromoCodeInfoFragment
  ) }
);

export type UpdatePromotionMutationVariables = Exact<{
  input: PromotionUpdateInput;
}>;


export type UpdatePromotionMutation = (
  { __typename?: 'Mutation' }
  & { updatePromotion: (
    { __typename?: 'Promotion' }
    & PromotionInfoFragment
  ) }
);

export type MarketsQueryVariables = Exact<{ [key: string]: never; }>;


export type MarketsQuery = (
  { __typename?: 'Query' }
  & { markets: Maybe<Array<(
    { __typename?: 'Market' }
    & Pick<Market, 'id' | 'name'>
  )>> }
);

export type SendPushToMarketMutationVariables = Exact<{
  input: MarketPushInput;
}>;


export type SendPushToMarketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendPushToMarket'>
);

export type SendPushToUsersMutationVariables = Exact<{
  input: UserPushInput;
}>;


export type SendPushToUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendPushToUsers'>
);

export type ConfirmedRequisitionsQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type ConfirmedRequisitionsQuery = (
  { __typename?: 'Query' }
  & { confirmedRequisitions: Array<(
    { __typename?: 'Requisition' }
    & Pick<Requisition, 'id' | 'firstName' | 'lastName'>
    & { fulfillmentLocation: Maybe<(
      { __typename?: 'RequisitionFulfillmentLocation' }
      & Pick<RequisitionFulfillmentLocation, 'id' | 'street' | 'street2' | 'city' | 'state' | 'zipcode'>
    )> }
  )> }
);

export type RequisitionSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type RequisitionSchedulesQuery = (
  { __typename?: 'Query' }
  & { schedules: Array<(
    { __typename?: 'RequisitionSchedule' }
    & Pick<RequisitionSchedule, 'id' | 'createdOn' | 'paused' | 'nextScheduledPayment'>
    & { requisition: (
      { __typename?: 'Requisition' }
      & Pick<Requisition, 'id' | 'firstName' | 'lastName'>
      & { items: Maybe<Array<(
        { __typename?: 'RequisitionItem' }
        & Pick<RequisitionItem, 'id'>
        & { item: (
          { __typename?: 'Item' }
          & Pick<Item, 'id' | 'name'>
        ) }
      )>>, fulfillmentLocation: Maybe<(
        { __typename?: 'RequisitionFulfillmentLocation' }
        & Pick<RequisitionFulfillmentLocation, 'id' | 'street' | 'street2' | 'city' | 'state' | 'countryCode' | 'zipcode'>
      )>, purchases: Maybe<Array<(
        { __typename?: 'RequisitionPurchase' }
        & Pick<RequisitionPurchase, 'id' | 'createdOn' | 'subtotal' | 'tax' | 'discount'>
        & { paymentIntent: Maybe<(
          { __typename?: 'RequisitionPurchasePaymentIntent' }
          & Pick<RequisitionPurchasePaymentIntent, 'id' | 'stripeId' | 'status'>
        )> }
      )>> }
    ) }
  )> }
);

export type RequisitionSinglesQueryVariables = Exact<{
  createdOnAfter: Scalars['Instant'];
}>;


export type RequisitionSinglesQuery = (
  { __typename?: 'Query' }
  & { singles: Array<(
    { __typename?: 'Requisition' }
    & Pick<Requisition, 'id' | 'firstName' | 'lastName'>
    & { items: Maybe<Array<(
      { __typename?: 'RequisitionItem' }
      & Pick<RequisitionItem, 'id'>
      & { item: (
        { __typename?: 'Item' }
        & Pick<Item, 'id' | 'name'>
      ) }
    )>>, fulfillmentLocation: Maybe<(
      { __typename?: 'RequisitionFulfillmentLocation' }
      & Pick<RequisitionFulfillmentLocation, 'id' | 'street' | 'street2' | 'city' | 'state' | 'countryCode' | 'zipcode'>
    )>, purchases: Maybe<Array<(
      { __typename?: 'RequisitionPurchase' }
      & Pick<RequisitionPurchase, 'id' | 'createdOn' | 'subtotal' | 'tax' | 'discount'>
      & { paymentIntent: Maybe<(
        { __typename?: 'RequisitionPurchasePaymentIntent' }
        & Pick<RequisitionPurchasePaymentIntent, 'id' | 'stripeId' | 'status'>
      )> }
    )>> }
  )> }
);

export type AddMerchantSubscriptionFeeMutationVariables = Exact<{
  input: AddMerchantSubscriptionFeeInput;
}>;


export type AddMerchantSubscriptionFeeMutation = (
  { __typename?: 'Mutation' }
  & { addMerchantSubscriptionFee: (
    { __typename?: 'MerchantSubscriptionFee' }
    & Pick<MerchantSubscriptionFee, 'stripeInvoiceItemId' | 'paid'>
    & { subscriptionFee: (
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
    ) }
  ) }
);

export type DefaultSubscriptionProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultSubscriptionProductsQuery = (
  { __typename?: 'Query' }
  & { defaultSubscriptionProducts: Array<(
    { __typename?: 'DefaultSubscriptionProduct' }
    & Pick<DefaultSubscriptionProduct, 'defaultSubscriptionType'>
    & { subscriptionProduct: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id'>
    )> }
  )> }
);

export type GetPlatformPricingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlatformPricingQuery = (
  { __typename?: 'Query' }
  & { getPlatformPricing: Array<(
    { __typename?: 'PlatformPricing' }
    & Pick<PlatformPricing, 'id' | 'name' | 'createdOn' | 'instantPriceUsCents' | 'platformPricingType' | 'subscriptionTerm'>
    & { subscriptionProduct: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'costUsCents' | 'name' | 'recurringInterval'>
    )> }
  )> }
);

export type SavePlatformPricingMutationVariables = Exact<{
  input: PlatformPricingInput;
}>;


export type SavePlatformPricingMutation = (
  { __typename?: 'Mutation' }
  & { savePlatformPricing: Maybe<(
    { __typename?: 'PlatformPricing' }
    & Pick<PlatformPricing, 'id' | 'name' | 'createdOn' | 'instantPriceUsCents' | 'platformPricingType' | 'subscriptionTerm'>
    & { subscriptionProduct: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'costUsCents' | 'name' | 'recurringInterval'>
    )> }
  )> }
);

export type SetDefaultSubscriptionProductMutationVariables = Exact<{
  subscriptionProductId: Scalars['ID'];
  defaultSuscriptionType: DefaultSuscriptionType;
}>;


export type SetDefaultSubscriptionProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultSubscriptionProduct'>
);

export type SetMerchantSubscriptionMutationVariables = Exact<{
  input: SetMerchantSubscriptionInput;
}>;


export type SetMerchantSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { setMerchantSubscription: (
    { __typename?: 'MerchantSubscription' }
    & Pick<MerchantSubscription, 'id' | 'trialEndDate' | 'organizationManaged' | 'termPricing'>
    & { subscriptionPlan: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
    )>, merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'stripeCustomerId'>
    ), stripeSubscription: Maybe<(
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'stripeSubscriptionId'>
    )>, platformPricing: Maybe<(
      { __typename?: 'PlatformPricing' }
      & Pick<PlatformPricing, 'id' | 'name' | 'createdOn' | 'instantPriceUsCents' | 'platformPricingType' | 'subscriptionTerm'>
      & { subscriptionProduct: Maybe<(
        { __typename?: 'SubscriptionProduct' }
        & Pick<SubscriptionProduct, 'id' | 'costUsCents' | 'name' | 'recurringInterval'>
      )> }
    )> }
  ) }
);

export type StripeSubscriptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StripeSubscriptionQuery = (
  { __typename?: 'Query' }
  & { stripeSubscription: (
    { __typename?: 'StripeSubscription' }
    & Pick<StripeSubscription, 'id' | 'stripeSubscriptionId' | 'subscriptionStatus'>
  ) }
);

export type UpdateSubscriptionPaymentMethodMutationVariables = Exact<{
  stripeSubscriptionId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
}>;


export type UpdateSubscriptionPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updateSubscriptionPaymentMethod: (
    { __typename?: 'StripeSubscription' }
    & Pick<StripeSubscription, 'id' | 'stripeSubscriptionId' | 'subscriptionStatus'>
  ) }
);

export type SubscriptionFeesQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionFeesQuery = (
  { __typename?: 'Query' }
  & { subscriptionFees: Array<(
    { __typename?: 'SubscriptionProduct' }
    & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents' | 'createdOn'>
  )> }
);

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionPlansQuery = (
  { __typename?: 'Query' }
  & { subscriptionPlans: Array<(
    { __typename?: 'SubscriptionProduct' }
    & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents' | 'recurringInterval' | 'createdOn'>
  )> }
);

export type GrantTokensMutationVariables = Exact<{
  input: GrantTokensInput;
}>;


export type GrantTokensMutation = (
  { __typename?: 'Mutation' }
  & { grantTokens: Array<(
    { __typename?: 'Token' }
    & TokenInfoFragment
  )> }
);

export type TokenInfoFragment = (
  { __typename?: 'Token' }
  & Pick<Token, 'id' | 'createdOn' | 'userId' | 'expirationTimestamp' | 'spent'>
);

export type CatalogSuggestedItemsQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type CatalogSuggestedItemsQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { activeCatalog: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'id'>
      & { suggestedItems: Maybe<Array<(
        { __typename?: 'SuggestedItem' }
        & CatalogSuggestedItemFragment
      )>> }
    )> }
  )> }
);

export type CatalogSuggestedItemFragment = (
  { __typename?: 'SuggestedItem' }
  & Pick<SuggestedItem, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & CatalogProductFragment
  ) }
);

export type UpdateSuggestedItemsMutationVariables = Exact<{
  input: UpdateSuggestedItemsInput;
}>;


export type UpdateSuggestedItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateSuggestedItems: (
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { suggestedItems: Maybe<Array<(
      { __typename?: 'SuggestedItem' }
      & Pick<SuggestedItem, 'id'>
      & { product: (
        { __typename?: 'Product' }
        & CatalogProductFragment
      ) }
    )>> }
  ) }
);

export type ActiveCatalogPreviewQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type ActiveCatalogPreviewQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & { activeCatalog: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'id' | 'name' | 'description'>
      & { categories: Maybe<Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
        & { activeProducts: Maybe<Array<(
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name'>
        )>> }
      )>>, suggestedItems: Maybe<Array<(
        { __typename?: 'SuggestedItem' }
        & Pick<SuggestedItem, 'id'>
        & { product: (
          { __typename?: 'Product' }
          & CatalogProductFragment
        ) }
      )>> }
    )> }
  )> }
);

export type CatalogListQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type CatalogListQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & { catalogs: Maybe<Array<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'id' | 'name' | 'description' | 'isDefault'>
      & { products: Maybe<Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      )>>, availabilityWindows: Maybe<Array<(
        { __typename?: 'CatalogAvailabilityWindow' }
        & Pick<CatalogAvailabilityWindow, 'id' | 'dayOfWeek' | 'start' | 'end'>
      )>> }
    )>> }
  )> }
);

export type CreateCatalogMutationVariables = Exact<{
  input: CreateCatalogInput;
}>;


export type CreateCatalogMutation = (
  { __typename?: 'Mutation' }
  & { createCatalog: (
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'name' | 'isDefault'>
    & { availabilityWindows: Maybe<Array<(
      { __typename?: 'CatalogAvailabilityWindow' }
      & Pick<CatalogAvailabilityWindow, 'id' | 'dayOfWeek' | 'start' | 'end'>
    )>> }
  ) }
);

export type DeleteCatalogMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCatalogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCatalog'>
);

export type UpdateCatalogMutationVariables = Exact<{
  input: UpdateCatalogInput;
}>;


export type UpdateCatalogMutation = (
  { __typename?: 'Mutation' }
  & { updateCatalog: (
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'name' | 'isDefault'>
    & { availabilityWindows: Maybe<Array<(
      { __typename?: 'CatalogAvailabilityWindow' }
      & Pick<CatalogAvailabilityWindow, 'id' | 'dayOfWeek' | 'start' | 'end'>
    )>> }
  ) }
);

export type MerchantSourceSystemQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantSourceSystemQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name' | 'source' | 'sourceSystemId'>
  )> }
);

export type UpdateMerchantSourceSystemMutationVariables = Exact<{
  merchantId: Scalars['ID'];
  sourceSystem: ExternalDataSource;
  sourceSystemId: Scalars['String'];
  syncMenu: Scalars['Boolean'];
}>;


export type UpdateMerchantSourceSystemMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchantSourceSystem: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name' | 'source' | 'sourceSystemId'>
  ) }
);

export type CreateMerchantKdsIntegrationMutationVariables = Exact<{
  input: CreateMerchantKdsIntegrationInput;
}>;


export type CreateMerchantKdsIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { createMerchantKdsIntegration: (
    { __typename?: 'MerchantKdsIntegration' }
    & Pick<MerchantKdsIntegration, 'id' | 'createdOn' | 'type' | 'locationId' | 'displayName' | 'active'>
  ) }
);

export type ArchiveMerchantKdsIntegrationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveMerchantKdsIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'archiveMerchantKdsIntegration'>
);

export type KdsIntegrationDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KdsIntegrationDetailsQuery = (
  { __typename?: 'Query' }
  & { kdsIntegration: Maybe<(
    { __typename?: 'MerchantKdsIntegration' }
    & Pick<MerchantKdsIntegration, 'id' | 'type' | 'locationId' | 'displayName' | 'active'>
    & { devices: Maybe<Array<(
      { __typename?: 'KdsIntegrationDevice' }
      & Pick<KdsIntegrationDevice, 'id' | 'uuid' | 'name'>
    )>> }
  )> }
);

export type MerchantOutputDestinationsQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantOutputDestinationsQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & { outputDestinations: Maybe<Array<(
      { __typename?: 'OutputDestination' }
      & Pick<OutputDestination, 'type'>
      & { kdsIntegrationDevice: Maybe<(
        { __typename?: 'KdsIntegrationDevice' }
        & Pick<KdsIntegrationDevice, 'id' | 'name' | 'uuid'>
      )>, categorySettings: Maybe<Array<(
        { __typename?: 'CategoryOutputDestination' }
        & Pick<CategoryOutputDestination, 'id' | 'active'>
        & { category: (
          { __typename?: 'Category' }
          & Pick<Category, 'id' | 'name' | 'active'>
        ) }
      )>> }
    )>> }
  )> }
);

export type UpdateOutputDestinationsMutationVariables = Exact<{
  input: Maybe<UpdateOutputDestinationCategoriesInput>;
}>;


export type UpdateOutputDestinationsMutation = (
  { __typename?: 'Mutation' }
  & { updateOutputDestinationCategories: Array<(
    { __typename?: 'OutputDestination' }
    & Pick<OutputDestination, 'type'>
    & { kdsIntegrationDevice: Maybe<(
      { __typename?: 'KdsIntegrationDevice' }
      & Pick<KdsIntegrationDevice, 'id' | 'name' | 'uuid'>
    )>, categorySettings: Maybe<Array<(
      { __typename?: 'CategoryOutputDestination' }
      & Pick<CategoryOutputDestination, 'id' | 'active'>
      & { category: (
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name' | 'active'>
      ) }
    )>> }
  )> }
);

export type KdsIntegrationListQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type KdsIntegrationListQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { kdsIntegrations: Maybe<Array<(
      { __typename?: 'MerchantKdsIntegration' }
      & Pick<MerchantKdsIntegration, 'id' | 'type' | 'locationId' | 'displayName' | 'active' | 'sendOrdersToRdy'>
    )>> }
  )> }
);

export type SetupKdsIntegrationDevicesMutationVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type SetupKdsIntegrationDevicesMutation = (
  { __typename?: 'Mutation' }
  & { setupKdsIntegrationDevices: (
    { __typename?: 'MerchantKdsIntegration' }
    & Pick<MerchantKdsIntegration, 'id' | 'type' | 'locationId' | 'displayName' | 'active'>
    & { devices: Maybe<Array<(
      { __typename?: 'KdsIntegrationDevice' }
      & Pick<KdsIntegrationDevice, 'id' | 'uuid' | 'name'>
    )>> }
  ) }
);

export type UpdateMerchantKdsIntegrationActiveMutationVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type UpdateMerchantKdsIntegrationActiveMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchantKdsIntegrationActive: (
    { __typename?: 'MerchantKdsIntegration' }
    & Pick<MerchantKdsIntegration, 'id' | 'type' | 'locationId' | 'displayName' | 'active'>
  ) }
);

export type KdsListQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type KdsListQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { kdss: Maybe<Array<(
      { __typename?: 'Kds' }
      & Pick<Kds, 'id' | 'uuid' | 'active' | 'os' | 'appVersion'>
      & { authorizedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { profile: (
          { __typename?: 'UserProfile' }
          & Pick<UserProfile, 'id' | 'contactEmail'>
        ) }
      ) }
    )>> }
  )> }
);

export type MyKdssQueryVariables = Exact<{ [key: string]: never; }>;


export type MyKdssQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { kdss: Maybe<Array<(
      { __typename?: 'Kds' }
      & Pick<Kds, 'id' | 'uuid' | 'active'>
      & { merchant: (
        { __typename?: 'Merchant' }
        & Pick<Merchant, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type UnlinkKdsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnlinkKdsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unlinkKds'>
);

export type AcceptInviteMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AcceptInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptUserInvite: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'roles'>
  ) }
);

export type InviteDetailsQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type InviteDetailsQuery = (
  { __typename?: 'Query' }
  & { userInvite: (
    { __typename?: 'UserInvite' }
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ), invitedBy: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { profile: (
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id' | 'firstName' | 'lastName'>
      ) }
    ) }
  ) }
);

export type CreateMerchantMutationVariables = Exact<{
  input: MerchantInput;
}>;


export type CreateMerchantMutation = (
  { __typename?: 'Mutation' }
  & { createMerchant: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
  ) }
);

export type MoveMerchantToDifferentOrganizationMutationVariables = Exact<{
  input: MoveMerchantToDifferentOrganizationInput;
}>;


export type MoveMerchantToDifferentOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'moveMerchantToDifferentOrganization'>
);

export type UpdateMerchantMutationVariables = Exact<{
  input: MerchantInput;
}>;


export type UpdateMerchantMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchant: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
  ) }
);

export type UpdateMerchantDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateMerchantDetailsQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name' | 'active' | 'taxRate' | 'diningOptions'>
    & { location: (
      { __typename?: 'MerchantLocation' }
      & Pick<MerchantLocation, 'id' | 'street' | 'street2' | 'city' | 'state' | 'zipCode' | 'countryCode' | 'geoFenceType'>
    ), operationHours: Maybe<Array<(
      { __typename?: 'MerchantHours' }
      & MerchantOperationHourFragment
    )>> }
  )> }
);

export type ViewMerchantQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewMerchantQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name' | 'active' | 'taxRate' | 'diningOptions'>
    & { location: (
      { __typename?: 'MerchantLocation' }
      & Pick<MerchantLocation, 'id' | 'street' | 'street2' | 'city' | 'state' | 'zipCode' | 'countryCode' | 'latitude' | 'longitude' | 'geoFenceType'>
    ), organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ), merchantContacts: Maybe<Array<(
      { __typename?: 'MerchantContact' }
      & MerchantContactDetailsFragment
    )>>, merchantFeeConfig: (
      { __typename?: 'MerchantFeeConfig' }
      & Pick<MerchantFeeConfig, 'id' | 'default' | 'staticFeeInUsCents' | 'percentageFee'>
    ), freshLocation: Maybe<(
      { __typename?: 'FreshLocationSync' }
      & Pick<FreshLocationSync, 'locationId' | 'lastSyncCompleted'>
    )>, operationHours: Maybe<Array<(
      { __typename?: 'MerchantHours' }
      & MerchantOperationHourFragment
    )>> }
  )> }
);

export type MerchantDeepLinkInfoFragment = (
  { __typename?: 'MerchantDeepLink' }
  & Pick<MerchantDeepLink, 'id' | 'createdOn' | 'link' | 'description'>
);

export type MerchantLinksQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchantLinksQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & { deepLinks: Maybe<Array<(
      { __typename?: 'MerchantDeepLink' }
      & MerchantDeepLinkInfoFragment
    )>> }
  )> }
);

export type MerchantReadableIdQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantReadableIdQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'readableId'>
  )> }
);

export type UpdateMerchantReadableIdMutationVariables = Exact<{
  merchantId: Scalars['ID'];
  readableId: Scalars['String'];
}>;


export type UpdateMerchantReadableIdMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchantReadableId: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'readableId'>
  ) }
);

export type MerchantForSyncQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchantForSyncQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name' | 'active'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ), freshLocation: Maybe<(
      { __typename?: 'FreshLocationSync' }
      & Pick<FreshLocationSync, 'locationId' | 'lastSyncCompleted'>
    )> }
  )> }
);

export type SyncFreshMenuMutationVariables = Exact<{
  merchantId: Scalars['ID'];
  brandId: Scalars['String'];
  token: Scalars['String'];
}>;


export type SyncFreshMenuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncFreshMenu'>
);

export type CreateMerchantContactMutationVariables = Exact<{
  input: CreateMerchantContactInput;
}>;


export type CreateMerchantContactMutation = (
  { __typename?: 'Mutation' }
  & { createMerchantContact: (
    { __typename?: 'MerchantContact' }
    & MerchantContactDetailsFragment
  ) }
);

export type DeleteMerchantContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMerchantContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMerchantContact'>
);

export type MerchantContactDetailsFragment = (
  { __typename?: 'MerchantContact' }
  & Pick<MerchantContact, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'email'>
);

export type MerchantContactDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchantContactDetailsQuery = (
  { __typename?: 'Query' }
  & { merchantContact: Maybe<(
    { __typename?: 'MerchantContact' }
    & MerchantContactDetailsFragment
  )> }
);

export type UpdateMerchantContactMutationVariables = Exact<{
  input: UpdateMerchantContactInput;
}>;


export type UpdateMerchantContactMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchantContact: (
    { __typename?: 'MerchantContact' }
    & MerchantContactDetailsFragment
  ) }
);

export type MerchantOperationHourFragment = (
  { __typename?: 'MerchantHours' }
  & Pick<MerchantHours, 'id' | 'start' | 'end' | 'durationMinutes' | 'dayOfWeek'>
);

export type DeleteMerchantImageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMerchantImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMerchantImage'>
);

export type MerchantImageDetailsFragment = (
  { __typename?: 'MerchantImage' }
  & Pick<MerchantImage, 'id' | 'imageUri' | 'sortOrder' | 'createdOn'>
);

export type MerchantImagesByMerchantQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantImagesByMerchantQuery = (
  { __typename?: 'Query' }
  & { merchantImagesByMerchant: Array<(
    { __typename?: 'MerchantImage' }
    & MerchantImageDetailsFragment
  )> }
);

export type UpdateMerchantImagesMutationVariables = Exact<{
  input: Maybe<Array<MerchantImageInput>>;
  merchantId: Scalars['ID'];
}>;


export type UpdateMerchantImagesMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchantImages: Array<(
    { __typename?: 'MerchantImage' }
    & MerchantImageDetailsFragment
  )> }
);

export type MerchantPromotionQueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchantPromotionQueryQuery = (
  { __typename?: 'Query' }
  & { promotion: (
    { __typename?: 'Promotion' }
    & MerchantPromotionInfoFragment
  ) }
);

export type MerchantPromotionInfoFragment = (
  { __typename?: 'Promotion' }
  & Pick<Promotion, 'id' | 'status' | 'customerFacingName' | 'start' | 'end' | 'newUserOnly' | 'limitPerUser' | 'flatDiscountUsCents' | 'percentageDiscount' | 'maxDiscountUsCents'>
);

export type PromotionsByMerchantQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type PromotionsByMerchantQuery = (
  { __typename?: 'Query' }
  & { promotionsByMerchant: Maybe<Array<(
    { __typename?: 'Promotion' }
    & MerchantPromotionInfoFragment
  )>> }
);

export type MerchantSettingsQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantSettingsQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { settings: Array<(
      { __typename?: 'MerchantSettingOutput' }
      & Pick<MerchantSettingOutput, 'name' | 'description' | 'category' | 'value' | 'valueType'>
    )> }
  )> }
);

export type UpdateMerchantBooleanSettingMutationVariables = Exact<{
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Boolean'];
}>;


export type UpdateMerchantBooleanSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchantBooleanSetting: (
    { __typename?: 'MerchantSettingOutput' }
    & Pick<MerchantSettingOutput, 'name' | 'description' | 'value' | 'valueType' | 'category'>
  ) }
);

export type CancelStripeSubscriptionMutationVariables = Exact<{
  stripeSubscriptionId: Scalars['ID'];
}>;


export type CancelStripeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelStripeSubscription: Array<(
    { __typename?: 'MerchantSubscription' }
    & Pick<MerchantSubscription, 'id'>
    & { subscriptionPlan: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
    )>, merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'stripeCustomerId'>
    ), stripeSubscription: Maybe<(
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'stripeSubscriptionId' | 'stripeCustomerId'>
    )> }
  )> }
);

export type CreateStripeSubscriptionMutationVariables = Exact<{
  merchantIds: Array<Scalars['ID']>;
  organizationManaged: Scalars['Boolean'];
}>;


export type CreateStripeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createStripeSubscription: Maybe<(
    { __typename?: 'SubscriptionPaymentIntent' }
    & Pick<SubscriptionPaymentIntent, 'clientSecret' | 'costUsCents'>
    & { lineItems: Maybe<Array<(
      { __typename?: 'SubscriptionPaymentIntentLineItem' }
      & Pick<SubscriptionPaymentIntentLineItem, 'costUsCents' | 'name'>
    )>>, merchantSubscriptions: Array<(
      { __typename?: 'MerchantSubscription' }
      & Pick<MerchantSubscription, 'id'>
      & { subscriptionPlan: Maybe<(
        { __typename?: 'SubscriptionProduct' }
        & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
      )>, merchant: (
        { __typename?: 'Merchant' }
        & Pick<Merchant, 'id' | 'stripeCustomerId'>
      ), stripeSubscription: Maybe<(
        { __typename?: 'StripeSubscription' }
        & Pick<StripeSubscription, 'stripeSubscriptionId' | 'stripeCustomerId'>
      )> }
    )> }
  )> }
);

export type MerchantInvoicesQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantInvoicesQuery = (
  { __typename?: 'Query' }
  & { merchantInvoices: Array<(
    { __typename?: 'SubscriptionInvoice' }
    & Pick<SubscriptionInvoice, 'status' | 'costUsCents' | 'createdOn' | 'finalizedOn' | 'paidOn'>
    & { subscriptionInvoiceLines: Array<(
      { __typename?: 'SubscriptionInvoiceLine' }
      & Pick<SubscriptionInvoiceLine, 'costUsCents' | 'quantity'>
      & { subscriptionProduct: Maybe<(
        { __typename?: 'SubscriptionProduct' }
        & Pick<SubscriptionProduct, 'name' | 'recurringInterval'>
      )> }
    )> }
  )> }
);

export type MerchantSubscriptionByMerchantIdQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantSubscriptionByMerchantIdQuery = (
  { __typename?: 'Query' }
  & { merchantSubscriptionByMerchantId: Maybe<(
    { __typename?: 'MerchantSubscription' }
    & Pick<MerchantSubscription, 'id' | 'trialEndDate' | 'organizationManaged' | 'subscriptionStatus' | 'termPricing'>
    & { subscriptionPlan: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents' | 'recurringInterval'>
    )>, merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'name' | 'stripeCustomerId'>
    ), stripeSubscription: Maybe<(
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'id' | 'stripeSubscriptionId' | 'subscriptionStatus' | 'stripeCustomerId'>
    )>, platformPricing: Maybe<(
      { __typename?: 'PlatformPricing' }
      & Pick<PlatformPricing, 'id' | 'name' | 'createdOn' | 'instantPriceUsCents' | 'platformPricingType' | 'subscriptionTerm'>
      & { subscriptionProduct: Maybe<(
        { __typename?: 'SubscriptionProduct' }
        & Pick<SubscriptionProduct, 'id' | 'costUsCents' | 'name' | 'recurringInterval'>
      )> }
    )> }
  )> }
);

export type MerchantSubscriptionFeesByMerchantIdQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantSubscriptionFeesByMerchantIdQuery = (
  { __typename?: 'Query' }
  & { merchantSubscriptionFeesByMerchantId: Array<(
    { __typename?: 'MerchantSubscriptionFee' }
    & Pick<MerchantSubscriptionFee, 'id'>
    & { subscriptionFee: (
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
    ) }
  )> }
);

export type OrderDetailsQueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderDetailsQueryQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'createdOn' | 'timerTriggerAt' | 'addedToQueueAt' | 'diningOption'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'name' | 'state' | 'priceCents' | 'priceTokens' | 'subtotalChargeUsCents' | 'subtotalChargeTokens' | 'refunded'>
      & { selectedModifiers: Maybe<Array<(
        { __typename?: 'OrderItemModifier' }
        & Pick<OrderItemModifier, 'id' | 'name' | 'priceCents'>
        & { originalModifier: (
          { __typename?: 'Modifier' }
          & Pick<Modifier, 'id' | 'name' | 'selectedName' | 'printableName'>
        ), originalTopModifier: Maybe<(
          { __typename?: 'Modifier' }
          & Pick<Modifier, 'name'>
        )> }
      )>>, events: Maybe<Array<(
        { __typename?: 'OrderItemEvent' }
        & Pick<OrderItemEvent, 'id' | 'name'>
      )>>, orderableProduct: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'printableName' | 'roaster'>
        & { category: (
          { __typename?: 'Category' }
          & Pick<Category, 'type'>
        ) }
      ) }
    )>, transactions: Maybe<Array<(
      { __typename?: 'OrderTransaction' }
      & Pick<OrderTransaction, 'id' | 'createdOn' | 'tokenValue' | 'subtotalUsCents' | 'feeUsCents' | 'taxUsCents' | 'tipUsCents' | 'discountUsCents' | 'tokenTipUsCents' | 'merchantFeeUsCents' | 'merchantTokenFeeUsCents' | 'merchantDiscountFeeUsCents' | 'stripePaymentUrl' | 'tokenValueUsCents'>
      & { promotions: Maybe<Array<(
        { __typename?: 'Promotion' }
        & Pick<Promotion, 'id'>
      )>>, refunds: Maybe<Array<(
        { __typename?: 'OrderTransactionRefund' }
        & Pick<OrderTransactionRefund, 'id' | 'initializedOn' | 'stripeId' | 'amountUsCents' | 'tokenValue'>
      )>> }
    )>>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { profile: (
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id' | 'firstName' | 'lastName'>
      ) }
    ), events: Array<(
      { __typename?: 'OrderEvent' }
      & Pick<OrderEvent, 'id' | 'name' | 'createdOn'>
    )> }
  ) }
);

export type RefundOrderItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RefundOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { refundOrderItem: Maybe<Array<(
    { __typename?: 'OrderTransactionRefund' }
    & Pick<OrderTransactionRefund, 'id' | 'initializedOn' | 'stripeId' | 'amountUsCents' | 'tokenValue'>
  )>> }
);

export type RefundOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RefundOrderMutation = (
  { __typename?: 'Mutation' }
  & { refundOrder: Maybe<Array<(
    { __typename?: 'OrderTransactionRefund' }
    & Pick<OrderTransactionRefund, 'id' | 'initializedOn' | 'stripeId' | 'amountUsCents' | 'tokenValue'>
  )>> }
);

export type ArchiveFulfillmentLocationMutationVariables = Exact<{
  fulfillmentLocationId: Scalars['ID'];
}>;


export type ArchiveFulfillmentLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'archiveFulfillmentLocation'>
);

export type CreateFulfillmentLocationMutationVariables = Exact<{
  input: CreateFulfillmentLocationInput;
}>;


export type CreateFulfillmentLocationMutation = (
  { __typename?: 'Mutation' }
  & { createFulfillmentLocation: (
    { __typename?: 'FulfillmentLocation' }
    & Pick<FulfillmentLocation, 'id' | 'displayName' | 'urlSafeName' | 'appUrl' | 'webUrl' | 'fulfillmentLocationType' | 'qrCodeDisplayUrl'>
  ) }
);

export type GenerateQrCodeDisplayMutationVariables = Exact<{
  fulfillmentLocationId: Scalars['ID'];
}>;


export type GenerateQrCodeDisplayMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateQRCodeDisplay'>
);

export type GetServingLocationsByMerchantIdQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type GetServingLocationsByMerchantIdQuery = (
  { __typename?: 'Query' }
  & { getServingLocationsByMerchantId: Array<(
    { __typename?: 'FulfillmentLocation' }
    & Pick<FulfillmentLocation, 'id' | 'displayName' | 'urlSafeName' | 'appUrl' | 'webUrl' | 'qrCodeDisplayUrl'>
  )> }
);

export type SetMerchantFeeConfigForMerchantMutationVariables = Exact<{
  merchantId: Scalars['ID'];
  merchantFeeConfigId: Scalars['ID'];
}>;


export type SetMerchantFeeConfigForMerchantMutation = (
  { __typename?: 'Mutation' }
  & { setMerchantFeeConfigForMerchant: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
    & { merchantFeeConfig: (
      { __typename?: 'MerchantFeeConfig' }
      & Pick<MerchantFeeConfig, 'id' | 'default' | 'staticFeeInUsCents' | 'percentageFee'>
    ) }
  ) }
);

export type CreatePlatformPricingPaymentIntentMutationVariables = Exact<{
  merchantIds: Array<Scalars['ID']>;
}>;


export type CreatePlatformPricingPaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { createPlatformPricingPaymentIntent: Maybe<(
    { __typename?: 'SubscriptionPaymentIntent' }
    & Pick<SubscriptionPaymentIntent, 'clientSecret' | 'costUsCents'>
    & { lineItems: Maybe<Array<(
      { __typename?: 'SubscriptionPaymentIntentLineItem' }
      & Pick<SubscriptionPaymentIntentLineItem, 'costUsCents' | 'name'>
    )>>, merchantSubscriptions: Array<(
      { __typename?: 'MerchantSubscription' }
      & Pick<MerchantSubscription, 'id'>
      & { subscriptionPlan: Maybe<(
        { __typename?: 'SubscriptionProduct' }
        & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
      )>, merchant: (
        { __typename?: 'Merchant' }
        & Pick<Merchant, 'id' | 'stripeCustomerId'>
      ), stripeSubscription: Maybe<(
        { __typename?: 'StripeSubscription' }
        & Pick<StripeSubscription, 'stripeSubscriptionId' | 'stripeCustomerId'>
      )> }
    )> }
  )> }
);

export type ModifiersWithInventoryQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ModifiersWithInventoryQuery = (
  { __typename?: 'Query' }
  & { modifiers: Array<(
    { __typename?: 'Modifier' }
    & Pick<Modifier, 'id' | 'name' | 'active'>
    & { inventory: Maybe<(
      { __typename?: 'Inventory' }
      & Pick<Inventory, 'id' | 'inventoryType' | 'currentQuantity'>
    )> }
  )> }
);

export type ProductsWithModifiersQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type ProductsWithModifiersQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { products: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
      & { modifiers: Maybe<Array<(
        { __typename?: 'Modifier' }
        & Pick<Modifier, 'id'>
      )>> }
    )>> }
  )> }
);

export type UpdateTopModifierUsageMutationVariables = Exact<{
  input: UpdateModifierUsageInput;
}>;


export type UpdateTopModifierUsageMutation = (
  { __typename?: 'Mutation' }
  & { updateTopModifierUsage: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
    & { modifiers: Maybe<Array<(
      { __typename?: 'Modifier' }
      & Pick<Modifier, 'id'>
    )>> }
  )> }
);

export type ContractByContractTypeQueryVariables = Exact<{
  contractType: ContractType;
}>;


export type ContractByContractTypeQuery = (
  { __typename?: 'Query' }
  & { contractByContractType: Array<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'content' | 'contractType'>
  )> }
);

export type FetchOrganizationContractSignaturesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchOrganizationContractSignaturesQuery = (
  { __typename?: 'Query' }
  & { organization: Maybe<(
    { __typename?: 'Organization' }
    & { contractSignatures: Array<(
      { __typename?: 'ContractSignature' }
      & Pick<ContractSignature, 'createdOn'>
      & { contract: (
        { __typename?: 'Contract' }
        & Pick<Contract, 'id' | 'content' | 'contractType'>
      ), user: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )> }
  )> }
);

export type SaveContractSignatureMutationVariables = Exact<{
  contractId: Scalars['ID'];
  organizationId: Scalars['ID'];
}>;


export type SaveContractSignatureMutation = (
  { __typename?: 'Mutation' }
  & { saveContractSignature: (
    { __typename?: 'ContractSignature' }
    & Pick<ContractSignature, 'createdOn'>
    & { contract: (
      { __typename?: 'Contract' }
      & Pick<Contract, 'id' | 'content' | 'contractType'>
    ), user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { profile: (
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'contactEmail' | 'firstName' | 'lastName' | 'loginEmail' | 'phoneNumber'>
      ) }
    ) }
  ) }
);

export type SetupOrganizationMutationVariables = Exact<{
  input: SetupOrganizationInput;
}>;


export type SetupOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { setupOrganization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { merchants: Maybe<Array<(
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id'>
    )>> }
  ) }
);

export type ValidateAndCreateUserMutationVariables = Exact<{
  overrideDuplicateCheck: Maybe<Scalars['Boolean']>;
}>;


export type ValidateAndCreateUserMutation = (
  { __typename?: 'Mutation' }
  & { validateAndCreateUser: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'type'>
    & { duplicateAccounts: Maybe<Array<Maybe<(
      { __typename?: 'DuplicateUser' }
      & Pick<DuplicateUser, 'id' | 'accessIdentifier'>
    )>>>, me: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'accessIdentifier'>
      & { profile: (
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id' | 'contactEmail' | 'loginEmail' | 'firstName' | 'lastName' | 'phoneNumber'>
      ) }
    )> }
  ) }
);

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createOrganization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ) }
);

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ) }
);

export type UpdateOrganizationDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateOrganizationDetailsQuery = (
  { __typename?: 'Query' }
  & { organization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type ViewOrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewOrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'stripeCustomerId'>
    & { users: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'roles'>
      & { profile: (
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id' | 'firstName' | 'lastName' | 'loginEmail' | 'contactEmail' | 'phoneNumber'>
      ) }
    )>>, merchants: Maybe<Array<(
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'name' | 'active'>
    )>> }
  )> }
);

export type MerchantPayoutsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type MerchantPayoutsQuery = (
  { __typename?: 'Query' }
  & { merchantPayouts: Array<(
    { __typename?: 'MerchantPayout' }
    & Pick<MerchantPayout, 'id' | 'createdOn' | 'periodStartDate' | 'periodEndDate' | 'payoutAmount' | 'payoutType' | 'manualPayout' | 'stripePaymentUrl' | 'paidOn'>
    & { merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'name'>
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      ) }
    ), orders: Maybe<Array<(
      { __typename?: 'OrderReportingDetails' }
      & OrderForReportsFragment
    )>>, tokenOrders: Maybe<Array<(
      { __typename?: 'OrderReportingDetails' }
      & OrderForReportsFragment
    )>>, discountOrders: Maybe<Array<(
      { __typename?: 'OrderReportingDetails' }
      & OrderForReportsFragment
    )>>, referrals: Maybe<Array<(
      { __typename?: 'MerchantReferral' }
      & Pick<MerchantReferral, 'id' | 'createdOn'>
    )>> }
  )> }
);

export type MerchantSubscriptionsByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type MerchantSubscriptionsByOrganizationIdQuery = (
  { __typename?: 'Query' }
  & { merchantSubscriptionsByOrganizationId: Array<(
    { __typename?: 'MerchantSubscription' }
    & Pick<MerchantSubscription, 'id' | 'trialEndDate' | 'organizationManaged' | 'subscriptionStatus' | 'termPricing'>
    & { subscriptionPlan: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents' | 'recurringInterval'>
    )>, merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'name'>
    ), stripeSubscription: Maybe<(
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'id' | 'stripeSubscriptionId' | 'stripeCustomerId' | 'subscriptionStatus'>
    )>, platformPricing: Maybe<(
      { __typename?: 'PlatformPricing' }
      & Pick<PlatformPricing, 'id' | 'name' | 'createdOn' | 'instantPriceUsCents' | 'platformPricingType' | 'subscriptionTerm'>
      & { subscriptionProduct: Maybe<(
        { __typename?: 'SubscriptionProduct' }
        & Pick<SubscriptionProduct, 'id' | 'costUsCents' | 'name' | 'recurringInterval'>
      )> }
    )> }
  )> }
);

export type MerchantSubscriptionFeesByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type MerchantSubscriptionFeesByOrganizationIdQuery = (
  { __typename?: 'Query' }
  & { merchantSubscriptionFeesByOrganizationId: Array<(
    { __typename?: 'MerchantSubscriptionFee' }
    & Pick<MerchantSubscriptionFee, 'id'>
    & { merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'name'>
    ), subscriptionFee: (
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
    ) }
  )> }
);

export type OrganizationInvoicesQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationInvoicesQuery = (
  { __typename?: 'Query' }
  & { organizationInvoices: Array<(
    { __typename?: 'SubscriptionInvoice' }
    & Pick<SubscriptionInvoice, 'status' | 'costUsCents' | 'createdOn' | 'finalizedOn' | 'paidOn'>
    & { subscriptionInvoiceLines: Array<(
      { __typename?: 'SubscriptionInvoiceLine' }
      & Pick<SubscriptionInvoiceLine, 'costUsCents' | 'quantity'>
      & { subscriptionProduct: Maybe<(
        { __typename?: 'SubscriptionProduct' }
        & Pick<SubscriptionProduct, 'name' | 'recurringInterval'>
      )> }
    )> }
  )> }
);

export type SetMerchantSubscriptionsMutationVariables = Exact<{
  inputs: Array<SetMerchantSubscriptionInput>;
}>;


export type SetMerchantSubscriptionsMutation = (
  { __typename?: 'Mutation' }
  & { setMerchantSubscriptions: Array<(
    { __typename?: 'MerchantSubscription' }
    & Pick<MerchantSubscription, 'id' | 'trialEndDate' | 'organizationManaged' | 'termPricing'>
    & { subscriptionPlan: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
    )>, merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'stripeCustomerId'>
    ), stripeSubscription: Maybe<(
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'stripeSubscriptionId'>
    )>, platformPricing: Maybe<(
      { __typename?: 'PlatformPricing' }
      & Pick<PlatformPricing, 'id' | 'name' | 'createdOn' | 'instantPriceUsCents' | 'platformPricingType' | 'subscriptionTerm'>
      & { subscriptionProduct: Maybe<(
        { __typename?: 'SubscriptionProduct' }
        & Pick<SubscriptionProduct, 'id' | 'costUsCents' | 'name' | 'recurringInterval'>
      )> }
    )> }
  )> }
);

export type UpdateMerchantsOnOrgSubscriptionMutationVariables = Exact<{
  merchantIds: Array<Scalars['ID']>;
}>;


export type UpdateMerchantsOnOrgSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchantsOnOrgSubscription: Array<(
    { __typename?: 'MerchantSubscription' }
    & Pick<MerchantSubscription, 'id' | 'trialEndDate'>
    & { subscriptionPlan: Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'costUsCents'>
    )>, merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'name'>
    ), stripeSubscription: Maybe<(
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'id' | 'stripeSubscriptionId' | 'stripeCustomerId' | 'subscriptionStatus'>
    )> }
  )> }
);

export type OrderForReportsFragment = (
  { __typename?: 'OrderReportingDetails' }
  & Pick<OrderReportingDetails, 'id' | 'date' | 'userId' | 'firstName' | 'lastName' | 'tokens' | 'tokenValue' | 'tokenTipValue' | 'tokenFeeValue' | 'subTotal' | 'taxes' | 'tip' | 'customerFee' | 'merchantFee' | 'netMerchantPayout' | 'merchantDiscountFeeUsCents' | 'rdyPromotionDiscountValue' | 'rdyPromotionName' | 'rdyPaidTokenCount' | 'organizationPaidTokenCount'>
);

export type OrganziationSettingsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganziationSettingsQuery = (
  { __typename?: 'Query' }
  & { organization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { settings: Array<(
      { __typename?: 'OrganizationSettingOutput' }
      & Pick<OrganizationSettingOutput, 'name' | 'description' | 'category' | 'value' | 'valueType'>
    )> }
  )> }
);

export type UpdateOrganizationBooleanSettingMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Boolean'];
}>;


export type UpdateOrganizationBooleanSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationBooleanSetting: (
    { __typename?: 'OrganizationSettingOutput' }
    & Pick<OrganizationSettingOutput, 'name' | 'description' | 'value' | 'valueType' | 'category'>
  ) }
);

export type UpdateOrganizationNumericSettingMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Int'];
}>;


export type UpdateOrganizationNumericSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationNumericSetting: (
    { __typename?: 'OrganizationSettingOutput' }
    & Pick<OrganizationSettingOutput, 'name' | 'description' | 'value' | 'valueType' | 'category'>
  ) }
);

export type OrganizationReadableIdQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationReadableIdQuery = (
  { __typename?: 'Query' }
  & { organization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'readableId'>
  )> }
);

export type UpdateOrganizationReadableIdMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  readableId: Scalars['String'];
}>;


export type UpdateOrganizationReadableIdMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationReadableId: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'readableId'>
  ) }
);

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  ) }
);

export type MerchantCatalogQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type MerchantCatalogQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { activeCatalog: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'id'>
      & { categories: Maybe<Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name' | 'type'>
      )>> }
    )> }
  )> }
);

export type ArchiveCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID'];
}>;


export type ArchiveCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'archiveCategory'>
);

export type UpdateCategoryNameMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateCategoryNameMutation = (
  { __typename?: 'Mutation' }
  & { updateCategoryName: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  ) }
);

export type UpdateCategorySortOrdersMutationVariables = Exact<{
  inputs: Array<UpdateSortOrderInput>;
}>;


export type UpdateCategorySortOrdersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCategorySortOrders'>
);

export type UpdateProductCategoriesMutationVariables = Exact<{
  inputs: Array<UpdateProductCategoryInput>;
}>;


export type UpdateProductCategoriesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProductCategories'>
);

export type UpdateProductNameMutationVariables = Exact<{
  productId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateProductNameMutation = (
  { __typename?: 'Mutation' }
  & { updateProductName: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  ) }
);

export type UpdateProductSortOrdersMutationVariables = Exact<{
  inputs: Array<UpdateSortOrderInput>;
}>;


export type UpdateProductSortOrdersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProductSortOrders'>
);

export type UpdateProductsActiveMutationVariables = Exact<{
  productIds: Array<Scalars['ID']>;
  active: Scalars['Boolean'];
}>;


export type UpdateProductsActiveMutation = (
  { __typename?: 'Mutation' }
  & { updateProductsActive: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'active'>
  )> }
);

export type UpdateProductFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name' | 'description' | 'active' | 'priceCents'>
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  ), modifiers: Maybe<Array<(
    { __typename?: 'Modifier' }
    & Pick<Modifier, 'id' | 'name' | 'description' | 'active' | 'childSelectionType' | 'defaultSelectionIds' | 'priceCents'>
    & { children: Maybe<Array<(
      { __typename?: 'Modifier' }
      & Pick<Modifier, 'id' | 'name' | 'active' | 'priceCents' | 'selectedName' | 'selected'>
      & { inventory: Maybe<(
        { __typename?: 'Inventory' }
        & InventoryDetailsFragment
      )> }
    )>> }
  )>>, productTags: Maybe<Array<(
    { __typename?: 'ProductTag' }
    & Pick<ProductTag, 'value' | 'tagType'>
  )>>, inventory: Maybe<(
    { __typename?: 'Inventory' }
    & InventoryDetailsFragment
  )>, availabilityWindows: Maybe<Array<(
    { __typename?: 'AvailabilityWindow' }
    & Pick<AvailabilityWindow, 'id' | 'dayOfWeek' | 'start' | 'end'>
  )>> }
);

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'priceCents'>
  ) }
);

export type UpdateProductDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateProductDetailsQuery = (
  { __typename?: 'Query' }
  & { product: Maybe<(
    { __typename?: 'Product' }
    & UpdateProductFragment
  )> }
);

export type CatalogProductSelectQueryVariables = Exact<{
  catalogId: Scalars['ID'];
}>;


export type CatalogProductSelectQuery = (
  { __typename?: 'Query' }
  & { catalog: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { categories: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
      & { activeProducts: Maybe<Array<(
        { __typename?: 'Product' }
        & CatalogProductFragment
      )>> }
    )>> }
  )> }
);

export type CatalogProductFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name'>
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'type'>
  ) }
);

export type CategorySelectQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type CategorySelectQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & SelectCategoryFragment
  )> }
);

export type SelectCategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'type' | 'sortOrder'>
);

export type CopyAllProductsFromMerchantMutationVariables = Exact<{
  input: CopyAllProductsFromMerchantInput;
}>;


export type CopyAllProductsFromMerchantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyAllProductsFromMerchant'>
);

export type CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )> }
);

export type CreateTemplateMenuMutationVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type CreateTemplateMenuMutation = (
  { __typename?: 'Mutation' }
  & { createTemplateMenu: Maybe<Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'active'>
    & { category: (
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    ) }
  )>> }
);

export type DuplicateProductMutationVariables = Exact<{
  productId: Scalars['ID'];
}>;


export type DuplicateProductMutation = (
  { __typename?: 'Mutation' }
  & { duplicateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'active'>
    & { category: (
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    ) }
  ) }
);

export type LibraryImagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LibraryImagesQuery = (
  { __typename?: 'Query' }
  & { libraryImages: Array<(
    { __typename?: 'LibraryImage' }
    & Pick<LibraryImage, 'id' | 'imageUri' | 'imageCode'>
  )> }
);

export type ProductImagesByMerchantQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type ProductImagesByMerchantQuery = (
  { __typename?: 'Query' }
  & { productImagesByMerchant: Array<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'id' | 'imageUri' | 'sortOrder'>
    & { libraryImage: Maybe<(
      { __typename?: 'LibraryImage' }
      & Pick<LibraryImage, 'imageUri' | 'imageCode'>
    )> }
  )> }
);

export type GetMenuSyncConfigQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type GetMenuSyncConfigQuery = (
  { __typename?: 'Query' }
  & { getMenuSyncConfig: Maybe<(
    { __typename?: 'MenuSyncConfig' }
    & Pick<MenuSyncConfig, 'id' | 'source' | 'active'>
  )> }
);

export type UpdateMenuSyncConfigActiveMutationVariables = Exact<{
  merchantId: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type UpdateMenuSyncConfigActiveMutation = (
  { __typename?: 'Mutation' }
  & { updateMenuSyncConfigActive: Maybe<(
    { __typename?: 'MenuSyncConfig' }
    & Pick<MenuSyncConfig, 'id' | 'source' | 'active'>
  )> }
);

export type ProductListQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type ProductListQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { products: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'active' | 'priceCents' | 'sortOrder'>
      & { category: (
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      ), images: Maybe<Array<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'id' | 'imageUri' | 'sortOrder'>
        & { libraryImage: Maybe<(
          { __typename?: 'LibraryImage' }
          & Pick<LibraryImage, 'id' | 'imageUri'>
        )> }
      )>>, modifiers: Maybe<Array<(
        { __typename?: 'Modifier' }
        & Pick<Modifier, 'id' | 'childSelectionType' | 'priceCents' | 'active' | 'name' | 'defaultSelectionIds'>
        & { children: Maybe<Array<(
          { __typename?: 'Modifier' }
          & Pick<Modifier, 'id' | 'priceCents' | 'selected' | 'active'>
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetExternalClientSyncsByMerchantIdQueryVariables = Exact<{
  merchantId: Scalars['ID'];
}>;


export type GetExternalClientSyncsByMerchantIdQuery = (
  { __typename?: 'Query' }
  & { getExternalClientSyncsByMerchantId: Array<(
    { __typename?: 'ExternalClientSync' }
    & Pick<ExternalClientSync, 'startedOn' | 'completedOn' | 'externalSourceId'>
    & { events: Maybe<Array<Maybe<(
      { __typename?: 'ExternalClientSyncEvent' }
      & Pick<ExternalClientSyncEvent, 'timestamp' | 'eventType' | 'url' | 'response'>
    )>>> }
  )> }
);

export type SyncSquareMenuMutationVariables = Exact<{
  merchantId: Scalars['ID'];
  baseUrl: Scalars['String'];
  onlyProductsWithCategories: Scalars['Boolean'];
  doNotImportCategories: Scalars['Boolean'];
}>;


export type SyncSquareMenuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncSquareMenu'>
);

export type UpdateProductActiveMutationVariables = Exact<{
  productId: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type UpdateProductActiveMutation = (
  { __typename?: 'Mutation' }
  & { updateProductActive: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'active'>
  ) }
);

export type CreateCsrfTokenMutationVariables = Exact<{
  data: Maybe<Scalars['String']>;
}>;


export type CreateCsrfTokenMutation = (
  { __typename?: 'Mutation' }
  & { createCsrfToken: (
    { __typename?: 'CsrfToken' }
    & Pick<CsrfToken, 'id' | 'value'>
  ) }
);

export type GenerateSquareOAuthTokenMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type GenerateSquareOAuthTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateSquareOAuthToken'>
);

export type HasValidSquareOAuthTokenQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type HasValidSquareOAuthTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hasValidSquareOAuthToken'>
);

export type SquareLocationsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  filter: SquareLocationQueryFilter;
}>;


export type SquareLocationsQuery = (
  { __typename?: 'Query' }
  & { squareLocations: Array<(
    { __typename?: 'SquareLocation' }
    & Pick<SquareLocation, 'id' | 'name' | 'phoneNumber' | 'latitude' | 'longitude' | 'merchantHasSquareSite'>
    & { address: Maybe<(
      { __typename?: 'SquareAddress' }
      & Pick<SquareAddress, 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'>
    )> }
  )> }
);

export type SyncSquareLocationsMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  inputs: Array<SquareSyncInput>;
}>;


export type SyncSquareLocationsMutation = (
  { __typename?: 'Mutation' }
  & { syncSquareLocations: Array<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { kdsIntegrations: Maybe<Array<(
      { __typename?: 'MerchantKdsIntegration' }
      & Pick<MerchantKdsIntegration, 'id' | 'type' | 'locationId' | 'displayName' | 'active' | 'sendOrdersToRdy'>
    )>> }
  )> }
);

export type InviteUserMutationVariables = Exact<{
  input: SendUserInviteInput;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendUserInvite'>
);

export type ProfileInfoFragment = (
  { __typename?: 'UserProfile' }
  & Pick<UserProfile, 'id' | 'contactEmail' | 'loginEmail' | 'firstName' | 'lastName' | 'phoneNumber'>
);

export type UpdatePersonalInfoMutationVariables = Exact<{
  input: UpdateMyProfileInput;
}>;


export type UpdatePersonalInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateMyProfile: (
    { __typename?: 'UserProfile' }
    & ProfileInfoFragment
  ) }
);

export type UserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInfoQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile: (
      { __typename?: 'UserProfile' }
      & ProfileInfoFragment
    ) }
  )> }
);

export const InventoryDetailsFragmentDoc = gql`
    fragment InventoryDetails on Inventory {
  inventoryType
  restockQuantity
  currentQuantity
  currentlyAvailable
  restockDays {
    dayOfWeek
  }
}
    `;
export const ModifierOptionFragmentDoc = gql`
    fragment ModifierOption on Modifier {
  id
  name
  printableName
  description
  childSelectionType
  defaultSelectionIds
  priceCents
  selected
  selectedName
  active
  archived
  isSynced
  inventory {
    ...InventoryDetails
  }
}
    ${InventoryDetailsFragmentDoc}`;
export const TopModifierFragmentDoc = gql`
    fragment TopModifier on Modifier {
  id
  name
  printableName
  description
  childSelectionType
  priceCents
  active
  defaultSelectionIds
  archived
  isSynced
  children {
    ...ModifierOption
  }
  allChildren {
    ...ModifierOption
  }
}
    ${ModifierOptionFragmentDoc}`;
export const OrderItemSummaryFragmentDoc = gql`
    fragment OrderItemSummary on OrderItem {
  id
  name
  priceCents
  subtotalChargeUsCents
  subtotalChargeTokens
  orderableProduct {
    id
    name
    category {
      name
    }
  }
  selectedModifiers {
    priceCents
  }
  refunded
}
    `;
export const OrderSummaryFragmentDoc = gql`
    fragment OrderSummary on Order {
  id
  createdOn
  transactions {
    id
    tokenValue
    subtotalUsCents
    discountUsCents
    feeUsCents
    taxUsCents
    tipUsCents
    tokenTipUsCents
    merchantFeeUsCents
    merchantTokenFeeUsCents
    merchantDiscountFeeUsCents
    transactionPromotions {
      id
      createdOn
      usCentsDeductedFromSubtotal
      promotion {
        id
        createdOn
        customerFacingName
        costOwner
      }
    }
  }
  user {
    id
    profile {
      firstName
      lastName
    }
  }
  items {
    ...OrderItemSummary
  }
}
    ${OrderItemSummaryFragmentDoc}`;
export const MerchantPayoutDetailsFragmentDoc = gql`
    fragment MerchantPayoutDetails on MerchantPayout {
  id
  createdOn
  merchant {
    id
    name
  }
  periodStartDate
  periodEndDate
  payoutAmount
  payoutType
  manualPayout
}
    `;
export const OrganizationDetailsFragmentDoc = gql`
    fragment OrganizationDetails on Organization {
  id
  name
}
    `;
export const ModifierDetailsFragmentDoc = gql`
    fragment ModifierDetails on Modifier {
  id
  name
  printableName
  description
  active
  childSelectionType
  priceCents
  defaultSelectionIds
  isSynced
  allChildren {
    id
    name
    printableName
    active
    priceCents
    selectedName
    selected
    inventory {
      ...InventoryDetails
    }
  }
}
    ${InventoryDetailsFragmentDoc}`;
export const ProductDetailsFragmentDoc = gql`
    fragment ProductDetails on Product {
  id
  name
  printableName
  description
  active
  archived
  priceCents
  sizeInGrams
  originType
  roaster
  roastType
  isSynced
  taxRate
  pickUpPriceUsCents
  suspendedUntil
  source
  catalogs {
    id
    name
  }
  category {
    id
    name
    type
  }
  modifiers {
    ...ModifierDetails
  }
  allModifiers {
    ...ModifierDetails
  }
  productTags {
    value
    tagType
  }
  images {
    imageUri
    createdOn
    sortOrder
    libraryImage {
      id
      imageUri
    }
  }
  inventory {
    ...InventoryDetails
  }
  availabilityWindows {
    dayOfWeek
    start
    end
  }
}
    ${ModifierDetailsFragmentDoc}
${InventoryDetailsFragmentDoc}`;
export const PromotionTopInfoFragmentDoc = gql`
    fragment PromotionTopInfo on Promotion {
  id
  status
  customerFacingName
  createdOn
  merchants {
    id
    name
  }
}
    `;
export const PromoCodeInfoFragmentDoc = gql`
    fragment PromoCodeInfo on PromoCode {
  id
  createdOn
  code
  validStart
  validEnd
  archived
  limitTotalUses
  deepLink
}
    `;
export const PromotionInfoFragmentDoc = gql`
    fragment PromotionInfo on Promotion {
  id
  createdOn
  customerFacingName
  costOwner
  start
  end
  newUserOnly
  recurring
  frequency
  type
  limitPerUser
  flatDiscountUsCents
  percentageDiscount
  maxDiscountUsCents
  status
  dispenseRewardOnEntry
  archived
  tokenType {
    id
    name
  }
  merchants {
    id
    name
  }
  numberOfTokens
  codes {
    ...PromoCodeInfo
  }
  productTags
}
    ${PromoCodeInfoFragmentDoc}`;
export const TokenInfoFragmentDoc = gql`
    fragment TokenInfo on Token {
  id
  createdOn
  userId
  expirationTimestamp
  spent
}
    `;
export const CatalogProductFragmentDoc = gql`
    fragment CatalogProduct on Product {
  id
  name
  category {
    id
    name
    type
  }
}
    `;
export const CatalogSuggestedItemFragmentDoc = gql`
    fragment CatalogSuggestedItem on SuggestedItem {
  id
  product {
    ...CatalogProduct
  }
}
    ${CatalogProductFragmentDoc}`;
export const MerchantDeepLinkInfoFragmentDoc = gql`
    fragment MerchantDeepLinkInfo on MerchantDeepLink {
  id
  createdOn
  link
  description
}
    `;
export const MerchantContactDetailsFragmentDoc = gql`
    fragment MerchantContactDetails on MerchantContact {
  id
  firstName
  lastName
  phoneNumber
  email
}
    `;
export const MerchantOperationHourFragmentDoc = gql`
    fragment MerchantOperationHour on MerchantHours {
  id
  start
  end
  durationMinutes
  dayOfWeek
}
    `;
export const MerchantImageDetailsFragmentDoc = gql`
    fragment MerchantImageDetails on MerchantImage {
  id
  imageUri
  sortOrder
  createdOn
}
    `;
export const MerchantPromotionInfoFragmentDoc = gql`
    fragment MerchantPromotionInfo on Promotion {
  id
  status
  customerFacingName
  start
  end
  newUserOnly
  limitPerUser
  flatDiscountUsCents
  percentageDiscount
  maxDiscountUsCents
}
    `;
export const OrderForReportsFragmentDoc = gql`
    fragment OrderForReports on OrderReportingDetails {
  id
  date
  userId
  firstName
  lastName
  tokens
  tokenValue
  tokenTipValue
  tokenFeeValue
  subTotal
  taxes
  tip
  customerFee
  merchantFee
  netMerchantPayout
  merchantDiscountFeeUsCents
  rdyPromotionDiscountValue
  rdyPromotionName
  rdyPaidTokenCount
  organizationPaidTokenCount
}
    `;
export const UpdateProductFragmentDoc = gql`
    fragment UpdateProduct on Product {
  id
  name
  description
  active
  priceCents
  category {
    id
    name
  }
  modifiers {
    id
    name
    description
    active
    childSelectionType
    defaultSelectionIds
    priceCents
    children {
      id
      name
      active
      priceCents
      selectedName
      selected
      inventory {
        ...InventoryDetails
      }
    }
  }
  productTags {
    value
    tagType
  }
  inventory {
    ...InventoryDetails
  }
  availabilityWindows {
    id
    dayOfWeek
    start
    end
  }
}
    ${InventoryDetailsFragmentDoc}`;
export const SelectCategoryFragmentDoc = gql`
    fragment SelectCategory on Category {
  id
  name
  type
  sortOrder
}
    `;
export const ProfileInfoFragmentDoc = gql`
    fragment ProfileInfo on UserProfile {
  id
  contactEmail
  loginEmail
  firstName
  lastName
  phoneNumber
}
    `;
export const CreateMerchantFromLocationDocument = gql`
    mutation createMerchantFromLocation($organizationId: ID!, $freshLocationId: String!, $brandId: String!, $token: String!) {
  createMerchantFromLocation(
    organizationId: $organizationId
    freshLocationId: $freshLocationId
    brandId: $brandId
    token: $token
  ) {
    id
    name
    freshLocation {
      locationId
      lastSyncCompleted
    }
  }
}
    `;
export type CreateMerchantFromLocationMutationFn = Apollo.MutationFunction<CreateMerchantFromLocationMutation, CreateMerchantFromLocationMutationVariables>;

/**
 * __useCreateMerchantFromLocationMutation__
 *
 * To run a mutation, you first call `useCreateMerchantFromLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantFromLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantFromLocationMutation, { data, loading, error }] = useCreateMerchantFromLocationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      freshLocationId: // value for 'freshLocationId'
 *      brandId: // value for 'brandId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateMerchantFromLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchantFromLocationMutation, CreateMerchantFromLocationMutationVariables>) {
        return Apollo.useMutation<CreateMerchantFromLocationMutation, CreateMerchantFromLocationMutationVariables>(CreateMerchantFromLocationDocument, baseOptions);
      }
export type CreateMerchantFromLocationMutationHookResult = ReturnType<typeof useCreateMerchantFromLocationMutation>;
export type CreateMerchantFromLocationMutationResult = Apollo.MutationResult<CreateMerchantFromLocationMutation>;
export type CreateMerchantFromLocationMutationOptions = Apollo.BaseMutationOptions<CreateMerchantFromLocationMutation, CreateMerchantFromLocationMutationVariables>;
export const GetExternalClientSyncsDocument = gql`
    query GetExternalClientSyncs($organizationId: ID!) {
  getExternalClientSyncs(organizationId: $organizationId) {
    startedOn
    completedOn
    externalSourceId
    identifier
    events {
      timestamp
      eventType
      url
      response
    }
  }
}
    `;

/**
 * __useGetExternalClientSyncsQuery__
 *
 * To run a query within a React component, call `useGetExternalClientSyncsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalClientSyncsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalClientSyncsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetExternalClientSyncsQuery(baseOptions: Apollo.QueryHookOptions<GetExternalClientSyncsQuery, GetExternalClientSyncsQueryVariables>) {
        return Apollo.useQuery<GetExternalClientSyncsQuery, GetExternalClientSyncsQueryVariables>(GetExternalClientSyncsDocument, baseOptions);
      }
export function useGetExternalClientSyncsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalClientSyncsQuery, GetExternalClientSyncsQueryVariables>) {
          return Apollo.useLazyQuery<GetExternalClientSyncsQuery, GetExternalClientSyncsQueryVariables>(GetExternalClientSyncsDocument, baseOptions);
        }
export type GetExternalClientSyncsQueryHookResult = ReturnType<typeof useGetExternalClientSyncsQuery>;
export type GetExternalClientSyncsLazyQueryHookResult = ReturnType<typeof useGetExternalClientSyncsLazyQuery>;
export type GetExternalClientSyncsQueryResult = Apollo.QueryResult<GetExternalClientSyncsQuery, GetExternalClientSyncsQueryVariables>;
export const GetFreshLocationsDocument = gql`
    query getFreshLocations($organizationId: ID!, $brandId: String!, $token: String!) {
  getFreshLocations(
    organizationId: $organizationId
    brandId: $brandId
    token: $token
  ) {
    id
    name
    address {
      line1
      line2
      city
      state
      zip
      phone
    }
  }
}
    `;

/**
 * __useGetFreshLocationsQuery__
 *
 * To run a query within a React component, call `useGetFreshLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreshLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreshLocationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      brandId: // value for 'brandId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetFreshLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetFreshLocationsQuery, GetFreshLocationsQueryVariables>) {
        return Apollo.useQuery<GetFreshLocationsQuery, GetFreshLocationsQueryVariables>(GetFreshLocationsDocument, baseOptions);
      }
export function useGetFreshLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFreshLocationsQuery, GetFreshLocationsQueryVariables>) {
          return Apollo.useLazyQuery<GetFreshLocationsQuery, GetFreshLocationsQueryVariables>(GetFreshLocationsDocument, baseOptions);
        }
export type GetFreshLocationsQueryHookResult = ReturnType<typeof useGetFreshLocationsQuery>;
export type GetFreshLocationsLazyQueryHookResult = ReturnType<typeof useGetFreshLocationsLazyQuery>;
export type GetFreshLocationsQueryResult = Apollo.QueryResult<GetFreshLocationsQuery, GetFreshLocationsQueryVariables>;
export const GetMerchantsWithFreshLocationByOrganizationIdDocument = gql`
    query GetMerchantsWithFreshLocationByOrganizationId($id: ID!) {
  organization(id: $id) {
    id
    merchants {
      id
      name
      freshLocation {
        locationId
        lastSyncCompleted
      }
    }
  }
}
    `;

/**
 * __useGetMerchantsWithFreshLocationByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetMerchantsWithFreshLocationByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantsWithFreshLocationByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantsWithFreshLocationByOrganizationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMerchantsWithFreshLocationByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetMerchantsWithFreshLocationByOrganizationIdQuery, GetMerchantsWithFreshLocationByOrganizationIdQueryVariables>) {
        return Apollo.useQuery<GetMerchantsWithFreshLocationByOrganizationIdQuery, GetMerchantsWithFreshLocationByOrganizationIdQueryVariables>(GetMerchantsWithFreshLocationByOrganizationIdDocument, baseOptions);
      }
export function useGetMerchantsWithFreshLocationByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantsWithFreshLocationByOrganizationIdQuery, GetMerchantsWithFreshLocationByOrganizationIdQueryVariables>) {
          return Apollo.useLazyQuery<GetMerchantsWithFreshLocationByOrganizationIdQuery, GetMerchantsWithFreshLocationByOrganizationIdQueryVariables>(GetMerchantsWithFreshLocationByOrganizationIdDocument, baseOptions);
        }
export type GetMerchantsWithFreshLocationByOrganizationIdQueryHookResult = ReturnType<typeof useGetMerchantsWithFreshLocationByOrganizationIdQuery>;
export type GetMerchantsWithFreshLocationByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetMerchantsWithFreshLocationByOrganizationIdLazyQuery>;
export type GetMerchantsWithFreshLocationByOrganizationIdQueryResult = Apollo.QueryResult<GetMerchantsWithFreshLocationByOrganizationIdQuery, GetMerchantsWithFreshLocationByOrganizationIdQueryVariables>;
export const ArchiveMerchantDocument = gql`
    mutation ArchiveMerchant($id: ID!) {
  archiveMerchant(id: $id) {
    id
  }
}
    `;
export type ArchiveMerchantMutationFn = Apollo.MutationFunction<ArchiveMerchantMutation, ArchiveMerchantMutationVariables>;

/**
 * __useArchiveMerchantMutation__
 *
 * To run a mutation, you first call `useArchiveMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMerchantMutation, { data, loading, error }] = useArchiveMerchantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveMerchantMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveMerchantMutation, ArchiveMerchantMutationVariables>) {
        return Apollo.useMutation<ArchiveMerchantMutation, ArchiveMerchantMutationVariables>(ArchiveMerchantDocument, baseOptions);
      }
export type ArchiveMerchantMutationHookResult = ReturnType<typeof useArchiveMerchantMutation>;
export type ArchiveMerchantMutationResult = Apollo.MutationResult<ArchiveMerchantMutation>;
export type ArchiveMerchantMutationOptions = Apollo.BaseMutationOptions<ArchiveMerchantMutation, ArchiveMerchantMutationVariables>;
export const FeatureFlagDocument = gql`
    query FeatureFlag($merchantId: ID!, $featureFlagName: FeatureFlagName!) {
  featureFlag(merchantId: $merchantId, featureFlagName: $featureFlagName) {
    name
    enabled
  }
}
    `;

/**
 * __useFeatureFlagQuery__
 *
 * To run a query within a React component, call `useFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      featureFlagName: // value for 'featureFlagName'
 *   },
 * });
 */
export function useFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
        return Apollo.useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, baseOptions);
      }
export function useFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
          return Apollo.useLazyQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, baseOptions);
        }
export type FeatureFlagQueryHookResult = ReturnType<typeof useFeatureFlagQuery>;
export type FeatureFlagLazyQueryHookResult = ReturnType<typeof useFeatureFlagLazyQuery>;
export type FeatureFlagQueryResult = Apollo.QueryResult<FeatureFlagQuery, FeatureFlagQueryVariables>;
export const MerchantReferralsByMerchantIdDocument = gql`
    query merchantReferralsByMerchantId($id: ID!, $startDate: Instant!, $endDate: Instant!) {
  merchantReferralsByMerchantId(id: $id, startDate: $startDate, endDate: $endDate) {
    id
    createdOn
  }
}
    `;

/**
 * __useMerchantReferralsByMerchantIdQuery__
 *
 * To run a query within a React component, call `useMerchantReferralsByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantReferralsByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantReferralsByMerchantIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useMerchantReferralsByMerchantIdQuery(baseOptions: Apollo.QueryHookOptions<MerchantReferralsByMerchantIdQuery, MerchantReferralsByMerchantIdQueryVariables>) {
        return Apollo.useQuery<MerchantReferralsByMerchantIdQuery, MerchantReferralsByMerchantIdQueryVariables>(MerchantReferralsByMerchantIdDocument, baseOptions);
      }
export function useMerchantReferralsByMerchantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantReferralsByMerchantIdQuery, MerchantReferralsByMerchantIdQueryVariables>) {
          return Apollo.useLazyQuery<MerchantReferralsByMerchantIdQuery, MerchantReferralsByMerchantIdQueryVariables>(MerchantReferralsByMerchantIdDocument, baseOptions);
        }
export type MerchantReferralsByMerchantIdQueryHookResult = ReturnType<typeof useMerchantReferralsByMerchantIdQuery>;
export type MerchantReferralsByMerchantIdLazyQueryHookResult = ReturnType<typeof useMerchantReferralsByMerchantIdLazyQuery>;
export type MerchantReferralsByMerchantIdQueryResult = Apollo.QueryResult<MerchantReferralsByMerchantIdQuery, MerchantReferralsByMerchantIdQueryVariables>;
export const UploadMerchantImageDocument = gql`
    mutation UploadMerchantImage($file: Upload!, $merchantId: ID!, $sortOrder: Int!) {
  uploadMerchantImage(file: $file, merchantId: $merchantId, sortOrder: $sortOrder) {
    ...MerchantImageDetails
  }
}
    ${MerchantImageDetailsFragmentDoc}`;
export type UploadMerchantImageMutationFn = Apollo.MutationFunction<UploadMerchantImageMutation, UploadMerchantImageMutationVariables>;

/**
 * __useUploadMerchantImageMutation__
 *
 * To run a mutation, you first call `useUploadMerchantImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMerchantImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMerchantImageMutation, { data, loading, error }] = useUploadMerchantImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      merchantId: // value for 'merchantId'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useUploadMerchantImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadMerchantImageMutation, UploadMerchantImageMutationVariables>) {
        return Apollo.useMutation<UploadMerchantImageMutation, UploadMerchantImageMutationVariables>(UploadMerchantImageDocument, baseOptions);
      }
export type UploadMerchantImageMutationHookResult = ReturnType<typeof useUploadMerchantImageMutation>;
export type UploadMerchantImageMutationResult = Apollo.MutationResult<UploadMerchantImageMutation>;
export type UploadMerchantImageMutationOptions = Apollo.BaseMutationOptions<UploadMerchantImageMutation, UploadMerchantImageMutationVariables>;
export const ArchiveModifierOptionDocument = gql`
    mutation ArchiveModifierOption($id: ID!) {
  archiveModifier(id: $id) {
    ...ModifierOption
  }
}
    ${ModifierOptionFragmentDoc}`;
export type ArchiveModifierOptionMutationFn = Apollo.MutationFunction<ArchiveModifierOptionMutation, ArchiveModifierOptionMutationVariables>;

/**
 * __useArchiveModifierOptionMutation__
 *
 * To run a mutation, you first call `useArchiveModifierOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveModifierOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveModifierOptionMutation, { data, loading, error }] = useArchiveModifierOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveModifierOptionMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveModifierOptionMutation, ArchiveModifierOptionMutationVariables>) {
        return Apollo.useMutation<ArchiveModifierOptionMutation, ArchiveModifierOptionMutationVariables>(ArchiveModifierOptionDocument, baseOptions);
      }
export type ArchiveModifierOptionMutationHookResult = ReturnType<typeof useArchiveModifierOptionMutation>;
export type ArchiveModifierOptionMutationResult = Apollo.MutationResult<ArchiveModifierOptionMutation>;
export type ArchiveModifierOptionMutationOptions = Apollo.BaseMutationOptions<ArchiveModifierOptionMutation, ArchiveModifierOptionMutationVariables>;
export const ArchiveModifierOptionsDocument = gql`
    mutation ArchiveModifierOptions($ids: [ID!]!) {
  archiveModifiers(ids: $ids) {
    ...ModifierOption
  }
}
    ${ModifierOptionFragmentDoc}`;
export type ArchiveModifierOptionsMutationFn = Apollo.MutationFunction<ArchiveModifierOptionsMutation, ArchiveModifierOptionsMutationVariables>;

/**
 * __useArchiveModifierOptionsMutation__
 *
 * To run a mutation, you first call `useArchiveModifierOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveModifierOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveModifierOptionsMutation, { data, loading, error }] = useArchiveModifierOptionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveModifierOptionsMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveModifierOptionsMutation, ArchiveModifierOptionsMutationVariables>) {
        return Apollo.useMutation<ArchiveModifierOptionsMutation, ArchiveModifierOptionsMutationVariables>(ArchiveModifierOptionsDocument, baseOptions);
      }
export type ArchiveModifierOptionsMutationHookResult = ReturnType<typeof useArchiveModifierOptionsMutation>;
export type ArchiveModifierOptionsMutationResult = Apollo.MutationResult<ArchiveModifierOptionsMutation>;
export type ArchiveModifierOptionsMutationOptions = Apollo.BaseMutationOptions<ArchiveModifierOptionsMutation, ArchiveModifierOptionsMutationVariables>;
export const ArchiveTopModifierDocument = gql`
    mutation ArchiveTopModifier($id: ID!) {
  archiveModifier(id: $id) {
    ...TopModifier
  }
}
    ${TopModifierFragmentDoc}`;
export type ArchiveTopModifierMutationFn = Apollo.MutationFunction<ArchiveTopModifierMutation, ArchiveTopModifierMutationVariables>;

/**
 * __useArchiveTopModifierMutation__
 *
 * To run a mutation, you first call `useArchiveTopModifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTopModifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTopModifierMutation, { data, loading, error }] = useArchiveTopModifierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveTopModifierMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveTopModifierMutation, ArchiveTopModifierMutationVariables>) {
        return Apollo.useMutation<ArchiveTopModifierMutation, ArchiveTopModifierMutationVariables>(ArchiveTopModifierDocument, baseOptions);
      }
export type ArchiveTopModifierMutationHookResult = ReturnType<typeof useArchiveTopModifierMutation>;
export type ArchiveTopModifierMutationResult = Apollo.MutationResult<ArchiveTopModifierMutation>;
export type ArchiveTopModifierMutationOptions = Apollo.BaseMutationOptions<ArchiveTopModifierMutation, ArchiveTopModifierMutationVariables>;
export const CreateModifierDocument = gql`
    mutation CreateModifier($input: ModifierInput!) {
  createModifier(input: $input) {
    ...TopModifier
  }
}
    ${TopModifierFragmentDoc}`;
export type CreateModifierMutationFn = Apollo.MutationFunction<CreateModifierMutation, CreateModifierMutationVariables>;

/**
 * __useCreateModifierMutation__
 *
 * To run a mutation, you first call `useCreateModifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModifierMutation, { data, loading, error }] = useCreateModifierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModifierMutation(baseOptions?: Apollo.MutationHookOptions<CreateModifierMutation, CreateModifierMutationVariables>) {
        return Apollo.useMutation<CreateModifierMutation, CreateModifierMutationVariables>(CreateModifierDocument, baseOptions);
      }
export type CreateModifierMutationHookResult = ReturnType<typeof useCreateModifierMutation>;
export type CreateModifierMutationResult = Apollo.MutationResult<CreateModifierMutation>;
export type CreateModifierMutationOptions = Apollo.BaseMutationOptions<CreateModifierMutation, CreateModifierMutationVariables>;
export const CreateModifierOptionDocument = gql`
    mutation CreateModifierOption($input: ModifierInput!) {
  createModifier(input: $input) {
    ...ModifierOption
  }
}
    ${ModifierOptionFragmentDoc}`;
export type CreateModifierOptionMutationFn = Apollo.MutationFunction<CreateModifierOptionMutation, CreateModifierOptionMutationVariables>;

/**
 * __useCreateModifierOptionMutation__
 *
 * To run a mutation, you first call `useCreateModifierOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModifierOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModifierOptionMutation, { data, loading, error }] = useCreateModifierOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModifierOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateModifierOptionMutation, CreateModifierOptionMutationVariables>) {
        return Apollo.useMutation<CreateModifierOptionMutation, CreateModifierOptionMutationVariables>(CreateModifierOptionDocument, baseOptions);
      }
export type CreateModifierOptionMutationHookResult = ReturnType<typeof useCreateModifierOptionMutation>;
export type CreateModifierOptionMutationResult = Apollo.MutationResult<CreateModifierOptionMutation>;
export type CreateModifierOptionMutationOptions = Apollo.BaseMutationOptions<CreateModifierOptionMutation, CreateModifierOptionMutationVariables>;
export const FetchModifierOptionDocument = gql`
    query FetchModifierOption($id: ID!) {
  modifier(id: $id) {
    ...ModifierOption
  }
}
    ${ModifierOptionFragmentDoc}`;

/**
 * __useFetchModifierOptionQuery__
 *
 * To run a query within a React component, call `useFetchModifierOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchModifierOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchModifierOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchModifierOptionQuery(baseOptions: Apollo.QueryHookOptions<FetchModifierOptionQuery, FetchModifierOptionQueryVariables>) {
        return Apollo.useQuery<FetchModifierOptionQuery, FetchModifierOptionQueryVariables>(FetchModifierOptionDocument, baseOptions);
      }
export function useFetchModifierOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchModifierOptionQuery, FetchModifierOptionQueryVariables>) {
          return Apollo.useLazyQuery<FetchModifierOptionQuery, FetchModifierOptionQueryVariables>(FetchModifierOptionDocument, baseOptions);
        }
export type FetchModifierOptionQueryHookResult = ReturnType<typeof useFetchModifierOptionQuery>;
export type FetchModifierOptionLazyQueryHookResult = ReturnType<typeof useFetchModifierOptionLazyQuery>;
export type FetchModifierOptionQueryResult = Apollo.QueryResult<FetchModifierOptionQuery, FetchModifierOptionQueryVariables>;
export const FetchTopModifierDocument = gql`
    query FetchTopModifier($id: ID!) {
  modifier(id: $id) {
    ...TopModifier
  }
}
    ${TopModifierFragmentDoc}`;

/**
 * __useFetchTopModifierQuery__
 *
 * To run a query within a React component, call `useFetchTopModifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTopModifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTopModifierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchTopModifierQuery(baseOptions: Apollo.QueryHookOptions<FetchTopModifierQuery, FetchTopModifierQueryVariables>) {
        return Apollo.useQuery<FetchTopModifierQuery, FetchTopModifierQueryVariables>(FetchTopModifierDocument, baseOptions);
      }
export function useFetchTopModifierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchTopModifierQuery, FetchTopModifierQueryVariables>) {
          return Apollo.useLazyQuery<FetchTopModifierQuery, FetchTopModifierQueryVariables>(FetchTopModifierDocument, baseOptions);
        }
export type FetchTopModifierQueryHookResult = ReturnType<typeof useFetchTopModifierQuery>;
export type FetchTopModifierLazyQueryHookResult = ReturnType<typeof useFetchTopModifierLazyQuery>;
export type FetchTopModifierQueryResult = Apollo.QueryResult<FetchTopModifierQuery, FetchTopModifierQueryVariables>;
export const ModifiersWithoutChildrenByMerchantDocument = gql`
    query ModifiersWithoutChildrenByMerchant($merchantId: ID!) {
  modifiersWithoutChildrenByMerchant(merchantId: $merchantId) {
    ...ModifierOption
  }
}
    ${ModifierOptionFragmentDoc}`;

/**
 * __useModifiersWithoutChildrenByMerchantQuery__
 *
 * To run a query within a React component, call `useModifiersWithoutChildrenByMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useModifiersWithoutChildrenByMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModifiersWithoutChildrenByMerchantQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useModifiersWithoutChildrenByMerchantQuery(baseOptions: Apollo.QueryHookOptions<ModifiersWithoutChildrenByMerchantQuery, ModifiersWithoutChildrenByMerchantQueryVariables>) {
        return Apollo.useQuery<ModifiersWithoutChildrenByMerchantQuery, ModifiersWithoutChildrenByMerchantQueryVariables>(ModifiersWithoutChildrenByMerchantDocument, baseOptions);
      }
export function useModifiersWithoutChildrenByMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModifiersWithoutChildrenByMerchantQuery, ModifiersWithoutChildrenByMerchantQueryVariables>) {
          return Apollo.useLazyQuery<ModifiersWithoutChildrenByMerchantQuery, ModifiersWithoutChildrenByMerchantQueryVariables>(ModifiersWithoutChildrenByMerchantDocument, baseOptions);
        }
export type ModifiersWithoutChildrenByMerchantQueryHookResult = ReturnType<typeof useModifiersWithoutChildrenByMerchantQuery>;
export type ModifiersWithoutChildrenByMerchantLazyQueryHookResult = ReturnType<typeof useModifiersWithoutChildrenByMerchantLazyQuery>;
export type ModifiersWithoutChildrenByMerchantQueryResult = Apollo.QueryResult<ModifiersWithoutChildrenByMerchantQuery, ModifiersWithoutChildrenByMerchantQueryVariables>;
export const TopModifiersByMerchantDocument = gql`
    query TopModifiersByMerchant($merchantId: ID!) {
  topModifiersByMerchant(merchantId: $merchantId) {
    ...TopModifier
  }
}
    ${TopModifierFragmentDoc}`;

/**
 * __useTopModifiersByMerchantQuery__
 *
 * To run a query within a React component, call `useTopModifiersByMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopModifiersByMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopModifiersByMerchantQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useTopModifiersByMerchantQuery(baseOptions: Apollo.QueryHookOptions<TopModifiersByMerchantQuery, TopModifiersByMerchantQueryVariables>) {
        return Apollo.useQuery<TopModifiersByMerchantQuery, TopModifiersByMerchantQueryVariables>(TopModifiersByMerchantDocument, baseOptions);
      }
export function useTopModifiersByMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopModifiersByMerchantQuery, TopModifiersByMerchantQueryVariables>) {
          return Apollo.useLazyQuery<TopModifiersByMerchantQuery, TopModifiersByMerchantQueryVariables>(TopModifiersByMerchantDocument, baseOptions);
        }
export type TopModifiersByMerchantQueryHookResult = ReturnType<typeof useTopModifiersByMerchantQuery>;
export type TopModifiersByMerchantLazyQueryHookResult = ReturnType<typeof useTopModifiersByMerchantLazyQuery>;
export type TopModifiersByMerchantQueryResult = Apollo.QueryResult<TopModifiersByMerchantQuery, TopModifiersByMerchantQueryVariables>;
export const UpdateModifierOptionDocument = gql`
    mutation UpdateModifierOption($input: UpdateModifierInput!) {
  updateModifier(input: $input) {
    ...ModifierOption
  }
}
    ${ModifierOptionFragmentDoc}`;
export type UpdateModifierOptionMutationFn = Apollo.MutationFunction<UpdateModifierOptionMutation, UpdateModifierOptionMutationVariables>;

/**
 * __useUpdateModifierOptionMutation__
 *
 * To run a mutation, you first call `useUpdateModifierOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModifierOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModifierOptionMutation, { data, loading, error }] = useUpdateModifierOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModifierOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModifierOptionMutation, UpdateModifierOptionMutationVariables>) {
        return Apollo.useMutation<UpdateModifierOptionMutation, UpdateModifierOptionMutationVariables>(UpdateModifierOptionDocument, baseOptions);
      }
export type UpdateModifierOptionMutationHookResult = ReturnType<typeof useUpdateModifierOptionMutation>;
export type UpdateModifierOptionMutationResult = Apollo.MutationResult<UpdateModifierOptionMutation>;
export type UpdateModifierOptionMutationOptions = Apollo.BaseMutationOptions<UpdateModifierOptionMutation, UpdateModifierOptionMutationVariables>;
export const UpdateTopModifierDocument = gql`
    mutation UpdateTopModifier($input: UpdateModifierInput!) {
  updateModifier(input: $input) {
    ...TopModifier
  }
}
    ${TopModifierFragmentDoc}`;
export type UpdateTopModifierMutationFn = Apollo.MutationFunction<UpdateTopModifierMutation, UpdateTopModifierMutationVariables>;

/**
 * __useUpdateTopModifierMutation__
 *
 * To run a mutation, you first call `useUpdateTopModifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopModifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopModifierMutation, { data, loading, error }] = useUpdateTopModifierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopModifierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopModifierMutation, UpdateTopModifierMutationVariables>) {
        return Apollo.useMutation<UpdateTopModifierMutation, UpdateTopModifierMutationVariables>(UpdateTopModifierDocument, baseOptions);
      }
export type UpdateTopModifierMutationHookResult = ReturnType<typeof useUpdateTopModifierMutation>;
export type UpdateTopModifierMutationResult = Apollo.MutationResult<UpdateTopModifierMutation>;
export type UpdateTopModifierMutationOptions = Apollo.BaseMutationOptions<UpdateTopModifierMutation, UpdateTopModifierMutationVariables>;
export const OrderReportingDetailsByMerchantIdDocument = gql`
    query orderReportingDetailsByMerchantId($id: ID!, $startDate: Instant!, $endDate: Instant!) {
  orderReportingDetailsByMerchantId(
    id: $id
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    date
    userId
    firstName
    lastName
    tokens
    tokenValue
    tokenTipValue
    tokenFeeValue
    subTotal
    taxes
    tip
    customerFee
    merchantFee
    netMerchantPayout
    merchantDiscountFeeUsCents
    rdyPromotionDiscountValue
    rdyPromotionName
    rdyPaidTokenCount
    organizationPaidTokenCount
  }
}
    `;

/**
 * __useOrderReportingDetailsByMerchantIdQuery__
 *
 * To run a query within a React component, call `useOrderReportingDetailsByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderReportingDetailsByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderReportingDetailsByMerchantIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useOrderReportingDetailsByMerchantIdQuery(baseOptions: Apollo.QueryHookOptions<OrderReportingDetailsByMerchantIdQuery, OrderReportingDetailsByMerchantIdQueryVariables>) {
        return Apollo.useQuery<OrderReportingDetailsByMerchantIdQuery, OrderReportingDetailsByMerchantIdQueryVariables>(OrderReportingDetailsByMerchantIdDocument, baseOptions);
      }
export function useOrderReportingDetailsByMerchantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderReportingDetailsByMerchantIdQuery, OrderReportingDetailsByMerchantIdQueryVariables>) {
          return Apollo.useLazyQuery<OrderReportingDetailsByMerchantIdQuery, OrderReportingDetailsByMerchantIdQueryVariables>(OrderReportingDetailsByMerchantIdDocument, baseOptions);
        }
export type OrderReportingDetailsByMerchantIdQueryHookResult = ReturnType<typeof useOrderReportingDetailsByMerchantIdQuery>;
export type OrderReportingDetailsByMerchantIdLazyQueryHookResult = ReturnType<typeof useOrderReportingDetailsByMerchantIdLazyQuery>;
export type OrderReportingDetailsByMerchantIdQueryResult = Apollo.QueryResult<OrderReportingDetailsByMerchantIdQuery, OrderReportingDetailsByMerchantIdQueryVariables>;
export const OrdersByMerchantIdDocument = gql`
    query ordersByMerchantId($id: ID!, $startDate: Instant!, $endDate: Instant!) {
  ordersByMerchantId(id: $id, startDate: $startDate, endDate: $endDate) {
    ...OrderSummary
  }
}
    ${OrderSummaryFragmentDoc}`;

/**
 * __useOrdersByMerchantIdQuery__
 *
 * To run a query within a React component, call `useOrdersByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersByMerchantIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useOrdersByMerchantIdQuery(baseOptions: Apollo.QueryHookOptions<OrdersByMerchantIdQuery, OrdersByMerchantIdQueryVariables>) {
        return Apollo.useQuery<OrdersByMerchantIdQuery, OrdersByMerchantIdQueryVariables>(OrdersByMerchantIdDocument, baseOptions);
      }
export function useOrdersByMerchantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersByMerchantIdQuery, OrdersByMerchantIdQueryVariables>) {
          return Apollo.useLazyQuery<OrdersByMerchantIdQuery, OrdersByMerchantIdQueryVariables>(OrdersByMerchantIdDocument, baseOptions);
        }
export type OrdersByMerchantIdQueryHookResult = ReturnType<typeof useOrdersByMerchantIdQuery>;
export type OrdersByMerchantIdLazyQueryHookResult = ReturnType<typeof useOrdersByMerchantIdLazyQuery>;
export type OrdersByMerchantIdQueryResult = Apollo.QueryResult<OrdersByMerchantIdQuery, OrdersByMerchantIdQueryVariables>;
export const AddOrganizationUserRoleDocument = gql`
    mutation AddOrganizationUserRole($userId: ID!, $organizationId: ID!, $role: Role) {
  addOrganizationUserRole(
    userId: $userId
    organizationId: $organizationId
    role: $role
  )
}
    `;
export type AddOrganizationUserRoleMutationFn = Apollo.MutationFunction<AddOrganizationUserRoleMutation, AddOrganizationUserRoleMutationVariables>;

/**
 * __useAddOrganizationUserRoleMutation__
 *
 * To run a mutation, you first call `useAddOrganizationUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationUserRoleMutation, { data, loading, error }] = useAddOrganizationUserRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddOrganizationUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationUserRoleMutation, AddOrganizationUserRoleMutationVariables>) {
        return Apollo.useMutation<AddOrganizationUserRoleMutation, AddOrganizationUserRoleMutationVariables>(AddOrganizationUserRoleDocument, baseOptions);
      }
export type AddOrganizationUserRoleMutationHookResult = ReturnType<typeof useAddOrganizationUserRoleMutation>;
export type AddOrganizationUserRoleMutationResult = Apollo.MutationResult<AddOrganizationUserRoleMutation>;
export type AddOrganizationUserRoleMutationOptions = Apollo.BaseMutationOptions<AddOrganizationUserRoleMutation, AddOrganizationUserRoleMutationVariables>;
export const ArchiveOrganizationDocument = gql`
    mutation ArchiveOrganization($id: ID!) {
  archiveOrganization(id: $id) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;
export type ArchiveOrganizationMutationFn = Apollo.MutationFunction<ArchiveOrganizationMutation, ArchiveOrganizationMutationVariables>;

/**
 * __useArchiveOrganizationMutation__
 *
 * To run a mutation, you first call `useArchiveOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOrganizationMutation, { data, loading, error }] = useArchiveOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveOrganizationMutation, ArchiveOrganizationMutationVariables>) {
        return Apollo.useMutation<ArchiveOrganizationMutation, ArchiveOrganizationMutationVariables>(ArchiveOrganizationDocument, baseOptions);
      }
export type ArchiveOrganizationMutationHookResult = ReturnType<typeof useArchiveOrganizationMutation>;
export type ArchiveOrganizationMutationResult = Apollo.MutationResult<ArchiveOrganizationMutation>;
export type ArchiveOrganizationMutationOptions = Apollo.BaseMutationOptions<ArchiveOrganizationMutation, ArchiveOrganizationMutationVariables>;
export const OrganizationListDocument = gql`
    query OrganizationList {
  organizations {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

/**
 * __useOrganizationListQuery__
 *
 * To run a query within a React component, call `useOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationListQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationListQuery, OrganizationListQueryVariables>) {
        return Apollo.useQuery<OrganizationListQuery, OrganizationListQueryVariables>(OrganizationListDocument, baseOptions);
      }
export function useOrganizationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationListQuery, OrganizationListQueryVariables>) {
          return Apollo.useLazyQuery<OrganizationListQuery, OrganizationListQueryVariables>(OrganizationListDocument, baseOptions);
        }
export type OrganizationListQueryHookResult = ReturnType<typeof useOrganizationListQuery>;
export type OrganizationListLazyQueryHookResult = ReturnType<typeof useOrganizationListLazyQuery>;
export type OrganizationListQueryResult = Apollo.QueryResult<OrganizationListQuery, OrganizationListQueryVariables>;
export const OrganizationPayoutsDocument = gql`
    query OrganizationPayouts($id: ID!) {
  organizationPayouts(id: $id) {
    ...MerchantPayoutDetails
  }
}
    ${MerchantPayoutDetailsFragmentDoc}`;

/**
 * __useOrganizationPayoutsQuery__
 *
 * To run a query within a React component, call `useOrganizationPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPayoutsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationPayoutsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationPayoutsQuery, OrganizationPayoutsQueryVariables>) {
        return Apollo.useQuery<OrganizationPayoutsQuery, OrganizationPayoutsQueryVariables>(OrganizationPayoutsDocument, baseOptions);
      }
export function useOrganizationPayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationPayoutsQuery, OrganizationPayoutsQueryVariables>) {
          return Apollo.useLazyQuery<OrganizationPayoutsQuery, OrganizationPayoutsQueryVariables>(OrganizationPayoutsDocument, baseOptions);
        }
export type OrganizationPayoutsQueryHookResult = ReturnType<typeof useOrganizationPayoutsQuery>;
export type OrganizationPayoutsLazyQueryHookResult = ReturnType<typeof useOrganizationPayoutsLazyQuery>;
export type OrganizationPayoutsQueryResult = Apollo.QueryResult<OrganizationPayoutsQuery, OrganizationPayoutsQueryVariables>;
export const RemoveOrganizationUserRoleDocument = gql`
    mutation RemoveOrganizationUserRole($userId: ID!, $organizationId: ID!, $role: Role) {
  removeOrganizationUserRole(
    userId: $userId
    organizationId: $organizationId
    role: $role
  )
}
    `;
export type RemoveOrganizationUserRoleMutationFn = Apollo.MutationFunction<RemoveOrganizationUserRoleMutation, RemoveOrganizationUserRoleMutationVariables>;

/**
 * __useRemoveOrganizationUserRoleMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationUserRoleMutation, { data, loading, error }] = useRemoveOrganizationUserRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRemoveOrganizationUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganizationUserRoleMutation, RemoveOrganizationUserRoleMutationVariables>) {
        return Apollo.useMutation<RemoveOrganizationUserRoleMutation, RemoveOrganizationUserRoleMutationVariables>(RemoveOrganizationUserRoleDocument, baseOptions);
      }
export type RemoveOrganizationUserRoleMutationHookResult = ReturnType<typeof useRemoveOrganizationUserRoleMutation>;
export type RemoveOrganizationUserRoleMutationResult = Apollo.MutationResult<RemoveOrganizationUserRoleMutation>;
export type RemoveOrganizationUserRoleMutationOptions = Apollo.BaseMutationOptions<RemoveOrganizationUserRoleMutation, RemoveOrganizationUserRoleMutationVariables>;
export const ArchiveMerchantPaymentAccountDocument = gql`
    mutation ArchiveMerchantPaymentAccount($id: ID!) {
  archiveMerchantPaymentAccount(id: $id) {
    id
    organization {
      id
    }
  }
}
    `;
export type ArchiveMerchantPaymentAccountMutationFn = Apollo.MutationFunction<ArchiveMerchantPaymentAccountMutation, ArchiveMerchantPaymentAccountMutationVariables>;

/**
 * __useArchiveMerchantPaymentAccountMutation__
 *
 * To run a mutation, you first call `useArchiveMerchantPaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMerchantPaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMerchantPaymentAccountMutation, { data, loading, error }] = useArchiveMerchantPaymentAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveMerchantPaymentAccountMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveMerchantPaymentAccountMutation, ArchiveMerchantPaymentAccountMutationVariables>) {
        return Apollo.useMutation<ArchiveMerchantPaymentAccountMutation, ArchiveMerchantPaymentAccountMutationVariables>(ArchiveMerchantPaymentAccountDocument, baseOptions);
      }
export type ArchiveMerchantPaymentAccountMutationHookResult = ReturnType<typeof useArchiveMerchantPaymentAccountMutation>;
export type ArchiveMerchantPaymentAccountMutationResult = Apollo.MutationResult<ArchiveMerchantPaymentAccountMutation>;
export type ArchiveMerchantPaymentAccountMutationOptions = Apollo.BaseMutationOptions<ArchiveMerchantPaymentAccountMutation, ArchiveMerchantPaymentAccountMutationVariables>;
export const ArchiveOrganizationPaymentAccountDocument = gql`
    mutation ArchiveOrganizationPaymentAccount($id: ID!) {
  archiveOrganizationPaymentAccount(id: $id) {
    id
  }
}
    `;
export type ArchiveOrganizationPaymentAccountMutationFn = Apollo.MutationFunction<ArchiveOrganizationPaymentAccountMutation, ArchiveOrganizationPaymentAccountMutationVariables>;

/**
 * __useArchiveOrganizationPaymentAccountMutation__
 *
 * To run a mutation, you first call `useArchiveOrganizationPaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOrganizationPaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOrganizationPaymentAccountMutation, { data, loading, error }] = useArchiveOrganizationPaymentAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveOrganizationPaymentAccountMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveOrganizationPaymentAccountMutation, ArchiveOrganizationPaymentAccountMutationVariables>) {
        return Apollo.useMutation<ArchiveOrganizationPaymentAccountMutation, ArchiveOrganizationPaymentAccountMutationVariables>(ArchiveOrganizationPaymentAccountDocument, baseOptions);
      }
export type ArchiveOrganizationPaymentAccountMutationHookResult = ReturnType<typeof useArchiveOrganizationPaymentAccountMutation>;
export type ArchiveOrganizationPaymentAccountMutationResult = Apollo.MutationResult<ArchiveOrganizationPaymentAccountMutation>;
export type ArchiveOrganizationPaymentAccountMutationOptions = Apollo.BaseMutationOptions<ArchiveOrganizationPaymentAccountMutation, ArchiveOrganizationPaymentAccountMutationVariables>;
export const SetupPaymentAccountDocument = gql`
    mutation setupPaymentAccount($organizationId: ID!, $merchantId: ID) {
  setupPaymentAccount(organizationId: $organizationId, merchantId: $merchantId)
}
    `;
export type SetupPaymentAccountMutationFn = Apollo.MutationFunction<SetupPaymentAccountMutation, SetupPaymentAccountMutationVariables>;

/**
 * __useSetupPaymentAccountMutation__
 *
 * To run a mutation, you first call `useSetupPaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupPaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupPaymentAccountMutation, { data, loading, error }] = useSetupPaymentAccountMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useSetupPaymentAccountMutation(baseOptions?: Apollo.MutationHookOptions<SetupPaymentAccountMutation, SetupPaymentAccountMutationVariables>) {
        return Apollo.useMutation<SetupPaymentAccountMutation, SetupPaymentAccountMutationVariables>(SetupPaymentAccountDocument, baseOptions);
      }
export type SetupPaymentAccountMutationHookResult = ReturnType<typeof useSetupPaymentAccountMutation>;
export type SetupPaymentAccountMutationResult = Apollo.MutationResult<SetupPaymentAccountMutation>;
export type SetupPaymentAccountMutationOptions = Apollo.BaseMutationOptions<SetupPaymentAccountMutation, SetupPaymentAccountMutationVariables>;
export const ValidatePaymentAccountDocument = gql`
    query ValidatePaymentAccount($organizationId: ID!, $merchantId: ID) {
  validatePaymentAccount(organizationId: $organizationId, merchantId: $merchantId) {
    hasAccount
    chargesEnabled
  }
}
    `;

/**
 * __useValidatePaymentAccountQuery__
 *
 * To run a query within a React component, call `useValidatePaymentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePaymentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePaymentAccountQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useValidatePaymentAccountQuery(baseOptions: Apollo.QueryHookOptions<ValidatePaymentAccountQuery, ValidatePaymentAccountQueryVariables>) {
        return Apollo.useQuery<ValidatePaymentAccountQuery, ValidatePaymentAccountQueryVariables>(ValidatePaymentAccountDocument, baseOptions);
      }
export function useValidatePaymentAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidatePaymentAccountQuery, ValidatePaymentAccountQueryVariables>) {
          return Apollo.useLazyQuery<ValidatePaymentAccountQuery, ValidatePaymentAccountQueryVariables>(ValidatePaymentAccountDocument, baseOptions);
        }
export type ValidatePaymentAccountQueryHookResult = ReturnType<typeof useValidatePaymentAccountQuery>;
export type ValidatePaymentAccountLazyQueryHookResult = ReturnType<typeof useValidatePaymentAccountLazyQuery>;
export type ValidatePaymentAccountQueryResult = Apollo.QueryResult<ValidatePaymentAccountQuery, ValidatePaymentAccountQueryVariables>;
export const ArchiveProductDocument = gql`
    mutation ArchiveProduct($id: ID!) {
  archiveProduct(id: $id) {
    ...ProductDetails
  }
}
    ${ProductDetailsFragmentDoc}`;
export type ArchiveProductMutationFn = Apollo.MutationFunction<ArchiveProductMutation, ArchiveProductMutationVariables>;

/**
 * __useArchiveProductMutation__
 *
 * To run a mutation, you first call `useArchiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProductMutation, { data, loading, error }] = useArchiveProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveProductMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProductMutation, ArchiveProductMutationVariables>) {
        return Apollo.useMutation<ArchiveProductMutation, ArchiveProductMutationVariables>(ArchiveProductDocument, baseOptions);
      }
export type ArchiveProductMutationHookResult = ReturnType<typeof useArchiveProductMutation>;
export type ArchiveProductMutationResult = Apollo.MutationResult<ArchiveProductMutation>;
export type ArchiveProductMutationOptions = Apollo.BaseMutationOptions<ArchiveProductMutation, ArchiveProductMutationVariables>;
export const ArchiveProductsDocument = gql`
    mutation ArchiveProducts($ids: [ID!]!) {
  archiveProducts(ids: $ids) {
    ...ProductDetails
  }
}
    ${ProductDetailsFragmentDoc}`;
export type ArchiveProductsMutationFn = Apollo.MutationFunction<ArchiveProductsMutation, ArchiveProductsMutationVariables>;

/**
 * __useArchiveProductsMutation__
 *
 * To run a mutation, you first call `useArchiveProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProductsMutation, { data, loading, error }] = useArchiveProductsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveProductsMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProductsMutation, ArchiveProductsMutationVariables>) {
        return Apollo.useMutation<ArchiveProductsMutation, ArchiveProductsMutationVariables>(ArchiveProductsDocument, baseOptions);
      }
export type ArchiveProductsMutationHookResult = ReturnType<typeof useArchiveProductsMutation>;
export type ArchiveProductsMutationResult = Apollo.MutationResult<ArchiveProductsMutation>;
export type ArchiveProductsMutationOptions = Apollo.BaseMutationOptions<ArchiveProductsMutation, ArchiveProductsMutationVariables>;
export const ProductDetailsDocument = gql`
    query ProductDetails($id: ID!) {
  product(id: $id) {
    ...ProductDetails
  }
}
    ${ProductDetailsFragmentDoc}`;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDetailsQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
        return Apollo.useQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, baseOptions);
      }
export function useProductDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
          return Apollo.useLazyQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, baseOptions);
        }
export type ProductDetailsQueryHookResult = ReturnType<typeof useProductDetailsQuery>;
export type ProductDetailsLazyQueryHookResult = ReturnType<typeof useProductDetailsLazyQuery>;
export type ProductDetailsQueryResult = Apollo.QueryResult<ProductDetailsQuery, ProductDetailsQueryVariables>;
export const UploadImageDocument = gql`
    mutation UploadImage($file: Upload!) {
  uploadImage(file: $file)
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, baseOptions);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UploadImageByUrlDocument = gql`
    mutation UploadImageByUrl($url: String!) {
  uploadImageByUrl(url: $url)
}
    `;
export type UploadImageByUrlMutationFn = Apollo.MutationFunction<UploadImageByUrlMutation, UploadImageByUrlMutationVariables>;

/**
 * __useUploadImageByUrlMutation__
 *
 * To run a mutation, you first call `useUploadImageByUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageByUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageByUrlMutation, { data, loading, error }] = useUploadImageByUrlMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUploadImageByUrlMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageByUrlMutation, UploadImageByUrlMutationVariables>) {
        return Apollo.useMutation<UploadImageByUrlMutation, UploadImageByUrlMutationVariables>(UploadImageByUrlDocument, baseOptions);
      }
export type UploadImageByUrlMutationHookResult = ReturnType<typeof useUploadImageByUrlMutation>;
export type UploadImageByUrlMutationResult = Apollo.MutationResult<UploadImageByUrlMutation>;
export type UploadImageByUrlMutationOptions = Apollo.BaseMutationOptions<UploadImageByUrlMutation, UploadImageByUrlMutationVariables>;
export const ArchiveMerchantPromotionDocument = gql`
    mutation ArchiveMerchantPromotion($id: ID!) {
  archivePromotion(id: $id) {
    id
    customerFacingName
    archived
  }
}
    `;
export type ArchiveMerchantPromotionMutationFn = Apollo.MutationFunction<ArchiveMerchantPromotionMutation, ArchiveMerchantPromotionMutationVariables>;

/**
 * __useArchiveMerchantPromotionMutation__
 *
 * To run a mutation, you first call `useArchiveMerchantPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMerchantPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMerchantPromotionMutation, { data, loading, error }] = useArchiveMerchantPromotionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveMerchantPromotionMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveMerchantPromotionMutation, ArchiveMerchantPromotionMutationVariables>) {
        return Apollo.useMutation<ArchiveMerchantPromotionMutation, ArchiveMerchantPromotionMutationVariables>(ArchiveMerchantPromotionDocument, baseOptions);
      }
export type ArchiveMerchantPromotionMutationHookResult = ReturnType<typeof useArchiveMerchantPromotionMutation>;
export type ArchiveMerchantPromotionMutationResult = Apollo.MutationResult<ArchiveMerchantPromotionMutation>;
export type ArchiveMerchantPromotionMutationOptions = Apollo.BaseMutationOptions<ArchiveMerchantPromotionMutation, ArchiveMerchantPromotionMutationVariables>;
export const ArchivePromotionDocument = gql`
    mutation ArchivePromotion($id: ID!) {
  archivePromotion(id: $id) {
    ...PromotionInfo
  }
}
    ${PromotionInfoFragmentDoc}`;
export type ArchivePromotionMutationFn = Apollo.MutationFunction<ArchivePromotionMutation, ArchivePromotionMutationVariables>;

/**
 * __useArchivePromotionMutation__
 *
 * To run a mutation, you first call `useArchivePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePromotionMutation, { data, loading, error }] = useArchivePromotionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchivePromotionMutation(baseOptions?: Apollo.MutationHookOptions<ArchivePromotionMutation, ArchivePromotionMutationVariables>) {
        return Apollo.useMutation<ArchivePromotionMutation, ArchivePromotionMutationVariables>(ArchivePromotionDocument, baseOptions);
      }
export type ArchivePromotionMutationHookResult = ReturnType<typeof useArchivePromotionMutation>;
export type ArchivePromotionMutationResult = Apollo.MutationResult<ArchivePromotionMutation>;
export type ArchivePromotionMutationOptions = Apollo.BaseMutationOptions<ArchivePromotionMutation, ArchivePromotionMutationVariables>;
export const LeadReceivedDocument = gql`
    mutation leadReceived($input: CafeLeadInput!) {
  leadReceived(input: $input)
}
    `;
export type LeadReceivedMutationFn = Apollo.MutationFunction<LeadReceivedMutation, LeadReceivedMutationVariables>;

/**
 * __useLeadReceivedMutation__
 *
 * To run a mutation, you first call `useLeadReceivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeadReceivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leadReceivedMutation, { data, loading, error }] = useLeadReceivedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeadReceivedMutation(baseOptions?: Apollo.MutationHookOptions<LeadReceivedMutation, LeadReceivedMutationVariables>) {
        return Apollo.useMutation<LeadReceivedMutation, LeadReceivedMutationVariables>(LeadReceivedDocument, baseOptions);
      }
export type LeadReceivedMutationHookResult = ReturnType<typeof useLeadReceivedMutation>;
export type LeadReceivedMutationResult = Apollo.MutationResult<LeadReceivedMutation>;
export type LeadReceivedMutationOptions = Apollo.BaseMutationOptions<LeadReceivedMutation, LeadReceivedMutationVariables>;
export const CafeValidationQueryDocument = gql`
    query CafeValidationQuery($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    taxRate
    kdsIntegrations {
      id
      type
      locationId
      displayName
      active
    }
    merchantContacts {
      ...MerchantContactDetails
    }
    kdss {
      id
      uuid
      authorizedBy {
        id
        profile {
          id
          contactEmail
        }
      }
      active
    }
    products {
      id
      name
      active
      category {
        id
        name
      }
    }
  }
}
    ${MerchantContactDetailsFragmentDoc}`;

/**
 * __useCafeValidationQueryQuery__
 *
 * To run a query within a React component, call `useCafeValidationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCafeValidationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCafeValidationQueryQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useCafeValidationQueryQuery(baseOptions: Apollo.QueryHookOptions<CafeValidationQueryQuery, CafeValidationQueryQueryVariables>) {
        return Apollo.useQuery<CafeValidationQueryQuery, CafeValidationQueryQueryVariables>(CafeValidationQueryDocument, baseOptions);
      }
export function useCafeValidationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CafeValidationQueryQuery, CafeValidationQueryQueryVariables>) {
          return Apollo.useLazyQuery<CafeValidationQueryQuery, CafeValidationQueryQueryVariables>(CafeValidationQueryDocument, baseOptions);
        }
export type CafeValidationQueryQueryHookResult = ReturnType<typeof useCafeValidationQueryQuery>;
export type CafeValidationQueryLazyQueryHookResult = ReturnType<typeof useCafeValidationQueryLazyQuery>;
export type CafeValidationQueryQueryResult = Apollo.QueryResult<CafeValidationQueryQuery, CafeValidationQueryQueryVariables>;
export const UpdateMerchantActiveDocument = gql`
    mutation UpdateMerchantActive($id: ID!, $active: Boolean!) {
  updateMerchantActive(id: $id, active: $active) {
    id
    active
  }
}
    `;
export type UpdateMerchantActiveMutationFn = Apollo.MutationFunction<UpdateMerchantActiveMutation, UpdateMerchantActiveMutationVariables>;

/**
 * __useUpdateMerchantActiveMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantActiveMutation, { data, loading, error }] = useUpdateMerchantActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateMerchantActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantActiveMutation, UpdateMerchantActiveMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantActiveMutation, UpdateMerchantActiveMutationVariables>(UpdateMerchantActiveDocument, baseOptions);
      }
export type UpdateMerchantActiveMutationHookResult = ReturnType<typeof useUpdateMerchantActiveMutation>;
export type UpdateMerchantActiveMutationResult = Apollo.MutationResult<UpdateMerchantActiveMutation>;
export type UpdateMerchantActiveMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantActiveMutation, UpdateMerchantActiveMutationVariables>;
export const CreateMerchantFeeConfigDocument = gql`
    mutation CreateMerchantFeeConfig($input: CreateMerchantFeeConfigInput!) {
  createMerchantFeeConfig(input: $input) {
    id
    default
    staticFeeInUsCents
    percentageFee
  }
}
    `;
export type CreateMerchantFeeConfigMutationFn = Apollo.MutationFunction<CreateMerchantFeeConfigMutation, CreateMerchantFeeConfigMutationVariables>;

/**
 * __useCreateMerchantFeeConfigMutation__
 *
 * To run a mutation, you first call `useCreateMerchantFeeConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantFeeConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantFeeConfigMutation, { data, loading, error }] = useCreateMerchantFeeConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMerchantFeeConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchantFeeConfigMutation, CreateMerchantFeeConfigMutationVariables>) {
        return Apollo.useMutation<CreateMerchantFeeConfigMutation, CreateMerchantFeeConfigMutationVariables>(CreateMerchantFeeConfigDocument, baseOptions);
      }
export type CreateMerchantFeeConfigMutationHookResult = ReturnType<typeof useCreateMerchantFeeConfigMutation>;
export type CreateMerchantFeeConfigMutationResult = Apollo.MutationResult<CreateMerchantFeeConfigMutation>;
export type CreateMerchantFeeConfigMutationOptions = Apollo.BaseMutationOptions<CreateMerchantFeeConfigMutation, CreateMerchantFeeConfigMutationVariables>;
export const CreateMerchantPromotionDocument = gql`
    mutation CreateMerchantPromotion($input: PromotionInput!) {
  createMerchantPromotion(input: $input) {
    ...MerchantPromotionInfo
  }
}
    ${MerchantPromotionInfoFragmentDoc}`;
export type CreateMerchantPromotionMutationFn = Apollo.MutationFunction<CreateMerchantPromotionMutation, CreateMerchantPromotionMutationVariables>;

/**
 * __useCreateMerchantPromotionMutation__
 *
 * To run a mutation, you first call `useCreateMerchantPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantPromotionMutation, { data, loading, error }] = useCreateMerchantPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMerchantPromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchantPromotionMutation, CreateMerchantPromotionMutationVariables>) {
        return Apollo.useMutation<CreateMerchantPromotionMutation, CreateMerchantPromotionMutationVariables>(CreateMerchantPromotionDocument, baseOptions);
      }
export type CreateMerchantPromotionMutationHookResult = ReturnType<typeof useCreateMerchantPromotionMutation>;
export type CreateMerchantPromotionMutationResult = Apollo.MutationResult<CreateMerchantPromotionMutation>;
export type CreateMerchantPromotionMutationOptions = Apollo.BaseMutationOptions<CreateMerchantPromotionMutation, CreateMerchantPromotionMutationVariables>;
export const CreatePromoCodeDocument = gql`
    mutation CreatePromoCode($input: PromoCodeInput!) {
  createPromoCode(input: $input) {
    ...PromoCodeInfo
  }
}
    ${PromoCodeInfoFragmentDoc}`;
export type CreatePromoCodeMutationFn = Apollo.MutationFunction<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;

/**
 * __useCreatePromoCodeMutation__
 *
 * To run a mutation, you first call `useCreatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoCodeMutation, { data, loading, error }] = useCreatePromoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>) {
        return Apollo.useMutation<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>(CreatePromoCodeDocument, baseOptions);
      }
export type CreatePromoCodeMutationHookResult = ReturnType<typeof useCreatePromoCodeMutation>;
export type CreatePromoCodeMutationResult = Apollo.MutationResult<CreatePromoCodeMutation>;
export type CreatePromoCodeMutationOptions = Apollo.BaseMutationOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;
export const CreatePromotionDocument = gql`
    mutation CreatePromotion($input: PromotionInput!) {
  createPromotion(input: $input) {
    ...PromotionInfo
  }
}
    ${PromotionInfoFragmentDoc}`;
export type CreatePromotionMutationFn = Apollo.MutationFunction<CreatePromotionMutation, CreatePromotionMutationVariables>;

/**
 * __useCreatePromotionMutation__
 *
 * To run a mutation, you first call `useCreatePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromotionMutation, { data, loading, error }] = useCreatePromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromotionMutation, CreatePromotionMutationVariables>) {
        return Apollo.useMutation<CreatePromotionMutation, CreatePromotionMutationVariables>(CreatePromotionDocument, baseOptions);
      }
export type CreatePromotionMutationHookResult = ReturnType<typeof useCreatePromotionMutation>;
export type CreatePromotionMutationResult = Apollo.MutationResult<CreatePromotionMutation>;
export type CreatePromotionMutationOptions = Apollo.BaseMutationOptions<CreatePromotionMutation, CreatePromotionMutationVariables>;
export const CreateSubscriptionFeeDocument = gql`
    mutation CreateSubscriptionFee($input: CreateSubscriptionFeeInput!) {
  createSubscriptionFee(input: $input) {
    id
    costUsCents
    name
  }
}
    `;
export type CreateSubscriptionFeeMutationFn = Apollo.MutationFunction<CreateSubscriptionFeeMutation, CreateSubscriptionFeeMutationVariables>;

/**
 * __useCreateSubscriptionFeeMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionFeeMutation, { data, loading, error }] = useCreateSubscriptionFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionFeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionFeeMutation, CreateSubscriptionFeeMutationVariables>) {
        return Apollo.useMutation<CreateSubscriptionFeeMutation, CreateSubscriptionFeeMutationVariables>(CreateSubscriptionFeeDocument, baseOptions);
      }
export type CreateSubscriptionFeeMutationHookResult = ReturnType<typeof useCreateSubscriptionFeeMutation>;
export type CreateSubscriptionFeeMutationResult = Apollo.MutationResult<CreateSubscriptionFeeMutation>;
export type CreateSubscriptionFeeMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionFeeMutation, CreateSubscriptionFeeMutationVariables>;
export const CreateSubscriptionPlanDocument = gql`
    mutation CreateSubscriptionPlan($input: CreateSubscriptionPlanInput!) {
  createSubscriptionPlan(input: $input) {
    id
    costUsCents
    name
  }
}
    `;
export type CreateSubscriptionPlanMutationFn = Apollo.MutationFunction<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>;

/**
 * __useCreateSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionPlanMutation, { data, loading, error }] = useCreateSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>) {
        return Apollo.useMutation<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>(CreateSubscriptionPlanDocument, baseOptions);
      }
export type CreateSubscriptionPlanMutationHookResult = ReturnType<typeof useCreateSubscriptionPlanMutation>;
export type CreateSubscriptionPlanMutationResult = Apollo.MutationResult<CreateSubscriptionPlanMutation>;
export type CreateSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>;
export const UserRolesDocument = gql`
    query UserRoles {
  me {
    id
    roles
  }
}
    `;

/**
 * __useUserRolesQuery__
 *
 * To run a query within a React component, call `useUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
        return Apollo.useQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, baseOptions);
      }
export function useUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
          return Apollo.useLazyQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, baseOptions);
        }
export type UserRolesQueryHookResult = ReturnType<typeof useUserRolesQuery>;
export type UserRolesLazyQueryHookResult = ReturnType<typeof useUserRolesLazyQuery>;
export type UserRolesQueryResult = Apollo.QueryResult<UserRolesQuery, UserRolesQueryVariables>;
export const PromotionsDocument = gql`
    query Promotions {
  promotions {
    ...PromotionTopInfo
  }
}
    ${PromotionTopInfoFragmentDoc}`;

/**
 * __usePromotionsQuery__
 *
 * To run a query within a React component, call `usePromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromotionsQuery(baseOptions?: Apollo.QueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
        return Apollo.useQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, baseOptions);
      }
export function usePromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
          return Apollo.useLazyQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, baseOptions);
        }
export type PromotionsQueryHookResult = ReturnType<typeof usePromotionsQuery>;
export type PromotionsLazyQueryHookResult = ReturnType<typeof usePromotionsLazyQuery>;
export type PromotionsQueryResult = Apollo.QueryResult<PromotionsQuery, PromotionsQueryVariables>;
export const MerchantFeeConfigsDocument = gql`
    query merchantFeeConfigs {
  merchantFeeConfigs {
    id
    default
    staticFeeInUsCents
    percentageFee
  }
}
    `;

/**
 * __useMerchantFeeConfigsQuery__
 *
 * To run a query within a React component, call `useMerchantFeeConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantFeeConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantFeeConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMerchantFeeConfigsQuery(baseOptions?: Apollo.QueryHookOptions<MerchantFeeConfigsQuery, MerchantFeeConfigsQueryVariables>) {
        return Apollo.useQuery<MerchantFeeConfigsQuery, MerchantFeeConfigsQueryVariables>(MerchantFeeConfigsDocument, baseOptions);
      }
export function useMerchantFeeConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantFeeConfigsQuery, MerchantFeeConfigsQueryVariables>) {
          return Apollo.useLazyQuery<MerchantFeeConfigsQuery, MerchantFeeConfigsQueryVariables>(MerchantFeeConfigsDocument, baseOptions);
        }
export type MerchantFeeConfigsQueryHookResult = ReturnType<typeof useMerchantFeeConfigsQuery>;
export type MerchantFeeConfigsLazyQueryHookResult = ReturnType<typeof useMerchantFeeConfigsLazyQuery>;
export type MerchantFeeConfigsQueryResult = Apollo.QueryResult<MerchantFeeConfigsQuery, MerchantFeeConfigsQueryVariables>;
export const SetDefaultMerchantFeeConfigDocument = gql`
    mutation SetDefaultMerchantFeeConfig($merchantFeeConfigId: ID!) {
  setDefaultMerchantFeeConfig(merchantFeeConfigId: $merchantFeeConfigId) {
    id
    default
    staticFeeInUsCents
    percentageFee
  }
}
    `;
export type SetDefaultMerchantFeeConfigMutationFn = Apollo.MutationFunction<SetDefaultMerchantFeeConfigMutation, SetDefaultMerchantFeeConfigMutationVariables>;

/**
 * __useSetDefaultMerchantFeeConfigMutation__
 *
 * To run a mutation, you first call `useSetDefaultMerchantFeeConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultMerchantFeeConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultMerchantFeeConfigMutation, { data, loading, error }] = useSetDefaultMerchantFeeConfigMutation({
 *   variables: {
 *      merchantFeeConfigId: // value for 'merchantFeeConfigId'
 *   },
 * });
 */
export function useSetDefaultMerchantFeeConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultMerchantFeeConfigMutation, SetDefaultMerchantFeeConfigMutationVariables>) {
        return Apollo.useMutation<SetDefaultMerchantFeeConfigMutation, SetDefaultMerchantFeeConfigMutationVariables>(SetDefaultMerchantFeeConfigDocument, baseOptions);
      }
export type SetDefaultMerchantFeeConfigMutationHookResult = ReturnType<typeof useSetDefaultMerchantFeeConfigMutation>;
export type SetDefaultMerchantFeeConfigMutationResult = Apollo.MutationResult<SetDefaultMerchantFeeConfigMutation>;
export type SetDefaultMerchantFeeConfigMutationOptions = Apollo.BaseMutationOptions<SetDefaultMerchantFeeConfigMutation, SetDefaultMerchantFeeConfigMutationVariables>;
export const AdjudicatePromotionDocument = gql`
    mutation AdjudicatePromotion($id: ID!, $status: PromotionStatus!, $reason: String) {
  adjudicatePromotion(id: $id, status: $status, reason: $reason) {
    id
    status
  }
}
    `;
export type AdjudicatePromotionMutationFn = Apollo.MutationFunction<AdjudicatePromotionMutation, AdjudicatePromotionMutationVariables>;

/**
 * __useAdjudicatePromotionMutation__
 *
 * To run a mutation, you first call `useAdjudicatePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdjudicatePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adjudicatePromotionMutation, { data, loading, error }] = useAdjudicatePromotionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useAdjudicatePromotionMutation(baseOptions?: Apollo.MutationHookOptions<AdjudicatePromotionMutation, AdjudicatePromotionMutationVariables>) {
        return Apollo.useMutation<AdjudicatePromotionMutation, AdjudicatePromotionMutationVariables>(AdjudicatePromotionDocument, baseOptions);
      }
export type AdjudicatePromotionMutationHookResult = ReturnType<typeof useAdjudicatePromotionMutation>;
export type AdjudicatePromotionMutationResult = Apollo.MutationResult<AdjudicatePromotionMutation>;
export type AdjudicatePromotionMutationOptions = Apollo.BaseMutationOptions<AdjudicatePromotionMutation, AdjudicatePromotionMutationVariables>;
export const PromotionDocument = gql`
    query Promotion($id: ID!) {
  promotion(id: $id) {
    ...PromotionInfo
  }
}
    ${PromotionInfoFragmentDoc}`;

/**
 * __usePromotionQuery__
 *
 * To run a query within a React component, call `usePromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePromotionQuery(baseOptions: Apollo.QueryHookOptions<PromotionQuery, PromotionQueryVariables>) {
        return Apollo.useQuery<PromotionQuery, PromotionQueryVariables>(PromotionDocument, baseOptions);
      }
export function usePromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionQuery, PromotionQueryVariables>) {
          return Apollo.useLazyQuery<PromotionQuery, PromotionQueryVariables>(PromotionDocument, baseOptions);
        }
export type PromotionQueryHookResult = ReturnType<typeof usePromotionQuery>;
export type PromotionLazyQueryHookResult = ReturnType<typeof usePromotionLazyQuery>;
export type PromotionQueryResult = Apollo.QueryResult<PromotionQuery, PromotionQueryVariables>;
export const UpdatePromotionCodeDocument = gql`
    mutation UpdatePromotionCode($input: PromotionCodeUpdateInput!) {
  updatePromotionCode(input: $input) {
    ...PromoCodeInfo
  }
}
    ${PromoCodeInfoFragmentDoc}`;
export type UpdatePromotionCodeMutationFn = Apollo.MutationFunction<UpdatePromotionCodeMutation, UpdatePromotionCodeMutationVariables>;

/**
 * __useUpdatePromotionCodeMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionCodeMutation, { data, loading, error }] = useUpdatePromotionCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromotionCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromotionCodeMutation, UpdatePromotionCodeMutationVariables>) {
        return Apollo.useMutation<UpdatePromotionCodeMutation, UpdatePromotionCodeMutationVariables>(UpdatePromotionCodeDocument, baseOptions);
      }
export type UpdatePromotionCodeMutationHookResult = ReturnType<typeof useUpdatePromotionCodeMutation>;
export type UpdatePromotionCodeMutationResult = Apollo.MutationResult<UpdatePromotionCodeMutation>;
export type UpdatePromotionCodeMutationOptions = Apollo.BaseMutationOptions<UpdatePromotionCodeMutation, UpdatePromotionCodeMutationVariables>;
export const UpdatePromotionDocument = gql`
    mutation UpdatePromotion($input: PromotionUpdateInput!) {
  updatePromotion(input: $input) {
    ...PromotionInfo
  }
}
    ${PromotionInfoFragmentDoc}`;
export type UpdatePromotionMutationFn = Apollo.MutationFunction<UpdatePromotionMutation, UpdatePromotionMutationVariables>;

/**
 * __useUpdatePromotionMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionMutation, { data, loading, error }] = useUpdatePromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromotionMutation, UpdatePromotionMutationVariables>) {
        return Apollo.useMutation<UpdatePromotionMutation, UpdatePromotionMutationVariables>(UpdatePromotionDocument, baseOptions);
      }
export type UpdatePromotionMutationHookResult = ReturnType<typeof useUpdatePromotionMutation>;
export type UpdatePromotionMutationResult = Apollo.MutationResult<UpdatePromotionMutation>;
export type UpdatePromotionMutationOptions = Apollo.BaseMutationOptions<UpdatePromotionMutation, UpdatePromotionMutationVariables>;
export const MarketsDocument = gql`
    query Markets {
  markets {
    id
    name
  }
}
    `;

/**
 * __useMarketsQuery__
 *
 * To run a query within a React component, call `useMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketsQuery(baseOptions?: Apollo.QueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
        return Apollo.useQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, baseOptions);
      }
export function useMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
          return Apollo.useLazyQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, baseOptions);
        }
export type MarketsQueryHookResult = ReturnType<typeof useMarketsQuery>;
export type MarketsLazyQueryHookResult = ReturnType<typeof useMarketsLazyQuery>;
export type MarketsQueryResult = Apollo.QueryResult<MarketsQuery, MarketsQueryVariables>;
export const SendPushToMarketDocument = gql`
    mutation SendPushToMarket($input: MarketPushInput!) {
  sendPushToMarket(input: $input)
}
    `;
export type SendPushToMarketMutationFn = Apollo.MutationFunction<SendPushToMarketMutation, SendPushToMarketMutationVariables>;

/**
 * __useSendPushToMarketMutation__
 *
 * To run a mutation, you first call `useSendPushToMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushToMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushToMarketMutation, { data, loading, error }] = useSendPushToMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPushToMarketMutation(baseOptions?: Apollo.MutationHookOptions<SendPushToMarketMutation, SendPushToMarketMutationVariables>) {
        return Apollo.useMutation<SendPushToMarketMutation, SendPushToMarketMutationVariables>(SendPushToMarketDocument, baseOptions);
      }
export type SendPushToMarketMutationHookResult = ReturnType<typeof useSendPushToMarketMutation>;
export type SendPushToMarketMutationResult = Apollo.MutationResult<SendPushToMarketMutation>;
export type SendPushToMarketMutationOptions = Apollo.BaseMutationOptions<SendPushToMarketMutation, SendPushToMarketMutationVariables>;
export const SendPushToUsersDocument = gql`
    mutation SendPushToUsers($input: UserPushInput!) {
  sendPushToUsers(input: $input)
}
    `;
export type SendPushToUsersMutationFn = Apollo.MutationFunction<SendPushToUsersMutation, SendPushToUsersMutationVariables>;

/**
 * __useSendPushToUsersMutation__
 *
 * To run a mutation, you first call `useSendPushToUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushToUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushToUsersMutation, { data, loading, error }] = useSendPushToUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPushToUsersMutation(baseOptions?: Apollo.MutationHookOptions<SendPushToUsersMutation, SendPushToUsersMutationVariables>) {
        return Apollo.useMutation<SendPushToUsersMutation, SendPushToUsersMutationVariables>(SendPushToUsersDocument, baseOptions);
      }
export type SendPushToUsersMutationHookResult = ReturnType<typeof useSendPushToUsersMutation>;
export type SendPushToUsersMutationResult = Apollo.MutationResult<SendPushToUsersMutation>;
export type SendPushToUsersMutationOptions = Apollo.BaseMutationOptions<SendPushToUsersMutation, SendPushToUsersMutationVariables>;
export const ConfirmedRequisitionsDocument = gql`
    query ConfirmedRequisitions($year: Int!, $month: Int!) {
  confirmedRequisitions(year: $year, month: $month) {
    id
    firstName
    lastName
    fulfillmentLocation {
      id
      street
      street2
      city
      state
      zipcode
    }
  }
}
    `;

/**
 * __useConfirmedRequisitionsQuery__
 *
 * To run a query within a React component, call `useConfirmedRequisitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmedRequisitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmedRequisitionsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useConfirmedRequisitionsQuery(baseOptions: Apollo.QueryHookOptions<ConfirmedRequisitionsQuery, ConfirmedRequisitionsQueryVariables>) {
        return Apollo.useQuery<ConfirmedRequisitionsQuery, ConfirmedRequisitionsQueryVariables>(ConfirmedRequisitionsDocument, baseOptions);
      }
export function useConfirmedRequisitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmedRequisitionsQuery, ConfirmedRequisitionsQueryVariables>) {
          return Apollo.useLazyQuery<ConfirmedRequisitionsQuery, ConfirmedRequisitionsQueryVariables>(ConfirmedRequisitionsDocument, baseOptions);
        }
export type ConfirmedRequisitionsQueryHookResult = ReturnType<typeof useConfirmedRequisitionsQuery>;
export type ConfirmedRequisitionsLazyQueryHookResult = ReturnType<typeof useConfirmedRequisitionsLazyQuery>;
export type ConfirmedRequisitionsQueryResult = Apollo.QueryResult<ConfirmedRequisitionsQuery, ConfirmedRequisitionsQueryVariables>;
export const RequisitionSchedulesDocument = gql`
    query RequisitionSchedules {
  schedules {
    id
    createdOn
    paused
    nextScheduledPayment
    requisition {
      id
      firstName
      lastName
      items {
        id
        item {
          id
          name
        }
      }
      fulfillmentLocation {
        id
        street
        street2
        city
        state
        countryCode
        zipcode
      }
      purchases {
        id
        createdOn
        subtotal
        tax
        discount
        paymentIntent {
          id
          stripeId
          status
        }
      }
    }
  }
}
    `;

/**
 * __useRequisitionSchedulesQuery__
 *
 * To run a query within a React component, call `useRequisitionSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequisitionSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<RequisitionSchedulesQuery, RequisitionSchedulesQueryVariables>) {
        return Apollo.useQuery<RequisitionSchedulesQuery, RequisitionSchedulesQueryVariables>(RequisitionSchedulesDocument, baseOptions);
      }
export function useRequisitionSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequisitionSchedulesQuery, RequisitionSchedulesQueryVariables>) {
          return Apollo.useLazyQuery<RequisitionSchedulesQuery, RequisitionSchedulesQueryVariables>(RequisitionSchedulesDocument, baseOptions);
        }
export type RequisitionSchedulesQueryHookResult = ReturnType<typeof useRequisitionSchedulesQuery>;
export type RequisitionSchedulesLazyQueryHookResult = ReturnType<typeof useRequisitionSchedulesLazyQuery>;
export type RequisitionSchedulesQueryResult = Apollo.QueryResult<RequisitionSchedulesQuery, RequisitionSchedulesQueryVariables>;
export const RequisitionSinglesDocument = gql`
    query RequisitionSingles($createdOnAfter: Instant!) {
  singles(createdOnAfter: $createdOnAfter) {
    id
    firstName
    lastName
    items {
      id
      item {
        id
        name
      }
    }
    fulfillmentLocation {
      id
      street
      street2
      city
      state
      countryCode
      zipcode
    }
    purchases {
      id
      createdOn
      subtotal
      tax
      discount
      paymentIntent {
        id
        stripeId
        status
      }
    }
  }
}
    `;

/**
 * __useRequisitionSinglesQuery__
 *
 * To run a query within a React component, call `useRequisitionSinglesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionSinglesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionSinglesQuery({
 *   variables: {
 *      createdOnAfter: // value for 'createdOnAfter'
 *   },
 * });
 */
export function useRequisitionSinglesQuery(baseOptions: Apollo.QueryHookOptions<RequisitionSinglesQuery, RequisitionSinglesQueryVariables>) {
        return Apollo.useQuery<RequisitionSinglesQuery, RequisitionSinglesQueryVariables>(RequisitionSinglesDocument, baseOptions);
      }
export function useRequisitionSinglesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequisitionSinglesQuery, RequisitionSinglesQueryVariables>) {
          return Apollo.useLazyQuery<RequisitionSinglesQuery, RequisitionSinglesQueryVariables>(RequisitionSinglesDocument, baseOptions);
        }
export type RequisitionSinglesQueryHookResult = ReturnType<typeof useRequisitionSinglesQuery>;
export type RequisitionSinglesLazyQueryHookResult = ReturnType<typeof useRequisitionSinglesLazyQuery>;
export type RequisitionSinglesQueryResult = Apollo.QueryResult<RequisitionSinglesQuery, RequisitionSinglesQueryVariables>;
export const AddMerchantSubscriptionFeeDocument = gql`
    mutation AddMerchantSubscriptionFee($input: AddMerchantSubscriptionFeeInput!) {
  addMerchantSubscriptionFee(input: $input) {
    subscriptionFee {
      id
      name
      costUsCents
    }
    stripeInvoiceItemId
    paid
  }
}
    `;
export type AddMerchantSubscriptionFeeMutationFn = Apollo.MutationFunction<AddMerchantSubscriptionFeeMutation, AddMerchantSubscriptionFeeMutationVariables>;

/**
 * __useAddMerchantSubscriptionFeeMutation__
 *
 * To run a mutation, you first call `useAddMerchantSubscriptionFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMerchantSubscriptionFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMerchantSubscriptionFeeMutation, { data, loading, error }] = useAddMerchantSubscriptionFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMerchantSubscriptionFeeMutation(baseOptions?: Apollo.MutationHookOptions<AddMerchantSubscriptionFeeMutation, AddMerchantSubscriptionFeeMutationVariables>) {
        return Apollo.useMutation<AddMerchantSubscriptionFeeMutation, AddMerchantSubscriptionFeeMutationVariables>(AddMerchantSubscriptionFeeDocument, baseOptions);
      }
export type AddMerchantSubscriptionFeeMutationHookResult = ReturnType<typeof useAddMerchantSubscriptionFeeMutation>;
export type AddMerchantSubscriptionFeeMutationResult = Apollo.MutationResult<AddMerchantSubscriptionFeeMutation>;
export type AddMerchantSubscriptionFeeMutationOptions = Apollo.BaseMutationOptions<AddMerchantSubscriptionFeeMutation, AddMerchantSubscriptionFeeMutationVariables>;
export const DefaultSubscriptionProductsDocument = gql`
    query DefaultSubscriptionProducts {
  defaultSubscriptionProducts {
    defaultSubscriptionType
    subscriptionProduct {
      id
    }
  }
}
    `;

/**
 * __useDefaultSubscriptionProductsQuery__
 *
 * To run a query within a React component, call `useDefaultSubscriptionProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultSubscriptionProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultSubscriptionProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultSubscriptionProductsQuery(baseOptions?: Apollo.QueryHookOptions<DefaultSubscriptionProductsQuery, DefaultSubscriptionProductsQueryVariables>) {
        return Apollo.useQuery<DefaultSubscriptionProductsQuery, DefaultSubscriptionProductsQueryVariables>(DefaultSubscriptionProductsDocument, baseOptions);
      }
export function useDefaultSubscriptionProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultSubscriptionProductsQuery, DefaultSubscriptionProductsQueryVariables>) {
          return Apollo.useLazyQuery<DefaultSubscriptionProductsQuery, DefaultSubscriptionProductsQueryVariables>(DefaultSubscriptionProductsDocument, baseOptions);
        }
export type DefaultSubscriptionProductsQueryHookResult = ReturnType<typeof useDefaultSubscriptionProductsQuery>;
export type DefaultSubscriptionProductsLazyQueryHookResult = ReturnType<typeof useDefaultSubscriptionProductsLazyQuery>;
export type DefaultSubscriptionProductsQueryResult = Apollo.QueryResult<DefaultSubscriptionProductsQuery, DefaultSubscriptionProductsQueryVariables>;
export const GetPlatformPricingDocument = gql`
    query GetPlatformPricing {
  getPlatformPricing {
    id
    name
    createdOn
    instantPriceUsCents
    platformPricingType
    subscriptionTerm
    subscriptionProduct {
      id
      costUsCents
      name
      recurringInterval
    }
  }
}
    `;

/**
 * __useGetPlatformPricingQuery__
 *
 * To run a query within a React component, call `useGetPlatformPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformPricingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatformPricingQuery(baseOptions?: Apollo.QueryHookOptions<GetPlatformPricingQuery, GetPlatformPricingQueryVariables>) {
        return Apollo.useQuery<GetPlatformPricingQuery, GetPlatformPricingQueryVariables>(GetPlatformPricingDocument, baseOptions);
      }
export function useGetPlatformPricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformPricingQuery, GetPlatformPricingQueryVariables>) {
          return Apollo.useLazyQuery<GetPlatformPricingQuery, GetPlatformPricingQueryVariables>(GetPlatformPricingDocument, baseOptions);
        }
export type GetPlatformPricingQueryHookResult = ReturnType<typeof useGetPlatformPricingQuery>;
export type GetPlatformPricingLazyQueryHookResult = ReturnType<typeof useGetPlatformPricingLazyQuery>;
export type GetPlatformPricingQueryResult = Apollo.QueryResult<GetPlatformPricingQuery, GetPlatformPricingQueryVariables>;
export const SavePlatformPricingDocument = gql`
    mutation SavePlatformPricing($input: PlatformPricingInput!) {
  savePlatformPricing(input: $input) {
    id
    name
    createdOn
    instantPriceUsCents
    platformPricingType
    subscriptionTerm
    subscriptionProduct {
      id
      costUsCents
      name
      recurringInterval
    }
  }
}
    `;
export type SavePlatformPricingMutationFn = Apollo.MutationFunction<SavePlatformPricingMutation, SavePlatformPricingMutationVariables>;

/**
 * __useSavePlatformPricingMutation__
 *
 * To run a mutation, you first call `useSavePlatformPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlatformPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlatformPricingMutation, { data, loading, error }] = useSavePlatformPricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePlatformPricingMutation(baseOptions?: Apollo.MutationHookOptions<SavePlatformPricingMutation, SavePlatformPricingMutationVariables>) {
        return Apollo.useMutation<SavePlatformPricingMutation, SavePlatformPricingMutationVariables>(SavePlatformPricingDocument, baseOptions);
      }
export type SavePlatformPricingMutationHookResult = ReturnType<typeof useSavePlatformPricingMutation>;
export type SavePlatformPricingMutationResult = Apollo.MutationResult<SavePlatformPricingMutation>;
export type SavePlatformPricingMutationOptions = Apollo.BaseMutationOptions<SavePlatformPricingMutation, SavePlatformPricingMutationVariables>;
export const SetDefaultSubscriptionProductDocument = gql`
    mutation setDefaultSubscriptionProduct($subscriptionProductId: ID!, $defaultSuscriptionType: DefaultSuscriptionType!) {
  setDefaultSubscriptionProduct(
    subscriptionProductId: $subscriptionProductId
    defaultSuscriptionType: $defaultSuscriptionType
  )
}
    `;
export type SetDefaultSubscriptionProductMutationFn = Apollo.MutationFunction<SetDefaultSubscriptionProductMutation, SetDefaultSubscriptionProductMutationVariables>;

/**
 * __useSetDefaultSubscriptionProductMutation__
 *
 * To run a mutation, you first call `useSetDefaultSubscriptionProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultSubscriptionProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultSubscriptionProductMutation, { data, loading, error }] = useSetDefaultSubscriptionProductMutation({
 *   variables: {
 *      subscriptionProductId: // value for 'subscriptionProductId'
 *      defaultSuscriptionType: // value for 'defaultSuscriptionType'
 *   },
 * });
 */
export function useSetDefaultSubscriptionProductMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultSubscriptionProductMutation, SetDefaultSubscriptionProductMutationVariables>) {
        return Apollo.useMutation<SetDefaultSubscriptionProductMutation, SetDefaultSubscriptionProductMutationVariables>(SetDefaultSubscriptionProductDocument, baseOptions);
      }
export type SetDefaultSubscriptionProductMutationHookResult = ReturnType<typeof useSetDefaultSubscriptionProductMutation>;
export type SetDefaultSubscriptionProductMutationResult = Apollo.MutationResult<SetDefaultSubscriptionProductMutation>;
export type SetDefaultSubscriptionProductMutationOptions = Apollo.BaseMutationOptions<SetDefaultSubscriptionProductMutation, SetDefaultSubscriptionProductMutationVariables>;
export const SetMerchantSubscriptionDocument = gql`
    mutation SetMerchantSubscription($input: SetMerchantSubscriptionInput!) {
  setMerchantSubscription(input: $input) {
    id
    trialEndDate
    organizationManaged
    subscriptionPlan {
      id
      name
      costUsCents
    }
    merchant {
      id
      stripeCustomerId
    }
    stripeSubscription {
      stripeSubscriptionId
    }
    platformPricing {
      id
      name
      createdOn
      instantPriceUsCents
      platformPricingType
      subscriptionTerm
      subscriptionProduct {
        id
        costUsCents
        name
        recurringInterval
      }
    }
    termPricing
  }
}
    `;
export type SetMerchantSubscriptionMutationFn = Apollo.MutationFunction<SetMerchantSubscriptionMutation, SetMerchantSubscriptionMutationVariables>;

/**
 * __useSetMerchantSubscriptionMutation__
 *
 * To run a mutation, you first call `useSetMerchantSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMerchantSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMerchantSubscriptionMutation, { data, loading, error }] = useSetMerchantSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMerchantSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<SetMerchantSubscriptionMutation, SetMerchantSubscriptionMutationVariables>) {
        return Apollo.useMutation<SetMerchantSubscriptionMutation, SetMerchantSubscriptionMutationVariables>(SetMerchantSubscriptionDocument, baseOptions);
      }
export type SetMerchantSubscriptionMutationHookResult = ReturnType<typeof useSetMerchantSubscriptionMutation>;
export type SetMerchantSubscriptionMutationResult = Apollo.MutationResult<SetMerchantSubscriptionMutation>;
export type SetMerchantSubscriptionMutationOptions = Apollo.BaseMutationOptions<SetMerchantSubscriptionMutation, SetMerchantSubscriptionMutationVariables>;
export const StripeSubscriptionDocument = gql`
    query StripeSubscription($id: ID!) {
  stripeSubscription(id: $id) {
    id
    stripeSubscriptionId
    subscriptionStatus
  }
}
    `;

/**
 * __useStripeSubscriptionQuery__
 *
 * To run a query within a React component, call `useStripeSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStripeSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>) {
        return Apollo.useQuery<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>(StripeSubscriptionDocument, baseOptions);
      }
export function useStripeSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>) {
          return Apollo.useLazyQuery<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>(StripeSubscriptionDocument, baseOptions);
        }
export type StripeSubscriptionQueryHookResult = ReturnType<typeof useStripeSubscriptionQuery>;
export type StripeSubscriptionLazyQueryHookResult = ReturnType<typeof useStripeSubscriptionLazyQuery>;
export type StripeSubscriptionQueryResult = Apollo.QueryResult<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>;
export const UpdateSubscriptionPaymentMethodDocument = gql`
    mutation UpdateSubscriptionPaymentMethod($stripeSubscriptionId: ID!, $paymentMethodId: String!) {
  updateSubscriptionPaymentMethod(
    stripeSubscriptionId: $stripeSubscriptionId
    paymentMethodId: $paymentMethodId
  ) {
    id
    stripeSubscriptionId
    subscriptionStatus
  }
}
    `;
export type UpdateSubscriptionPaymentMethodMutationFn = Apollo.MutationFunction<UpdateSubscriptionPaymentMethodMutation, UpdateSubscriptionPaymentMethodMutationVariables>;

/**
 * __useUpdateSubscriptionPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionPaymentMethodMutation, { data, loading, error }] = useUpdateSubscriptionPaymentMethodMutation({
 *   variables: {
 *      stripeSubscriptionId: // value for 'stripeSubscriptionId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateSubscriptionPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionPaymentMethodMutation, UpdateSubscriptionPaymentMethodMutationVariables>) {
        return Apollo.useMutation<UpdateSubscriptionPaymentMethodMutation, UpdateSubscriptionPaymentMethodMutationVariables>(UpdateSubscriptionPaymentMethodDocument, baseOptions);
      }
export type UpdateSubscriptionPaymentMethodMutationHookResult = ReturnType<typeof useUpdateSubscriptionPaymentMethodMutation>;
export type UpdateSubscriptionPaymentMethodMutationResult = Apollo.MutationResult<UpdateSubscriptionPaymentMethodMutation>;
export type UpdateSubscriptionPaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionPaymentMethodMutation, UpdateSubscriptionPaymentMethodMutationVariables>;
export const SubscriptionFeesDocument = gql`
    query SubscriptionFees {
  subscriptionFees {
    id
    name
    costUsCents
    createdOn
  }
}
    `;

/**
 * __useSubscriptionFeesQuery__
 *
 * To run a query within a React component, call `useSubscriptionFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionFeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionFeesQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionFeesQuery, SubscriptionFeesQueryVariables>) {
        return Apollo.useQuery<SubscriptionFeesQuery, SubscriptionFeesQueryVariables>(SubscriptionFeesDocument, baseOptions);
      }
export function useSubscriptionFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionFeesQuery, SubscriptionFeesQueryVariables>) {
          return Apollo.useLazyQuery<SubscriptionFeesQuery, SubscriptionFeesQueryVariables>(SubscriptionFeesDocument, baseOptions);
        }
export type SubscriptionFeesQueryHookResult = ReturnType<typeof useSubscriptionFeesQuery>;
export type SubscriptionFeesLazyQueryHookResult = ReturnType<typeof useSubscriptionFeesLazyQuery>;
export type SubscriptionFeesQueryResult = Apollo.QueryResult<SubscriptionFeesQuery, SubscriptionFeesQueryVariables>;
export const SubscriptionPlansDocument = gql`
    query SubscriptionPlans {
  subscriptionPlans {
    id
    name
    costUsCents
    recurringInterval
    createdOn
  }
}
    `;

/**
 * __useSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlansQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
        return Apollo.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, baseOptions);
      }
export function useSubscriptionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
          return Apollo.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, baseOptions);
        }
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = Apollo.QueryResult<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>;
export const GrantTokensDocument = gql`
    mutation GrantTokens($input: GrantTokensInput!) {
  grantTokens(input: $input) {
    ...TokenInfo
  }
}
    ${TokenInfoFragmentDoc}`;
export type GrantTokensMutationFn = Apollo.MutationFunction<GrantTokensMutation, GrantTokensMutationVariables>;

/**
 * __useGrantTokensMutation__
 *
 * To run a mutation, you first call `useGrantTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantTokensMutation, { data, loading, error }] = useGrantTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrantTokensMutation(baseOptions?: Apollo.MutationHookOptions<GrantTokensMutation, GrantTokensMutationVariables>) {
        return Apollo.useMutation<GrantTokensMutation, GrantTokensMutationVariables>(GrantTokensDocument, baseOptions);
      }
export type GrantTokensMutationHookResult = ReturnType<typeof useGrantTokensMutation>;
export type GrantTokensMutationResult = Apollo.MutationResult<GrantTokensMutation>;
export type GrantTokensMutationOptions = Apollo.BaseMutationOptions<GrantTokensMutation, GrantTokensMutationVariables>;
export const CatalogSuggestedItemsDocument = gql`
    query CatalogSuggestedItems($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    activeCatalog {
      id
      suggestedItems {
        ...CatalogSuggestedItem
      }
    }
  }
}
    ${CatalogSuggestedItemFragmentDoc}`;

/**
 * __useCatalogSuggestedItemsQuery__
 *
 * To run a query within a React component, call `useCatalogSuggestedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogSuggestedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogSuggestedItemsQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useCatalogSuggestedItemsQuery(baseOptions: Apollo.QueryHookOptions<CatalogSuggestedItemsQuery, CatalogSuggestedItemsQueryVariables>) {
        return Apollo.useQuery<CatalogSuggestedItemsQuery, CatalogSuggestedItemsQueryVariables>(CatalogSuggestedItemsDocument, baseOptions);
      }
export function useCatalogSuggestedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogSuggestedItemsQuery, CatalogSuggestedItemsQueryVariables>) {
          return Apollo.useLazyQuery<CatalogSuggestedItemsQuery, CatalogSuggestedItemsQueryVariables>(CatalogSuggestedItemsDocument, baseOptions);
        }
export type CatalogSuggestedItemsQueryHookResult = ReturnType<typeof useCatalogSuggestedItemsQuery>;
export type CatalogSuggestedItemsLazyQueryHookResult = ReturnType<typeof useCatalogSuggestedItemsLazyQuery>;
export type CatalogSuggestedItemsQueryResult = Apollo.QueryResult<CatalogSuggestedItemsQuery, CatalogSuggestedItemsQueryVariables>;
export const UpdateSuggestedItemsDocument = gql`
    mutation UpdateSuggestedItems($input: UpdateSuggestedItemsInput!) {
  updateSuggestedItems(input: $input) {
    id
    suggestedItems {
      id
      product {
        ...CatalogProduct
      }
    }
  }
}
    ${CatalogProductFragmentDoc}`;
export type UpdateSuggestedItemsMutationFn = Apollo.MutationFunction<UpdateSuggestedItemsMutation, UpdateSuggestedItemsMutationVariables>;

/**
 * __useUpdateSuggestedItemsMutation__
 *
 * To run a mutation, you first call `useUpdateSuggestedItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuggestedItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuggestedItemsMutation, { data, loading, error }] = useUpdateSuggestedItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSuggestedItemsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSuggestedItemsMutation, UpdateSuggestedItemsMutationVariables>) {
        return Apollo.useMutation<UpdateSuggestedItemsMutation, UpdateSuggestedItemsMutationVariables>(UpdateSuggestedItemsDocument, baseOptions);
      }
export type UpdateSuggestedItemsMutationHookResult = ReturnType<typeof useUpdateSuggestedItemsMutation>;
export type UpdateSuggestedItemsMutationResult = Apollo.MutationResult<UpdateSuggestedItemsMutation>;
export type UpdateSuggestedItemsMutationOptions = Apollo.BaseMutationOptions<UpdateSuggestedItemsMutation, UpdateSuggestedItemsMutationVariables>;
export const ActiveCatalogPreviewDocument = gql`
    query ActiveCatalogPreview($merchantId: ID!) {
  merchant(id: $merchantId) {
    activeCatalog {
      id
      name
      description
      categories {
        id
        name
        activeProducts {
          id
          name
        }
      }
      suggestedItems {
        id
        product {
          ...CatalogProduct
        }
      }
    }
  }
}
    ${CatalogProductFragmentDoc}`;

/**
 * __useActiveCatalogPreviewQuery__
 *
 * To run a query within a React component, call `useActiveCatalogPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveCatalogPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveCatalogPreviewQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useActiveCatalogPreviewQuery(baseOptions: Apollo.QueryHookOptions<ActiveCatalogPreviewQuery, ActiveCatalogPreviewQueryVariables>) {
        return Apollo.useQuery<ActiveCatalogPreviewQuery, ActiveCatalogPreviewQueryVariables>(ActiveCatalogPreviewDocument, baseOptions);
      }
export function useActiveCatalogPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveCatalogPreviewQuery, ActiveCatalogPreviewQueryVariables>) {
          return Apollo.useLazyQuery<ActiveCatalogPreviewQuery, ActiveCatalogPreviewQueryVariables>(ActiveCatalogPreviewDocument, baseOptions);
        }
export type ActiveCatalogPreviewQueryHookResult = ReturnType<typeof useActiveCatalogPreviewQuery>;
export type ActiveCatalogPreviewLazyQueryHookResult = ReturnType<typeof useActiveCatalogPreviewLazyQuery>;
export type ActiveCatalogPreviewQueryResult = Apollo.QueryResult<ActiveCatalogPreviewQuery, ActiveCatalogPreviewQueryVariables>;
export const CatalogListDocument = gql`
    query CatalogList($merchantId: ID!) {
  merchant(id: $merchantId) {
    catalogs {
      id
      name
      description
      isDefault
      products {
        id
        name
      }
      availabilityWindows {
        id
        dayOfWeek
        start
        end
      }
    }
  }
}
    `;

/**
 * __useCatalogListQuery__
 *
 * To run a query within a React component, call `useCatalogListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogListQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useCatalogListQuery(baseOptions: Apollo.QueryHookOptions<CatalogListQuery, CatalogListQueryVariables>) {
        return Apollo.useQuery<CatalogListQuery, CatalogListQueryVariables>(CatalogListDocument, baseOptions);
      }
export function useCatalogListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogListQuery, CatalogListQueryVariables>) {
          return Apollo.useLazyQuery<CatalogListQuery, CatalogListQueryVariables>(CatalogListDocument, baseOptions);
        }
export type CatalogListQueryHookResult = ReturnType<typeof useCatalogListQuery>;
export type CatalogListLazyQueryHookResult = ReturnType<typeof useCatalogListLazyQuery>;
export type CatalogListQueryResult = Apollo.QueryResult<CatalogListQuery, CatalogListQueryVariables>;
export const CreateCatalogDocument = gql`
    mutation CreateCatalog($input: CreateCatalogInput!) {
  createCatalog(input: $input) {
    id
    name
    isDefault
    availabilityWindows {
      id
      dayOfWeek
      start
      end
    }
  }
}
    `;
export type CreateCatalogMutationFn = Apollo.MutationFunction<CreateCatalogMutation, CreateCatalogMutationVariables>;

/**
 * __useCreateCatalogMutation__
 *
 * To run a mutation, you first call `useCreateCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogMutation, { data, loading, error }] = useCreateCatalogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCatalogMutation(baseOptions?: Apollo.MutationHookOptions<CreateCatalogMutation, CreateCatalogMutationVariables>) {
        return Apollo.useMutation<CreateCatalogMutation, CreateCatalogMutationVariables>(CreateCatalogDocument, baseOptions);
      }
export type CreateCatalogMutationHookResult = ReturnType<typeof useCreateCatalogMutation>;
export type CreateCatalogMutationResult = Apollo.MutationResult<CreateCatalogMutation>;
export type CreateCatalogMutationOptions = Apollo.BaseMutationOptions<CreateCatalogMutation, CreateCatalogMutationVariables>;
export const DeleteCatalogDocument = gql`
    mutation DeleteCatalog($id: ID!) {
  deleteCatalog(id: $id)
}
    `;
export type DeleteCatalogMutationFn = Apollo.MutationFunction<DeleteCatalogMutation, DeleteCatalogMutationVariables>;

/**
 * __useDeleteCatalogMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogMutation, { data, loading, error }] = useDeleteCatalogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCatalogMutation, DeleteCatalogMutationVariables>) {
        return Apollo.useMutation<DeleteCatalogMutation, DeleteCatalogMutationVariables>(DeleteCatalogDocument, baseOptions);
      }
export type DeleteCatalogMutationHookResult = ReturnType<typeof useDeleteCatalogMutation>;
export type DeleteCatalogMutationResult = Apollo.MutationResult<DeleteCatalogMutation>;
export type DeleteCatalogMutationOptions = Apollo.BaseMutationOptions<DeleteCatalogMutation, DeleteCatalogMutationVariables>;
export const UpdateCatalogDocument = gql`
    mutation UpdateCatalog($input: UpdateCatalogInput!) {
  updateCatalog(input: $input) {
    id
    name
    isDefault
    availabilityWindows {
      id
      dayOfWeek
      start
      end
    }
  }
}
    `;
export type UpdateCatalogMutationFn = Apollo.MutationFunction<UpdateCatalogMutation, UpdateCatalogMutationVariables>;

/**
 * __useUpdateCatalogMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogMutation, { data, loading, error }] = useUpdateCatalogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCatalogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCatalogMutation, UpdateCatalogMutationVariables>) {
        return Apollo.useMutation<UpdateCatalogMutation, UpdateCatalogMutationVariables>(UpdateCatalogDocument, baseOptions);
      }
export type UpdateCatalogMutationHookResult = ReturnType<typeof useUpdateCatalogMutation>;
export type UpdateCatalogMutationResult = Apollo.MutationResult<UpdateCatalogMutation>;
export type UpdateCatalogMutationOptions = Apollo.BaseMutationOptions<UpdateCatalogMutation, UpdateCatalogMutationVariables>;
export const MerchantSourceSystemDocument = gql`
    query MerchantSourceSystem($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    name
    source
    sourceSystemId
  }
}
    `;

/**
 * __useMerchantSourceSystemQuery__
 *
 * To run a query within a React component, call `useMerchantSourceSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSourceSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSourceSystemQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantSourceSystemQuery(baseOptions: Apollo.QueryHookOptions<MerchantSourceSystemQuery, MerchantSourceSystemQueryVariables>) {
        return Apollo.useQuery<MerchantSourceSystemQuery, MerchantSourceSystemQueryVariables>(MerchantSourceSystemDocument, baseOptions);
      }
export function useMerchantSourceSystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSourceSystemQuery, MerchantSourceSystemQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSourceSystemQuery, MerchantSourceSystemQueryVariables>(MerchantSourceSystemDocument, baseOptions);
        }
export type MerchantSourceSystemQueryHookResult = ReturnType<typeof useMerchantSourceSystemQuery>;
export type MerchantSourceSystemLazyQueryHookResult = ReturnType<typeof useMerchantSourceSystemLazyQuery>;
export type MerchantSourceSystemQueryResult = Apollo.QueryResult<MerchantSourceSystemQuery, MerchantSourceSystemQueryVariables>;
export const UpdateMerchantSourceSystemDocument = gql`
    mutation UpdateMerchantSourceSystem($merchantId: ID!, $sourceSystem: ExternalDataSource!, $sourceSystemId: String!, $syncMenu: Boolean!) {
  updateMerchantSourceSystem(
    merchantId: $merchantId
    sourceSystem: $sourceSystem
    sourceSystemId: $sourceSystemId
    syncMenu: $syncMenu
  ) {
    id
    name
    source
    sourceSystemId
  }
}
    `;
export type UpdateMerchantSourceSystemMutationFn = Apollo.MutationFunction<UpdateMerchantSourceSystemMutation, UpdateMerchantSourceSystemMutationVariables>;

/**
 * __useUpdateMerchantSourceSystemMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantSourceSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantSourceSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantSourceSystemMutation, { data, loading, error }] = useUpdateMerchantSourceSystemMutation({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      sourceSystem: // value for 'sourceSystem'
 *      sourceSystemId: // value for 'sourceSystemId'
 *      syncMenu: // value for 'syncMenu'
 *   },
 * });
 */
export function useUpdateMerchantSourceSystemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantSourceSystemMutation, UpdateMerchantSourceSystemMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantSourceSystemMutation, UpdateMerchantSourceSystemMutationVariables>(UpdateMerchantSourceSystemDocument, baseOptions);
      }
export type UpdateMerchantSourceSystemMutationHookResult = ReturnType<typeof useUpdateMerchantSourceSystemMutation>;
export type UpdateMerchantSourceSystemMutationResult = Apollo.MutationResult<UpdateMerchantSourceSystemMutation>;
export type UpdateMerchantSourceSystemMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantSourceSystemMutation, UpdateMerchantSourceSystemMutationVariables>;
export const CreateMerchantKdsIntegrationDocument = gql`
    mutation CreateMerchantKdsIntegration($input: CreateMerchantKdsIntegrationInput!) {
  createMerchantKdsIntegration(input: $input) {
    id
    createdOn
    type
    locationId
    displayName
    active
  }
}
    `;
export type CreateMerchantKdsIntegrationMutationFn = Apollo.MutationFunction<CreateMerchantKdsIntegrationMutation, CreateMerchantKdsIntegrationMutationVariables>;

/**
 * __useCreateMerchantKdsIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateMerchantKdsIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantKdsIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantKdsIntegrationMutation, { data, loading, error }] = useCreateMerchantKdsIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMerchantKdsIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchantKdsIntegrationMutation, CreateMerchantKdsIntegrationMutationVariables>) {
        return Apollo.useMutation<CreateMerchantKdsIntegrationMutation, CreateMerchantKdsIntegrationMutationVariables>(CreateMerchantKdsIntegrationDocument, baseOptions);
      }
export type CreateMerchantKdsIntegrationMutationHookResult = ReturnType<typeof useCreateMerchantKdsIntegrationMutation>;
export type CreateMerchantKdsIntegrationMutationResult = Apollo.MutationResult<CreateMerchantKdsIntegrationMutation>;
export type CreateMerchantKdsIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateMerchantKdsIntegrationMutation, CreateMerchantKdsIntegrationMutationVariables>;
export const ArchiveMerchantKdsIntegrationDocument = gql`
    mutation ArchiveMerchantKdsIntegration($id: ID!) {
  archiveMerchantKdsIntegration(id: $id)
}
    `;
export type ArchiveMerchantKdsIntegrationMutationFn = Apollo.MutationFunction<ArchiveMerchantKdsIntegrationMutation, ArchiveMerchantKdsIntegrationMutationVariables>;

/**
 * __useArchiveMerchantKdsIntegrationMutation__
 *
 * To run a mutation, you first call `useArchiveMerchantKdsIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMerchantKdsIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMerchantKdsIntegrationMutation, { data, loading, error }] = useArchiveMerchantKdsIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveMerchantKdsIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveMerchantKdsIntegrationMutation, ArchiveMerchantKdsIntegrationMutationVariables>) {
        return Apollo.useMutation<ArchiveMerchantKdsIntegrationMutation, ArchiveMerchantKdsIntegrationMutationVariables>(ArchiveMerchantKdsIntegrationDocument, baseOptions);
      }
export type ArchiveMerchantKdsIntegrationMutationHookResult = ReturnType<typeof useArchiveMerchantKdsIntegrationMutation>;
export type ArchiveMerchantKdsIntegrationMutationResult = Apollo.MutationResult<ArchiveMerchantKdsIntegrationMutation>;
export type ArchiveMerchantKdsIntegrationMutationOptions = Apollo.BaseMutationOptions<ArchiveMerchantKdsIntegrationMutation, ArchiveMerchantKdsIntegrationMutationVariables>;
export const KdsIntegrationDetailsDocument = gql`
    query KdsIntegrationDetails($id: ID!) {
  kdsIntegration(id: $id) {
    id
    type
    locationId
    displayName
    active
    devices {
      id
      uuid
      name
    }
  }
}
    `;

/**
 * __useKdsIntegrationDetailsQuery__
 *
 * To run a query within a React component, call `useKdsIntegrationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsIntegrationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsIntegrationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKdsIntegrationDetailsQuery(baseOptions: Apollo.QueryHookOptions<KdsIntegrationDetailsQuery, KdsIntegrationDetailsQueryVariables>) {
        return Apollo.useQuery<KdsIntegrationDetailsQuery, KdsIntegrationDetailsQueryVariables>(KdsIntegrationDetailsDocument, baseOptions);
      }
export function useKdsIntegrationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsIntegrationDetailsQuery, KdsIntegrationDetailsQueryVariables>) {
          return Apollo.useLazyQuery<KdsIntegrationDetailsQuery, KdsIntegrationDetailsQueryVariables>(KdsIntegrationDetailsDocument, baseOptions);
        }
export type KdsIntegrationDetailsQueryHookResult = ReturnType<typeof useKdsIntegrationDetailsQuery>;
export type KdsIntegrationDetailsLazyQueryHookResult = ReturnType<typeof useKdsIntegrationDetailsLazyQuery>;
export type KdsIntegrationDetailsQueryResult = Apollo.QueryResult<KdsIntegrationDetailsQuery, KdsIntegrationDetailsQueryVariables>;
export const MerchantOutputDestinationsDocument = gql`
    query MerchantOutputDestinations($merchantId: ID!) {
  merchant(id: $merchantId) {
    outputDestinations {
      type
      kdsIntegrationDevice {
        id
        name
        uuid
      }
      categorySettings {
        id
        category {
          id
          name
          active
        }
        active
      }
    }
  }
}
    `;

/**
 * __useMerchantOutputDestinationsQuery__
 *
 * To run a query within a React component, call `useMerchantOutputDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantOutputDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantOutputDestinationsQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantOutputDestinationsQuery(baseOptions: Apollo.QueryHookOptions<MerchantOutputDestinationsQuery, MerchantOutputDestinationsQueryVariables>) {
        return Apollo.useQuery<MerchantOutputDestinationsQuery, MerchantOutputDestinationsQueryVariables>(MerchantOutputDestinationsDocument, baseOptions);
      }
export function useMerchantOutputDestinationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantOutputDestinationsQuery, MerchantOutputDestinationsQueryVariables>) {
          return Apollo.useLazyQuery<MerchantOutputDestinationsQuery, MerchantOutputDestinationsQueryVariables>(MerchantOutputDestinationsDocument, baseOptions);
        }
export type MerchantOutputDestinationsQueryHookResult = ReturnType<typeof useMerchantOutputDestinationsQuery>;
export type MerchantOutputDestinationsLazyQueryHookResult = ReturnType<typeof useMerchantOutputDestinationsLazyQuery>;
export type MerchantOutputDestinationsQueryResult = Apollo.QueryResult<MerchantOutputDestinationsQuery, MerchantOutputDestinationsQueryVariables>;
export const UpdateOutputDestinationsDocument = gql`
    mutation UpdateOutputDestinations($input: UpdateOutputDestinationCategoriesInput) {
  updateOutputDestinationCategories(input: $input) {
    type
    kdsIntegrationDevice {
      id
      name
      uuid
    }
    categorySettings {
      id
      category {
        id
        name
        active
      }
      active
    }
  }
}
    `;
export type UpdateOutputDestinationsMutationFn = Apollo.MutationFunction<UpdateOutputDestinationsMutation, UpdateOutputDestinationsMutationVariables>;

/**
 * __useUpdateOutputDestinationsMutation__
 *
 * To run a mutation, you first call `useUpdateOutputDestinationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutputDestinationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutputDestinationsMutation, { data, loading, error }] = useUpdateOutputDestinationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOutputDestinationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutputDestinationsMutation, UpdateOutputDestinationsMutationVariables>) {
        return Apollo.useMutation<UpdateOutputDestinationsMutation, UpdateOutputDestinationsMutationVariables>(UpdateOutputDestinationsDocument, baseOptions);
      }
export type UpdateOutputDestinationsMutationHookResult = ReturnType<typeof useUpdateOutputDestinationsMutation>;
export type UpdateOutputDestinationsMutationResult = Apollo.MutationResult<UpdateOutputDestinationsMutation>;
export type UpdateOutputDestinationsMutationOptions = Apollo.BaseMutationOptions<UpdateOutputDestinationsMutation, UpdateOutputDestinationsMutationVariables>;
export const KdsIntegrationListDocument = gql`
    query KdsIntegrationList($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    kdsIntegrations {
      id
      type
      locationId
      displayName
      active
      sendOrdersToRdy
    }
  }
}
    `;

/**
 * __useKdsIntegrationListQuery__
 *
 * To run a query within a React component, call `useKdsIntegrationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsIntegrationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsIntegrationListQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useKdsIntegrationListQuery(baseOptions: Apollo.QueryHookOptions<KdsIntegrationListQuery, KdsIntegrationListQueryVariables>) {
        return Apollo.useQuery<KdsIntegrationListQuery, KdsIntegrationListQueryVariables>(KdsIntegrationListDocument, baseOptions);
      }
export function useKdsIntegrationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsIntegrationListQuery, KdsIntegrationListQueryVariables>) {
          return Apollo.useLazyQuery<KdsIntegrationListQuery, KdsIntegrationListQueryVariables>(KdsIntegrationListDocument, baseOptions);
        }
export type KdsIntegrationListQueryHookResult = ReturnType<typeof useKdsIntegrationListQuery>;
export type KdsIntegrationListLazyQueryHookResult = ReturnType<typeof useKdsIntegrationListLazyQuery>;
export type KdsIntegrationListQueryResult = Apollo.QueryResult<KdsIntegrationListQuery, KdsIntegrationListQueryVariables>;
export const SetupKdsIntegrationDevicesDocument = gql`
    mutation SetupKdsIntegrationDevices($integrationId: ID!) {
  setupKdsIntegrationDevices(integrationId: $integrationId) {
    id
    type
    locationId
    displayName
    active
    devices {
      id
      uuid
      name
    }
  }
}
    `;
export type SetupKdsIntegrationDevicesMutationFn = Apollo.MutationFunction<SetupKdsIntegrationDevicesMutation, SetupKdsIntegrationDevicesMutationVariables>;

/**
 * __useSetupKdsIntegrationDevicesMutation__
 *
 * To run a mutation, you first call `useSetupKdsIntegrationDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupKdsIntegrationDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupKdsIntegrationDevicesMutation, { data, loading, error }] = useSetupKdsIntegrationDevicesMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useSetupKdsIntegrationDevicesMutation(baseOptions?: Apollo.MutationHookOptions<SetupKdsIntegrationDevicesMutation, SetupKdsIntegrationDevicesMutationVariables>) {
        return Apollo.useMutation<SetupKdsIntegrationDevicesMutation, SetupKdsIntegrationDevicesMutationVariables>(SetupKdsIntegrationDevicesDocument, baseOptions);
      }
export type SetupKdsIntegrationDevicesMutationHookResult = ReturnType<typeof useSetupKdsIntegrationDevicesMutation>;
export type SetupKdsIntegrationDevicesMutationResult = Apollo.MutationResult<SetupKdsIntegrationDevicesMutation>;
export type SetupKdsIntegrationDevicesMutationOptions = Apollo.BaseMutationOptions<SetupKdsIntegrationDevicesMutation, SetupKdsIntegrationDevicesMutationVariables>;
export const UpdateMerchantKdsIntegrationActiveDocument = gql`
    mutation UpdateMerchantKdsIntegrationActive($id: ID!, $active: Boolean!) {
  updateMerchantKdsIntegrationActive(id: $id, active: $active) {
    id
    type
    locationId
    displayName
    active
  }
}
    `;
export type UpdateMerchantKdsIntegrationActiveMutationFn = Apollo.MutationFunction<UpdateMerchantKdsIntegrationActiveMutation, UpdateMerchantKdsIntegrationActiveMutationVariables>;

/**
 * __useUpdateMerchantKdsIntegrationActiveMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantKdsIntegrationActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantKdsIntegrationActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantKdsIntegrationActiveMutation, { data, loading, error }] = useUpdateMerchantKdsIntegrationActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateMerchantKdsIntegrationActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantKdsIntegrationActiveMutation, UpdateMerchantKdsIntegrationActiveMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantKdsIntegrationActiveMutation, UpdateMerchantKdsIntegrationActiveMutationVariables>(UpdateMerchantKdsIntegrationActiveDocument, baseOptions);
      }
export type UpdateMerchantKdsIntegrationActiveMutationHookResult = ReturnType<typeof useUpdateMerchantKdsIntegrationActiveMutation>;
export type UpdateMerchantKdsIntegrationActiveMutationResult = Apollo.MutationResult<UpdateMerchantKdsIntegrationActiveMutation>;
export type UpdateMerchantKdsIntegrationActiveMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantKdsIntegrationActiveMutation, UpdateMerchantKdsIntegrationActiveMutationVariables>;
export const KdsListDocument = gql`
    query KdsList($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    kdss {
      id
      uuid
      authorizedBy {
        id
        profile {
          id
          contactEmail
        }
      }
      active
      os
      appVersion
    }
  }
}
    `;

/**
 * __useKdsListQuery__
 *
 * To run a query within a React component, call `useKdsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsListQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useKdsListQuery(baseOptions: Apollo.QueryHookOptions<KdsListQuery, KdsListQueryVariables>) {
        return Apollo.useQuery<KdsListQuery, KdsListQueryVariables>(KdsListDocument, baseOptions);
      }
export function useKdsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsListQuery, KdsListQueryVariables>) {
          return Apollo.useLazyQuery<KdsListQuery, KdsListQueryVariables>(KdsListDocument, baseOptions);
        }
export type KdsListQueryHookResult = ReturnType<typeof useKdsListQuery>;
export type KdsListLazyQueryHookResult = ReturnType<typeof useKdsListLazyQuery>;
export type KdsListQueryResult = Apollo.QueryResult<KdsListQuery, KdsListQueryVariables>;
export const MyKdssDocument = gql`
    query MyKdss {
  me {
    id
    kdss {
      id
      uuid
      merchant {
        id
        name
      }
      active
    }
  }
}
    `;

/**
 * __useMyKdssQuery__
 *
 * To run a query within a React component, call `useMyKdssQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyKdssQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyKdssQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyKdssQuery(baseOptions?: Apollo.QueryHookOptions<MyKdssQuery, MyKdssQueryVariables>) {
        return Apollo.useQuery<MyKdssQuery, MyKdssQueryVariables>(MyKdssDocument, baseOptions);
      }
export function useMyKdssLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyKdssQuery, MyKdssQueryVariables>) {
          return Apollo.useLazyQuery<MyKdssQuery, MyKdssQueryVariables>(MyKdssDocument, baseOptions);
        }
export type MyKdssQueryHookResult = ReturnType<typeof useMyKdssQuery>;
export type MyKdssLazyQueryHookResult = ReturnType<typeof useMyKdssLazyQuery>;
export type MyKdssQueryResult = Apollo.QueryResult<MyKdssQuery, MyKdssQueryVariables>;
export const UnlinkKdsDocument = gql`
    mutation UnlinkKds($id: ID!) {
  unlinkKds(id: $id)
}
    `;
export type UnlinkKdsMutationFn = Apollo.MutationFunction<UnlinkKdsMutation, UnlinkKdsMutationVariables>;

/**
 * __useUnlinkKdsMutation__
 *
 * To run a mutation, you first call `useUnlinkKdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkKdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkKdsMutation, { data, loading, error }] = useUnlinkKdsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlinkKdsMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkKdsMutation, UnlinkKdsMutationVariables>) {
        return Apollo.useMutation<UnlinkKdsMutation, UnlinkKdsMutationVariables>(UnlinkKdsDocument, baseOptions);
      }
export type UnlinkKdsMutationHookResult = ReturnType<typeof useUnlinkKdsMutation>;
export type UnlinkKdsMutationResult = Apollo.MutationResult<UnlinkKdsMutation>;
export type UnlinkKdsMutationOptions = Apollo.BaseMutationOptions<UnlinkKdsMutation, UnlinkKdsMutationVariables>;
export const AcceptInviteDocument = gql`
    mutation AcceptInvite($token: String!) {
  acceptUserInvite(token: $token) {
    id
    roles
  }
}
    `;
export type AcceptInviteMutationFn = Apollo.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, baseOptions);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const InviteDetailsDocument = gql`
    query InviteDetails($token: String!) {
  userInvite(token: $token) {
    organization {
      id
      name
    }
    invitedBy {
      id
      profile {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useInviteDetailsQuery__
 *
 * To run a query within a React component, call `useInviteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteDetailsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInviteDetailsQuery(baseOptions: Apollo.QueryHookOptions<InviteDetailsQuery, InviteDetailsQueryVariables>) {
        return Apollo.useQuery<InviteDetailsQuery, InviteDetailsQueryVariables>(InviteDetailsDocument, baseOptions);
      }
export function useInviteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteDetailsQuery, InviteDetailsQueryVariables>) {
          return Apollo.useLazyQuery<InviteDetailsQuery, InviteDetailsQueryVariables>(InviteDetailsDocument, baseOptions);
        }
export type InviteDetailsQueryHookResult = ReturnType<typeof useInviteDetailsQuery>;
export type InviteDetailsLazyQueryHookResult = ReturnType<typeof useInviteDetailsLazyQuery>;
export type InviteDetailsQueryResult = Apollo.QueryResult<InviteDetailsQuery, InviteDetailsQueryVariables>;
export const CreateMerchantDocument = gql`
    mutation CreateMerchant($input: MerchantInput!) {
  createMerchant(input: $input) {
    id
    name
  }
}
    `;
export type CreateMerchantMutationFn = Apollo.MutationFunction<CreateMerchantMutation, CreateMerchantMutationVariables>;

/**
 * __useCreateMerchantMutation__
 *
 * To run a mutation, you first call `useCreateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantMutation, { data, loading, error }] = useCreateMerchantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchantMutation, CreateMerchantMutationVariables>) {
        return Apollo.useMutation<CreateMerchantMutation, CreateMerchantMutationVariables>(CreateMerchantDocument, baseOptions);
      }
export type CreateMerchantMutationHookResult = ReturnType<typeof useCreateMerchantMutation>;
export type CreateMerchantMutationResult = Apollo.MutationResult<CreateMerchantMutation>;
export type CreateMerchantMutationOptions = Apollo.BaseMutationOptions<CreateMerchantMutation, CreateMerchantMutationVariables>;
export const MoveMerchantToDifferentOrganizationDocument = gql`
    mutation MoveMerchantToDifferentOrganization($input: moveMerchantToDifferentOrganizationInput!) {
  moveMerchantToDifferentOrganization(input: $input)
}
    `;
export type MoveMerchantToDifferentOrganizationMutationFn = Apollo.MutationFunction<MoveMerchantToDifferentOrganizationMutation, MoveMerchantToDifferentOrganizationMutationVariables>;

/**
 * __useMoveMerchantToDifferentOrganizationMutation__
 *
 * To run a mutation, you first call `useMoveMerchantToDifferentOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMerchantToDifferentOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMerchantToDifferentOrganizationMutation, { data, loading, error }] = useMoveMerchantToDifferentOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveMerchantToDifferentOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<MoveMerchantToDifferentOrganizationMutation, MoveMerchantToDifferentOrganizationMutationVariables>) {
        return Apollo.useMutation<MoveMerchantToDifferentOrganizationMutation, MoveMerchantToDifferentOrganizationMutationVariables>(MoveMerchantToDifferentOrganizationDocument, baseOptions);
      }
export type MoveMerchantToDifferentOrganizationMutationHookResult = ReturnType<typeof useMoveMerchantToDifferentOrganizationMutation>;
export type MoveMerchantToDifferentOrganizationMutationResult = Apollo.MutationResult<MoveMerchantToDifferentOrganizationMutation>;
export type MoveMerchantToDifferentOrganizationMutationOptions = Apollo.BaseMutationOptions<MoveMerchantToDifferentOrganizationMutation, MoveMerchantToDifferentOrganizationMutationVariables>;
export const UpdateMerchantDocument = gql`
    mutation UpdateMerchant($input: MerchantInput!) {
  updateMerchant(input: $input) {
    id
    name
  }
}
    `;
export type UpdateMerchantMutationFn = Apollo.MutationFunction<UpdateMerchantMutation, UpdateMerchantMutationVariables>;

/**
 * __useUpdateMerchantMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantMutation, { data, loading, error }] = useUpdateMerchantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantMutation, UpdateMerchantMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantMutation, UpdateMerchantMutationVariables>(UpdateMerchantDocument, baseOptions);
      }
export type UpdateMerchantMutationHookResult = ReturnType<typeof useUpdateMerchantMutation>;
export type UpdateMerchantMutationResult = Apollo.MutationResult<UpdateMerchantMutation>;
export type UpdateMerchantMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantMutation, UpdateMerchantMutationVariables>;
export const UpdateMerchantDetailsDocument = gql`
    query UpdateMerchantDetails($id: ID!) {
  merchant(id: $id) {
    id
    name
    active
    location {
      id
      street
      street2
      city
      state
      zipCode
      countryCode
      geoFenceType
    }
    taxRate
    diningOptions
    operationHours {
      ...MerchantOperationHour
    }
  }
}
    ${MerchantOperationHourFragmentDoc}`;

/**
 * __useUpdateMerchantDetailsQuery__
 *
 * To run a query within a React component, call `useUpdateMerchantDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateMerchantDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMerchantDetailsQuery(baseOptions: Apollo.QueryHookOptions<UpdateMerchantDetailsQuery, UpdateMerchantDetailsQueryVariables>) {
        return Apollo.useQuery<UpdateMerchantDetailsQuery, UpdateMerchantDetailsQueryVariables>(UpdateMerchantDetailsDocument, baseOptions);
      }
export function useUpdateMerchantDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateMerchantDetailsQuery, UpdateMerchantDetailsQueryVariables>) {
          return Apollo.useLazyQuery<UpdateMerchantDetailsQuery, UpdateMerchantDetailsQueryVariables>(UpdateMerchantDetailsDocument, baseOptions);
        }
export type UpdateMerchantDetailsQueryHookResult = ReturnType<typeof useUpdateMerchantDetailsQuery>;
export type UpdateMerchantDetailsLazyQueryHookResult = ReturnType<typeof useUpdateMerchantDetailsLazyQuery>;
export type UpdateMerchantDetailsQueryResult = Apollo.QueryResult<UpdateMerchantDetailsQuery, UpdateMerchantDetailsQueryVariables>;
export const ViewMerchantDocument = gql`
    query ViewMerchant($id: ID!) {
  merchant(id: $id) {
    id
    name
    active
    location {
      id
      street
      street2
      city
      state
      zipCode
      countryCode
      latitude
      longitude
      geoFenceType
    }
    organization {
      id
      name
    }
    merchantContacts {
      ...MerchantContactDetails
    }
    merchantFeeConfig {
      id
      default
      staticFeeInUsCents
      percentageFee
    }
    taxRate
    diningOptions
    freshLocation {
      locationId
      lastSyncCompleted
    }
    operationHours {
      ...MerchantOperationHour
    }
  }
}
    ${MerchantContactDetailsFragmentDoc}
${MerchantOperationHourFragmentDoc}`;

/**
 * __useViewMerchantQuery__
 *
 * To run a query within a React component, call `useViewMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewMerchantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewMerchantQuery(baseOptions: Apollo.QueryHookOptions<ViewMerchantQuery, ViewMerchantQueryVariables>) {
        return Apollo.useQuery<ViewMerchantQuery, ViewMerchantQueryVariables>(ViewMerchantDocument, baseOptions);
      }
export function useViewMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewMerchantQuery, ViewMerchantQueryVariables>) {
          return Apollo.useLazyQuery<ViewMerchantQuery, ViewMerchantQueryVariables>(ViewMerchantDocument, baseOptions);
        }
export type ViewMerchantQueryHookResult = ReturnType<typeof useViewMerchantQuery>;
export type ViewMerchantLazyQueryHookResult = ReturnType<typeof useViewMerchantLazyQuery>;
export type ViewMerchantQueryResult = Apollo.QueryResult<ViewMerchantQuery, ViewMerchantQueryVariables>;
export const MerchantLinksDocument = gql`
    query MerchantLinks($id: ID!) {
  merchant(id: $id) {
    deepLinks {
      ...MerchantDeepLinkInfo
    }
  }
}
    ${MerchantDeepLinkInfoFragmentDoc}`;

/**
 * __useMerchantLinksQuery__
 *
 * To run a query within a React component, call `useMerchantLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantLinksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantLinksQuery(baseOptions: Apollo.QueryHookOptions<MerchantLinksQuery, MerchantLinksQueryVariables>) {
        return Apollo.useQuery<MerchantLinksQuery, MerchantLinksQueryVariables>(MerchantLinksDocument, baseOptions);
      }
export function useMerchantLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantLinksQuery, MerchantLinksQueryVariables>) {
          return Apollo.useLazyQuery<MerchantLinksQuery, MerchantLinksQueryVariables>(MerchantLinksDocument, baseOptions);
        }
export type MerchantLinksQueryHookResult = ReturnType<typeof useMerchantLinksQuery>;
export type MerchantLinksLazyQueryHookResult = ReturnType<typeof useMerchantLinksLazyQuery>;
export type MerchantLinksQueryResult = Apollo.QueryResult<MerchantLinksQuery, MerchantLinksQueryVariables>;
export const MerchantReadableIdDocument = gql`
    query MerchantReadableId($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    readableId
  }
}
    `;

/**
 * __useMerchantReadableIdQuery__
 *
 * To run a query within a React component, call `useMerchantReadableIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantReadableIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantReadableIdQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantReadableIdQuery(baseOptions: Apollo.QueryHookOptions<MerchantReadableIdQuery, MerchantReadableIdQueryVariables>) {
        return Apollo.useQuery<MerchantReadableIdQuery, MerchantReadableIdQueryVariables>(MerchantReadableIdDocument, baseOptions);
      }
export function useMerchantReadableIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantReadableIdQuery, MerchantReadableIdQueryVariables>) {
          return Apollo.useLazyQuery<MerchantReadableIdQuery, MerchantReadableIdQueryVariables>(MerchantReadableIdDocument, baseOptions);
        }
export type MerchantReadableIdQueryHookResult = ReturnType<typeof useMerchantReadableIdQuery>;
export type MerchantReadableIdLazyQueryHookResult = ReturnType<typeof useMerchantReadableIdLazyQuery>;
export type MerchantReadableIdQueryResult = Apollo.QueryResult<MerchantReadableIdQuery, MerchantReadableIdQueryVariables>;
export const UpdateMerchantReadableIdDocument = gql`
    mutation UpdateMerchantReadableId($merchantId: ID!, $readableId: String!) {
  updateMerchantReadableId(merchantId: $merchantId, readableId: $readableId) {
    id
    readableId
  }
}
    `;
export type UpdateMerchantReadableIdMutationFn = Apollo.MutationFunction<UpdateMerchantReadableIdMutation, UpdateMerchantReadableIdMutationVariables>;

/**
 * __useUpdateMerchantReadableIdMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantReadableIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantReadableIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantReadableIdMutation, { data, loading, error }] = useUpdateMerchantReadableIdMutation({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      readableId: // value for 'readableId'
 *   },
 * });
 */
export function useUpdateMerchantReadableIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantReadableIdMutation, UpdateMerchantReadableIdMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantReadableIdMutation, UpdateMerchantReadableIdMutationVariables>(UpdateMerchantReadableIdDocument, baseOptions);
      }
export type UpdateMerchantReadableIdMutationHookResult = ReturnType<typeof useUpdateMerchantReadableIdMutation>;
export type UpdateMerchantReadableIdMutationResult = Apollo.MutationResult<UpdateMerchantReadableIdMutation>;
export type UpdateMerchantReadableIdMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantReadableIdMutation, UpdateMerchantReadableIdMutationVariables>;
export const MerchantForSyncDocument = gql`
    query MerchantForSync($id: ID!) {
  merchant(id: $id) {
    id
    name
    active
    organization {
      id
      name
    }
    freshLocation {
      locationId
      lastSyncCompleted
    }
  }
}
    `;

/**
 * __useMerchantForSyncQuery__
 *
 * To run a query within a React component, call `useMerchantForSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantForSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantForSyncQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantForSyncQuery(baseOptions: Apollo.QueryHookOptions<MerchantForSyncQuery, MerchantForSyncQueryVariables>) {
        return Apollo.useQuery<MerchantForSyncQuery, MerchantForSyncQueryVariables>(MerchantForSyncDocument, baseOptions);
      }
export function useMerchantForSyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantForSyncQuery, MerchantForSyncQueryVariables>) {
          return Apollo.useLazyQuery<MerchantForSyncQuery, MerchantForSyncQueryVariables>(MerchantForSyncDocument, baseOptions);
        }
export type MerchantForSyncQueryHookResult = ReturnType<typeof useMerchantForSyncQuery>;
export type MerchantForSyncLazyQueryHookResult = ReturnType<typeof useMerchantForSyncLazyQuery>;
export type MerchantForSyncQueryResult = Apollo.QueryResult<MerchantForSyncQuery, MerchantForSyncQueryVariables>;
export const SyncFreshMenuDocument = gql`
    mutation syncFreshMenu($merchantId: ID!, $brandId: String!, $token: String!) {
  syncFreshMenu(merchantId: $merchantId, brandId: $brandId, token: $token)
}
    `;
export type SyncFreshMenuMutationFn = Apollo.MutationFunction<SyncFreshMenuMutation, SyncFreshMenuMutationVariables>;

/**
 * __useSyncFreshMenuMutation__
 *
 * To run a mutation, you first call `useSyncFreshMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncFreshMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncFreshMenuMutation, { data, loading, error }] = useSyncFreshMenuMutation({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      brandId: // value for 'brandId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSyncFreshMenuMutation(baseOptions?: Apollo.MutationHookOptions<SyncFreshMenuMutation, SyncFreshMenuMutationVariables>) {
        return Apollo.useMutation<SyncFreshMenuMutation, SyncFreshMenuMutationVariables>(SyncFreshMenuDocument, baseOptions);
      }
export type SyncFreshMenuMutationHookResult = ReturnType<typeof useSyncFreshMenuMutation>;
export type SyncFreshMenuMutationResult = Apollo.MutationResult<SyncFreshMenuMutation>;
export type SyncFreshMenuMutationOptions = Apollo.BaseMutationOptions<SyncFreshMenuMutation, SyncFreshMenuMutationVariables>;
export const CreateMerchantContactDocument = gql`
    mutation CreateMerchantContact($input: CreateMerchantContactInput!) {
  createMerchantContact(input: $input) {
    ...MerchantContactDetails
  }
}
    ${MerchantContactDetailsFragmentDoc}`;
export type CreateMerchantContactMutationFn = Apollo.MutationFunction<CreateMerchantContactMutation, CreateMerchantContactMutationVariables>;

/**
 * __useCreateMerchantContactMutation__
 *
 * To run a mutation, you first call `useCreateMerchantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantContactMutation, { data, loading, error }] = useCreateMerchantContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMerchantContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchantContactMutation, CreateMerchantContactMutationVariables>) {
        return Apollo.useMutation<CreateMerchantContactMutation, CreateMerchantContactMutationVariables>(CreateMerchantContactDocument, baseOptions);
      }
export type CreateMerchantContactMutationHookResult = ReturnType<typeof useCreateMerchantContactMutation>;
export type CreateMerchantContactMutationResult = Apollo.MutationResult<CreateMerchantContactMutation>;
export type CreateMerchantContactMutationOptions = Apollo.BaseMutationOptions<CreateMerchantContactMutation, CreateMerchantContactMutationVariables>;
export const DeleteMerchantContactDocument = gql`
    mutation DeleteMerchantContact($id: ID!) {
  deleteMerchantContact(id: $id)
}
    `;
export type DeleteMerchantContactMutationFn = Apollo.MutationFunction<DeleteMerchantContactMutation, DeleteMerchantContactMutationVariables>;

/**
 * __useDeleteMerchantContactMutation__
 *
 * To run a mutation, you first call `useDeleteMerchantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMerchantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMerchantContactMutation, { data, loading, error }] = useDeleteMerchantContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMerchantContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMerchantContactMutation, DeleteMerchantContactMutationVariables>) {
        return Apollo.useMutation<DeleteMerchantContactMutation, DeleteMerchantContactMutationVariables>(DeleteMerchantContactDocument, baseOptions);
      }
export type DeleteMerchantContactMutationHookResult = ReturnType<typeof useDeleteMerchantContactMutation>;
export type DeleteMerchantContactMutationResult = Apollo.MutationResult<DeleteMerchantContactMutation>;
export type DeleteMerchantContactMutationOptions = Apollo.BaseMutationOptions<DeleteMerchantContactMutation, DeleteMerchantContactMutationVariables>;
export const MerchantContactDetailsDocument = gql`
    query MerchantContactDetails($id: ID!) {
  merchantContact(id: $id) {
    ...MerchantContactDetails
  }
}
    ${MerchantContactDetailsFragmentDoc}`;

/**
 * __useMerchantContactDetailsQuery__
 *
 * To run a query within a React component, call `useMerchantContactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantContactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantContactDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantContactDetailsQuery(baseOptions: Apollo.QueryHookOptions<MerchantContactDetailsQuery, MerchantContactDetailsQueryVariables>) {
        return Apollo.useQuery<MerchantContactDetailsQuery, MerchantContactDetailsQueryVariables>(MerchantContactDetailsDocument, baseOptions);
      }
export function useMerchantContactDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantContactDetailsQuery, MerchantContactDetailsQueryVariables>) {
          return Apollo.useLazyQuery<MerchantContactDetailsQuery, MerchantContactDetailsQueryVariables>(MerchantContactDetailsDocument, baseOptions);
        }
export type MerchantContactDetailsQueryHookResult = ReturnType<typeof useMerchantContactDetailsQuery>;
export type MerchantContactDetailsLazyQueryHookResult = ReturnType<typeof useMerchantContactDetailsLazyQuery>;
export type MerchantContactDetailsQueryResult = Apollo.QueryResult<MerchantContactDetailsQuery, MerchantContactDetailsQueryVariables>;
export const UpdateMerchantContactDocument = gql`
    mutation UpdateMerchantContact($input: UpdateMerchantContactInput!) {
  updateMerchantContact(input: $input) {
    ...MerchantContactDetails
  }
}
    ${MerchantContactDetailsFragmentDoc}`;
export type UpdateMerchantContactMutationFn = Apollo.MutationFunction<UpdateMerchantContactMutation, UpdateMerchantContactMutationVariables>;

/**
 * __useUpdateMerchantContactMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantContactMutation, { data, loading, error }] = useUpdateMerchantContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMerchantContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantContactMutation, UpdateMerchantContactMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantContactMutation, UpdateMerchantContactMutationVariables>(UpdateMerchantContactDocument, baseOptions);
      }
export type UpdateMerchantContactMutationHookResult = ReturnType<typeof useUpdateMerchantContactMutation>;
export type UpdateMerchantContactMutationResult = Apollo.MutationResult<UpdateMerchantContactMutation>;
export type UpdateMerchantContactMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantContactMutation, UpdateMerchantContactMutationVariables>;
export const DeleteMerchantImageDocument = gql`
    mutation DeleteMerchantImage($id: ID!) {
  deleteMerchantImage(id: $id)
}
    `;
export type DeleteMerchantImageMutationFn = Apollo.MutationFunction<DeleteMerchantImageMutation, DeleteMerchantImageMutationVariables>;

/**
 * __useDeleteMerchantImageMutation__
 *
 * To run a mutation, you first call `useDeleteMerchantImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMerchantImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMerchantImageMutation, { data, loading, error }] = useDeleteMerchantImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMerchantImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMerchantImageMutation, DeleteMerchantImageMutationVariables>) {
        return Apollo.useMutation<DeleteMerchantImageMutation, DeleteMerchantImageMutationVariables>(DeleteMerchantImageDocument, baseOptions);
      }
export type DeleteMerchantImageMutationHookResult = ReturnType<typeof useDeleteMerchantImageMutation>;
export type DeleteMerchantImageMutationResult = Apollo.MutationResult<DeleteMerchantImageMutation>;
export type DeleteMerchantImageMutationOptions = Apollo.BaseMutationOptions<DeleteMerchantImageMutation, DeleteMerchantImageMutationVariables>;
export const MerchantImagesByMerchantDocument = gql`
    query MerchantImagesByMerchant($merchantId: ID!) {
  merchantImagesByMerchant(merchantId: $merchantId) {
    ...MerchantImageDetails
  }
}
    ${MerchantImageDetailsFragmentDoc}`;

/**
 * __useMerchantImagesByMerchantQuery__
 *
 * To run a query within a React component, call `useMerchantImagesByMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantImagesByMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantImagesByMerchantQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantImagesByMerchantQuery(baseOptions: Apollo.QueryHookOptions<MerchantImagesByMerchantQuery, MerchantImagesByMerchantQueryVariables>) {
        return Apollo.useQuery<MerchantImagesByMerchantQuery, MerchantImagesByMerchantQueryVariables>(MerchantImagesByMerchantDocument, baseOptions);
      }
export function useMerchantImagesByMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantImagesByMerchantQuery, MerchantImagesByMerchantQueryVariables>) {
          return Apollo.useLazyQuery<MerchantImagesByMerchantQuery, MerchantImagesByMerchantQueryVariables>(MerchantImagesByMerchantDocument, baseOptions);
        }
export type MerchantImagesByMerchantQueryHookResult = ReturnType<typeof useMerchantImagesByMerchantQuery>;
export type MerchantImagesByMerchantLazyQueryHookResult = ReturnType<typeof useMerchantImagesByMerchantLazyQuery>;
export type MerchantImagesByMerchantQueryResult = Apollo.QueryResult<MerchantImagesByMerchantQuery, MerchantImagesByMerchantQueryVariables>;
export const UpdateMerchantImagesDocument = gql`
    mutation UpdateMerchantImages($input: [MerchantImageInput!], $merchantId: ID!) {
  updateMerchantImages(input: $input, merchantId: $merchantId) {
    ...MerchantImageDetails
  }
}
    ${MerchantImageDetailsFragmentDoc}`;
export type UpdateMerchantImagesMutationFn = Apollo.MutationFunction<UpdateMerchantImagesMutation, UpdateMerchantImagesMutationVariables>;

/**
 * __useUpdateMerchantImagesMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantImagesMutation, { data, loading, error }] = useUpdateMerchantImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useUpdateMerchantImagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantImagesMutation, UpdateMerchantImagesMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantImagesMutation, UpdateMerchantImagesMutationVariables>(UpdateMerchantImagesDocument, baseOptions);
      }
export type UpdateMerchantImagesMutationHookResult = ReturnType<typeof useUpdateMerchantImagesMutation>;
export type UpdateMerchantImagesMutationResult = Apollo.MutationResult<UpdateMerchantImagesMutation>;
export type UpdateMerchantImagesMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantImagesMutation, UpdateMerchantImagesMutationVariables>;
export const MerchantPromotionQueryDocument = gql`
    query MerchantPromotionQuery($id: ID!) {
  promotion(id: $id) {
    ...MerchantPromotionInfo
  }
}
    ${MerchantPromotionInfoFragmentDoc}`;

/**
 * __useMerchantPromotionQueryQuery__
 *
 * To run a query within a React component, call `useMerchantPromotionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantPromotionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantPromotionQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantPromotionQueryQuery(baseOptions: Apollo.QueryHookOptions<MerchantPromotionQueryQuery, MerchantPromotionQueryQueryVariables>) {
        return Apollo.useQuery<MerchantPromotionQueryQuery, MerchantPromotionQueryQueryVariables>(MerchantPromotionQueryDocument, baseOptions);
      }
export function useMerchantPromotionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantPromotionQueryQuery, MerchantPromotionQueryQueryVariables>) {
          return Apollo.useLazyQuery<MerchantPromotionQueryQuery, MerchantPromotionQueryQueryVariables>(MerchantPromotionQueryDocument, baseOptions);
        }
export type MerchantPromotionQueryQueryHookResult = ReturnType<typeof useMerchantPromotionQueryQuery>;
export type MerchantPromotionQueryLazyQueryHookResult = ReturnType<typeof useMerchantPromotionQueryLazyQuery>;
export type MerchantPromotionQueryQueryResult = Apollo.QueryResult<MerchantPromotionQueryQuery, MerchantPromotionQueryQueryVariables>;
export const PromotionsByMerchantDocument = gql`
    query PromotionsByMerchant($merchantId: ID!) {
  promotionsByMerchant(merchantId: $merchantId) {
    ...MerchantPromotionInfo
  }
}
    ${MerchantPromotionInfoFragmentDoc}`;

/**
 * __usePromotionsByMerchantQuery__
 *
 * To run a query within a React component, call `usePromotionsByMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionsByMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionsByMerchantQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function usePromotionsByMerchantQuery(baseOptions: Apollo.QueryHookOptions<PromotionsByMerchantQuery, PromotionsByMerchantQueryVariables>) {
        return Apollo.useQuery<PromotionsByMerchantQuery, PromotionsByMerchantQueryVariables>(PromotionsByMerchantDocument, baseOptions);
      }
export function usePromotionsByMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionsByMerchantQuery, PromotionsByMerchantQueryVariables>) {
          return Apollo.useLazyQuery<PromotionsByMerchantQuery, PromotionsByMerchantQueryVariables>(PromotionsByMerchantDocument, baseOptions);
        }
export type PromotionsByMerchantQueryHookResult = ReturnType<typeof usePromotionsByMerchantQuery>;
export type PromotionsByMerchantLazyQueryHookResult = ReturnType<typeof usePromotionsByMerchantLazyQuery>;
export type PromotionsByMerchantQueryResult = Apollo.QueryResult<PromotionsByMerchantQuery, PromotionsByMerchantQueryVariables>;
export const MerchantSettingsDocument = gql`
    query MerchantSettings($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    settings {
      name
      description
      category
      value
      valueType
    }
  }
}
    `;

/**
 * __useMerchantSettingsQuery__
 *
 * To run a query within a React component, call `useMerchantSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSettingsQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantSettingsQuery(baseOptions: Apollo.QueryHookOptions<MerchantSettingsQuery, MerchantSettingsQueryVariables>) {
        return Apollo.useQuery<MerchantSettingsQuery, MerchantSettingsQueryVariables>(MerchantSettingsDocument, baseOptions);
      }
export function useMerchantSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSettingsQuery, MerchantSettingsQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSettingsQuery, MerchantSettingsQueryVariables>(MerchantSettingsDocument, baseOptions);
        }
export type MerchantSettingsQueryHookResult = ReturnType<typeof useMerchantSettingsQuery>;
export type MerchantSettingsLazyQueryHookResult = ReturnType<typeof useMerchantSettingsLazyQuery>;
export type MerchantSettingsQueryResult = Apollo.QueryResult<MerchantSettingsQuery, MerchantSettingsQueryVariables>;
export const UpdateMerchantBooleanSettingDocument = gql`
    mutation UpdateMerchantBooleanSetting($merchantId: ID!, $name: String!, $value: Boolean!) {
  updateMerchantBooleanSetting(
    merchantId: $merchantId
    name: $name
    value: $value
  ) {
    name
    description
    value
    valueType
    category
  }
}
    `;
export type UpdateMerchantBooleanSettingMutationFn = Apollo.MutationFunction<UpdateMerchantBooleanSettingMutation, UpdateMerchantBooleanSettingMutationVariables>;

/**
 * __useUpdateMerchantBooleanSettingMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantBooleanSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantBooleanSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantBooleanSettingMutation, { data, loading, error }] = useUpdateMerchantBooleanSettingMutation({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateMerchantBooleanSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantBooleanSettingMutation, UpdateMerchantBooleanSettingMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantBooleanSettingMutation, UpdateMerchantBooleanSettingMutationVariables>(UpdateMerchantBooleanSettingDocument, baseOptions);
      }
export type UpdateMerchantBooleanSettingMutationHookResult = ReturnType<typeof useUpdateMerchantBooleanSettingMutation>;
export type UpdateMerchantBooleanSettingMutationResult = Apollo.MutationResult<UpdateMerchantBooleanSettingMutation>;
export type UpdateMerchantBooleanSettingMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantBooleanSettingMutation, UpdateMerchantBooleanSettingMutationVariables>;
export const CancelStripeSubscriptionDocument = gql`
    mutation CancelStripeSubscription($stripeSubscriptionId: ID!) {
  cancelStripeSubscription(stripeSubscriptionId: $stripeSubscriptionId) {
    id
    subscriptionPlan {
      id
      name
      costUsCents
    }
    merchant {
      id
      stripeCustomerId
    }
    stripeSubscription {
      stripeSubscriptionId
      stripeCustomerId
    }
  }
}
    `;
export type CancelStripeSubscriptionMutationFn = Apollo.MutationFunction<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>;

/**
 * __useCancelStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStripeSubscriptionMutation, { data, loading, error }] = useCancelStripeSubscriptionMutation({
 *   variables: {
 *      stripeSubscriptionId: // value for 'stripeSubscriptionId'
 *   },
 * });
 */
export function useCancelStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>) {
        return Apollo.useMutation<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>(CancelStripeSubscriptionDocument, baseOptions);
      }
export type CancelStripeSubscriptionMutationHookResult = ReturnType<typeof useCancelStripeSubscriptionMutation>;
export type CancelStripeSubscriptionMutationResult = Apollo.MutationResult<CancelStripeSubscriptionMutation>;
export type CancelStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>;
export const CreateStripeSubscriptionDocument = gql`
    mutation CreateStripeSubscription($merchantIds: [ID!]!, $organizationManaged: Boolean!) {
  createStripeSubscription(
    merchantIds: $merchantIds
    organizationManaged: $organizationManaged
  ) {
    clientSecret
    costUsCents
    lineItems {
      costUsCents
      name
    }
    merchantSubscriptions {
      id
      subscriptionPlan {
        id
        name
        costUsCents
      }
      merchant {
        id
        stripeCustomerId
      }
      stripeSubscription {
        stripeSubscriptionId
        stripeCustomerId
      }
    }
  }
}
    `;
export type CreateStripeSubscriptionMutationFn = Apollo.MutationFunction<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>;

/**
 * __useCreateStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSubscriptionMutation, { data, loading, error }] = useCreateStripeSubscriptionMutation({
 *   variables: {
 *      merchantIds: // value for 'merchantIds'
 *      organizationManaged: // value for 'organizationManaged'
 *   },
 * });
 */
export function useCreateStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>) {
        return Apollo.useMutation<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>(CreateStripeSubscriptionDocument, baseOptions);
      }
export type CreateStripeSubscriptionMutationHookResult = ReturnType<typeof useCreateStripeSubscriptionMutation>;
export type CreateStripeSubscriptionMutationResult = Apollo.MutationResult<CreateStripeSubscriptionMutation>;
export type CreateStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>;
export const MerchantInvoicesDocument = gql`
    query merchantInvoices($merchantId: ID!) {
  merchantInvoices(merchantId: $merchantId) {
    subscriptionInvoiceLines {
      costUsCents
      quantity
      subscriptionProduct {
        name
        recurringInterval
      }
    }
    status
    costUsCents
    createdOn
    finalizedOn
    paidOn
  }
}
    `;

/**
 * __useMerchantInvoicesQuery__
 *
 * To run a query within a React component, call `useMerchantInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantInvoicesQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantInvoicesQuery(baseOptions: Apollo.QueryHookOptions<MerchantInvoicesQuery, MerchantInvoicesQueryVariables>) {
        return Apollo.useQuery<MerchantInvoicesQuery, MerchantInvoicesQueryVariables>(MerchantInvoicesDocument, baseOptions);
      }
export function useMerchantInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantInvoicesQuery, MerchantInvoicesQueryVariables>) {
          return Apollo.useLazyQuery<MerchantInvoicesQuery, MerchantInvoicesQueryVariables>(MerchantInvoicesDocument, baseOptions);
        }
export type MerchantInvoicesQueryHookResult = ReturnType<typeof useMerchantInvoicesQuery>;
export type MerchantInvoicesLazyQueryHookResult = ReturnType<typeof useMerchantInvoicesLazyQuery>;
export type MerchantInvoicesQueryResult = Apollo.QueryResult<MerchantInvoicesQuery, MerchantInvoicesQueryVariables>;
export const MerchantSubscriptionByMerchantIdDocument = gql`
    query MerchantSubscriptionByMerchantId($merchantId: ID!) {
  merchantSubscriptionByMerchantId(merchantId: $merchantId) {
    id
    trialEndDate
    organizationManaged
    subscriptionStatus
    subscriptionPlan {
      id
      name
      costUsCents
      recurringInterval
    }
    merchant {
      id
      name
      stripeCustomerId
    }
    stripeSubscription {
      id
      stripeSubscriptionId
      subscriptionStatus
      stripeCustomerId
    }
    platformPricing {
      id
      name
      createdOn
      instantPriceUsCents
      platformPricingType
      subscriptionTerm
      subscriptionProduct {
        id
        costUsCents
        name
        recurringInterval
      }
    }
    termPricing
  }
}
    `;

/**
 * __useMerchantSubscriptionByMerchantIdQuery__
 *
 * To run a query within a React component, call `useMerchantSubscriptionByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSubscriptionByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSubscriptionByMerchantIdQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantSubscriptionByMerchantIdQuery(baseOptions: Apollo.QueryHookOptions<MerchantSubscriptionByMerchantIdQuery, MerchantSubscriptionByMerchantIdQueryVariables>) {
        return Apollo.useQuery<MerchantSubscriptionByMerchantIdQuery, MerchantSubscriptionByMerchantIdQueryVariables>(MerchantSubscriptionByMerchantIdDocument, baseOptions);
      }
export function useMerchantSubscriptionByMerchantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSubscriptionByMerchantIdQuery, MerchantSubscriptionByMerchantIdQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSubscriptionByMerchantIdQuery, MerchantSubscriptionByMerchantIdQueryVariables>(MerchantSubscriptionByMerchantIdDocument, baseOptions);
        }
export type MerchantSubscriptionByMerchantIdQueryHookResult = ReturnType<typeof useMerchantSubscriptionByMerchantIdQuery>;
export type MerchantSubscriptionByMerchantIdLazyQueryHookResult = ReturnType<typeof useMerchantSubscriptionByMerchantIdLazyQuery>;
export type MerchantSubscriptionByMerchantIdQueryResult = Apollo.QueryResult<MerchantSubscriptionByMerchantIdQuery, MerchantSubscriptionByMerchantIdQueryVariables>;
export const MerchantSubscriptionFeesByMerchantIdDocument = gql`
    query MerchantSubscriptionFeesByMerchantId($merchantId: ID!) {
  merchantSubscriptionFeesByMerchantId(merchantId: $merchantId) {
    id
    subscriptionFee {
      id
      name
      costUsCents
    }
  }
}
    `;

/**
 * __useMerchantSubscriptionFeesByMerchantIdQuery__
 *
 * To run a query within a React component, call `useMerchantSubscriptionFeesByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSubscriptionFeesByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSubscriptionFeesByMerchantIdQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantSubscriptionFeesByMerchantIdQuery(baseOptions: Apollo.QueryHookOptions<MerchantSubscriptionFeesByMerchantIdQuery, MerchantSubscriptionFeesByMerchantIdQueryVariables>) {
        return Apollo.useQuery<MerchantSubscriptionFeesByMerchantIdQuery, MerchantSubscriptionFeesByMerchantIdQueryVariables>(MerchantSubscriptionFeesByMerchantIdDocument, baseOptions);
      }
export function useMerchantSubscriptionFeesByMerchantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSubscriptionFeesByMerchantIdQuery, MerchantSubscriptionFeesByMerchantIdQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSubscriptionFeesByMerchantIdQuery, MerchantSubscriptionFeesByMerchantIdQueryVariables>(MerchantSubscriptionFeesByMerchantIdDocument, baseOptions);
        }
export type MerchantSubscriptionFeesByMerchantIdQueryHookResult = ReturnType<typeof useMerchantSubscriptionFeesByMerchantIdQuery>;
export type MerchantSubscriptionFeesByMerchantIdLazyQueryHookResult = ReturnType<typeof useMerchantSubscriptionFeesByMerchantIdLazyQuery>;
export type MerchantSubscriptionFeesByMerchantIdQueryResult = Apollo.QueryResult<MerchantSubscriptionFeesByMerchantIdQuery, MerchantSubscriptionFeesByMerchantIdQueryVariables>;
export const OrderDetailsQueryDocument = gql`
    query OrderDetailsQuery($id: ID!) {
  order(id: $id) {
    id
    createdOn
    timerTriggerAt
    addedToQueueAt
    items {
      id
      name
      state
      priceCents
      priceTokens
      subtotalChargeUsCents
      subtotalChargeTokens
      refunded
      selectedModifiers {
        id
        name
        priceCents
        originalModifier {
          id
          name
          selectedName
          printableName
        }
        originalTopModifier {
          name
        }
      }
      events {
        id
        name
      }
      orderableProduct {
        id
        name
        printableName
        roaster
        category {
          type
        }
      }
    }
    transactions {
      id
      createdOn
      tokenValue
      subtotalUsCents
      feeUsCents
      taxUsCents
      tipUsCents
      discountUsCents
      tokenTipUsCents
      promotions {
        id
      }
      merchantFeeUsCents
      merchantTokenFeeUsCents
      merchantDiscountFeeUsCents
      stripePaymentUrl
      tokenValueUsCents
      refunds {
        id
        initializedOn
        stripeId
        amountUsCents
        tokenValue
      }
    }
    user {
      id
      profile {
        id
        firstName
        lastName
      }
    }
    events {
      id
      name
      createdOn
    }
    diningOption
  }
}
    `;

/**
 * __useOrderDetailsQueryQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderDetailsQueryQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailsQueryQuery, OrderDetailsQueryQueryVariables>) {
        return Apollo.useQuery<OrderDetailsQueryQuery, OrderDetailsQueryQueryVariables>(OrderDetailsQueryDocument, baseOptions);
      }
export function useOrderDetailsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsQueryQuery, OrderDetailsQueryQueryVariables>) {
          return Apollo.useLazyQuery<OrderDetailsQueryQuery, OrderDetailsQueryQueryVariables>(OrderDetailsQueryDocument, baseOptions);
        }
export type OrderDetailsQueryQueryHookResult = ReturnType<typeof useOrderDetailsQueryQuery>;
export type OrderDetailsQueryLazyQueryHookResult = ReturnType<typeof useOrderDetailsQueryLazyQuery>;
export type OrderDetailsQueryQueryResult = Apollo.QueryResult<OrderDetailsQueryQuery, OrderDetailsQueryQueryVariables>;
export const RefundOrderItemDocument = gql`
    mutation RefundOrderItem($id: ID!) {
  refundOrderItem(id: $id) {
    id
    initializedOn
    stripeId
    amountUsCents
    tokenValue
  }
}
    `;
export type RefundOrderItemMutationFn = Apollo.MutationFunction<RefundOrderItemMutation, RefundOrderItemMutationVariables>;

/**
 * __useRefundOrderItemMutation__
 *
 * To run a mutation, you first call `useRefundOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrderItemMutation, { data, loading, error }] = useRefundOrderItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<RefundOrderItemMutation, RefundOrderItemMutationVariables>) {
        return Apollo.useMutation<RefundOrderItemMutation, RefundOrderItemMutationVariables>(RefundOrderItemDocument, baseOptions);
      }
export type RefundOrderItemMutationHookResult = ReturnType<typeof useRefundOrderItemMutation>;
export type RefundOrderItemMutationResult = Apollo.MutationResult<RefundOrderItemMutation>;
export type RefundOrderItemMutationOptions = Apollo.BaseMutationOptions<RefundOrderItemMutation, RefundOrderItemMutationVariables>;
export const RefundOrderDocument = gql`
    mutation RefundOrder($id: ID!) {
  refundOrder(id: $id) {
    id
    initializedOn
    stripeId
    amountUsCents
    tokenValue
  }
}
    `;
export type RefundOrderMutationFn = Apollo.MutationFunction<RefundOrderMutation, RefundOrderMutationVariables>;

/**
 * __useRefundOrderMutation__
 *
 * To run a mutation, you first call `useRefundOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrderMutation, { data, loading, error }] = useRefundOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundOrderMutation(baseOptions?: Apollo.MutationHookOptions<RefundOrderMutation, RefundOrderMutationVariables>) {
        return Apollo.useMutation<RefundOrderMutation, RefundOrderMutationVariables>(RefundOrderDocument, baseOptions);
      }
export type RefundOrderMutationHookResult = ReturnType<typeof useRefundOrderMutation>;
export type RefundOrderMutationResult = Apollo.MutationResult<RefundOrderMutation>;
export type RefundOrderMutationOptions = Apollo.BaseMutationOptions<RefundOrderMutation, RefundOrderMutationVariables>;
export const ArchiveFulfillmentLocationDocument = gql`
    mutation ArchiveFulfillmentLocation($fulfillmentLocationId: ID!) {
  archiveFulfillmentLocation(fulfillmentLocationId: $fulfillmentLocationId)
}
    `;
export type ArchiveFulfillmentLocationMutationFn = Apollo.MutationFunction<ArchiveFulfillmentLocationMutation, ArchiveFulfillmentLocationMutationVariables>;

/**
 * __useArchiveFulfillmentLocationMutation__
 *
 * To run a mutation, you first call `useArchiveFulfillmentLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveFulfillmentLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveFulfillmentLocationMutation, { data, loading, error }] = useArchiveFulfillmentLocationMutation({
 *   variables: {
 *      fulfillmentLocationId: // value for 'fulfillmentLocationId'
 *   },
 * });
 */
export function useArchiveFulfillmentLocationMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveFulfillmentLocationMutation, ArchiveFulfillmentLocationMutationVariables>) {
        return Apollo.useMutation<ArchiveFulfillmentLocationMutation, ArchiveFulfillmentLocationMutationVariables>(ArchiveFulfillmentLocationDocument, baseOptions);
      }
export type ArchiveFulfillmentLocationMutationHookResult = ReturnType<typeof useArchiveFulfillmentLocationMutation>;
export type ArchiveFulfillmentLocationMutationResult = Apollo.MutationResult<ArchiveFulfillmentLocationMutation>;
export type ArchiveFulfillmentLocationMutationOptions = Apollo.BaseMutationOptions<ArchiveFulfillmentLocationMutation, ArchiveFulfillmentLocationMutationVariables>;
export const CreateFulfillmentLocationDocument = gql`
    mutation CreateFulfillmentLocation($input: CreateFulfillmentLocationInput!) {
  createFulfillmentLocation(input: $input) {
    id
    displayName
    urlSafeName
    appUrl
    webUrl
    fulfillmentLocationType
    qrCodeDisplayUrl
  }
}
    `;
export type CreateFulfillmentLocationMutationFn = Apollo.MutationFunction<CreateFulfillmentLocationMutation, CreateFulfillmentLocationMutationVariables>;

/**
 * __useCreateFulfillmentLocationMutation__
 *
 * To run a mutation, you first call `useCreateFulfillmentLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFulfillmentLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFulfillmentLocationMutation, { data, loading, error }] = useCreateFulfillmentLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFulfillmentLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateFulfillmentLocationMutation, CreateFulfillmentLocationMutationVariables>) {
        return Apollo.useMutation<CreateFulfillmentLocationMutation, CreateFulfillmentLocationMutationVariables>(CreateFulfillmentLocationDocument, baseOptions);
      }
export type CreateFulfillmentLocationMutationHookResult = ReturnType<typeof useCreateFulfillmentLocationMutation>;
export type CreateFulfillmentLocationMutationResult = Apollo.MutationResult<CreateFulfillmentLocationMutation>;
export type CreateFulfillmentLocationMutationOptions = Apollo.BaseMutationOptions<CreateFulfillmentLocationMutation, CreateFulfillmentLocationMutationVariables>;
export const GenerateQrCodeDisplayDocument = gql`
    mutation GenerateQRCodeDisplay($fulfillmentLocationId: ID!) {
  generateQRCodeDisplay(fulfillmentLocationId: $fulfillmentLocationId)
}
    `;
export type GenerateQrCodeDisplayMutationFn = Apollo.MutationFunction<GenerateQrCodeDisplayMutation, GenerateQrCodeDisplayMutationVariables>;

/**
 * __useGenerateQrCodeDisplayMutation__
 *
 * To run a mutation, you first call `useGenerateQrCodeDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateQrCodeDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateQrCodeDisplayMutation, { data, loading, error }] = useGenerateQrCodeDisplayMutation({
 *   variables: {
 *      fulfillmentLocationId: // value for 'fulfillmentLocationId'
 *   },
 * });
 */
export function useGenerateQrCodeDisplayMutation(baseOptions?: Apollo.MutationHookOptions<GenerateQrCodeDisplayMutation, GenerateQrCodeDisplayMutationVariables>) {
        return Apollo.useMutation<GenerateQrCodeDisplayMutation, GenerateQrCodeDisplayMutationVariables>(GenerateQrCodeDisplayDocument, baseOptions);
      }
export type GenerateQrCodeDisplayMutationHookResult = ReturnType<typeof useGenerateQrCodeDisplayMutation>;
export type GenerateQrCodeDisplayMutationResult = Apollo.MutationResult<GenerateQrCodeDisplayMutation>;
export type GenerateQrCodeDisplayMutationOptions = Apollo.BaseMutationOptions<GenerateQrCodeDisplayMutation, GenerateQrCodeDisplayMutationVariables>;
export const GetServingLocationsByMerchantIdDocument = gql`
    query GetServingLocationsByMerchantId($merchantId: ID!) {
  getServingLocationsByMerchantId(merchantId: $merchantId) {
    id
    displayName
    urlSafeName
    appUrl
    webUrl
    qrCodeDisplayUrl
  }
}
    `;

/**
 * __useGetServingLocationsByMerchantIdQuery__
 *
 * To run a query within a React component, call `useGetServingLocationsByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServingLocationsByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServingLocationsByMerchantIdQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useGetServingLocationsByMerchantIdQuery(baseOptions: Apollo.QueryHookOptions<GetServingLocationsByMerchantIdQuery, GetServingLocationsByMerchantIdQueryVariables>) {
        return Apollo.useQuery<GetServingLocationsByMerchantIdQuery, GetServingLocationsByMerchantIdQueryVariables>(GetServingLocationsByMerchantIdDocument, baseOptions);
      }
export function useGetServingLocationsByMerchantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServingLocationsByMerchantIdQuery, GetServingLocationsByMerchantIdQueryVariables>) {
          return Apollo.useLazyQuery<GetServingLocationsByMerchantIdQuery, GetServingLocationsByMerchantIdQueryVariables>(GetServingLocationsByMerchantIdDocument, baseOptions);
        }
export type GetServingLocationsByMerchantIdQueryHookResult = ReturnType<typeof useGetServingLocationsByMerchantIdQuery>;
export type GetServingLocationsByMerchantIdLazyQueryHookResult = ReturnType<typeof useGetServingLocationsByMerchantIdLazyQuery>;
export type GetServingLocationsByMerchantIdQueryResult = Apollo.QueryResult<GetServingLocationsByMerchantIdQuery, GetServingLocationsByMerchantIdQueryVariables>;
export const SetMerchantFeeConfigForMerchantDocument = gql`
    mutation setMerchantFeeConfigForMerchant($merchantId: ID!, $merchantFeeConfigId: ID!) {
  setMerchantFeeConfigForMerchant(
    merchantId: $merchantId
    merchantFeeConfigId: $merchantFeeConfigId
  ) {
    id
    name
    merchantFeeConfig {
      id
      default
      staticFeeInUsCents
      percentageFee
    }
  }
}
    `;
export type SetMerchantFeeConfigForMerchantMutationFn = Apollo.MutationFunction<SetMerchantFeeConfigForMerchantMutation, SetMerchantFeeConfigForMerchantMutationVariables>;

/**
 * __useSetMerchantFeeConfigForMerchantMutation__
 *
 * To run a mutation, you first call `useSetMerchantFeeConfigForMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMerchantFeeConfigForMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMerchantFeeConfigForMerchantMutation, { data, loading, error }] = useSetMerchantFeeConfigForMerchantMutation({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      merchantFeeConfigId: // value for 'merchantFeeConfigId'
 *   },
 * });
 */
export function useSetMerchantFeeConfigForMerchantMutation(baseOptions?: Apollo.MutationHookOptions<SetMerchantFeeConfigForMerchantMutation, SetMerchantFeeConfigForMerchantMutationVariables>) {
        return Apollo.useMutation<SetMerchantFeeConfigForMerchantMutation, SetMerchantFeeConfigForMerchantMutationVariables>(SetMerchantFeeConfigForMerchantDocument, baseOptions);
      }
export type SetMerchantFeeConfigForMerchantMutationHookResult = ReturnType<typeof useSetMerchantFeeConfigForMerchantMutation>;
export type SetMerchantFeeConfigForMerchantMutationResult = Apollo.MutationResult<SetMerchantFeeConfigForMerchantMutation>;
export type SetMerchantFeeConfigForMerchantMutationOptions = Apollo.BaseMutationOptions<SetMerchantFeeConfigForMerchantMutation, SetMerchantFeeConfigForMerchantMutationVariables>;
export const CreatePlatformPricingPaymentIntentDocument = gql`
    mutation CreatePlatformPricingPaymentIntent($merchantIds: [ID!]!) {
  createPlatformPricingPaymentIntent(merchantIds: $merchantIds) {
    clientSecret
    costUsCents
    lineItems {
      costUsCents
      name
    }
    merchantSubscriptions {
      id
      subscriptionPlan {
        id
        name
        costUsCents
      }
      merchant {
        id
        stripeCustomerId
      }
      stripeSubscription {
        stripeSubscriptionId
        stripeCustomerId
      }
    }
  }
}
    `;
export type CreatePlatformPricingPaymentIntentMutationFn = Apollo.MutationFunction<CreatePlatformPricingPaymentIntentMutation, CreatePlatformPricingPaymentIntentMutationVariables>;

/**
 * __useCreatePlatformPricingPaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePlatformPricingPaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlatformPricingPaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlatformPricingPaymentIntentMutation, { data, loading, error }] = useCreatePlatformPricingPaymentIntentMutation({
 *   variables: {
 *      merchantIds: // value for 'merchantIds'
 *   },
 * });
 */
export function useCreatePlatformPricingPaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlatformPricingPaymentIntentMutation, CreatePlatformPricingPaymentIntentMutationVariables>) {
        return Apollo.useMutation<CreatePlatformPricingPaymentIntentMutation, CreatePlatformPricingPaymentIntentMutationVariables>(CreatePlatformPricingPaymentIntentDocument, baseOptions);
      }
export type CreatePlatformPricingPaymentIntentMutationHookResult = ReturnType<typeof useCreatePlatformPricingPaymentIntentMutation>;
export type CreatePlatformPricingPaymentIntentMutationResult = Apollo.MutationResult<CreatePlatformPricingPaymentIntentMutation>;
export type CreatePlatformPricingPaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreatePlatformPricingPaymentIntentMutation, CreatePlatformPricingPaymentIntentMutationVariables>;
export const ModifiersWithInventoryDocument = gql`
    query ModifiersWithInventory($ids: [ID!]!) {
  modifiers(ids: $ids) {
    id
    name
    active
    inventory {
      id
      inventoryType
      currentQuantity
    }
  }
}
    `;

/**
 * __useModifiersWithInventoryQuery__
 *
 * To run a query within a React component, call `useModifiersWithInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useModifiersWithInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModifiersWithInventoryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useModifiersWithInventoryQuery(baseOptions: Apollo.QueryHookOptions<ModifiersWithInventoryQuery, ModifiersWithInventoryQueryVariables>) {
        return Apollo.useQuery<ModifiersWithInventoryQuery, ModifiersWithInventoryQueryVariables>(ModifiersWithInventoryDocument, baseOptions);
      }
export function useModifiersWithInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModifiersWithInventoryQuery, ModifiersWithInventoryQueryVariables>) {
          return Apollo.useLazyQuery<ModifiersWithInventoryQuery, ModifiersWithInventoryQueryVariables>(ModifiersWithInventoryDocument, baseOptions);
        }
export type ModifiersWithInventoryQueryHookResult = ReturnType<typeof useModifiersWithInventoryQuery>;
export type ModifiersWithInventoryLazyQueryHookResult = ReturnType<typeof useModifiersWithInventoryLazyQuery>;
export type ModifiersWithInventoryQueryResult = Apollo.QueryResult<ModifiersWithInventoryQuery, ModifiersWithInventoryQueryVariables>;
export const ProductsWithModifiersDocument = gql`
    query ProductsWithModifiers($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    products {
      id
      name
      modifiers {
        id
      }
    }
  }
}
    `;

/**
 * __useProductsWithModifiersQuery__
 *
 * To run a query within a React component, call `useProductsWithModifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsWithModifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsWithModifiersQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useProductsWithModifiersQuery(baseOptions: Apollo.QueryHookOptions<ProductsWithModifiersQuery, ProductsWithModifiersQueryVariables>) {
        return Apollo.useQuery<ProductsWithModifiersQuery, ProductsWithModifiersQueryVariables>(ProductsWithModifiersDocument, baseOptions);
      }
export function useProductsWithModifiersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsWithModifiersQuery, ProductsWithModifiersQueryVariables>) {
          return Apollo.useLazyQuery<ProductsWithModifiersQuery, ProductsWithModifiersQueryVariables>(ProductsWithModifiersDocument, baseOptions);
        }
export type ProductsWithModifiersQueryHookResult = ReturnType<typeof useProductsWithModifiersQuery>;
export type ProductsWithModifiersLazyQueryHookResult = ReturnType<typeof useProductsWithModifiersLazyQuery>;
export type ProductsWithModifiersQueryResult = Apollo.QueryResult<ProductsWithModifiersQuery, ProductsWithModifiersQueryVariables>;
export const UpdateTopModifierUsageDocument = gql`
    mutation updateTopModifierUsage($input: UpdateModifierUsageInput!) {
  updateTopModifierUsage(input: $input) {
    id
    name
    modifiers {
      id
    }
  }
}
    `;
export type UpdateTopModifierUsageMutationFn = Apollo.MutationFunction<UpdateTopModifierUsageMutation, UpdateTopModifierUsageMutationVariables>;

/**
 * __useUpdateTopModifierUsageMutation__
 *
 * To run a mutation, you first call `useUpdateTopModifierUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopModifierUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopModifierUsageMutation, { data, loading, error }] = useUpdateTopModifierUsageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopModifierUsageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopModifierUsageMutation, UpdateTopModifierUsageMutationVariables>) {
        return Apollo.useMutation<UpdateTopModifierUsageMutation, UpdateTopModifierUsageMutationVariables>(UpdateTopModifierUsageDocument, baseOptions);
      }
export type UpdateTopModifierUsageMutationHookResult = ReturnType<typeof useUpdateTopModifierUsageMutation>;
export type UpdateTopModifierUsageMutationResult = Apollo.MutationResult<UpdateTopModifierUsageMutation>;
export type UpdateTopModifierUsageMutationOptions = Apollo.BaseMutationOptions<UpdateTopModifierUsageMutation, UpdateTopModifierUsageMutationVariables>;
export const ContractByContractTypeDocument = gql`
    query ContractByContractType($contractType: ContractType!) {
  contractByContractType(contractType: $contractType) {
    id
    content
    contractType
  }
}
    `;

/**
 * __useContractByContractTypeQuery__
 *
 * To run a query within a React component, call `useContractByContractTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractByContractTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractByContractTypeQuery({
 *   variables: {
 *      contractType: // value for 'contractType'
 *   },
 * });
 */
export function useContractByContractTypeQuery(baseOptions: Apollo.QueryHookOptions<ContractByContractTypeQuery, ContractByContractTypeQueryVariables>) {
        return Apollo.useQuery<ContractByContractTypeQuery, ContractByContractTypeQueryVariables>(ContractByContractTypeDocument, baseOptions);
      }
export function useContractByContractTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractByContractTypeQuery, ContractByContractTypeQueryVariables>) {
          return Apollo.useLazyQuery<ContractByContractTypeQuery, ContractByContractTypeQueryVariables>(ContractByContractTypeDocument, baseOptions);
        }
export type ContractByContractTypeQueryHookResult = ReturnType<typeof useContractByContractTypeQuery>;
export type ContractByContractTypeLazyQueryHookResult = ReturnType<typeof useContractByContractTypeLazyQuery>;
export type ContractByContractTypeQueryResult = Apollo.QueryResult<ContractByContractTypeQuery, ContractByContractTypeQueryVariables>;
export const FetchOrganizationContractSignaturesDocument = gql`
    query FetchOrganizationContractSignatures($id: ID!) {
  organization(id: $id) {
    contractSignatures {
      createdOn
      contract {
        id
        content
        contractType
      }
      user {
        id
      }
    }
  }
}
    `;

/**
 * __useFetchOrganizationContractSignaturesQuery__
 *
 * To run a query within a React component, call `useFetchOrganizationContractSignaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrganizationContractSignaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrganizationContractSignaturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchOrganizationContractSignaturesQuery(baseOptions: Apollo.QueryHookOptions<FetchOrganizationContractSignaturesQuery, FetchOrganizationContractSignaturesQueryVariables>) {
        return Apollo.useQuery<FetchOrganizationContractSignaturesQuery, FetchOrganizationContractSignaturesQueryVariables>(FetchOrganizationContractSignaturesDocument, baseOptions);
      }
export function useFetchOrganizationContractSignaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOrganizationContractSignaturesQuery, FetchOrganizationContractSignaturesQueryVariables>) {
          return Apollo.useLazyQuery<FetchOrganizationContractSignaturesQuery, FetchOrganizationContractSignaturesQueryVariables>(FetchOrganizationContractSignaturesDocument, baseOptions);
        }
export type FetchOrganizationContractSignaturesQueryHookResult = ReturnType<typeof useFetchOrganizationContractSignaturesQuery>;
export type FetchOrganizationContractSignaturesLazyQueryHookResult = ReturnType<typeof useFetchOrganizationContractSignaturesLazyQuery>;
export type FetchOrganizationContractSignaturesQueryResult = Apollo.QueryResult<FetchOrganizationContractSignaturesQuery, FetchOrganizationContractSignaturesQueryVariables>;
export const SaveContractSignatureDocument = gql`
    mutation SaveContractSignature($contractId: ID!, $organizationId: ID!) {
  saveContractSignature(contractId: $contractId, organizationId: $organizationId) {
    createdOn
    contract {
      id
      content
      contractType
    }
    user {
      id
      profile {
        contactEmail
        firstName
        lastName
        loginEmail
        phoneNumber
      }
    }
  }
}
    `;
export type SaveContractSignatureMutationFn = Apollo.MutationFunction<SaveContractSignatureMutation, SaveContractSignatureMutationVariables>;

/**
 * __useSaveContractSignatureMutation__
 *
 * To run a mutation, you first call `useSaveContractSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContractSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContractSignatureMutation, { data, loading, error }] = useSaveContractSignatureMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSaveContractSignatureMutation(baseOptions?: Apollo.MutationHookOptions<SaveContractSignatureMutation, SaveContractSignatureMutationVariables>) {
        return Apollo.useMutation<SaveContractSignatureMutation, SaveContractSignatureMutationVariables>(SaveContractSignatureDocument, baseOptions);
      }
export type SaveContractSignatureMutationHookResult = ReturnType<typeof useSaveContractSignatureMutation>;
export type SaveContractSignatureMutationResult = Apollo.MutationResult<SaveContractSignatureMutation>;
export type SaveContractSignatureMutationOptions = Apollo.BaseMutationOptions<SaveContractSignatureMutation, SaveContractSignatureMutationVariables>;
export const SetupOrganizationDocument = gql`
    mutation SetupOrganization($input: SetupOrganizationInput!) {
  setupOrganization(input: $input) {
    id
    merchants {
      id
    }
  }
}
    `;
export type SetupOrganizationMutationFn = Apollo.MutationFunction<SetupOrganizationMutation, SetupOrganizationMutationVariables>;

/**
 * __useSetupOrganizationMutation__
 *
 * To run a mutation, you first call `useSetupOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupOrganizationMutation, { data, loading, error }] = useSetupOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SetupOrganizationMutation, SetupOrganizationMutationVariables>) {
        return Apollo.useMutation<SetupOrganizationMutation, SetupOrganizationMutationVariables>(SetupOrganizationDocument, baseOptions);
      }
export type SetupOrganizationMutationHookResult = ReturnType<typeof useSetupOrganizationMutation>;
export type SetupOrganizationMutationResult = Apollo.MutationResult<SetupOrganizationMutation>;
export type SetupOrganizationMutationOptions = Apollo.BaseMutationOptions<SetupOrganizationMutation, SetupOrganizationMutationVariables>;
export const ValidateAndCreateUserDocument = gql`
    mutation ValidateAndCreateUser($overrideDuplicateCheck: Boolean) {
  validateAndCreateUser(overrideDuplicateCheck: $overrideDuplicateCheck) {
    type
    duplicateAccounts {
      id
      accessIdentifier
    }
    me {
      id
      accessIdentifier
      profile {
        id
        contactEmail
        loginEmail
        firstName
        lastName
        phoneNumber
      }
    }
  }
}
    `;
export type ValidateAndCreateUserMutationFn = Apollo.MutationFunction<ValidateAndCreateUserMutation, ValidateAndCreateUserMutationVariables>;

/**
 * __useValidateAndCreateUserMutation__
 *
 * To run a mutation, you first call `useValidateAndCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAndCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAndCreateUserMutation, { data, loading, error }] = useValidateAndCreateUserMutation({
 *   variables: {
 *      overrideDuplicateCheck: // value for 'overrideDuplicateCheck'
 *   },
 * });
 */
export function useValidateAndCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<ValidateAndCreateUserMutation, ValidateAndCreateUserMutationVariables>) {
        return Apollo.useMutation<ValidateAndCreateUserMutation, ValidateAndCreateUserMutationVariables>(ValidateAndCreateUserDocument, baseOptions);
      }
export type ValidateAndCreateUserMutationHookResult = ReturnType<typeof useValidateAndCreateUserMutation>;
export type ValidateAndCreateUserMutationResult = Apollo.MutationResult<ValidateAndCreateUserMutation>;
export type ValidateAndCreateUserMutationOptions = Apollo.BaseMutationOptions<ValidateAndCreateUserMutation, ValidateAndCreateUserMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    id
    name
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, baseOptions);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
    name
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateOrganizationDetailsDocument = gql`
    query UpdateOrganizationDetails($id: ID!) {
  organization(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useUpdateOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useUpdateOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateOrganizationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrganizationDetailsQuery(baseOptions: Apollo.QueryHookOptions<UpdateOrganizationDetailsQuery, UpdateOrganizationDetailsQueryVariables>) {
        return Apollo.useQuery<UpdateOrganizationDetailsQuery, UpdateOrganizationDetailsQueryVariables>(UpdateOrganizationDetailsDocument, baseOptions);
      }
export function useUpdateOrganizationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateOrganizationDetailsQuery, UpdateOrganizationDetailsQueryVariables>) {
          return Apollo.useLazyQuery<UpdateOrganizationDetailsQuery, UpdateOrganizationDetailsQueryVariables>(UpdateOrganizationDetailsDocument, baseOptions);
        }
export type UpdateOrganizationDetailsQueryHookResult = ReturnType<typeof useUpdateOrganizationDetailsQuery>;
export type UpdateOrganizationDetailsLazyQueryHookResult = ReturnType<typeof useUpdateOrganizationDetailsLazyQuery>;
export type UpdateOrganizationDetailsQueryResult = Apollo.QueryResult<UpdateOrganizationDetailsQuery, UpdateOrganizationDetailsQueryVariables>;
export const ViewOrganizationDocument = gql`
    query ViewOrganization($id: ID!) {
  organization(id: $id) {
    id
    name
    users {
      id
      profile {
        id
        firstName
        lastName
        loginEmail
        contactEmail
        phoneNumber
      }
      roles
    }
    merchants {
      id
      name
      active
    }
    stripeCustomerId
  }
}
    `;

/**
 * __useViewOrganizationQuery__
 *
 * To run a query within a React component, call `useViewOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ViewOrganizationQuery, ViewOrganizationQueryVariables>) {
        return Apollo.useQuery<ViewOrganizationQuery, ViewOrganizationQueryVariables>(ViewOrganizationDocument, baseOptions);
      }
export function useViewOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewOrganizationQuery, ViewOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<ViewOrganizationQuery, ViewOrganizationQueryVariables>(ViewOrganizationDocument, baseOptions);
        }
export type ViewOrganizationQueryHookResult = ReturnType<typeof useViewOrganizationQuery>;
export type ViewOrganizationLazyQueryHookResult = ReturnType<typeof useViewOrganizationLazyQuery>;
export type ViewOrganizationQueryResult = Apollo.QueryResult<ViewOrganizationQuery, ViewOrganizationQueryVariables>;
export const MerchantPayoutsDocument = gql`
    query MerchantPayouts($ids: [ID!]!) {
  merchantPayouts(ids: $ids) {
    id
    createdOn
    merchant {
      id
      name
      organization {
        id
        name
      }
    }
    periodStartDate
    periodEndDate
    payoutAmount
    payoutType
    manualPayout
    stripePaymentUrl
    paidOn
    orders {
      ...OrderForReports
    }
    tokenOrders {
      ...OrderForReports
    }
    discountOrders {
      ...OrderForReports
    }
    referrals {
      id
      createdOn
    }
  }
}
    ${OrderForReportsFragmentDoc}`;

/**
 * __useMerchantPayoutsQuery__
 *
 * To run a query within a React component, call `useMerchantPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantPayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantPayoutsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMerchantPayoutsQuery(baseOptions: Apollo.QueryHookOptions<MerchantPayoutsQuery, MerchantPayoutsQueryVariables>) {
        return Apollo.useQuery<MerchantPayoutsQuery, MerchantPayoutsQueryVariables>(MerchantPayoutsDocument, baseOptions);
      }
export function useMerchantPayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantPayoutsQuery, MerchantPayoutsQueryVariables>) {
          return Apollo.useLazyQuery<MerchantPayoutsQuery, MerchantPayoutsQueryVariables>(MerchantPayoutsDocument, baseOptions);
        }
export type MerchantPayoutsQueryHookResult = ReturnType<typeof useMerchantPayoutsQuery>;
export type MerchantPayoutsLazyQueryHookResult = ReturnType<typeof useMerchantPayoutsLazyQuery>;
export type MerchantPayoutsQueryResult = Apollo.QueryResult<MerchantPayoutsQuery, MerchantPayoutsQueryVariables>;
export const MerchantSubscriptionsByOrganizationIdDocument = gql`
    query merchantSubscriptionsByOrganizationId($organizationId: ID!) {
  merchantSubscriptionsByOrganizationId(organizationId: $organizationId) {
    id
    trialEndDate
    organizationManaged
    subscriptionStatus
    subscriptionPlan {
      id
      name
      costUsCents
      recurringInterval
    }
    merchant {
      id
      name
    }
    stripeSubscription {
      id
      stripeSubscriptionId
      stripeCustomerId
      subscriptionStatus
    }
    platformPricing {
      id
      name
      createdOn
      instantPriceUsCents
      platformPricingType
      subscriptionTerm
      subscriptionProduct {
        id
        costUsCents
        name
        recurringInterval
      }
    }
    termPricing
  }
}
    `;

/**
 * __useMerchantSubscriptionsByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useMerchantSubscriptionsByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSubscriptionsByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSubscriptionsByOrganizationIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMerchantSubscriptionsByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<MerchantSubscriptionsByOrganizationIdQuery, MerchantSubscriptionsByOrganizationIdQueryVariables>) {
        return Apollo.useQuery<MerchantSubscriptionsByOrganizationIdQuery, MerchantSubscriptionsByOrganizationIdQueryVariables>(MerchantSubscriptionsByOrganizationIdDocument, baseOptions);
      }
export function useMerchantSubscriptionsByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSubscriptionsByOrganizationIdQuery, MerchantSubscriptionsByOrganizationIdQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSubscriptionsByOrganizationIdQuery, MerchantSubscriptionsByOrganizationIdQueryVariables>(MerchantSubscriptionsByOrganizationIdDocument, baseOptions);
        }
export type MerchantSubscriptionsByOrganizationIdQueryHookResult = ReturnType<typeof useMerchantSubscriptionsByOrganizationIdQuery>;
export type MerchantSubscriptionsByOrganizationIdLazyQueryHookResult = ReturnType<typeof useMerchantSubscriptionsByOrganizationIdLazyQuery>;
export type MerchantSubscriptionsByOrganizationIdQueryResult = Apollo.QueryResult<MerchantSubscriptionsByOrganizationIdQuery, MerchantSubscriptionsByOrganizationIdQueryVariables>;
export const MerchantSubscriptionFeesByOrganizationIdDocument = gql`
    query MerchantSubscriptionFeesByOrganizationId($organizationId: ID!) {
  merchantSubscriptionFeesByOrganizationId(organizationId: $organizationId) {
    id
    merchant {
      id
      name
    }
    subscriptionFee {
      id
      name
      costUsCents
    }
  }
}
    `;

/**
 * __useMerchantSubscriptionFeesByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useMerchantSubscriptionFeesByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSubscriptionFeesByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSubscriptionFeesByOrganizationIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMerchantSubscriptionFeesByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<MerchantSubscriptionFeesByOrganizationIdQuery, MerchantSubscriptionFeesByOrganizationIdQueryVariables>) {
        return Apollo.useQuery<MerchantSubscriptionFeesByOrganizationIdQuery, MerchantSubscriptionFeesByOrganizationIdQueryVariables>(MerchantSubscriptionFeesByOrganizationIdDocument, baseOptions);
      }
export function useMerchantSubscriptionFeesByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSubscriptionFeesByOrganizationIdQuery, MerchantSubscriptionFeesByOrganizationIdQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSubscriptionFeesByOrganizationIdQuery, MerchantSubscriptionFeesByOrganizationIdQueryVariables>(MerchantSubscriptionFeesByOrganizationIdDocument, baseOptions);
        }
export type MerchantSubscriptionFeesByOrganizationIdQueryHookResult = ReturnType<typeof useMerchantSubscriptionFeesByOrganizationIdQuery>;
export type MerchantSubscriptionFeesByOrganizationIdLazyQueryHookResult = ReturnType<typeof useMerchantSubscriptionFeesByOrganizationIdLazyQuery>;
export type MerchantSubscriptionFeesByOrganizationIdQueryResult = Apollo.QueryResult<MerchantSubscriptionFeesByOrganizationIdQuery, MerchantSubscriptionFeesByOrganizationIdQueryVariables>;
export const OrganizationInvoicesDocument = gql`
    query organizationInvoices($organizationId: ID!) {
  organizationInvoices(organizationId: $organizationId) {
    subscriptionInvoiceLines {
      costUsCents
      quantity
      subscriptionProduct {
        name
        recurringInterval
      }
    }
    status
    costUsCents
    createdOn
    finalizedOn
    paidOn
  }
}
    `;

/**
 * __useOrganizationInvoicesQuery__
 *
 * To run a query within a React component, call `useOrganizationInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInvoicesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationInvoicesQuery(baseOptions: Apollo.QueryHookOptions<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>) {
        return Apollo.useQuery<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>(OrganizationInvoicesDocument, baseOptions);
      }
export function useOrganizationInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>) {
          return Apollo.useLazyQuery<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>(OrganizationInvoicesDocument, baseOptions);
        }
export type OrganizationInvoicesQueryHookResult = ReturnType<typeof useOrganizationInvoicesQuery>;
export type OrganizationInvoicesLazyQueryHookResult = ReturnType<typeof useOrganizationInvoicesLazyQuery>;
export type OrganizationInvoicesQueryResult = Apollo.QueryResult<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>;
export const SetMerchantSubscriptionsDocument = gql`
    mutation SetMerchantSubscriptions($inputs: [SetMerchantSubscriptionInput!]!) {
  setMerchantSubscriptions(inputs: $inputs) {
    id
    trialEndDate
    organizationManaged
    subscriptionPlan {
      id
      name
      costUsCents
    }
    merchant {
      id
      stripeCustomerId
    }
    stripeSubscription {
      stripeSubscriptionId
    }
    platformPricing {
      id
      name
      createdOn
      instantPriceUsCents
      platformPricingType
      subscriptionTerm
      subscriptionProduct {
        id
        costUsCents
        name
        recurringInterval
      }
    }
    termPricing
  }
}
    `;
export type SetMerchantSubscriptionsMutationFn = Apollo.MutationFunction<SetMerchantSubscriptionsMutation, SetMerchantSubscriptionsMutationVariables>;

/**
 * __useSetMerchantSubscriptionsMutation__
 *
 * To run a mutation, you first call `useSetMerchantSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMerchantSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMerchantSubscriptionsMutation, { data, loading, error }] = useSetMerchantSubscriptionsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useSetMerchantSubscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<SetMerchantSubscriptionsMutation, SetMerchantSubscriptionsMutationVariables>) {
        return Apollo.useMutation<SetMerchantSubscriptionsMutation, SetMerchantSubscriptionsMutationVariables>(SetMerchantSubscriptionsDocument, baseOptions);
      }
export type SetMerchantSubscriptionsMutationHookResult = ReturnType<typeof useSetMerchantSubscriptionsMutation>;
export type SetMerchantSubscriptionsMutationResult = Apollo.MutationResult<SetMerchantSubscriptionsMutation>;
export type SetMerchantSubscriptionsMutationOptions = Apollo.BaseMutationOptions<SetMerchantSubscriptionsMutation, SetMerchantSubscriptionsMutationVariables>;
export const UpdateMerchantsOnOrgSubscriptionDocument = gql`
    mutation UpdateMerchantsOnOrgSubscription($merchantIds: [ID!]!) {
  updateMerchantsOnOrgSubscription(merchantIds: $merchantIds) {
    id
    trialEndDate
    subscriptionPlan {
      id
      name
      costUsCents
    }
    merchant {
      id
      name
    }
    stripeSubscription {
      id
      stripeSubscriptionId
      stripeCustomerId
      subscriptionStatus
    }
  }
}
    `;
export type UpdateMerchantsOnOrgSubscriptionMutationFn = Apollo.MutationFunction<UpdateMerchantsOnOrgSubscriptionMutation, UpdateMerchantsOnOrgSubscriptionMutationVariables>;

/**
 * __useUpdateMerchantsOnOrgSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantsOnOrgSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantsOnOrgSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantsOnOrgSubscriptionMutation, { data, loading, error }] = useUpdateMerchantsOnOrgSubscriptionMutation({
 *   variables: {
 *      merchantIds: // value for 'merchantIds'
 *   },
 * });
 */
export function useUpdateMerchantsOnOrgSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantsOnOrgSubscriptionMutation, UpdateMerchantsOnOrgSubscriptionMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantsOnOrgSubscriptionMutation, UpdateMerchantsOnOrgSubscriptionMutationVariables>(UpdateMerchantsOnOrgSubscriptionDocument, baseOptions);
      }
export type UpdateMerchantsOnOrgSubscriptionMutationHookResult = ReturnType<typeof useUpdateMerchantsOnOrgSubscriptionMutation>;
export type UpdateMerchantsOnOrgSubscriptionMutationResult = Apollo.MutationResult<UpdateMerchantsOnOrgSubscriptionMutation>;
export type UpdateMerchantsOnOrgSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantsOnOrgSubscriptionMutation, UpdateMerchantsOnOrgSubscriptionMutationVariables>;
export const OrganziationSettingsDocument = gql`
    query OrganziationSettings($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    settings {
      name
      description
      category
      value
      valueType
    }
  }
}
    `;

/**
 * __useOrganziationSettingsQuery__
 *
 * To run a query within a React component, call `useOrganziationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganziationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganziationSettingsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganziationSettingsQuery(baseOptions: Apollo.QueryHookOptions<OrganziationSettingsQuery, OrganziationSettingsQueryVariables>) {
        return Apollo.useQuery<OrganziationSettingsQuery, OrganziationSettingsQueryVariables>(OrganziationSettingsDocument, baseOptions);
      }
export function useOrganziationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganziationSettingsQuery, OrganziationSettingsQueryVariables>) {
          return Apollo.useLazyQuery<OrganziationSettingsQuery, OrganziationSettingsQueryVariables>(OrganziationSettingsDocument, baseOptions);
        }
export type OrganziationSettingsQueryHookResult = ReturnType<typeof useOrganziationSettingsQuery>;
export type OrganziationSettingsLazyQueryHookResult = ReturnType<typeof useOrganziationSettingsLazyQuery>;
export type OrganziationSettingsQueryResult = Apollo.QueryResult<OrganziationSettingsQuery, OrganziationSettingsQueryVariables>;
export const UpdateOrganizationBooleanSettingDocument = gql`
    mutation UpdateOrganizationBooleanSetting($organizationId: ID!, $name: String!, $value: Boolean!) {
  updateOrganizationBooleanSetting(
    organizationId: $organizationId
    name: $name
    value: $value
  ) {
    name
    description
    value
    valueType
    category
  }
}
    `;
export type UpdateOrganizationBooleanSettingMutationFn = Apollo.MutationFunction<UpdateOrganizationBooleanSettingMutation, UpdateOrganizationBooleanSettingMutationVariables>;

/**
 * __useUpdateOrganizationBooleanSettingMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationBooleanSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationBooleanSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationBooleanSettingMutation, { data, loading, error }] = useUpdateOrganizationBooleanSettingMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateOrganizationBooleanSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationBooleanSettingMutation, UpdateOrganizationBooleanSettingMutationVariables>) {
        return Apollo.useMutation<UpdateOrganizationBooleanSettingMutation, UpdateOrganizationBooleanSettingMutationVariables>(UpdateOrganizationBooleanSettingDocument, baseOptions);
      }
export type UpdateOrganizationBooleanSettingMutationHookResult = ReturnType<typeof useUpdateOrganizationBooleanSettingMutation>;
export type UpdateOrganizationBooleanSettingMutationResult = Apollo.MutationResult<UpdateOrganizationBooleanSettingMutation>;
export type UpdateOrganizationBooleanSettingMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationBooleanSettingMutation, UpdateOrganizationBooleanSettingMutationVariables>;
export const UpdateOrganizationNumericSettingDocument = gql`
    mutation UpdateOrganizationNumericSetting($organizationId: ID!, $name: String!, $value: Int!) {
  updateOrganizationNumericSetting(
    organizationId: $organizationId
    name: $name
    value: $value
  ) {
    name
    description
    value
    valueType
    category
  }
}
    `;
export type UpdateOrganizationNumericSettingMutationFn = Apollo.MutationFunction<UpdateOrganizationNumericSettingMutation, UpdateOrganizationNumericSettingMutationVariables>;

/**
 * __useUpdateOrganizationNumericSettingMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationNumericSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationNumericSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationNumericSettingMutation, { data, loading, error }] = useUpdateOrganizationNumericSettingMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateOrganizationNumericSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationNumericSettingMutation, UpdateOrganizationNumericSettingMutationVariables>) {
        return Apollo.useMutation<UpdateOrganizationNumericSettingMutation, UpdateOrganizationNumericSettingMutationVariables>(UpdateOrganizationNumericSettingDocument, baseOptions);
      }
export type UpdateOrganizationNumericSettingMutationHookResult = ReturnType<typeof useUpdateOrganizationNumericSettingMutation>;
export type UpdateOrganizationNumericSettingMutationResult = Apollo.MutationResult<UpdateOrganizationNumericSettingMutation>;
export type UpdateOrganizationNumericSettingMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationNumericSettingMutation, UpdateOrganizationNumericSettingMutationVariables>;
export const OrganizationReadableIdDocument = gql`
    query OrganizationReadableId($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    readableId
  }
}
    `;

/**
 * __useOrganizationReadableIdQuery__
 *
 * To run a query within a React component, call `useOrganizationReadableIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationReadableIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationReadableIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationReadableIdQuery(baseOptions: Apollo.QueryHookOptions<OrganizationReadableIdQuery, OrganizationReadableIdQueryVariables>) {
        return Apollo.useQuery<OrganizationReadableIdQuery, OrganizationReadableIdQueryVariables>(OrganizationReadableIdDocument, baseOptions);
      }
export function useOrganizationReadableIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationReadableIdQuery, OrganizationReadableIdQueryVariables>) {
          return Apollo.useLazyQuery<OrganizationReadableIdQuery, OrganizationReadableIdQueryVariables>(OrganizationReadableIdDocument, baseOptions);
        }
export type OrganizationReadableIdQueryHookResult = ReturnType<typeof useOrganizationReadableIdQuery>;
export type OrganizationReadableIdLazyQueryHookResult = ReturnType<typeof useOrganizationReadableIdLazyQuery>;
export type OrganizationReadableIdQueryResult = Apollo.QueryResult<OrganizationReadableIdQuery, OrganizationReadableIdQueryVariables>;
export const UpdateOrganizationReadableIdDocument = gql`
    mutation UpdateOrganizationReadableId($organizationId: ID!, $readableId: String!) {
  updateOrganizationReadableId(
    organizationId: $organizationId
    readableId: $readableId
  ) {
    id
    readableId
  }
}
    `;
export type UpdateOrganizationReadableIdMutationFn = Apollo.MutationFunction<UpdateOrganizationReadableIdMutation, UpdateOrganizationReadableIdMutationVariables>;

/**
 * __useUpdateOrganizationReadableIdMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationReadableIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationReadableIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationReadableIdMutation, { data, loading, error }] = useUpdateOrganizationReadableIdMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      readableId: // value for 'readableId'
 *   },
 * });
 */
export function useUpdateOrganizationReadableIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationReadableIdMutation, UpdateOrganizationReadableIdMutationVariables>) {
        return Apollo.useMutation<UpdateOrganizationReadableIdMutation, UpdateOrganizationReadableIdMutationVariables>(UpdateOrganizationReadableIdDocument, baseOptions);
      }
export type UpdateOrganizationReadableIdMutationHookResult = ReturnType<typeof useUpdateOrganizationReadableIdMutation>;
export type UpdateOrganizationReadableIdMutationResult = Apollo.MutationResult<UpdateOrganizationReadableIdMutation>;
export type UpdateOrganizationReadableIdMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationReadableIdMutation, UpdateOrganizationReadableIdMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
    name
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const MerchantCatalogDocument = gql`
    query MerchantCatalog($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    activeCatalog {
      id
      categories {
        id
        name
        type
      }
    }
  }
}
    `;

/**
 * __useMerchantCatalogQuery__
 *
 * To run a query within a React component, call `useMerchantCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantCatalogQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useMerchantCatalogQuery(baseOptions: Apollo.QueryHookOptions<MerchantCatalogQuery, MerchantCatalogQueryVariables>) {
        return Apollo.useQuery<MerchantCatalogQuery, MerchantCatalogQueryVariables>(MerchantCatalogDocument, baseOptions);
      }
export function useMerchantCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantCatalogQuery, MerchantCatalogQueryVariables>) {
          return Apollo.useLazyQuery<MerchantCatalogQuery, MerchantCatalogQueryVariables>(MerchantCatalogDocument, baseOptions);
        }
export type MerchantCatalogQueryHookResult = ReturnType<typeof useMerchantCatalogQuery>;
export type MerchantCatalogLazyQueryHookResult = ReturnType<typeof useMerchantCatalogLazyQuery>;
export type MerchantCatalogQueryResult = Apollo.QueryResult<MerchantCatalogQuery, MerchantCatalogQueryVariables>;
export const ArchiveCategoryDocument = gql`
    mutation ArchiveCategory($categoryId: ID!) {
  archiveCategory(categoryId: $categoryId)
}
    `;
export type ArchiveCategoryMutationFn = Apollo.MutationFunction<ArchiveCategoryMutation, ArchiveCategoryMutationVariables>;

/**
 * __useArchiveCategoryMutation__
 *
 * To run a mutation, you first call `useArchiveCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCategoryMutation, { data, loading, error }] = useArchiveCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useArchiveCategoryMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveCategoryMutation, ArchiveCategoryMutationVariables>) {
        return Apollo.useMutation<ArchiveCategoryMutation, ArchiveCategoryMutationVariables>(ArchiveCategoryDocument, baseOptions);
      }
export type ArchiveCategoryMutationHookResult = ReturnType<typeof useArchiveCategoryMutation>;
export type ArchiveCategoryMutationResult = Apollo.MutationResult<ArchiveCategoryMutation>;
export type ArchiveCategoryMutationOptions = Apollo.BaseMutationOptions<ArchiveCategoryMutation, ArchiveCategoryMutationVariables>;
export const UpdateCategoryNameDocument = gql`
    mutation UpdateCategoryName($categoryId: ID!, $name: String!) {
  updateCategoryName(categoryId: $categoryId, name: $name) {
    id
    name
  }
}
    `;
export type UpdateCategoryNameMutationFn = Apollo.MutationFunction<UpdateCategoryNameMutation, UpdateCategoryNameMutationVariables>;

/**
 * __useUpdateCategoryNameMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryNameMutation, { data, loading, error }] = useUpdateCategoryNameMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCategoryNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryNameMutation, UpdateCategoryNameMutationVariables>) {
        return Apollo.useMutation<UpdateCategoryNameMutation, UpdateCategoryNameMutationVariables>(UpdateCategoryNameDocument, baseOptions);
      }
export type UpdateCategoryNameMutationHookResult = ReturnType<typeof useUpdateCategoryNameMutation>;
export type UpdateCategoryNameMutationResult = Apollo.MutationResult<UpdateCategoryNameMutation>;
export type UpdateCategoryNameMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryNameMutation, UpdateCategoryNameMutationVariables>;
export const UpdateCategorySortOrdersDocument = gql`
    mutation UpdateCategorySortOrders($inputs: [UpdateSortOrderInput!]!) {
  updateCategorySortOrders(inputs: $inputs)
}
    `;
export type UpdateCategorySortOrdersMutationFn = Apollo.MutationFunction<UpdateCategorySortOrdersMutation, UpdateCategorySortOrdersMutationVariables>;

/**
 * __useUpdateCategorySortOrdersMutation__
 *
 * To run a mutation, you first call `useUpdateCategorySortOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategorySortOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategorySortOrdersMutation, { data, loading, error }] = useUpdateCategorySortOrdersMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateCategorySortOrdersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategorySortOrdersMutation, UpdateCategorySortOrdersMutationVariables>) {
        return Apollo.useMutation<UpdateCategorySortOrdersMutation, UpdateCategorySortOrdersMutationVariables>(UpdateCategorySortOrdersDocument, baseOptions);
      }
export type UpdateCategorySortOrdersMutationHookResult = ReturnType<typeof useUpdateCategorySortOrdersMutation>;
export type UpdateCategorySortOrdersMutationResult = Apollo.MutationResult<UpdateCategorySortOrdersMutation>;
export type UpdateCategorySortOrdersMutationOptions = Apollo.BaseMutationOptions<UpdateCategorySortOrdersMutation, UpdateCategorySortOrdersMutationVariables>;
export const UpdateProductCategoriesDocument = gql`
    mutation UpdateProductCategories($inputs: [UpdateProductCategoryInput!]!) {
  updateProductCategories(inputs: $inputs)
}
    `;
export type UpdateProductCategoriesMutationFn = Apollo.MutationFunction<UpdateProductCategoriesMutation, UpdateProductCategoriesMutationVariables>;

/**
 * __useUpdateProductCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateProductCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCategoriesMutation, { data, loading, error }] = useUpdateProductCategoriesMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateProductCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCategoriesMutation, UpdateProductCategoriesMutationVariables>) {
        return Apollo.useMutation<UpdateProductCategoriesMutation, UpdateProductCategoriesMutationVariables>(UpdateProductCategoriesDocument, baseOptions);
      }
export type UpdateProductCategoriesMutationHookResult = ReturnType<typeof useUpdateProductCategoriesMutation>;
export type UpdateProductCategoriesMutationResult = Apollo.MutationResult<UpdateProductCategoriesMutation>;
export type UpdateProductCategoriesMutationOptions = Apollo.BaseMutationOptions<UpdateProductCategoriesMutation, UpdateProductCategoriesMutationVariables>;
export const UpdateProductNameDocument = gql`
    mutation UpdateProductName($productId: ID!, $name: String!) {
  updateProductName(productId: $productId, name: $name) {
    id
    name
  }
}
    `;
export type UpdateProductNameMutationFn = Apollo.MutationFunction<UpdateProductNameMutation, UpdateProductNameMutationVariables>;

/**
 * __useUpdateProductNameMutation__
 *
 * To run a mutation, you first call `useUpdateProductNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductNameMutation, { data, loading, error }] = useUpdateProductNameMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProductNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductNameMutation, UpdateProductNameMutationVariables>) {
        return Apollo.useMutation<UpdateProductNameMutation, UpdateProductNameMutationVariables>(UpdateProductNameDocument, baseOptions);
      }
export type UpdateProductNameMutationHookResult = ReturnType<typeof useUpdateProductNameMutation>;
export type UpdateProductNameMutationResult = Apollo.MutationResult<UpdateProductNameMutation>;
export type UpdateProductNameMutationOptions = Apollo.BaseMutationOptions<UpdateProductNameMutation, UpdateProductNameMutationVariables>;
export const UpdateProductSortOrdersDocument = gql`
    mutation UpdateProductSortOrders($inputs: [UpdateSortOrderInput!]!) {
  updateProductSortOrders(inputs: $inputs)
}
    `;
export type UpdateProductSortOrdersMutationFn = Apollo.MutationFunction<UpdateProductSortOrdersMutation, UpdateProductSortOrdersMutationVariables>;

/**
 * __useUpdateProductSortOrdersMutation__
 *
 * To run a mutation, you first call `useUpdateProductSortOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductSortOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductSortOrdersMutation, { data, loading, error }] = useUpdateProductSortOrdersMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateProductSortOrdersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductSortOrdersMutation, UpdateProductSortOrdersMutationVariables>) {
        return Apollo.useMutation<UpdateProductSortOrdersMutation, UpdateProductSortOrdersMutationVariables>(UpdateProductSortOrdersDocument, baseOptions);
      }
export type UpdateProductSortOrdersMutationHookResult = ReturnType<typeof useUpdateProductSortOrdersMutation>;
export type UpdateProductSortOrdersMutationResult = Apollo.MutationResult<UpdateProductSortOrdersMutation>;
export type UpdateProductSortOrdersMutationOptions = Apollo.BaseMutationOptions<UpdateProductSortOrdersMutation, UpdateProductSortOrdersMutationVariables>;
export const UpdateProductsActiveDocument = gql`
    mutation UpdateProductsActive($productIds: [ID!]!, $active: Boolean!) {
  updateProductsActive(productIds: $productIds, active: $active) {
    id
    name
    active
  }
}
    `;
export type UpdateProductsActiveMutationFn = Apollo.MutationFunction<UpdateProductsActiveMutation, UpdateProductsActiveMutationVariables>;

/**
 * __useUpdateProductsActiveMutation__
 *
 * To run a mutation, you first call `useUpdateProductsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductsActiveMutation, { data, loading, error }] = useUpdateProductsActiveMutation({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateProductsActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductsActiveMutation, UpdateProductsActiveMutationVariables>) {
        return Apollo.useMutation<UpdateProductsActiveMutation, UpdateProductsActiveMutationVariables>(UpdateProductsActiveDocument, baseOptions);
      }
export type UpdateProductsActiveMutationHookResult = ReturnType<typeof useUpdateProductsActiveMutation>;
export type UpdateProductsActiveMutationResult = Apollo.MutationResult<UpdateProductsActiveMutation>;
export type UpdateProductsActiveMutationOptions = Apollo.BaseMutationOptions<UpdateProductsActiveMutation, UpdateProductsActiveMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
    name
    priceCents
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateProductDetailsDocument = gql`
    query UpdateProductDetails($id: ID!) {
  product(id: $id) {
    ...UpdateProduct
  }
}
    ${UpdateProductFragmentDoc}`;

/**
 * __useUpdateProductDetailsQuery__
 *
 * To run a query within a React component, call `useUpdateProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateProductDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProductDetailsQuery(baseOptions: Apollo.QueryHookOptions<UpdateProductDetailsQuery, UpdateProductDetailsQueryVariables>) {
        return Apollo.useQuery<UpdateProductDetailsQuery, UpdateProductDetailsQueryVariables>(UpdateProductDetailsDocument, baseOptions);
      }
export function useUpdateProductDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateProductDetailsQuery, UpdateProductDetailsQueryVariables>) {
          return Apollo.useLazyQuery<UpdateProductDetailsQuery, UpdateProductDetailsQueryVariables>(UpdateProductDetailsDocument, baseOptions);
        }
export type UpdateProductDetailsQueryHookResult = ReturnType<typeof useUpdateProductDetailsQuery>;
export type UpdateProductDetailsLazyQueryHookResult = ReturnType<typeof useUpdateProductDetailsLazyQuery>;
export type UpdateProductDetailsQueryResult = Apollo.QueryResult<UpdateProductDetailsQuery, UpdateProductDetailsQueryVariables>;
export const CatalogProductSelectDocument = gql`
    query CatalogProductSelect($catalogId: ID!) {
  catalog(id: $catalogId) {
    id
    categories {
      id
      activeProducts {
        ...CatalogProduct
      }
    }
  }
}
    ${CatalogProductFragmentDoc}`;

/**
 * __useCatalogProductSelectQuery__
 *
 * To run a query within a React component, call `useCatalogProductSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogProductSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogProductSelectQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useCatalogProductSelectQuery(baseOptions: Apollo.QueryHookOptions<CatalogProductSelectQuery, CatalogProductSelectQueryVariables>) {
        return Apollo.useQuery<CatalogProductSelectQuery, CatalogProductSelectQueryVariables>(CatalogProductSelectDocument, baseOptions);
      }
export function useCatalogProductSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogProductSelectQuery, CatalogProductSelectQueryVariables>) {
          return Apollo.useLazyQuery<CatalogProductSelectQuery, CatalogProductSelectQueryVariables>(CatalogProductSelectDocument, baseOptions);
        }
export type CatalogProductSelectQueryHookResult = ReturnType<typeof useCatalogProductSelectQuery>;
export type CatalogProductSelectLazyQueryHookResult = ReturnType<typeof useCatalogProductSelectLazyQuery>;
export type CatalogProductSelectQueryResult = Apollo.QueryResult<CatalogProductSelectQuery, CatalogProductSelectQueryVariables>;
export const CategorySelectDocument = gql`
    query CategorySelect($merchantId: ID!) {
  categories(merchantId: $merchantId) {
    ...SelectCategory
  }
}
    ${SelectCategoryFragmentDoc}`;

/**
 * __useCategorySelectQuery__
 *
 * To run a query within a React component, call `useCategorySelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategorySelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategorySelectQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useCategorySelectQuery(baseOptions: Apollo.QueryHookOptions<CategorySelectQuery, CategorySelectQueryVariables>) {
        return Apollo.useQuery<CategorySelectQuery, CategorySelectQueryVariables>(CategorySelectDocument, baseOptions);
      }
export function useCategorySelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategorySelectQuery, CategorySelectQueryVariables>) {
          return Apollo.useLazyQuery<CategorySelectQuery, CategorySelectQueryVariables>(CategorySelectDocument, baseOptions);
        }
export type CategorySelectQueryHookResult = ReturnType<typeof useCategorySelectQuery>;
export type CategorySelectLazyQueryHookResult = ReturnType<typeof useCategorySelectLazyQuery>;
export type CategorySelectQueryResult = Apollo.QueryResult<CategorySelectQuery, CategorySelectQueryVariables>;
export const CopyAllProductsFromMerchantDocument = gql`
    mutation CopyAllProductsFromMerchant($input: CopyAllProductsFromMerchantInput!) {
  copyAllProductsFromMerchant(input: $input)
}
    `;
export type CopyAllProductsFromMerchantMutationFn = Apollo.MutationFunction<CopyAllProductsFromMerchantMutation, CopyAllProductsFromMerchantMutationVariables>;

/**
 * __useCopyAllProductsFromMerchantMutation__
 *
 * To run a mutation, you first call `useCopyAllProductsFromMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyAllProductsFromMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyAllProductsFromMerchantMutation, { data, loading, error }] = useCopyAllProductsFromMerchantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyAllProductsFromMerchantMutation(baseOptions?: Apollo.MutationHookOptions<CopyAllProductsFromMerchantMutation, CopyAllProductsFromMerchantMutationVariables>) {
        return Apollo.useMutation<CopyAllProductsFromMerchantMutation, CopyAllProductsFromMerchantMutationVariables>(CopyAllProductsFromMerchantDocument, baseOptions);
      }
export type CopyAllProductsFromMerchantMutationHookResult = ReturnType<typeof useCopyAllProductsFromMerchantMutation>;
export type CopyAllProductsFromMerchantMutationResult = Apollo.MutationResult<CopyAllProductsFromMerchantMutation>;
export type CopyAllProductsFromMerchantMutationOptions = Apollo.BaseMutationOptions<CopyAllProductsFromMerchantMutation, CopyAllProductsFromMerchantMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    id
    name
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreateTemplateMenuDocument = gql`
    mutation CreateTemplateMenu($merchantId: ID!) {
  createTemplateMenu(merchantId: $merchantId) {
    id
    name
    active
    category {
      id
      name
    }
  }
}
    `;
export type CreateTemplateMenuMutationFn = Apollo.MutationFunction<CreateTemplateMenuMutation, CreateTemplateMenuMutationVariables>;

/**
 * __useCreateTemplateMenuMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMenuMutation, { data, loading, error }] = useCreateTemplateMenuMutation({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useCreateTemplateMenuMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMenuMutation, CreateTemplateMenuMutationVariables>) {
        return Apollo.useMutation<CreateTemplateMenuMutation, CreateTemplateMenuMutationVariables>(CreateTemplateMenuDocument, baseOptions);
      }
export type CreateTemplateMenuMutationHookResult = ReturnType<typeof useCreateTemplateMenuMutation>;
export type CreateTemplateMenuMutationResult = Apollo.MutationResult<CreateTemplateMenuMutation>;
export type CreateTemplateMenuMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMenuMutation, CreateTemplateMenuMutationVariables>;
export const DuplicateProductDocument = gql`
    mutation DuplicateProduct($productId: ID!) {
  duplicateProduct(id: $productId) {
    id
    name
    active
    category {
      id
      name
    }
  }
}
    `;
export type DuplicateProductMutationFn = Apollo.MutationFunction<DuplicateProductMutation, DuplicateProductMutationVariables>;

/**
 * __useDuplicateProductMutation__
 *
 * To run a mutation, you first call `useDuplicateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProductMutation, { data, loading, error }] = useDuplicateProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDuplicateProductMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateProductMutation, DuplicateProductMutationVariables>) {
        return Apollo.useMutation<DuplicateProductMutation, DuplicateProductMutationVariables>(DuplicateProductDocument, baseOptions);
      }
export type DuplicateProductMutationHookResult = ReturnType<typeof useDuplicateProductMutation>;
export type DuplicateProductMutationResult = Apollo.MutationResult<DuplicateProductMutation>;
export type DuplicateProductMutationOptions = Apollo.BaseMutationOptions<DuplicateProductMutation, DuplicateProductMutationVariables>;
export const LibraryImagesDocument = gql`
    query LibraryImages {
  libraryImages {
    id
    imageUri
    imageCode
  }
}
    `;

/**
 * __useLibraryImagesQuery__
 *
 * To run a query within a React component, call `useLibraryImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLibraryImagesQuery(baseOptions?: Apollo.QueryHookOptions<LibraryImagesQuery, LibraryImagesQueryVariables>) {
        return Apollo.useQuery<LibraryImagesQuery, LibraryImagesQueryVariables>(LibraryImagesDocument, baseOptions);
      }
export function useLibraryImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryImagesQuery, LibraryImagesQueryVariables>) {
          return Apollo.useLazyQuery<LibraryImagesQuery, LibraryImagesQueryVariables>(LibraryImagesDocument, baseOptions);
        }
export type LibraryImagesQueryHookResult = ReturnType<typeof useLibraryImagesQuery>;
export type LibraryImagesLazyQueryHookResult = ReturnType<typeof useLibraryImagesLazyQuery>;
export type LibraryImagesQueryResult = Apollo.QueryResult<LibraryImagesQuery, LibraryImagesQueryVariables>;
export const ProductImagesByMerchantDocument = gql`
    query ProductImagesByMerchant($merchantId: ID!) {
  productImagesByMerchant(merchantId: $merchantId) {
    id
    imageUri
    sortOrder
    libraryImage {
      imageUri
      imageCode
    }
  }
}
    `;

/**
 * __useProductImagesByMerchantQuery__
 *
 * To run a query within a React component, call `useProductImagesByMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductImagesByMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductImagesByMerchantQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useProductImagesByMerchantQuery(baseOptions: Apollo.QueryHookOptions<ProductImagesByMerchantQuery, ProductImagesByMerchantQueryVariables>) {
        return Apollo.useQuery<ProductImagesByMerchantQuery, ProductImagesByMerchantQueryVariables>(ProductImagesByMerchantDocument, baseOptions);
      }
export function useProductImagesByMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductImagesByMerchantQuery, ProductImagesByMerchantQueryVariables>) {
          return Apollo.useLazyQuery<ProductImagesByMerchantQuery, ProductImagesByMerchantQueryVariables>(ProductImagesByMerchantDocument, baseOptions);
        }
export type ProductImagesByMerchantQueryHookResult = ReturnType<typeof useProductImagesByMerchantQuery>;
export type ProductImagesByMerchantLazyQueryHookResult = ReturnType<typeof useProductImagesByMerchantLazyQuery>;
export type ProductImagesByMerchantQueryResult = Apollo.QueryResult<ProductImagesByMerchantQuery, ProductImagesByMerchantQueryVariables>;
export const GetMenuSyncConfigDocument = gql`
    query GetMenuSyncConfig($merchantId: ID!) {
  getMenuSyncConfig(merchantId: $merchantId) {
    id
    source
    active
  }
}
    `;

/**
 * __useGetMenuSyncConfigQuery__
 *
 * To run a query within a React component, call `useGetMenuSyncConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuSyncConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuSyncConfigQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useGetMenuSyncConfigQuery(baseOptions: Apollo.QueryHookOptions<GetMenuSyncConfigQuery, GetMenuSyncConfigQueryVariables>) {
        return Apollo.useQuery<GetMenuSyncConfigQuery, GetMenuSyncConfigQueryVariables>(GetMenuSyncConfigDocument, baseOptions);
      }
export function useGetMenuSyncConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuSyncConfigQuery, GetMenuSyncConfigQueryVariables>) {
          return Apollo.useLazyQuery<GetMenuSyncConfigQuery, GetMenuSyncConfigQueryVariables>(GetMenuSyncConfigDocument, baseOptions);
        }
export type GetMenuSyncConfigQueryHookResult = ReturnType<typeof useGetMenuSyncConfigQuery>;
export type GetMenuSyncConfigLazyQueryHookResult = ReturnType<typeof useGetMenuSyncConfigLazyQuery>;
export type GetMenuSyncConfigQueryResult = Apollo.QueryResult<GetMenuSyncConfigQuery, GetMenuSyncConfigQueryVariables>;
export const UpdateMenuSyncConfigActiveDocument = gql`
    mutation UpdateMenuSyncConfigActive($merchantId: ID!, $active: Boolean!) {
  updateMenuSyncConfigActive(merchantId: $merchantId, active: $active) {
    id
    source
    active
  }
}
    `;
export type UpdateMenuSyncConfigActiveMutationFn = Apollo.MutationFunction<UpdateMenuSyncConfigActiveMutation, UpdateMenuSyncConfigActiveMutationVariables>;

/**
 * __useUpdateMenuSyncConfigActiveMutation__
 *
 * To run a mutation, you first call `useUpdateMenuSyncConfigActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuSyncConfigActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuSyncConfigActiveMutation, { data, loading, error }] = useUpdateMenuSyncConfigActiveMutation({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateMenuSyncConfigActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuSyncConfigActiveMutation, UpdateMenuSyncConfigActiveMutationVariables>) {
        return Apollo.useMutation<UpdateMenuSyncConfigActiveMutation, UpdateMenuSyncConfigActiveMutationVariables>(UpdateMenuSyncConfigActiveDocument, baseOptions);
      }
export type UpdateMenuSyncConfigActiveMutationHookResult = ReturnType<typeof useUpdateMenuSyncConfigActiveMutation>;
export type UpdateMenuSyncConfigActiveMutationResult = Apollo.MutationResult<UpdateMenuSyncConfigActiveMutation>;
export type UpdateMenuSyncConfigActiveMutationOptions = Apollo.BaseMutationOptions<UpdateMenuSyncConfigActiveMutation, UpdateMenuSyncConfigActiveMutationVariables>;
export const ProductListDocument = gql`
    query ProductList($merchantId: ID!) {
  merchant(id: $merchantId) {
    id
    products {
      id
      name
      active
      priceCents
      sortOrder
      category {
        id
        name
      }
      images {
        id
        imageUri
        sortOrder
        libraryImage {
          id
          imageUri
        }
      }
      modifiers {
        id
        childSelectionType
        priceCents
        active
        name
        defaultSelectionIds
        children {
          id
          priceCents
          selected
          active
        }
      }
    }
  }
}
    `;

/**
 * __useProductListQuery__
 *
 * To run a query within a React component, call `useProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductListQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useProductListQuery(baseOptions: Apollo.QueryHookOptions<ProductListQuery, ProductListQueryVariables>) {
        return Apollo.useQuery<ProductListQuery, ProductListQueryVariables>(ProductListDocument, baseOptions);
      }
export function useProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductListQuery, ProductListQueryVariables>) {
          return Apollo.useLazyQuery<ProductListQuery, ProductListQueryVariables>(ProductListDocument, baseOptions);
        }
export type ProductListQueryHookResult = ReturnType<typeof useProductListQuery>;
export type ProductListLazyQueryHookResult = ReturnType<typeof useProductListLazyQuery>;
export type ProductListQueryResult = Apollo.QueryResult<ProductListQuery, ProductListQueryVariables>;
export const GetExternalClientSyncsByMerchantIdDocument = gql`
    query GetExternalClientSyncsByMerchantId($merchantId: ID!) {
  getExternalClientSyncsByMerchantId(merchantId: $merchantId) {
    startedOn
    completedOn
    externalSourceId
    events {
      timestamp
      eventType
      url
      response
    }
  }
}
    `;

/**
 * __useGetExternalClientSyncsByMerchantIdQuery__
 *
 * To run a query within a React component, call `useGetExternalClientSyncsByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalClientSyncsByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalClientSyncsByMerchantIdQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useGetExternalClientSyncsByMerchantIdQuery(baseOptions: Apollo.QueryHookOptions<GetExternalClientSyncsByMerchantIdQuery, GetExternalClientSyncsByMerchantIdQueryVariables>) {
        return Apollo.useQuery<GetExternalClientSyncsByMerchantIdQuery, GetExternalClientSyncsByMerchantIdQueryVariables>(GetExternalClientSyncsByMerchantIdDocument, baseOptions);
      }
export function useGetExternalClientSyncsByMerchantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalClientSyncsByMerchantIdQuery, GetExternalClientSyncsByMerchantIdQueryVariables>) {
          return Apollo.useLazyQuery<GetExternalClientSyncsByMerchantIdQuery, GetExternalClientSyncsByMerchantIdQueryVariables>(GetExternalClientSyncsByMerchantIdDocument, baseOptions);
        }
export type GetExternalClientSyncsByMerchantIdQueryHookResult = ReturnType<typeof useGetExternalClientSyncsByMerchantIdQuery>;
export type GetExternalClientSyncsByMerchantIdLazyQueryHookResult = ReturnType<typeof useGetExternalClientSyncsByMerchantIdLazyQuery>;
export type GetExternalClientSyncsByMerchantIdQueryResult = Apollo.QueryResult<GetExternalClientSyncsByMerchantIdQuery, GetExternalClientSyncsByMerchantIdQueryVariables>;
export const SyncSquareMenuDocument = gql`
    mutation SyncSquareMenu($merchantId: ID!, $baseUrl: String!, $onlyProductsWithCategories: Boolean!, $doNotImportCategories: Boolean!) {
  syncSquareMenu(
    merchantId: $merchantId
    baseUrl: $baseUrl
    onlyProductsWithCategories: $onlyProductsWithCategories
    doNotImportCategories: $doNotImportCategories
  )
}
    `;
export type SyncSquareMenuMutationFn = Apollo.MutationFunction<SyncSquareMenuMutation, SyncSquareMenuMutationVariables>;

/**
 * __useSyncSquareMenuMutation__
 *
 * To run a mutation, you first call `useSyncSquareMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSquareMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSquareMenuMutation, { data, loading, error }] = useSyncSquareMenuMutation({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      baseUrl: // value for 'baseUrl'
 *      onlyProductsWithCategories: // value for 'onlyProductsWithCategories'
 *      doNotImportCategories: // value for 'doNotImportCategories'
 *   },
 * });
 */
export function useSyncSquareMenuMutation(baseOptions?: Apollo.MutationHookOptions<SyncSquareMenuMutation, SyncSquareMenuMutationVariables>) {
        return Apollo.useMutation<SyncSquareMenuMutation, SyncSquareMenuMutationVariables>(SyncSquareMenuDocument, baseOptions);
      }
export type SyncSquareMenuMutationHookResult = ReturnType<typeof useSyncSquareMenuMutation>;
export type SyncSquareMenuMutationResult = Apollo.MutationResult<SyncSquareMenuMutation>;
export type SyncSquareMenuMutationOptions = Apollo.BaseMutationOptions<SyncSquareMenuMutation, SyncSquareMenuMutationVariables>;
export const UpdateProductActiveDocument = gql`
    mutation UpdateProductActive($productId: ID!, $active: Boolean!) {
  updateProductActive(productId: $productId, active: $active) {
    id
    name
    active
  }
}
    `;
export type UpdateProductActiveMutationFn = Apollo.MutationFunction<UpdateProductActiveMutation, UpdateProductActiveMutationVariables>;

/**
 * __useUpdateProductActiveMutation__
 *
 * To run a mutation, you first call `useUpdateProductActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductActiveMutation, { data, loading, error }] = useUpdateProductActiveMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateProductActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductActiveMutation, UpdateProductActiveMutationVariables>) {
        return Apollo.useMutation<UpdateProductActiveMutation, UpdateProductActiveMutationVariables>(UpdateProductActiveDocument, baseOptions);
      }
export type UpdateProductActiveMutationHookResult = ReturnType<typeof useUpdateProductActiveMutation>;
export type UpdateProductActiveMutationResult = Apollo.MutationResult<UpdateProductActiveMutation>;
export type UpdateProductActiveMutationOptions = Apollo.BaseMutationOptions<UpdateProductActiveMutation, UpdateProductActiveMutationVariables>;
export const CreateCsrfTokenDocument = gql`
    mutation CreateCsrfToken($data: String) {
  createCsrfToken(data: $data) {
    id
    value
  }
}
    `;
export type CreateCsrfTokenMutationFn = Apollo.MutationFunction<CreateCsrfTokenMutation, CreateCsrfTokenMutationVariables>;

/**
 * __useCreateCsrfTokenMutation__
 *
 * To run a mutation, you first call `useCreateCsrfTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCsrfTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCsrfTokenMutation, { data, loading, error }] = useCreateCsrfTokenMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCsrfTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateCsrfTokenMutation, CreateCsrfTokenMutationVariables>) {
        return Apollo.useMutation<CreateCsrfTokenMutation, CreateCsrfTokenMutationVariables>(CreateCsrfTokenDocument, baseOptions);
      }
export type CreateCsrfTokenMutationHookResult = ReturnType<typeof useCreateCsrfTokenMutation>;
export type CreateCsrfTokenMutationResult = Apollo.MutationResult<CreateCsrfTokenMutation>;
export type CreateCsrfTokenMutationOptions = Apollo.BaseMutationOptions<CreateCsrfTokenMutation, CreateCsrfTokenMutationVariables>;
export const GenerateSquareOAuthTokenDocument = gql`
    mutation GenerateSquareOAuthToken($code: String!) {
  generateSquareOAuthToken(code: $code)
}
    `;
export type GenerateSquareOAuthTokenMutationFn = Apollo.MutationFunction<GenerateSquareOAuthTokenMutation, GenerateSquareOAuthTokenMutationVariables>;

/**
 * __useGenerateSquareOAuthTokenMutation__
 *
 * To run a mutation, you first call `useGenerateSquareOAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSquareOAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSquareOAuthTokenMutation, { data, loading, error }] = useGenerateSquareOAuthTokenMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGenerateSquareOAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSquareOAuthTokenMutation, GenerateSquareOAuthTokenMutationVariables>) {
        return Apollo.useMutation<GenerateSquareOAuthTokenMutation, GenerateSquareOAuthTokenMutationVariables>(GenerateSquareOAuthTokenDocument, baseOptions);
      }
export type GenerateSquareOAuthTokenMutationHookResult = ReturnType<typeof useGenerateSquareOAuthTokenMutation>;
export type GenerateSquareOAuthTokenMutationResult = Apollo.MutationResult<GenerateSquareOAuthTokenMutation>;
export type GenerateSquareOAuthTokenMutationOptions = Apollo.BaseMutationOptions<GenerateSquareOAuthTokenMutation, GenerateSquareOAuthTokenMutationVariables>;
export const HasValidSquareOAuthTokenDocument = gql`
    query HasValidSquareOAuthToken($organizationId: ID!) {
  hasValidSquareOAuthToken(organizationId: $organizationId)
}
    `;

/**
 * __useHasValidSquareOAuthTokenQuery__
 *
 * To run a query within a React component, call `useHasValidSquareOAuthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasValidSquareOAuthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasValidSquareOAuthTokenQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useHasValidSquareOAuthTokenQuery(baseOptions: Apollo.QueryHookOptions<HasValidSquareOAuthTokenQuery, HasValidSquareOAuthTokenQueryVariables>) {
        return Apollo.useQuery<HasValidSquareOAuthTokenQuery, HasValidSquareOAuthTokenQueryVariables>(HasValidSquareOAuthTokenDocument, baseOptions);
      }
export function useHasValidSquareOAuthTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasValidSquareOAuthTokenQuery, HasValidSquareOAuthTokenQueryVariables>) {
          return Apollo.useLazyQuery<HasValidSquareOAuthTokenQuery, HasValidSquareOAuthTokenQueryVariables>(HasValidSquareOAuthTokenDocument, baseOptions);
        }
export type HasValidSquareOAuthTokenQueryHookResult = ReturnType<typeof useHasValidSquareOAuthTokenQuery>;
export type HasValidSquareOAuthTokenLazyQueryHookResult = ReturnType<typeof useHasValidSquareOAuthTokenLazyQuery>;
export type HasValidSquareOAuthTokenQueryResult = Apollo.QueryResult<HasValidSquareOAuthTokenQuery, HasValidSquareOAuthTokenQueryVariables>;
export const SquareLocationsDocument = gql`
    query SquareLocations($organizationId: ID!, $filter: SquareLocationQueryFilter!) {
  squareLocations(organizationId: $organizationId, filter: $filter) {
    id
    name
    address {
      line1
      line2
      city
      state
      postalCode
      country
    }
    phoneNumber
    latitude
    longitude
    merchantHasSquareSite
  }
}
    `;

/**
 * __useSquareLocationsQuery__
 *
 * To run a query within a React component, call `useSquareLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquareLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquareLocationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSquareLocationsQuery(baseOptions: Apollo.QueryHookOptions<SquareLocationsQuery, SquareLocationsQueryVariables>) {
        return Apollo.useQuery<SquareLocationsQuery, SquareLocationsQueryVariables>(SquareLocationsDocument, baseOptions);
      }
export function useSquareLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SquareLocationsQuery, SquareLocationsQueryVariables>) {
          return Apollo.useLazyQuery<SquareLocationsQuery, SquareLocationsQueryVariables>(SquareLocationsDocument, baseOptions);
        }
export type SquareLocationsQueryHookResult = ReturnType<typeof useSquareLocationsQuery>;
export type SquareLocationsLazyQueryHookResult = ReturnType<typeof useSquareLocationsLazyQuery>;
export type SquareLocationsQueryResult = Apollo.QueryResult<SquareLocationsQuery, SquareLocationsQueryVariables>;
export const SyncSquareLocationsDocument = gql`
    mutation SyncSquareLocations($organizationId: ID!, $inputs: [SquareSyncInput!]!) {
  syncSquareLocations(organizationId: $organizationId, inputs: $inputs) {
    id
    kdsIntegrations {
      id
      type
      locationId
      displayName
      active
      sendOrdersToRdy
    }
  }
}
    `;
export type SyncSquareLocationsMutationFn = Apollo.MutationFunction<SyncSquareLocationsMutation, SyncSquareLocationsMutationVariables>;

/**
 * __useSyncSquareLocationsMutation__
 *
 * To run a mutation, you first call `useSyncSquareLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSquareLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSquareLocationsMutation, { data, loading, error }] = useSyncSquareLocationsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useSyncSquareLocationsMutation(baseOptions?: Apollo.MutationHookOptions<SyncSquareLocationsMutation, SyncSquareLocationsMutationVariables>) {
        return Apollo.useMutation<SyncSquareLocationsMutation, SyncSquareLocationsMutationVariables>(SyncSquareLocationsDocument, baseOptions);
      }
export type SyncSquareLocationsMutationHookResult = ReturnType<typeof useSyncSquareLocationsMutation>;
export type SyncSquareLocationsMutationResult = Apollo.MutationResult<SyncSquareLocationsMutation>;
export type SyncSquareLocationsMutationOptions = Apollo.BaseMutationOptions<SyncSquareLocationsMutation, SyncSquareLocationsMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($input: SendUserInviteInput!) {
  sendUserInvite(input: $input)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, baseOptions);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const UpdatePersonalInfoDocument = gql`
    mutation UpdatePersonalInfo($input: UpdateMyProfileInput!) {
  updateMyProfile(input: $input) {
    ...ProfileInfo
  }
}
    ${ProfileInfoFragmentDoc}`;
export type UpdatePersonalInfoMutationFn = Apollo.MutationFunction<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>;

/**
 * __useUpdatePersonalInfoMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalInfoMutation, { data, loading, error }] = useUpdatePersonalInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonalInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>) {
        return Apollo.useMutation<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>(UpdatePersonalInfoDocument, baseOptions);
      }
export type UpdatePersonalInfoMutationHookResult = ReturnType<typeof useUpdatePersonalInfoMutation>;
export type UpdatePersonalInfoMutationResult = Apollo.MutationResult<UpdatePersonalInfoMutation>;
export type UpdatePersonalInfoMutationOptions = Apollo.BaseMutationOptions<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>;
export const UserInfoDocument = gql`
    query UserInfo {
  me {
    id
    profile {
      ...ProfileInfo
    }
  }
}
    ${ProfileInfoFragmentDoc}`;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, baseOptions);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, baseOptions);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;