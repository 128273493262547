import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, DialogContentText } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { TopModifierFragment, useFetchTopModifierQuery } from '../../../graphql/types.generated'
import { useArchiveTopModifier } from '../../../hooks/useArchiveTopModifier'
import { UpdateModifierUsage } from '../UpdateModifierUsage/UpdateModifierUsage'
import { UpdateModifierForm } from './UpdateModifierForm'

export interface UpdateModifierProps {
  modifierId?: string
  inlineEdit?: boolean
  onClose?: (updatedModifier?: TopModifierFragment) => void
}

export const UpdateModifier = (props: UpdateModifierProps) => {
  const navigate = useNavigate()

  const { modifierId: paramModifierId, merchantId } = useParams()
  const { inlineEdit, onClose } = props
  const [showDeletePrompt, setShowDeletePrompt] = useState(false)
  const modifierId = props.modifierId ?? paramModifierId

  const { archive, loading: loadingArchive } = useArchiveTopModifier()
  const [showModifierUsageModal, setShowModifierUsageModal] = useState(false)
  const { data, loading, refetch } = useFetchTopModifierQuery({
    variables: {
      id: modifierId!
    }
  })

  async function done(refechModifier?: boolean) {
    if (!inlineEdit) {
      navigate(-1)
    } else if (!!onClose) {
      if (refechModifier) {
        var ret = await refetch()
        onClose(ret.data.modifier)
      } else {
        onClose()
      }
    }
  }

  async function handleDelete() {
    await archive(modifierId!, () => done())
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Section
          title={`Update ${data?.modifier.name}`}
          variant={inlineEdit ? 'subsection' : undefined}
          addon={
            <div style={addonStyle}>
              <Button
                style={buttonStyle}
                onClick={() => {
                  setShowModifierUsageModal(true)
                }}
                disabled={data?.modifier.isSynced}
                label='Apply to Products'
              />
              <Button
                style={buttonStyle}
                disabled={loadingArchive || loading || !!data?.modifier.isSynced}
                theme='warning'
                endIcon={<DeleteIcon />}
                onClick={() => setShowDeletePrompt(true)}
              />
            </div>
          }
        >
          <UpdateModifierForm modifierId={modifierId} onClose={onClose} />
          <UpdateModifierUsage
            merchantId={merchantId!}
            modifierId={modifierId!}
            disabled={loadingArchive || !!data?.modifier.isSynced}
            onClose={() => {
              setShowModifierUsageModal(false)
            }}
            open={showModifierUsageModal}
          ></UpdateModifierUsage>{' '}
          <ConfirmationDialog
            title='Are you sure?'
            open={showDeletePrompt}
            onClose={() => setShowDeletePrompt(false)}
            onConfirm={handleDelete}
          >
            <DialogContentText>Are you sure you want to delete this Modifier?</DialogContentText>
          </ConfirmationDialog>
        </Section>
      )}
    </>
  )
}

const addonStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}

const buttonStyle: React.CSSProperties = {
  marginLeft: 10
}
