import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ModifierOptionsListContainer } from './ViewModifiers/ModifierOptionsList/ModifierOptionsListContainer'
import { TopModifiersListContainer } from './ViewModifiers/TopModifiersList/TopModifiersListContainer'

interface ModifiersContainerProps {
  merchantId: ID
  organizationId: ID
}

export const ModifiersContainer = (props: ModifiersContainerProps) => {
  const { merchantId, organizationId } = props
  const navigate = useNavigate()
  const { pathname } = useLocation()

  function handleCreateModifier() {
    navigate(`${pathname}/new`)
  }

  function handleCreateModifierOption() {
    navigate(`${pathname}/options/new`)
  }

  function handleEditModifierOption(id: string) {
    navigate(`${pathname}/options/${id}/edit`)
  }

  function handleEditModifier(id: string) {
    navigate(`${pathname}/${id}/edit`)
  }

  return (
    <div>
      <TopModifiersListContainer
        onEdit={handleEditModifier}
        merchantId={merchantId}
        organizationId={organizationId}
        createTopModifier={handleCreateModifier}
      />
      <ModifierOptionsListContainer
        createModifierOption={handleCreateModifierOption}
        onEdit={handleEditModifierOption}
        merchantId={merchantId}
        organizationId={organizationId}
      />
    </div>
  )
}
