import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import {
  ContractType,
  Maybe,
  PlatformPricingType,
  useFetchOrganizationContractSignaturesQuery,
  useGetPlatformPricingQuery,
  useSetMerchantSubscriptionMutation
} from 'graphql/types.generated'
import { useCafeLeadDetailsStorage } from 'hooks/useCafeLeadDetailsStorage/useCafeLeadDetailsStorage'
import { useToast } from 'hooks/useToast'
import { logger } from 'logger'
import { PlatformPricingInfo } from 'models/Subscriptions'
import React, { useEffect, useState } from 'react'
import { TermsAgreement } from 'views/onboarding/TermsAgreement'

import { PlatformPlan } from './PlatformPlan'

interface MerchantPlatformPricingProps {
  merchantId: ID
  organizationId: ID
  onComplete: () => void
}

export function MerchantPlatformPricing(props: MerchantPlatformPricingProps) {
  const { merchantId, organizationId, onComplete } = props

  const toast = useToast()

  const { cafeLeadDetails } = useCafeLeadDetailsStorage()

  const [pricingPlan, setPricingPlan] = useState<Maybe<PlatformPricingInfo>>()
  const [termPricing, setTermPricing] = useState<boolean>(false)
  const [hidingTerms, setHidingTerms] = useState(true)

  const [setMerchantSubscriptionPlan, { loading: loadingSetMerchantSubPlan }] = useSetMerchantSubscriptionMutation()
  const { data, loading } = useGetPlatformPricingQuery()

  const instantPlan = data?.getPlatformPricing.find((p) => p.platformPricingType === PlatformPricingType.Instant)
  const standardPlan = data?.getPlatformPricing.find((p) => p.platformPricingType === PlatformPricingType.Standard)
  const signaturePlan = data?.getPlatformPricing.find((p) => p.platformPricingType === PlatformPricingType.Signature)

  useEffect(() => {
    if (!!data?.getPlatformPricing && !pricingPlan) {
      let plan =
        data?.getPlatformPricing.find((p) => p.id === cafeLeadDetails?.platformPricingId) ??
        data?.getPlatformPricing.find((p) => p.platformPricingType === PlatformPricingType.Standard)

      setPricingPlan(plan)

      if (cafeLeadDetails?.termPricing) {
        setTermPricing(cafeLeadDetails.termPricing)
      }
    }
  }, [data, pricingPlan, cafeLeadDetails])

  const { data: signedContractData, loading: loadingContractSignatures } = useFetchOrganizationContractSignaturesQuery({
    variables: {
      id: organizationId
    }
  })

  async function assignPricingPlan() {
    logger.debug('MerchantPlatformPricing', 'assigning pricing plan')
    if (!pricingPlan?.id) {
      toast.error('Please select a plan')
      return
    }
    if (!!signedContractData?.organization?.contractSignatures.length) {
      logger.debug('MerchantPlatformPricing', 'signed contract data exists')
      savePricingPlan()
    } else {
      logger.debug('MerchantPlatformPricing', 'no contract signatures')
      setHidingTerms(false)
    }
  }

  async function savePricingPlan() {
    try {
      await setMerchantSubscriptionPlan({
        variables: {
          input: {
            subscriptionProductId: null,
            merchantId: merchantId,
            trialEndDate: null,
            platformPricingId: pricingPlan!!.id,
            termPricing: termPricing
          }
        }
      })
      toast.success('Pricing Plan Updated')
      onComplete()
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  if (loading || !data?.getPlatformPricing) {
    return <CircularProgress />
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          <PlatformPlan
            platformPricing={instantPlan!!}
            select={(termPricing: boolean) => {
              setTermPricing(termPricing)
              setPricingPlan(instantPlan)
            }}
            selected={pricingPlan === instantPlan}
            termPricing={termPricing}
          />
          <PlatformPlan
            platformPricing={standardPlan!!}
            select={(termPricing: boolean) => {
              setTermPricing(termPricing)
              setPricingPlan(standardPlan)
            }}
            selected={pricingPlan === standardPlan}
            termPricing={termPricing}
          />
          <PlatformPlan
            platformPricing={signaturePlan!!}
            select={(termPricing: boolean) => {
              setTermPricing(termPricing)
              setPricingPlan(signaturePlan)
            }}
            selected={pricingPlan === signaturePlan}
            termPricing={termPricing}
          />
        </div>
        <Button
          style={{ alignSelf: 'center', marginTop: 5 }}
          onClick={assignPricingPlan}
          disabled={!pricingPlan}
          label='Get Started'
          endIcon={(loadingSetMerchantSubPlan || loadingContractSignatures) && <CircularProgress size={16} />}
        />
      </div>

      <TermsAgreement
        organizationId={organizationId!!}
        contractType={ContractType.StandardContract}
        hidden={hidingTerms}
        onComplete={savePricingPlan}
        onCancel={() => setHidingTerms(true)}
        onAdminSkip={savePricingPlan}
      />
    </>
  )
}
