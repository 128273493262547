import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { DiningOption, useViewMerchantQuery } from 'graphql/types.generated'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { KdsIntegrationDetailsContainer } from 'views/devices/components/KdsIntegrationList/KdsIntegrationDetailsContainer/KdsIntegrationDetailsContainer'
import { CreateMerchantContact } from 'views/merchants/components/MerchantContacts/CreateMerchantContact'
import { MerchantContacts } from 'views/merchants/components/MerchantContacts/MerchantContacts'
import { UpdateMerchantContact } from 'views/merchants/components/MerchantContacts/UpdateMerchantContact'
import { MerchantImages } from 'views/merchants/components/MerchantImages/MerchantImages'
import { CreateMerchantPromotion } from 'views/merchants/components/MerchantPromotions/CreateMerchantPromotion/CreateMerchantPromotion'
import { ViewMerchantPromotion } from 'views/merchants/components/MerchantPromotions/ViewMerchantPromotion/ViewMerchantPromotion'
import { ViewMerchantPromotions } from 'views/merchants/components/MerchantPromotions/ViewMerchantPromotions/ViewMerchantPromotions'
import { CreateServingLocation } from 'views/merchants/components/ServingLocations/CreateServingLocation'
import { ServingLocationsContainer } from 'views/merchants/components/ServingLocations/ServingLocationsContainer'
import { UpdateMerchant } from 'views/merchants/UpdateMerchant/UpdateMerchant'
import { SquareKdsIntegrationContainer } from 'views/square/SquareKdsIntegrationContainer'

import { DevicesContainer } from './DevicesContainer'
import { MerchantLinkContainer } from './Links/MerchantLinkContainer'

interface CafeHomeContainerProps {
  merchantId: ID
  organizationId: ID
}

export function CafeHomeContainer(props: CafeHomeContainerProps) {
  const { merchantId, organizationId } = props

  const { data, loading } = useViewMerchantQuery({
    variables: {
      id: merchantId!
    },
    fetchPolicy: 'cache-first'
  })

  const servingLocationRoutes =
    !loading && !!data?.merchant?.diningOptions?.find((x) => x === DiningOption.TableService)
      ? [
          {
            link: `/organizations/${organizationId}/merchants/${merchantId}/home/servingLocations`,
            paths: [`/organizations/:organizationId/merchants/:merchantId/home/servingLocations/*`],
            text: 'Serving Locations'
          }
        ]
      : []

  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/home/details`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/home/details`],
          text: 'Cafe Details'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/home/contacts`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/home/contacts/*`],
          text: 'Contacts'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/home/devices`,
          paths: [
            `/organizations/:organizationId/merchants/:merchantId/home/devices`,
            `/organizations/:organizationId/merchants/:merchantId/home/kdsIntegrations/*`
          ],
          text: 'Tablets'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/home/images`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/home/images`],
          text: 'Photos'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/home/links`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/home/links`],
          text: 'Links'
        },
        {
          link: `/organizations/${organizationId}/merchants/${merchantId}/home/promotions`,
          paths: [`/organizations/:organizationId/merchants/:merchantId/home/promotions/*`],
          text: 'Promotions'
        },
        ...servingLocationRoutes
        // {
        //   link: `/organizations/${organizationId}/merchants/${merchantId}/home/settings`,
        //   paths: [`/organizations/:organizationId/merchants/:merchantId/home/settings/*`],
        //   text: 'Settings'
        // }
      ]}
    >
      <Routes>
        <Route
          path={`devices`}
          element={<DevicesContainer merchantId={merchantId} organizationId={organizationId} />}
        />
        <Route path={`details`} element={<UpdateMerchant />} />
        <Route path='kdsIntegrations/square/new' element={<SquareKdsIntegrationContainer />} />
        <Route path={`kdsIntegrations/:integrationId`} element={<KdsIntegrationDetailsContainer />} />
        <Route path={`images`} element={<MerchantImages merchantId={merchantId} organizationId={organizationId} />} />
        <Route
          path={`contacts`}
          element={<MerchantContacts organizationId={organizationId} merchantId={merchantId} />}
        />
        <Route path={`contacts/new`} element={<CreateMerchantContact />} />
        <Route path={`contacts/:merchantContactId/edit`} element={<UpdateMerchantContact />} />
        <Route path={`links`} element={<MerchantLinkContainer merchantId={merchantId} />} />
        <Route path={`promotions`} element={<ViewMerchantPromotions />} />
        <Route path={`promotions/new`} element={<CreateMerchantPromotion />} />
        <Route path={`promotions/:promotionId`} element={<ViewMerchantPromotion />} />
        <Route path={`servingLocations`} element={<ServingLocationsContainer />} />
        <Route path={`servingLocations/new`} element={<CreateServingLocation />} />

        {/* <Route path={`settings`} element={<MerchantSettingsContainer merchantId={merchantId} />} /> */}

        <Route path={`*`} element={<Navigate to={'details'} replace={true} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
