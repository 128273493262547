import { Button } from '@bloom-coffee/steamed-milk'
import { IconButton, lighten, makeStyles, Paper } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { TextInPlaceEditor } from 'components/TextInPlaceEditor'
import {
  CategorySelectDocument,
  useArchiveCategoryMutation,
  useUpdateCategoryNameMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { CategoryInfo } from 'models/Merchant'
import { ProductInfo } from 'models/Product'
import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { ProductCard } from './ProductCard'

interface CategoryCardProps {
  merchantId: string
  organizationId: string
  category: CategoryInfo
  products: ProductInfo[]
  index: number
  selectedProductIds: string[]
  productSelectedChanged: (productId: string, selected: boolean) => void
}

export function CategoryCard(props: CategoryCardProps) {
  const { merchantId, organizationId, category, products, index, selectedProductIds, productSelectedChanged } = props
  const styles = useStyles()
  const toast = useToast()

  const [execute, { loading }] = useUpdateCategoryNameMutation()
  const [archiveCategory] = useArchiveCategoryMutation()
  const [expanded, setExpanded] = React.useState(true)
  async function updateCategoryName(newName: string): Promise<boolean> {
    try {
      await execute({
        variables: {
          categoryId: category.id,
          name: newName
        },
        refetchQueries: [{ query: CategorySelectDocument, variables: { merchantId } }]
      })
      toast.success(`Updated Name to ${newName}`)
      return true
    } catch (e: any) {
      toast.error(e.toString())
    }
    return false
  }

  async function handleArchive() {
    try {
      await archiveCategory({
        variables: {
          categoryId: category.id
        },
        refetchQueries: [{ query: CategorySelectDocument, variables: { merchantId } }]
      })
      toast.success(`Deleted ${category.name}`)
      return true
    } catch (e: any) {
      toast.error(e.toString())
    }
    return false
  }

  return (
    <Draggable key={category.id} draggableId={category.id} index={index}>
      {(provided, snapshot) => (
        <Paper
          variant={snapshot.isDragging ? 'elevation' : 'outlined'}
          className={clsx(
            styles.listItem,
            snapshot.isDragging && styles.listItemDragging,
            !products.length && styles.noProductsStyle
          )}
          elevation={snapshot.isDragging ? 4 : 0}
          {...provided.draggableProps}
          innerRef={provided.innerRef}
        >
          <span {...provided.dragHandleProps} style={dragHandleStyle}>
            <DragIndicatorIcon />
          </span>
          <Droppable droppableId={category.id} type='productSort'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                className={clsx(styles.dropContainer, snapshot.isDraggingOver && styles.dropContainerDragging)}
                ref={provided.innerRef}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <IconButton style={{ height: 32, width: 32 }} onClick={() => setExpanded((prev: boolean) => !prev)}>
                      {!expanded && <ExpandMoreIcon style={{ height: 32, width: 32 }} />}
                      {expanded && <ExpandLessIcon style={{ height: 32, width: 32 }} />}
                    </IconButton>
                    <TextInPlaceEditor
                      style={nameStyle}
                      variant={'h6'}
                      value={category.name}
                      label='Category Name'
                      onSubmit={updateCategoryName}
                      onCancel={() => {}}
                      loading={loading}
                    />
                  </div>
                  <Button onClick={handleArchive} endIcon={<DeleteIcon />} />
                </div>

                {expanded &&
                  products.map((product: ProductInfo, index: number) => {
                    return (
                      <ProductCard
                        key={product.id}
                        organizationId={organizationId}
                        merchantId={merchantId}
                        product={product}
                        index={index}
                        checked={selectedProductIds.indexOf(product.id) >= 0}
                        checkChanged={(checked) => productSelectedChanged(product.id, checked)}
                      />
                    )
                  })}

                {expanded && !products.length && <span>No Products</span>}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Paper>
      )}
    </Draggable>
  )
}

const useStyles = makeStyles((theme) => ({
  dropContainer: {
    transition: '0.1s all ease-in-out',
    width: '100%'
  },
  dropContainerDragging: {
    backgroundColor: lighten(theme.palette.primary.light, 0.8)
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    width: '100%'
  },
  listItemName: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  listItemDragging: {
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  noProductsStyle: {
    backgroundColor: lighten(theme.palette.primary.light, 0.8)
  }
}))

const nameStyle: React.CSSProperties = {
  marginLeft: 10,
  fontSize: 24
}

const dragHandleStyle: React.CSSProperties = {
  marginTop: 5
}
