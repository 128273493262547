import { Text } from '@bloom-coffee/steamed-milk'
import { TextField } from '@material-ui/core'
import { Maybe } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { get, useController, useFormContext } from 'react-hook-form'

import { TextFieldProps } from './models'

export interface MaskedFieldProps extends TextFieldProps {
  formatValue: (value: any | null) => string
  parseValue: (rawValue: string) => any
  defaultValue?: Maybe<any>
  variant?: 'outlined' | 'standard'
}

export const MaskedField = (props: MaskedFieldProps) => {
  const { formatValue, parseValue, name, label, defaultValue, width, error: propError, style, variant, ...rest } = props
  const [error, setError] = useState<string | undefined>()

  const methods = useFormContext()
  useEffect(() => {
    const errMessage = get(methods.errors, name)?.message
    if (errMessage) {
      setError(errMessage)
    }
  }, [methods.errors, name])

  const {
    // name causes a bug on defaultValue
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { name: excludedName, value, onChange, ...fieldProps }
  } = useController({
    name,
    defaultValue
  })

  return (
    <>
      <TextField
        variant={variant ?? 'outlined'}
        error={!!error}
        style={style}
        {...rest}
        {...fieldProps}
        label={label}
        fullWidth
        name={name}
        value={formatValue(value)}
        onChange={(e) => onChange(parseValue(e.target.value))}
      />
      {error && (
        <div style={{ marginTop: 4 }}>
          <Text variant='error'>{error}</Text>
        </div>
      )}
    </>
  )
}
