const verySimpleEmailRegex = /^.+@.+\..+$/

export function isValidEmail(str: string): boolean {
  return verySimpleEmailRegex.test(str)
}

export function isReasonableFirstName(str: string): boolean {
  return str.length > 0
}

export function isReasonableLastName(str: string): boolean {
  return str.length > 0
}

export function isValidUsPhoneWithoutCountryCode(inputVal: string) {
  if (!parseInt(inputVal, 10)) {
    return false
  } else {
    return inputVal.length === 10
  }
}

export const transform = {
  number(v: any, o: any) {
    return o === '' ? null : v
  }
}
