import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Maybe, SubscriptionStatus, useMerchantSubscriptionsByOrganizationIdQuery } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { logger } from 'logger'
import { SubscriptionPaymentIntentInfo } from 'models/Subscriptions'
import React, { useEffect, useState } from 'react'
import { theme } from 'theme/theme'
import { formatUsCentsAsUsDollar } from 'util/formatting'

interface StripeActivationCheckoutFormProps {
  organizationId: string
  merchantIds: string[]
  subscriptionPaymentIntent: Maybe<SubscriptionPaymentIntentInfo>
  onComplete: () => void
  onCancel: () => void
}

export function StripeActivationCheckoutForm(props: StripeActivationCheckoutFormProps) {
  const { organizationId, onComplete, onCancel, subscriptionPaymentIntent, merchantIds } = props

  const tag = 'StripeActivationCheckoutForm'

  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [pollingUntil, setPollingUntil] = useState<number | null>()

  const { refetch } = useMerchantSubscriptionsByOrganizationIdQuery({
    variables: {
      organizationId: organizationId
    }
  })

  async function handleSubmit(event: { preventDefault: () => void }) {
    event.preventDefault()
    setLoading(true)

    if (!stripe || !elements) {
      return
    }

    if (!!subscriptionPaymentIntent?.clientSecret) {
      logger.debug(tag, 'confirming card payment')
      const result = await stripe.confirmCardPayment(subscriptionPaymentIntent?.clientSecret!, {
        payment_method: {
          card: elements.getElement(CardElement)!
        }
      })

      if (result.error) {
        toast.error(result.error.message || 'Unable to process payment')
        setLoading(false)
      } else {
        logger.debug(tag, 'confirmed payment, starting polling')
        setPollingUntil(Date.now() + 30000)
      }
    }
  }

  useEffect(() => {
    async function delay(time: number) {
      return new Promise((resolve) => setTimeout(resolve, time))
    }
    async function isPaymentProcessed() {
      try {
        var res = await refetch()
        var merchantSubscriptions =
          res?.data?.merchantSubscriptionsByOrganizationId.filter((ms) => merchantIds.indexOf(ms.merchant.id) >= 0) ??
          []

        logger.debug(tag, `${JSON.stringify(res.data.merchantSubscriptionsByOrganizationId)}`)

        return (
          merchantSubscriptions.length > 0 &&
          merchantSubscriptions.every((e) => e.subscriptionStatus !== SubscriptionStatus.PendingPayment)
        )
      } catch (e: any) {
        toast.error(`Error fetching payment status: ${e}`)
        return false
      }
    }
    async function startPolling() {
      while (Date.now() < pollingUntil!) {
        var paymentIsProcessed = await isPaymentProcessed()
        if (paymentIsProcessed) {
          setLoading(false)
          onComplete()
          setPollingUntil(null)
          return
        }
        await delay(1000)
      }
      toast.error(
        'Timed out waiting for payment status. Please refresh this page to see the updated subscription status'
      )
      setPollingUntil(null)
    }
    if (!pollingUntil) {
      return
    }
    startPolling()
  }, [merchantIds, onComplete, pollingUntil, refetch, toast])

  return (
    <form onSubmit={handleSubmit}>
      <Box padding={1}>
        {(!!subscriptionPaymentIntent?.lineItems || !!subscriptionPaymentIntent?.costUsCents) && (
          <Box display='flex' flexDirection='column' paddingBottom={3}>
            {!!subscriptionPaymentIntent?.lineItems &&
              subscriptionPaymentIntent?.lineItems.map((li, index) => (
                <Box display='flex' flexDirection='row' justifyContent='space-between' key={`${li}.${index}`}>
                  <Typography variant='caption'>{li.name}</Typography>
                  <Typography variant='caption'>{formatUsCentsAsUsDollar(li.costUsCents)}</Typography>
                </Box>
              ))}
            {!!subscriptionPaymentIntent?.costUsCents && (
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Typography variant='subtitle2'>Total</Typography>
                <Typography variant='subtitle2'>
                  {formatUsCentsAsUsDollar(subscriptionPaymentIntent?.costUsCents)}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Section title='Enter your Credit Card Information:' variant='subsection'>
          <Box padding={1} border={`1px solid ${theme.palette.divider}`}>
            <CardElement />
          </Box>
          <Box padding={1}>
            <Typography variant='caption'>
              This will be set as the default payment method for future payments
            </Typography>
          </Box>
        </Section>
        <div style={actionsStyle}>
          <Button style={buttonStyle} onClick={() => onCancel()} disabled={!stripe} label='Cancel' theme='cancel' />
          <Button
            style={buttonStyle}
            type='submit'
            disabled={!stripe || loading}
            label=' Submit Payment'
            onClick={handleSubmit}
            endIcon={loading && <CircularProgress color='inherit' size={20} />}
          />
        </div>
      </Box>
    </form>
  )
}

const actionsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const buttonStyle: React.CSSProperties = {
  marginRight: 5
}
