import { Color } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { RequisitionSchedule } from './models'
import { RequisitionContainer } from './RequisitionContainer'

interface RequisitionScheduleProps {
  requisitionSchedule: RequisitionSchedule
}
export const RequisitionScheduleContainer = (props: RequisitionScheduleProps) => {
  const { requisitionSchedule } = props
  return (
    <div>
      <div style={{ marginBottom: 8 }}>Next Scheduled Payment: {requisitionSchedule.nextScheduledPayment}</div>
      <div style={{ height: 1, backgroundColor: Color.GREY_600, marginTop: 8, marginBottom: 8 }} />
      {!!requisitionSchedule.requisition && (
        <div style={{ marginBottom: 8 }}>
          <div>
            <RequisitionContainer requisition={requisitionSchedule.requisition} />
          </div>
        </div>
      )}
    </div>
  )
}
