import { isValidEmail, isValidUsPhoneWithoutCountryCode } from 'validation/ValidationUtils'
import * as yup from 'yup'

export const signUpSchema = yup.object({
  organizationName: yup.string().label('Company/Cafe Name').required(),
  contactEmail: yup
    .string()
    .label('Email')
    .test({
      message: 'Invalid email',
      test: (inputVal) => {
        if (inputVal) {
          return isValidEmail(inputVal)
        }
        return true
      }
    })
    .required(),
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  phoneNumber: yup
    .string()
    .label('Phone Number')
    .nullable()
    .test({
      message: 'Invalid phone number',
      test: (inputVal) => {
        if (inputVal) {
          return isValidUsPhoneWithoutCountryCode(inputVal)
        }
        return true
      }
    })
    .required()
})

export type SignUpFormModel = yup.InferType<typeof signUpSchema>
