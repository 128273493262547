import { TokenTypeName } from 'graphql/types.generated'
import * as yup from 'yup'

export const grantTokensSchema = yup.object({
  tokenType: yup.mixed<TokenTypeName>().label('Token Type').required(),
  numberOfTokens: yup.number().label('Number of Tokens').required().min(1),
  userId: yup.string().label('User ID').required()
})

export type GrantTokensModel = yup.InferType<typeof grantTokensSchema>
