import { ApolloCache, FetchResult } from '@apollo/client'
import { ArchivePromotionMutation, PromotionInfoFragment, useArchivePromotionMutation } from 'graphql/types.generated'

import { useToast } from '../useToast'

export function useArchivePromotion() {
  const toast = useToast()
  const [execute, { loading }] = useArchivePromotionMutation()

  function updateCache(
    cache: ApolloCache<ArchivePromotionMutation>,
    mutationResult: FetchResult<ArchivePromotionMutation>
  ) {
    const idToRemove = mutationResult.data?.archivePromotion.id

    if (idToRemove) {
      cache.modify({
        fields: {
          promotions(existingPromotions = [], { readField }) {
            return existingPromotions.filter((pif: PromotionInfoFragment) => idToRemove !== readField('id', pif))
          }
        }
      })
    }
  }

  async function archive(id: string, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { id },
        update: updateCache
      })

      if (res.data?.archivePromotion?.archived) {
        onSuccess()
      } else {
        throw new Error(`${res.errors}`)
      }
    } catch (e) {
      toast.error(`Could not archive promo ${e}`)
    }
  }

  return { archive, loading }
}
