import { Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { useUpdateOrganizationDetailsQuery, useUpdateOrganizationMutation } from 'graphql/types.generated'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ArchiveContainer } from '../../merchants/UpdateMerchant/ArchiveContainer'
import { OrganizationForm, OrganizationFormModel } from '../components/OrganizationForm'

export function UpdateOrganization() {
  const { organizationId } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useUpdateOrganizationDetailsQuery({
    variables: {
      id: organizationId!
    }
  })

  const [execute] = useUpdateOrganizationMutation()

  async function saveOrganization(values: OrganizationFormModel) {
    await execute({
      variables: {
        input: {
          id: organizationId!,
          ...values
        }
      }
    })
    navigate(-1)
  }

  return (
    <Section
      title='Update Organization'
      addon={
        <ArchiveContainer variant='organization' organizationId={organizationId!} onSuccess={() => navigate('/')} />
      }
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <OrganizationForm
          initialValues={data?.organization || undefined}
          onCancel={() => navigate(-1)}
          onSubmit={saveOrganization}
        />
      )}
    </Section>
  )
}
