import { MenuItem, Select } from '@material-ui/core'
import { OrganizationInfo } from 'models/Organization'
import { useNavigate } from 'react-router-dom'

interface OrganizationSwitcherProps {
  organizations: OrganizationInfo[]
  onSwitch: () => void
}

export function OrganizationSwitcher(props: OrganizationSwitcherProps) {
  const { organizations, onSwitch } = props
  const navigate = useNavigate()
  return (
    <Select
      fullWidth={true}
      onChange={(e) => {
        navigate(`/organizations/${e.target.value}`)
        onSwitch()
      }}
      defaultValue=''
    >
      <MenuItem value=''>
        <em>Select an Organization</em>
      </MenuItem>
      {[...organizations]
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((org) => {
          return (
            <MenuItem key={org.id} value={org.id}>
              {org.name}
            </MenuItem>
          )
        })}
    </Select>
  )
}
