import { useDetectOutsideClick } from '@bloom-coffee/steamed-milk'
import Info from '@material-ui/icons/Info'
import React, { PropsWithChildren, useRef, useState } from 'react'
import { ColorHex } from 'theme/ThemeConstants'

interface InfoButtonProps {
  title: React.ReactNode
  horizontalDirection?: 'left' | 'right'
  style?: React.CSSProperties
}

export function InfoButton(props: PropsWithChildren<InfoButtonProps>) {
  const { children, title, horizontalDirection = 'right', style = {} } = props
  const [buttonHovered, setButtonHovered] = useState(false)
  const [contentHovered, setContentHovered] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)
  const { isActive, setIsActive } = useDetectOutsideClick(contentRef, false)

  return (
    <div style={buttonContainerStyle}>
      <Info
        fontSize='small'
        onClick={() => setIsActive(true)}
        onMouseEnter={(e) => {
          setButtonHovered(true)
        }}
        onMouseLeave={(e) => {
          setButtonHovered(false)
        }}
        htmlColor={ColorHex.RDY_MALIBU}
        style={{ ...{ backgroundColor: ColorHex.RDY_FOREST, borderRadius: 15 }, ...style }}
      />

      <div
        ref={contentRef}
        style={{
          ...contentStyle,
          ...(buttonHovered || contentHovered || isActive ? contentActiveStyle : {}),
          ...(horizontalDirection === 'right' ? rightStyle : leftStyle)
        }}
        onMouseEnter={(e) => {
          setContentHovered(true)
        }}
        onMouseLeave={(e) => {
          setContentHovered(false)
        }}
      >
        <div style={titleStyle}>{title}</div>
        <div>{children}</div>
      </div>
    </div>
  )
}

const titleStyle: React.CSSProperties = {
  fontFamily: 'Raw-Bold',
  textAlign: 'center',
  fontSize: 18,
  paddingBottom: 5
}
const buttonContainerStyle: React.CSSProperties = {
  position: 'relative'
}
const rightStyle: React.CSSProperties = {
  top: 20,
  left: 0
}
const leftStyle: React.CSSProperties = {
  top: 20,
  left: 200
}

const contentStyle: React.CSSProperties = {
  position: 'absolute',
  width: 200,
  background: ColorHex.RDY_BEIGE,
  border: '1px solid',
  borderColor: ColorHex.RDY_FOREST,
  borderRadius: 5,
  padding: 5,
  boxShadow: '0 1px 8px rgba(0, 0, 0, 0.3)',
  visibility: 'hidden',
  transform: 'translateY(-50%) scaleY(0)',
  transition: 'opacity 0.2s ease, transform 0.4s ease, visibility 0.2s',
  zIndex: 100
}

const contentActiveStyle: React.CSSProperties = {
  opacity: 1,
  visibility: 'visible',
  transform: 'translateY(0%) scaleY(1)'
}
