import { Maybe, ModifierOptionFragment } from '../../../graphql/types.generated'
import { ModifierOptionFormModel } from '../CreateModifierOption/modifierOptionValidator'

export function convertModifierOptionToFormModel(
  modifier: Maybe<ModifierOptionFragment>
): ModifierOptionFormModel | undefined {
  if (!modifier) {
    return undefined
  }

  return {
    name: modifier.name,
    selectedName: modifier.selectedName,
    priceCents: modifier.priceCents,
    active: modifier.active,
    printableName: modifier.printableName,
    currentQuantity: modifier.inventory?.currentQuantity === undefined ? null : modifier.inventory?.currentQuantity,
    currentlyAvailable:
      modifier.inventory?.currentlyAvailable === false ? false : modifier.inventory?.currentlyAvailable ?? true
  }
}
