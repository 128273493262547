import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { Navigate, Route, Routes } from 'react-router-dom'

import { UpdateOrganization } from '../UpdateOrganization/UpdateOrganization'
import { OrganizationSettingsContainer } from './OrganizationSettings/OrganizationSettingsContainer'
import { OrganizationUserList } from './OrganizationUserList'
import { WebOrderingOrganizationUrl } from './WebOrderingUrl/WebOrderingOrganizationUrl'

interface SettingsContainerProps {
  organizationId: ID
}

export function SettingsContainer(props: SettingsContainerProps) {
  const { organizationId } = props

  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/organizations/${organizationId}/settings/users`,
          paths: [`/organizations/:organizationId/settings/users`],
          text: 'Users'
        },
        {
          link: `/organizations/${organizationId}/settings/edit`,
          paths: [`/organizations/:organizationId/settings/edit`],
          text: 'Edit Organization'
        },
        {
          link: `/organizations/${organizationId}/settings/webordering`,
          paths: [`/organizations/:organizationId/settings/webordering`],
          text: 'Web Ordering'
        },
        {
          link: `/organizations/${organizationId}/settings/settings`,
          paths: [`/organizations/:organizationId/settings/settings/*`],
          text: 'Settings'
        }
      ]}
    >
      <Routes>
        <Route path={`edit`} element={<UpdateOrganization />} />
        <Route path={`users`} element={<OrganizationUserList organizationId={organizationId} />} />
        <Route path={`webordering`} element={<WebOrderingOrganizationUrl organizationId={organizationId} />} />
        <Route
          path={`*`}
          element={<Navigate to={`/organizations/${organizationId}/settings/users`} replace={true} />}
        />
        <Route path={`settings`} element={<OrganizationSettingsContainer organizationId={organizationId} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
