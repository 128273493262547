import { Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { useToast } from 'hooks/useToast'
import { useState } from 'react'
import { freshToGoLogin } from 'util/freshToGoUtil'

import { FreshToGoLoginForm, FreshToGoLoginFormModel } from './FreshToGoLoginForm'

interface FreshToGoLoginProps {
  onCancel: () => void
  onSuccess: (token: string) => void
}

export function FreshToGoLogin(props: FreshToGoLoginProps) {
  const { onCancel, onSuccess } = props
  const toast = useToast()
  const [loggingIn, setLoggingIn] = useState(false)

  async function login(data: FreshToGoLoginFormModel) {
    try {
      setLoggingIn(true)
      onSuccess(await freshToGoLogin(data.username, data.password))
    } catch (e: any) {
      toast.error('Unable to log in. Please check your username and password and try again.')
    } finally {
      setLoggingIn(false)
    }
  }

  return (
    <Section variant='subsection' title='Log into your Fresh account' style={{ maxWidth: 400 }}>
      <FreshToGoLoginForm onSubmit={login} onCancel={onCancel} disabled={loggingIn} />
      {loggingIn && <CircularProgress />}
    </Section>
  )
}
