import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Card, CardContent, DialogContentText, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { HoursDisplay } from 'components/hours/HoursDisplay'
import { DayHoursFormModel } from 'components/hours/validator'
import { CatalogListDocument, useDeleteCatalogMutation, useUpdateCatalogMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import { CatalogInfo } from 'models/Merchant'
import React, { useState } from 'react'
import { convertDayHoursModel } from 'util/merchantHours'

import { convertToSaveModel, groupCategoryAvailabilityWindows } from './CatalogUtil'

interface CatalogProps {
  catalog: CatalogInfo
  merchantId: string
}

export function Catalog(props: CatalogProps) {
  const { catalog, merchantId } = props
  const toast = useToast()
  const [hours, setHours] = useState(groupCategoryAvailabilityWindows(catalog.availabilityWindows))
  const [addHours, setAddHours] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const [execute] = useUpdateCatalogMutation()
  const [deleteCatalog] = useDeleteCatalogMutation()

  async function updateHours(value: DayHoursFormModel) {
    let updatedAvailabilityWindows = hours.filter((aw) => !value.weekDays.includes(aw.weekDay))
    updatedAvailabilityWindows.push(...convertDayHoursModel(value))

    let catalogAvailWindows = convertToSaveModel(updatedAvailabilityWindows)
    try {
      await execute({
        variables: {
          input: {
            id: catalog.id,
            availabilityWindows: catalogAvailWindows ?? [],
            description: catalog.description,
            name: catalog.name,
            productIds: catalog.products?.map((p) => p.id) ?? []
          }
        }
      })
      setHours(updatedAvailabilityWindows)
      toast.success('Updated Menu')
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  async function deleteCategory() {
    try {
      await deleteCatalog({
        variables: { id: catalog.id },
        refetchQueries: [{ query: CatalogListDocument, variables: { merchantId } }]
      })
      setShowDelete(false)
      toast.success('Deleted Menu')
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  return (
    <Section
      title={catalog.name}
      key={catalog.id}
      variant='subsection'
      addon={<Button onClick={() => setShowDelete(true)} endIcon={<DeleteIcon />} />}
    >
      <Card variant='outlined'>
        <CardContent>
          {(hours.length || addHours) && <HoursDisplay values={hours} saveHours={updateHours} />}
          {!catalog.availabilityWindows?.length && !addHours && (
            <div>
              <Typography>Available during all open hours</Typography>
              <Button label={'Add specific hours'} onClick={() => setAddHours(true)} />
            </div>
          )}
        </CardContent>
      </Card>
      <ConfirmationDialog
        title='Are you sure?'
        open={!!showDelete}
        onClose={() => setShowDelete(false)}
        onConfirm={deleteCategory}
      >
        <DialogContentText>Are you sure you want to delete this Menu?</DialogContentText>
      </ConfirmationDialog>
    </Section>
  )
}
