import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

interface CheckboxFieldProps {
  label: string
  name: string
  defaultValue?: boolean
}

export function CheckboxField(props: CheckboxFieldProps) {
  const { name, defaultValue, label } = props
  const { control } = useFormContext()

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={(controlProps) => (
            <MuiCheckbox
              {...controlProps}
              onChange={(e) => controlProps.onChange(e.target.checked)}
              checked={controlProps.value}
            />
          )}
        />
      }
      label={label}
    />
  )
}
