import { Section } from '@bloom-coffee/steamed-milk'
import React from 'react'
import { KdsIntegarationList } from 'views/devices/components/KdsIntegrationList/KdsIntegrationList'
import { KdsList } from 'views/devices/components/KdsList/KdsList'

interface DevicesContainerProps {
  merchantId: ID
  organizationId: ID
}

export function DevicesContainer(props: DevicesContainerProps) {
  const { merchantId, organizationId } = props

  return (
    <>
      <Section
        title='RDY Cafe Apps'
        subTitle={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              Download the RDY Cafe app to manage incoming orders, process returns, etc. After logging in, link your
              tablet to this Cafe on the RDY Cafe app. Your Tablet’s Unique Identifier will appear here.
            </span>
            <a href='https://play.google.com/store/apps/details?id=com.bloom.macchiato&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img
                style={googlePlayBadgeStyle}
                alt='Get it on Google Play'
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
              />
            </a>
          </div>
        }
        variant='grid'
      >
        <KdsList merchantId={merchantId} organizationId={organizationId} />
      </Section>
      <Section
        title='RDY Partner Integrations'
        subTitle='If you wish to send orders to a third party instead of the RDY App, you may do so here'
        variant='grid'
      >
        <KdsIntegarationList merchantId={merchantId} organizationId={organizationId} />
      </Section>
    </>
  )
}

const googlePlayBadgeStyle: React.CSSProperties = {
  maxWidth: 250
}
