import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { PhoneField } from 'components/form/PhoneField'
import React from 'react'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'
import { stripNonNumericCharacters } from 'util/formatting'

import { DefaultFormFooter } from '../../../../components/form/DefaultFormFooter'
import { TextField } from '../../../../components/form/TextField'
import { MerchantContactFormModel, merchantContactSchema } from './merchantContactValidator'

interface MerchantContactFormProps {
  onCancel: () => void
  onSubmit: (values: MerchantContactFormModel) => void
  initialValues: DefaultValues<MerchantContactFormModel> | undefined
  disableSubmit?: boolean
}

export function MerchantContactForm(props: MerchantContactFormProps) {
  const { onCancel, onSubmit, initialValues, disableSubmit } = props

  const formProps = useForm<MerchantContactFormModel>({
    defaultValues: initialValues,
    resolver: yupResolver(merchantContactSchema),
    shouldUnregister: false
  })
  const { register, handleSubmit, formState } = formProps

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name='firstName'
              label='First Name'
              inputRef={register()}
              defaultValue={initialValues?.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name='lastName'
              label='Last Name'
              inputRef={register()}
              defaultValue={initialValues?.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth name='email' label='Email' inputRef={register()} defaultValue={initialValues?.email} />
          </Grid>
          <Grid item xs={6}>
            <PhoneField
              name='phoneNumber'
              label='Phone Number'
              defaultValue={
                initialValues?.phoneNumber ? Number(stripNonNumericCharacters(initialValues?.phoneNumber)) : null
              }
            />
          </Grid>

          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
