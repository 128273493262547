import { Button, Section } from '@bloom-coffee/steamed-milk'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { MerchantPromotionsList } from './components/MerchantPromotionsList'

export const ViewMerchantPromotions = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Section
      title='Promotions'
      addon={
        <Button
          endIcon={<AddIcon />}
          onClick={() => navigate(`${pathname}/new`)}
          label='
          Create Promotion'
        />
      }
      variant='grid'
    >
      <MerchantPromotionsList />
    </Section>
  )
}
