import { Section } from '@bloom-coffee/steamed-milk'
import { useCreateSubscriptionFee } from 'hooks/useCreateSubscriptionFee/useCreateSubscriptionFee'
import { useNavigate } from 'react-router-dom'

import { SubscriptionFeeForm } from '../components/SubscriptionFeeForm'
import { SubscriptionFeeFormModel } from '../components/subscriptionFeeValidator'

export const CreateSubscriptionFee = () => {
  const navigate = useNavigate()

  const { loading, create } = useCreateSubscriptionFee()

  function handleSubmit(input: SubscriptionFeeFormModel) {
    create(input, () => navigate(-1))
  }

  return (
    <Section title={`Create new Subscription Fee`}>
      <SubscriptionFeeForm onCancel={() => navigate(-1)} onSubmit={handleSubmit} disableSubmit={loading} />
    </Section>
  )
}
