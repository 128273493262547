import { CircularProgress } from '@material-ui/core'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { useMerchantSubscriptionByMerchantIdQuery } from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import { AddMerchantSubscriptionFee } from 'views/admin/subscriptions/AddMerchantSubscriptionFee/AddMerchantSubscriptionFee'
import { SetMerchantSubscription } from 'views/admin/subscriptions/SetMerchantSubscription/SetMerchantSubscription'

interface ManageMerchantSubscriptionProps {
  merchantId: ID
}

export function ManageMerchantSubscription(props: ManageMerchantSubscriptionProps) {
  const { merchantId } = props
  const { data, loading: loadingSubscription } = useMerchantSubscriptionByMerchantIdQuery({
    variables: {
      merchantId: merchantId
    }
  })
  const merchantSubscription = data?.merchantSubscriptionByMerchantId

  return (
    <>
      {loadingSubscription && <CircularProgress />}
      {!loadingSubscription && (
        <HasAnyRole roleMatchers={[roles.admin]}>
          <SetMerchantSubscription merchantId={merchantId} merchantSubscription={merchantSubscription} />
          <AddMerchantSubscriptionFee merchantId={merchantId} />
        </HasAnyRole>
      )}
    </>
  )
}
