import { Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { Requisition } from './models'

export const RequisitionCustomerDetails = (props: { requisition: Requisition }) => {
  const { requisition } = props
  return (
    <div>
      <div>
        <Text variant='header3'>Name</Text>
      </div>
      <div style={{ marginBottom: 8 }}>{`${requisition.firstName} ${requisition.lastName}`}</div>

      <div>
        <Text variant='header3'>Location</Text>
        <div>{requisition.fulfillmentLocation?.street}</div>
        <div>{requisition.fulfillmentLocation?.street2}</div>
        <div>
          {`${requisition.fulfillmentLocation?.city}, ${requisition.fulfillmentLocation?.state} ${requisition.fulfillmentLocation?.zipcode}`}
        </div>
      </div>
    </div>
  )
}
