import { Button, Section } from '@bloom-coffee/steamed-milk'
import AddIcon from '@material-ui/icons/Add'
import { usePromotions } from 'hooks/usePromotions/usePromotions'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PromotionsList } from './components/PromotionsList'

export const Promotions = () => {
  const navigate = useNavigate()

  const { promotions } = usePromotions()

  return (
    <Section
      title='Promotions'
      addon={
        <Button
          endIcon={<AddIcon />}
          onClick={() => navigate('/admin/promotions/promotions/new')}
          label='
          Create Promotion'
        />
      }
    >
      <Section title='Approved' variant='subsection'>
        <PromotionsList promotions={promotions.APPROVED} />
      </Section>

      <Section title='Pending' variant='subsection'>
        <PromotionsList promotions={promotions.PENDING} />
      </Section>

      <Section title='Rejected' variant='subsection'>
        <PromotionsList promotions={promotions.REJECTED} />
      </Section>
    </Section>
  )
}
