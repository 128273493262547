import { Color, Text } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import {
  Maybe,
  SquareSyncInput,
  useGetExternalClientSyncsQuery,
  useSquareLocationsQuery,
  useSyncSquareLocationsMutation
} from 'graphql/types.generated'
import { logger } from 'logger'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringUtils } from 'util/stringUtils'

import { SquareLocationContainer } from './SquareLocationContainer'
import { SquareLocationSelectionConfig, SquareSyncVariant } from './SquareUtils'

interface SquareLocationContainerProps {
  organizationId: string
  merchantId: Maybe<string>
  variant: SquareSyncVariant
}

export const SquareLocationsContainer = (props: SquareLocationContainerProps) => {
  const { organizationId, merchantId, variant } = props

  const tag = 'SquareLocationsContainer'

  const [selections, setSelections] = useState<SquareLocationSelectionConfig[]>([])
  const [error, setError] = useState<string | undefined>()
  const [shouldPollForInputs, setShouldPollForInputs] = useState<SquareSyncInput[]>([])
  const [startedPollingOn, setStartedPollingOn] = useState<Maybe<Date>>()
  const [confirmMessage, setConfirmMessage] = useState<string | undefined>()

  const { loading: fetchingSquareLocations, data: squareLocationData } = useSquareLocationsQuery({
    fetchPolicy: 'network-only',
    variables: { organizationId, filter: { availableForMerchantId: merchantId } }
  })
  const { data: externalSyncData, startPolling, stopPolling } = useGetExternalClientSyncsQuery({
    variables: { organizationId }
  })
  const [sync, { error: syncError }] = useSyncSquareLocationsMutation()

  const navigate = useNavigate()

  useEffect(() => {
    if (syncError?.message) {
      logger.debug(tag, `${syncError.message}`)
    }
  }, [syncError])

  useEffect(() => {
    if (squareLocationData?.squareLocations && selections.length === 0) {
      logger.debug(tag, 'Initializing selection configs')
      setSelections(
        squareLocationData.squareLocations.map((squareLocationInfo, index) => ({
          id: `${squareLocationInfo.id}.${index}`,
          info: squareLocationInfo,
          createMerchant: false,
          createKdsIntegration: false,
          deleteExistingMenu: false,
          onlyWebCatalogItems: false,
          syncProducts: false,
          keepMenuSynced: false
        }))
      )
    }
  }, [selections.length, squareLocationData])

  useEffect(() => {
    if (shouldPollForInputs.length && !startedPollingOn) {
      logger.debug(tag, 'Starting polling')
      setStartedPollingOn(new Date())
      startPolling(2000)
    }
  }, [shouldPollForInputs, startPolling, startedPollingOn])

  useEffect(() => {
    if (!startedPollingOn) {
      logger.debug(tag, 'not polling yet')
      return
    }

    if (!shouldPollForInputs.length) {
      logger.error(tag, 'Something went wrong. Poll Locations.length === 0')
      return
    }

    let allSynced = true
    let errorMessage: string = ''

    for (let syncInput of shouldPollForInputs) {
      let thisOneFound = false
      for (let externalSync of externalSyncData?.getExternalClientSyncs || []) {
        if (
          externalSync.externalSourceId === syncInput.locationId &&
          externalSync.identifier === syncInput.identifier
        ) {
          thisOneFound = true
          let thisError = externalSync.events?.filter((e) => e?.eventType === 'error')[0]?.response
          if (thisError) {
            errorMessage += `\n ${thisError}`
          }
          break
        }
      }

      if (!thisOneFound) {
        allSynced = false
        break
      }
    }

    if (allSynced) {
      logger.debug(tag, 'All synced')
      stopPolling()
      setShouldPollForInputs([])
      setStartedPollingOn(null)

      if (errorMessage.length === 0) {
        if (variant === 'CreateMerchant') {
          navigate(`/organizations/${organizationId}`)
        } else if (variant === 'KdsIntegration') {
          navigate(`/organizations/${organizationId}/merchants/${merchantId}/home/devices?refetchIntegrations=1`)
        } else if (variant === 'SyncProducts') {
          navigate(`/organizations/${organizationId}/merchants/${merchantId}/menu/products?refetchProducts=1`)
        }
      } else {
        setError(errorMessage)
      }
    } else {
      logger.debug(tag, 'Not all synced yet')
    }
  }, [
    externalSyncData,
    navigate,
    merchantId,
    variant,
    organizationId,
    startedPollingOn,
    stopPolling,
    shouldPollForInputs
  ])

  async function executeSubmit() {
    const inputs: SquareSyncInput[] = []
    for (let selection of selections) {
      if (selection.createKdsIntegration || selection.createMerchant || selection.syncProducts) {
        inputs.push({
          locationId: selection.info.id!,
          createKdsIntegration: selection.createKdsIntegration,
          createMerchant: selection.createMerchant,
          syncProducts: selection.syncProducts,
          onlyWebCatalogItems: selection.onlyWebCatalogItems,
          merchantId,
          identifier: StringUtils.generateRandomAlphaNumeric(12),
          keepMenuSynced: selection.keepMenuSynced
        })
      }
    }

    if (!inputs.length) {
      setError('You must select at least one merchant')
      return
    }

    setError(undefined)
    setShouldPollForInputs(inputs)
    sync({ variables: { organizationId, inputs } }).catch((_) => {
      logger.debug(tag, 'probably timeout error')
    })
    logger.debug(tag, 'Started sync')
  }

  async function handleSubmit() {
    if (selections.filter((s) => s.syncProducts === true).length) {
      setConfirmMessage('Are you sure? Your existing menu will be replaced by your Square menu.')
    } else {
      await executeSubmit()
    }
  }

  function handleCreateMerchantSelection(config: SquareLocationSelectionConfig, checked: boolean) {
    if (variant !== 'CreateMerchant') {
      setError('Something went wrong')
      return
    }

    const newSelections = []
    for (let selection of selections) {
      if (selection.info.id === config.info.id) {
        // update the selection. If unchecked, remove the createKdsIntegration selection as well
        newSelections.push({
          ...selection,
          createMerchant: checked,
          createKdsIntegration: selection.createKdsIntegration && checked,
          onlyWebCatalogItems: false
        })
      } else {
        newSelections.push(selection)
      }
    }
    setSelections(newSelections)
  }

  function handleCreateKdsIntegrationSelection(config: SquareLocationSelectionConfig, checked: boolean) {
    const newSelections = []
    for (let selection of selections) {
      if (selection.info.id === config.info.id) {
        newSelections.push({ ...selection, createKdsIntegration: checked })
      } else {
        if (variant === 'KdsIntegration' && checked) {
          // uncheck all other selections
          newSelections.push({ ...selection, createKdsIntegration: false })
        } else {
          newSelections.push(selection)
        }
      }
    }
    setSelections(newSelections)
  }

  function handleSyncProductsSelection(config: SquareLocationSelectionConfig, checked: boolean) {
    if (variant !== 'SyncProducts') {
      setError('Something went wrong')
      return
    }

    const newSelections = []
    for (let selection of selections) {
      if (selection.info.id === config.info.id) {
        newSelections.push({
          ...selection,
          syncProducts: checked,
          deleteExistingMenu: false,
          onlyWebCatalogItems: false
        })
      } else {
        if (checked) {
          // remove other selections
          newSelections.push({
            ...selection,
            syncProducts: false,
            deleteExistingMenu: false,
            onlyWebCatalogItems: false
          })
        } else {
          newSelections.push(selection)
        }
      }
    }
    setSelections(newSelections)
  }

  function handleOnlyWebProductsClick(config: SquareLocationSelectionConfig, checked: boolean) {
    if (variant !== 'SyncProducts' && variant !== 'CreateMerchant') {
      setError('Something went wrong')
      return
    }

    const newSelections = []
    for (let selection of selections) {
      if (selection.info.id === config.info.id) {
        newSelections.push({ ...selection, onlyWebCatalogItems: checked })
      } else {
        newSelections.push(selection)
      }
    }
    setSelections(newSelections)
  }

  function handleKeepMenuSyncClick(config: SquareLocationSelectionConfig, checked: boolean) {
    if (variant !== 'SyncProducts' && variant !== 'CreateMerchant') {
      setError('Something went wrong')
      return
    }

    const newSelections = []
    for (let selection of selections) {
      if (selection.info.id === config.info.id) {
        newSelections.push({ ...selection, keepMenuSynced: checked })
      } else {
        newSelections.push(selection)
      }
    }
    setSelections(newSelections)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        {fetchingSquareLocations && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <Text variant='header3'>Fetching Square Locations</Text>
            </div>
            <div>
              <CircularProgress />
            </div>
          </div>
        )}

        <div>
          {selections.map((config: SquareLocationSelectionConfig, index: number) => (
            <div
              key={`${index}.${config.info.id}`}
              style={{ marginBottom: 30, borderBottom: `1px solid ${Color.BLUE_GREY_100}`, width: 400 }}
            >
              <SquareLocationContainer
                clickDisabled={!!startedPollingOn || typeof confirmMessage !== 'undefined'}
                config={config}
                variant={variant}
                onCreateKdsIntegrationClick={handleCreateKdsIntegrationSelection}
                onCreateNewMerchantClick={handleCreateMerchantSelection}
                onSyncProductsClick={handleSyncProductsSelection}
                onOnlyWebProductsClick={handleOnlyWebProductsClick}
                onKeepMenuSyncedClick={handleKeepMenuSyncClick}
              />
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        {!!startedPollingOn && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginBottom: 8 }}>
              <Text variant='header3'>Syncing Square Locations</Text>
            </div>
            <div style={{ marginBottom: 12 }}>
              <Text variant='body1'>This may take a few minutes</Text>
            </div>
            <div>
              <CircularProgress />
            </div>
          </div>
        )}

        {!startedPollingOn && typeof confirmMessage === 'undefined' && (
          <div>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: Color.RDY_BLACK,
                height: 44,
                width: 150,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 4,
                border: 'none'
              }}
              onClick={() => handleSubmit()}
            >
              <Text variant='subheader2' color={Color.WHITE}>
                Submit
              </Text>
            </button>
          </div>
        )}

        {!startedPollingOn && typeof confirmMessage !== 'undefined' && (
          <div>
            <div style={{ marginBottom: 12 }}>
              <Text variant='body1'>{confirmMessage}</Text>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
              <button
                style={{
                  cursor: 'pointer',
                  backgroundColor: Color.RDY_BLACK,
                  height: 44,
                  width: 150,
                  paddingLeft: 12,
                  paddingRight: 12,
                  borderRadius: 4,
                  border: 'none'
                }}
                onClick={() => executeSubmit()}
              >
                <Text variant='subheader2' color={Color.WHITE}>
                  Yes
                </Text>
              </button>

              <button
                style={{
                  cursor: 'pointer',
                  backgroundColor: Color.GREY_600,
                  height: 44,
                  width: 150,
                  paddingLeft: 12,
                  paddingRight: 12,
                  borderRadius: 4,
                  border: 'none'
                }}
                onClick={() => setConfirmMessage(undefined)}
              >
                <Text variant='subheader2' color={Color.WHITE}>
                  Cancel
                </Text>
              </button>
            </div>
          </div>
        )}

        {!!error && (
          <div>
            <div>
              <Text variant='error'>{`${error}`}</Text>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
