import { Alert } from '@material-ui/lab'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { PrivateRoute } from 'components/router/PrivateRoute'
import { roles } from 'hooks/usePermissions/usePermissions'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { MerchantsContainer } from '../components/MerchantsContainer'
import { OrganizationBillingContainer } from '../components/OrganizationBillingContainer'
import { PayoutContainer } from '../components/PayoutContainer'
import { SettingsContainer } from '../components/SettingsContainer'

export function ViewOrganization() {
  const { organizationId } = useParams()
  if (!organizationId) {
    return <Alert color='error'>Organization not found.</Alert>
  }

  return (
    <>
      <Routes>
        <Route
          path={`settings/*`}
          element={
            <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId)]}>
              <SettingsContainer organizationId={organizationId} />
            </HasAnyRole>
          }
        />
        <Route
          path={`billing/*`}
          element={
            <PrivateRoute roleMatchers={[roles.organizationAdmin(organizationId)]}>
              <OrganizationBillingContainer organizationId={organizationId} />
            </PrivateRoute>
          }
        />

        <Route path={`payouts/*`} element={<PayoutContainer organizationId={organizationId} />} />
        <Route path={`merchants/*`} element={<MerchantsContainer organizationId={organizationId} />} />
        <Route path={`*`} element={<Navigate to={`/organizations/${organizationId}/merchants`} replace={true} />} />
      </Routes>
    </>
  )
}
