import { PromoCodeFormModel } from 'views/admin/promotions/CreatePromoCode/components/promoCodeValidator'

import { PromotionDocument, useCreatePromoCodeMutation } from '../../graphql/types.generated'
import { useToast } from '../useToast'

export function useCreatePromoCode() {
  const toast = useToast()
  const [execute, { loading }] = useCreatePromoCodeMutation()

  async function create(input: PromoCodeFormModel, onSuccess: () => void) {
    try {
      const validStart = new Date(input.validStart).getTime()
      const validEnd = input.validEnd ? new Date(input.validEnd).getTime() : undefined

      const res = await execute({
        variables: { input: { ...input, validStart, validEnd } },
        refetchQueries: [{ query: PromotionDocument, variables: { id: input.promotionId } }]
      })
      if (res.data?.createPromoCode) {
        toast.success(`Promo code created: ${res.data.createPromoCode.code}`)
        onSuccess()
      }
    } catch (e) {
      toast.error(`Could not create promotion ${e}`)
    }
  }

  return { create, loading }
}
