import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Checkbox, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { ModifierOptionFragment } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'

import { formatValueAsPrice, fromCents } from '../../../util/formatting'
import { UpdateModifierOptionForm } from '../UpdateModifierOption/UpdateModifierOptionForm'
import { ModifierOptionForModifierFormModel } from './modifierValidator'

interface ModifierOptionFormSectionProps {
  name: string
  defaultValues: Partial<ModifierOptionForModifierFormModel>
  onRemove: () => void
  onClose: (updatedModifierOption?: ModifierOptionFragment, selectedByDefault?: boolean) => void
  onSelectedByDefaultChange: (value: boolean) => void
  isSynced?: boolean
}

export const ModifierOptionFormSection = (props: ModifierOptionFormSectionProps) => {
  const { defaultValues, onRemove, onClose, onSelectedByDefaultChange, isSynced } = props

  const [selectedByDefault, setSelectedByDefault] = useState(!!defaultValues?.selectedByDefault)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (defaultValues) {
      setSelectedByDefault(!!defaultValues?.selectedByDefault)
    }
  }, [defaultValues, setSelectedByDefault])

  return (
    <div style={{ marginBottom: 1, width: '100%', paddingLeft: 2 }}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={1}>
          <Checkbox
            checked={selectedByDefault}
            disabled={isSynced}
            onChange={(e) => {
              setSelectedByDefault(e.target.checked)
              onSelectedByDefaultChange(e.target.checked)
            }}
          ></Checkbox>
        </Grid>

        <Grid item xs={3}>
          <Typography>{defaultValues.name}</Typography>
          {defaultValues.currentQuantity != null && (
            <Typography variant='subtitle2'>{`(${defaultValues.currentQuantity!!} Available)`}</Typography>
          )}
        </Grid>

        <Grid item xs={3}>
          <Typography>{defaultValues.printableName || 'n/a'}</Typography>
        </Grid>

        <Grid item xs={1}>
          <Typography>{formatValueAsPrice(fromCents(defaultValues.priceCents))}</Typography>
        </Grid>

        <Grid item>
          <IconButton onClick={() => setIsEditing(true)}>
            <EditIcon />
          </IconButton>
          <IconButton disabled={isSynced} onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      {isEditing && (
        <Paper style={{ paddingLeft: 30, paddingRight: 30 }}>
          <Section
            title={'Editing Modifier Option'}
            addon={<Button endIcon={<CloseIcon />} onClick={() => setIsEditing(false)} theme='cancel' label='Close' />}
            variant='subsection'
          >
            <UpdateModifierOptionForm
              modifierId={defaultValues.id}
              isSynced={isSynced}
              inlineEdit={true}
              onClose={(updatedModifierOption?: ModifierOptionFragment) => {
                onClose(updatedModifierOption, selectedByDefault)
                setIsEditing(false)
              }}
            />
          </Section>
        </Paper>
      )}
    </div>
  )
}
