import { useUserInfoQuery } from 'graphql/types.generated'

export function UserTitle() {
  const { data } = useUserInfoQuery({ fetchPolicy: 'cache-first' })

  function getName() {
    if (!data?.me?.profile || (!data?.me?.profile?.firstName && !data?.me?.profile?.lastName)) {
      return data?.me?.profile?.loginEmail
    }
    return `${data?.me?.profile?.firstName} ${data?.me?.profile?.lastName}`
  }

  return <div>{getName()}</div>
}
