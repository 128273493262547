import { CircularProgress, DialogContentText } from '@material-ui/core'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useUpdateMerchantActiveMutation } from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import { useState } from 'react'
import { ColorHex } from 'theme/ThemeConstants'

interface MerchantStatusButtonProps {
  merchantId: ID
  active: boolean
}

export function MerchantStatusButton(props: MerchantStatusButtonProps) {
  const { merchantId, active } = props
  const [update, { loading }] = useUpdateMerchantActiveMutation()
  const errorHandler = useErrorHandler()
  const [opened, setOpened] = useState(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  async function updateMerchantActive() {
    try {
      await update({
        variables: {
          id: merchantId,
          active: !active
        }
      })
      setOpened(false)
    } catch (e) {
      errorHandler(e)
    }
  }

  return (
    <>
      {loading && <CircularProgress style={{ color: ColorHex.RDY_MALIBU }} size={16} />}
      <button
        style={{ ...(active ? activeButton : inactiveButton), ...(isHovered ? hovered : {}) }}
        onClick={active ? () => setOpened(true) : updateMerchantActive}
        disabled={loading}
        onMouseEnter={(e) => {
          setIsHovered(true)
        }}
        onMouseLeave={(e) => {
          setIsHovered(false)
        }}
      >
        {active ? 'GO OFFLINE' : 'GO LIVE'}
      </button>
      <ConfirmationDialog
        title='Take Cafe Offline?'
        open={opened}
        onClose={() => setOpened(false)}
        onConfirm={updateMerchantActive}
      >
        <DialogContentText>
          Are you sure you want to take this cafe offline?
          <br /> This will cause the cafe to stop receiving orders until chosing to go live again.
        </DialogContentText>
      </ConfirmationDialog>
    </>
  )
}

const buttonBase: React.CSSProperties = {
  border: 'solid 2px',
  borderRadius: 5,
  height: 28,
  fontWeight: 300,
  fontSize: 'medium',
  fontFamily: 'Raw-Bold',
  backgroundColor: ColorHex.RDY_FOREST,
  borderColor: ColorHex.RDY_MALIBU
}

const activeButton: React.CSSProperties = {
  ...buttonBase,
  color: ColorHex.RDY_MALIBU
}
const inactiveButton: React.CSSProperties = {
  ...buttonBase,
  color: ColorHex.RDY_MALIBU
}

const hovered: React.CSSProperties = {
  textDecoration: 'underline',
  cursor: 'pointer'
}
