import { Box, Grid, MenuItem, Typography } from '@material-ui/core'
import { SelectField } from 'components/form/SelectField'
import { MessageType, PushAppAction, useMarketsQuery } from 'graphql/types.generated'
import { useState } from 'react'
import { FormProvider, UseFormMethods } from 'react-hook-form'

import { DefaultFormFooter } from '../../../components/form/DefaultFormFooter'
import { TextField } from '../../../components/form/TextField'

interface SendPushFormProps {
  type: 'user' | 'market'
  onCancel: () => void
  onSubmit: (values: any) => void
  disableSubmit?: boolean
  formProps: UseFormMethods<any>
}

const applicableMessageTypes = [MessageType.PushGeneral, MessageType.PushPromotion]

export const SendPushForm = (props: SendPushFormProps) => {
  const [confirmation, setConfirmation] = useState(false)
  const { onCancel, onSubmit, disableSubmit, formProps, type } = props
  const { data, loading } = useMarketsQuery({ fetchPolicy: 'cache-first' })

  const { register, handleSubmit, formState } = formProps
  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={6}>
            <TextField fullWidth label='Title' name='title' inputRef={register()} />
          </Grid>

          <Grid item xs={6}>
            <TextField fullWidth label='Analytics tag' name='analyticsTag' inputRef={register()} />
          </Grid>

          <Grid item xs={12}>
            <TextField fullWidth label='Body' name='body' inputRef={register()} />
          </Grid>

          <Grid item xs={6}>
            <SelectField fullWidth label='Message Type' name='messageType'>
              <MenuItem value={undefined}>
                <em>Select a message type</em>
              </MenuItem>

              {applicableMessageTypes.map((mt, index) => (
                <MenuItem key={`${mt}.${index}`} value={mt}>
                  {mt}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={6}>
            <SelectField fullWidth label='App Action' name='appAction'>
              <MenuItem value={undefined}>
                <em>None</em>
              </MenuItem>

              {Object.values(PushAppAction).map((aa, index) => (
                <MenuItem key={`${aa}.${index}`} value={aa}>
                  {aa}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          {type === 'market' && (
            <Grid item xs={12}>
              <SelectField fullWidth label='Market' name='marketId'>
                {data?.markets?.length && (
                  <MenuItem value=''>
                    <em>Select a Market</em>
                  </MenuItem>
                )}

                {data?.markets?.map((market, index: number) => (
                  <MenuItem key={`${market.id}.${index}`} value={market.id}>
                    {market.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
          )}

          {type === 'user' && (
            <Grid item xs={12}>
              <TextField fullWidth label='User IDs (e.g. 2, 19, 7)' name='userIds' inputRef={register()} />
            </Grid>
          )}

          <Grid item>
            {confirmation ? (
              <Box>
                <Typography variant='subtitle1'>
                  Are you sure? This will send a push notification to actual devices.
                </Typography>
                <DefaultFormFooter
                  saveLabel='Yes'
                  disabled={disableSubmit || loading}
                  onCancel={() => setConfirmation(false)}
                  onSubmit={handleSubmit(onSubmit)}
                  submitting={formState.isSubmitting}
                />
              </Box>
            ) : (
              <DefaultFormFooter
                saveLabel='Send Push'
                disabled={disableSubmit || loading}
                onCancel={onCancel}
                onSubmit={() => setConfirmation(true)}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
