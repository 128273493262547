import { Button } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { TextField } from 'components/form/TextField'
import { useMoveMerchantToDifferentOrganizationMutation } from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { MoveToDifferentOrganizationModel, moveToDifferentOrganizationSchema } from './validator'

interface MoveToDifferentOrganizationProps {
  merchantId: ID
}

export function MoveToDifferentOrganizationButton(props: MoveToDifferentOrganizationProps) {
  const { merchantId } = props
  const toast = useToast()
  const styles = useStyles()
  const [opened, setOpened] = useState(false)
  const errorHandler = useErrorHandler()
  const navigate = useNavigate()

  const [execute] = useMoveMerchantToDifferentOrganizationMutation()

  const formProps = useForm<MoveToDifferentOrganizationModel>({
    resolver: yupResolver(moveToDifferentOrganizationSchema)
  })

  const { register, handleSubmit, formState } = formProps

  function toggleModal() {
    setOpened((old) => !old)
  }

  async function moveMechant(values: MoveToDifferentOrganizationModel) {
    const toOrganizationId = values.toOrganizationId
    try {
      await execute({
        variables: {
          input: {
            merchantId,
            toOrganizationId
          }
        }
      })
      toggleModal()
      toast.success(`Merchant Moved to Organization ${toOrganizationId}`)
      navigate(`/organizations/${toOrganizationId}`)
    } catch (e) {
      errorHandler(e)
    }
  }

  return (
    <>
      <Button onClick={toggleModal} label='Change Org' />
      <Dialog open={opened} onClose={toggleModal}>
        <DialogTitle>Move to Organization</DialogTitle>
        <DialogContent className={styles.dialog}>
          <FormProvider {...formProps}>
            <form onSubmit={handleSubmit(moveMechant)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name='toOrganizationId' label='To Organization' inputRef={register} />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <DefaultFormFooter
            onCancel={toggleModal}
            onSubmit={handleSubmit(moveMechant)}
            saveLabel='Move Merchant'
            submitting={formState.isSubmitting}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 400
  }
}))
