import { Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { useMyKdssQuery } from 'graphql/types.generated'
interface MyKdssListProps {}

export function MyKdssList(props: MyKdssListProps) {
  const { data, loading } = useMyKdssQuery()

  return (
    <DataGrid
      loading={loading}
      autoHeight
      hideFooterPagination
      disableSelectionOnClick
      rows={data?.me?.kdss || []}
      style={{ border: 'solid 1px rgba(224, 224, 224, 1)' }}
      columns={[
        { field: 'uuid', headerName: 'UUID', sortable: false, width: 350 },
        {
          field: 'merchant',
          headerName: 'Merchant',
          sortable: false,
          width: 500,
          renderCell(params) {
            return <Typography>{params.row.merchant.name}</Typography>
          }
        },
        {
          field: 'active',
          headerName: 'Active/Inactive',
          sortable: false,
          width: 200,
          renderCell(params) {
            return <Typography>{params.row.active ? 'Active' : 'Inactive'}</Typography>
          }
        }
      ]}
    />
  )
}
