import { PaymentIntentStatus } from 'graphql/types.generated'
import React from 'react'
import { formatUsCentsAsUsDollar } from 'util/formatting'

import { RequisitionPurchase } from './models'
interface RequisitionPurchaseContainerProps {
  purchase: RequisitionPurchase
}
export const RequisitionPurchaseContainer = (props: RequisitionPurchaseContainerProps) => {
  const { purchase } = props
  return (
    <div>
      <div style={{ marginBottom: 8 }}>Created On: {new Date(purchase.createdOn || 0).toDateString()}</div>
      <div style={{ marginBottom: 8 }}>Subtotal {formatUsCentsAsUsDollar(purchase.subtotal || 0)}</div>
      <div style={{ marginBottom: 8 }}>Tax {formatUsCentsAsUsDollar(purchase.tax || 0)}</div>

      {!!purchase.paymentIntent && (
        <div style={{ marginBottom: 8 }}>
          <div>Stripe Info</div>

          <div style={{ paddingLeft: 8 }}>
            <div style={{ marginBottom: 8 }}>Stripe ID: {purchase.paymentIntent.stripeId}</div>

            <div style={{ marginBottom: 8 }}>
              Payment Successful: {purchase.paymentIntent.status === PaymentIntentStatus.Success ? 'YES' : 'NO'}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
