import * as yup from 'yup'

const merchantLocationSchema = yup.object({
  id: yup.string().nullable(true),
  city: yup.string().label('City').required(),
  state: yup.string().label('State').required(),
  street: yup.string().label('Street').required(),
  street2: yup.string().label('Street line 2').nullable(true).notRequired(),
  zipCode: yup.string().label('Zip Code').required(),
  countryCode: yup.string().label('Country Code').nullable(true).notRequired(),
  geoFenceType: yup
    .string()
    .label('Geo-Fence Type')
    .test('geoFenceValidation', 'Please select a geo-fence type', (value: any) => !!value)
    .nullable(true)
    .notRequired()
})

export const merchantSchema = yup.object({
  name: yup.string().label('Name').required(),
  taxRate: yup
    .number()
    .typeError('Please enter a valid tax rate')
    .test(
      'taxRateValidation',
      'Please enter a valid tax rate',
      (value: any) => !isNaN(value) && value >= 0 && value < 100
    )
    .label('Tax Rate')
    .nullable(true)
    .notRequired(),
  diningOptions: yup.array(yup.string().nullable(false)).label('Dining Options').nullable(true).optional(),
  location: merchantLocationSchema.required(),
  operationHours: yup.array().label('Hours').nullable(true).notRequired()
})

export type MerchantLocationFormModel = yup.InferType<typeof merchantLocationSchema>
export type MerchantFormModel = yup.InferType<typeof merchantSchema>
