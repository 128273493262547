import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Card, CardContent, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { CatalogSuggestedItemFragment } from 'graphql/types.generated'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface SuggestedItemsSectionProps {
  suggestedItems?: CatalogSuggestedItemFragment[] | null
}

export function SuggestedItemsSection(props: SuggestedItemsSectionProps) {
  const { suggestedItems = [] } = props
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const styles = useStyles()

  function handleUpdateSuggestedItems() {
    navigate(`${pathname}/updateSuggested`)
  }

  return (
    <Section
      title='Featured Items'
      subTitle='These items will be displayed in a grid at the top of your menu. We recommend featuring top-sellers and seasonal drinks within this section.'
      addon={<Button onClick={handleUpdateSuggestedItems} label='Update' />}
      variant='subsection'
    >
      <Card variant='outlined'>
        <CardContent>
          {suggestedItems && (
            <Grid container spacing={2}>
              {suggestedItems?.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <Paper className={styles.itemCard} variant='outlined'>
                    <Typography>{item.product.name}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
          {!suggestedItems?.length && (
            <Typography color='textSecondary'>
              <em>No suggested items</em>
            </Typography>
          )}
        </CardContent>
      </Card>
    </Section>
  )
}

const useStyles = makeStyles((theme) => ({
  itemCard: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  }
}))
