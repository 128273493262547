import { ApolloCache, FetchResult } from '@apollo/client'
import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Card, CardContent, CircularProgress, Input, lighten, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import {
  MerchantImageDetailsFragment,
  UploadMerchantImageMutation,
  useMerchantImagesByMerchantQuery,
  useUpdateMerchantImagesMutation,
  useUploadMerchantImageMutation
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

import { MerchantImageDetail } from './MerchantImageDetail'
interface MerchantImagesProps {
  merchantId: string
  organizationId: string
}

export function MerchantImages(props: MerchantImagesProps) {
  const { merchantId } = props
  const { data, loading } = useMerchantImagesByMerchantQuery({ fetchPolicy: 'cache-first', variables: { merchantId } })
  const [execute, { loading: saveLoading }] = useUploadMerchantImageMutation()
  const [updateSortOrder] = useUpdateMerchantImagesMutation()
  const toast = useToast()
  const styles = useStyles()

  const [merchantImages, setMerchantImages] = useState<MerchantImageDetailsFragment[]>([])

  useEffect(() => {
    setMerchantImages(
      (data?.merchantImagesByMerchant ?? []).concat().sort((a, b) => {
        return a.sortOrder - b.sortOrder
      })
    )
  }, [data])

  function updateCache(
    cache: ApolloCache<UploadMerchantImageMutation>,
    mutationResult: FetchResult<UploadMerchantImageMutation>
  ) {
    const newImage = mutationResult.data?.uploadMerchantImage

    if (newImage) {
      cache.modify({
        fields: {
          merchantImagesByMerchant(existingImages, { readField }) {
            return [...existingImages, newImage]
          }
        }
      })
    }
  }

  const hiddenFileInput = React.useRef<HTMLInputElement>(null)
  const handleChooseFileClick = (event: any) => {
    if (document != null) {
      document.getElementById('fileInput')?.click()
    }
  }
  async function uploadFile(currentFile: File) {
    if (!!currentFile && data) {
      try {
        var sortOrder = 1
        if (!!data.merchantImagesByMerchant?.length)
          sortOrder =
            Math.max.apply(
              Math,
              data.merchantImagesByMerchant.map(function (o) {
                return o.sortOrder
              })
            ) + 1

        await execute({
          variables: {
            file: currentFile,
            merchantId: merchantId,
            sortOrder: sortOrder
          },
          update: updateCache
        })
      } catch (e: any) {
        toast.error(e.toString())
      }
    }
  }

  async function onChangeHandler(event: any) {
    if (event.target.validity.valid) {
      uploadFile(event.target.files[0])
    }
  }

  async function handleDragEnd(result: DropResult) {
    if (!result.destination || !merchantImages) {
      return
    }

    const reorderedItems = reorder(merchantImages, result.source.index, result.destination.index)
    setMerchantImages(reorderedItems)
    var newIndex = 1
    var imagesToSave = reorderedItems
      .filter((i) => !!i)
      .map((i) => {
        return {
          id: i.id,
          sortOrder: newIndex++
        }
      })
    await updateSortOrder({
      variables: {
        input: imagesToSave,
        merchantId
      }
    })
  }

  function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  return (
    <Section
      title='Photos'
      subTitle='Show off your cafe’s exterior, interior and a sampling of your bevs, food and merch. They will display on your cafe’s menu in the order they appear here'
    >
      {loading && <CircularProgress size={14} />}
      {!loading && (
        <div>
          <Button
            disabled={saveLoading}
            onClick={handleChooseFileClick}
            label='Upload a new Photo'
            endIcon={saveLoading && <CircularProgress size={14} />}
          />
          <Input
            id='fileInput'
            type='file'
            placeholder='Choose a photo'
            ref={hiddenFileInput}
            onChange={onChangeHandler}
            style={{ display: 'none' }}
          />

          {!!data && data.merchantImagesByMerchant && merchantImages.length > 0 && (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='merchantImages'>
                {(provided, snapshot) => (
                  <Card
                    {...provided.droppableProps}
                    className={clsx(styles.dropContainer, snapshot.isDraggingOver && styles.dropContainerDragging)}
                    ref={provided.innerRef}
                  >
                    <CardContent>
                      {merchantImages.map((m, index) => {
                        return <MerchantImageDetail key={m.id} merchantImage={m} index={index} />
                      })}
                      {provided.placeholder}
                    </CardContent>
                  </Card>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      )}
    </Section>
  )
}

const useStyles = makeStyles((theme) => ({
  dropContainer: {
    width: 'fit-content',
    transition: '0.1s all ease-in-out'
  },
  dropContainerDragging: {
    backgroundColor: lighten(theme.palette.primary.light, 0.8)
  }
}))
