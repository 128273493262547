import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Box, Checkbox, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import {
  Maybe,
  useCreateMerchantFromLocationMutation,
  useGetExternalClientSyncsQuery,
  useGetMerchantsWithFreshLocationByOrganizationIdQuery
} from 'graphql/types.generated'
import { FreshLocationInfo } from 'models/Integrations'
import React, { useEffect, useState } from 'react'
import { ColorHex } from 'theme/ThemeConstants'

interface ImportFreshLocationsProps {
  onCancel: () => void
  onSuccess: () => void
  organizationId: string
  token: string
  locations: FreshLocationInfo[]
  brandId: string
}

export function ImportFreshLocations(props: ImportFreshLocationsProps) {
  const { onCancel, onSuccess, organizationId, locations, token, brandId } = props

  const styles = useStyles()

  const { data, refetch } = useGetMerchantsWithFreshLocationByOrganizationIdQuery({
    variables: {
      id: organizationId
    }
  })

  const {
    data: syncEventData,
    loading,
    startPolling: startSyncPolling,
    stopPolling: stopSyncPolling
  } = useGetExternalClientSyncsQuery({
    variables: {
      organizationId
    }
  })

  const [execute] = useCreateMerchantFromLocationMutation()

  const [startedPolling, setStartedPolling] = useState<Maybe<Date>>(null)
  const [importingLocations, setImportingLocations] = useState<string[]>([])
  const [selectedLocations, setSelectedLocations] = useState<string[]>(locations.map((loc) => loc.id))

  async function importSelectedLocations() {
    try {
      let locationsToImport = selectedLocations.filter((location) => !isLocationSynced(location))
      setImportingLocations(locationsToImport)
      let syncing = []

      for (let locationId of locationsToImport) {
        syncing.push(
          execute({
            variables: {
              organizationId: organizationId,
              freshLocationId: locationId,
              brandId: brandId!,
              token: token!
            }
          })
        )
      }

      startSyncPolling(1000)
      setStartedPolling(new Date())
      await Promise.all(syncing)
    } catch (e: any) {}
  }

  useEffect(() => {
    if (startedPolling == null) {
      return
    }

    var remainingLocationsSyncing = []
    for (let locationId of importingLocations) {
      const locationSyncs = syncEventData?.getExternalClientSyncs?.filter(
        (s) => s.externalSourceId === locationId && !!s.completedOn
      )

      if (!locationSyncs?.length) {
        remainingLocationsSyncing.push(locationId)
      } else if (!locationSyncs.find((s) => Math.abs(s.startedOn - startedPolling.getTime()) < 5000)) {
        remainingLocationsSyncing.push(locationId)
      }
    }

    if (remainingLocationsSyncing.length !== importingLocations.length) {
      setImportingLocations(remainingLocationsSyncing)
    }

    if (!remainingLocationsSyncing.length) {
      onSuccess()
      stopSyncPolling()
      setStartedPolling(null)
      refetch()
    }
  }, [syncEventData, importingLocations, startedPolling, stopSyncPolling, setImportingLocations, onSuccess, refetch])

  function handleCancel() {
    if (startedPolling != null) {
      onSuccess()
    } else {
      onCancel()
    }
  }

  function setLocationIdSelected(selected: boolean, locationId: string) {
    var updatedSelectedIds = selectedLocations.slice()
    if (selected) {
      updatedSelectedIds.push(locationId)
    } else if (updatedSelectedIds.indexOf(locationId) >= 0) {
      updatedSelectedIds.splice(updatedSelectedIds.indexOf(locationId), 1)
    }
    setSelectedLocations(updatedSelectedIds)
  }

  function isLocationSynced(locationId: string) {
    return !!data?.organization?.merchants?.find((m) => m.freshLocation?.locationId === locationId)
  }

  return (
    <Section title='Choose the location(s) to import:' variant='subsection'>
      {!loading && (
        <>
          <Box display='flex' flexDirection='column'>
            {locations.map((location) => (
              <Box key={location.id} display='flex' flexDirection='row'>
                {!importingLocations.length && (
                  <Checkbox
                    disabled={isLocationSynced(location.id)}
                    onChange={(e) => setLocationIdSelected(!!e.target.checked, location.id)}
                    checked={selectedLocations.includes(location.id)}
                  />
                )}
                <Box display='flex' flexDirection='column' padding={1}>
                  <Box display='flex' flexDirection='row'>
                    <Typography className={styles.locationDetail}>{location.name}</Typography>
                    {isLocationSynced(location.id) && (
                      <Typography className={styles.synced}>Successfully Synced</Typography>
                    )}
                  </Box>
                  <Typography>{`${location.address?.line1}, ${location.address?.city}, ${location.address?.state} ${location.address?.zip}`}</Typography>
                </Box>
                {!!startedPolling && importingLocations.includes(location.id) && (
                  <Box width={50} padding={2}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            ))}
            {!locations.length && !loading && (
              <Box className={styles.importingDetails}>
                <Typography variant='body1'>No locations found</Typography>
              </Box>
            )}
          </Box>
          {!!startedPolling && (
            <Box className={styles.importingDetails}>
              <Typography variant='body1'>
                Importing the selected cafe(s) may take a few minutes. An email will be sent to the organization admin
                when they are successfully imported. Closing this window will not affect the import.
              </Typography>
            </Box>
          )}
          <Box className={styles.importingDetails}>
            <Typography variant='body1'>
              For support please email <a href={'mailto:support@rdy.xyz'}>support@rdy.xyz</a>
            </Typography>
          </Box>
          <div style={actionsStyle}>
            <Button style={buttonStyle} onClick={handleCancel} theme='cancel' label='Close' />
            {!!locations.length && !loading && !startedPolling && (
              <Button
                style={buttonStyle}
                disabled={!selectedLocations.length || !!startedPolling}
                onClick={importSelectedLocations}
                label='Import'
              />
            )}
          </div>
        </>
      )}
      {loading && <CircularProgress />}
    </Section>
  )
}

const actionsStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const buttonStyle: React.CSSProperties = {
  marginRight: 5
}

const useStyles = makeStyles((theme) => ({
  importingDetails: {
    padding: 10,
    backgroundColor: ColorHex.RDY_BEIGE,
    border: '1px solid',
    borderRadius: 5,
    margin: 2
  },
  locationDetail: {
    fontWeight: 800,
    padding: 5
  },
  synced: {
    fontWeight: 800,
    paddingLeft: 5,
    paddingRight: 5,
    margin: 5,
    color: 'green',
    backgroundColor: ColorHex.RDY_BEIGE,
    border: '1px solid',
    borderRadius: 5
  }
}))
