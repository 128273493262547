import { ColorHex } from 'theme/ThemeConstants'

import { MenuBar } from '../MenuBar'
import { MenuButtonDetails } from '../MenuButton'

export interface SideMenuBarProps {
  menuButtonDetails: MenuButtonDetails[]
  size?: 'small' | 'medium' | 'large'
}

export function SideMenuBar(props: SideMenuBarProps) {
  const { menuButtonDetails, size = 'medium' } = props

  const sizeStyles = {
    small: smallMenuStyle,
    medium: mediumMenuStyle,
    large: largeMenuStyle
  }

  const defaultButtonStyles = {
    small: smallDefaultButtonStyle,
    medium: mediumDefaultButtonStyle,
    large: defaultButtonStyle
  }
  const activeButtonStyles = {
    small: smallActiveButtonStyle,
    medium: mediumActiveButtonStyle,
    large: activeButtonStyle
  }

  return (
    <MenuBar
      style={sizeStyles[size]}
      menuButtonDetails={menuButtonDetails}
      defaultButtonStyle={defaultButtonStyles[size]}
      activeButtonStyle={activeButtonStyles[size]}
    />
  )
}

const largeMenuStyle: React.CSSProperties = {
  color: ColorHex.WHITE,
  marginTop: 28,
  width: 216,
  height: 350,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly'
}

const mediumMenuStyle: React.CSSProperties = {
  color: ColorHex.WHITE,
  marginTop: 10,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly'
}

const smallMenuStyle: React.CSSProperties = {
  color: ColorHex.WHITE,
  marginTop: 4,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly'
}

const defaultButtonStyle: React.CSSProperties = {
  width: '100%',
  border: 'none',
  backgroundColor: 'inherit',
  fontFamily: 'Raw-Bold',
  fontSize: 18,
  padding: 10
}

const activeButtonStyle: React.CSSProperties = {
  ...defaultButtonStyle,
  backgroundColor: ColorHex.RDY_BEIGE,
  border: 'solid 1px',
  borderRight: 'solid 1px #F2EFE4',
  borderLeft: 'none',
  borderColor: ColorHex.RDY_FOREST,
  borderRightColor: ColorHex.RDY_BEIGE,
  position: 'relative',
  left: '1px',
  color: ColorHex.RDY_RED
}

const smallDefaultButtonStyle: React.CSSProperties = {
  border: 'solid 1px',
  backgroundColor: ColorHex.RDY_BEIGE,
  fontFamily: 'Matter-Medium',
  fontSize: 12,
  padding: 4,
  margin: 4,
  borderRadius: 4
}

const mediumDefaultButtonStyle: React.CSSProperties = {
  border: 'solid 1px',
  backgroundColor: ColorHex.RDY_BEIGE,
  fontFamily: 'Matter-Medium',
  fontSize: 14,
  padding: 4,
  paddingLeft: 10,
  paddingRight: 10,
  margin: 4,
  borderRadius: 4
}

const smallActiveButtonStyle: React.CSSProperties = {
  ...smallDefaultButtonStyle,
  backgroundColor: ColorHex.RDY_BEIGE,
  border: 'solid 1px',
  borderColor: ColorHex.RDY_FOREST,
  color: ColorHex.RDY_RED
}

const mediumActiveButtonStyle: React.CSSProperties = {
  ...mediumDefaultButtonStyle,
  backgroundColor: ColorHex.RDY_BEIGE,
  border: 'solid 1px',
  borderColor: ColorHex.RDY_FOREST,
  color: ColorHex.RDY_RED
}
