import { CircularProgress } from '@material-ui/core'
import { useSetupPaymentAccountMutation } from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export function SetupPaymentAccount() {
  const { organizationId, merchantId } = useParams()
  const [execute] = useSetupPaymentAccountMutation()
  const errorHandler = useErrorHandler()

  useEffect(() => {
    async function setupPaymentAccount() {
      try {
        let { data } = await execute({
          variables: {
            organizationId: organizationId!,
            merchantId: merchantId
          }
        })
        if (data) {
          window.location.replace(data.setupPaymentAccount)
        }
      } catch (e) {
        errorHandler(e)
      }
    }
    setupPaymentAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, merchantId, execute])

  return <CircularProgress />
}
