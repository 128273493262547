import { transform } from 'validation/transform'
import * as yup from 'yup'

export const merchantPromotionSchema = yup.object({
  start: yup.string().label('Start Date*').required(),
  end: yup.string().label('End Date*').nullable().optional(),
  notifyUsersOn: yup.string().label('Notify Customers On**').nullable().optional(),
  newUserOnly: yup.boolean().label('New User Only').required(),
  customerFacingName: yup.string().label('Promotion Name').required(),
  flatDiscountUsCents: yup.number().transform(transform.price).nullable(true).label('Flat Discount'),
  percentageDiscount: yup.number().transform(transform.nullable).nullable(true).label('Percentage Discount'),
  limitPerUser: yup.number().label('Limit Per User').optional().default(1),
  maxDiscountUsCents: yup.number().transform(transform.price).nullable(true).label('Maximum Discount'),
  promoCode: yup.string().nullable(true).transform(transform.nullable).label('Promo Code'),
  productTags: yup.array(yup.string().nullable(false)).label('Product Tags').nullable(true).optional()
})

export type MerchantPromotionFormModel = yup.InferType<typeof merchantPromotionSchema>
