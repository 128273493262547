import { ApolloCache, FetchResult } from '@apollo/client'
import { client } from 'graphql/client'

import {
  ArchiveModifierOptionMutation,
  ModifierOptionFragment,
  TopModifiersByMerchantDocument,
  useArchiveModifierOptionMutation,
  useArchiveModifierOptionsMutation
} from '../graphql/types.generated'
import { useToast } from './useToast'

export function useArchiveModifierOption(merchantId: string) {
  const toast = useToast()
  const [execute, { loading }] = useArchiveModifierOptionMutation()
  const [executeAll, { loading: loadingAll }] = useArchiveModifierOptionsMutation()

  function updateCache(
    cache: ApolloCache<ArchiveModifierOptionMutation>,
    mutationResult: FetchResult<ArchiveModifierOptionMutation>
  ) {
    const idToRemove = mutationResult.data?.archiveModifier.id

    if (idToRemove) {
      cache.modify({
        fields: {
          modifiersWithoutChildrenByMerchant(existingMods, { readField }) {
            return existingMods.filter((modOption: ModifierOptionFragment) => idToRemove !== readField('id', modOption))
          }
        }
      })
    }
  }

  function updateMultipleCache(ids: string[]) {
    client.cache.modify({
      fields: {
        modifiersWithoutChildrenByMerchant(existingMods, { readField }) {
          return existingMods.filter((modOption: ModifierOptionFragment) => {
            const value = readField('id', modOption)
            return value ? ids.indexOf(value.toString()) < 0 : false
          })
        }
      }
    })
  }

  async function archive(id: string, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { id },
        update: updateCache,
        refetchQueries: [{ query: TopModifiersByMerchantDocument, variables: { merchantId } }]
      })

      if (res.data?.archiveModifier) {
        onSuccess()
      } else {
        throw new Error(`Could not archive mod: ${res.errors}`)
      }
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  async function archiveAll(ids: string[], onSuccess: () => void) {
    try {
      const res = await executeAll({
        variables: { ids },
        refetchQueries: [{ query: TopModifiersByMerchantDocument, variables: { merchantId } }]
      })

      if (res.data?.archiveModifiers) {
        updateMultipleCache(ids)
        onSuccess()
      } else {
        throw new Error(`Could not archive mod: ${res.errors}`)
      }
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  return { archive, archiveAll, loading: loading || loadingAll }
}
