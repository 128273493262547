import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  useDeleteMerchantContactMutation,
  useMerchantContactDetailsQuery,
  useUpdateMerchantContactMutation,
  ViewMerchantDocument
} from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { MerchantContactForm } from './MerchantContactForm'
import { MerchantContactFormModel } from './merchantContactValidator'

export function UpdateMerchantContact() {
  const navigate = useNavigate()
  const toast = useToast()
  const { merchantContactId, merchantId } = useParams()
  const [execute] = useUpdateMerchantContactMutation()
  const [deleteContact] = useDeleteMerchantContactMutation()
  const { data, loading } = useMerchantContactDetailsQuery({
    variables: {
      id: merchantContactId!
    }
  })

  async function handleSubmit(values: MerchantContactFormModel) {
    try {
      const res = await execute({
        variables: {
          input: {
            id: values.id!,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber
          }
        }
      })
      if (res.data?.updateMerchantContact) {
        toast.success('Contact updated')
        navigate(-1)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  function handleCancel() {
    navigate(-1)
  }

  async function handleDelete() {
    await deleteContact({
      variables: {
        id: merchantContactId!
      },
      refetchQueries: [{ query: ViewMerchantDocument, variables: { id: merchantId! } }]
    })
    navigate(-1)
  }

  return (
    <Section
      title={'Update Contact'}
      addon={<Button theme='warning' disabled={loading} onClick={handleDelete} endIcon={<DeleteIcon />} />}
    >
      {loading && <CircularProgress size={14}></CircularProgress>}
      {!loading && data && data.merchantContact && (
        <MerchantContactForm onCancel={handleCancel} onSubmit={handleSubmit} initialValues={data.merchantContact} />
      )}
    </Section>
  )
}
