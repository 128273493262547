import { Section } from '@bloom-coffee/steamed-milk'
import { Alert } from '@material-ui/lab'
import { PriceField } from 'components/form/PriceField'
import { useTopModifiersByMerchantQuery } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { DefaultValues, useFormContext } from 'react-hook-form'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { ProductFormModel } from 'views/products/CreateProduct/productValidator'

interface ProductPricingProps {
  initialValues?: DefaultValues<ProductFormModel> | undefined
  isSynced?: boolean
  merchantId: string
}

export function ProductPricing(props: ProductPricingProps) {
  const { initialValues, isSynced, merchantId } = props
  const { watch } = useFormContext()
  const [priceError, setPriceError] = useState(false)
  const [defaultPrice, setDefaultPrice] = useState('$0.00')

  const { data: topModiferData } = useTopModifiersByMerchantQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })

  const basePrice = watch('priceCents')
  const modifiers = watch('modifiers')

  useEffect(() => {
    let price = basePrice ?? 0
    let validPrice = basePrice > 0
    for (let modifier of modifiers) {
      let topMod = topModiferData?.topModifiersByMerchant?.find((t) => t.id === modifier.id)
      if (topMod != null) {
        let childMods = topMod.children?.filter((c) => (topMod?.defaultSelectionIds?.indexOf(c.id) ?? -1) >= 0) ?? []
        for (let childMod of childMods) {
          price += childMod.priceCents ?? 0
        }
        if (!validPrice) {
          let allMods = topMod.children?.filter((c) => c.active) ?? []
          validPrice = !allMods.find((m) => m.priceCents === 0)
        }
      }
    }
    setPriceError(!validPrice)
    setDefaultPrice(formatUsCentsAsUsDollar(price))
  }, [basePrice, modifiers, topModiferData?.topModifiersByMerchant])

  return (
    <Section
      title='Price'
      variant='subsection'
      subTitle='The default price will be shown as the menu price. It is the base price plus the price of the default modifiers.'
    >
      <div style={detailsContainerStyle}>
        <PriceField
          disabled={isSynced}
          style={fieldStyle}
          name='priceCents'
          label='Base Price'
          defaultValue={initialValues?.priceCents}
        />
        <div>{`Default Price: ${defaultPrice}`}</div>
      </div>
      {priceError && (
        <Alert severity={'warning'}>
          This product currently can be purchased for $0 based on the product price and modifier prices. Update the
          price or add modifiers with prices.
        </Alert>
      )}
    </Section>
  )
}

const fieldStyle: React.CSSProperties = {
  minWidth: 200,
  margin: 10
}

const detailsContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  maxWidth: 700
}
