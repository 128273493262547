import { CircularProgress } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useFetchModifierOptionQuery } from '../../../graphql/types.generated'
import { useUpdateModifier } from '../../../hooks/useUpdateModifier'
import { ModifierOptionForm } from '../CreateModifierOption/ModifierOptionForm'
import { ModifierOptionFormModel } from '../CreateModifierOption/modifierOptionValidator'
import { convertModifierOptionToFormModel } from './convertModifierOptionToFormModel'
import { UpdateModifierOptionProps } from './UpdateModifierOption'

interface UpdateModifierOptionFormProps extends UpdateModifierOptionProps {
  inlineEdit?: boolean
  isSynced?: boolean
}

export const UpdateModifierOptionForm = (props: UpdateModifierOptionFormProps) => {
  const navigate = useNavigate()
  const { merchantId, modifierId: paramsModiferId } = useParams()

  const modifierId = props.modifierId ?? paramsModiferId!
  const { inlineEdit, onClose, isSynced } = props

  const { updateModifier, loading: loadingUpdate } = useUpdateModifier(merchantId!)
  const { data, loading, refetch } = useFetchModifierOptionQuery({
    variables: {
      id: modifierId
    }
  })

  async function done(refechModifier?: boolean) {
    if (!inlineEdit) {
      navigate(-1)
    } else if (!!onClose) {
      if (refechModifier) {
        var ret = await refetch()
        onClose(ret.data.modifier)
      } else {
        onClose()
      }
    }
  }

  const initialValues = useMemo(() => convertModifierOptionToFormModel(data?.modifier), [data?.modifier])

  async function handleSubmit(values: ModifierOptionFormModel) {
    const { currentQuantity, currentlyAvailable, ...rest } = values

    await updateModifier(
      {
        id: modifierId,
        description: undefined,
        childSelectionType: undefined,
        defaultSelectionIds: [],
        options: [],
        ...rest
      },
      () => done(true)
    )
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <ModifierOptionForm
          disableSubmit={loadingUpdate}
          isSynced={isSynced}
          onCancel={() => done()}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      )}
    </>
  )
}
