import { config } from 'config'
import { Maybe } from 'graphql/types.generated'
import { SquareLocationInfo } from 'models/Integrations'

export type SquareSyncVariant = 'CreateMerchant' | 'KdsIntegration' | 'SyncProducts'

export interface SquareLocationSelectionConfig {
  id: string
  info: SquareLocationInfo
  createKdsIntegration: boolean
  createMerchant: boolean
  syncProducts: boolean
  deleteExistingMenu: boolean
  onlyWebCatalogItems: boolean
  keepMenuSynced: boolean
}

const squareScope = [
  'ITEMS_READ',
  'EMPLOYEES_READ',
  'INVENTORY_READ',
  'MERCHANT_PROFILE_READ',
  'ORDERS_WRITE',
  'ORDERS_READ',
  'PAYMENTS_WRITE',
  'ONLINE_STORE_SITE_READ'
]

export function getSquareAuthorizationUrl(): string {
  const scopeString = squareScope.join('+')
  return (
    `${config.squareConnectUrl}/oauth2/authorize` +
    `?client_id=${config.squareClientId}` +
    `&scope=${scopeString}` +
    `&session=false`
  )
}

export function parseDataFromCsrfToken(csrfToken: string | null | undefined): Record<string, any> {
  if (!csrfToken) {
    return {}
  }

  let hyphenIndex: number | null = null
  for (let i = 0; i < csrfToken.length; i++) {
    if (hyphenIndex === null) {
      if (csrfToken[i] === '-') {
        hyphenIndex = i
      }
    }
  }

  if (hyphenIndex === null) {
    return {}
  }

  const b64Data = csrfToken.substring(0, hyphenIndex)
  return decodeDataFromCsrfToken(b64Data)
}

export function encodeDataForCsrfToken(
  organizationId: string,
  variant: SquareSyncVariant,
  merchantId: Maybe<string>
): string {
  const dataAsObject: Record<string, any> = { organizationId, variant }
  if (merchantId) {
    dataAsObject['merchantId'] = merchantId
  }
  return btoa(JSON.stringify(dataAsObject))
}

export function decodeDataFromCsrfToken(input: string): Record<string, any> {
  const decoded = atob(input)
  return JSON.parse(decoded) as Record<string, any>
}

const LOCAL_STORAGE_KEY_SQUARE_REDIRECT_DETAILS = 'squareRedirectDetails'

export function storeSquareRedirectDetails(
  organizationId: string,
  variant: SquareSyncVariant,
  merchantId: Maybe<string>
) {
  const dataAsObject: Record<string, any> = { organizationId, variant }
  if (merchantId) {
    dataAsObject['merchantId'] = merchantId
  }
  localStorage.setItem(LOCAL_STORAGE_KEY_SQUARE_REDIRECT_DETAILS, JSON.stringify(dataAsObject))
}

export function fetchSquareRedirectDetails(): Record<string, any> {
  const value = localStorage.getItem(LOCAL_STORAGE_KEY_SQUARE_REDIRECT_DETAILS)
  if (value === null) {
    return {}
  }
  return JSON.parse(value) as Record<string, any>
}
