import * as yup from 'yup'

import { ModifierChildSelectionType } from '../../../graphql/types.generated'
import { rules } from '../../../validation/rules'
import { transform } from '../../../validation/transform'

const modifierOptionForModifier = yup.object({
  id: yup.string().required(),
  name: yup.string().label('Name').optional(),
  printableName: yup.string().nullable(true).optional(),
  active: yup.boolean().label('Active').optional(),
  selectedName: yup.string().label('Selected name').optional(),
  priceCents: yup.number().label('Price').optional(),
  selectedByDefault: yup.boolean().required().default(false),
  currentQuantity: yup.number().label('Current Quantity').nullable(true).optional().default(0),
  currentlyAvailable: yup.boolean().label('Currently Available').nullable(true).optional().default(null)
})

export const modifierSchema = yup.object({
  id: yup.string().nullable(true).optional(),
  name: yup.string().label('Name').required(),
  printableName: yup.string().nullable(true).optional(),
  description: yup.string().label('Description').nullable(true).optional(),
  active: yup.boolean().label('Active').required().default(true),
  priceCents: yup
    .number()
    .transform(transform.nullable)
    .label('Price')
    .default(0)
    .optional()
    .typeError('Price needs to be a number'),
  childSelectionType: yup
    .mixed<ModifierChildSelectionType>()
    .oneOf(Object.values(ModifierChildSelectionType), 'Selection type is a required field')
    .label('Selection type')
    .required(),
  options: yup
    .array(modifierOptionForModifier)
    .label('Options')
    .test(rules.arrayNotEmpty('You need to add at least one option'))
    .test('default-selection-2', 'You must select at least one item as default', (value, context) => {
      const numberSelectedByDefault = value?.filter((option) => option?.selectedByDefault).length ?? 0
      return numberSelectedByDefault > 0
    })
    .test(
      'default-selection',
      'You can only select one option as default for Single-Select modifiers',
      (value, context) => {
        const numberSelectedByDefault = value?.filter((option) => option?.selectedByDefault).length ?? 0
        if (context.parent.childSelectionType === ModifierChildSelectionType.SingleSelect) {
          return numberSelectedByDefault === 1
        }
        return true
      }
    )
    .required()
})

interface ModifierControl {
  allowedChildSelectionTypes?: ModifierChildSelectionType[]
}
export type ModifierFormModel = yup.InferType<typeof modifierSchema> & ModifierControl
export type ModifierOptionForModifierFormModel = yup.InferType<typeof modifierOptionForModifier>
