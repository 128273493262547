import { Button } from '@bloom-coffee/steamed-milk'
import { Avatar, Card, CardActions, CardHeader, Container, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import logoRounded from 'assets/img/logo_rounded.png'
import { BackdropLoading } from 'components/layout/BackdropLoading'
import { useAcceptInviteMutation, useInviteDetailsQuery, UserRolesDocument } from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import { useLogout } from 'hooks/useLogout'
import { useToast } from 'hooks/useToast'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export function AcceptInvite() {
  const { token } = useParams()
  const styles = useStyles()
  const navigate = useNavigate()
  const toast = useToast()
  const errorHandler = useErrorHandler()
  const { data, loading, error } = useInviteDetailsQuery({
    variables: {
      token: token!
    }
  })
  const [accept] = useAcceptInviteMutation()
  const logout = useLogout()

  async function handleAcceptInvite() {
    try {
      await accept({
        variables: {
          token: token!
        },
        refetchQueries: [{ query: UserRolesDocument }]
      })
      navigate('/')
      toast.success('Invite accepted')
    } catch (e) {
      errorHandler(e)
    }
  }

  if (loading) {
    return <BackdropLoading message='Loading invite information' />
  }

  if (error) {
    return <Alert color='error'>{error.message}</Alert>
  }

  const inviterProfile = data?.userInvite.invitedBy.profile

  const invitedBy = inviterProfile?.firstName ? `${inviterProfile?.firstName} ${inviterProfile?.lastName}` : 'An user'

  return (
    <Container className={styles.root}>
      <Card variant='outlined' className={styles.inviteCard}>
        <CardHeader
          avatar={<Avatar src={logoRounded} />}
          title="You've been invited!"
          subheader={`${invitedBy} has invited you to join ${data?.userInvite.organization.name}`}
        />
        <CardActions>
          <Button label='Accept' onClick={handleAcceptInvite} />
        </CardActions>
      </Card>
      <Button onClick={logout} label='Logout' />
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(12)
  },
  inviteCard: {
    marginBottom: theme.spacing(2),
    width: 600
  }
}))
