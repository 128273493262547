import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers'
import { useController } from 'react-hook-form'

import { useFieldError } from '../../hooks/useFieldError'

type DateTimeFieldProps = Omit<KeyboardDateTimePickerProps, 'onChange' | 'value'> & {
  name: string
  defaultValue?: string
}

export function DateTimeField(props: DateTimeFieldProps) {
  const { name, defaultValue, ...rest } = props
  const error = useFieldError<any>({ name })
  const {
    field: { ref, onChange, value, ...fieldRest }
  } = useController({
    name,
    defaultValue: defaultValue || null
  })

  return (
    <KeyboardDateTimePicker
      {...fieldRest}
      {...rest}
      value={value}
      format='MM/dd/yyyy hh:mm a'
      inputRef={ref}
      inputVariant='outlined'
      margin='normal'
      autoOk={true}
      showTodayButton={true}
      onChange={(date, value) => {
        onChange(value)
      }}
      error={!!error}
      helperText={error?.message}
    />
  )
}
