import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CategoryType, ProductTagType } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { getCoffeeTagSuggestions, getTeaTagSuggestions } from 'util/productTags'

interface ProductTagsContainerProps {
  productName: string
  categoryType: CategoryType | null
  tagType: ProductTagType
  allowedTags: string[]
  selectedValues: string[]
  updateSelections: (values: string[]) => void
  disabled?: boolean
}

export const ProductTagsContainer = (props: ProductTagsContainerProps) => {
  const { productName, categoryType, allowedTags, selectedValues, updateSelections, tagType, disabled } = props

  const [overrideIntial, setOverrideInitial] = useState(false)

  useEffect(() => {
    if (overrideIntial) {
      setSuggestedTags()
    } else {
      setOverrideInitial(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productName, categoryType])

  function setSuggestedTags() {
    let newTags: string[] = []
    if (tagType === ProductTagType.Tea) {
      newTags = getTeaTagSuggestions(productName)
    } else if (tagType === ProductTagType.Coffee) {
      newTags = getCoffeeTagSuggestions(productName)
    }
    updateSelections(newTags)
  }

  function isTagSelected(tagValue: string): boolean {
    return selectedValues.indexOf(tagValue) >= 0
  }

  function toggleTagSelection(tagValue: string) {
    let updatedTagValues = [...selectedValues]
    const tagIndex = updatedTagValues.indexOf(tagValue)
    if (tagIndex >= 0) {
      updatedTagValues.splice(tagIndex, 1)
    } else {
      updatedTagValues.push(tagValue)
    }
    updateSelections(updatedTagValues)
  }

  return (
    <Section title='Smart Tags' variant='subsection'>
      <span>RDY uses Smart Tags to improve product suggestions</span>
      <div style={tagContainerStyle}>
        {allowedTags.map((tag) => (
          <Button
            disabled={disabled}
            style={{ ...tagButton, ...(isTagSelected(tag) ? selectedTag : unselectedTag) }}
            key={tag}
            label={tag}
            onClick={() => toggleTagSelection(tag)}
          />
        ))}
      </div>
    </Section>
  )
}

const tagContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxWidth: 700
}
const tagButton: React.CSSProperties = {
  margin: 5
}
const selectedTag: React.CSSProperties = {
  backgroundColor: '#c7f8ff'
}
const unselectedTag: React.CSSProperties = {
  color: '#ababab',
  backgroundColor: 'transparent'
}
