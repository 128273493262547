import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { ProductListDocument, useCreateTemplateMenuMutation } from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ImportChowlyMenu } from 'views/chowly/ImportChowlyMenu'
import { SquareButton } from 'views/square/SquareButton'

import { CopyAllProductsFromMerchantButton } from '../CopyProductsAllFromMerchantButton/CopyAllProductsFromMerchantButton'
import { SyncFreshMenuButton } from '../SyncFreshMenuButton'

interface EmptyProductListProps {
  organizationId: string
  merchantId: string
  onProductUpdate: () => void
}

export function EmptyProductList(props: EmptyProductListProps) {
  const { organizationId, merchantId, onProductUpdate } = props
  const toast = useToast()
  const navigate = useNavigate()

  const [execute, { loading }] = useCreateTemplateMenuMutation({
    variables: { merchantId },
    refetchQueries: [{ query: ProductListDocument, variables: { merchantId } }],
    awaitRefetchQueries: true
  })

  async function handleClick() {
    try {
      await execute()
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <Section
      title='No Products Found'
      subTitle='Quickly setup your cafe by starting with a Standard Menu of the most popular cafe products or create a new product to start building your menu from scratch.'
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SyncFreshMenuButton merchantId={merchantId} style={buttonStyle} onSuccess={onProductUpdate} />
          <Button
            label='Start from Standard Menu'
            style={buttonStyle}
            disabled={loading}
            onClick={handleClick}
            endIcon={loading && <CircularProgress size={16} />}
          />
          <Button label='Create New Product' style={{ marginLeft: 10 }} onClick={() => navigate('new')} />
          <div style={{ marginLeft: 10 }}>
            <SquareButton
              title='Import from Square'
              organizationId={organizationId!}
              merchantId={merchantId}
              variant='SyncProducts'
            />
          </div>
          <ImportChowlyMenu merchantId={merchantId} style={{ marginLeft: 10 }} />

          <HasAnyRole roleMatchers={[roles.admin]}>
            <CopyAllProductsFromMerchantButton style={buttonStyle} merchantId={merchantId} />
          </HasAnyRole>
        </div>
      </div>
    </Section>
  )
}

const buttonStyle: React.CSSProperties = {
  marginLeft: 5
}
