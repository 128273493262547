import { Button, Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Typography } from '@material-ui/core'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { useValidatePaymentAccountQuery } from 'graphql/types.generated'
import { RoleMatcher, roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AccountStatus } from './AccountStatus'
import { ArchivePaymentAccount } from './ArchivePaymentAccount'

interface PaymentAccountProps {
  organizationId: ID
  merchantId?: ID
}

export function PaymentAccount(props: PaymentAccountProps) {
  const { organizationId, merchantId } = props
  const navigate = useNavigate()
  const toast = useToast()

  const { data, loading } = useValidatePaymentAccountQuery({
    variables: {
      organizationId: organizationId,
      merchantId: merchantId
    }
  })

  async function setupPaymentAccount() {
    if (!!merchantId) navigate(`/organizations/${organizationId}/merchants/${merchantId}/billing/setupPaymentAccount`)
    else navigate(`/organizations/${organizationId}/billing/setupPaymentAccount`)
  }

  function getEditingRoles(): RoleMatcher[] {
    if (!!merchantId) return [roles.merchantAdmin(merchantId), roles.organizationAdmin(organizationId)]
    else return [roles.organizationAdmin(organizationId)]
  }

  function accountArchivedSuccessfully() {
    toast.success('Unlinked the Stripe Account')
  }

  if (loading) return <CircularProgress />

  if (!data) return <span>Stripe account information is unavailable at this time</span>

  return (
    <Section
      title='Payout Account'
      subTitle="Setting up a payout account for the whole organization will allow all payouts to go to a single account. If you would like to set up payout accounts for each cafe separately, update the payout account on the cafe's billing screen"
    >
      {data && data.validatePaymentAccount && <AccountStatus validatePaymentResult={data.validatePaymentAccount} />}

      {!!merchantId && <Typography>This payout account is specific to this cafe</Typography>}
      <HasAnyRole roleMatchers={getEditingRoles()}>
        {data && data.validatePaymentAccount && !data.validatePaymentAccount.chargesEnabled && (
          <Button
            label={data && data.validatePaymentAccount?.hasAccount ? 'Update Stripe Account' : 'Set up Stripe Account'}
            onClick={setupPaymentAccount}
            style={buttonStyle}
          />
        )}
        {data && data.validatePaymentAccount && data.validatePaymentAccount.hasAccount && (
          <ArchivePaymentAccount
            merchantId={merchantId}
            organizationId={organizationId}
            onSuccess={accountArchivedSuccessfully}
          />
        )}
      </HasAnyRole>
    </Section>
  )
}

const buttonStyle: React.CSSProperties = {
  marginRight: 3
}
