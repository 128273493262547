import { useWindowSize } from '@bloom-coffee/steamed-milk'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { useViewMerchantQuery } from 'graphql/types.generated'
import { useCafeValidationStatus } from 'hooks/useCafeValidationStatus/useCafeValidationStatus'
import { roles } from 'hooks/usePermissions/usePermissions'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MerchantStatusButton } from 'views/merchants/ViewMerchant/components/MerchantStatusButton'

import { HeaderMenuBar } from './HeaderMenuBar'
import { HeaderTitleBar } from './HeaderTitleBar'
import { UserTitle } from './UserTitle'

export function MerchantHeader() {
  const { merchantId, organizationId } = useParams()
  const navigate = useNavigate()

  const [windowSize] = useWindowSize()

  const { data: merchantData } = useViewMerchantQuery({
    variables: {
      id: merchantId!
    },
    fetchPolicy: 'cache-first',
    skip: !merchantId
  })
  const { isReadyToGoLive } = useCafeValidationStatus(merchantId!, organizationId!)

  const [orgTitleStyle, setOrgTitleStyle] = useState(orgTitleBaseStyle)

  return (
    <div>
      <HeaderTitleBar
        title={
          <div style={titleStyle}>
            {!!merchantData?.merchant?.organization?.name && windowSize === 'large' && (
              <div>
                <span
                  style={orgTitleStyle}
                  onClick={() => navigate(`/organizations/${organizationId}`)}
                  onMouseEnter={(e) => {
                    setOrgTitleStyle(orgTitleHoverStyle)
                  }}
                  onMouseLeave={(e) => {
                    setOrgTitleStyle(orgTitleBaseStyle)
                  }}
                >
                  {merchantData.merchant.organization.name}
                </span>

                <span style={{ paddingRight: 5 }}> &gt; </span>
              </div>
            )}
            {!!merchantData?.merchant && <div style={orgTitleBaseStyle}>{merchantData.merchant.name}</div>}
          </div>
        }
        subTitle={<UserTitle />}
        action={
          <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId!), roles.merchantAdmin(merchantId!)]}>
            {(!!merchantData?.merchant?.active || isReadyToGoLive()) && (
              <MerchantStatusButton merchantId={merchantId!} active={!!merchantData?.merchant?.active} />
            )}
          </HasAnyRole>
        }
      />
      <HeaderMenuBar
        menuButtonDetails={[
          {
            link: `/organizations/${organizationId}/merchants/${merchantId}`,
            paths: ['/organizations/:organizationId/merchants/:merchantId/home/*'],
            text: 'Cafe Home'
          },
          {
            link: `/organizations/${organizationId}/merchants/${merchantId}/menu`,
            paths: ['/organizations/:organizationId/merchants/:merchantId/menu/*'],
            text: 'Menu'
          },
          {
            link: `/organizations/${organizationId}/merchants/${merchantId}/billing`,
            paths: ['/organizations/:organizationId/merchants/:merchantId/billing/*'],
            text: 'Billing'
          },
          {
            link: `/organizations/${organizationId}/merchants/${merchantId}/reports`,
            paths: ['/organizations/:organizationId/merchants/:merchantId/reports/*'],
            text: 'Reports'
          }
        ]}
      />
    </div>
  )
}

const titleStyle: React.CSSProperties = { display: 'flex', flexDirection: 'row' }
const orgTitleBaseStyle: React.CSSProperties = { height: 40, textAlign: 'center' }
const orgTitleHoverStyle: React.CSSProperties = {
  ...orgTitleBaseStyle,
  cursor: 'pointer',
  textDecoration: 'underline'
}
