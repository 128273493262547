import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { CreateMerchantFeeConfig } from '../merchantFeeConfig/CreateMerchantFeeConfig/CreateMerchantFeeConfig'
import { MerchantFeeConfigList } from '../merchantFeeConfig/MerchantFeeConfigList'
import { CreateSubscriptionFee } from '../subscriptions/CreateSubscriptionFee/CreateSubscriptionFee'
import { CreateSubscriptionPlan } from '../subscriptions/CreateSubscriptionPlan/CreateSubscriptionPlan'
import { PlatformPricingSetup } from '../subscriptions/PlatformPricing/PlatformPricingSetup'
import { SavePlatformPricing } from '../subscriptions/SavePlatformPricing/SavePlatforrmPricing'
import { SubscriptionFees } from '../subscriptions/SubscriptionFees/SubscriptionFees'
import { SubscriptionPlans } from '../subscriptions/SubscriptionPlans/SubscriptionPlans'

export function MerchantFeesContainer() {
  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/admin/merchantFees/subscriptionPlans`,
          paths: [`/admin/merchantFees/subscriptionPlans/*`],
          text: 'Subscription Plans'
        },
        {
          link: `/admin/merchantFees/subscriptionFees`,
          paths: [`/admin/merchantFees/subscriptionFees/*`],
          text: 'Subscription Fees'
        },
        {
          link: `/admin/merchantFees/merchantFeeConfigs`,
          paths: [`/admin/merchantFees/merchantFeeConfigs/*`],
          text: 'Transaction Fees'
        },
        {
          link: `/admin/merchantFees/platformPricing`,
          paths: [`/admin/merchantFees/platformPricing/*`],
          text: 'Platform Pricing'
        }
      ]}
    >
      <Routes>
        <Route path={`subscriptionPlans/new`} element={<CreateSubscriptionPlan />} />
        <Route path={`subscriptionPlans`} element={<SubscriptionPlans />} />
        <Route path={`subscriptionFees/new`} element={<CreateSubscriptionFee />} />
        <Route path={`subscriptionFees`} element={<SubscriptionFees />} />
        <Route path={`merchantFeeConfigs/new`} element={<CreateMerchantFeeConfig />} />
        <Route path={`merchantFeeConfigs`} element={<MerchantFeeConfigList />} />
        <Route path={`platformPricing/save`} element={<SavePlatformPricing />} />
        <Route path={`platformPricing`} element={<PlatformPricingSetup />} />
        <Route path={`*`} element={<Navigate to={'subscriptionPlans'} replace={true} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
