import { ApolloCache, FetchResult } from '@apollo/client'
import { Button, Section, Text } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress } from '@material-ui/core'
import { TextField } from 'components/form/TextField'
import { config } from 'config'
import {
  OrganizationReadableIdDocument,
  OrganizationReadableIdQuery,
  OrganizationReadableIdQueryVariables,
  UpdateOrganizationReadableIdMutation,
  useOrganizationReadableIdQuery,
  useUpdateOrganizationReadableIdMutation
} from 'graphql/types.generated'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

interface WebOrderingOrganizationUrlProps {
  organizationId: string
}

const readableIdSchema = yup.object({
  readableId: yup.string().label('Url Path').required()
})

type ReadableIdFormModel = yup.InferType<typeof readableIdSchema>

export const WebOrderingOrganizationUrl = (props: WebOrderingOrganizationUrlProps) => {
  const { organizationId } = props

  const [error, setError] = useState<string | undefined>()

  const { data, loading: fetchingReadableId } = useOrganizationReadableIdQuery({
    fetchPolicy: 'cache-first',
    variables: { organizationId }
  })

  const [execute, { loading: updatingReadableId }] = useUpdateOrganizationReadableIdMutation()

  const formProps = useForm<ReadableIdFormModel>({
    resolver: yupResolver(readableIdSchema)
  })
  const { register, handleSubmit, formState } = formProps

  function updateCache(
    cache: ApolloCache<UpdateOrganizationReadableIdMutation>,
    mutationResult: FetchResult<UpdateOrganizationReadableIdMutation>
  ) {
    const newReadableId = mutationResult.data?.updateOrganizationReadableId.readableId
    if (newReadableId) {
      const cachedData = cache.readQuery<OrganizationReadableIdQuery, OrganizationReadableIdQueryVariables>({
        query: OrganizationReadableIdDocument,
        variables: { organizationId }
      })

      const organization = cachedData?.organization
        ? { ...cachedData.organization, readableId: newReadableId }
        : {
            id: organizationId,
            readableId: newReadableId
          }

      cache.writeQuery<OrganizationReadableIdQuery, OrganizationReadableIdQueryVariables>({
        query: OrganizationReadableIdDocument,
        variables: { organizationId },
        data: { organization }
      })
    }
  }

  async function onSubmit(input: ReadableIdFormModel) {
    const readableId = input.readableId.replace(/ /g, '')

    if (readableId !== data?.organization?.readableId) {
      try {
        setError(undefined)
        await execute({ variables: { organizationId, readableId }, update: updateCache })
      } catch (e) {
        const err = `${e}`
        if (err.toLowerCase().includes('taken')) {
          setError('This url is already taken')
        } else {
          setError('An unknown error occurred. Please try again')
        }
      }
    }
  }

  return (
    <Section title='Web Ordering'>
      {(fetchingReadableId || updatingReadableId) && <CircularProgress size='small' />}

      <div style={{ marginBottom: 12 }}>
        {data?.organization?.readableId && (
          <a href={`${config.latteBaseUrl}/locations/${data.organization.readableId}`} target='_blank' rel='noreferrer'>
            <Text variant='body1'>{config.latteBaseUrl + '/locations/' + data.organization.readableId}</Text>
          </a>
        )}

        {!!data?.organization && !data.organization?.readableId && (
          <>
            <Text variant='body1'>
              You have not created a url path for your web-ordering link.
              <br />
            </Text>
            <Text variant='body1'>
              Enter a path to generate a link with the format {`${config.latteBaseUrl}/locations/your_url_path`}{' '}
            </Text>
          </>
        )}
      </div>

      <FormProvider {...formProps}>
        <form>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 8 }}>
              <TextField label='Url Path' name='readableId' inputRef={register()} />

              <div style={{ marginTop: 8 }}>{error && <Text variant='error'>{error}</Text>}</div>
            </div>

            <div>
              <Button
                size='medium'
                label='Update'
                onClick={handleSubmit(onSubmit)}
                disabled={fetchingReadableId || updatingReadableId || formState.isSubmitting}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Section>
  )
}
