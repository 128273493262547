import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { Navigate, Route, Routes } from 'react-router-dom'

import { CreatePromoCode } from '../promotions/CreatePromoCode/CreatePromoCode'
import { CreatePromotion } from '../promotions/CreatePromotion/CreatePromotion'
import { Promotion } from '../promotions/Promotion/Promotion'
import { Promotions } from '../promotions/Promotions/Promotions'
import { GrantTokens } from '../tokens/GrantTokens/GrantTokens'

export function PromotionsContainer() {
  return (
    <MenuedContentContainer
      menuButtonDetails={[
        {
          link: `/admin/promotions/promotions`,
          paths: [`/admin/promotions/promotions/*`],
          text: 'View Promotions'
        },
        {
          link: `/admin/promotions/tokens/grant`,
          paths: [`/admin/promotions/tokens/grant`],
          text: 'Grant Tokens'
        }
      ]}
    >
      <Routes>
        <Route path={`tokens/grant`} element={<GrantTokens />} />
        <Route path={`promotions/new`} element={<CreatePromotion />} />
        <Route path={`promotions/:promotionId/code`} element={<CreatePromoCode />} />
        <Route path={`promotions/:promotionId`} element={<Promotion />} />
        <Route path={`promotions`} element={<Promotions />} />
        <Route path={`*`} element={<Navigate to={'promotions'} />} />
      </Routes>
    </MenuedContentContainer>
  )
}
