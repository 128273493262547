import { Button } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { TextField } from 'components/form/TextField'
import { Role, useInviteUserMutation } from 'graphql/types.generated'
import { useErrorHandler } from 'hooks/useErrorHandler'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { InviteUserFormModel, inviteUserSchema } from './inviteUserValidator'

interface InviteUserButtonProps {
  organizationId: ID
}

export function InviteUserButton(props: InviteUserButtonProps) {
  const { organizationId } = props
  const [execute] = useInviteUserMutation()
  const [open, setOpen] = useState(false)
  const toast = useToast()
  const styles = useStyles()
  const handleError = useErrorHandler()
  const formProps = useForm<InviteUserFormModel>({
    resolver: yupResolver(inviteUserSchema)
  })
  const { register, handleSubmit, formState } = formProps

  function toggleModal() {
    setOpen((old) => !old)
  }

  async function inviteUser(values: InviteUserFormModel) {
    try {
      await execute({
        variables: {
          input: {
            organizationId,
            roles: [Role.Admin, Role.TreasuryContact],
            acceptUrl: `${window.location.origin}/acceptInvite`,
            ...values
          }
        }
      })
      toggleModal()
      toast.success('Invite sent.')
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <>
      <Button label='Invite admin user' onClick={toggleModal} />
      <Dialog open={open} onClose={toggleModal}>
        <DialogTitle>Invite Admin User</DialogTitle>
        <DialogContent className={styles.dialog}>
          <FormProvider {...formProps}>
            <form onSubmit={handleSubmit(inviteUser)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name='email' label='Email' inputRef={register()} />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <DefaultFormFooter
            onCancel={toggleModal}
            onSubmit={handleSubmit(inviteUser)}
            saveLabel='Send invite'
            submitting={formState.isSubmitting}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 400
  }
}))
