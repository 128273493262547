import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

interface ConfirmationDialogProps {
  open?: boolean
  cancelLabel?: string
  confirmLabel?: string
  title: string
  onClose(): void
  onConfirm(): void
  confirmDisabled?: boolean
  loading?: boolean
}

export function ConfirmationDialog(props: PropsWithChildren<ConfirmationDialogProps>) {
  const {
    cancelLabel = 'Cancel',
    confirmLabel = 'Confirm',
    children,
    open = true,
    confirmDisabled,
    title,
    onClose,
    onConfirm,
    loading
  } = props

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} theme='cancel' label={cancelLabel} />{' '}
        <Button
          onClick={onConfirm}
          disabled={confirmDisabled}
          label={confirmLabel}
          endIcon={loading && <CircularProgress color='inherit' size={16} />}
        />
      </DialogActions>
    </Dialog>
  )
}
