import { ApolloCache, FetchResult } from '@apollo/client'

import {
  ArchiveTopModifierMutation,
  TopModifierFragment,
  useArchiveTopModifierMutation
} from '../graphql/types.generated'
import { useToast } from './useToast'

export function useArchiveTopModifier() {
  const toast = useToast()
  const [execute, { loading }] = useArchiveTopModifierMutation()

  function updateCache(
    cache: ApolloCache<ArchiveTopModifierMutation>,
    mutationResult: FetchResult<ArchiveTopModifierMutation>
  ) {
    const idToRemove = mutationResult.data?.archiveModifier.id

    if (idToRemove) {
      cache.modify({
        fields: {
          topModifiersByMerchant(existingMods, { readField }) {
            return existingMods.filter((modOption: TopModifierFragment) => idToRemove !== readField('id', modOption))
          }
        }
      })
    }
  }

  async function archive(id: ID, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { id },
        update: updateCache
      })

      if (res.data?.archiveModifier) {
        onSuccess()
      } else {
        throw new Error(`Could not archive mod: ${res.errors}`)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  return { archive, loading }
}
