import { Button } from '@bloom-coffee/steamed-milk'
import { DateTimeField } from 'components/form/DateTimeField'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

export function SuspendedUntil() {
  const { setValue, watch } = useFormContext()

  const [suspended, setSuspended] = useState(false)
  const suspendedUntil = watch('suspendedUntil')

  useEffect(() => {
    if (!!suspendedUntil && new Date(suspendedUntil) > new Date()) {
      setSuspended(true)
    }
    if (!suspendedUntil) {
      setSuspended(false)
    }
  }, [suspendedUntil])

  return (
    <div style={{ width: '100%', margin: 10 }}>
      {suspended && (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <DateTimeField
            name='suspendedUntil'
            label={`86'd Until`}
            defaultValue={suspendedUntil || new Date().toISOString()}
          />
          <Button
            style={{ marginLeft: 10, marginTop: 'auto', marginBottom: 'auto' }}
            label={'Cancel 86'}
            onClick={() => setValue('suspendedUntil', null)}
          />
        </div>
      )}
      {!suspended && (
        <div style={{ width: '100%' }}>
          <Button
            label={'86 Product'}
            onClick={() => {
              var date = new Date()
              date.setDate(date.getDate() + 1)
              setValue('suspendedUntil', date.toString())
            }}
          />
        </div>
      )}
    </div>
  )
}
