import { DayHoursFormModel, DayHoursModel } from 'components/hours/validator'
import { Maybe, MerchantOperationHourFragment } from 'graphql/types.generated'
import { groupBy, mapValues } from 'lodash'

export function groupMerchantHours(hours: Maybe<MerchantOperationHourFragment[]> = []): DayHoursModel[] {
  const gr = mapValues(
    groupBy(hours, (hour) => hour.dayOfWeek),
    (values) => {
      return values.map((value) => ({ start: value.start, end: value.end }))
    }
  )

  return Object.keys(gr).map((key) => {
    return {
      weekDay: Number(key),
      timeIntervals: gr[key]
    } as DayHoursModel
  })
}

export function convertAndGroupAvailabilityWindows(hours: Maybe<DayHoursFormModelWrapper[]> = []): DayHoursModel[] {
  const gr = mapValues(
    groupBy(hours, (hour) => !!hour.weekDays?.length && hour.weekDays[0]),
    (values) => {
      return values.map((value) => ({
        start: !!value.timeIntervals?.length && value.timeIntervals[0].start,
        end: !!value.timeIntervals?.length && value.timeIntervals[0].end
      }))
    }
  )

  return Object.keys(gr).map((key) => {
    return {
      weekDay: Number(key),
      timeIntervals: gr[key]
    } as DayHoursModel
  })
}

export function groupAvailabilityWindows(hours: Maybe<DayHoursModel[]> = []): DayHoursModel[] {
  const gr = mapValues(
    groupBy(hours, (hour) => hour.weekDay),
    (values) => {
      return values
        .map((value) => {
          if (!value.timeIntervals?.length) {
            return null
          }
          return {
            start: value.timeIntervals[0].start,
            end: value.timeIntervals[0].end
          }
        })
        .filter((v) => !!v)
    }
  )

  return Object.keys(gr).map((key) => {
    return {
      weekDay: Number(key),
      timeIntervals: gr[key]
    } as DayHoursModel
  })
}

export interface DayHoursFormModelWrapper {
  weekDays?: number[] | undefined
  timeIntervals?: {
    start?: string | undefined
    end?: string | undefined
  }[]
}

export function convertDayHoursModel(value: DayHoursFormModel): DayHoursModel[] {
  return value.weekDays.map((day) => {
    return {
      timeIntervals: value.timeIntervals,
      weekDay: day
    }
  })
}

export function convertToDayHoursFormModel(value: DayHoursModel): DayHoursFormModel {
  return {
    timeIntervals: value.timeIntervals,
    weekDays: [value.weekDay]
  }
}
