import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, IconButton, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import EditIcon from '@material-ui/icons/Edit'
import { TextField } from 'components/form/TextField'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface TextInPlaceEditorProps {
  value: string
  label: string
  onCancel?(): void
  onSubmit(value: string): Promise<boolean>
  loading: boolean
  style?: React.CSSProperties
  variant?: Variant
}

export function TextInPlaceEditor(props: TextInPlaceEditorProps) {
  const { onCancel, onSubmit, label, value, loading, style, variant } = props
  const formProps = useForm<TextFormModel>()
  const [editingField, setEditingField] = useState(false)

  const { register, handleSubmit } = formProps

  async function submit(value: TextFormModel) {
    if (!(await onSubmit(value.fieldValue))) {
      return
    }
    setEditingField(false)
  }

  function cancel() {
    onCancel!!()
    setEditingField(false)
  }

  return (
    <div style={style}>
      {!editingField && (
        <div style={formStyle}>
          <Typography variant={variant}>{value}</Typography>

          <IconButton style={{ height: 32, width: 32 }} onClick={() => setEditingField(true)}>
            <EditIcon style={{ height: 18 }} />
          </IconButton>
          {loading && <CircularProgress size={14} />}
        </div>
      )}
      {editingField && (
        <div>
          <form onSubmit={handleSubmit(submit)} style={formStyle}>
            <FormProvider {...formProps}>
              <TextField
                fullWidth
                defaultValue={value}
                name='fieldValue'
                label={label}
                inputRef={register({ required: 'Field is required' })}
              />
            </FormProvider>
            {!!onCancel && <Button style={buttonStyle} onClick={cancel} theme='cancel' label='Cancel' />}
            <Button style={buttonStyle} onClick={handleSubmit(submit)} label='Save' />
            {loading && (
              <div>
                <CircularProgress size={14} />
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  )
}

export interface TextFormModel {
  fieldValue: string
}

const formStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}

const buttonStyle: React.CSSProperties = {
  margin: 5
}
