import { Maybe, ModifierOptionFragment, TopModifierFragment } from '../../../graphql/types.generated'
import { ModifierFormModel } from '../CreateModifier/modifierValidator'

export function convertTopModifierToFormModel(modifier: Maybe<TopModifierFragment>): ModifierFormModel | undefined {
  if (!modifier) {
    return undefined
  }

  return {
    id: modifier.id,
    name: modifier.name,
    printableName: modifier.printableName,
    priceCents: modifier.priceCents,
    active: modifier.active,
    childSelectionType: modifier.childSelectionType!,
    description: modifier.description,
    options:
      modifier.allChildren?.map((mo: ModifierOptionFragment) => {
        return {
          id: mo.id,
          name: mo.name,
          printableName: mo.printableName,
          priceCents: mo.priceCents,
          active: true,
          selectedByDefault: !!modifier.defaultSelectionIds?.includes(mo.id),
          selectedName: mo.selectedName || undefined,
          currentQuantity: mo.inventory?.currentQuantity === undefined ? null : mo.inventory?.currentQuantity,
          currentlyAvailable:
            mo.inventory?.currentlyAvailable === false ? false : mo.inventory?.currentlyAvailable ?? true
        }
      }) || []
  }
}
