import { Button } from '@bloom-coffee/steamed-milk'
import { DialogContentText } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useArchiveMerchantPaymentAccount } from 'hooks/useArchiveMerchantPaymentAccount'
import { useArchiveOrganizationPaymentAccount } from 'hooks/useArchiveOrganizationPaymentAccount'
import { useErrorHandler } from 'hooks/useErrorHandler'
import React, { useState } from 'react'

interface ArchivePaymentAccountProps {
  organizationId: string
  merchantId?: string
  onSuccess: () => void
}

export const ArchivePaymentAccount = (props: ArchivePaymentAccountProps) => {
  const { merchantId, organizationId, onSuccess } = props

  const errorHandler = useErrorHandler()
  const [archiveDialogOpened, setArchiveDialogOpened] = useState(false)
  const { archive: archiveOrgPayment, loading: loadingOrgPaymentArchive } = useArchiveOrganizationPaymentAccount()
  const { archive: archiveMerchPayment, loading: loadingMerchPaymentArchive } = useArchiveMerchantPaymentAccount()

  async function handleArchive() {
    try {
      if (!!merchantId) await archiveMerchPayment(merchantId!!, onSuccess)
      else await archiveOrgPayment(organizationId, onSuccess)
    } catch (e) {
      errorHandler(e)
    }
    setArchiveDialogOpened(false)
  }

  const loading = loadingOrgPaymentArchive || loadingMerchPaymentArchive

  return (
    <>
      <ConfirmationDialog
        title='Unlink Stripe Account'
        open={archiveDialogOpened}
        onClose={() => setArchiveDialogOpened(false)}
        onConfirm={handleArchive}
      >
        <DialogContentText>Are you sure you want to unlink this Stripe account?</DialogContentText>
      </ConfirmationDialog>
      <Button
        disabled={loading}
        label='Unlink Stripe Account'
        endIcon={<DeleteIcon />}
        onClick={() => setArchiveDialogOpened(true)}
      />
    </>
  )
}
