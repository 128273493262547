import { ApolloCache, FetchResult } from '@apollo/client'

import {
  ArchiveMerchantPaymentAccountMutation,
  useArchiveMerchantPaymentAccountMutation,
  ValidatePaymentAccountDocument
} from '../graphql/types.generated'
import { useToast } from './useToast'

export function useArchiveMerchantPaymentAccount() {
  const toast = useToast()
  const [execute, { loading }] = useArchiveMerchantPaymentAccountMutation()

  function updateCache(
    cache: ApolloCache<ArchiveMerchantPaymentAccountMutation>,
    mutationResult: FetchResult<ArchiveMerchantPaymentAccountMutation>
  ) {
    const merchantId = mutationResult.data?.archiveMerchantPaymentAccount.id
    const organizationId = mutationResult.data?.archiveMerchantPaymentAccount.organization.id

    if (merchantId) {
      cache.writeQuery({
        query: ValidatePaymentAccountDocument,
        data: {
          validatePaymentAccount: {
            hasAccount: false,
            chargesEnabled: false
          }
        },
        variables: {
          organizationId: organizationId,
          merchantId: merchantId
        }
      })
    }
  }

  async function archive(merchantId: string, onSuccess: () => void) {
    try {
      const res = await execute({
        variables: { id: merchantId },
        update: updateCache
      })

      if (res.data?.archiveMerchantPaymentAccount) {
        onSuccess()
      } else {
        throw new Error(`Could not archive merchant payment account: ${res.errors}`)
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  return { archive, loading }
}
