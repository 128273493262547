import { QRCodeLink } from 'components/widgets/QRCodeLink'
import { useMerchantLinksQuery } from 'graphql/types.generated'
import React from 'react'

const tableStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: 10
}
const leftColumnStyle: React.CSSProperties = {
  textAlign: 'right',
  paddingRight: 10,
  flex: 1,
  margin: 'auto',
  fontWeight: 500
}
const columnStyle: React.CSSProperties = {
  flex: 1
}

const rowStyle: React.CSSProperties = { display: 'flex', flexDirection: 'row' }

interface MerchantDeepLinkGridProps {
  merchantId: string
}

export const MerchantDeepLinkGrid = (props: MerchantDeepLinkGridProps) => {
  const { merchantId } = props

  const { data } = useMerchantLinksQuery({ fetchPolicy: 'cache-first', variables: { id: merchantId } })

  return (
    <div style={tableStyle}>
      {data?.merchant?.deepLinks?.length && (
        <div style={rowStyle}>
          <div style={leftColumnStyle}>In Store QR Code</div>
          <div style={columnStyle}>
            <QRCodeLink link={data?.merchant?.deepLinks[0].link} />
          </div>
        </div>
      )}
      <div style={rowStyle}>
        <div style={leftColumnStyle}>Linktree - Add to your IG and website</div>
        <div style={columnStyle}>
          <a href='https://linktr.ee/RDYapp'>https://linktr.ee/RDYapp</a>
        </div>
      </div>
      <div style={rowStyle}>
        <div style={leftColumnStyle}>Marketing Material - Pick Up Sign</div>
        <div style={columnStyle}>
          <a href='https://rdyimageprod.blob.core.windows.net/image/PickUpSign.pdf'>PickUpSign.pdf</a>
        </div>
      </div>
      <div style={rowStyle}>
        <div style={leftColumnStyle}>Marketing Material - A-Frame and POS Sign</div>
        <div style={columnStyle}>
          <a href='https://rdyimageprod.blob.core.windows.net/image/AFramePosSign.pdf'>AFramePosSign.pdf</a>
        </div>
      </div>
      {data?.merchant?.deepLinks?.length && (
        <div style={rowStyle}>
          <div style={leftColumnStyle}>RDY App Download Link</div>
          <div style={columnStyle}>
            <a href={data?.merchant?.deepLinks[0].link}>{data?.merchant?.deepLinks[0].link}</a>
          </div>
        </div>
      )}
    </div>
  )
}
