import { Color, Section, Text } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { ReactComponent as FreshLogo } from 'assets/svg/freshLogo.svg'
import { ImportFreshToGoCafes } from 'components/FreshToGoIntegration/ImportFreshToGoCafes'
import {
  DiningOption,
  GeoFenceType,
  Maybe,
  MerchantLocationInput,
  UserRolesDocument,
  useSetupOrganizationMutation,
  useUpdatePersonalInfoMutation,
  useUserRolesQuery,
  useValidateAndCreateUserMutation
} from 'graphql/types.generated'
import { useCafeLeadDetailsStorage } from 'hooks/useCafeLeadDetailsStorage/useCafeLeadDetailsStorage'
import { useErrorHandler } from 'hooks/useErrorHandler'
import React, { useEffect, useState } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { MerchantForm } from 'views/merchants/components/MerchantForm/MerchantForm'
import { MerchantFormModel } from 'views/merchants/components/MerchantForm/merchantValidator'
import { OrganizationForm, OrganizationFormModel } from 'views/organizations/components/OrganizationForm'
import { getSquareAuthorizationUrl, storeSquareRedirectDetails } from 'views/square/SquareUtils'

import { WizardFooter } from './WizardFooter'

export function OnboardingSteps() {
  const [organization, setOrganization] = useState<OrganizationFormModel>()
  const [merchant, setMerchant] = useState<MerchantFormModel>()
  const [activeStep, setActiveStep] = useState(0)
  const [organizationId, setOrganizationId] = useState<string | undefined>()
  const [loadingSquare, setLoadingSquare] = useState(false)

  let location = useLocation()
  let isFreshOnboardingPath = !!matchPath('/fresh-onboarding', location.pathname)

  const { cafeLeadDetails } = useCafeLeadDetailsStorage()
  const [setup, { loading }] = useSetupOrganizationMutation()
  const [updateUserProfile] = useUpdatePersonalInfoMutation()
  const [validateAndCreateAdminUser, { loading: savingUser }] = useValidateAndCreateUserMutation()
  const errorHandler = useErrorHandler()
  const navigate = useNavigate()
  const [isFreshOnboarding, setIsFreshOnboarding] = useState(isFreshOnboardingPath)

  const { refetch } = useUserRolesQuery({
    fetchPolicy: 'cache-first'
  })

  const steps = [
    'Organization Name',
    ...(isFreshOnboarding
      ? [
          <div>
            <span>Import Cafes From </span>
            <FreshLogo style={{ width: 100, height: 'auto' }} />
          </div>
        ]
      : ['Cafe Details'])
  ]

  useEffect(() => {
    if (activeStep === 0 && !!cafeLeadDetails?.organizationName) {
      handleSaveOrganization({
        name: cafeLeadDetails?.organizationName!!
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cafeLeadDetails, activeStep])

  async function handleSaveOrganization(organziationFormData: OrganizationFormModel) {
    setOrganization(organziationFormData)
    if (isFreshOnboardingPath) {
      if (!(await saveUserAndOrganization(organziationFormData))) {
        return
      }
    }
    setActiveStep((curr) => curr + 1)
  }

  async function updateUserProfileFromSignUp() {
    if (!cafeLeadDetails) {
      return
    }

    await updateUserProfile({
      variables: {
        input: {
          contactEmail: cafeLeadDetails.email,
          firstName: cafeLeadDetails.firstName,
          lastName: cafeLeadDetails.lastName,
          phoneNumber: cafeLeadDetails.phoneNumber
        }
      }
    })
  }

  async function saveMerchantAdmin() {
    await validateAndCreateAdminUser({
      variables: {
        overrideDuplicateCheck: true
      }
    })
    await updateUserProfileFromSignUp()
  }

  async function handleSaveMerchant(values: MerchantFormModel) {
    const { operationHours, location: locationForm, diningOptions: diningOptionStrings, ...rest } = values
    setMerchant(values)

    const diningOptions = diningOptionStrings as DiningOption[]
    const { geoFenceType: geoFenceTypeString, ...locationRest } = locationForm
    const location = locationRest as MerchantLocationInput
    location.geoFenceType = geoFenceTypeString as GeoFenceType

    try {
      await saveMerchantAdmin()
      let saveMerchantResponse = await setup({
        variables: {
          input: {
            name: organization?.name!,
            merchant: {
              id: null,
              organizationId: null,
              ...rest,
              location,
              hours:
                operationHours?.map((h) => {
                  return {
                    weekDay: h.weekDays[0],
                    timeIntervals: h.timeIntervals
                  }
                }) ?? [],
              taxRate: values.taxRate!,
              diningOptions
            }
          }
        },
        refetchQueries: [{ query: UserRolesDocument }]
      })
      var org = saveMerchantResponse.data!.setupOrganization
      var merchantId = org!.merchants![0].id

      navigate(`/organizations/${org.id}/merchants/${merchantId}`)
    } catch (e: any) {
      errorHandler(e)
    }
  }

  async function saveUserAndOrganization(organziationFormData?: OrganizationFormModel): Promise<Maybe<string>> {
    try {
      await saveMerchantAdmin()
      let createOrganziationResponse = await setup({
        variables: {
          input: {
            name: organziationFormData?.name!,
            merchant: null
          }
        }
      })

      const organizationId = createOrganziationResponse.data!.setupOrganization.id
      setOrganizationId(organizationId)
      return organizationId
    } catch (e: any) {
      errorHandler(e)
    }
  }

  async function handleSquareClick() {
    try {
      setLoadingSquare(true)

      const organizationId = await saveUserAndOrganization(organization)
      if (!organizationId) {
        throw new Error('Something went wrong')
      }

      storeSquareRedirectDetails(organizationId, 'CreateMerchant', null)
      setLoadingSquare(false)
      window.location.href = getSquareAuthorizationUrl()
    } catch (e) {
      setLoadingSquare(false)
      errorHandler(e)
    }
  }

  return (
    <Section
      title={steps[activeStep]}
      addon={
        <>
          Step {activeStep + 1} of {steps.length}
        </>
      }
    >
      {activeStep === 0 && (
        <Section title='Organization name' variant='subsection'>
          {!loading && (
            <OrganizationForm
              initialValues={organization}
              onSubmit={handleSaveOrganization}
              FooterComponent={WizardFooter}
            />
          )}
          {loading && <CircularProgress />}
        </Section>
      )}
      {activeStep === 1 && isFreshOnboarding && (
        <>
          {loading && <CircularProgress />}
          {!!organizationId && (
            <ImportFreshToGoCafes
              onCancel={() => {
                setIsFreshOnboarding(false)
                refetch()
              }}
              onSuccess={() => {
                refetch()
                navigate(`/organizations/${organizationId}`)
              }}
              organizationId={organizationId!}
            />
          )}
        </>
      )}
      {activeStep === 1 && !isFreshOnboarding && (
        <div>
          <div style={{ marginBottom: 20 }}>
            <Text variant='header2'>Import a Cafe</Text>
          </div>

          {loadingSquare && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <Text variant='header3'>Loading Square</Text>
              </div>
              <div>
                <CircularProgress />
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 24
            }}
          >
            <div style={{ marginLeft: 12, marginRight: 12 }}>
              <button
                style={{
                  cursor: 'pointer',
                  backgroundColor: Color.RDY_BLACK,
                  width: 180,
                  height: 36,
                  borderRadius: 4,
                  border: 'none'
                }}
                onClick={() => handleSquareClick()}
                disabled={savingUser}
              >
                <Text variant='subheader2' color={Color.WHITE}>
                  Import from Square
                </Text>
              </button>
            </div>

            <div
              style={{
                marginLeft: 12,
                marginRight: 12,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <button
                style={{ margin: 10, borderRadius: 5, cursor: 'pointer' }}
                onClick={() => {
                  setIsFreshOnboarding(true)
                  saveUserAndOrganization(organization)
                }}
                disabled={savingUser}
              >
                <FreshLogo style={{ width: 150, height: 'auto' }} />
              </button>

              <a href='https://youtu.be/BBIDO28PWWo' target='_blank' rel='noopener noreferrer'>
                How to import from Fresh
              </a>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ flex: 1, height: 1, backgroundColor: Color.GREY_300 }} />
            <div style={{ paddingLeft: 12, paddingRight: 12 }}>
              <Text variant='subheader1'>OR</Text>
            </div>
            <div style={{ flex: 1, height: 1, backgroundColor: Color.GREY_300 }} />
          </div>

          <div style={{ marginBottom: 20 }}>
            <Text variant='header2'>Create Your Own</Text>
          </div>
          <div>
            <MerchantForm
              defaultValues={merchant}
              onSubmit={handleSaveMerchant}
              FooterComponent={({ onSubmit }) => (
                <WizardFooter
                  onBack={() => setActiveStep((curr) => curr - 1)}
                  onSubmit={onSubmit}
                  nextLabel='Finish setup'
                  loading={loading || savingUser}
                />
              )}
            />
          </div>
        </div>
      )}
    </Section>
  )
}
