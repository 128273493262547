import { Menu, MenuItem } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import {
  Role,
  useAddOrganizationUserRoleMutation,
  User,
  useRemoveOrganizationUserRoleMutation,
  ViewOrganizationDocument
} from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import React, { MouseEvent, useState } from 'react'

interface OrganziationUserMenuProps {
  organizationId: ID
  user: User
}

export function OrganizationUserMenu(props: OrganziationUserMenuProps) {
  const { organizationId, user } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const toast = useToast()
  const [addUserRole] = useAddOrganizationUserRoleMutation()
  const [removeUserRole] = useRemoveOrganizationUserRoleMutation()
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  async function handleRemoveTreasuryRole() {
    try {
      await removeUserRole({
        variables: { organizationId, userId: user.id, role: Role.TreasuryContact },
        refetchQueries: [
          {
            query: ViewOrganizationDocument,
            variables: {
              id: organizationId
            }
          }
        ]
      })
      handleClose()
    } catch (e) {
      toast.error(e.toString())
    }
  }

  async function handleAddTreasuryRole() {
    try {
      await addUserRole({
        variables: { organizationId, userId: user.id, role: Role.TreasuryContact },
        refetchQueries: [
          {
            query: ViewOrganizationDocument,
            variables: {
              id: organizationId
            }
          }
        ]
      })
      handleClose()
    } catch (e) {
      toast.error(e.toString())
    }
  }

  function userHasTreasuryContactRole() {
    return (user.roles?.filter((role: string) => roles.treasuryContact(organizationId)(role)) ?? []).length > 0
  }

  return (
    <>
      <button onClick={handleClick} style={menuButtonStyle}>
        <MenuIcon />
      </button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {!userHasTreasuryContactRole() && (
          <MenuItem onClick={handleAddTreasuryRole}>Add Treasury Contact Role</MenuItem>
        )}
        {userHasTreasuryContactRole() && (
          <MenuItem onClick={handleRemoveTreasuryRole}>Remove Treasury Contact Role</MenuItem>
        )}
      </Menu>
    </>
  )
}

const menuButtonStyle: React.CSSProperties = {
  background: 'transparent',
  border: 'none',
  width: 30,
  height: 30,
  padding: 0,
  margin: 'auto'
}
