import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Checkbox, DialogContentText, lighten, makeStyles, Switch, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import { Alert } from '@material-ui/lab'
import SquareLogo from 'assets/img/SquareLogo.png'
import clsx from 'clsx'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import {
  ExternalDataSource,
  ProductListDocument,
  UpdateSortOrderInput,
  useArchiveProductMutation,
  useArchiveProductsMutation,
  useCategorySelectQuery,
  useGetMenuSyncConfigQuery,
  useProductListQuery,
  useUpdateCategorySortOrdersMutation,
  useUpdateProductCategoriesMutation,
  useUpdateProductsActiveMutation,
  useUpdateProductSortOrdersMutation
} from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import { useToast } from 'hooks/useToast'
import { CategoryInfo } from 'models/Merchant'
import { ProductInfo } from 'models/Product'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { moveItem, sortOrderSort } from 'util/sorting'

import { ImportMenuLink } from '../components/ImportMenu/ImportMenuLink'
import { MenuSyncContainer } from '../components/MenuSyncContainer/MenuSyncContainer'
import { CategoryCard } from './CategoryCard'

interface ProductManagementProps {
  merchantId: ID
  organizationId: ID
}

export function ProductManagement(props: ProductManagementProps) {
  const { merchantId, organizationId } = props
  const toast = useToast()
  const navigate = useNavigate()

  const styles = useStyles()
  const [searchParams] = useSearchParams()
  const [refetchedFromParams, setRefetchedFromParams] = useState(false)
  const [hideInactive, _setHideInactive] = useState(false)
  const refetchQueries = [{ query: ProductListDocument, variables: { merchantId } }]
  const [deleteCategoryId, setShowDeleteCategoryIdPrompt] = useState<string | undefined>()
  const [categories, setCategories] = useState<CategoryInfo[] | undefined>()
  const [productsByCategoryId, setProductsByCategoryId] = useState<Record<string, ProductInfo[]> | undefined>()
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([])
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)

  const { data: categoryData } = useCategorySelectQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })

  const { data, loading: loadingList, refetch } = useProductListQuery({
    variables: {
      merchantId
    },
    fetchPolicy: 'cache-first'
  })

  const { data: menuSyncConfigData } = useGetMenuSyncConfigQuery({
    variables: {
      merchantId: merchantId
    }
  })

  const [updateCategorySortOrders] = useUpdateCategorySortOrdersMutation()
  const [updateProductSortOrders] = useUpdateProductSortOrdersMutation()
  const [updateProductCategories] = useUpdateProductCategoriesMutation()
  const [archive] = useArchiveProductsMutation()
  const [updateProductsActive] = useUpdateProductsActiveMutation()

  useEffect(() => {
    if (data && categoryData) {
      const _categories: Array<CategoryInfo> = []
      const _productsByCategoryId: Record<string, ProductInfo[]> = {}
      const categorySeen: Record<string, boolean> = {}

      for (let product of data?.merchant?.products || []) {
        if (!product.active && hideInactive) {
          continue
        }
        if (product.category) {
          const categoryId = product.category.id
          if (!categorySeen[categoryId]) {
            categorySeen[categoryId] = true
            _categories.push(product.category)
          }
          const productsForThisCategory = _productsByCategoryId[categoryId] || []
          productsForThisCategory.push(product)
          _productsByCategoryId[categoryId] = productsForThisCategory?.sort(sortOrderSort)
        }
      }

      for (let category of categoryData.categories) {
        if (!_productsByCategoryId[category.id]) {
          _productsByCategoryId[category.id] = []
        }
      }
      setProductsByCategoryId(_productsByCategoryId)
      setCategories([...categoryData.categories].sort(sortOrderSort))
    }
  }, [data, categoryData, hideInactive])

  const [executeDelete] = useArchiveProductMutation()

  useEffect(() => {
    if (!refetchedFromParams && searchParams.get('refetchProducts')) {
      refetch()
      setRefetchedFromParams(true)
    }
  }, [refetch, searchParams, refetchedFromParams])

  async function handleDelete() {
    try {
      await executeDelete({
        variables: { id: deleteCategoryId!! },
        refetchQueries
      })
      toast.success(`Successfully deleted product`)
      setShowDeleteCategoryIdPrompt(undefined)
    } catch (e) {
      toast.error(e.toString())
    }
  }

  async function handleCreateCategory() {
    navigate(`/organizations/${organizationId}/merchants/${merchantId}/menu/categories/new`)
  }

  async function updateCategorySort(result: DropResult) {
    if (!result.destination || !categories) {
      return
    }
    var updatedCategorySort = moveItem(categories, result.source.index, result.destination.index)
    setCategories(updatedCategorySort)
    await updateCategorySortOrders({
      variables: {
        inputs: updatedCategorySort.map((category, index) => {
          return { id: category.id, sortOrder: index }
        })
      }
    })
  }

  async function updateProductSortOrder(productSortOrders: UpdateSortOrderInput[]) {
    await updateProductSortOrders({
      variables: {
        inputs: productSortOrders
      }
    })
  }
  async function updateProductCategory(productId: string, categoryId: string) {
    try {
      await updateProductCategories({
        variables: {
          inputs: [{ productId, categoryId }]
        }
      })
      return true
    } catch (e: any) {
      toast.error(e.toString())
    }
    return false
  }

  async function handleDragEnd(result: DropResult) {
    if (!result.destination || !productsByCategoryId || !categories) {
      return
    }

    if (result.type === 'categorySort') {
      updateCategorySort(result)
      return
    }

    let items = productsByCategoryId!![result.destination.droppableId]

    const updatedProductsByCategoryId = { ...productsByCategoryId }
    const originalProductsByCategoryId = { ...productsByCategoryId }
    if (items.find((p) => p.id === result.draggableId)) {
      let updatedProductOrder = moveItem(items, result.source.index, result.destination.index)
      updatedProductsByCategoryId[result.destination.droppableId] = updatedProductOrder
      setProductsByCategoryId(updatedProductsByCategoryId)
      updateProductSortOrder(
        updatedProductOrder.map((product, index) => {
          return { id: product.id, sortOrder: index }
        })
      )
    } else {
      let sourceCategoryList = [...updatedProductsByCategoryId[result.source.droppableId]]
      const [removed] = sourceCategoryList.splice(result.source.index, 1)

      updatedProductsByCategoryId[result.source.droppableId] = sourceCategoryList

      let destinationCategoryList = [...updatedProductsByCategoryId[result.destination.droppableId]]
      destinationCategoryList.splice(result.destination.index, 0, removed)

      updatedProductsByCategoryId[result.destination.droppableId] = destinationCategoryList

      setProductsByCategoryId(updatedProductsByCategoryId)
      if (!(await updateProductCategory(removed.id, result.destination.droppableId))) {
        setProductsByCategoryId(originalProductsByCategoryId)

        return
      }

      updateProductSortOrder(
        sourceCategoryList
          .map((product, index) => {
            return { id: product.id, sortOrder: index }
          })
          .concat(
            destinationCategoryList.map((product, index) => {
              return { id: product.id, sortOrder: index }
            })
          )
      )
    }
  }

  function handleAddProduct() {
    navigate(`/organizations/${organizationId}/merchants/${merchantId}/menu/products/new`)
  }

  function handleProductSelectedChanged(productId: string, checked: boolean) {
    let existingIndex = selectedProductIds.indexOf(productId)

    if (checked && existingIndex >= 0) {
      return
    }

    if (!checked && existingIndex < 0) {
      return
    }

    let updatedSelectedProductIds = [...selectedProductIds]
    if (existingIndex >= 0 && !checked) {
      updatedSelectedProductIds.splice(existingIndex, 1)
    } else if (checked && existingIndex < 0) {
      updatedSelectedProductIds.push(productId)
    }

    setSelectedProductIds(updatedSelectedProductIds)
  }

  async function handleProductsActiveChange(active: boolean) {
    try {
      await updateProductsActive({
        variables: { productIds: selectedProductIds, active: active },
        refetchQueries
      })
      toast.success(`Updated Products`)
    } catch (e) {
      toast.error(e.toString())
    }
  }

  async function deleteSelected() {
    setShowDeleteConfirmationModal(false)

    await archive({
      variables: {
        ids: selectedProductIds
      },
      refetchQueries
    })

    setSelectedProductIds([])
  }

  function setHideInactive(value: boolean) {
    if (value) {
      let updatedSelectedProductIds = []
      for (let selectedProductId of selectedProductIds) {
        const product = data?.merchant?.products?.find((p) => p.id === selectedProductId)!!
        if (product.active) {
          updatedSelectedProductIds.push(product.id)
        }
      }
      setSelectedProductIds(updatedSelectedProductIds)
    }
    _setHideInactive(value)
  }

  if (categories?.length === 0 && !loadingList) {
    return <Typography color='textSecondary'>Create a Category!</Typography>
  }

  return (
    <Section
      title='Products'
      variant='grid'
      subTitle={
        <div>
          {menuSyncConfigData?.getMenuSyncConfig?.active && (
            <Alert
              severity='info'
              style={{ marginBottom: 10, marginRight: 10 }}
              icon={
                menuSyncConfigData?.getMenuSyncConfig?.source === ExternalDataSource.Square ? (
                  <img style={{ width: 50, paddingRight: 10, height: 42 }} src={SquareLogo} alt={'Square Sync'} />
                ) : (
                  <></>
                )
              }
            >
              {`The products on this menu are being synced${
                menuSyncConfigData?.getMenuSyncConfig?.source
                  ? ` from ${menuSyncConfigData?.getMenuSyncConfig?.source}`
                  : ''
              }. To make changes to pricing, modifiers or options please make changes in
                  the source system. Changes to names, pictures, and categories can be made here and will not be overrided by the syncing process.`}
            </Alert>
          )}
          {!menuSyncConfigData?.getMenuSyncConfig?.active && (
            <div>Use this page to create and manage products and categories on your menu</div>
          )}
        </div>
      }
      addon={
        <div style={flexRow}>
          <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId), roles.merchantAdmin(merchantId)]}>
            <MenuSyncContainer
              merchantId={merchantId}
              canSync={!!menuSyncConfigData?.getMenuSyncConfig}
              isSyncActive={menuSyncConfigData?.getMenuSyncConfig?.active}
              sourceSystem={menuSyncConfigData?.getMenuSyncConfig?.source}
            />
            <ImportMenuLink merchantId={merchantId} organizationId={organizationId} />
            {!menuSyncConfigData?.getMenuSyncConfig?.active && (
              <Button style={buttonStyle} endIcon={<AddIcon />} label='Create Product' onClick={handleAddProduct} />
            )}
            <Button style={buttonStyle} endIcon={<AddIcon />} label='Create Category' onClick={handleCreateCategory} />
          </HasAnyRole>
        </div>
      }
    >
      <div style={{ ...flexRow, justifyContent: 'space-between' }}>
        <div style={flexRow}>
          <Checkbox
            checked={selectedProductIds.length === data?.merchant?.products?.length}
            indeterminate={
              selectedProductIds.length !== data?.merchant?.products?.length && selectedProductIds.length !== 0
            }
            indeterminateIcon={<IndeterminateCheckBoxIcon className={styles.checkbox} />}
            onChange={() =>
              selectedProductIds.length !== 0
                ? setSelectedProductIds([])
                : setSelectedProductIds(data?.merchant?.products?.map((p) => p.id) ?? [])
            }
          />
          {selectedProductIds.length > 0 && (
            <div style={flexRow}>
              <Button
                style={buttonStyle}
                label='Deactivate Selected'
                onClick={() => handleProductsActiveChange(false)}
              />
              <Button style={buttonStyle} label='Activate Selected' onClick={() => handleProductsActiveChange(true)} />
              <Button
                style={buttonStyle}
                label='Delete Selected'
                onClick={() => {
                  setShowDeleteConfirmationModal(true)
                }}
              />
            </div>
          )}
        </div>
        <div>
          {hideInactive ? 'Show Inactive' : 'Hide Inactive'}
          <Switch checked={hideInactive} onChange={(e, c) => setHideInactive(c)} />
        </div>
      </div>
      {!!productsByCategoryId && !!categories && (
        <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
          <Droppable droppableId={'categories'} type='categorySort'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={clsx(styles.dropContainer, snapshot.isDraggingOver && styles.dropContainerDragging)}
              >
                {categories.map((category, index) => (
                  <CategoryCard
                    key={category.id}
                    organizationId={organizationId}
                    merchantId={merchantId}
                    category={category}
                    index={index}
                    products={productsByCategoryId[category.id]}
                    productSelectedChanged={handleProductSelectedChanged}
                    selectedProductIds={selectedProductIds}
                  />
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <ConfirmationDialog
        title='Are you sure?'
        open={!!deleteCategoryId}
        onClose={() => setShowDeleteCategoryIdPrompt(undefined)}
        onConfirm={handleDelete}
      >
        <DialogContentText>Are you sure you want to delete this Category?</DialogContentText>
      </ConfirmationDialog>
      <ConfirmationDialog
        title='Delete Products?'
        open={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        onConfirm={deleteSelected}
      >
        <DialogContentText>Are you sure you want to delete the selected products?</DialogContentText>
      </ConfirmationDialog>
    </Section>
  )
}

const flexRow: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}

const buttonStyle: React.CSSProperties = {
  marginLeft: 5
}

const useStyles = makeStyles((theme) => ({
  dropContainer: {
    transition: '0.1s all ease-in-out'
  },
  dropContainerDragging: {
    backgroundColor: lighten(theme.palette.primary.light, 0.8)
  },
  checkbox: {
    color: theme.palette.primary.main
  }
}))
