import { TextField } from '@material-ui/core'
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'
import { CatalogProductFragment, useCatalogProductSelectQuery } from 'graphql/types.generated'

interface CatalogProductSelectProps
  extends Omit<
    AutocompleteProps<CatalogProductFragment, undefined, undefined, undefined>,
    'options' | 'loading' | 'getOptionLabel' | 'renderInput'
  > {
  label: string
  name: string
  catalogId: ID
}

export function CatalogProductSelect(props: CatalogProductSelectProps) {
  const { catalogId, label, ...rest } = props
  const { data, loading } = useCatalogProductSelectQuery({
    variables: {
      catalogId
    }
  })

  const products =
    (data?.catalog?.categories
      ?.filter((category) => (category.activeProducts?.length ?? 0) > 0)
      .flatMap((category) => category.activeProducts) as CatalogProductFragment[]) ?? []

  return (
    <Autocomplete
      options={products}
      loading={loading}
      noOptionsText='No products found'
      {...rest}
      getOptionLabel={(product) => product?.name ?? ''}
      renderInput={(params) => <TextField {...params} label={label} variant='outlined' />}
    />
  )
}
