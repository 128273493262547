import { Button } from '@bloom-coffee/steamed-milk'
import { Modal } from 'components/layout/Modal/Modal'
import { FulfillmentLocationInfo } from 'models/FulfillmentLocation'
import React, { useState } from 'react'

import { ServingLocationQRCodeSign } from './ServingLocationQRCodeSign'

interface QRCodeSignLinkProps {
  servingLocation: FulfillmentLocationInfo
  merchantId: string
}

export const QRCodeSignLink = (props: QRCodeSignLinkProps) => {
  const { servingLocation, merchantId } = props
  const [showModal, setShowModal] = useState(false)

  return (
    <div style={{ margin: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Button label='QR Code Table Sign' onClick={() => setShowModal(true)} />

      <Modal open={!!showModal} onClose={() => setShowModal(false)} title='QR Code Table Sign'>
        <ServingLocationQRCodeSign servingLocation={servingLocation} merchantId={merchantId!!} />
      </Modal>
    </div>
  )
}
