import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, TextField } from '@material-ui/core'
import { PhoneField } from 'components/form/PhoneField'
import { Maybe } from 'graphql/jsutils/Maybe'
import { PlatformPricingType, useGetPlatformPricingQuery } from 'graphql/types.generated'
import { useCafeLeadDetailsStorage } from 'hooks/useCafeLeadDetailsStorage/useCafeLeadDetailsStorage'
import { PlatformPricingInfo } from 'models/Subscriptions'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ColorHex } from 'theme/ThemeConstants'
import { PlatformPlan } from 'views/merchants/components/PlatformPricing/PlatformPlan'

import { SignUpFormModel, signUpSchema } from './signUpFormValidator'

interface SignUpFormProps {
  isFreshOnboarding: boolean
}

export function SignUpForm(props: SignUpFormProps) {
  const { isFreshOnboarding } = props
  const { loginWithRedirect } = useAuth0()
  const [pricingPlan, setPricingPlan] = useState<Maybe<PlatformPricingInfo>>()
  const [termPricing, setTermPricing] = useState<boolean>(false)
  const [collectingCafeDetails, setCollectingCafeDetails] = useState<boolean>(isFreshOnboarding)

  const { data, loading } = useGetPlatformPricingQuery()
  const { saveCafeLeadDetails } = useCafeLeadDetailsStorage()

  const instantPlan = data?.getPlatformPricing.find((p) => p.platformPricingType === PlatformPricingType.Instant)
  const standardPlan = data?.getPlatformPricing.find((p) => p.platformPricingType === PlatformPricingType.Standard)
  const signaturePlan = data?.getPlatformPricing.find((p) => p.platformPricingType === PlatformPricingType.Signature)

  useEffect(() => {
    if (!!data?.getPlatformPricing && !pricingPlan) {
      setPricingPlan(data?.getPlatformPricing.find((p) => p.platformPricingType === PlatformPricingType.Standard))
    }
  }, [data, pricingPlan])

  const formProps = useForm<SignUpFormModel>({
    resolver: yupResolver(signUpSchema),
    shouldUnregister: false
  })

  const { register, handleSubmit, formState } = formProps
  function onSubmit(model: SignUpFormModel) {
    saveCafeLeadDetails({
      platformPricingId: pricingPlan?.id,
      termPricing: termPricing,
      organizationName: model.organizationName,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.contactEmail,
      phoneNumber: model.phoneNumber
    })

    if (isFreshOnboarding) {
      const freshOnboardingPath = '/fresh-onboarding'
      loginWithRedirect({
        appState: {
          returnTo: freshOnboardingPath
        },
        callbackUrlPath: freshOnboardingPath,
        signUp: true,
        email: model.contactEmail
      })
    } else {
      loginWithRedirect({
        signUp: true,
        email: model.contactEmail
      })
    }
  }

  return (
    <div>
      {loading && <CircularProgress size={16} />}
      {!loading && !!data && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {!collectingCafeDetails && (
            <div>
              <div style={titleStyle}>
                <h2>Choose your Plan </h2>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                <PlatformPlan
                  platformPricing={instantPlan!!}
                  select={(termPricing: boolean) => {
                    setTermPricing(termPricing)
                    setPricingPlan(instantPlan)
                  }}
                  selected={pricingPlan === instantPlan}
                  termPricing={termPricing}
                />
                <PlatformPlan
                  platformPricing={standardPlan!!}
                  select={(termPricing: boolean) => {
                    setTermPricing(termPricing)
                    setPricingPlan(standardPlan)
                  }}
                  selected={pricingPlan === standardPlan}
                  termPricing={termPricing}
                />
                <PlatformPlan
                  platformPricing={signaturePlan!!}
                  select={(termPricing: boolean) => {
                    setTermPricing(termPricing)
                    setPricingPlan(signaturePlan)
                  }}
                  selected={pricingPlan === signaturePlan}
                  termPricing={termPricing}
                />
              </div>
              <Button
                style={buttonStyle}
                onClick={() => setCollectingCafeDetails(true)}
                label='Get Started'
                endIcon={loading && <CircularProgress size={16} />}
              />
            </div>
          )}
          {collectingCafeDetails && (
            <div>
              <div style={titleStyle}>
                <h2>Tell us about your cafe</h2>
              </div>
              <FormProvider {...formProps}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: 900,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      padding: 10,
                      background: ColorHex.RDY_BEIGE,
                      border: '1px solid',
                      borderColor: ColorHex.RDY_FOREST,
                      borderRadius: 5
                    }}
                  >
                    <div style={rowStyle}>
                      <TextField
                        fullWidth
                        name='organizationName'
                        label='Company/Cafe Name'
                        inputRef={register()}
                        style={fieldStyle}
                      />
                    </div>
                    <div style={rowStyle}>
                      <TextField
                        fullWidth
                        name='firstName'
                        label='First Name'
                        inputRef={register()}
                        style={fieldStyle}
                      />
                      <TextField fullWidth name='lastName' label='Last Name' inputRef={register()} style={fieldStyle} />
                    </div>
                    <div style={rowStyle}>
                      <TextField fullWidth name='contactEmail' label='Email' inputRef={register()} style={fieldStyle} />

                      <PhoneField
                        name='phoneNumber'
                        label='Phone Number'
                        variant='standard'
                        style={fieldStyle}
                        defaultValue={null}
                      />
                    </div>
                    <Button
                      theme='action'
                      type='submit'
                      size='medium'
                      onClick={handleSubmit(onSubmit)}
                      disabled={formState.isSubmitting}
                      endIcon={formState.isSubmitting && <CircularProgress color='inherit' size={20} />}
                      label={'Set up Cafe'}
                      style={buttonStyle}
                    />
                  </div>
                </form>
              </FormProvider>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const buttonStyle = {
  marginTop: 10,
  marginLeft: 'auto',
  marginRight: 'auto'
}

const titleStyle: React.CSSProperties = {
  maxWidth: 900,
  textAlign: 'center',
  fontFamily: 'Raw-Bold',
  fontSize: 18,
  marginLeft: 'auto',
  marginRight: 'auto'
}

const rowStyle: React.CSSProperties = { display: 'flex', flexDirection: 'row', paddingTop: 10 }

const fieldStyle: React.CSSProperties = { padding: 10 }
