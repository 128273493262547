import { Section } from '@bloom-coffee/steamed-milk'
import { CircularProgress } from '@material-ui/core'
import { DataGrid, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid'
import { MerchantPayoutDetailsFragment, PayoutType, useOrganizationPayoutsQuery } from 'graphql/types.generated'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { readableDateFromInstant } from 'util/date'
import { formatUsCentsAsUsDollar } from 'util/formatting'

interface OrganizationPayoutsProps {
  organizationId: ID
  payoutDetailBaseUrl: string
}

interface OrgnaizationPayoutPeriod {
  id: string
  payoutType: PayoutType
  payoutDate: number
  periodEndDate: number
  totalPaidOut: number
  merchantPayouts: MerchantPayoutDetailsFragment[]
  includesManualPayouts: boolean
}

export function OrganizationPayouts(props: OrganizationPayoutsProps) {
  const { organizationId, payoutDetailBaseUrl } = props

  const { data, loading } = useOrganizationPayoutsQuery({
    variables: {
      id: organizationId
    }
  })

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'periodEndDate',
      sort: 'desc'
    }
  ])

  const payouts = groupMerchantPayoutsByOrganization(data?.organizationPayouts || [])

  function groupMerchantPayoutsByOrganization(merchantPayouts: MerchantPayoutDetailsFragment[]) {
    var groupedPayouts = merchantPayouts.reduce(function (obj: any, payout: MerchantPayoutDetailsFragment) {
      const groupHash = `${payout.payoutType}${roundToPeriodEndDate(new Date(payout.periodEndDate))}`
      obj[groupHash] = obj[groupHash] || []
      obj[groupHash].push(payout)
      return obj
    }, {})

    return Object.keys(groupedPayouts).map(function (key) {
      const merchantPayouts = groupedPayouts[key] as MerchantPayoutDetailsFragment[]

      const totalPaidOut = merchantPayouts.reduce((total, merchantPayout) => (total += merchantPayout.payoutAmount), 0)

      const earliestStartDatePayout = merchantPayouts.reduce((prev, curr) =>
        prev.periodStartDate < curr.periodStartDate ? prev : curr
      )

      return {
        id: `${earliestStartDatePayout.payoutType}${earliestStartDatePayout.periodEndDate}`,
        payoutType: earliestStartDatePayout.payoutType,
        periodEndDate: roundToPeriodEndDate(new Date(earliestStartDatePayout.periodEndDate), -1),
        payoutDate: earliestStartDatePayout.createdOn,
        merchantPayouts: merchantPayouts,
        totalPaidOut: totalPaidOut,
        includesManualPayouts: merchantPayouts.filter((p) => p.manualPayout).length > 0
      } as OrgnaizationPayoutPeriod
    })
  }

  function roundToPeriodEndDate(payoutPeriod: Date, dayOffSet: number = 0) {
    const firstOfCurrentMonth = Date.UTC(payoutPeriod.getUTCFullYear(), payoutPeriod.getUTCMonth(), 1, 0, 0, 0)
    const fifteenthOfCurrentMonth = Date.UTC(payoutPeriod.getUTCFullYear(), payoutPeriod.getUTCMonth(), 16, 0, 0, 0)
    const firstOfNextMonth = Date.UTC(
      payoutPeriod.getUTCFullYear(),
      payoutPeriod.getUTCMonth() === 11 ? 0 : payoutPeriod.getUTCMonth() + 1,
      1,
      0,
      0,
      0
    )

    const possiblePayoutDates = [firstOfCurrentMonth, fifteenthOfCurrentMonth, firstOfNextMonth]

    possiblePayoutDates.sort(function (a, b) {
      const distanceToA = Math.abs(payoutPeriod.getTime() - a)
      const distanceToB = Math.abs(payoutPeriod.getTime() - b)
      return distanceToA - distanceToB
    })

    const adjustedPayoutPeriod = possiblePayoutDates[0]

    var utcDate = new Date(adjustedPayoutPeriod)
    return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate() + dayOffSet).getTime()
  }
  function getPayoutIdQueryString(payouts: MerchantPayoutDetailsFragment[]): string {
    return payouts.map((payout) => payout.id.toString()).join(',')
  }

  if (loading) return <CircularProgress />

  return (
    <Section title='Payouts' variant='grid'>
      <DataGrid
        loading={loading}
        autoHeight
        disableSelectionOnClick
        rows={payouts}
        sortModel={sortModel}
        onSortModelChange={(model) => {
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model)
          }
        }}
        columns={[
          {
            field: 'payoutDate',
            headerName: 'Invoice Date',
            sortable: true,
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => {
              if (params.row.includesManualPayouts) return '-'
              return readableDateFromInstant(params.value as number)
            }
          },
          {
            field: 'payoutType',
            headerName: 'Payout Type',
            sortable: true,
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => {
              switch (params.value) {
                case PayoutType.MerchantReferrals:
                  return 'Referral Tips'
                case PayoutType.Tokens:
                  return 'Token Reimbursement'
                case PayoutType.Orders:
                  return 'Orders'
                case PayoutType.Discounts:
                  return 'Discounts'
              }
            }
          },
          {
            field: 'periodEndDate',
            headerName: 'Period End Date',
            sortable: true,
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => readableDateFromInstant(params.value as number)
          },
          {
            field: 'totalPaidOut',
            headerName: 'Total Paid Out',
            sortable: true,
            width: 175,
            valueFormatter: (params: GridValueFormatterParams) => formatUsCentsAsUsDollar(params.value as number)
          },
          {
            field: '',
            headerName: '',
            sortable: false,
            width: 150,
            renderCell(params) {
              return (
                <Link to={`${payoutDetailBaseUrl}${getPayoutIdQueryString(params.row.merchantPayouts)}`} replace>
                  View Details
                </Link>
              )
            }
          }
        ]}
      />
    </Section>
  )
}
