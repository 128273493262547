import { isValidEmail, isValidUsPhoneWithoutCountryCode } from 'validation/ValidationUtils'
import * as yup from 'yup'

export const userProfileSchema = yup.object({
  contactEmail: yup
    .string()
    .label('Email')
    .test({
      message: 'Invalid email',
      test: (inputVal) => {
        if (inputVal) {
          return isValidEmail(inputVal)
        }
        return true
      }
    })
    .required(),
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  phoneNumber: yup
    .string()
    .label('Phone number')
    .nullable()
    .test({
      message: 'Invalid phone number',
      test: (inputVal) => {
        if (inputVal) {
          return isValidUsPhoneWithoutCountryCode(inputVal)
        }
        return true
      }
    })
    .required()
})

export type UserProfileFormModel = yup.InferType<typeof userProfileSchema>
