import { Button } from '@bloom-coffee/steamed-milk'
import { Modal } from 'components/layout/Modal/Modal'
import React, { useState } from 'react'
import { ProductImageFormModel } from 'views/products/CreateProduct/productValidator'

import { ImageUpload } from './ImageUpload'
import { LibraryImageSelection } from './LibraryImageSelection'

interface ImageSelectionLinkProps {
  merchantId: string
  productImages: ProductImageFormModel[]
  updateProductImages: (images: ProductImageFormModel[]) => void
}

export const ImageSelectionLink = (props: ImageSelectionLinkProps) => {
  const { merchantId, updateProductImages, productImages } = props
  const [isOpen, setIsOpen] = useState(false)
  const [uploadImage, setUploadImage] = useState(false)
  const [libraryImage, setLibraryImage] = useState(true)

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Button label='Choose an Image' onClick={() => setIsOpen(true)} />

      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={uploadImage ? 'Upload an Image' : 'Choose from our Image Library'}
      >
        <Button
          label={uploadImage ? 'Choose from our Image Library' : 'Upload an Image'}
          onClick={() => {
            setUploadImage(!uploadImage)
            setLibraryImage(!libraryImage)
          }}
        />
        {uploadImage && <ImageUpload productImages={productImages} updateProductImages={updateProductImages} />}
        {libraryImage && (
          <LibraryImageSelection
            merchantId={merchantId}
            productImages={productImages}
            updateProductImages={updateProductImages}
          />
        )}
      </Modal>
    </div>
  )
}
