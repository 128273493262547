import { Color } from '@bloom-coffee/steamed-milk'
import React from 'react'
import { useParams } from 'react-router-dom'

import { SquareSyncContainer } from './SquareSyncContainer'

export const ImportProductsFromSquareContainer = () => {
  const { organizationId, merchantId } = useParams()
  return (
    <div style={{ backgroundColor: Color.WHITE }}>
      <SquareSyncContainer organizationId={organizationId!} merchantId={merchantId!} variant='SyncProducts' />
    </div>
  )
}
