import { MessageType, PushAppAction } from 'graphql/types.generated'
import * as yup from 'yup'

export const userPushSchema = yup.object({
  title: yup.string().label('Title').required(),
  body: yup.string().label('Body').required(),
  analyticsTag: yup.string().label('Analytics Tag').required(),
  userIds: yup.string().label('User IDs (e.g. 2, 19, 7)').optional().default(undefined),
  messageType: yup.mixed<MessageType>().oneOf(Object.values(MessageType), 'Required').label('Message Type').required(),
  appAction: yup.mixed<PushAppAction>().label('App Action').optional()
})

export type UserPushFormModel = yup.InferType<typeof userPushSchema>
