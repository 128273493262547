import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../../../components/form/DefaultFormFooter'
import { SelectField } from '../../../../../components/form/SelectField'
import { TextField } from '../../../../../components/form/TextField'
import { TokenTypeName, useGrantTokensMutation } from '../../../../../graphql/types.generated'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'
import { useToast } from '../../../../../hooks/useToast'
import { GrantTokensModel, grantTokensSchema } from './grantTokensValidator'

interface GrantRewardFormProps {
  onCancel: () => void
}

export const GrantTokensForm = (props: GrantRewardFormProps) => {
  const { onCancel } = props
  const toast = useToast()
  const errorHandler = useErrorHandler()
  const [execute] = useGrantTokensMutation()

  const formProps = useForm<GrantTokensModel>({
    resolver: yupResolver(grantTokensSchema)
  })

  const { register, handleSubmit, formState } = formProps

  async function grantTokens(input: GrantTokensModel) {
    try {
      const res = await execute({
        variables: { input }
      })
      toast.success(`${res.data?.grantTokens.length} token(s) granted to user ID ${res.data?.grantTokens[0].userId}`)
    } catch (e) {
      errorHandler(e)
    }
  }

  function handleCancel() {
    if (!formState.isSubmitting) {
      onCancel()
    }
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(grantTokens)}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField fullWidth name='userId' label='User ID' inputRef={register} />
          </Grid>

          <Grid item xs={4}>
            <SelectField fullWidth label='Token Type' name='tokenType'>
              <MenuItem value=''>
                <em>Select a Token Type</em>
              </MenuItem>

              {Object.values(TokenTypeName).map((tt, index) => (
                <MenuItem key={`${tt}.${index}`} value={tt}>
                  {tt}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={4}>
            <TextField fullWidth name='numberOfTokens' label='Number of Tokens' inputRef={register} />
          </Grid>

          <Grid item>
            <DefaultFormFooter
              onSubmit={handleSubmit(grantTokens)}
              onCancel={handleCancel}
              saveLabel='Grant Tokens'
              submitting={formState.isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
