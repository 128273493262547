import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { AuthProvider } from 'components/auth/AuthProvider'
import { GraphQLProvider } from 'components/graphql/GraphQLProvider'
import { config } from 'config'
import { PermissionProvider } from 'hooks/usePermissions/usePermissions'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { theme } from 'theme/theme'
import { ContentRoot } from 'views/ContentRoot'

function App() {
  const stripe = loadStripe(config.stripePublishableKey)
  return (
    <BrowserRouter>
      <AuthProvider>
        <GraphQLProvider>
          <PermissionProvider>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <SnackbarProvider>
                  <Elements stripe={stripe}>
                    <CssBaseline />
                    <ContentRoot />
                  </Elements>
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </PermissionProvider>
        </GraphQLProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
