import { Section } from '@bloom-coffee/steamed-milk'
import { TextField } from '@material-ui/core'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { HoursDisplay } from 'components/hours/HoursDisplay'
import { DayHoursFormModel, DayHoursModel } from 'components/hours/validator'
import { useCreateCatalogMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { convertDayHoursModel } from 'util/merchantHours'

import { convertToSaveModel } from './CatalogUtil'

interface CreateCatalogProps {
  merchantId: ID
}

export function CreateCatalog(props: CreateCatalogProps) {
  const { merchantId } = props
  const toast = useToast()
  const navigate = useNavigate()
  const [execute, { loading }] = useCreateCatalogMutation()

  const [hours, setHours] = useState<DayHoursModel[]>([])

  const formProps = useForm<CreateCatalogModel>()
  const { register, handleSubmit } = formProps

  async function updateHours(value: DayHoursFormModel) {
    let updatedAvailabilityWindows = hours.filter((aw) => !value.weekDays.includes(aw.weekDay))
    updatedAvailabilityWindows.push(...convertDayHoursModel(value))

    setHours(updatedAvailabilityWindows)
  }

  async function save(values: CreateCatalogModel) {
    try {
      let catalogAvailWindows = convertToSaveModel(hours)
      await execute({
        variables: {
          input: {
            merchantId: merchantId,
            availabilityWindows: catalogAvailWindows ?? [],
            description: values.description,
            name: values.name,
            productIds: []
          }
        }
      })
      navigate(-1)
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  return (
    <Section title={'Create Menu'}>
      <form onSubmit={handleSubmit(save)}>
        <FormProvider {...formProps}>
          <TextField
            style={{ margin: 10, width: 300 }}
            variant='outlined'
            name='name'
            label='Name'
            inputRef={register({ required: 'Name is required' })}
          />
          <TextField
            style={{ margin: 10, width: 300 }}
            variant='outlined'
            name='description'
            label='Description'
            inputRef={register({ required: 'Description is required' })}
          />
          <HoursDisplay values={hours} saveHours={updateHours} />
          <DefaultFormFooter
            onCancel={() => navigate(-1)}
            onSubmit={handleSubmit(save)}
            submitting={loading}
            saveLabel='Create Menu'
          />
        </FormProvider>
      </form>
    </Section>
  )
}

interface CreateCatalogModel {
  name: string
  description: string
}
