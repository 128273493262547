import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { PriceField } from 'components/form/PriceField'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'

import { DefaultFormFooter } from '../../../../components/form/DefaultFormFooter'
import { TextField } from '../../../../components/form/TextField'
import { SubscriptionFeeFormModel, subscriptionFeeSchema } from './subscriptionFeeValidator'

interface SubscriptionFeeFormProps {
  onCancel: () => void
  onSubmit: (values: SubscriptionFeeFormModel) => void
  disableSubmit?: boolean
  initialValues?: DefaultValues<SubscriptionFeeFormModel> | undefined
}

export const SubscriptionFeeForm = (props: SubscriptionFeeFormProps) => {
  const { onCancel, onSubmit, disableSubmit, initialValues } = props

  const formProps = useForm<SubscriptionFeeFormModel>({
    resolver: yupResolver(subscriptionFeeSchema),
    shouldUnregister: false
  })

  const { register, handleSubmit, formState } = formProps
  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={4}>
            <TextField fullWidth label='Name' name='name' inputRef={register()} />
          </Grid>

          <Grid item xs={4}>
            <PriceField fullWidth name='costUsCents' label='Price' defaultValue={initialValues?.costUsCents} />
          </Grid>

          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
