import { DayHoursModel } from 'components/hours/validator'
import { Maybe } from 'graphql/jsutils/Maybe'
import { CatalogAvailabilityWindowInput, DayOfWeek } from 'graphql/types.generated'
import { groupBy, mapValues } from 'lodash'
import { CatalogAvailabilityWindowInfo } from 'models/Merchant'

const dayOfWeekToDayNumberMap: { [key in DayOfWeek]: number } = {
  [DayOfWeek.Sunday]: 0,
  [DayOfWeek.Monday]: 1,
  [DayOfWeek.Tuesday]: 2,
  [DayOfWeek.Wednesday]: 3,
  [DayOfWeek.Thursday]: 4,
  [DayOfWeek.Friday]: 5,
  [DayOfWeek.Saturday]: 6
}

const dayNumberToDayOfWeekMap: Record<number, DayOfWeek> = {
  0: DayOfWeek.Sunday,
  1: DayOfWeek.Monday,
  2: DayOfWeek.Tuesday,
  3: DayOfWeek.Wednesday,
  4: DayOfWeek.Thursday,
  5: DayOfWeek.Friday,
  6: DayOfWeek.Saturday
}

export function convertToSaveModel(hours: Maybe<DayHoursModel[]> = []): CatalogAvailabilityWindowInput[] {
  let updatedWindows = []
  for (let day of hours ?? []) {
    for (let time of day.timeIntervals) {
      updatedWindows.push({
        dayOfWeek: dayNumberToDayOfWeekMap[day.weekDay]!!,
        start: time.start,
        end: time.end
      } as CatalogAvailabilityWindowInput)
    }
  }

  return updatedWindows
}
export function groupCategoryAvailabilityWindows(hours: Maybe<CatalogAvailabilityWindowInfo[]> = []): DayHoursModel[] {
  const gr = mapValues(
    groupBy(hours, (hour) => hour.dayOfWeek),
    (values) => {
      return values
        .map((value) => {
          return {
            start: value.start,
            end: value.end
          }
        })
        .filter((v) => !!v)
    }
  )

  return Object.keys(gr).map((key) => {
    return {
      weekDay: dayOfWeekToDayNumberMap[key as DayOfWeek],
      timeIntervals: gr[key]
    } as DayHoursModel
  })
}
