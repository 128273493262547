import { Button, Section } from '@bloom-coffee/steamed-milk'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import { DataGrid } from '@mui/x-data-grid'
import { HasAnyRole } from 'components/auth/HasAnyRole'
import { useViewMerchantQuery } from 'graphql/types.generated'
import { roles } from 'hooks/usePermissions/usePermissions'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface MerchantContactsProps {
  merchantId: string
  organizationId: string
}

export function MerchantContacts(props: MerchantContactsProps) {
  const { organizationId, merchantId } = props
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { data, loading } = useViewMerchantQuery({
    variables: {
      id: merchantId!
    },
    fetchPolicy: 'cache-first'
  })
  function handleAdd() {
    navigate(`${pathname}/new`)
  }

  return (
    <Section
      title={'Contacts'}
      subTitle={
        'Add the contact details for your management team. We’ll reach out via SMS or email with pertinent updates.'
      }
      addon={
        <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId), roles.merchantAdmin(merchantId)]}>
          <Button endIcon={<AddIcon />} theme='action' onClick={handleAdd} label='Add Contact' />
        </HasAnyRole>
      }
      variant='grid'
    >
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rows={data?.merchant?.merchantContacts || []}
        loading={loading}
        columns={[
          { field: 'firstName', headerName: 'First Name', sortable: true, width: 200 },
          { field: 'lastName', headerName: 'Last Name', sortable: true, width: 200 },
          { field: 'email', headerName: 'Email', sortable: true, width: 300 },
          { field: 'phoneNumber', headerName: 'Phone Number', sortable: true, width: 200 },
          {
            field: '',
            headerName: '',
            sortable: true,
            width: 150,
            renderCell(params) {
              function handleEdit() {
                navigate(`${pathname}/${params.row.id}/edit`)
              }
              return (
                <HasAnyRole roleMatchers={[roles.organizationAdmin(organizationId), roles.merchantAdmin(merchantId)]}>
                  <Button endIcon={<EditIcon style={{ height: 18 }} />} theme='action' onClick={handleEdit} />
                </HasAnyRole>
              )
            }
          }
        ]}
      />
    </Section>
  )
}
