import { dayTitles } from 'util/date'

import { DaySection } from './DaySection'
import { DayHoursFormModel, DayHoursModel } from './validator'

interface HoursDisplayProps {
  saveHours: (hours: DayHoursFormModel) => void
  values: DayHoursModel[]
  disableEditing?: boolean
}

export function HoursDisplay(props: HoursDisplayProps) {
  const { saveHours, values, disableEditing } = props

  return (
    <div style={weekOuterContainer}>
      <div style={weekContainer}>
        {dayTitles.map((dayTitle, index) => {
          const dayValues = values.filter((day) => day.weekDay === index)[0]
          return (
            <DaySection
              disableEditing={disableEditing}
              key={index}
              saveHours={saveHours}
              value={dayValues || { weekDay: index, timeIntervals: [] }}
            />
          )
        })}
      </div>
    </div>
  )
}
const weekContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginBottom: 10
}
const weekOuterContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left'
}
