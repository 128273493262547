import { Button } from '@bloom-coffee/steamed-milk'
import { CategoryOutputDestination } from 'graphql/types.generated'
import React, { useState } from 'react'

interface OutputStationCategoryButtonProps {
  setting: CategoryOutputDestination
  addUpdatedSetting: (categoryOutputDestinationId: ID, active: boolean) => void
}

export const OutputStationCategoryButton = (props: OutputStationCategoryButtonProps) => {
  const { setting, addUpdatedSetting } = props
  const { id, category, active } = setting

  const [selected, setSelected] = useState(active)

  function handleClick() {
    const updatedSelected = !selected
    setSelected(updatedSelected)
    addUpdatedSetting(id, updatedSelected)
  }
  return <Button label={category.name} style={selected ? selectedStyle : unselectedStyle} onClick={handleClick} />
}

const baseStyle: React.CSSProperties = {
  margin: 5
}
const selectedStyle: React.CSSProperties = {
  ...baseStyle,
  backgroundColor: '#c7f8ff'
}
const unselectedStyle: React.CSSProperties = {
  ...baseStyle,
  color: '#ababab',
  backgroundColor: 'transparent'
}
