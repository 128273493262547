import { MenuedContentContainer } from 'components/layout/MenuedContentContainer/MenuedContentContainer'
import { Route, Routes } from 'react-router-dom'
import { OrganizationList } from 'views/organizations/OrganizationList'

export function OrganizationContainer() {
  return (
    <MenuedContentContainer menuButtonDetails={[]}>
      <Routes>
        <Route path={`*`} element={<OrganizationList />} />
      </Routes>
    </MenuedContentContainer>
  )
}
