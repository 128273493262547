import { Section } from '@bloom-coffee/steamed-milk'
import { User, useViewOrganizationQuery } from 'graphql/types.generated'
import { UserList } from 'views/users/components/UserList/UserList'
import { InviteUserButton } from 'views/users/InviteUser/InviteUserButton'

interface OrganizationUserListProps {
  organizationId: ID
}

export function OrganizationUserList(props: OrganizationUserListProps) {
  const { organizationId } = props

  const { data, loading } = useViewOrganizationQuery({
    variables: {
      id: organizationId!
    }
  })

  return (
    <Section title='Users' addon={<InviteUserButton organizationId={organizationId} />} variant='grid'>
      {!loading && <UserList users={data?.organization?.users as User[]} organizationId={organizationId} />}
    </Section>
  )
}
