import { Button } from '@bloom-coffee/steamed-milk'
import { CircularProgress, Radio } from '@material-ui/core'
import {
  ContractType,
  PlatformPricingType,
  useFetchOrganizationContractSignaturesQuery,
  useGetPlatformPricingQuery,
  useSetMerchantSubscriptionsMutation
} from 'graphql/types.generated'
import { useCafeLeadDetailsStorage } from 'hooks/useCafeLeadDetailsStorage/useCafeLeadDetailsStorage'
import { useToast } from 'hooks/useToast'
import { MerchantInfo } from 'models/Merchant'
import { MerchantSubscriptionInfo, PlatformPricingInfo } from 'models/Subscriptions'
import React, { useEffect, useState } from 'react'
import { formatUsCentsAsUsDollar } from 'util/formatting'
import { PlatformPlan } from 'views/merchants/components/PlatformPricing/PlatformPlan'
import { TermsAgreement } from 'views/onboarding/TermsAgreement'

interface OrganizationPlatformPricingProps {
  merchants: MerchantInfo[]
  organizationId: string
  merchantSubscriptions: MerchantSubscriptionInfo[]
  onComplete: () => void
}

interface MerchantPricingPlanInput {
  merchantId: ID
  pricingPlan: PlatformPricingInfo
  termPricing: boolean
}

export function OrganizationPlatformPricing(props: OrganizationPlatformPricingProps) {
  const { merchants, organizationId, merchantSubscriptions, onComplete } = props

  const toast = useToast()
  const { cafeLeadDetails } = useCafeLeadDetailsStorage()
  const [individualPlans, setIndividualPlans] = useState(
    new Set(
      merchantSubscriptions.map((ms) => {
        return `${ms.termPricing}${ms.platformPricing?.id}`
      })
    ).size > 1
  )
  const [hidingTerms, setHidingTerms] = useState(true)

  const [setMerchantSubscriptionsPlan, { loading: loadingSetMerchantSubPlan }] = useSetMerchantSubscriptionsMutation()
  const { data, loading } = useGetPlatformPricingQuery()

  const { data: signedContractData, loading: loadingContractSignatures } = useFetchOrganizationContractSignaturesQuery({
    variables: {
      id: organizationId
    }
  })
  const instantPlan: PlatformPricingInfo = data?.getPlatformPricing.find(
    (p) => p.platformPricingType === PlatformPricingType.Instant
  )!!
  const standardPlan: PlatformPricingInfo = data?.getPlatformPricing.find(
    (p) => p.platformPricingType === PlatformPricingType.Standard
  )!!
  const signaturePlan: PlatformPricingInfo = data?.getPlatformPricing.find(
    (p) => p.platformPricingType === PlatformPricingType.Signature
  )!!

  const [merchantSelections, setMerchantSelections] = useState<MerchantPricingPlanInput[]>([])

  useEffect(() => {
    const unassignedMerchants = merchants.filter((m) => !merchantSelections.find((ms) => ms.merchantId === m.id))
    if (!unassignedMerchants.length || !standardPlan) {
      return
    }
    const cafeLeadPlan = data?.getPlatformPricing.find((p) => p.id === cafeLeadDetails?.platformPricingId)

    const updatedPlans = unassignedMerchants.map((m) => {
      const existingSub = merchantSubscriptions.find((ms) => ms.merchant!!.id === m.id)

      if (!!existingSub) {
        return {
          merchantId: m.id,
          pricingPlan: existingSub.platformPricing!!,
          termPricing: existingSub.termPricing ?? false
        }
      }

      return {
        merchantId: m.id,
        pricingPlan: cafeLeadPlan ?? standardPlan,
        termPricing: cafeLeadDetails?.termPricing ?? false
      }
    })

    const newMerchantSelections = merchantSelections.concat(updatedPlans)
    setMerchantSelections(newMerchantSelections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchants, merchantSelections, standardPlan, merchantSubscriptions])

  function handlePlanChange(merchantId: string, plan: PlatformPricingInfo) {
    let existingMerchantSelection = merchantSelections.find((ms) => ms.merchantId === merchantId)
    let updatedList = [...merchantSelections]

    if (existingMerchantSelection) {
      updatedList.splice(updatedList.indexOf(existingMerchantSelection), 1)
    }

    setMerchantSelections(
      updatedList.concat([
        {
          merchantId: merchantId,
          pricingPlan: plan,
          termPricing: false
        }
      ])
    )
  }

  function isPlanChecked(merchant: MerchantInfo, plan: PlatformPricingInfo) {
    let merchantSelection = merchantSelections.find((ms) => ms.merchantId === merchant.id)
    if (!merchantSelection) {
      return false
    }

    return merchantSelection.pricingPlan.id === plan.id
  }

  function isTermPrickingChecked(merchant: MerchantInfo) {
    let merchantSelection = merchantSelections.find((ms) => ms.merchantId === merchant.id)
    if (!merchantSelection) {
      return false
    }

    if (!merchantSelection.pricingPlan?.subscriptionProduct) {
      return false
    }
    return merchantSelection.termPricing
  }

  function merchantPlan(merchant: MerchantInfo) {
    return merchantSelections.find((ms) => ms.merchantId === merchant.id)
  }

  function toggleTermPricing(merchant: MerchantInfo) {
    let existingMerchantSelection = merchantSelections.find((ms) => ms.merchantId === merchant.id)
    let updatedList = [...merchantSelections]

    if (!existingMerchantSelection) {
      return
    }
    if (!existingMerchantSelection.pricingPlan.subscriptionProduct) {
      return
    }

    updatedList.splice(updatedList.indexOf(existingMerchantSelection), 1)
    setMerchantSelections(
      updatedList.concat([
        {
          merchantId: merchant.id,
          pricingPlan: existingMerchantSelection.pricingPlan,
          termPricing: !existingMerchantSelection.termPricing
        }
      ])
    )
  }

  async function setMerchantsSubscriptions() {
    if (!merchantSelections.length) {
      return
    }
    if (!!signedContractData?.organization?.contractSignatures.length) {
      savePricingPlans()
    } else {
      setHidingTerms(false)
    }
  }

  async function savePricingPlans() {
    try {
      await setMerchantSubscriptionsPlan({
        variables: {
          inputs: merchantSelections.map((ms) => {
            return {
              subscriptionProductId: null,
              merchantId: ms.merchantId,
              trialEndDate: null,
              platformPricingId: ms.pricingPlan.id,
              termPricing: ms.termPricing
            }
          })
        }
      })
      toast.success('Pricing Plan(s) Updated')
      onComplete()
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  if (loading || loadingSetMerchantSubPlan) {
    return <CircularProgress />
  }

  function updatePlans(plan: PlatformPricingInfo, termPricing: boolean) {
    setMerchantSelections(
      merchants.map((merchant) => {
        return {
          merchantId: merchant.id,
          pricingPlan: plan,
          termPricing: termPricing
        }
      })
    )
  }

  return (
    <>
      {!individualPlans && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
            <PlatformPlan
              platformPricing={instantPlan!!}
              select={(termPricing: boolean) => {
                updatePlans(instantPlan, termPricing)
              }}
              selected={isPlanChecked(merchants[0], instantPlan)}
              termPricing={isTermPrickingChecked(merchants[0])}
            />
            <PlatformPlan
              platformPricing={standardPlan!!}
              select={(termPricing: boolean) => {
                updatePlans(standardPlan, termPricing)
              }}
              selected={isPlanChecked(merchants[0], standardPlan)}
              termPricing={isTermPrickingChecked(merchants[0])}
            />
            <PlatformPlan
              platformPricing={signaturePlan!!}
              select={(termPricing: boolean) => {
                updatePlans(signaturePlan, termPricing)
              }}
              selected={isPlanChecked(merchants[0], signaturePlan)}
              termPricing={isTermPrickingChecked(merchants[0])}
            />
          </div>
          <div style={buttonContainerStyle}>
            <Button
              onClick={setMerchantsSubscriptions}
              label='Get Started'
              endIcon={(loadingSetMerchantSubPlan || loadingContractSignatures) && <CircularProgress size={16} />}
            />
            <Button
              onClick={() => setIndividualPlans(true)}
              label='Setup individual cafe plans'
              theme='cancel'
              endIcon={(loadingSetMerchantSubPlan || loadingContractSignatures) && <CircularProgress size={16} />}
            />
          </div>
        </div>
      )}
      {individualPlans && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
            <PlatformPlan platformPricing={instantPlan!!} />
            <PlatformPlan platformPricing={standardPlan!!} />
            <PlatformPlan platformPricing={signaturePlan!!} />
          </div>
          <div>
            <div style={headerStyle}>Choose Your Plan</div>
            {merchants.map((merchant) => (
              <div key={merchant.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div style={merchantCell}>
                  <div>{merchant.name}</div>
                </div>
                <div style={selectionCell}>
                  <Radio
                    color='primary'
                    value={instantPlan}
                    checked={isPlanChecked(merchant, instantPlan)}
                    onChange={(event, checked) => {
                      handlePlanChange(merchant.id, instantPlan)
                    }}
                  />
                  <div>{instantPlan.name}</div>
                </div>
                <div style={selectionCell}>
                  <Radio
                    color='primary'
                    value={standardPlan}
                    checked={isPlanChecked(merchant, standardPlan)}
                    onChange={(event, checked) => {
                      handlePlanChange(merchant.id, standardPlan)
                    }}
                  />
                  <div>{standardPlan.name}</div>
                </div>
                <div style={selectionCell}>
                  <Radio
                    color='primary'
                    value={signaturePlan}
                    checked={isPlanChecked(merchant, signaturePlan)}
                    onChange={(event, checked) => {
                      handlePlanChange(merchant.id, signaturePlan)
                    }}
                  />
                  <div>{signaturePlan.name}</div>
                </div>
                {!!merchantPlan(merchant) && (
                  <div style={buttonContainer}>
                    <Button
                      onClick={() => {
                        toggleTermPricing(merchant)
                      }}
                      theme={isTermPrickingChecked(merchant) ? 'cancel' : 'action'}
                      label={`${formatUsCentsAsUsDollar(merchantPlan(merchant)?.pricingPlan.instantPriceUsCents!!)}`}
                      size='medium'
                      style={buttonStyle}
                    />
                    {!!merchantPlan(merchant)!!.pricingPlan.subscriptionProduct && (
                      <Button
                        label={`${formatUsCentsAsUsDollar(
                          merchantPlan(merchant)!!.pricingPlan.subscriptionProduct!!.costUsCents!!
                        )} / Month for ${merchantPlan(merchant)!!.pricingPlan.subscriptionTerm} Months`}
                        theme={isTermPrickingChecked(merchant) ? 'action' : 'cancel'}
                        type='submit'
                        size='medium'
                        onClick={() => {
                          toggleTermPricing(merchant)
                        }}
                        style={buttonStyle}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div style={buttonContainerStyle}>
            <Button
              onClick={setMerchantsSubscriptions}
              label='Get Started'
              endIcon={(loadingSetMerchantSubPlan || loadingContractSignatures) && <CircularProgress size={16} />}
            />
          </div>
        </div>
      )}
      <TermsAgreement
        organizationId={organizationId!!}
        contractType={ContractType.StandardContract}
        hidden={hidingTerms}
        onComplete={savePricingPlans}
        onCancel={() => setHidingTerms(true)}
        onAdminSkip={savePricingPlans}
      />
    </>
  )
}

const buttonContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: 500,
  marginBottom: 'auto',
  marginTop: 'auto'
}

const buttonStyle: React.CSSProperties = {
  margin: 5
}

const selectionCell: React.CSSProperties = {
  width: 100,
  textAlign: 'center'
}
const merchantCell: React.CSSProperties = {
  width: 200,
  textAlign: 'right',
  marginBottom: 'auto',
  marginTop: 'auto',
  fontWeight: 'bold'
}

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  fontSize: 24,
  fontWeight: 'bold',
  paddingTop: 10
}
const buttonContainerStyle: React.CSSProperties = {
  marginTop: 10,
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'row'
}
