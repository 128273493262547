import { Box, makeStyles, Typography, TypographyProps } from '@material-ui/core'
import { PropsWithChildren } from 'react'

interface TextDisplayProps {
  title: React.ReactNode
  titleVariant?: TypographyProps['variant']
  titleColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error'
}

export function TextDisplay(props: PropsWithChildren<TextDisplayProps>) {
  const { children, title, titleColor = 'textPrimary', titleVariant = 'h6' } = props
  const styles = useStyles()

  return (
    <Box padding={1}>
      <Box display='flex' alignItems='flex-start' flexDirection='column'>
        <Typography variant={titleVariant} className={styles.title} color={titleColor}>
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1
  }
}))
