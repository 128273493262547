import { Checkbox, Grid, Typography } from '@material-ui/core'
import React from 'react'

import { ModifierOptionFragment } from '../../../../graphql/types.generated'
import { formatValueAsPrice, fromCents } from '../../../../util/formatting'

interface ModifierOptionListItemProps {
  selectedIds: string[]
  options: ModifierOptionFragment[]
}

export const ModifierOptionForTopModifiersList = (props: ModifierOptionListItemProps) => {
  const { options, selectedIds } = props
  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={2}>
        <Typography variant='subtitle2'>Selected by Default</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='subtitle2'>Name</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='subtitle2'>Selected Name</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='subtitle2'>Price</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='subtitle2'>Active</Typography>
      </Grid>

      {options.map((mo: ModifierOptionFragment) => {
        return (
          <Grid key={mo.id} container spacing={2} alignItems='center'>
            <Grid item xs={2}>
              <Checkbox checked={selectedIds.includes(mo.id)} tabIndex={-1} disableRipple />
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle1'>{mo.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='subtitle1'>{mo.selectedName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='subtitle1'> {formatValueAsPrice(fromCents(mo.priceCents))}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='body2' color={mo.active ? 'textPrimary' : 'primary'}>
                {mo.active ? 'Active' : 'Inactive'}
              </Typography>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}
