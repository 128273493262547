import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Checkbox, Grid, Typography } from '@material-ui/core'
import { CheckboxField } from 'components/form/CheckboxField'
import { DateTimeField } from 'components/form/DateTimeField'
import { DefaultFormFooter } from 'components/form/DefaultFormFooter'
import { PriceField } from 'components/form/PriceField'
import { TextField } from 'components/form/TextField'
import TagSelector from 'components/TagSelector'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { allDrinkTags } from 'util/productTags'

import { MerchantPromotionFormModel, merchantPromotionSchema } from './merchantPromotionValidator'

interface MerchantPromotionFormProps {
  onCancel: () => void
  onSubmit: (values: MerchantPromotionFormModel) => void
  disableSubmit?: boolean
}

export const MerchantPromotionForm = (props: MerchantPromotionFormProps) => {
  const { onCancel, onSubmit, disableSubmit } = props

  const formProps = useForm<MerchantPromotionFormModel>({
    resolver: yupResolver(merchantPromotionSchema),
    shouldUnregister: false
  })

  const { register, handleSubmit, formState, setValue } = formProps

  const [notifyCustomers, setNotifyCustomers] = useState(true)

  function updateNotifyCustomers(shouldNotifiy: boolean) {
    setNotifyCustomers(shouldNotifiy)
    setValue('notifyUsersOn', shouldNotifiy ? new Date().toString() : null)
  }

  function updateTags(tags: string[]) {
    setValue('productTags', tags)
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <TextField label='Promotion Name' name='customerFacingName' inputRef={register()} />
          </Grid>

          <Grid item xs={3}>
            <DateTimeField name='start' label='Start Date*' defaultValue={new Date().toString()} />
          </Grid>

          <Grid item xs={3}>
            <DateTimeField name='end' label='End Date*' defaultValue={new Date().toString()} />
          </Grid>

          <Grid item xs={8}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'right' }}>
                Notify Existing Customers**
              </Typography>
              <Checkbox onChange={(e) => updateNotifyCustomers(!!e.target.checked)} checked={notifyCustomers} />
              {notifyCustomers && (
                <DateTimeField name='notifyUsersOn' label='Notify Customers On' defaultValue={new Date().toString()} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <PriceField label='Flat Discount' name='flatDiscountUsCents' inputRef={register()} defaultValue={null} />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography variant='subtitle2'>or</Typography>
            </Box>
          </Grid>

          <Grid item xs={3}>
            <TextField label='Percentage Discount' name='percentageDiscount' inputRef={register()} />
          </Grid>

          <Grid item xs={9}>
            <PriceField label='Maximum Discount' name='maxDiscountUsCents' inputRef={register()} defaultValue={null} />
          </Grid>

          <Grid item xs={3}>
            <CheckboxField name='newUserOnly' label='New Users Only' defaultValue={false}></CheckboxField>
          </Grid>

          <Grid item xs={6}>
            <TagSelector
              tagOptions={allDrinkTags}
              onTagsChanged={(value: string[]) => updateTags(value)}
              label={'Product Tags Required for Discount'}
              initialTags={[]}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField label='Limit Per User' name='limitPerUser' defaultValue={1} inputRef={register()} />
          </Grid>

          <Grid item xs={12}>
            <TextField label='Promo Code' name='promoCode' inputRef={register()} />
          </Grid>

          <Grid item>
            <DefaultFormFooter
              disabled={disableSubmit}
              onCancel={onCancel}
              onSubmit={handleSubmit(onSubmit)}
              submitting={formState.isSubmitting}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body2' color='secondary'>
              *Timezone is local to your computer
            </Typography>

            <Typography variant='body2' color='secondary'>
              **Your previous customers will be sent a push notification with the details of your promotion.
            </Typography>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
