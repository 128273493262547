import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core'
import { ChangeEvent, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { ErrorMessage } from './ErrorMessage'

interface WeekDaysFieldProps {
  name: string
  defaultValues?: number[]
}

export function WeekDaysField(props: WeekDaysFieldProps) {
  const { name, defaultValues = [] } = props
  const { setValue, watch } = useFormContext()

  useEffect(() => {
    setValue(name, defaultValues)
  }, [defaultValues, name, setValue])

  const value: number[] = watch(name)
  return (
    <Box marginBottom={2}>
      <FormLabel>Week days</FormLabel>
      <FormGroup row>
        {days.map((label, index) => {
          function handleChange(e: ChangeEvent<HTMLInputElement>) {
            if (!e.target.checked) {
              setValue(
                name,
                value.filter((item) => item !== index)
              )
            } else {
              setValue(name, [...value, index])
            }
          }
          return (
            <FormControlLabel
              key={index}
              control={<Checkbox key={index} onChange={handleChange} checked={value.includes(index)} />}
              name={name}
              label={label}
              value={index}
            />
          )
        })}
      </FormGroup>
      <ErrorMessage name={name} />
    </Box>
  )
}

export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
