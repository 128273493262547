import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import React from 'react'

const filter = createFilterOptions<string>()

interface TagDropdownProps {
  tagOptions: string[]
  onTagChange: (value: string) => void
  label: string
  disabled?: boolean
}

export default function TagDropdown(props: TagDropdownProps) {
  const { tagOptions, onTagChange, label, disabled } = props
  const [value, setValue] = React.useState<string | null>(null)

  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
        if (newValue) {
          onTagChange(newValue)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        if (params.inputValue !== '') {
          filtered.push(params.inputValue)
        }

        return filtered
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={tagOptions}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option
      }}
      renderOption={(option) => option}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => <TextField {...params} label={label} variant='outlined' />}
    />
  )
}
