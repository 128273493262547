import { Button, Section } from '@bloom-coffee/steamed-milk'
import { Checkbox, CircularProgress, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { DataGrid } from '@mui/x-data-grid'
import { useMerchantFeeConfigsQuery, useSetDefaultMerchantFeeConfigMutation } from 'graphql/types.generated'
import { useToast } from 'hooks/useToast'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatPercentage, formatUsCentsAsUsDollar } from 'util/formatting'

export const MerchantFeeConfigList = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { data, loading: loadingList } = useMerchantFeeConfigsQuery()
  const [execute, { loading: loadingSetDefault }] = useSetDefaultMerchantFeeConfigMutation()

  function handleAdd() {
    navigate('/admin/merchantFees/merchantFeeConfigs/new')
  }
  async function handleSetDefault(merchantFeeConfigId: string) {
    try {
      await execute({
        variables: {
          merchantFeeConfigId: merchantFeeConfigId
        }
      })
    } catch (e: any) {
      toast.error(e.message)
    }
  }
  return (
    <>
      <Section
        title={'Merchant Transaction Fees'}
        addon={<Button endIcon={<AddIcon />} label='Create New Fee' onClick={handleAdd} />}
        variant='grid'
      >
        <Paper>
          <DataGrid
            autoHeight
            disableSelectionOnClick
            loading={loadingList}
            hideFooter
            rows={data?.merchantFeeConfigs || []}
            columns={[
              {
                type: 'number',
                field: 'percentageFee',
                headerName: 'Percentage Fee',
                width: 200,

                valueFormatter: (params) => formatPercentage(params.row.percentageFee)
              },
              {
                type: 'number',
                field: 'staticFeeInUsCents',
                headerName: 'Static Fee',
                width: 150,
                valueFormatter: (params) => formatUsCentsAsUsDollar(params.row.staticFeeInUsCents)
              },
              {
                field: 'default',
                headerName: 'Default',
                width: 125,
                renderCell(params) {
                  return <Checkbox checked={params.row.default} disabled={true}></Checkbox>
                }
              },
              {
                field: '',
                headerName: '',
                width: 150,
                renderCell(params) {
                  return (
                    <div>
                      {(!params.row.default || loadingSetDefault) && (
                        <Button
                          onClick={() => {
                            handleSetDefault(params.row.id)
                          }}
                          endIcon={loadingSetDefault && <CircularProgress color='inherit' />}
                          label='Set as Default'
                        />
                      )}
                    </div>
                  )
                }
              }
            ]}
          />
        </Paper>
      </Section>
    </>
  )
}
