import { ReactComponent as RdyLogo } from 'assets/svg/glyphs/RDY_logo_primary.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ColorHex } from 'theme/ThemeConstants'

export function SignUpHeader() {
  const navigate = useNavigate()

  return (
    <div>
      <div style={headerContentStyle}>
        <RdyLogo style={rdyStyle} onClick={() => navigate('/')} />
      </div>
    </div>
  )
}

const rdyStyle: React.CSSProperties = {
  color: ColorHex.RDY_MALIBU,
  fill: ColorHex.RDY_MALIBU,
  width: 60,
  height: 60,
  padding: 0,
  paddingLeft: 10,
  margin: 0,
  cursor: 'pointer'
}

const headerContentStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}
