import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core'
import { useFieldError } from 'hooks/useFieldError'

type TextFieldProps = MuiTextFieldProps & {
  name: string
  variant?: 'outlined' | 'standard'
}

export function TextField(props: TextFieldProps) {
  const { name, variant, ...rest } = props
  const error = useFieldError<any>({ name })

  return (
    <MuiTextField
      name={name}
      {...rest}
      error={!!error}
      helperText={error?.message}
      variant={variant ?? 'outlined'}
      InputLabelProps={{ shrink: true }}
    />
  )
}
